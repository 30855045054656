// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e9Kf0j5xjmTBXdRs_Khp{text-align:center}.AwR7n99h06KQTmYpd4Kx{border-color:#ffa940;margin:18px 0 12px}.gPIW_D8lzOUMezKYMCCK .k7DY8EvZaDLbyQAKd28f{position:absolute;right:5px;top:5px;z-index:1}.gPIW_D8lzOUMezKYMCCK .ant-form-item{margin-bottom:15px}.gPIW_D8lzOUMezKYMCCK .ant-form-item-has-error .ant-input-group-addon{color:inherit}.gPIW_D8lzOUMezKYMCCK .ant-input-group-addon{background-color:#fff;width:38px}.gPIW_D8lzOUMezKYMCCK .ant-input-affix-wrapper{padding-right:35px}.t4ooQWsRwsfdgiHtpxBo{text-align:center}.BlbcEPupNVNYT0QeL1sz{display:flex;justify-content:center}.BlbcEPupNVNYT0QeL1sz .ha7VmVjlgGm4R3Dh0d00{background-color:#ff7875;border-radius:5px 5px 0 0;color:#fff;font-size:12px;text-align:center;width:160px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-node-widget/random-split-node-widget/random-split-node-widget.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAGF,sBACE,oBCmFyB,CDlFzB,kBAAA,CAIA,4CACE,iBAAA,CAEA,SAAA,CADA,OAAA,CAEA,SAAA,CAIA,qCACE,kBAAA,CAGF,sEACE,aAAA,CAGF,6CACE,qBAAA,CACA,UAAA,CAGF,+CACE,kBAAA,CAKN,sBACE,iBAAA,CAGF,sBACE,YAAA,CACA,sBAAA,CAEA,4CAIE,wBCOyB,CDNzB,yBAAA,CAHA,UAAA,CADA,cAAA,CAEA,iBAAA,CAGA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `e9Kf0j5xjmTBXdRs_Khp`,
	"divider": `AwR7n99h06KQTmYpd4Kx`,
	"inputContainer": `gPIW_D8lzOUMezKYMCCK`,
	"portContainer": `k7DY8EvZaDLbyQAKd28f`,
	"input": `t4ooQWsRwsfdgiHtpxBo`,
	"inputErrorContainer": `BlbcEPupNVNYT0QeL1sz`,
	"inputError": `ha7VmVjlgGm4R3Dh0d00`
};
export default ___CSS_LOADER_EXPORT___;
