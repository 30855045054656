import { FlowItemUI } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { hasMessageOnFlowItemType } from '../../../../types/guards/flow-item-ui.guards'
import { selectEditorFlow, selectFlowItemsMetaById } from '../flow-editor.selectors'
import { FlowEditorState, flowItemInitialMeta, messageInitialMeta } from '../flow-editor.state'
import { replaceRawValueToCanvasHtmlValue, replaceRawValueToHtmlValue } from '../helpers'

export function initFlowItemMeta(state: FlowEditorState, flowItem: FlowItemUI) {
  const flowItemsMetaById = selectFlowItemsMetaById(state)
  flowItemsMetaById[flowItem._id] = cloneDeep(flowItemInitialMeta)

  if (hasMessageOnFlowItemType(flowItem)) {
    for (let messageIndex = 0; messageIndex < flowItem.item.messages.length; messageIndex++) {
      flowItemsMetaById[flowItem._id].messageMeta[messageIndex] = cloneDeep(messageInitialMeta)
      const message = flowItem.item.messages[0]

      flowItemsMetaById[flowItem._id].messageMeta[messageIndex].textHTML =
        replaceRawValueToHtmlValue(state, message.text, flowItem._id)

      flowItemsMetaById[flowItem._id].messageMeta[messageIndex].canvasHTML =
        replaceRawValueToCanvasHtmlValue(state, message.text)

      flowItemsMetaById[flowItem._id].messageMeta[messageIndex].caretPosition = message.text.length
    }
  }
}

export function insertFlowItem(state: FlowEditorState, flowItem: FlowItemUI) {
  const flow = selectEditorFlow(state)
  if (!flow) {
    throw new Error('Flow not found')
  }

  flow.sequenceItems.push(cloneDeep(flowItem))
  flow.sequenceItemIds.push(flowItem._id)

  if (flow.quickReplyIndexToId) {
    flow.quickReplyIndexToId[flowItem._id] = {}
  }

  initFlowItemMeta(state, flowItem)
}
