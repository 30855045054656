import { useQuery } from '@tanstack/react-query'
import { Achievement } from '../../types/achievement.type'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useAchievements(): UseResource<Achievement[]> {
  const { isLoading, data } = useQuery<Achievement[]>([QUERY_NAME.achievements], async () => {
    return request.get<Achievement[]>('me/achievements')
  })

  return {
    data,
    isLoading
  }
}
