import {
  inlineDiscountCodeValidationErrorExpired,
  inlineDiscountCodeValidationErrorNotExists
} from '../sequence-editor.actions'
import { selectEditorSequenceMeta } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
import { setVariablesMetaHandler } from './set-variables-meta.handler'

export function inlineDiscountCodeValidationErrorHandler(
  state: SequenceEditorState,
  action: ReturnType<
    | typeof inlineDiscountCodeValidationErrorExpired
    | typeof inlineDiscountCodeValidationErrorNotExists
  >
): void {
  const sequenceMeta = selectEditorSequenceMeta(state)

  const invalidDiscountCodes = new Set(sequenceMeta.invalidDiscountCodes)
  invalidDiscountCodes.add(action.payload.discountCode)
  sequenceMeta.invalidDiscountCodes = Array.from(invalidDiscountCodes)

  const validDiscountCodes = new Set(sequenceMeta.validDiscountCodes)
  validDiscountCodes.delete(action.payload.discountCode)
  sequenceMeta.validDiscountCodes = Array.from(validDiscountCodes)

  if (action.type === inlineDiscountCodeValidationErrorExpired.type) {
    const expiredDiscountCodes = new Set(sequenceMeta.expiredDiscountCodes)
    expiredDiscountCodes.add(action.payload.discountCode)
    sequenceMeta.expiredDiscountCodes = Array.from(expiredDiscountCodes)
  }

  setVariablesMetaHandler(state)
}
