import { isMessengerMessageSequenceItemUI, PortOrder, QuickReply } from '@ghostmonitor/recartapis'
import { ReorderQuickReplyAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

function generateIndexToId(quickReplies: QuickReply[]): PortOrder {
  const indexToId = {}
  quickReplies.forEach((quickReply, idx) => {
    indexToId[idx.toString(10)] = quickReply.id
  })
  return indexToId
}

export function reorderQuickReplyActionHandler(
  state: SequenceEditorState,
  action: ReorderQuickReplyAction
) {
  if (action.newIndex === action.oldIndex) {
    return
  }

  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequence = selectEditorSequence(state)
  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const quickReply = sequenceItem.quickReplies[action.oldIndex]
    sequenceItem.quickReplies.splice(action.oldIndex, 1)
    sequenceItem.quickReplies.splice(action.newIndex, 0, quickReply)

    const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)
    const quickReplyMeta = sequenceItemMeta.quickReplies[action.oldIndex]
    sequenceItemMeta.quickReplies.splice(action.oldIndex, 1)
    sequenceItemMeta.quickReplies.splice(action.newIndex, 0, quickReplyMeta)

    sequence.quickReplyIndexToId[action.sequenceItemId] = generateIndexToId(
      sequenceItem.quickReplies
    )
  }
}
