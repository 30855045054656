import { Action, isAnyOf, Store } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../store/create-store'
import { DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors, selectSite } from '../../../store/selectors'
import {
  FlowItemAPI,
  getConnectedFlowItemApiIds,
  isDelayFlowItemAPI,
  SMSCampaignScheduleType,
  SMSSettings
} from '@ghostmonitor/recartapis'
import { convertFlowItemUIToFlowItem } from '../../../types/flow-editor/convert-flow-item-ui-to-flow-item'
import { isInTimeRange } from '../../../utils/quiet-hours/quiet-hours-status'
import { loadScheduledFor } from '../../../utils/scheduled-for-date-handling'
import { selectTimeZone } from '../../../store/slices/site/site.selectors'
import { isInQuietHoursByFlowItemIdCalculated } from '../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { TimeOfDay } from '../../../types/time-of-day'

function getNextScheduledDate(flowItem: FlowItemAPI, lastScheduledDate: Date): Date {
  const nextScheduledDate = new Date(lastScheduledDate)

  if (isDelayFlowItemAPI(flowItem)) {
    if (flowItem.item.logic.delay.type === 'delay') {
      nextScheduledDate.setMilliseconds(
        nextScheduledDate.getMilliseconds() + flowItem.item.logic.delay.delay_duration / 1000000
      )
    }

    if (
      flowItem.item.logic.delay.type === 'delay-scheduled' &&
      flowItem.item.logic.delay.scheduled_date !== undefined
    ) {
      nextScheduledDate.setTime(new Date(flowItem.item.logic.delay.scheduled_date).getTime())
    }
  }

  return nextScheduledDate
}

function getInQuietHoursByFlowItemId(
  entryFlowItemId: string,
  flowItemAPIs: FlowItemAPI[],
  campaignScheduleDate: Date,
  inQuietHoursValidator: (date: Date) => boolean
): Record<string, boolean> {
  const inQuietHoursByFlowItemId: Record<string, boolean> = flowItemAPIs.reduce((acc, item) => {
    acc[item._id] = false
    return acc
  }, {})

  const calculateNextItems = (flowItemId: string, lastScheduledDate: Date) => {
    const flowItem = flowItemAPIs.find((item) => item._id === flowItemId)

    if (!flowItem) {
      return
    }

    const nextScheduledDate = getNextScheduledDate(flowItem, lastScheduledDate)
    const isInQuietHours = inQuietHoursValidator(nextScheduledDate)
    if (!inQuietHoursByFlowItemId[flowItemId]) {
      inQuietHoursByFlowItemId[flowItemId] = isInQuietHours
    }

    const connectedFlowItemIds = getConnectedFlowItemApiIds(flowItem)
    for (const connectedFlowItemId of connectedFlowItemIds) {
      calculateNextItems(connectedFlowItemId, nextScheduledDate)
    }
  }

  calculateNextItems(entryFlowItemId, campaignScheduleDate)
  return inQuietHoursByFlowItemId
}

function getDateToTimeOfDayConverter(
  scheduleType: SMSCampaignScheduleType,
  timezone: string
): (d: Date) => TimeOfDay {
  return (d: Date) => {
    const loadedDate = loadScheduledFor(d.toISOString(), scheduleType, timezone)

    return {
      hour: loadedDate.hour(),
      minute: loadedDate.minute()
    }
  }
}

function getInQuietHoursValidator(
  quietHoursConfig: SMSSettings.QuietHoursConfig,
  dateToTimeOfDay: ReturnType<typeof getDateToTimeOfDayConverter>
) {
  return (date: Date) => {
    const { start: quietHoursStart, end: quietHoursEnd } = quietHoursConfig
    if (!quietHoursStart || !quietHoursEnd) {
      return false
    }

    const timeOfDay = dateToTimeOfDay(date)
    return isInTimeRange(timeOfDay, quietHoursStart, quietHoursEnd)
  }
}

const validActions = [
  flowEditorActions.smsCampaignSettingsChanged,
  flowEditorActions.edgeAdded,
  flowEditorActions.edgeRemoved,
  flowEditorActions.flowEditorReady,
  flowEditorActions.delayItemChanged,
  flowEditorActions.nodeDuplicated,
  flowEditorActions.nodeInserted
]

export function updateQuietHoursInfoMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  return (next) => (action: Action) => {
    next(action)

    if (!isAnyOf(...validActions)(action)) {
      return
    }

    const state = store.getState()
    const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const timezone =
      selectTimeZone(selectSite(state)) ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    const smsCampaignScheduledFor = smsCampaign?.scheduledFor
      ? new Date(smsCampaign.scheduledFor)
      : new Date()
    const smsCampaignScheduleType = smsCampaign?.scheduleType ?? 'siteTimezone'
    const quietHoursConfig = flowEditorSelectors.selectQuietHoursConfig(state)
    const { entrySequenceItemId: entryFlowItemId } = flow ?? {}

    if (
      !flow ||
      !flow.tags.includes('campaign') ||
      entryFlowItemId === undefined ||
      !quietHoursConfig
    ) {
      return
    }

    const flowItemAPIs = flow.sequenceItems.map((item) => convertFlowItemUIToFlowItem(item, flow))
    const dateToTimeOfDay = getDateToTimeOfDayConverter(smsCampaignScheduleType, timezone)
    const inQuietHoursValidator = getInQuietHoursValidator(quietHoursConfig, dateToTimeOfDay)

    const inQuietHoursByFlowItemId = getInQuietHoursByFlowItemId(
      entryFlowItemId,
      flowItemAPIs,
      smsCampaignScheduledFor,
      inQuietHoursValidator
    )

    dispatch(isInQuietHoursByFlowItemIdCalculated(inQuietHoursByFlowItemId))
  }
}
