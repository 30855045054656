import { ReplyKeyword, Variable } from '@ghostmonitor/recartapis'
import uniqBy from 'lodash/uniqBy'
import { getVariablesFromRawValue } from './get-variables'

export function getKeywordReplyVariable(replyKeyword: string): Variable {
  return {
    name: replyKeyword.toUpperCase(),
    params: {
      keyword_reply: replyKeyword.toUpperCase()
    }
  }
}

export function getKeywordRepliesFromText(text: string): ReplyKeyword[] {
  const variables = getVariablesFromRawValue(text)

  const keywordReplies: ReplyKeyword[] = variables.reduce((accKeywordReplies, variable) => {
    // trigger value will be added when flowItem trigger is updated
    if (variable.params.keyword_reply) {
      accKeywordReplies.push({ keyword: variable.params.keyword_reply, trigger: '' })
    }
    return accKeywordReplies
  }, [])

  return uniqBy(keywordReplies, 'keyword')
}
