import { BlastStatus } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'

import { selectEditorBlast, selectIsReadOnly } from '../../../../store/selectors'
import { SequenceEditorHeaderProps } from '../../types/sequence-editor-header-props'
import { BlastHeaderBack } from './components/blast-header-back.component'
import { BlastHeaderDuplicateButton } from './components/blast-header-duplicate-button.component'
import { BlastHeaderEditButton } from './components/blast-header-edit-button.component'
import { BlastHeaderSaveAndActivateButton } from './components/blast-header-save-and-activate-button.component'
import { BlastHeaderSaveAsDraftButton } from './components/blast-header-save-as-draft-button.component'
import { HeaderSendSMSTestButton } from './components/header-send-sms-test-button.component'
import { HeaderStatusText } from './components/header-status-text.component'
import { HeaderTimeFrameFilter } from './components/header-time-frame-filter.component'
import { HeaderViewOnly } from './components/header-view-only.component'
import { LastUpdated } from './components/last-updated.component'
import { NotificationBar } from './components/notification-bar.component'
import styles from './header.component.scss'

export function BlastHeader(props: SequenceEditorHeaderProps) {
  const isReadOnly = useSelector(selectIsReadOnly)
  const blast = useSelector(selectEditorBlast)
  const canLeaveReadOnlyMode = blast?.status === BlastStatus.SCHEDULED

  return (
    <div className={styles.container} ref={props.headerRef} data-testid='editor-blast-header'>
      <div className={cn('flex justify-between', styles.headerContainer)}>
        <div className='flex items-center'>
          <BlastHeaderBack />
          <LastUpdated />
        </div>
        {isReadOnly && <HeaderViewOnly />}
        {isReadOnly ? (
          canLeaveReadOnlyMode ? (
            <div className='flex space-x-3 items-center'>
              <BlastHeaderEditButton />
            </div>
          ) : (
            <div className='flex space-x-3 items-center'>
              <HeaderTimeFrameFilter />
              <BlastHeaderDuplicateButton />
            </div>
          )
        ) : (
          <div className='flex space-x-3 items-center'>
            <div className='flex items-center'>
              <HeaderStatusText />
              <HeaderSendSMSTestButton />
              <BlastHeaderSaveAsDraftButton />
              <BlastHeaderSaveAndActivateButton />
            </div>
          </div>
        )}
      </div>
      <NotificationBar />
    </div>
  )
}
