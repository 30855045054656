import { isSMSMessageContactCardUI, isSMSMessageMMSUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../store/selectors'
import { isSMSMessageFlowItemUI } from '../types/guards/flow-item-ui.guards'

export function useHasMMSFER(): boolean {
  const flowItems = useSelector(flowEditorSelectors.selectFlowItems)

  return flowItems.some((flowItem) => {
    if (!isSMSMessageFlowItemUI(flowItem)) {
      return false
    }

    return flowItem.item.messages.some((message) => {
      return isSMSMessageMMSUI(message) || isSMSMessageContactCardUI(message)
    })
  })
}
