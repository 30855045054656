// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RsNuhDhvfHs2iQmV4Agv{font-size:var(--recart-font-size-base);height:40px;padding-right:var(--recart-spacing-2)}.RsNuhDhvfHs2iQmV4Agv.kJuZcuM7Xoe5RV6WgjJt.ant-input-group-wrapper input{border-color:var(--recart-red-1)}.RsNuhDhvfHs2iQmV4Agv .ant-input-suffix{color:var(--recart-grey-6)}.RsNuhDhvfHs2iQmV4Agv .ant-input{font-size:var(--recart-font-size-base)}.RsNuhDhvfHs2iQmV4Agv .ant-input:focus{border-color:var(--recart-primary-blue-1);box-shadow:0 0 4px rgba(3,117,255,.33)}.RsNuhDhvfHs2iQmV4Agv .ant-input[disabled]{color:var(--recart-grey-5)}.kJuZcuM7Xoe5RV6WgjJt{border-color:var(--recart-red-1)}.kJuZcuM7Xoe5RV6WgjJt:focus{box-shadow:0 0 4px hsla(0,100%,70%,.33)}.kuhxw1VLRa7U0hAWgXVF{height:40px}.kuhxw1VLRa7U0hAWgXVF input{font-size:var(--recart-font-size-base)}.kuhxw1VLRa7U0hAWgXVF .ant-input{font-size:var(--recart-font-size-base)}.kuhxw1VLRa7U0hAWgXVF .ant-input:hover:not(:focus){border-color:var(--recart-grey-3)}.kuhxw1VLRa7U0hAWgXVF .ant-input:focus{border:1px solid var(--recart-primary-blue-1);box-shadow:0 0 4px rgba(3,117,255,.33)}.kuhxw1VLRa7U0hAWgXVF .ant-input-number-input[disabled]{color:var(--recart-grey-5)}.kuhxw1VLRa7U0hAWgXVF .ant-input-number-group-addon{background-color:#fff;color:var(--recart-grey-6)}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/input/input.component.scss"],"names":[],"mappings":"AACE,sBACE,sCAAA,CAEA,WAAA,CADA,qCACA,CAGE,yEACE,gCAAA,CAKF,wCACE,0BAAA,CAGF,iCACE,sCAAA,CAEA,uCACE,yCAAA,CACA,sCAAA,CAIJ,2CACE,0BAAA,CAKN,sBACE,gCAAA,CAEA,4BACE,uCAAA,CAIJ,sBACE,WAAA,CAEA,4BACE,sCAAA,CAIA,iCACE,sCAAA,CAEA,mDACE,iCAAA,CAGF,uCACE,6CAAA,CACA,sCAAA,CAIJ,wDACE,0BAAA,CAGF,oDAEE,qBAAA,CADA,0BACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `RsNuhDhvfHs2iQmV4Agv`,
	"inputError": `kJuZcuM7Xoe5RV6WgjJt`,
	"inputNumber": `kuhxw1VLRa7U0hAWgXVF`
};
export default ___CSS_LOADER_EXPORT___;
