import { Alert } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFacebookSelector } from '../../../../../hooks/use-facebook-selector'
import { selectFacebookPageId } from '../../../../../store/slices/facebook/facebook.selectors'
import { facebookCommentsPostIdChanged } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { useEditorFacebookCommentPostId } from '../../../hooks/use-editor-post-id'
import { CommentCaptureInput } from '../../custom-conversation-wizard/comment-capture-input/comment-capture-input.component'
import { Modal } from '../../../../../components/ui-kit/modal/modal.component'

export function FacebookPostIdBar() {
  const pageId = useFacebookSelector(selectFacebookPageId)
  const currentPostId = useEditorFacebookCommentPostId()
  const [postId, setPostId] = useState(currentPostId)
  const [isPostIdModalOpen, setIsPostIdModalOpen] = useState(false)
  const dispatch = useDispatch()

  function handleEditClick() {
    setIsPostIdModalOpen(true)
  }

  function handlePostIdChange(value = '') {
    setPostId(value)
  }

  function handleModalOk() {
    dispatch(facebookCommentsPostIdChanged({ postId, pageId }))
    setIsPostIdModalOpen(false)
  }

  function handleModalCancel() {
    setIsPostIdModalOpen(false)
  }

  return (
    <div>
      <Modal
        centered
        open={isPostIdModalOpen}
        title='Select Facebook post'
        okText='Use this post'
        onCancel={handleModalCancel}
        onOk={handleModalOk}
        okButtonProps={{
          disabled: !postId
        }}
      >
        <CommentCaptureInput
          defaultValue={postId}
          onChange={handlePostIdChange}
          facebookPostId={postId}
        />
      </Modal>
      {currentPostId ? (
        <Alert
          banner
          type='info'
          message={
            <>
              You can change the selected Facebook post anytime.{' '}
              <span onClick={handleEditClick}>
                <a>Change Facebook post</a>
              </span>
            </>
          }
        />
      ) : (
        <Alert
          banner
          message={
            <>
              <strong>No Facebook post selected.</strong> You must select the desired Facebook post
              before enabling the conversation flow.{' '}
              <span onClick={handleEditClick}>
                <a>Select Facebook post</a>
              </span>
            </>
          }
        />
      )}
    </div>
  )
}
