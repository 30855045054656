import { DiscountType } from '@ghostmonitor/recartapis'
import React from 'react'
import { ReactComponent as CheckIcon } from '../../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/33-Form-Validation/check-circle-1.svg'
import styles from './discount-code-success.component.scss'
import { DiscountTypeLabels } from './types/discount-type-labels'

interface DiscountCodeSuccessProp {
  code: string
  type: DiscountType
}

export function DiscountCodeSuccess(props: DiscountCodeSuccessProp) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Discount code ready</div>
        <CheckIcon />
      </div>
      <div>The discount code has been added to your message.</div>
      <div className={styles.codeContainer}>
        <span className={styles.codeBox}>{props.code}</span>
        <div className={styles.codeType}>{DiscountTypeLabels[props.type]}</div>
      </div>
      <div>You can change the discount settings anytime in your Shopify admin.</div>
    </div>
  )
}
