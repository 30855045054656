import { Blast, SequenceItemUI, SequenceUI } from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import {
  SequenceEditorInstance,
  SequenceEditorInstancePatch
} from '../../../store/slices/sequence-editor/sequence-editor.state'
import { createScope } from '../../../utils/logger/logger'
import { setLocalStorageItem } from '../../../utils/set-local-storage-item'

const { error } = createScope('sequence-editor')

export function setLocalBlast(blast: Blast): void {
  try {
    setLocalStorageItem(`blast-sequenceId:${blast.sequenceId}`, JSON.stringify(blast))
  } catch (err) {
    error(err, { blast })
  }
}

export function getLocalBlastBySequenceId(sequenceId: string): Blast | null {
  const blastInstance = localStorage.getItem(`blast-sequenceId:${sequenceId}`)
  if (blastInstance === null) {
    return null
  }
  return JSON.parse(blastInstance)
}

export function patchLocalBlastBySequenceId(sequenceId: string, patch: Partial<Blast>): void {
  const localBlast = getLocalBlastBySequenceId(sequenceId)
  if (localBlast === null) {
    return
  }
  merge(localBlast, patch)
  setLocalBlast(localBlast)
}

export function setLocalSequenceEditorInstance(
  sequenceId: string,
  sequenceEditorInstance: SequenceEditorInstance
): void {
  try {
    setLocalStorageItem(
      `sequenceEditorInstance-${sequenceId}`,
      JSON.stringify(sequenceEditorInstance)
    )
  } catch (err) {
    error(err, { sequenceId })
  }
}

export function getLocalSequenceEditorInstance(sequenceId: string): SequenceEditorInstance | null {
  const sequenceEditorInstance = localStorage.getItem(`sequenceEditorInstance-${sequenceId}`)
  if (sequenceEditorInstance === null) {
    return null
  }
  return JSON.parse(sequenceEditorInstance)
}

export function patchLocalSequenceEditorInstance(
  sequenceId: string,
  patch: SequenceEditorInstancePatch
): void {
  const sequenceEditorInstance = getLocalSequenceEditorInstance(sequenceId)
  merge(sequenceEditorInstance, patch)
  setLocalSequenceEditorInstance(sequenceId, sequenceEditorInstance)
}

export function setLocalSequence(sequenceId: string, sequence: SequenceUI): void {
  const sequenceEditorInstance = getLocalSequenceEditorInstance(sequenceId)
  if (sequenceEditorInstance !== null) {
    sequenceEditorInstance.sequence = sequence
    setLocalSequenceEditorInstance(sequenceId, sequenceEditorInstance)
  } else {
    error('CannotPatchLocalSequence', { sequence })
  }
}

export function setLocalSequenceItem(sequenceItem: SequenceItemUI): void {
  try {
    setLocalStorageItem(`sequenceItem-${sequenceItem._id}`, JSON.stringify(sequenceItem))
  } catch (err) {
    error(err, { sequenceItem })
  }
}
export function getLocalSequenceItem(sequenceItemId: string): SequenceItemUI {
  const sequenceItem = localStorage.getItem(`sequenceItem-${sequenceItemId}`)
  if (sequenceItem === null) {
    return null
  }
  return JSON.parse(sequenceItem)
}

export function getLocalDebugView(): boolean {
  const showDebugView = localStorage.getItem('recart-sequence-editor-debug-view')
  if (!showDebugView) {
    return null
  }
  return showDebugView !== 'false'
}

export function setLocalDebugView(enabled: boolean): void {
  try {
    setLocalStorageItem('recart-sequence-editor-debug-view', JSON.stringify(enabled))
  } catch (err) {
    console.warn('setLocalDebugView failed')
  }
}

export function removeLocalSeqenceItem(sequenceItemId: string): void {
  localStorage.removeItem(`sequenceItem-${sequenceItemId}`)
}
