import { Button } from 'antd'
import type { ButtonProps as AntButtonProps } from 'antd/lib/button/button.d'
import cn from 'classnames'
import React from 'react'
import styles from './sequence-item-button.component.scss'

export interface SequenceItemButtonProps extends AntButtonProps {
  tilted?: boolean
}

export function SequenceItemButton({ className, tilted, ...props }: SequenceItemButtonProps) {
  const buttonStyles = cn(styles.container, {
    [styles.disabled]: props.disabled,
    [styles.tilted]: tilted,
    [className]: className
  })

  return (
    <Button
      {...props}
      className={buttonStyles}
      data-testid={`sequence-item-button-${props.title}`}
    />
  )
}
