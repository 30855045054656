import { Store } from '@reduxjs/toolkit'
import { history } from '../../../../routing-utilities/history/history'
import type { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { selectEditorSequence } from '../../../../store/selectors'
import { updateBlastTargetRules } from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { loadBlastThunk } from '../../../../store/slices/sequence-editor/thunks/load-blast.thunk'
import { isBlastSequence } from '../../utils/assert-sequence-type'
import { isBlastReadonly } from '../../utils/is-blast-read-only'

export function updateBlastTargetRulesMiddleware(
  store: Store<DashboardState>
): (next: any) => (action: ValidatableAction) => void {
  const dispatch: AppDispatch = store.dispatch
  return (next) => (action: ValidatableAction) => {
    next(action)
    const state = store.getState()
    const sequence = selectEditorSequence(state)
    if (loadBlastThunk.fulfilled.match(action)) {
      if (isBlastSequence(sequence) && !isBlastReadonly(action.payload)) {
        const searchParams = new URLSearchParams(state.location.currentLocation.search)
        const segmentId = searchParams.get('segmentId') ?? undefined

        if (segmentId) {
          dispatch(
            updateBlastTargetRules({
              targetType: 'includeSegmentIds',
              segmentIds: [segmentId]
            })
          )
          history.replace(state.location.currentLocation.pathname)
        }
      }
    }
  }
}
