export interface VariableTypeOption {
  value: string
  label: string
}

const pageName: VariableTypeOption = {
  value: 'page.name',
  label: 'Facebook Page name'
}

const siteName: VariableTypeOption = {
  value: 'site.name',
  label: 'Site name'
}

const siteDomain: VariableTypeOption = {
  value: 'site.domain',
  label: 'Site URL'
}

export const cartUrl: VariableTypeOption = {
  value: 'cart.url',
  label: 'Cart URL'
}

export const fulfillmentUrl: VariableTypeOption = {
  value: 'fulfillment.tracking_url',
  label: 'Fulfillment tracking URL'
}

export const orderStatusUrl: VariableTypeOption = {
  value: 'order.status_url',
  label: 'Order status URL'
}

const firstName: VariableTypeOption = {
  value: 'first_name',
  label: 'First name'
}

const lastName: VariableTypeOption = {
  value: 'last_name',
  label: 'Last name'
}

export const productName: VariableTypeOption = {
  value: 'product.name',
  label: 'Product name'
}

export const productUrl: VariableTypeOption = {
  value: 'product.url',
  label: 'Product URL'
}

export const supportEmail: VariableTypeOption = {
  value: 'site.email',
  label: 'Support email'
}

export const restoreLink: VariableTypeOption = {
  value: 'subscriber.optin_source_url',
  label: 'Restore link'
}

export const loopworkBillingAttemptErrorMessage: VariableTypeOption = {
  value: 'loopwork.billing_attempt_error_message',
  label: 'Loop billing attempt error message'
}

export const loopworkRetryDelayDays: VariableTypeOption = {
  value: 'loopwork.billing_retry_delay_days',
  label: 'Loop billing retry delay days'
}

export const loopworkFirstName: VariableTypeOption = {
  value: 'loopwork.first_name',
  label: 'Loop first name'
}

export const loopworkLastName: VariableTypeOption = {
  value: 'loopwork.last_name',
  label: 'Loop last name'
}

export const loopworkProductNames: VariableTypeOption = {
  value: 'loopwork.product_names',
  label: 'Loop product names'
}

export const loopworkNextBillingDateDisplay: VariableTypeOption = {
  value: 'loopwork.next_billing_date_display',
  label: 'Loop next billing date'
}

export const loopworkSubscriptionShopifyId: VariableTypeOption = {
  value: 'loopwork.subscription_shopify_id',
  label: 'Loop subscription Shopify ID'
}

export const loopworkQuickActionReactivateSubscription: VariableTypeOption = {
  value: 'loopwork.quick_action_reactivate_subscription',
  label: 'Quick action: Reactivate subscription'
}

export const loopworkQuickActionUpdatePaymentMethod: VariableTypeOption = {
  value: 'loopwork.quick_action_update_payment_method',
  label: 'Quick action: Update payment method'
}

export const loopworkQuickActionResumeSubscription: VariableTypeOption = {
  value: 'loopwork.quick_action_resume_subscription',
  label: 'Quick action: Resume subscription'
}

export const loopworkQuickActionAddProduct: VariableTypeOption = {
  value: 'loopwork.quick_action_add_product',
  label: 'Quick action: Add product'
}

export const loopworkCustomerPortalLink: VariableTypeOption = {
  value: 'loopwork.customer_portal_link',
  label: 'Loop customer portal link'
}

export const wondermentTrackingCode: VariableTypeOption = {
  value: 'wonderment.tracking_code',
  label: 'Tracking code'
}

export const wondermentOrderNumber: VariableTypeOption = {
  value: 'wonderment.order_number',
  label: 'Shopify order number'
}

export const wondermentOrderID: VariableTypeOption = {
  value: 'wonderment.order_id',
  label: 'Shopify order ID'
}

export const wondermentCarrierName: VariableTypeOption = {
  value: 'wonderment.carrier_name',
  label: 'Carrier name'
}

export const wondermentProductNames: VariableTypeOption = {
  value: 'wonderment.product_names',
  label: 'Product names'
}

export const wondermentTrackingUrlOption: VariableTypeOption = {
  value: 'wonderment.tracking_url',
  label: 'Tracking URL'
}

export const wondermentWondermentTrackingUrlOption: VariableTypeOption = {
  value: 'wonderment.wonderment_tracking_url',
  label: 'Wonderment Tracking URL'
}

export const checkoutAbandonmentRecoveryLink: VariableTypeOption = {
  value: 'checkout.recovery_link',
  label: 'Checkout Abandonment Recovery Link'
}

export const wondermentVariableTypeOptions: VariableTypeOption[] = [
  wondermentCarrierName,
  wondermentProductNames,
  wondermentOrderID,
  wondermentOrderNumber,
  wondermentTrackingCode,
  wondermentTrackingUrlOption,
  wondermentWondermentTrackingUrlOption
]

export const loopworkBillingFailedVariableTypeOptions = [
  loopworkBillingAttemptErrorMessage,
  loopworkRetryDelayDays
]

export const loopWorkQuickActions = [
  loopworkQuickActionReactivateSubscription,
  loopworkQuickActionUpdatePaymentMethod,
  loopworkQuickActionResumeSubscription,
  loopworkQuickActionAddProduct
]

export const loopworkGeneralVariableTypeOptions: VariableTypeOption[] = [
  loopworkFirstName,
  loopworkLastName,
  loopworkProductNames,
  loopworkNextBillingDateDisplay,
  loopworkSubscriptionShopifyId,
  loopworkCustomerPortalLink
]

export const loopworkVariableTypeOptions: VariableTypeOption[] = [
  ...loopworkBillingFailedVariableTypeOptions,
  ...loopworkGeneralVariableTypeOptions,
  ...loopWorkQuickActions
]

export const smsVariableTypeOptions: VariableTypeOption[] = [siteName, siteDomain]

export const messengerVariableTypeOptions: VariableTypeOption[] = [
  firstName,
  lastName,
  pageName,
  siteDomain
]

export const entryItemOnlyVariableTypeOptions = [
  orderStatusUrl,
  fulfillmentUrl,
  ...wondermentVariableTypeOptions,
  ...loopworkVariableTypeOptions
]

export const entryItemOnlyVariableValues = entryItemOnlyVariableTypeOptions.map(
  (variableOption) => variableOption.value
)

export const variableNames = [
  ...smsVariableTypeOptions,
  ...messengerVariableTypeOptions,
  ...wondermentVariableTypeOptions,
  ...loopworkVariableTypeOptions,
  cartUrl,
  fulfillmentUrl,
  orderStatusUrl,
  restoreLink,
  productUrl,
  productName,
  checkoutAbandonmentRecoveryLink
].map((option) => option.value)
