import { NodeType } from '@ghostmonitor/recartapis'
import React from 'react'
import { AbstractNodeFactory, DiagramEngine } from 'storm-react-diagrams'
import { DelaySequenceItemNodeWidget } from '../../../components/sequence-item-node-widget/delay-node-widget/delay-sequence-item-node-widget.component'
import { DelaySequenceItemNodeModel } from './delay-sequence-item-model'

export class DelaySequenceItemNodeFactory extends AbstractNodeFactory {
  constructor(private readonly repaintCanvas: VoidFunction = () => undefined) {
    super(NodeType.DELAY_SEQUENCE_ITEM)
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    sequenceItemNode: DelaySequenceItemNodeModel
  ): JSX.Element {
    return (
      <DelaySequenceItemNodeWidget
        sequenceItemNode={sequenceItemNode}
        repaintCanvas={this.repaintCanvas}
      />
    )
  }

  public getNewInstance(...args) {
    return new DelaySequenceItemNodeModel()
  }
}
