// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QhElsfDUGphWwfgxIPuP{display:inline-block;padding:0 15px}._EVrqLVMN5d9NErApysw{color:var(--recart-grey-4);height:12px;position:relative;top:1px;width:12px}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/range-picker/range-picker-separator.component.scss"],"names":[],"mappings":"AACE,sBAEE,oBAAA,CADA,cACA,CAGF,sBAKE,0BAAA,CAFA,WAAA,CAFA,iBAAA,CACA,OAAA,CAEA,UACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `QhElsfDUGphWwfgxIPuP`,
	"icon": `_EVrqLVMN5d9NErApysw`
};
export default ___CSS_LOADER_EXPORT___;
