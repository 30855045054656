import { createAction } from '@reduxjs/toolkit'
import { Connection, NodeChange } from 'reactflow'
import { FlowEditorValidationError } from '../../../utils/flow-editor/flow-editor-errors'
import { withPayloadType } from '../../redux-helpers'
import { SMSSettings } from '@ghostmonitor/recartapis'

export const nodeChanges = createAction(
  'flowEditor/nodeChanges',
  withPayloadType<{
    nodeChanges: NodeChange[]
  }>()
)
export const nodeRemoved = createAction(
  'flowEditor/nodeRemoved',
  withPayloadType<{
    flowItemId: string
  }>()
)
export const removeDeprecatedConditionalSplitClicked = createAction(
  'flowEditor/removeDeprecatedConditionalSplitClicked',
  withPayloadType<{
    flowItemId: string
  }>()
)

export const edgeAdded = createAction(
  'flowEditor/edgeAdded',
  withPayloadType<{
    connection: Connection
  }>()
)

export const messageClicked = createAction(
  'flowEditor/messageClicked',
  withPayloadType<{
    flowItemId: string
    messageIndex: number
  }>()
)

export interface PayloadDiscountCodeValidationFailed {
  discountCodeName: string
  error: FlowEditorValidationError
}

export const discountCodeValidationFailed = createAction(
  'flowEditor/discountCodeValidationFailed',
  withPayloadType<PayloadDiscountCodeValidationFailed>()
)

// It's a separate action because of circular dependency
export const discountCodeValidationSucceeded = createAction(
  'flowEditor/discountCodeValidationSucceeded',
  withPayloadType<{
    discountCodeName: string
  }>()
)

export interface PayloadFlowValidationFailed {
  errors: (FlowEditorValidationError & {
    nodeId: string
    edgeId?: string
  })[]
}

// It's a separate action because of circular dependency
export const flowValidationFailed = createAction(
  'flowEditor/flowValidationFailed',
  withPayloadType<PayloadFlowValidationFailed>()
)

// It's a separate action because of circular dependency
export const flowValidationSucceeded = createAction('flowEditor/flowValidationSucceeded')

export interface PayloadSmsCampaignValidationFailed {
  errors: FlowEditorValidationError[]
}

// It's a separate action because of circular dependency
export const smsCampaignValidationFailed = createAction(
  'flowEditor/smsCampaignValidationFailed',
  withPayloadType<PayloadSmsCampaignValidationFailed>()
)

// It's a separate action because of circular dependency
export const smsCampaignValidationSucceeded = createAction(
  'flowEditor/smsCampaignValidationSucceeded'
)

export interface PayloadFlowItemValidation {
  flowItemId: string
}

export interface PayloadFlowItemValidationFailed extends PayloadFlowItemValidation {
  errors: FlowEditorValidationError[]
}

// It's a separate action because of circular dependency
export const flowItemValidationFailed = createAction(
  'flowEditor/flowItemValidationFailed',
  withPayloadType<PayloadFlowItemValidationFailed>()
)

// It's a separate action because of circular dependency
export const flowItemValidationSucceeded = createAction(
  'flowEditor/flowItemValidationSucceeded',
  withPayloadType<PayloadFlowItemValidation>()
)

export interface PayloadMessageValidationFailed extends PayloadFlowItemValidation {
  messageIndex: number
  errors: FlowEditorValidationError[]
}

export interface PayloadMessageValidationSucceeded extends PayloadFlowItemValidation {
  messageIndex: number
}

// It's a separate action because of circular dependency
export const messageValidationFailed = createAction(
  'flowEditor/messageValidationFailed',
  withPayloadType<PayloadMessageValidationFailed>()
)

// It's a separate action because of circular dependency
export const messageValidationSucceeded = createAction(
  'flowEditor/messageValidationSucceeded',
  withPayloadType<PayloadMessageValidationSucceeded>()
)

export const localDraftChanged = createAction(
  'flowEditor/localDraftChanged',
  withPayloadType<{ reason: string }>()
)

export const settingsReady = createAction('flowEditor/settingsReady')

export const quietHoursSettingsLoaded = createAction(
  'flowEditor/quietHoursSettingsLoaded',
  withPayloadType<SMSSettings.QuietHoursConfig>()
)

export const isInQuietHoursByFlowItemIdCalculated = createAction(
  'flowEditor/isInQuietHoursByFlowItemIdCalculated',
  withPayloadType<Record<string, boolean>>()
)
