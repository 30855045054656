import { Spin } from 'antd'
import React from 'react'
import { ReactComponent as RecartLogo } from '../../static/images/svg/loading-recart-logo.svg'
import styles from './app-loader.component.scss'

export function AppLoader() {
  return (
    <div data-testid='app-loader' className={styles.loader}>
      <RecartLogo className={styles.logo} />
      <Spin size='large' className={styles.spinner} />
    </div>
  )
}
