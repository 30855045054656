// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T2Hc4QUwhb86QyPpw48D{align-items:center;background:#dee4f5;border:none;border-radius:6px;cursor:pointer;display:flex;height:25px;justify-content:center;margin:0 2px;transition:all .1s;width:25px}.T2Hc4QUwhb86QyPpw48D:hover{transform:scale(1.15);transform-origin:center}.T2Hc4QUwhb86QyPpw48D:hover.ymri4i6AO9J2JfpvSIHR{transform:none}.T2Hc4QUwhb86QyPpw48D svg{width:14px}.T2Hc4QUwhb86QyPpw48D svg path{fill:#212b36}.ymri4i6AO9J2JfpvSIHR{cursor:default;opacity:.5}.ymri4i6AO9J2JfpvSIHR.IusWI0SnkcZI59BvseMI span{transform:rotate(45deg);transition:transform .6s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-button/sequence-item-button.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAKE,kBAAA,CAJA,kBCHc,CDKd,WAAA,CADA,iBAAA,CAQA,cAAA,CANA,YAAA,CAIA,WAAA,CAFA,sBAAA,CAKA,YAAA,CAFA,kBAAA,CAFA,UAIA,CAEA,4BAEE,qBAAA,CADA,uBACA,CAEA,iDACE,cAAA,CAIJ,0BACE,UAAA,CAEA,+BACE,YC+EY,CD1ElB,sBACE,cAAA,CACA,UAAA,CAGE,gDAEE,uBAAA,CADA,oCACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `T2Hc4QUwhb86QyPpw48D`,
	"disabled": `ymri4i6AO9J2JfpvSIHR`,
	"tilted": `IusWI0SnkcZI59BvseMI`
};
export default ___CSS_LOADER_EXPORT___;
