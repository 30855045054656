// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g56LILRLdt0gtkCbCkEA{position:relative}.wqRbYECvkoQi0QhdInm0{visibility:hidden}.AmxrZwElSZgusWOfS10V{display:flex;position:absolute;right:10px;text-align:right;top:115px;z-index:2}.AmxrZwElSZgusWOfS10V>div{margin-right:10px}.QAjWJ6cU_astsimq3XkM{background:#fff;border:1px dashed #476dff;border-radius:10px 10px 0 0;color:#476dff;cursor:pointer;font-size:12px;height:18px;line-height:18px;position:absolute;right:-125px;text-align:center;top:105px;transform:rotate(270deg);transition:all .1s;width:224px;z-index:3}.QAjWJ6cU_astsimq3XkM:hover{transform:rotate(270deg) scaleX(1.1);transform-origin:center}.U0OMl_eeDpBn4c7cWQkn{align-items:stretch;bottom:0;cursor:default;display:flex;flex-direction:column;justify-content:center;left:-18px;position:absolute;top:0;width:14px}.U0OMl_eeDpBn4c7cWQkn svg{color:#979797;cursor:grab}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/gallery-carousel/gallery-carousel.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAGF,sBACE,iBAAA,CAGF,sBAME,YAAA,CALA,iBAAA,CAGA,UAAA,CAFA,gBAAA,CACA,SAAA,CAEA,SACA,CAEA,0BACE,iBAAA,CAIJ,sBAME,eAAA,CAHA,yBAAA,CAIA,2BAAA,CACA,aC7BY,CD8BZ,cAAA,CAKA,cAAA,CADA,WAAA,CADA,gBAAA,CAXA,iBAAA,CAGA,YAAA,CAWA,iBAAA,CAVA,SAAA,CAHA,wBAAA,CAQA,kBAAA,CACA,WAAA,CAKA,SAAA,CAEA,4BAEE,oCAAA,CADA,uBACA,CAIJ,sBASE,mBAAA,CAJA,QAAA,CAKA,cAAA,CAJA,YAAA,CAEA,qBAAA,CADA,sBAAA,CALA,UAAA,CADA,iBAAA,CAGA,KAAA,CADA,UAOA,CAEA,0BAEE,aAAA,CADA,WACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `g56LILRLdt0gtkCbCkEA`,
	"hidden": `wqRbYECvkoQi0QhdInm0`,
	"toolbar": `AmxrZwElSZgusWOfS10V`,
	"add-card": `QAjWJ6cU_astsimq3XkM`,
	"handle": `U0OMl_eeDpBn4c7cWQkn`
};
export default ___CSS_LOADER_EXPORT___;
