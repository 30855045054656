export interface SMSCountry {
  dialInCode: number
  countryCode: string
  country: string
}

export const smsCountries: SMSCountry[] = [
  {
    country: 'Afghanistan',
    dialInCode: 93,
    countryCode: 'AF'
  },
  {
    country: 'Albania',
    dialInCode: 355,
    countryCode: 'AL'
  },
  {
    country: 'Algeria',
    dialInCode: 213,
    countryCode: 'DZ'
  },
  {
    country: 'American Samoa',
    dialInCode: 1,
    countryCode: 'AS'
  },
  {
    country: 'Andorra',
    dialInCode: 376,
    countryCode: 'AD'
  },
  {
    country: 'Angola',
    dialInCode: 244,
    countryCode: 'AO'
  },
  {
    country: 'Anguilla',
    dialInCode: 1,
    countryCode: 'AI'
  },
  {
    country: 'Antarctica',
    dialInCode: 672,
    countryCode: 'AQ'
  },
  {
    country: 'Antigua and Barbuda',
    dialInCode: 1,
    countryCode: 'AG'
  },
  {
    country: 'Argentina',
    dialInCode: 54,
    countryCode: 'AR'
  },
  {
    country: 'Armenia',
    dialInCode: 374,
    countryCode: 'AM'
  },
  {
    country: 'Aruba',
    dialInCode: 297,
    countryCode: 'AW'
  },
  {
    country: 'Australia',
    dialInCode: 61,
    countryCode: 'AU'
  },
  {
    country: 'Austria',
    dialInCode: 43,
    countryCode: 'AT'
  },
  {
    country: 'Azerbaijan',
    dialInCode: 994,
    countryCode: 'AZ'
  },
  {
    country: 'Bahamas',
    dialInCode: 1,
    countryCode: 'BS'
  },
  {
    country: 'Bahrain',
    dialInCode: 973,
    countryCode: 'BH'
  },
  {
    country: 'Bangladesh',
    dialInCode: 880,
    countryCode: 'BD'
  },
  {
    country: 'Barbados',
    dialInCode: 1,
    countryCode: 'BB'
  },
  {
    country: 'Belarus',
    dialInCode: 375,
    countryCode: 'BY'
  },
  {
    country: 'Belgium',
    dialInCode: 32,
    countryCode: 'BE'
  },
  {
    country: 'Belize',
    dialInCode: 501,
    countryCode: 'BZ'
  },
  {
    country: 'Benin',
    dialInCode: 229,
    countryCode: 'BJ'
  },
  {
    country: 'Bermuda',
    dialInCode: 1,
    countryCode: 'BM'
  },
  {
    country: 'Bhutan',
    dialInCode: 975,
    countryCode: 'BT'
  },
  {
    country: 'Bolivia',
    dialInCode: 591,
    countryCode: 'BO'
  },
  {
    country: 'Bosnia and Herzegovina',
    dialInCode: 387,
    countryCode: 'BA'
  },
  {
    country: 'Botswana',
    dialInCode: 267,
    countryCode: 'BW'
  },
  {
    country: 'Brazil',
    dialInCode: 55,
    countryCode: 'BR'
  },
  {
    country: 'British Indian Ocean Territory',
    dialInCode: 246,
    countryCode: 'IO'
  },
  {
    country: 'British Virgin Islands',
    dialInCode: 1,
    countryCode: 'VG'
  },
  {
    country: 'Brunei',
    dialInCode: 673,
    countryCode: 'BN'
  },
  {
    country: 'Bulgaria',
    dialInCode: 359,
    countryCode: 'BG'
  },
  {
    country: 'Burkina Faso',
    dialInCode: 226,
    countryCode: 'BF'
  },
  {
    country: 'Burundi',
    dialInCode: 257,
    countryCode: 'BI'
  },
  {
    country: 'Cambodia',
    dialInCode: 855,
    countryCode: 'KH'
  },
  {
    country: 'Cameroon',
    dialInCode: 237,
    countryCode: 'CM'
  },
  {
    country: 'Canada',
    dialInCode: 1,
    countryCode: 'CA'
  },
  {
    country: 'Cape Verde',
    dialInCode: 238,
    countryCode: 'CV'
  },
  {
    country: 'Cayman Islands',
    dialInCode: 1,
    countryCode: 'KY'
  },
  {
    country: 'Central African Republic',
    dialInCode: 236,
    countryCode: 'CF'
  },
  {
    country: 'Chad',
    dialInCode: 235,
    countryCode: 'TD'
  },
  {
    country: 'Chile',
    dialInCode: 56,
    countryCode: 'CL'
  },
  {
    country: 'China',
    dialInCode: 86,
    countryCode: 'CN'
  },
  {
    country: 'Christmas Island',
    dialInCode: 61,
    countryCode: 'CX'
  },
  {
    country: 'Cocos Islands',
    dialInCode: 61,
    countryCode: 'CC'
  },
  {
    country: 'Colombia',
    dialInCode: 57,
    countryCode: 'CO'
  },
  {
    country: 'Comoros',
    dialInCode: 269,
    countryCode: 'KM'
  },
  {
    country: 'Cook Islands',
    dialInCode: 682,
    countryCode: 'CK'
  },
  {
    country: 'Costa Rica',
    dialInCode: 506,
    countryCode: 'CR'
  },
  {
    country: 'Croatia',
    dialInCode: 385,
    countryCode: 'HR'
  },
  {
    country: 'Cuba',
    dialInCode: 53,
    countryCode: 'CU'
  },
  {
    country: 'Curacao',
    dialInCode: 599,
    countryCode: 'CW'
  },
  {
    country: 'Cyprus',
    dialInCode: 357,
    countryCode: 'CY'
  },
  {
    country: 'Czech Republic',
    dialInCode: 420,
    countryCode: 'CZ'
  },
  {
    country: 'Democratic Republic of the Congo',
    dialInCode: 243,
    countryCode: 'CD'
  },
  {
    country: 'Denmark',
    dialInCode: 45,
    countryCode: 'DK'
  },
  {
    country: 'Djibouti',
    dialInCode: 253,
    countryCode: 'DJ'
  },
  {
    country: 'Dominica',
    dialInCode: 1,
    countryCode: 'DM'
  },
  {
    country: 'Dominican Republic',
    dialInCode: 1,
    countryCode: 'DO'
  },
  {
    country: 'East Timor',
    dialInCode: 670,
    countryCode: 'TL'
  },
  {
    country: 'Ecuador',
    dialInCode: 593,
    countryCode: 'EC'
  },
  {
    country: 'Egypt',
    dialInCode: 20,
    countryCode: 'EG'
  },
  {
    country: 'El Salvador',
    dialInCode: 503,
    countryCode: 'SV'
  },
  {
    country: 'Equatorial Guinea',
    dialInCode: 240,
    countryCode: 'GQ'
  },
  {
    country: 'Eritrea',
    dialInCode: 291,
    countryCode: 'ER'
  },
  {
    country: 'Estonia',
    dialInCode: 372,
    countryCode: 'EE'
  },
  {
    country: 'Ethiopia',
    dialInCode: 251,
    countryCode: 'ET'
  },
  {
    country: 'Falkland Islands',
    dialInCode: 500,
    countryCode: 'FK'
  },
  {
    country: 'Faroe Islands',
    dialInCode: 298,
    countryCode: 'FO'
  },
  {
    country: 'Fiji',
    dialInCode: 679,
    countryCode: 'FJ'
  },
  {
    country: 'Finland',
    dialInCode: 358,
    countryCode: 'FI'
  },
  {
    country: 'France',
    dialInCode: 33,
    countryCode: 'FR'
  },
  {
    country: 'French Polynesia',
    dialInCode: 689,
    countryCode: 'PF'
  },
  {
    country: 'Gabon',
    dialInCode: 241,
    countryCode: 'GA'
  },
  {
    country: 'Gambia',
    dialInCode: 220,
    countryCode: 'GM'
  },
  {
    country: 'Georgia',
    dialInCode: 995,
    countryCode: 'GE'
  },
  {
    country: 'Germany',
    dialInCode: 49,
    countryCode: 'DE'
  },
  {
    country: 'Ghana',
    dialInCode: 233,
    countryCode: 'GH'
  },
  {
    country: 'Gibraltar',
    dialInCode: 350,
    countryCode: 'GI'
  },
  {
    country: 'Greece',
    dialInCode: 30,
    countryCode: 'GR'
  },
  {
    country: 'Greenland',
    dialInCode: 299,
    countryCode: 'GL'
  },
  {
    country: 'Grenada',
    dialInCode: 1,
    countryCode: 'GD'
  },
  {
    country: 'Guam',
    dialInCode: 1,
    countryCode: 'GU'
  },
  {
    country: 'Guatemala',
    dialInCode: 502,
    countryCode: 'GT'
  },
  {
    country: 'Guernsey',
    dialInCode: 44,
    countryCode: 'GG'
  },
  {
    country: 'Guinea',
    dialInCode: 224,
    countryCode: 'GN'
  },
  {
    country: 'Guinea-Bissau',
    dialInCode: 245,
    countryCode: 'GW'
  },
  {
    country: 'Guyana',
    dialInCode: 592,
    countryCode: 'GY'
  },
  {
    country: 'Haiti',
    dialInCode: 509,
    countryCode: 'HT'
  },
  {
    country: 'Honduras',
    dialInCode: 504,
    countryCode: 'HN'
  },
  {
    country: 'Hong Kong',
    dialInCode: 852,
    countryCode: 'HK'
  },
  {
    country: 'Hungary',
    dialInCode: 36,
    countryCode: 'HU'
  },
  {
    country: 'Iceland',
    dialInCode: 354,
    countryCode: 'IS'
  },
  {
    country: 'India',
    dialInCode: 91,
    countryCode: 'IN'
  },
  {
    country: 'Indonesia',
    dialInCode: 62,
    countryCode: 'ID'
  },
  {
    country: 'Iran',
    dialInCode: 98,
    countryCode: 'IR'
  },
  {
    country: 'Iraq',
    dialInCode: 964,
    countryCode: 'IQ'
  },
  {
    country: 'Ireland',
    dialInCode: 353,
    countryCode: 'IE'
  },
  {
    country: 'Isle of Man',
    dialInCode: 44,
    countryCode: 'IM'
  },
  {
    country: 'Israel',
    dialInCode: 972,
    countryCode: 'IL'
  },
  {
    country: 'Italy',
    dialInCode: 39,
    countryCode: 'IT'
  },
  {
    country: 'Ivory Coast',
    dialInCode: 225,
    countryCode: 'CI'
  },
  {
    country: 'Jamaica',
    dialInCode: 1,
    countryCode: 'JM'
  },
  {
    country: 'Japan',
    dialInCode: 81,
    countryCode: 'JP'
  },
  {
    country: 'Jersey',
    dialInCode: 44,
    countryCode: 'JE'
  },
  {
    country: 'Jordan',
    dialInCode: 962,
    countryCode: 'JO'
  },
  {
    country: 'Kazakhstan',
    dialInCode: 7,
    countryCode: 'KZ'
  },
  {
    country: 'Kenya',
    dialInCode: 254,
    countryCode: 'KE'
  },
  {
    country: 'Kiribati',
    dialInCode: 686,
    countryCode: 'KI'
  },
  {
    country: 'Kosovo',
    dialInCode: 383,
    countryCode: 'XK'
  },
  {
    country: 'Kuwait',
    dialInCode: 965,
    countryCode: 'KW'
  },
  {
    country: 'Kyrgyzstan',
    dialInCode: 996,
    countryCode: 'KG'
  },
  {
    country: 'Laos',
    dialInCode: 856,
    countryCode: 'LA'
  },
  {
    country: 'Latvia',
    dialInCode: 371,
    countryCode: 'LV'
  },
  {
    country: 'Lebanon',
    dialInCode: 961,
    countryCode: 'LB'
  },
  {
    country: 'Lesotho',
    dialInCode: 266,
    countryCode: 'LS'
  },
  {
    country: 'Liberia',
    dialInCode: 231,
    countryCode: 'LR'
  },
  {
    country: 'Libya',
    dialInCode: 218,
    countryCode: 'LY'
  },
  {
    country: 'Liechtenstein',
    dialInCode: 423,
    countryCode: 'LI'
  },
  {
    country: 'Lithuania',
    dialInCode: 370,
    countryCode: 'LT'
  },
  {
    country: 'Luxembourg',
    dialInCode: 352,
    countryCode: 'LU'
  },
  {
    country: 'Macau',
    dialInCode: 853,
    countryCode: 'MO'
  },
  {
    country: 'Macedonia',
    dialInCode: 389,
    countryCode: 'MK'
  },
  {
    country: 'Madagascar',
    dialInCode: 261,
    countryCode: 'MG'
  },
  {
    country: 'Malawi',
    dialInCode: 265,
    countryCode: 'MW'
  },
  {
    country: 'Malaysia',
    dialInCode: 60,
    countryCode: 'MY'
  },
  {
    country: 'Maldives',
    dialInCode: 960,
    countryCode: 'MV'
  },
  {
    country: 'Mali',
    dialInCode: 223,
    countryCode: 'ML'
  },
  {
    country: 'Malta',
    dialInCode: 356,
    countryCode: 'MT'
  },
  {
    country: 'Marshall Islands',
    dialInCode: 692,
    countryCode: 'MH'
  },
  {
    country: 'Mauritania',
    dialInCode: 222,
    countryCode: 'MR'
  },
  {
    country: 'Mauritius',
    dialInCode: 230,
    countryCode: 'MU'
  },
  {
    country: 'Mayotte',
    dialInCode: 262,
    countryCode: 'YT'
  },
  {
    country: 'Mexico',
    dialInCode: 52,
    countryCode: 'MX'
  },
  {
    country: 'Micronesia',
    dialInCode: 691,
    countryCode: 'FM'
  },
  {
    country: 'Moldova',
    dialInCode: 373,
    countryCode: 'MD'
  },
  {
    country: 'Monaco',
    dialInCode: 377,
    countryCode: 'MC'
  },
  {
    country: 'Mongolia',
    dialInCode: 976,
    countryCode: 'MN'
  },
  {
    country: 'Montenegro',
    dialInCode: 382,
    countryCode: 'ME'
  },
  {
    country: 'Montserrat',
    dialInCode: 1,
    countryCode: 'MS'
  },
  {
    country: 'Morocco',
    dialInCode: 212,
    countryCode: 'MA'
  },
  {
    country: 'Mozambique',
    dialInCode: 258,
    countryCode: 'MZ'
  },
  {
    country: 'Myanmar',
    dialInCode: 95,
    countryCode: 'MM'
  },
  {
    country: 'Namibia',
    dialInCode: 264,
    countryCode: 'NA'
  },
  {
    country: 'Nauru',
    dialInCode: 674,
    countryCode: 'NR'
  },
  {
    country: 'Nepal',
    dialInCode: 977,
    countryCode: 'NP'
  },
  {
    country: 'Netherlands',
    dialInCode: 31,
    countryCode: 'NL'
  },
  {
    country: 'Netherlands Antilles',
    dialInCode: 599,
    countryCode: 'AN'
  },
  {
    country: 'New Caledonia',
    dialInCode: 687,
    countryCode: 'NC'
  },
  {
    country: 'New Zealand',
    dialInCode: 64,
    countryCode: 'NZ'
  },
  {
    country: 'Nicaragua',
    dialInCode: 505,
    countryCode: 'NI'
  },
  {
    country: 'Niger',
    dialInCode: 227,
    countryCode: 'NE'
  },
  {
    country: 'Nigeria',
    dialInCode: 234,
    countryCode: 'NG'
  },
  {
    country: 'Niue',
    dialInCode: 683,
    countryCode: 'NU'
  },
  {
    country: 'North Korea',
    dialInCode: 850,
    countryCode: 'KP'
  },
  {
    country: 'Northern Mariana Islands',
    dialInCode: 1,
    countryCode: 'MP'
  },
  {
    country: 'Norway',
    dialInCode: 47,
    countryCode: 'NO'
  },
  {
    country: 'Oman',
    dialInCode: 968,
    countryCode: 'OM'
  },
  {
    country: 'Pakistan',
    dialInCode: 92,
    countryCode: 'PK'
  },
  {
    country: 'Palau',
    dialInCode: 680,
    countryCode: 'PW'
  },
  {
    country: 'Palestine',
    dialInCode: 970,
    countryCode: 'PS'
  },
  {
    country: 'Panama',
    dialInCode: 507,
    countryCode: 'PA'
  },
  {
    country: 'Papua New Guinea',
    dialInCode: 675,
    countryCode: 'PG'
  },
  {
    country: 'Paraguay',
    dialInCode: 595,
    countryCode: 'PY'
  },
  {
    country: 'Peru',
    dialInCode: 51,
    countryCode: 'PE'
  },
  {
    country: 'Philippines',
    dialInCode: 63,
    countryCode: 'PH'
  },
  {
    country: 'Pitcairn',
    dialInCode: 64,
    countryCode: 'PN'
  },
  {
    country: 'Poland',
    dialInCode: 48,
    countryCode: 'PL'
  },
  {
    country: 'Portugal',
    dialInCode: 351,
    countryCode: 'PT'
  },
  {
    country: 'Puerto Rico',
    dialInCode: 1,
    countryCode: 'PR'
  },
  {
    country: 'Qatar',
    dialInCode: 974,
    countryCode: 'QA'
  },
  {
    country: 'Republic of the Congo',
    dialInCode: 242,
    countryCode: 'CG'
  },
  {
    country: 'Reunion',
    dialInCode: 262,
    countryCode: 'RE'
  },
  {
    country: 'Romania',
    dialInCode: 40,
    countryCode: 'RO'
  },
  {
    country: 'Russia',
    dialInCode: 7,
    countryCode: 'RU'
  },
  {
    country: 'Rwanda',
    dialInCode: 250,
    countryCode: 'RW'
  },
  {
    country: 'Saint Barthelemy',
    dialInCode: 590,
    countryCode: 'BL'
  },
  {
    country: 'Saint Helena',
    dialInCode: 290,
    countryCode: 'SH'
  },
  {
    country: 'Saint Kitts and Nevis',
    dialInCode: 1,
    countryCode: 'KN'
  },
  {
    country: 'Saint Lucia',
    dialInCode: 1,
    countryCode: 'LC'
  },
  {
    country: 'Saint Martin',
    dialInCode: 590,
    countryCode: 'MF'
  },
  {
    country: 'Saint Pierre and Miquelon',
    dialInCode: 508,
    countryCode: 'PM'
  },
  {
    country: 'Saint Vincent and the Grenadines',
    dialInCode: 1,
    countryCode: 'VC'
  },
  {
    country: 'Samoa',
    dialInCode: 685,
    countryCode: 'WS'
  },
  {
    country: 'San Marino',
    dialInCode: 378,
    countryCode: 'SM'
  },
  {
    country: 'Sao Tome and Principe',
    dialInCode: 239,
    countryCode: 'ST'
  },
  {
    country: 'Saudi Arabia',
    dialInCode: 966,
    countryCode: 'SA'
  },
  {
    country: 'Senegal',
    dialInCode: 221,
    countryCode: 'SN'
  },
  {
    country: 'Serbia',
    dialInCode: 381,
    countryCode: 'RS'
  },
  {
    country: 'Seychelles',
    dialInCode: 248,
    countryCode: 'SC'
  },
  {
    country: 'Sierra Leone',
    dialInCode: 232,
    countryCode: 'SL'
  },
  {
    country: 'Singapore',
    dialInCode: 65,
    countryCode: 'SG'
  },
  {
    country: 'Sint Maarten',
    dialInCode: 1,
    countryCode: 'SX'
  },
  {
    country: 'Slovakia',
    dialInCode: 421,
    countryCode: 'SK'
  },
  {
    country: 'Slovenia',
    dialInCode: 386,
    countryCode: 'SI'
  },
  {
    country: 'Solomon Islands',
    dialInCode: 677,
    countryCode: 'SB'
  },
  {
    country: 'Somalia',
    dialInCode: 252,
    countryCode: 'SO'
  },
  {
    country: 'South Africa',
    dialInCode: 27,
    countryCode: 'ZA'
  },
  {
    country: 'South Korea',
    dialInCode: 82,
    countryCode: 'KR'
  },
  {
    country: 'South Sudan',
    dialInCode: 211,
    countryCode: 'SS'
  },
  {
    country: 'Spain',
    dialInCode: 34,
    countryCode: 'ES'
  },
  {
    country: 'Sri Lanka',
    dialInCode: 94,
    countryCode: 'LK'
  },
  {
    country: 'Sudan',
    dialInCode: 249,
    countryCode: 'SD'
  },
  {
    country: 'Suriname',
    dialInCode: 597,
    countryCode: 'SR'
  },
  {
    country: 'Svalbard and Jan Mayen',
    dialInCode: 47,
    countryCode: 'SJ'
  },
  {
    country: 'Swaziland',
    dialInCode: 268,
    countryCode: 'SZ'
  },
  {
    country: 'Sweden',
    dialInCode: 46,
    countryCode: 'SE'
  },
  {
    country: 'Switzerland',
    dialInCode: 41,
    countryCode: 'CH'
  },
  {
    country: 'Syria',
    dialInCode: 963,
    countryCode: 'SY'
  },
  {
    country: 'Taiwan',
    dialInCode: 886,
    countryCode: 'TW'
  },
  {
    country: 'Tajikistan',
    dialInCode: 992,
    countryCode: 'TJ'
  },
  {
    country: 'Tanzania',
    dialInCode: 255,
    countryCode: 'TZ'
  },
  {
    country: 'Thailand',
    dialInCode: 66,
    countryCode: 'TH'
  },
  {
    country: 'Togo',
    dialInCode: 228,
    countryCode: 'TG'
  },
  {
    country: 'Tokelau',
    dialInCode: 690,
    countryCode: 'TK'
  },
  {
    country: 'Tonga',
    dialInCode: 676,
    countryCode: 'TO'
  },
  {
    country: 'Trinidad and Tobago',
    dialInCode: 1,
    countryCode: 'TT'
  },
  {
    country: 'Tunisia',
    dialInCode: 216,
    countryCode: 'TN'
  },
  {
    country: 'Turkey',
    dialInCode: 90,
    countryCode: 'TR'
  },
  {
    country: 'Turkmenistan',
    dialInCode: 993,
    countryCode: 'TM'
  },
  {
    country: 'Turks and Caicos Islands',
    dialInCode: 1,
    countryCode: 'TC'
  },
  {
    country: 'Tuvalu',
    dialInCode: 688,
    countryCode: 'TV'
  },
  {
    country: 'U.S. Virgin Islands',
    dialInCode: 1,
    countryCode: 'VI'
  },
  {
    country: 'Uganda',
    dialInCode: 256,
    countryCode: 'UG'
  },
  {
    country: 'Ukraine',
    dialInCode: 380,
    countryCode: 'UA'
  },
  {
    country: 'United Arab Emirates',
    dialInCode: 971,
    countryCode: 'AE'
  },
  {
    country: 'United Kingdom',
    dialInCode: 44,
    countryCode: 'GB'
  },
  {
    country: 'United States',
    dialInCode: 1,
    countryCode: 'US'
  },
  {
    country: 'Uruguay',
    dialInCode: 598,
    countryCode: 'UY'
  },
  {
    country: 'Uzbekistan',
    dialInCode: 998,
    countryCode: 'UZ'
  },
  {
    country: 'Vanuatu',
    dialInCode: 678,
    countryCode: 'VU'
  },
  {
    country: 'Vatican',
    dialInCode: 379,
    countryCode: 'VA'
  },
  {
    country: 'Venezuela',
    dialInCode: 58,
    countryCode: 'VE'
  },
  {
    country: 'Vietnam',
    dialInCode: 84,
    countryCode: 'VN'
  },
  {
    country: 'Wallis and Futuna',
    dialInCode: 681,
    countryCode: 'WF'
  },
  {
    country: 'Western Sahara',
    dialInCode: 212,
    countryCode: 'EH'
  },
  {
    country: 'Yemen',
    dialInCode: 967,
    countryCode: 'YE'
  },
  {
    country: 'Zambia',
    dialInCode: 260,
    countryCode: 'ZM'
  },
  {
    country: 'Zimbabwe',
    dialInCode: 263,
    countryCode: 'ZW'
  }
]

export function getCountryNameByCountryCode(countryCode: string): string | undefined {
  return smsCountries.find((country) => country.countryCode === countryCode)?.country
}

export function getDialInCodeByCountryCode(countryCode: string): string {
  return `+${smsCountries.find((country) => country.countryCode === countryCode)?.dialInCode}`
}
