import React from 'react'
import cn from 'classnames'

import styles from './removable-element.component.scss'

export interface RemovableElementProps {
  onRemove: VoidFunction
  visible?: boolean
  dataTestId?: string
  children: React.ReactNode
}

export function RemovableElement(props: RemovableElementProps) {
  const buttonStyles = cn({
    [styles.remove]: true,
    [styles.visible]: props.visible
  })

  return (
    <div className={styles.container}>
      <div className={buttonStyles} onClick={props.onRemove} data-testid={props.dataTestId}>
        ×
      </div>
      {props.children}
    </div>
  )
}
