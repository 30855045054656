import { SMSSettingsService } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useLeasablePhoneNumbersCount(): UseResource<SMSSettingsService.GetLeasablePhoneNumbersCountResponse_LeasablePhoneNumbersCount> {
  const queryName = [QUERY_NAME.leasablePhoneNumbersCount]

  const { data, isError, isLoading } =
    useQuery<SMSSettingsService.GetLeasablePhoneNumbersCountResponse>(
      queryName,
      api.getLeasablePhoneNumbersCount
    )

  return {
    data: data?.data,
    isError,
    isLoading
  }
}
