import cn from 'classnames'
import React from 'react'
import { ReactComponent as BulbIcon } from '../../../../static/images/svg/bulb.svg'
import './hint.style.scss'

interface HintProps {
  className?: string
  children: React.ReactNode
}

export function Hint(props: HintProps) {
  return (
    <div className={cn('hint', props.className)}>
      <div className='hint__icon'>
        <BulbIcon viewBox='0 0 467.977 467.977' />
      </div>
      <div className='hint__text'>{props.children}</div>
    </div>
  )
}
