import axios from 'axios'
import axiosRetry from 'axios-retry'
import { config as globalConfig } from '../config'

export const axiosInstance = axios.create({
  baseURL: globalConfig.SITES_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 60000
})

axiosInstance.interceptors.request.use((config) => {
  const authToken = localStorage.getItem('x-auth-token')
  if (authToken) {
    config.headers['X-Auth-Token'] = authToken
  }
  config.headers['App-Version'] = globalConfig.APP_VERSION
  return config
})

axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
