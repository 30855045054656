import { Popover as AntPopover } from 'antd'
import { PopoverProps as AntPopoverProps } from 'antd/lib/popover'
import cn from 'classnames'
import React from 'react'
import { Tooltip } from '../tooltip/tooltip.component'
import styles from './popover.component.scss'

export interface PopoverItem {
  icon?: JSX.Element
  title: string
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  dataTestid?: string
  disabled?: boolean
  disabledTooltipText?: string
}

export interface PopoverProps extends AntPopoverProps {
  items: PopoverItem[]
}

export function Popover({ items, children, ...restProps }: PopoverProps) {
  const content = items?.map(
    ({ icon, title, onClick, dataTestid, disabled, disabledTooltipText }) =>
      disabled ? (
        <Tooltip key={`${title}-disabled`} placement='left' title={disabledTooltipText}>
          <div
            className={cn(styles.row, styles.disabled)}
            onClick={(e) => e.stopPropagation()}
            data-testid={dataTestid}
          >
            {icon}
            <span>{title}</span>
          </div>
        </Tooltip>
      ) : (
        <div key={title} className={styles.row} onClick={onClick} data-testid={dataTestid}>
          {icon}
          <span>{title}</span>
        </div>
      )
  )

  return (
    <AntPopover
      content={content}
      overlayClassName={styles.tooltip}
      arrowPointAtCenter
      trigger='click'
      align={{
        offset: [-6]
      }}
      {...restProps}
    >
      {children}
    </AntPopover>
  )
}
