// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gEFwzOmkaljZHmP3Rzjh .ant-radio-wrapper{font-size:var(--recart-font-size-sm)}.sY283Efm8omy8aTUatgA{margin-left:var(--recart-spacing-1)}.sY283Efm8omy8aTUatgA svg{display:block}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/labelled-radio-group/labelled-radio-group.component.scss"],"names":[],"mappings":"AAGM,yCACE,oCAAA,CAKN,sBACE,mCAAA,CAEA,0BACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `gEFwzOmkaljZHmP3Rzjh`,
	"tooltipIcon": `sY283Efm8omy8aTUatgA`
};
export default ___CSS_LOADER_EXPORT___;
