import { Middleware } from '@reduxjs/toolkit'
import { draftMiddleware as sequenceEditorDraftMiddleware } from '../routes/SequenceEditor/middlewares/draft/draft.middleware'
import { editorLifecycleMiddleware } from '../routes/SequenceEditor/middlewares/editor-lifecycle.middleware'
import { optinToolsMiddleware } from '../routes/SequenceEditor/middlewares/optin-in-tools.middleware'
import { updateDiscountCodesOnSequenceItemMiddleware } from '../routes/SequenceEditor/middlewares/update-discount-codes.middleware'
import { updateBlastTargetRulesMiddleware } from '../routes/SequenceEditor/middlewares/utils/update-blast-target-rules.middleware'
import { validateBlastMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-blast.middleware'
import { validateButtonMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-button.middleware'
import { validateMessageItemMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-message-item.middleware'
import { validateMessageMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-message.middleware'
import { validateQuickReplyMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-quick-reply.middleware'
import { validateSequenceItemMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-sequence-item.middleware'
import { validateSequenceOnLoadMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-sequence-on-load.middleware'
import { validateSequenceMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-sequence.middleware'
import { validateSplitMiddleware } from '../routes/SequenceEditor/middlewares/validation/validate-split.middleware'
import { draftMiddleware as flowEditorDraftMiddleware } from '../routes/flow-editor/middlewares/draft.middleware'
import { updateDiscountCodesOnFlowItemMiddleware } from '../routes/flow-editor/middlewares/update-discount-codes.middleware'
import { updateKeywordRepliesOnFlowItemMiddleware } from '../routes/flow-editor/middlewares/update-keyword-replies.middleware'
import { updateKeywordReplyTagOnFlowMiddleware } from '../routes/flow-editor/middlewares/update-keyword-reply-tag-on-flow.middleware'
import { updateSMSCampaignTargetRulesMiddleware } from '../routes/flow-editor/middlewares/update-sms-campaign-target-rules.middleware'
import { validateMiddleware as flowEditorValidateMiddleware } from '../routes/flow-editor/middlewares/validate.middleware'
import { crashReporter } from './middlewares/crash-reporter.middleware'
import { detectAuthChangeMiddleware } from './middlewares/detect-auth-change.middleware'
import { optinToolEditorFontsMiddleware } from './slices/optin-tool-editor/middlewares/optin-tool-editor-fonts.middleware'
import { updateQuietHoursInfoMiddleware } from '../routes/flow-editor/middlewares/update-quiet-hours-info.middleware'

export const defaultMiddlewares: Middleware[] = [
  crashReporter,
  detectAuthChangeMiddleware,
  sequenceEditorDraftMiddleware,
  optinToolEditorFontsMiddleware,

  // FlowEditor
  flowEditorDraftMiddleware,
  flowEditorValidateMiddleware,
  updateSMSCampaignTargetRulesMiddleware,
  updateQuietHoursInfoMiddleware,

  editorLifecycleMiddleware,
  updateDiscountCodesOnSequenceItemMiddleware,
  validateBlastMiddleware,
  validateButtonMiddleware,
  validateMessageItemMiddleware,
  validateMessageMiddleware,
  validateQuickReplyMiddleware,
  validateSequenceItemMiddleware,
  validateSequenceMiddleware,
  validateSequenceOnLoadMiddleware,
  updateBlastTargetRulesMiddleware,
  validateSplitMiddleware,
  optinToolsMiddleware,
  updateDiscountCodesOnFlowItemMiddleware,
  updateKeywordRepliesOnFlowItemMiddleware,
  updateKeywordReplyTagOnFlowMiddleware
]
