import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { feautureFlags } from '../../../utils/feature-flags'
import { getLaunchDarklyClient } from '../../../utils/launchdarkly'
import { DashboardState } from '../../dashboard.state'
import { selectSite, selectUser } from '../../selectors'
import { mergeLaunchDarklyFlags } from './app.actions'

let initPromise = null
export const initLaunchDarklyClient = createAsyncThunk(
  'app/initLaunchDarklyClient',
  async (args, store: any) => {
    const dispatch = store.dispatch
    const state = store.getState() as DashboardState

    if (initPromise !== null) {
      return initPromise
    }

    initPromise = new Promise(async (resolve, reject) => {
      const user = selectUser(state)
      const site = selectSite(state)
      const client = await getLaunchDarklyClient(user, site)
      dispatch(watchLaunchDarklyChanges(client))
      const flags = client.allFlags()
      Object.assign(feautureFlags, flags)
      if (Object.keys(flags).length === 0) {
        reject(new Error('Cannot initialize LaunchDarkly'))
      } else {
        resolve(flags)
      }
    })

    return initPromise
  }
)

function watchLaunchDarklyChanges(launchDarklyClient: LDClient) {
  return (dispatch: Dispatch) => {
    // @ts-expect-error
    if (window.Cypress || navigator.webdriver) {
      return
    }

    launchDarklyClient.on('change', (settings) => {
      const flags = {}
      Object.keys(settings).forEach((settingKey) => {
        flags[settingKey] = settings[settingKey].current
      })
      dispatch(mergeLaunchDarklyFlags(flags))
    })
  }
}
