import { Store } from '@reduxjs/toolkit'

let store: Store<any, any> | null = null

export function getGlobalStore(): Store<any, any> {
  if (store === null) {
    throw new Error('Global store is not set')
  }
  return store
}

export function setGlobalStore(_store: Store<any, any>): void {
  store = _store
}
