import { PopoverProps } from 'antd'
import React from 'react'
import { EditorButton } from '../../../../components/ui-kit/editor-button/editor-button.component'
import { hooks } from '../../../../hooks/hooks'
import { useDiscountCode } from '../../../../hooks/resources/use-discount-code'
import { ReactComponent as AlertIcon } from '../../../../static/ui-kit/icons/small/alert.svg'
import styles from './popover-discount-code-expired.component.scss'

interface PopoverDiscountCodeExpiredProps extends PopoverProps {
  discountCodeName: string
  onRemoveDiscountCode: (discountCodeName: string) => void
}

export function PopoverDiscountCodeExpired(props: PopoverDiscountCodeExpiredProps) {
  const { data: me } = hooks.useMe()
  const { data: discountCode, isLoading } = useDiscountCode(props.discountCodeName)

  if (props.discountCodeName === undefined) {
    return null
  }

  if (!isLoading && !discountCode) {
    throw new Error(`Expired discount code popover shouldn't be rendered here`)
  }

  function handleRemoveDiscountCodeClick() {
    props.onRemoveDiscountCode(props.discountCodeName)
  }

  function handleOpenShopifyClicked() {
    window.open(`${me?.site.domain}/admin/discounts/${discountCode?.priceRuleId}`, '_blank')
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className='flex items-center justify-between mt-1 mb-3'>
          <h2 className='p-0 text-sm font-bold'>Expired discount code</h2>
          <div className='icon-xs ml-1'>
            <AlertIcon />
          </div>
        </div>
        <p className='text-tiny mb-3'>
          Your discount code and destination URL expired. Please edit in Shopify and refresh the
          page, or add a new one to your flow.
        </p>
      </div>

      <EditorButton
        loading={isLoading}
        type='primary'
        className='w-full pt-1'
        onClick={handleRemoveDiscountCodeClick}
      >
        Remove discount code
      </EditorButton>
      <EditorButton
        loading={isLoading}
        type='link'
        onClick={handleOpenShopifyClicked}
        className='w-full mt-2'
      >
        Edit in Shopify
      </EditorButton>
    </div>
  )
}
