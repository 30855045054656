import { combineReducers } from '@reduxjs/toolkit'
import { appReducer } from './slices/app/app.reducer'
import { facebookReducer } from './slices/facebook/facebook.reducer'
import { flowEditorSlice } from './slices/flow-editor/flow-editor.reducer'
import { integrationsReducer } from './slices/integrations/integrations.reducer'
import { locationReducer } from './slices/location/location.reducer'
import { meReducer } from './slices/me/me.reducer'
import { modalReducer } from './slices/modal/modal.reducer'
import { notificationReducer } from './slices/notification/notification.reducer'
import { onboardingReducer } from './slices/onboarding/onboarding.reducer'
import { optinToolEditorReducer } from './slices/optin-tool-editor/optin-tool-editor.reducer'
import { sequenceEditorReducer } from './slices/sequence-editor/sequence-editor.reducer'

export function makeRootReducer() {
  const baseReducers = {
    app: appReducer,
    facebook: facebookReducer,
    integrations: integrationsReducer,
    location: locationReducer,
    me: meReducer,
    modal: modalReducer,
    notification: notificationReducer,
    onboarding: onboardingReducer,
    optinToolEditor: optinToolEditorReducer,
    flowEditor: flowEditorSlice.reducer,
    sequenceEditor: sequenceEditorReducer
  }

  return combineReducers(baseReducers)
}
