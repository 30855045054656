// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U65cZ7bmtPO877OFjdrv{background:#1890ff;border:0;color:#fff;font-size:16px;height:40px}.U65cZ7bmtPO877OFjdrv:hover{background:#2b92ff;color:#fff}.U65cZ7bmtPO877OFjdrv:disabled{background:#1890ff!important;color:#fff!important;opacity:.3}.RrIYDd4QLrSn2fwk8Tu8{border:1px solid #d9d9d9;color:var(--recart-grey-8);font-size:16px;height:40px;margin-right:16px}.RrIYDd4QLrSn2fwk8Tu8:hover{border-color:#2b92ff;color:#2b92ff}.RrIYDd4QLrSn2fwk8Tu8:disabled{color:#d9d9d9}.VUbWZA_P7X3CTofqxe4w{color:#1890ff;font-size:16px;height:40px;margin-right:16px}.VUbWZA_P7X3CTofqxe4w svg path{stroke:#1890ff}.VUbWZA_P7X3CTofqxe4w:hover{background:#e6f7ff;color:#40a9ff}.VUbWZA_P7X3CTofqxe4w:hover svg path{stroke:#40a9ff}.qD4zvUpquYmOgg2Gef67{color:#8c8c8c;padding-left:0;padding-right:0}.qD4zvUpquYmOgg2Gef67 span{text-decoration:underline}.qD4zvUpquYmOgg2Gef67:hover{color:#1890ff}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/editor-button/editor-button.component.scss"],"names":[],"mappings":"AACE,sBAEE,kBAAA,CAGA,QAAA,CAJA,UAAA,CAGA,cAAA,CADA,WAEA,CAEA,4BAEE,kBAAA,CADA,UACA,CAGF,+BAEE,4BAAA,CADA,oBAAA,CAEA,UAAA,CAIJ,sBAEE,wBAAA,CADA,0BAAA,CAGA,cAAA,CADA,WAAA,CAEA,iBAAA,CAEA,4BAEE,oBAAA,CADA,aACA,CAGF,+BACE,aAAA,CAIJ,sBACE,aAAA,CAGA,cAAA,CADA,WAAA,CADA,iBAEA,CAEA,+BACE,cAAA,CAGF,4BAEE,kBAAA,CADA,aACA,CAEA,qCACE,cAAA,CAKN,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CAEA,2BACE,yBAAA,CAGF,4BACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": `U65cZ7bmtPO877OFjdrv`,
	"default": `RrIYDd4QLrSn2fwk8Tu8`,
	"text": `VUbWZA_P7X3CTofqxe4w`,
	"link": `qD4zvUpquYmOgg2Gef67`
};
export default ___CSS_LOADER_EXPORT___;
