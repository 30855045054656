import {
  ButtonActionType,
  MessengerButtonTemplatePayload,
  MessengerMessageSequenceItemUI,
  NodeType,
  PortType,
  SequenceItemType,
  SequenceUI
} from '@ghostmonitor/recartapis'
import { nanoid } from '@reduxjs/toolkit'
import { ObjectId } from 'bson'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import range from 'lodash/range'
import { DiagramEngine, PortModel } from 'storm-react-diagrams'
import { createScope } from '../../../../../utils/logger/logger'
import { attachCanConvert } from '../../../middlewares/utils/attach-can-convert'
import { ButtonPortModel } from '../../port/button-port-model'
import { FollowUpPortModel } from '../../port/follow-up-port-model'
import { QuickReplyPortModel } from '../../port/quick-reply-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'
import { generateButtonParentId } from './generate-button-parent-id'
import { generateButtonPortName } from './generate-button-parent-name'

const { info } = createScope('sequence-editor')

interface PortPatch {
  name?: string
  parentId?: string
  messageIndex?: number
  buttonIndex?: number
  messageItemIndex?: number
}

export function getNewMessageSequenceItem(
  sequence: SequenceUI,
  siteId: string
): MessengerMessageSequenceItemUI {
  const sequenceItemId = new ObjectId().toHexString()
  let messageSequenceItem: MessengerMessageSequenceItemUI = {
    _id: sequenceItemId,
    type: SequenceItemType.FBMESSAGE,
    siteId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    channel: 'messenger',
    messages: [],
    quickReplies: [],
    isEnabled: false,
    tags: [],
    trigger: [sequenceItemId],
    canConvert: true
  }
  messageSequenceItem = attachCanConvert(sequence, messageSequenceItem)
  return messageSequenceItem
}

export class MessageSequenceItemNodeModel extends BaseSequenceItemNodeModel {
  constructor(sequenceItemId?: string, sequenceItem?: MessengerMessageSequenceItemUI, id?: string) {
    super(NodeType.MESSAGE_SEQUENCE_ITEM, sequenceItemId, id)
    if (sequenceItem !== undefined) {
      sequenceItem.quickReplies.forEach((quickReply) => {
        const quickReplyPortModel = new QuickReplyPortModel(quickReply.id)
        this.addPort(quickReplyPortModel)
      })
      this.generateButtonPorts(sequenceItem)
    }
  }

  public portOrder: string[] = []

  public addPort<T extends PortModel>(portModel: T): T {
    if (this.getInputPort() !== undefined && portModel.type === PortType.SEQUENCE_ITEM) {
      throw new Error("There can't be more than one input port.")
    }
    return super.addPort(portModel)
  }

  public removePort(port: PortModel): void {
    return super.removePort(port)
  }

  private createPortPatchTransaction() {
    const tempContext = new MessageSequenceItemNodeModel(this.sequenceItemId)
    tempContext.ports = cloneDeep(this.ports)

    const getPort: (name: string) => PortModel = tempContext.getPort.bind(tempContext)
    const renamePort: (oldPortName: string, newPortName: string) => void =
      tempContext.renamePort.bind(tempContext)
    const portNames: {
      [portName: string]: PortPatch
    } = {}
    return {
      patch: (portName: string, payload: PortPatch) => {
        if (payload.name) {
          const tempName = nanoid()
          renamePort(portName, tempName)
          portNames[tempName] = payload
        } else {
          portNames[portName] = payload
        }
      },
      apply: () => {
        Object.keys(portNames).forEach((portName) => {
          const payload = portNames[portName]
          const { name, ...portPatch } = payload
          const port = getPort(portName)
          merge(port, portPatch)
          if (name) {
            renamePort(portName, name)
          }
        })
        this.ports = tempContext.ports
      }
    }
  }

  public renamePort(oldPortName: string, newPortName: string) {
    const port = this.getPort(oldPortName)
    this.removePort(port)
    port.name = newPortName
    this.addPort(port)
  }

  public reorderMessage(oldMessageIndex: number, newMessageIndex: number) {
    const buttonPorts = this.getButtonPorts()
    const biggerMessageIndex = Math.max(oldMessageIndex, newMessageIndex)
    const messageIndexes = range(0, biggerMessageIndex + 1)
    messageIndexes.splice(oldMessageIndex, 1)
    messageIndexes.splice(newMessageIndex, 0, oldMessageIndex)

    const { patch, apply } = this.createPortPatchTransaction()

    messageIndexes.forEach((oldIndex, newIndex) => {
      if (oldIndex !== newIndex) {
        buttonPorts.forEach((buttonPort) => {
          if (buttonPort.messageIndex === oldIndex) {
            patch(buttonPort.name, {
              parentId: generateButtonParentId(
                this.sequenceItemId,
                newIndex,
                buttonPort.messageItemIndex
              ),
              messageIndex: newIndex,
              name: generateButtonPortName(
                this.sequenceItemId,
                newIndex,
                buttonPort.buttonIndex,
                buttonPort.messageItemIndex
              )
            })
          }
        })
      }
    })

    apply()
  }

  public removeMessage(messageIndex: number) {
    const { patch, apply } = this.createPortPatchTransaction()

    const buttonPorts = this.getButtonPorts()
    buttonPorts.forEach((buttonPort) => {
      // Ports that belongs to messages that were after the delete message
      if (buttonPort.messageIndex > messageIndex) {
        const newMessageIndex = buttonPort.messageIndex - 1
        patch(buttonPort.name, {
          parentId: generateButtonParentId(
            this.sequenceItemId,
            newMessageIndex,
            buttonPort.messageItemIndex
          ),
          messageIndex: newMessageIndex,
          name: generateButtonPortName(
            this.sequenceItemId,
            newMessageIndex,
            buttonPort.buttonIndex,
            buttonPort.messageItemIndex
          )
        })
      }
    })

    apply()
  }

  public changePortMessageIndex(
    sequenceItemId: string,
    oldMessageIndex: number,
    newMessageIndex: number
  ) {
    Object.values<ButtonPortModel>(this.ports as any)
      .filter((port) => {
        return (
          port.type === PortType.BUTTON &&
          port.name.indexOf(`${sequenceItemId}-${oldMessageIndex}`) === 0
        )
      })
      .forEach((port) => {
        this.renamePort(
          port.name,
          port.name.replace(
            `${sequenceItemId}-${oldMessageIndex}`,
            `${sequenceItemId}-${newMessageIndex}`
          )
        )
        port.parentId = `${sequenceItemId}-${newMessageIndex}`
      })
  }

  public changePortMessageItemIndex(
    sequenceItemId: string,
    messageIndex: string,
    oldMessageItemIndex: string,
    newMessageItemIndex: string
  ) {
    Object.values<ButtonPortModel>(this.ports as any)
      .filter((port) => {
        return (
          port.type === PortType.BUTTON &&
          port.name.indexOf(`${sequenceItemId}-${messageIndex}-${oldMessageItemIndex}`) === 0
        )
      })
      .forEach((port) => {
        this.renamePort(
          port.name,
          port.name.replace(
            `${sequenceItemId}-${messageIndex}-${oldMessageItemIndex}`,
            `${sequenceItemId}-${messageIndex}-${newMessageItemIndex}`
          )
        )
        port.parentId = `${sequenceItemId}-${messageIndex}-${newMessageItemIndex}`
      })
  }

  public serialize() {
    return merge(super.serialize(), {
      portOrder: this.portOrder
    })
  }

  public deSerialize(serializedDiagram, engine: DiagramEngine) {
    super.deSerialize(serializedDiagram, engine)
    this.portOrder = serializedDiagram.portOrder
  }

  public generateButtonPortName(
    sequenceItemId: string,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ): string {
    return generateButtonPortName(sequenceItemId, messageIndex, buttonIndex, messageItemIndex)
  }

  public getButtonPort(
    sequenceItemId: string,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ): ButtonPortModel {
    return this.getPort(
      generateButtonPortName(sequenceItemId, messageIndex, buttonIndex, messageItemIndex)
    ) as ButtonPortModel
  }

  public getButtonPorts(): ButtonPortModel[] {
    return Object.values<ButtonPortModel>(this.ports as any).filter((port) => {
      return port.type === PortType.BUTTON
    })
  }

  public getButtonPortsByMessage(
    sequenceItemId: string,
    messageIndex: number,
    messageItemIndex?: number
  ): ButtonPortModel[] {
    const parentId = generateButtonParentId(sequenceItemId, messageIndex, messageItemIndex)
    return this.getButtonPorts().filter((port) => {
      return port.parentId === parentId
    })
  }

  public remove() {
    super.remove()
    this.iterateListeners((listener: any) => {
      if (listener.removeNode) {
        listener.removeNode(this.sequenceItemId)
      }
    })
  }

  public pushButtonsFromIndex(
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) {
    const buttonPorts = this.getButtonPortsByMessage(
      this.sequenceItemId,
      messageIndex,
      messageItemIndex
    )

    const { patch, apply } = this.createPortPatchTransaction()

    buttonPorts.forEach((port) => {
      if (port.buttonIndex >= buttonIndex) {
        patch(port.name, {
          name: this.generateButtonPortName(
            this.sequenceItemId,
            messageIndex,
            port.buttonIndex + 1,
            messageItemIndex
          ),
          buttonIndex: port.buttonIndex + 1
        })
      }
    })

    apply()
  }

  public pullButtonsFromIndex(
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) {
    const buttonPorts = this.getButtonPortsByMessage(
      this.sequenceItemId,
      messageIndex,
      messageItemIndex
    )

    const { patch, apply } = this.createPortPatchTransaction()

    buttonPorts.forEach((port) => {
      if (port.buttonIndex > buttonIndex) {
        patch(port.name, {
          name: this.generateButtonPortName(
            this.sequenceItemId,
            messageIndex,
            port.buttonIndex - 1,
            messageItemIndex
          ),
          buttonIndex: port.buttonIndex - 1
        })
      }
    })

    apply()
  }

  public createButton(
    buttonType: ButtonActionType,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) {
    this.pushButtonsFromIndex(messageIndex, buttonIndex, messageItemIndex)
    if (buttonType === ButtonActionType.postback) {
      this.createButtonPort(messageIndex, buttonIndex, messageItemIndex)
    }
  }

  public createButtonPort(messageIndex: number, buttonIndex: number, messageItemIndex?: number) {
    this.addPort(
      new ButtonPortModel(this.sequenceItemId, messageIndex, buttonIndex, messageItemIndex)
    )
  }

  public removeButton(
    buttonType: ButtonActionType,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) {
    if (buttonType === ButtonActionType.postback) {
      this.removeButtonPort(messageIndex, buttonIndex, messageItemIndex)
    }
    this.pullButtonsFromIndex(messageIndex, buttonIndex, messageItemIndex)
  }

  public removeButtonPort(messageIndex: number, buttonIndex: number, messageItemIndex?: number) {
    const buttonPortToDelete: ButtonPortModel = this.getButtonPort(
      this.sequenceItemId,
      messageIndex,
      buttonIndex,
      messageItemIndex
    )

    if (!buttonPortToDelete) {
      info('removeButtonPort:0')
      return
    }

    Object.values(buttonPortToDelete.getLinks()).forEach((link) => {
      link.remove()
    })
    this.removePort(buttonPortToDelete)
    buttonPortToDelete.remove()
  }

  public createQuickReply(name: string) {
    const quickReplyPort = new QuickReplyPortModel(name)
    this.addPort(quickReplyPort)
  }

  public removeQuickReply(name: string) {
    const quickReplyPortToDelete = this.getPort(name) as QuickReplyPortModel
    Object.values(quickReplyPortToDelete.getLinks()).forEach((link) => {
      link.remove()
    })
    this.removePort(quickReplyPortToDelete)
    quickReplyPortToDelete.remove()
  }

  public getQuickReplyPort(portName: string): QuickReplyPortModel {
    return this.getPort(portName) as QuickReplyPortModel
  }

  public getFollowUpPort(): FollowUpPortModel {
    return this.getPortsByType(PortType.FOLLOW_UP)[0] as FollowUpPortModel
  }

  public getFollowUpPortTargetPortId(): string {
    const followUpPort = this.getFollowUpPort()
    if (followUpPort) {
      const targetPort = this.getConnectedPorts(followUpPort)[0]

      return targetPort?.id
    }
  }

  public removeFollowUpPort(): void {
    const followUpPort = this.getFollowUpPort()
    Object.values(followUpPort.getLinks()).forEach((link) => {
      link.remove()
    })
    this.removePort(followUpPort)
    followUpPort.remove()
  }

  public removeUnusedPorts(sequenceItem: MessengerMessageSequenceItemUI): boolean {
    let unusedPortFound = false
    Object.values(this.getPorts())
      .filter((port) => port.type === PortType.QUICK_REPLY)
      .forEach((port) => {
        if (!sequenceItem.quickReplies.some((quickReply) => quickReply.id === port.name)) {
          this.removePort(port)
          unusedPortFound = true
        }
      })
    return unusedPortFound
  }

  public generateButtonPorts(sequenceItem: MessengerMessageSequenceItemUI) {
    sequenceItem.messages.forEach((message, messageIndex) => {
      if (message.attachmentPayload && message.attachmentPayload.buttons) {
        message.attachmentPayload.buttons.forEach((button, buttonIndex) =>
          this.addPort(new ButtonPortModel(sequenceItem._id, messageIndex, buttonIndex))
        )
      }
      if (message.messengerTemplatePayload && !Array.isArray(message.messengerTemplatePayload)) {
        (message.messengerTemplatePayload as MessengerButtonTemplatePayload).buttons.forEach(
          (button, buttonIdx) =>
            this.addPort(new ButtonPortModel(sequenceItem._id, messageIndex, buttonIdx))
        )
      }
      if (message.messengerTemplatePayload && Array.isArray(message.messengerTemplatePayload)) {
        message.messengerTemplatePayload.forEach((template, messageItemIndex) =>
          template.buttons.forEach((button, buttonIndex) =>
            this.addPort(
              new ButtonPortModel(sequenceItem._id, messageIndex, buttonIndex, messageItemIndex)
            )
          )
        )
      }
    })
  }

  public addMissingButtonPortsFromSequenceItem(
    sequenceItem: MessengerMessageSequenceItemUI
  ): boolean {
    let foundMissingPort = false
    sequenceItem.messages.forEach((message, messageIndex) => {
      if (message.attachmentPayload && message.attachmentPayload.buttons) {
        message.attachmentPayload.buttons.forEach((button, buttonIndex) => {
          if (!this.getButtonPort(sequenceItem._id, messageIndex, buttonIndex)) {
            this.addPort(new ButtonPortModel(sequenceItem._id, messageIndex, buttonIndex))
            foundMissingPort = true
          }
        })
      }
      if (message.messengerTemplatePayload && !Array.isArray(message.messengerTemplatePayload)) {
        (message.messengerTemplatePayload as MessengerButtonTemplatePayload).buttons.forEach(
          (button, buttonIndex) => {
            if (!this.getButtonPort(sequenceItem._id, messageIndex, buttonIndex)) {
              this.addPort(new ButtonPortModel(sequenceItem._id, messageIndex, buttonIndex))
              foundMissingPort = true
            }
          }
        )
      }
      if (message.messengerTemplatePayload && Array.isArray(message.messengerTemplatePayload)) {
        message.messengerTemplatePayload.forEach((template, messageItemIndex) =>
          template.buttons.forEach((button, buttonIndex) => {
            if (
              !this.getButtonPort(sequenceItem._id, messageIndex, buttonIndex, messageItemIndex)
            ) {
              this.addPort(
                new ButtonPortModel(sequenceItem._id, messageIndex, buttonIndex, messageItemIndex)
              )
              foundMissingPort = true
            }
          })
        )
      }
    })
    return foundMissingPort
  }
}
