import { isMessengerMessageSequenceItemUI } from '@ghostmonitor/recartapis'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { createScope } from '../../../../utils/logger/logger'
import { CreateQuickReplyAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { QuickReplyInitialMeta, SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function createQuickReplyActionHandler(
  state: SequenceEditorState,
  action: CreateQuickReplyAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const sequence = selectEditorSequence(state)
    const sequenceMeta = selectEditorSequenceMeta(state)
    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
    const quickReplyIndex = sequenceItem.quickReplies.length

    sequenceItem.quickReplies.push(action.quickReply)
    sequence.quickReplyIndexToId[action.sequenceItemId][quickReplyIndex] = action.quickReply.id

    if (isMessengerMessageSequenceItemUI(sequenceItem)) {
      node.createQuickReply(action.quickReply.id)
    }

    sequence.serializedDiagram = diagramModel.serializeDiagram() as any

    sequenceMeta.quickRepliesTouched = false

    const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)
    sequenceItemMeta.quickReplies.push({ ...QuickReplyInitialMeta })
  } else {
    info('createQuickReplyActionHandler:1', { action, sequenceItem })
  }
}
