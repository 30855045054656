import {
  MessageButtonTemplate,
  MessageGenericGalleryTemplate,
  MessageGenericTemplate,
  MessageMediaTemplate,
  MessageReceiptTemplate,
  MessageReviewTemplate,
  MessengerGenericTemplatePayload,
  MessengerMessageUI,
  MessengerTemplateType
} from '@ghostmonitor/recartapis'

export function isMessageGenericGalleryTemplate(
  message: MessengerMessageUI
): message is MessageGenericGalleryTemplate {
  return !message
    ? false
    : message.messengerTemplateType === MessengerTemplateType.GENERIC &&
        Array.isArray(message.messengerTemplatePayload)
}

export function hasMessengerButtonOnMessageType(
  message: MessengerMessageUI
): message is
  | MessageGenericTemplate
  | MessageButtonTemplate
  | MessageMediaTemplate
  | MessageReviewTemplate {
  if (!message) {
    return false
  }
  return (
    [
      MessengerTemplateType.BUTTON,
      MessengerTemplateType.GENERIC,
      MessengerTemplateType.MEDIA,
      MessengerTemplateType.REVIEW
    ].includes(message.messengerTemplateType) && !isMessageGenericGalleryTemplate(message)
  )
}

export function isMessageGenericTemplate(
  message: MessengerMessageUI
): message is MessageGenericTemplate {
  return !message
    ? false
    : message.messengerTemplateType === MessengerTemplateType.GENERIC &&
        !Array.isArray(message.messengerTemplatePayload)
}
export function isMessengerGenericGalleryTemplatePayload(
  messengerTemplatePayload: MessengerGenericTemplatePayload | MessengerGenericTemplatePayload[]
): messengerTemplatePayload is MessengerGenericTemplatePayload[] {
  return Array.isArray(messengerTemplatePayload)
}

export function isMessageButtonTemplate(
  message: MessengerMessageUI
): message is MessageButtonTemplate {
  return !message ? false : message.messengerTemplateType === MessengerTemplateType.BUTTON
}

export function isMessageReceiptTemplate(
  message: MessengerMessageUI
): message is MessageReceiptTemplate {
  return !message ? false : message.messengerTemplateType === MessengerTemplateType.RECEIPT
}

export function isMessageMediaTemplate(
  message: MessengerMessageUI
): message is MessageMediaTemplate {
  return !message ? false : message.messengerTemplateType === MessengerTemplateType.MEDIA
}
