import { DelayFlowItemAPI, DelayType, FlowAPI } from '@ghostmonitor/recartapis'

export function checkDelayFlowItemIntegrity(flow: FlowAPI, flowItem: DelayFlowItemAPI) {
  const edges = flow.flowEditorDiagram.edges
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Cannot find node with flowItemId ${flowItem._id}`)
  }

  if (node.data.logicHandles.length !== 1) {
    throw new Error('Delay node should have 1 logic handle')
  }

  const delayTrigger = flowItem.item.logic.delay.trigger
  const logicHandle = node.data.logicHandles[0]
  const outgoingEdge = edges.find((edge) => edge.sourceHandle === logicHandle.id)

  /**************************************
   * Connected from trigger perspective
   *************************************/
  if (delayTrigger !== '') {
    if (outgoingEdge === undefined) {
      throw new Error('Delay node has delay trigger, but there is no outgoing edge')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === delayTrigger
    )

    if (targetNode === undefined) {
      throw new Error('Cannot find target node for delay trigger')
    }

    if (targetNode.id !== outgoingEdge.target) {
      throw new Error(
        `Delay trigger (${delayTrigger}) does not match the target node ${targetNode.data.flowItemId}`
      )
    }
  }

  /*****************************************
   * Connected from connection perspective
   ****************************************/
  if (outgoingEdge !== undefined) {
    if (delayTrigger === '') {
      throw new Error('Delay node has outgoing edge, but there is no delay trigger')
    }

    const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === outgoingEdge.target)

    if (targetNode === undefined) {
      throw new Error('Cannot find target node for outgoing edge')
    }

    if (targetNode.id !== outgoingEdge.target) {
      throw new Error(
        `Delay trigger (${delayTrigger}) does not match the target node ${targetNode.data.flowItemId}`
      )
    }
  }

  /*****************************************
   * Delay type related checks
   ****************************************/
  if (flowItem.item.logic.delay.type === DelayType.delayScheduled) {
    if (flowItem.item.logic.delay.scheduled_date === undefined) {
      throw new Error(
        `Delay type is delayScheduled, but scheduled_date is undefined on ${flowItem._id}`
      )
    }
    if (flowItem.item.logic.delay.schedule_type === undefined) {
      throw new Error(
        `Delay type is delayScheduled, but scheduled_type is undefined on ${flowItem._id}`
      )
    }
  }

  if (flowItem.item.logic.delay.type === DelayType.delay) {
    if (flowItem.item.logic.delay.scheduled_date !== undefined) {
      throw new Error(`Delay type is default delay, but scheduled_date exists on ${flowItem._id}`)
    }
    if (flowItem.item.logic.delay.schedule_type !== undefined) {
      throw new Error(`Delay type is default delay, but schedule_type exists on ${flowItem._id}`)
    }
  }
}
