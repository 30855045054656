// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cH6sY74rVsk_wv2FbsWQ{font-size:30px;margin-top:-20px}.y7Rf72A3Hu3E5yBPM623{position:relative}.file-uploader__preview-image-wrapper{flex:auto}.file-uploader__preview-image-wrapper .file-uploader__preview-image{background-position:50%}.file-uploader__preview-file{padding:10px 15px}.file-uploader__preview-file svg{height:24px;width:20px}.file-uploader-hover{align-items:center;border:1px solid rgba(56,163,236,.85);border-top-left-radius:22px;border-top-right-radius:22px}.file-uploader-hover__container{align-items:center;display:flex;margin:0 auto;max-height:100%}.file-uploader-hover__icon,.file-uploader-hover__icon svg{height:50px;width:65px}.file-uploader-hover__text{align-self:center;margin-top:0}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/file-editor/file-editor.component.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CAGF,sBACE,iBAAA,CAGF,sCACE,SAAA,CAEA,oEACE,uBAAA,CAIJ,6BACE,iBAAA,CAEA,iCAEE,WAAA,CADA,UACA,CAIJ,qBAIE,kBAAA,CAHA,qCAAA,CACA,2BAAA,CACA,4BACA,CAGF,gCAGE,kBAAA,CADA,YAAA,CADA,aAAA,CAGA,eAAA,CAGF,0DAEE,WAAA,CACA,UAAA,CAGF,2BAEE,iBAAA,CADA,YACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageItemIndex": `cH6sY74rVsk_wv2FbsWQ`,
	"file-editor": `y7Rf72A3Hu3E5yBPM623`
};
export default ___CSS_LOADER_EXPORT___;
