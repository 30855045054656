import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../redux-helpers'
import { ModalType } from './modal.type'

export const showModal = createAction(
  'showModal',
  withPayloadType<{
    modalType: ModalType
  }>()
)
export const hideModal = createAction(
  'hideModal',
  withPayloadType<{
    modalType: ModalType
  }>()
)
