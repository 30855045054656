import { isSMSMessageMMSUI, SMSMessageTextUI } from '@ghostmonitor/recartapis'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { hooks } from '../../../../../hooks/hooks'
import {
  selectEditorBlast,
  selectIsReadOnly,
  selectMessage,
  selectMessageMeta
} from '../../../../../store/selectors'
import {
  selectSiteId,
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../../store/slices/site/site.selectors'
import { MMS_MESSAGE_MAX_LENGTH, SMS_MESSAGE_MAX_LENGTH } from '../../../config'
import { MessageTemplateProps } from '../../../types/message-template-props.type'
import { getSequenceEditorSMSStat } from '../../../utils/get-sms-stat'
import { InlineEditor } from '../../inline-editor/inline-editor.component'
import { useMessageControl } from '../use-message-control.hook'

export function SMSTextTemplate(props: MessageTemplateProps) {
  const editorRef = useRef<HTMLDivElement>()
  const siteName = hooks.useSiteSelector(selectSiteName)
  const siteId = hooks.useSiteSelector(selectSiteId)
  const urlGenerationSettings = hooks.useSiteSelector(selectUrlGenerationSettings)
  const supportEmail = hooks.useSiteSelector(selectSupportEmail)
  const blast = useSelector(selectEditorBlast)
  const isReadOnly = useSelector(selectIsReadOnly)
  const messageMeta = useSelector(selectMessageMeta(props.sequenceItemId, props.messageIndex))

  const { handleFocus, handleBlur } = useMessageControl(props.sequenceItemId, props.messageIndex)
  const message = useSelector(
    selectMessage<SMSMessageTextUI>(props.sequenceItemId, props.messageIndex)
  )

  function getCharacterCount(rawValue: string) {
    return getSequenceEditorSMSStat(rawValue, {
      siteName: siteName ?? '',
      supportEmail,
      urlGenerationSettings,
      blast,
      isReadOnly
    }).characterCount
  }

  const isMMSMessage = isSMSMessageMMSUI(message)
  const characterLimit = isMMSMessage ? MMS_MESSAGE_MAX_LENGTH : SMS_MESSAGE_MAX_LENGTH

  function handleChange(value: string) {
    props.onChange?.(value)
  }

  if (siteName === undefined || siteId === undefined) {
    return null
  }

  return (
    <div className='px-3 py-2 pb-4 cursor-text'>
      <InlineEditor
        editorRef={editorRef}
        defaultValue={message.text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        characterLimit={characterLimit}
        getCharacterCount={getCharacterCount}
        isEditable
        messageMeta={messageMeta}
        sequenceItemId={props.sequenceItemId}
        type='sms-discount'
      />
    </div>
  )
}
