import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface OnboardingState {
  completedSteps: string[]
}

export const onboardingInitialState: OnboardingState = {
  completedSteps: []
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: onboardingInitialState,
  reducers: {
    goNextStep(state, action: PayloadAction<{ stepSlug: string }>) {
      if (!state.completedSteps.includes(action.payload.stepSlug)) {
        state.completedSteps.push(action.payload.stepSlug)
      }
    },
    goPreviousStep(state, action: PayloadAction<{ stepSlug: string }>) {
      const indexOfStepSlug = state.completedSteps.findIndex((item) => {
        return item === action.payload.stepSlug
      })

      if (indexOfStepSlug !== -1) {
        state.completedSteps.splice(indexOfStepSlug, 1)
      }
    }
  }
})

export const { goNextStep, goPreviousStep } = onboardingSlice.actions

export const { reducer: onboardingReducer } = onboardingSlice
