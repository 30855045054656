// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eKh7mCkAiw7WlMlz0rgB{height:24px}.eKh7mCkAiw7WlMlz0rgB.D0AS4N1JZnYlnROEw_es{height:32px}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/tag/tag.component.scss"],"names":[],"mappings":"AACE,sBACE,WAAA,CAEA,2CACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `eKh7mCkAiw7WlMlz0rgB`,
	"large": `D0AS4N1JZnYlnROEw_es`
};
export default ___CSS_LOADER_EXPORT___;
