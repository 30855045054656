import { BillingCharge, GetChargesResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export type UseBillingHistory = UseResource<BillingCharge[]> & {
  chargeTypes?: string[]
}

export function useBillingHistory(): UseBillingHistory {
  const queryName = [QUERY_NAME.billingHistory]

  const {
    isLoading,
    data: charges,
    isError,
    refetch
  } = useQuery<GetChargesResponse>(queryName, async () => {
    return request.get<GetChargesResponse>('billing/charges')
  })

  const chargeTypes = charges?.data?.reduce((acc, charge) => {
    return [...new Set([...acc, charge.type])]
  }, [])

  return {
    data: charges?.data,
    chargeTypes,
    isLoading,
    isError,
    refetch: refetch as any
  }
}
