export function escapeAngleBrackets(str: string): string {
  let result = ''
  let buffer = ''
  let isInsideTag = false

  for (let i = 0; i < str.length; i++) {
    const char = str[i]

    buffer += char

    if (char === '<' && !isInsideTag) {
      isInsideTag = true
    } else if (char === '>' && isInsideTag) {
      isInsideTag = false
      result += buffer
      buffer = ''
    }
  }

  // Process remaining characters outside of tags
  if (buffer) {
    result += buffer.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  }

  return result
}
