import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDiscountCodePools } from '../../../hooks/resources/use-discount-code-pools'
import { useSubscribersCount } from '../../../hooks/resources/use-subscribers-count'
import { flowEditorSelectors } from '../../../store/selectors'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { isSMSMessageFlowItemUI } from '../../../types/guards/flow-item-ui.guards'

export interface UseDiscountCodePoolValidation {
  isLoading: boolean
  isPoolSizeEnough: boolean
  isAvailableCountEnough: boolean
  smallestSizePoolId: string | undefined
}

export function useDiscountCodePoolValidation(): UseDiscountCodePoolValidation {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } = useDiscountCodePools()

  const dispatch = useDispatch()
  const smsCampaign = useSelector(flowEditorSelectors.selectEditorSMSCampaign)
  const { data: subscribersCount, isLoading: isSubscriberCountLoading } = useSubscribersCount(
    'sms',
    smsCampaign?.targetRules,
    smsCampaign !== undefined
  )

  const flowItems = useSelector(flowEditorSelectors.selectEditorFlowItems)

  const attachedDiscountPoolIds =
    flowItems
      ?.filter((flowItem) => {
        if (isSMSMessageFlowItemUI(flowItem) && flowItem.item.discountCodePoolId) {
          return flowItem
        }
        return null
      })
      .map((flowItem) => {
        if (isSMSMessageFlowItemUI(flowItem)) {
          return flowItem.item.discountCodePoolId
        }
        return null
      }) ?? []

  const attachedPoolSizes = discountCodePools
    ?.filter((pool) => attachedDiscountPoolIds.includes(pool.id))
    .map((pool) => pool.targetCount)

  const minAttachedPoolSize = Math.min(...(attachedPoolSizes ?? []))
  const attachedPoolAvailableCount = discountCodePools
    ?.filter((pool) => attachedDiscountPoolIds.includes(pool.id))
    .map((pool) => pool.availableCount)

  const minAttachedAvailableCount = Math.min(...(attachedPoolAvailableCount ?? []))
  const numberOfSubscribers = subscribersCount?.count ?? 0
  const smallestSizePoolId = discountCodePools?.find(
    (pool) => pool.targetCount === minAttachedPoolSize
  )?.id

  const isPoolSizeEnough = minAttachedPoolSize >= numberOfSubscribers
  const isAvailableCountEnough = minAttachedAvailableCount >= numberOfSubscribers

  useEffect(() => {
    if (
      isDiscountCodePoolsLoading ||
      isSubscriberCountLoading ||
      discountCodePools === undefined ||
      smsCampaign === undefined ||
      subscribersCount === undefined
    ) {
      return
    }
    dispatch(
      flowEditorActions.discountCodePoolValidated({ isPoolSizeEnough, isAvailableCountEnough })
    )
  }, [
    dispatch,
    isDiscountCodePoolsLoading,
    isSubscriberCountLoading,
    discountCodePools,
    smsCampaign,
    isPoolSizeEnough,
    isAvailableCountEnough,
    subscribersCount
  ])

  if (
    isDiscountCodePoolsLoading ||
    isSubscriberCountLoading ||
    discountCodePools === undefined ||
    smsCampaign === undefined ||
    subscribersCount === undefined
  ) {
    return {
      isLoading: true,
      isPoolSizeEnough: false,
      isAvailableCountEnough: false,
      smallestSizePoolId: undefined
    }
  }

  return {
    isLoading:
      isDiscountCodePoolsLoading || isSubscriberCountLoading || subscribersCount === undefined,
    isPoolSizeEnough,
    isAvailableCountEnough,
    smallestSizePoolId
  }
}
