import {
  ConditionalSplitSequenceItemUI,
  isConditionalSplitSequenceItemUI,
  isDelaySequenceItemUI,
  isMessengerMessageSequenceItemUI,
  isMessengerUserInputSequenceItemUI,
  isSMSSequenceItemUI,
  MessengerMessageSequenceItemUI,
  MessengerUserInputSequenceItemUI,
  SequenceItemUI,
  SequenceTag,
  SMSMessageSequenceItemUI,
  SMSSequenceItemUI
} from '@ghostmonitor/recartapis'

export function hasQuickReplyOnSequenceItemType(
  sequenceItem: SequenceItemUI
): sequenceItem is MessengerMessageSequenceItemUI | MessengerUserInputSequenceItemUI {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) ||
    isMessengerUserInputSequenceItemUI(sequenceItem)
  )
}

export function hasMessageOnSequenceItemType(
  sequenceItem: SequenceItemUI
): sequenceItem is
  | MessengerMessageSequenceItemUI
  | MessengerUserInputSequenceItemUI
  | SMSSequenceItemUI {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) ||
    isMessengerUserInputSequenceItemUI(sequenceItem) ||
    isSMSSequenceItemUI(sequenceItem)
  )
}

export function hasFollowUpButtonOnSequenceItemType(
  sequenceItem: SequenceItemUI
): sequenceItem is MessengerMessageSequenceItemUI | SMSMessageSequenceItemUI {
  return isMessengerMessageSequenceItemUI(sequenceItem) || isSMSSequenceItemUI(sequenceItem)
}

export function hasLogicButtonOnSequenceItemType(
  sequenceItem: SequenceItemUI
): sequenceItem is ConditionalSplitSequenceItemUI {
  return isConditionalSplitSequenceItemUI(sequenceItem)
}

export function isFacebookCommentsSequenceItem(
  sequenceItem: SequenceItemUI
): sequenceItem is MessengerMessageSequenceItemUI {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) &&
    sequenceItem.tags.includes(SequenceTag.FACEBOOK_COMMENTS)
  )
}

export function isFulillmentSequenceItem(sequenceItem: SequenceItemUI): boolean {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) &&
    sequenceItem.tags.includes(SequenceTag.FULFILLMENT)
  )
}

export function isReceiptSequenceItem(sequenceItem: SequenceItemUI): boolean {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) &&
    sequenceItem.tags.includes(SequenceTag.RECEIPT)
  )
}

export function isReviewSequenceItem(sequenceItem: SequenceItemUI): boolean {
  return (
    isMessengerMessageSequenceItemUI(sequenceItem) && sequenceItem.tags.includes(SequenceTag.REVIEW)
  )
}

export function isAbandonmentSequenceItem(sequenceItem: SequenceItemUI): boolean {
  return isDelaySequenceItemUI(sequenceItem) && sequenceItem.tags.includes(SequenceTag.ABANDONMENT)
}
