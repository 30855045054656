import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectEditorSequence } from '../../../../../store/selectors'
import { useEditorFacebookCommentPostId } from '../../../hooks/use-editor-post-id'
import { isFacebookCommentsSequence } from '../../../utils/assert-sequence-type'
import { SaveModal, SaveModalProps } from '../save-modal.component'

export function FacebookCommentSaveModal(props: Partial<SaveModalProps>) {
  const sequence = useSelector(selectEditorSequence)
  const facebookCommentPostId = useEditorFacebookCommentPostId()
  const [disableSaveAndEnable, setDisableSaveAndEnable] = useState(false)

  useEffect(() => {
    if (isFacebookCommentsSequence(sequence)) {
      setDisableSaveAndEnable(!facebookCommentPostId)
    }
  }, [sequence, facebookCommentPostId])

  return <SaveModal disableMaskCloseable disableSaveAndEnable={disableSaveAndEnable} {...props} />
}
