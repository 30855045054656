import { FollowUpPortModel } from '../../../../routes/SequenceEditor/models/port/follow-up-port-model'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { SMSMessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/sms-message/sms-message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { hasFollowUpButtonOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { CreateFollowUpPortAction } from '../sequence-editor.actions'
import { selectEditorSequence, selectSequenceItem } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
const { info } = createScope('sequence-editor')

export function createFollowUpPortActionHandler(
  state: SequenceEditorState,
  action: CreateFollowUpPortAction
) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)

  if (hasFollowUpButtonOnSequenceItemType(sequenceItem)) {
    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const node = getNodeModel<MessageSequenceItemNodeModel | SMSMessageSequenceItemNodeModel>(
      diagramModel,
      action.sequenceItemId
    )
    const port = new FollowUpPortModel()
    node.addPort(port)
    sequence.serializedDiagram = diagramModel.serializeDiagram() as any
  } else {
    info('createFollowUpPortActionHandler:1', { action, sequenceItem })
  }
}
