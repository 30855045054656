import { PortType } from '@ghostmonitor/recartapis'
import { SequenceEditorPortModel } from './port-model'

export class SequenceItemPortModel extends SequenceEditorPortModel {
  constructor(name?: string) {
    super(PortType.SEQUENCE_ITEM, name)
  }

  public canLinkToPort(port: SequenceItemPortModel): boolean {
    // At this point, link is already added, and this.getLinks() counts this +1 link,
    // even if we return with false at this point. If we return with false, the link is
    // going to be removed

    const linkingToSameNode = port.parent && port.parent.id === this.getParent().id
    const targetPortAlreadyOccupied = Object.keys(port.getLinks()).length > 1
    return port.portType !== this.portType && !linkingToSameNode && !targetPortAlreadyOccupied
  }
}
