import { FlowItemUI } from '@ghostmonitor/recartapis'
import { DashboardState } from '../../../../dashboard.state'
import { ValidateFlowItemThunkArgs } from '../validate-flow-item.thunk'
import { isSMSMessageFlowItemUI } from '../../../../../types/guards/flow-item-ui.guards'
import { getSMSMessageValidationResult } from './sms/sms-validator'
import { flowEditorSelectors } from '../../../../selectors'
import { getItemValidator, ItemValidationRunner, ValidationResult } from './validator-factory'
import { FlowItemError } from '../../flow-editor.state'
import { FlowEditorValidationError } from '../../../../../utils/flow-editor/flow-editor-errors'

async function validateFlowItems(
  validationRunner: ItemValidationRunner,
  existingFlowItemErrors: FlowItemError[],
  validateFlowItemThunkArgs?: ValidateFlowItemThunkArgs
): Promise<FlowEditorValidationError[]> {
  const validators = validationRunner.getConfiguredValidators()
  const errors: FlowEditorValidationError[] = []

  for (const [key, validator] of Object.entries(validators)) {
    const validatorConfigValue = validateFlowItemThunkArgs?.validators[key]
    const validationErrors = await validator.validate()

    switch (validatorConfigValue) {
      case true:
        errors.push(...validationErrors)
        break
      case 'onlyIfValidationSucceeded':
        if (!validationErrors.length) {
          errors.push(...validationErrors)
        }
        break
      case 'onlyIfAlreadyFailed':
        if (existingFlowItemErrors.length > 0) {
          errors.push(...validationErrors)
        }
        break
    }
  }

  return errors
}

export async function getValidationResult(
  flowItem: FlowItemUI,
  state: DashboardState,
  validateFlowItemThunkArgs: ValidateFlowItemThunkArgs
): Promise<ValidationResult> {
  const result: ValidationResult = {
    message: {
      succeeded: [],
      failed: []
    },
    item: {
      errors: []
    }
  }

  if (isSMSMessageFlowItemUI(flowItem) && validateFlowItemThunkArgs.validators.flowItemContent) {
    result.message = getSMSMessageValidationResult(flowItem, state)
  }

  const itemValidator = getItemValidator(flowItem, state, validateFlowItemThunkArgs)
  if (itemValidator !== undefined) {
    const existingFlowItemErrors = flowEditorSelectors.selectFlowItemErrors(flowItem._id)(state)
    result.item.errors = await validateFlowItems(
      itemValidator,
      existingFlowItemErrors,
      validateFlowItemThunkArgs
    )
  }

  return result
}
