import { SMSSettingsService } from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import { useQuery } from '@tanstack/react-query'
import mapValues from 'lodash/mapValues'
import { api } from '../../utils/api'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export interface PhoneNumberPrices {
  phoneNumber: string
  countryCode: string
  smsPrice: number
  mmsPrice: number
  countryDialInCode: number
}

export function usePhoneNumbersCurrentPrices(): UseResource<PhoneNumberPrices[]> {
  const queryName = [QUERY_NAME.currentPhoneNumbersPrices]

  const {
    isLoading,
    data,
    error: requestError
  } = useQuery<SMSSettingsService.GetCurrentPricesByPhoneNumbersResponse>(
    queryName,
    api.getPhoneNumbersCurrentPrices
  )
  const error: SerializedError = requestError
  const { isLoading: isSMSPhoneNumbersLoading, data: phoneNumbers } = hooks.useSMSPhoneNumbers()

  const generalPricesByPhoneNumber = mapValues(data?.data, 'general')

  const currentPrices: PhoneNumberPrices[] = Object.entries(generalPricesByPhoneNumber).reduce<
    PhoneNumberPrices[]
  >((acc, [phoneNumber, priceCycles]) => {
    const smsPrices = priceCycles?.sms ?? []
    const mmsPrices = priceCycles?.mms ?? []
    const countryPrices = smsPrices.map((priceCycle) => {
      return {
        phoneNumber,
        smsPrice: priceCycle.price,
        countryCode: priceCycle.countryCode,
        mmsPrice: mmsPrices.find((price) => price.countryCode === priceCycle.countryCode)?.price,
        countryDialInCode: priceCycle.handsetDialInCode
      }
    })

    return [...acc, ...countryPrices]
  }, [])

  return {
    data: currentPrices,
    error,
    isLoading: isLoading || isSMSPhoneNumbersLoading || phoneNumbers === undefined
  }
}
