import { NodeType } from '@ghostmonitor/recartapis'
import React from 'react'
import { AbstractNodeFactory, DiagramEngine } from 'storm-react-diagrams'
import { UserInputSequenceItemNodeWidget } from '../../../components/sequence-item-node-widget/user-input-node-widget/user-input-node-widget.component'
import { UserInputSequenceItemNodeModel } from './user-input-sequence-item-model'

export class UserInputSequenceItemNodeFactory extends AbstractNodeFactory {
  constructor(private readonly repaintCanvas: VoidFunction = () => undefined) {
    super(NodeType.USER_INPUT_SEQUENCE_ITEM)
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    sequenceItemNode: UserInputSequenceItemNodeModel
  ): JSX.Element {
    return (
      <UserInputSequenceItemNodeWidget
        sequenceItemNode={sequenceItemNode}
        linking={false}
        repaintCanvas={this.repaintCanvas}
      />
    )
  }

  public getNewInstance(...args) {
    return new UserInputSequenceItemNodeModel()
  }
}
