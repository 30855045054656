import {
  OptinToolStep,
  Popup,
  PopupEmailSettings,
  PopupSMSSettings
} from '@ghostmonitor/recartapis'
import intersection from 'lodash/intersection'
import isEqual from 'lodash/isEqual'
import { OptinToolMenuItem } from '../../components/editor/left-panel/left-panel.component'
import { isEmailOnlyOptinTool, isSMSOptinTool } from '../../utils/helpers'

export enum PopupMenuItemSlug {
  LAYOUT_AND_STYLES = 'layoutAndStyles',
  TEASER = 'teaser',
  EMAIL = 'email',
  EMAIL_FORM_ERROR = 'emailFormError',
  MESSENGER = 'messenger',
  SMS = 'sms',
  SMS_WITH_EMAIL = 'smsWithEmail',
  SMS_WITH_EMAIL_DESKTOP_FORM_ERROR = 'smsWithEmailDesktopFormError',
  SMS_WITH_EMAIL_MOBILE_FORM_ERROR = 'smsWithEmailMobileFormError',
  SMS_FORM_ERROR = 'smsFormError',
  SUCCESS = 'success',
  ONE_TIME_PASSWORD = 'oneTimePassword',
  MINIMIZED = 'minimized',
  SETTINGS = 'settings'
}

export function getPopupMenuItems(popup: Popup): OptinToolMenuItem[] {
  const hasSingleErrorStep =
    isEqual(popup.channels, ['sms']) && !popup.steps.includes('oneTimePassword')

  const hasSMSInput =
    (isSMSOptinTool(popup) && popup.devices.includes('desktop')) ||
    popup.steps.includes('oneTimePassword') ||
    popup.messagingType === 'one-way'

  const smsFormItems = popup.settings.sms?.form?.items ?? []
  const hasSMSForm = smsFormItems.length > 0

  const menuItems: OptinToolMenuItem[] = [
    {
      slug: PopupMenuItemSlug.LAYOUT_AND_STYLES,
      label: 'Layout & styles',
      items: [
        'appearance',
        'additionalImage',
        'background',
        'closeIcon',
        'logo',
        'heading',
        'bodyText',
        'legalText',
        'primaryButton',
        'secondaryButton',
        'input'
      ]
    },
    {
      slug: PopupMenuItemSlug.TEASER,
      label: 'Teaser',
      items: ['teaser', 'logo', 'heading', 'primaryButton', 'secondaryButton']
    },
    {
      slug: PopupMenuItemSlug.EMAIL,
      steps: ['email'],
      label: 'Email opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: PopupMenuItemSlug.EMAIL_FORM_ERROR,
      steps: ['email'],
      label: 'Form item errors (email)',
      items: [...getErrorAccordionFirstItem(popup, 'email')]
    },
    {
      slug: PopupMenuItemSlug.MESSENGER,
      steps: ['messenger'],
      label: 'Messenger opt-in',
      items: ['logo', 'heading', 'emailSuccess', 'button', 'description']
    },
    {
      slug: PopupMenuItemSlug.SMS,
      steps: ['sms'],
      label: 'SMS opt-in',
      items: ['logo', 'heading', 'smsInput', 'button', 'description']
    },
    {
      slug: PopupMenuItemSlug.SMS_WITH_EMAIL,
      steps: ['smsWithEmail'],
      label: 'Email and SMS opt-in',
      items: ['logo', 'heading', 'emailInput', 'smsInput', 'button', 'description']
    },
    {
      slug: PopupMenuItemSlug.SMS_WITH_EMAIL_DESKTOP_FORM_ERROR,
      steps: ['smsWithEmail'],
      label: 'Form item errors',
      items: [...getErrorAccordionFirstItem(popup, 'smsWithEmail')],
      isVisible: hasSMSInput
    },
    {
      slug: PopupMenuItemSlug.SMS_WITH_EMAIL_MOBILE_FORM_ERROR,
      steps: ['smsWithEmail'],
      device: 'mobile',
      label: 'Form item errors',
      items: [...getErrorAccordionFirstItem(popup, 'smsWithEmail')],
      isVisible: !hasSMSInput
    },
    {
      slug: PopupMenuItemSlug.SMS_FORM_ERROR,
      steps: ['sms'],
      label: `Form item errors ${hasSingleErrorStep ? '' : '(SMS)'}`,
      items: [...getErrorAccordionFirstItem(popup, 'sms')],
      isVisible: hasSMSInput || hasSMSForm
    },
    {
      slug: PopupMenuItemSlug.ONE_TIME_PASSWORD,
      steps: ['oneTimePassword'],
      label: 'SMS consent',
      items: ['logo', 'heading', 'description', 'oneTimePasswordInput', 'button', 'resend']
    },
    {
      slug: PopupMenuItemSlug.SUCCESS,
      steps: ['sms', 'smsWithEmail', 'email'],
      label: 'Success',
      items: ['logo', 'heading', 'description', 'button'],
      isVisible: hasSMSInput || isEmailOnlyOptinTool(popup)
    },
    { slug: PopupMenuItemSlug.MINIMIZED, label: 'Minimized view', items: ['minimized'] },
    {
      slug: PopupMenuItemSlug.SETTINGS,
      label: 'Settings'
    }
  ]

  return menuItems.filter(
    (menuItem) =>
      (menuItem.steps === undefined || intersection(popup.steps, menuItem.steps).length > 0) &&
      (menuItem.device === undefined || popup.devices.includes(menuItem.device)) &&
      (menuItem.isVisible === undefined || menuItem.isVisible)
  )
}

function getErrorAccordionFirstItem(popup: Popup, step: OptinToolStep): string[] {
  const popupSMSSettings = popup.settings[step] as PopupSMSSettings | PopupEmailSettings
  return popupSMSSettings?.form?.items && popupSMSSettings?.form?.items.length > 0
    ? ['formErrors']
    : []
}
