/* global UPLOAD_API_URL */
import { AttachmentType } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { DropzoneComponent } from 'react-dropzone-component'
import { config } from '../../../../config'
import { ReactComponent as AudioIcon } from '../../../../static/images/svg/icon-audio.svg'
import { ReactComponent as FileIcon } from '../../../../static/images/svg/icon-file.svg'
import { ReactComponent as ImageIcon } from '../../../../static/images/svg/icon-image.svg'
import { ReactComponent as ReplaceIcon } from '../../../../static/images/svg/icon-replace.svg'
import { ReactComponent as VideoIcon } from '../../../../static/images/svg/icon-video.svg'
import { ReactComponent as PlayIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/42-Multimedia-Controls/button-play-1.svg'
import { classify } from '../../../../utils/utils'
import { Audio } from './components/audio.component'
import { File } from './components/file.component'
import { Image } from './components/image.component'
import { Video } from './components/video.component'
import styles from './file-editor.component.scss'
import './file-upload.style.scss'

const MEDIA_UPLOAD_SOURCE = 'sequence-editor'

export const fbElements = {
  image: {
    icon: <ImageIcon />
  },
  file: {
    icon: <FileIcon />
  },
  audio: {
    icon: <AudioIcon />
  },
  video: {
    icon: <VideoIcon />
  }
}

const attachmentPreviews = {
  image: Image,
  file: File,
  audio: Audio,
  video: Video
}

export interface FileEditorProps {
  messageItemIndex?: number
  messageMeta?: any
  change: (change: any) => void
  onUploadEnded?: (file: string) => void
  input: {
    onBlur?: () => void
    name: string
    value: string
  }
  attachmentType: string
  contentEditable: boolean
  isGallery?: boolean
  siteId: string
}

export class FileEditor extends Component<FileEditorProps, any> {
  private readonly componentConfig: any
  private readonly eventHandlers: any
  private readonly djsConfig: any
  private readonly name: string

  constructor(props) {
    super(props)
    this.name = `upload_${Math.round(Math.random() * 100000).toString()}`
    const { componentOptions, siteId } = props

    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
    this.addedfile = this.addedfile.bind(this)
    this.canceled = this.canceled.bind(this)
    this.state = { loading: false }

    this.componentConfig = Object.assign(
      {},
      {
        showFiletypeIcon: false,
        postUrl:
          config.UPLOAD_API_URL + `sites/${siteId}/media-uploads?source=${MEDIA_UPLOAD_SOURCE}`
      },
      componentOptions || {}
    )

    this.eventHandlers = {
      canceled: this.canceled,
      addedfile: this.addedfile,
      success: this.success,
      error: this.error
    }
    const authToken = localStorage.getItem('x-auth-token')
    this.djsConfig = {
      headers: { 'X-Auth-Token': authToken },
      paramName: 'file',
      timeout: 120000,
      maxFilesize: 25,
      acceptedFiles: 'image/*, audio/*, video/*, application/*',
      clickable: `.file-uploader--${classify(this.name)} .file-uploader__clickable`,
      previewsContainer: `.file-uploader--${classify(this.name)}`,
      dictDefaultMessage: '',
      dictMaxFilesExceeded: 'MaxFiles limit exceeded',
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className='dz-preview dz-file-preview'>
          <div className='dz-error-message'>
            <span data-dz-errormessage />
          </div>
        </div>
      )
    }
  }

  public success(file) {
    const { change, onUploadEnded } = this.props
    const response = JSON.parse(file.xhr.response)
    onUploadEnded && onUploadEnded(file)
    change(response.url)
  }

  public error(data) {
    this.setState({ loading: false })
  }

  public addedfile(file) {
    const { change } = this.props
    change('')
    this.setState({ loading: true })
  }

  public canceled() {
    const {
      input: { onBlur }
    } = this.props
    onBlur()
  }

  public loadFinished() {
    this.setState({ loading: false })
  }

  public renderDefaultMessage() {
    const { attachmentType } = this.props
    return (
      <div className='file-uploader__preview-placeholder'>
        {this.props.messageItemIndex !== undefined ? (
          <div className={styles.messageItemIndex}>{this.props.messageItemIndex + 1}</div>
        ) : null}
        <div className={`file-uploader__preview-icon ${attachmentType}`}>
          {fbElements[attachmentType].icon}
        </div>
        Please select an attachment file
      </div>
    )
  }

  public renderHover() {
    const isFlexRow =
      [AttachmentType.audio, AttachmentType.file].indexOf(
        AttachmentType[this.props.attachmentType]
      ) > -1
    return (
      <div className='file-uploader-hover'>
        <div
          className='file-uploader-hover__container'
          style={{ flexDirection: isFlexRow ? 'row' : 'column' }}
        >
          <div className='file-uploader-hover__icon'>
            <ReplaceIcon />
          </div>
          <div className='file-uploader-hover__text'>Click to replace</div>
        </div>
      </div>
    )
  }

  public render() {
    const {
      input: { value },
      attachmentType,
      contentEditable,
      isGallery
    } = this.props
    const AttachmentComponent = attachmentPreviews[attachmentType]
    let preview = (
      <AttachmentComponent
        {...this.props}
        loadFinished={() => this.loadFinished()}
        isGallery={isGallery}
      />
    )
    if (!value) {
      preview = this.renderDefaultMessage()
    }

    const classes = {
      'file-uploader': true,
      [`file-uploader--${classify(this.name)}`]: true,
      'file-uploader--has-value': !!value
    }
    const fileUploaderPreview = (
      <div className='file-uploader__preview'>
        <div
          className={`file-uploader__loader-container ${
            this.state.loading ? 'file-uploader__loader-container--active' : ''
          }`}
        >
          <div className='file-uploader__loader' />
        </div>
        {contentEditable && value && this.renderHover()}
        {preview}
      </div>
    )

    if (contentEditable) {
      return (
        <div className={cn(styles['file-editor'], classes)}>
          {value && attachmentType === AttachmentType.video ? (
            <div className='video-icon'>
              <PlayIcon />
            </div>
          ) : null}
          <div className='file-uploader__clickable'>
            <DropzoneComponent
              config={this.componentConfig}
              eventHandlers={this.eventHandlers}
              djsConfig={this.djsConfig}
            >
              {fileUploaderPreview}
            </DropzoneComponent>
          </div>
        </div>
      )
    }
    return (
      <div className={cn(classes)}>
        <div className='file-uploader__clickable'>{fileUploaderPreview}</div>
      </div>
    )
  }
}
