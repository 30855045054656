// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NKIIYRZp3RH_myObubl6{background-color:#dee4f5;border:2px solid #476dff;border-radius:12px;color:#212b36;cursor:pointer;display:flex;height:24px;line-height:14px;margin-right:5px;padding:3px 9px;width:auto}.NKIIYRZp3RH_myObubl6 .Uz0b7thvUcQQHAut9SpQ{display:inline-block;height:15px;position:relative}.NKIIYRZp3RH_myObubl6 .Uz0b7thvUcQQHAut9SpQ svg{height:15px;width:15px}.NKIIYRZp3RH_myObubl6 .YApD_YCZQaTZJ1bEHj7k{font-size:14px;margin-left:4px}.XO_ec79QEnKZYfl4fDQ9{cursor:default;inset:0;position:fixed}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-emoji-picker/make-emoji-picker.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAME,wBCRc,CDUd,wBAAA,CACA,kBAAA,CAFA,aCRG,CDYH,cAAA,CAVA,YAAA,CAEA,WAAA,CACA,gBAAA,CAMA,gBAAA,CALA,eAAA,CAHA,UASA,CAEA,4CACE,oBAAA,CAEA,WAAA,CADA,iBACA,CAEA,gDACE,WAAA,CACA,UAAA,CAIJ,4CACE,cAAA,CACA,eAAA,CAIJ,sBAGE,cAAA,CADA,OAAA,CADA,cAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonContainer": `NKIIYRZp3RH_myObubl6`,
	"emojiPickerIcon": `Uz0b7thvUcQQHAut9SpQ`,
	"characterCount": `YApD_YCZQaTZJ1bEHj7k`,
	"pickerMask": `XO_ec79QEnKZYfl4fDQ9`
};
export default ___CSS_LOADER_EXPORT___;
