// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JPyHFWhEUYc2HFc5gyWA{background-color:#dee4f5;border:2px solid #476dff;border-radius:12px;color:#212b36;cursor:pointer;font-size:12px;height:24px;margin-right:5px;padding:3px 10px;width:36px}.JPyHFWhEUYc2HFc5gyWA .ETGYkXXc2TCMX_u_YSrM{display:inline-block;height:13px;position:relative}.JPyHFWhEUYc2HFc5gyWA .ETGYkXXc2TCMX_u_YSrM svg{height:13px;width:13px}.vdX0LDO5uIdPw499xny5{cursor:default;inset:0;position:fixed}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-link-shortening/make-link-shortening.hoc.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAIE,wBCNc,CDSd,wBAAA,CACA,kBAAA,CAHA,aCNG,CDWH,cAAA,CAJA,cAAA,CALA,WAAA,CAQA,gBAAA,CANA,gBAAA,CADA,UAQA,CAEA,4CACE,oBAAA,CAEA,WAAA,CADA,iBACA,CAEA,gDACE,WAAA,CACA,UAAA,CAKN,sBAGE,cAAA,CADA,OAAA,CADA,cAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `JPyHFWhEUYc2HFc5gyWA`,
	"icon": `ETGYkXXc2TCMX_u_YSrM`,
	"pickerWrapper": `vdX0LDO5uIdPw499xny5`
};
export default ___CSS_LOADER_EXPORT___;
