import {
  isSequenceItemLinkButton,
  LinkingMeta,
  MessengerMessageSequenceItemUI,
  MessengerMessageUI,
  QuickReply
} from '@ghostmonitor/recartapis'
import { Divider } from 'antd'
import { ObjectId } from 'bson'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { createStructuredSelector } from 'reselect'
import {
  selectCurrencySlug,
  selectDraggedItemType,
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectLinking,
  selectLinkingMeta,
  selectQuickReplies,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView,
  selectShowSequenceItemStatistics
} from '../../../../../store/selectors'
import {
  createFollowUpPort,
  createMessage,
  createQuickReply,
  createSequenceItem,
  removeMessage,
  removeQuickReply,
  removeSequenceItem,
  reorderMessage,
  reorderQuickReply,
  updateQuickReply
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import {
  SequenceItemMeta,
  SequenceMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  makeSequenceItem,
  SequenceItemNodeWidgetBaseProps
} from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc'
import hocStyles from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'
import { FollowUpPortModel } from '../../../models/port/follow-up-port-model'
import { QuickReplyPortModel } from '../../../models/port/quick-reply-port-model'
import { MessageSequenceItemNodeModel } from '../../../models/sequence-item/message/message-sequence-item-model'
import { FOLLOW_UP_PORT } from '../../../models/sequence-item/port-names'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import { MessageTrayItem, TrayItem } from '../../../types/tray-item.type'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../utils/assert-message-template-type'
import {
  isFacebookCommentsSequenceItem,
  isFulillmentSequenceItem,
  isReviewSequenceItem
} from '../../../utils/assert-sequence-item-type'
import {
  AddElementButton,
  AddElementButtonType
} from '../../add-element-button/add-element-button.component'
import { LogicSequenceItemPortWidget } from '../../logic-sequence-item-port-widget/logic-sequence-item-port-widget.component'
import { QuickReplyPlaceholder } from '../../quick-reply-placeholder/quick-reply-placeholder.component'
import { QuickReplyPortWidget } from '../../quick-reply-port-widget/quick-reply-port-widget.component'
import { SequenceItemMessages } from '../../sequence-item-messages/sequence-item-messages.component'
import styles from './message-sequence-item-node-widget.component.scss'

const MAX_QUICK_REPLY_COUNT = 13

interface QuickReplySortableElementProps {
  port: QuickReplyPortModel
  title: string
  error: string | null
  blurred: boolean
  index: number
  sorting: boolean
  linking: boolean
  linkingMeta: LinkingMeta
  sequenceItemNode: MessageSequenceItemNodeModel

  onPortFocus: (quickReplyId: string) => void
  onPortBlur: (quickReplyId: string) => void

  onUpdateQuickReply: (newValue: string) => void
  onRemoveQuickReply: () => void
}

const SortableItem = SortableElement((props: QuickReplySortableElementProps) => {
  return (
    <QuickReplyPortWidget
      key={props.port ? props.port.name || props.port.id : ''}
      sequenceItemNode={props.sequenceItemNode}
      onPortFocus={props.onPortFocus}
      onPortBlur={props.onPortBlur}
      onUpdateQuickReply={props.onUpdateQuickReply}
      onRemoveQuickReply={props.onRemoveQuickReply}
      title={props.title}
      port={props.port}
      blurred={props.blurred}
      sorting={props.sorting}
      linking={props.linking}
      linkingMeta={props.linkingMeta}
      error={props.error}
    />
  )
})

interface QuickReplySortableListProps {
  sequenceItemNode: MessageSequenceItemNodeModel
  blurred: boolean
  sorting: boolean
  linking: boolean
  linkingMeta: LinkingMeta
  onPortFocus: (quickReplyId: string) => void
  onPortBlur: (quickReplyId: string) => void

  onUpdateQuickReply: (quickReplyIndex: number, newValue: string) => void
  onRemoveQuickReply: (quickReplyIndex: number) => void
}

const SortableList = SortableContainer((props: QuickReplySortableListProps) => {
  const sequenceItemMeta = useSelector(
    selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId)
  )
  const quickReplies = useSelector(selectQuickReplies(props.sequenceItemNode.sequenceItemId))

  return (
    <div>
      {quickReplies.map((quickReply: QuickReply, index: number) => {
        const port = props.sequenceItemNode.getQuickReplyPort(quickReply.id)
        const error = sequenceItemMeta.quickReplies?.[index]?.error ?? null
        const quickReplySortableElement: QuickReplySortableElementProps = {
          port,
          title: quickReply.title,
          error,
          blurred: props.blurred,
          index,
          sorting: props.sorting,
          linking: props.linking,
          linkingMeta: props.linkingMeta,
          sequenceItemNode: props.sequenceItemNode,
          onPortFocus: props.onPortFocus,
          onPortBlur: props.onPortBlur,
          onUpdateQuickReply: (newValue: string) => props.onUpdateQuickReply(index, newValue),
          onRemoveQuickReply: () => props.onRemoveQuickReply(index)
        }

        return <SortableItem key={`item-${port.name}`} {...quickReplySortableElement} />
      })}
    </div>
  )
})

export interface MessageSequenceItemNodeWidgetViewComponentProps
  extends SequenceItemNodeWidgetBaseProps {
  sequenceItemNode: MessageSequenceItemNodeModel
  sequenceItem: MessengerMessageSequenceItemUI
  sequenceMeta: SequenceMeta
  sequenceItemMeta: SequenceItemMeta

  repaintCanvas: () => void

  createMessage: (sequenceItemId: string, message: MessengerMessageUI) => void
  removeMessage: (sequenceItemId: string, messageIndex: number) => void
  reorderMessage: (sequenceItemId: string, oldIndex: number, newIndex: number) => void

  createQuickReply: (sequenceItemId: string, quickReply: QuickReply) => void
  removeQuickReply: (sequenceItemId: string, quickReplyIndex: number) => void
  updateQuickReply: (sequenceItemId: string, quickReplyIndex: number, label: string) => void
  reorderQuickReply: (sequenceItemId: string, oldIndex: number, newIndex: number) => void

  createFollowUpPort: (sequenceItemId: string) => void

  linking: boolean
  linkingMeta: LinkingMeta
  draggedItemType: TrayItem
  sequenceName: string
  entrySequenceItemId: string
  isAbandonmentSequence: boolean
  showSequenceItemStatistics: boolean
  showDebugView: boolean
  hasVariable: boolean
}

const MessageSequenceItemNodeWidgetViewComponent = makeSequenceItem(
  (props: React.PropsWithChildren<MessageSequenceItemNodeWidgetViewComponentProps>) => {
    const [sortingQuickReplies, setSortingQuickReplies] = useState(false)
    const setSortingMessages = useState(false)[1]
    const quickReplies = useSelector(selectQuickReplies(props.sequenceItemNode.sequenceItemId))

    function handleQuickReplySortEnd(oldIndex: number, newIndex: number) {
      setSortingQuickReplies(false)
      props.reorderQuickReply(props.sequenceItemNode.sequenceItemId, oldIndex, newIndex)
    }

    function handleCreateQuickReply() {
      const newQuickReply: QuickReply = {
        id: new ObjectId().toHexString(),
        title: '',
        trigger: undefined,
        content_type: 'text',
        image_url: undefined
      }
      props.createQuickReply(props.sequenceItemNode.sequenceItemId, newQuickReply)
    }

    // ! TODO implement~
    function handlePortFocus() {
      return undefined
    }

    // ! TODO implement~
    function handlePortBlur() {
      return undefined
    }

    function handleRemoveQuickReply(quickReplyIndex: number) {
      props.removeQuickReply(props.sequenceItemNode.sequenceItemId, quickReplyIndex)
    }

    function handleUpdateQuickReply(quickReplyIndex: number, newLabel: string) {
      props.updateQuickReply(props.sequenceItemNode.sequenceItemId, quickReplyIndex, newLabel)
    }

    function handleMessagesSortEnd(oldIndex: number, newIndex: number) {
      setSortingMessages(false)
      props.reorderMessage(props.sequenceItemNode.sequenceItemId, oldIndex, newIndex)
    }

    function handleCreateFollowUpPort() {
      props.createFollowUpPort(props.sequenceItemNode.sequenceItemId)
    }

    function getQuickReplies() {
      if (quickReplies.length === 0) {
        return null
      }

      // Preparing to an temporary scenario when model is not sync with store
      const validQuickReplies = quickReplies.filter(
        (quickReply) => props.sequenceItemNode.getPort(quickReply.id) !== undefined
      )

      if (validQuickReplies.length !== quickReplies.length) {
        return null
      }

      const quickReplySortableListProps: QuickReplySortableListProps = {
        blurred: props.sequenceItemMeta.selected,
        sequenceItemNode: props.sequenceItemNode,
        sorting: sortingQuickReplies,
        linking: props.linking,
        linkingMeta: props.linkingMeta,

        onPortFocus: handlePortFocus,
        onPortBlur: handlePortBlur,

        onUpdateQuickReply: handleUpdateQuickReply,
        onRemoveQuickReply: handleRemoveQuickReply
      }

      return (
        <SortableList
          onSortStart={() => setSortingQuickReplies(true)}
          onSortEnd={({ oldIndex, newIndex }) => handleQuickReplySortEnd(oldIndex, newIndex)}
          useDragHandle
          lockAxis='y'
          distance={10}
          {...quickReplySortableListProps}
        />
      )
    }

    const followUpPort = props.sequenceItemNode.getPort(FOLLOW_UP_PORT) as FollowUpPortModel

    const isEntrySequenceItem = props.sequenceItem._id === props.entrySequenceItemId
    const isRestrictedSequenceItem = isFacebookCommentsSequenceItem(props.sequenceItem)

    const showFollowUp = !isRestrictedSequenceItem || !isEntrySequenceItem

    const isFulfillmentEntry = isFulillmentSequenceItem(props.sequenceItem) && isEntrySequenceItem
    const isReviewEntry = isReviewSequenceItem(props.sequenceItem) && isEntrySequenceItem

    return (
      <>
        <SequenceItemMessages
          sequenceItemId={props.sequenceItemNode.sequenceItemId}
          onSortStart={() => setSortingMessages(true)}
          onSortEnd={handleMessagesSortEnd}
          sequenceItemNode={props.sequenceItemNode}
          isFulfillmentEntry={isFulfillmentEntry}
          isReviewEntry={isReviewEntry}
          isAbandonmentSequence={props.isAbandonmentSequence}
          hasVariable={props.hasVariable}
        />
        <div>
          {getQuickReplies()}
          {quickReplies !== undefined && quickReplies.length < MAX_QUICK_REPLY_COUNT && (
            <QuickReplyPlaceholder
              blurred={props.sequenceItemMeta.selected}
              onClick={handleCreateQuickReply}
            >
              Add quick reply
            </QuickReplyPlaceholder>
          )}
        </div>
        {showFollowUp && (
          <div className={styles['follow-up-wrapper']}>
            <Divider className={styles.divider} />
            {followUpPort ? (
              <LogicSequenceItemPortWidget
                linking={props.linking}
                linkingMeta={props.linkingMeta}
                node={props.sequenceItemNode}
                port={followUpPort}
              />
            ) : (
              <AddElementButton
                buttonType={AddElementButtonType.FOLLOW_UP}
                label='Add follow-up'
                onClick={handleCreateFollowUpPort}
              />
            )}
          </div>
        )}
      </>
    )
  },
  [hocStyles['message-wrapper']],
  []
)

export interface MessageSequenceItemNodeWidgetComponentProps {
  sequenceItemNode: MessageSequenceItemNodeModel
  repaintCanvas: () => void
  hasVariable?: boolean
}

export function MessageSequenceItemNodeWidgetComponent(
  props: MessageSequenceItemNodeWidgetComponentProps
) {
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      sequenceMeta: selectEditorSequenceMeta,
      sequenceItem: selectSequenceItem<MessengerMessageSequenceItemUI>(
        props.sequenceItemNode.sequenceItemId
      ),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId),
      showSequenceItemStatistics: selectShowSequenceItemStatistics,
      showDebugView: selectShowDebugView,
      linking: selectLinking,
      linkingMeta: selectLinkingMeta,
      draggedItemType: selectDraggedItemType,
      currencySlug: selectCurrencySlug
    }),
    [props.sequenceItemNode.sequenceItemId]
  )

  const state = useSelector(stateSelector)
  const dispatch = useDispatch()

  const [removing, setRemoving] = useState(false)

  useEffect(() => {
    if (state.sequenceItem?.messages.length === 0) {
      props.repaintCanvas()
    }
  }, [state.sequenceItem?.messages])

  if (!state.sequenceItem) {
    return null
  }

  const isEntry =
    state.sequenceItem !== undefined &&
    state.sequenceItem._id === state.sequence.entrySequenceItemId
  const isDragging =
    state.draggedItemType !== null && state.draggedItemType === MessageTrayItem.messengerMessage
  const inputPort = props.sequenceItemNode.getInputPort()

  function handleRepaintCanvas() {
    if (!removing) {
      props.repaintCanvas()
    }
  }

  async function handleRemoveSequenceItem() {
    // Entry sequence items cannot be deleted
    if (state.sequence.entrySequenceItemId === props.sequenceItemNode.sequenceItemId) {
      return false
    }
    setRemoving(true)
    dispatch(removeSequenceItem(props.sequenceItemNode.sequenceItemId))
  }

  function handleCloneSequenceItem() {
    const newSequenceItem = cloneDeep(state.sequenceItem)
    // Cannot be two sequence item with the same slug
    delete newSequenceItem.nextSequenceItemTrigger

    newSequenceItem.quickReplies.forEach((quickReply) => {
      quickReply.id = new ObjectId().toHexString()
      delete quickReply.trigger
    })

    newSequenceItem._id = new ObjectId().toHexString()
    newSequenceItem.trigger = [newSequenceItem._id]
    newSequenceItem.tags = [...state.sequence.tags]

    newSequenceItem.messages.forEach((message) => {
      if (isMessageGenericGalleryTemplate(message)) {
        message.messengerTemplatePayload.forEach((messengerTemplatePayload) => {
          messengerTemplatePayload.buttons.forEach((button) => {
            if (isSequenceItemLinkButton(button)) {
              delete button.trigger
            }
          })
        })
      } else if (hasMessengerButtonOnMessageType(message)) {
        message.messengerTemplatePayload.buttons.forEach((button) => {
          if (isSequenceItemLinkButton(button)) {
            delete button.trigger
          }
        })
      }
    })

    dispatch(
      createSequenceItem(newSequenceItem, {
        x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
        y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
      })
    )
  }

  function handleCreateMessage(sequenceItemId: string, message: MessengerMessageUI) {
    dispatch(createMessage(sequenceItemId, message))
  }

  function handleRemoveMessage(sequenceItemId: string, messageIndex: number) {
    dispatch(removeMessage(sequenceItemId, messageIndex))
  }

  function handleReorderMessage(sequenceItemId: string, oldIndex: number, newIndex: number) {
    dispatch(reorderMessage(sequenceItemId, oldIndex, newIndex))
  }

  function handleCreateQuickReply(sequenceItemId: string, quickReply: QuickReply) {
    dispatch(createQuickReply(sequenceItemId, quickReply))
  }

  function handleRemoveQuickReply(sequenceItemId: string, quickReplyIndex: number) {
    dispatch(removeQuickReply(sequenceItemId, quickReplyIndex))
  }

  function handleUpdateQuickReply(sequenceItemId: string, quickReplyIndex: number, title: string) {
    dispatch(updateQuickReply(sequenceItemId, quickReplyIndex, title))
  }

  function handleReorderQuickReply(sequenceItemId: string, oldIndex: number, newIndex: number) {
    dispatch(reorderQuickReply(sequenceItemId, oldIndex, newIndex))
  }

  function handleCreateFollowUpPort(sequenceItemId: string) {
    dispatch(createFollowUpPort(sequenceItemId))
  }

  return (
    <MessageSequenceItemNodeWidgetViewComponent
      inputPort={inputPort}
      currencySlug={state.currencySlug}
      showDropZone={isDragging}
      isEntry={isEntry}
      faded={!!state.draggedItemType && state.draggedItemType !== MessageTrayItem.messengerMessage}
      onRemove={handleRemoveSequenceItem}
      onClone={handleCloneSequenceItem}
      sequenceItemNode={props.sequenceItemNode}
      repaintCanvas={handleRepaintCanvas}
      sequenceMeta={state.sequenceMeta}
      sequenceItem={state.sequenceItem}
      sequenceItemMeta={state.sequenceItemMeta}
      showSequenceItemStatistics={state.showSequenceItemStatistics}
      showDebugView={state.showDebugView}
      linking={state.linking}
      linkingMeta={state.linkingMeta}
      draggedItemType={state.draggedItemType}
      sequenceName={state.sequence.name}
      entrySequenceItemId={state.sequence.entrySequenceItemId}
      isAbandonmentSequence={state.sequence.tags.includes('abandonment')}
      createMessage={handleCreateMessage}
      removeMessage={handleRemoveMessage}
      reorderMessage={handleReorderMessage}
      createQuickReply={handleCreateQuickReply}
      removeQuickReply={handleRemoveQuickReply}
      updateQuickReply={handleUpdateQuickReply}
      reorderQuickReply={handleReorderQuickReply}
      createFollowUpPort={handleCreateFollowUpPort}
      hasVariable={props.hasVariable}
    />
  )
}

export const MessageSequenceItemNodeWidget = React.memo(MessageSequenceItemNodeWidgetComponent)
