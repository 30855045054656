import { SMSMessageMMSUI } from '@ghostmonitor/recartapis'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showErrorModal } from '../../../../../components/ui-kit/modals/error-modal/error-modal.component'
import { hooks } from '../../../../../hooks/hooks'
import { selectMessage } from '../../../../../store/selectors'
import {
  mediaUploadCompleted,
  mediaUploadStarted,
  messageValidationError
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { serializedError } from '../../../../../utils/serialized-error'
import { MessageTemplateProps } from '../../../types/message-template-props.type'
import { SequenceEditorError } from '../../../types/sequence-editor-errors'
import { ImageFormat, ImageUploadByDrag } from '../../image-upload/image-upload.component'

export function MMSImageUploaderTemplate(props: MessageTemplateProps) {
  const dispatch = useDispatch()
  const isMMSImageResizingEnabled = hooks.useIsMMSImageResizingEnabled()

  function handleFormatError() {
    return showErrorModal({
      title: 'Could not upload image',
      content: `Your file's format is invalid. Please use .jpg, ${
        isMMSImageResizingEnabled ? '.png, .webp ' : ''
      }or .gif format.`,
      okText: 'OK'
    })
  }

  function handleSizeError(type: string) {
    return showErrorModal({
      title: 'Could not upload image',
      content:
        type === 'image/gif' ? (
          <span>
            GIF size must be less than 500KB. Please upload a GIF under 500KB for optimal size and
            resolution.{' '}
            <a
              href='https://help.recart.com/en/articles/5905287-add-images-to-your-recart-sms-messages'
              target='_blank'
              rel='noreferrer'
            >
              Learn more
            </a>
          </span>
        ) : (
          `Your file's size exceeds the maximum allowed limit. Please choose an attachment under ${
            isMMSImageResizingEnabled ? '25MB' : '500KB'
          }.`
        ),
      okText: 'OK'
    })
  }

  function handleError(response) {
    const { error } = response
    const message = typeof error.detail === 'string' ? error.detail : 'Something went wrong'

    dispatch(
      messageValidationError([serializedError(SequenceEditorError.MMSImageUploadError, message)], {
        sequenceItemId: props.sequenceItemId,
        messageIndex: props.messageIndex
      })
    )
    showErrorModal({
      title: 'Could not upload image',
      content: message,
      okText: 'OK'
    })
  }

  function handleSucces(imageUrl: string) {
    const payload = {
      sequenceItemId: props.sequenceItemId,
      messageIndex: props.messageIndex,
      url: imageUrl
    }
    dispatch(mediaUploadCompleted(payload))
  }

  function handleUploadStarted() {
    const payload = {
      sequenceItemId: props.sequenceItemId,
      messageIndex: props.messageIndex
    }
    dispatch(mediaUploadStarted(payload))
  }

  const { attachments } = useSelector(
    selectMessage<SMSMessageMMSUI>(props.sequenceItemId, props.messageIndex)
  )

  const defaultAllowedImageSize = 25 * 1024 * 1024 // 25 Mbyte
  let allowedImageFormats: ImageFormat[] = [
    {
      mimeType: 'image/png',
      allowedSize: defaultAllowedImageSize,
      allowedManualSize: 500 * 1024 // 500 Kbyte
    },
    {
      mimeType: 'image/webp',
      allowedSize: defaultAllowedImageSize,
      allowedManualSize: 500 * 1024 // 500 Kbyte
    },
    {
      mimeType: 'image/jpeg',
      allowedSize: defaultAllowedImageSize,
      allowedManualSize: 500 * 1024 // 500 Kbyte
    },
    {
      mimeType: 'image/jpg',
      allowedSize: defaultAllowedImageSize,
      allowedManualSize: 500 * 1024 // 500 Kbyte
    },
    {
      mimeType: 'image/gif',
      allowedSize: defaultAllowedImageSize,
      allowedManualSize: 500 * 1024, // 500 Kbyte
      isResizeDisabled: true
    }
  ]

  if (!isMMSImageResizingEnabled) {
    allowedImageFormats = allowedImageFormats.filter(
      (format) => !['image/png', 'image/webp'].includes(format.mimeType)
    )
  }

  return (
    <ImageUploadByDrag
      apiRelativePath='media/mms'
      onSizeError={handleSizeError}
      onFormatError={handleFormatError}
      allowedImageFormats={allowedImageFormats}
      onError={handleError}
      onSuccess={handleSucces}
      imageUrl={attachments[0]}
      onUploadStarted={handleUploadStarted}
    />
  )
}
