import { OptinToolStep } from '@ghostmonitor/recartapis'
import { GeotargetingCountry } from '../../../../hooks/use-geotargeting-countries'
import { CustomerChatMenuItemSlug } from '../../../../routes/optin-tools/customer-chat/editor/utils/menu-items'
import { EmbeddedFormMenuItemSlug } from '../../../../routes/optin-tools/embedded-form/editor/menu-items'
import {
  LandingPageMenuItemSlug,
  getMenuSlugForDevice
} from '../../../../routes/optin-tools/landing-page/editor/menu-items'
import { ExportableLandingPageLink } from '../../../../routes/optin-tools/landing-page/editor/views/general/landing-page-link-select/landing-page-link-select.component'
import { PopupMenuItemSlug } from '../../../../routes/optin-tools/popup/editor/menu-items'
import { isEmailOnlyOptinTool } from '../../../../routes/optin-tools/utils/helpers'
import {
  validateABTesting,
  validateAdditionalImage,
  validateCustomerChatGreeting,
  validateEmptyExitIntentUserAction,
  validateFormItems,
  validateGeotargeting,
  validateNetworkOptin,
  validatePageTitle,
  validateSequenceId,
  validateShopifyLink,
  validateURL,
  validateURLRules
} from '../../../../routes/optin-tools/utils/validators'
import type { AppDispatch } from '../../../create-store'
import { SyncThunk } from '../../../redux-helpers'
import { selectOptinTool } from '../../../selectors'
import { OptinToolMetaErrors, updateErrorMeta } from '../optin-tool-editor.reducer'
import { getMainChannelStep } from '../templates/optin-tool-error-meta'

export function validateOptinTool(smsCountries: GeotargetingCountry[]): SyncThunk {
  return (dispatch: AppDispatch, getState): boolean => {
    let errors: OptinToolMetaErrors = {}
    const state = getState()
    const optinTool = selectOptinTool(state)

    if (optinTool.type === 'keyword') {
      return false
    }

    if (optinTool.type === 'customer-chat') {
      errors = {
        [CustomerChatMenuItemSlug.GENERAL]: {
          welcomeFlow: {
            sequenceId: validateSequenceId(optinTool.settings.sequenceId)
          }
        },
        [CustomerChatMenuItemSlug.LAYOUT_BEHAVIOR]: {
          greeting: {
            greetingMessage: validateCustomerChatGreeting(optinTool.settings.greetingMessage)
          }
        }
      }
    }

    if (optinTool.type === 'popup') {
      errors = {
        [PopupMenuItemSlug.SETTINGS]: {
          displayRules: {
            url: validateURLRules(optinTool.settings.displayRules.url),
            location: validateGeotargeting(
              optinTool.settings.displayRules.location,
              smsCountries as GeotargetingCountry[]
            ),
            abTesting: validateABTesting(optinTool.settings.displayRules.abTesting),
            userActions:
              optinTool.settings.displayRules.userAction.type === 'exit-intent'
                ? validateEmptyExitIntentUserAction(
                    optinTool.settings.displayRules.userAction.values
                  )
                : { errorMessage: '' }
          },
          welcomeFlow: {
            sequenceId: isEmailOnlyOptinTool(optinTool)
              ? { errorMessage: '' }
              : validateSequenceId(optinTool.settings.sequenceId)
          }
        },
        layout: {
          additionalImage: {
            additionalImage: validateAdditionalImage(optinTool.settings.additionalImage)
          }
        },
        success: {
          networkOptinCheckbox: {
            networkOptinCheckbox: validateNetworkOptin(optinTool.settings.success?.networkOptin)
          }
        }
      }
    }

    if (
      optinTool.type === 'popup' ||
      optinTool.type === 'landing-page' ||
      optinTool.type === 'embedded-form'
    ) {
      for (const step of ['sms', 'email', 'smsWithEmail']) {
        if (optinTool.steps.includes(step as OptinToolStep)) {
          errors = {
            ...errors,
            [step]: {
              form: {
                items: optinTool.settings[step]?.form?.items
                  ? validateFormItems(optinTool.settings[step]?.form?.items)
                  : []
              },
              networkOptinCheckbox: {
                networkOptinCheckbox: validateNetworkOptin(optinTool.settings[step]?.networkOptin)
              }
            }
          }
        }
      }
    }

    if (optinTool.type === 'landing-page') {
      const step = getMainChannelStep(optinTool.steps)

      const smsMenuItemSlug =
        step === 'sms' ? LandingPageMenuItemSlug.SMS : LandingPageMenuItemSlug.SMS_WITH_EMAIL

      errors = {
        [LandingPageMenuItemSlug.SETTINGS]: {
          displayRules: {
            pageTitle: validatePageTitle(optinTool.pageTitle),
            link:
              (optinTool.link as ExportableLandingPageLink).type === 'custom'
                ? validateURL((optinTool.link as ExportableLandingPageLink).url)
                : validateShopifyLink(
                    (optinTool.link as ExportableLandingPageLink).url.split('/pages/')[1]
                  ),
            sequenceId: validateSequenceId(optinTool.settings.sequenceId),
            location: validateGeotargeting(
              optinTool.settings.displayRules.location,
              smsCountries as GeotargetingCountry[]
            )
          }
        },
        [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.LAYOUT)]: {
          additionalImage: {
            additionalImage: validateAdditionalImage(optinTool.settings.desktop.additionalImage)
          }
        },
        [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.EMAIL)]: {
          form: {
            items: optinTool.settings.desktop[LandingPageMenuItemSlug.EMAIL]?.form?.items
              ? validateFormItems(
                  optinTool.settings.desktop[LandingPageMenuItemSlug.EMAIL].form?.items
                )
              : []
          }
        },
        [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.SMS_SUCCESS)]: {
          button: {
            link: validateURL(optinTool.settings.desktop[step].success.button.link)
          }
        },
        [getMenuSlugForDevice('desktop', smsMenuItemSlug)]: {
          form: {
            items: optinTool.settings.desktop[smsMenuItemSlug].form?.items
              ? validateFormItems(optinTool.settings.desktop[smsMenuItemSlug].form?.items)
              : []
          }
        },
        [getMenuSlugForDevice('desktop', LandingPageMenuItemSlug.GEOTARGETING_ERROR)]: {
          button: {
            link:
              optinTool.settings.desktop.geotargetingError.button.type === 'copied'
                ? { errorMessage: '' }
                : validateURL(optinTool.settings.desktop.geotargetingError.button.link)
          }
        },
        [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.LAYOUT)]: {
          additionalImage: {
            additionalImage:
              optinTool.settings.mobile.additionalImage?.image.type === 'copied'
                ? { errorMessage: '' }
                : validateAdditionalImage(optinTool.settings.mobile.additionalImage)
          }
        },
        [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.EMAIL)]: {
          form: {
            items: optinTool.settings.mobile[LandingPageMenuItemSlug.EMAIL]?.form?.items
              ? validateFormItems(
                  optinTool.settings.mobile[LandingPageMenuItemSlug.EMAIL].form?.items
                )
              : []
          }
        },
        [getMenuSlugForDevice('mobile', smsMenuItemSlug)]: {
          form: {
            items: optinTool.settings.mobile[smsMenuItemSlug].form?.items
              ? validateFormItems(optinTool.settings.mobile[smsMenuItemSlug].form?.items)
              : []
          }
        },
        [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.SMS_SUCCESS)]: {
          button: {
            link: validateURL(optinTool.settings.mobile[step].success.button.link)
          }
        },
        [getMenuSlugForDevice('mobile', LandingPageMenuItemSlug.GEOTARGETING_ERROR)]: {
          button: {
            link:
              optinTool.settings.mobile.geotargetingError.button.type === 'copied'
                ? { errorMessage: '' }
                : validateURL(optinTool.settings.mobile.geotargetingError.button.link)
          }
        }
      }
    }
    if (optinTool.type === 'embedded-form') {
      const step = getMainChannelStep(optinTool.steps)

      const smsMenuItemSlug =
        step === 'sms' ? EmbeddedFormMenuItemSlug.SMS : EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL

      errors = {
        [EmbeddedFormMenuItemSlug.SETTINGS]: {
          displayRules: {
            sequenceId: validateSequenceId(optinTool.settings.sequenceId),
            location: validateGeotargeting(
              optinTool.settings.displayRules.location,
              smsCountries as GeotargetingCountry[]
            )
          }
        },
        [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.LAYOUT)]: {
          additionalImage: {
            additionalImage: validateAdditionalImage(optinTool.settings.desktop.additionalImage)
          }
        },
        [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.EMAIL)]: {
          form: {
            items: optinTool.settings.desktop[EmbeddedFormMenuItemSlug.EMAIL]?.form?.items
              ? validateFormItems(
                  optinTool.settings.desktop[EmbeddedFormMenuItemSlug.EMAIL].form?.items
                )
              : []
          }
        },
        [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS_SUCCESS)]: {
          button: {
            link: validateURL(optinTool.settings.desktop[step].success?.button?.link)
          }
        },
        [getMenuSlugForDevice('desktop', smsMenuItemSlug)]: {
          form: {
            items: optinTool.settings.desktop[smsMenuItemSlug].form?.items
              ? validateFormItems(optinTool.settings.desktop[smsMenuItemSlug].form?.items)
              : []
          }
        },
        [getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR)]: {
          button: {
            link:
              optinTool.settings.desktop.geotargetingError.button?.type === 'copied'
                ? { errorMessage: '' }
                : validateURL(optinTool.settings.desktop.geotargetingError.button?.link)
          }
        },
        [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.LAYOUT)]: {
          additionalImage: {
            additionalImage:
              optinTool.settings.mobile.additionalImage?.image.type === 'copied'
                ? { errorMessage: '' }
                : validateAdditionalImage(optinTool.settings.mobile.additionalImage)
          }
        },
        [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.EMAIL)]: {
          form: {
            items: optinTool.settings.mobile[EmbeddedFormMenuItemSlug.EMAIL]?.form?.items
              ? validateFormItems(
                  optinTool.settings.mobile[EmbeddedFormMenuItemSlug.EMAIL].form?.items
                )
              : []
          }
        },
        [getMenuSlugForDevice('mobile', smsMenuItemSlug)]: {
          form: {
            items: optinTool.settings.mobile[smsMenuItemSlug].form?.items
              ? validateFormItems(optinTool.settings.mobile[smsMenuItemSlug].form?.items)
              : []
          }
        },
        [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS_SUCCESS)]: {
          button: {
            link: validateURL(optinTool.settings.mobile[step].success?.button?.link)
          }
        },
        [getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR)]: {
          button: {
            link:
              optinTool.settings.mobile.geotargetingError.button?.type === 'copied'
                ? { errorMessage: '' }
                : validateURL(optinTool.settings.mobile.geotargetingError.button?.link)
          }
        }
      }
    }
    dispatch(updateErrorMeta({ errors }))
    const isError = Object.values(errors).some((view) => {
      return Object.values(view)
        .flatMap((menuSlug) => Object.values(menuSlug))
        .some((errorField) => {
          if (Array.isArray(errorField)) {
            return errorField.some((field) => field.errorMessage !== '')
          }
          return errorField.errorMessage !== ''
        })
    })

    return isError
  }
}
