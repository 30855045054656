// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YLoIadZ7K46eDaRTywLo{align-items:center;display:flex;justify-content:flex-end;margin-top:8px}.YLoIadZ7K46eDaRTywLo .pY8By9Z90wLztQXL5tvj{margin-right:12px}.YLoIadZ7K46eDaRTywLo .hsKbe6s9zeuIEQJM84UW{align-items:center;display:flex;height:100%}.YLoIadZ7K46eDaRTywLo .Glyj9MTPmTA5BZRof13K{align-items:center;display:flex;height:100%}.hGaTjUoBjoRd70q3BA8I{color:#8c8c8c}.SHWJmG_UkJHrnG5vIrUp{color:#979ba1}.gvo1l17eRHbUGyOUiN_t{color:#212b36;font-weight:600}.PLGQ0S_a2y0T8pKVrGqQ{align-items:center;display:flex;justify-content:center;margin-bottom:18px}.PLGQ0S_a2y0T8pKVrGqQ svg{color:#faad14;height:21px;margin-right:18px;width:21px}.wrapped-form-item-no-margin.ant-form-item{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/save-modal/save-modal.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAGE,kBAAA,CAFA,YAAA,CACA,wBAAA,CAEA,cAAA,CAEA,4CACE,iBAAA,CAGF,4CAGE,kBAAA,CAFA,YAAA,CACA,WACA,CAGF,4CAGE,kBAAA,CAFA,YAAA,CACA,WACA,CAIJ,sBACE,aCVK,CDaP,sBACE,aAAA,CAGF,sBACE,aAAA,CACA,eAAA,CAGF,sBAGE,kBAAA,CAFA,YAAA,CACA,sBAAA,CAEA,kBAAA,CAEA,0BAIE,aAAA,CAFA,WAAA,CACA,iBAAA,CAFA,UAGA,CAOF,2CACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `YLoIadZ7K46eDaRTywLo`,
	"button-margin": `pY8By9Z90wLztQXL5tvj`,
	"right": `hsKbe6s9zeuIEQJM84UW`,
	"left": `Glyj9MTPmTA5BZRof13K`,
	"inputComment": `hGaTjUoBjoRd70q3BA8I`,
	"note": `SHWJmG_UkJHrnG5vIrUp`,
	"noteLabel": `gvo1l17eRHbUGyOUiN_t`,
	"warn": `PLGQ0S_a2y0T8pKVrGqQ`
};
export default ___CSS_LOADER_EXPORT___;
