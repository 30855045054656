import { Stat } from '@ghostmonitor/recartapis'
import { useCallback, useContext } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { api } from '../../utils/api'
import { UseResourceCache } from '../types/use-resource-cache.type'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export interface SequenceItemsStatsById {
  [sequenceItemId: string]: Stat
}

export function useSequenceItemsStat(
  sequenceId: string,
  enabled?: boolean
): UseResource<SequenceItemsStatsById> {
  const queryName = [QUERY_NAME.sequenceItemsStat, sequenceId]
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryParams: {
    sequenceId: string
    startDate?: string
    endDate?: string
  } = {
    sequenceId
  }

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
    queryName.push(dateFrom.toISOString())
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
    queryName.push(dateTo.toISOString())
  }

  const {
    isInitialLoading,
    data: sequenceItemsStat,
    isError
  } = useQuery<SequenceItemsStatsById>(
    queryName,
    async () => api.getSequenceItemsStat(queryParams),
    {
      enabled: sequenceId !== undefined && enabled
    }
  )

  return {
    data: sequenceItemsStat,
    isLoading: isInitialLoading,
    isError
  }
}

export function useSequenceItemsStatCache(sequenceId: string): UseResourceCache {
  const queryClient = useQueryClient()

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([QUERY_NAME.sequenceItemsStat, sequenceId])
  }, [queryClient, sequenceId])

  return {
    invalidateCache
  }
}
