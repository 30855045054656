// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pAaGRxu0rAAPj3l89rVv{align-items:center;background-color:#fff;box-sizing:border-box;color:#476dff;display:flex;font-size:14px;height:100%;justify-content:center;min-height:30px;padding:2px 10px;transition:all .15s linear;width:100%}.pAaGRxu0rAAPj3l89rVv .vf4bgi0qHsu6saME1kgc{flex:1}.pAaGRxu0rAAPj3l89rVv svg{height:20px;width:auto}.pAaGRxu0rAAPj3l89rVv.Jj1Ux9snTIg7coefaJPk.glKGgN4yhTTr3MqreGE7{background-color:#fff;color:#476dff}.pAaGRxu0rAAPj3l89rVv:not(.Jj1Ux9snTIg7coefaJPk):hover{background-color:#476dff;color:#fff;transition:all .15s linear}.pAaGRxu0rAAPj3l89rVv:not(.Jj1Ux9snTIg7coefaJPk).glKGgN4yhTTr3MqreGE7{color:#476dff}.pAaGRxu0rAAPj3l89rVv.glKGgN4yhTTr3MqreGE7{background-color:#476dff}.pAaGRxu0rAAPj3l89rVv.glKGgN4yhTTr3MqreGE7 svg{color:#fff;stroke-width:2px}.pAaGRxu0rAAPj3l89rVv[disabled]{opacity:.3}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/canvas-toolbar/canvas-button/canvas-button.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAEA,sBAKE,kBAAA,CAHA,qBAAA,CAQA,qBAAA,CAPA,aCFc,CDAd,YAAA,CAGA,cAAA,CAIA,WAAA,CAFA,sBAAA,CAGA,eAAA,CAFA,gBAAA,CAIA,0BAAA,CACA,UAAA,CAEA,4CACE,MAAA,CAGF,0BACE,WAAA,CACA,UAAA,CAIA,gEACE,qBAAA,CACA,aCzBU,CD8BZ,uDACE,wBC/BU,CDgCV,UAAA,CACA,0BAAA,CAGF,sEACE,aCrCU,CDyCd,2CACE,wBC1CY,CD4CZ,+CACE,UAAA,CACA,gBAAA,CAIJ,gCACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas-button": `pAaGRxu0rAAPj3l89rVv`,
	"label": `vf4bgi0qHsu6saME1kgc`,
	"readOnly": `Jj1Ux9snTIg7coefaJPk`,
	"active": `glKGgN4yhTTr3MqreGE7`
};
export default ___CSS_LOADER_EXPORT___;
