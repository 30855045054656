import { BillingSubscription, GetBillingSubscriptionsResponse } from '@ghostmonitor/recartapis'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

type UseBillingSubscriptionsOptions = UseQueryOptions<GetBillingSubscriptionsResponse>

type UseBillingSubscriptions = UseResource<BillingSubscription[]>

export function useBillingSubscriptions(
  options?: UseBillingSubscriptionsOptions
): UseBillingSubscriptions {
  const queryName = [QUERY_NAME.billingSubscriptions]

  const {
    isLoading,
    data: billingSubscriptions,
    isError,
    refetch
  } = useQuery<GetBillingSubscriptionsResponse>(queryName, api.getBillingSubscriptions, options)

  return {
    data: billingSubscriptions?.data,
    isLoading,
    isError,
    refetch: refetch as any
  }
}
