// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mmcQvsdlKhTPvm2ZmxTb{border:none;border-radius:2px;font-size:12px;font-weight:700}.mmcQvsdlKhTPvm2ZmxTb.ub94JcOKRnS7GtNg6lEa{background-color:rgba(71,109,255,.1);color:#476dff}.mmcQvsdlKhTPvm2ZmxTb.jSIMQeYmaHEFdzNMC1hR{background-color:#e0daff;color:#6d56f7}.mmcQvsdlKhTPvm2ZmxTb._byoalrA04EAsW2DFdAV{background-color:#ffe58f;color:#746b78}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/add-element-button/add-element-button.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,WAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CAEA,2CACE,oCCCiB,CAAA,aARP,CDWZ,2CACE,wBCiDO,CDhDP,aCqDO,CDlDT,2CACE,wBCsEgC,CDrEhC,aCwE2B","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `mmcQvsdlKhTPvm2ZmxTb`,
	"followUp": `ub94JcOKRnS7GtNg6lEa`,
	"condition": `jSIMQeYmaHEFdzNMC1hR`,
	"split": `_byoalrA04EAsW2DFdAV`
};
export default ___CSS_LOADER_EXPORT___;
