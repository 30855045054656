import { SendingFrequency } from '@ghostmonitor/recartapis'
import { Action, createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../redux-helpers'

export const UPDATE_QUIET_HOURS = 'sequence/updateQuietHours'
export const UPDATE_SEGMENT_ID = 'sequence/updateSegmentId'
export const UPDATE_SENDING_FREQUENCY = 'sequence/updateSendingFrequency'
export const UPDATE_SEQUENCE_NAME = 'sequence/updateSequenceName'

export interface UpdateQuietHours extends Action {
  isQuietHoursEnabled: boolean
}

export const updateQuietHours = createAction(
  UPDATE_QUIET_HOURS,
  withPayloadType<{ isQuietHoursEnabled: boolean }>()
)

export interface UpdateSegmentId extends Action {
  segmentId: string
}

export const updateSegmentId = createAction(
  UPDATE_SEGMENT_ID,
  withPayloadType<{ segmentId: string }>()
)

export interface UpdateSendingFrequency extends Action {
  sendingFrequency: SendingFrequency
}

export const updateSendingFrequency = createAction(
  UPDATE_SENDING_FREQUENCY,
  withPayloadType<{ sendingFrequency: SendingFrequency }>()
)

export interface UpdateSequenceName extends Action {
  name: string
}

export const updateSequenceName = createAction(
  UPDATE_SEQUENCE_NAME,
  withPayloadType<{ name: string }>()
)
