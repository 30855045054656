// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GxOtXp8NxwyaW8zEUY28{font-size:var(--recart-font-size-sm);font-weight:var(--recart-font-weight-bold);line-height:var(--recart-line-height-sm);margin-bottom:var(--recart-spacing-3);margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/settings-drawer/components/label/label.component.scss"],"names":[],"mappings":"AACE,sBACE,oCAAA,CAEA,0CAAA,CADA,wCAAA,CAGA,qCAAA,CADA,eACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `GxOtXp8NxwyaW8zEUY28`
};
export default ___CSS_LOADER_EXPORT___;
