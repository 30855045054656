import { NodeType, PortType } from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { DiagramEngine, NodeModel, PortModel } from 'storm-react-diagrams'
import { SequenceItemPortModel } from '../port/sequence-item-port-model'
import { INPUT_PORT } from './port-names'

export abstract class BaseSequenceItemNodeModel extends NodeModel {
  public sequenceId: string
  public sequenceItemId: string

  protected constructor(type: NodeType, sequenceItemId: string, id?: string) {
    super(type, id)
    this.addPort(new SequenceItemPortModel(INPUT_PORT))
    if (sequenceItemId) {
      this.sequenceItemId = sequenceItemId
    } else {
      this.sequenceItemId = new ObjectId().toHexString()
    }
  }

  public serialize() {
    return {
      ...super.serialize(),
      sequenceItemId: this.sequenceItemId
    }
  }

  public deSerialize(serializedDiagram: any, engine: DiagramEngine): void {
    this.removePorts()
    super.deSerialize(serializedDiagram, engine)
    this.sequenceItemId = serializedDiagram.sequenceItemId
  }

  public removePorts() {
    const ports = this.getPorts()
    Object.values(ports).forEach((port) => this.removePort(port))
  }

  public getInputPort(): SequenceItemPortModel {
    const ports = Object.values(this.getPorts())
    for (const port of ports) {
      if (port.type === PortType.SEQUENCE_ITEM) {
        return port as SequenceItemPortModel
      }
    }
  }

  public getPortsByType(portType: PortType): PortModel[] {
    return Object.values(this.getPorts()).filter((port) => port.type === portType)
  }

  public getConnectedPorts(port: PortModel): PortModel[] {
    const connectedPorts: PortModel[] = []
    const links = Object.values(port.getLinks())

    links.forEach((link) => {
      const sourcePort = link.getSourcePort()
      const targetPort = link.getTargetPort()

      const connectedPort = sourcePort.id === port.id ? targetPort : sourcePort

      connectedPorts.push(connectedPort)
    })

    return connectedPorts
  }
}
