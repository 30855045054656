import { Channel, SequenceTag, SequenceUI } from '@ghostmonitor/recartapis'
import { isCustomWelcomeSequence } from '../utils/is-custom-welcome-sequence'
import { useSequencesByTags } from './resources/use-sequences-by-tags'
import { UseResource } from './types/use-resource.type'

export function useCustomWelcomeSequences(channel?: Channel): UseResource<SequenceUI[]> {
  const { data: welcomeSequences, isLoading } = useSequencesByTags([SequenceTag.WELCOME])

  let channelTag = null

  if (channel === 'messenger') {
    channelTag = SequenceTag.FBMESSAGE
  }

  if (channel === 'sms') {
    channelTag = SequenceTag.SMS
  }

  const customWelcomeSequences = welcomeSequences?.filter(isCustomWelcomeSequence)

  return {
    data: channelTag
      ? customWelcomeSequences?.filter((sequence) => sequence.tags.includes(channelTag))
      : customWelcomeSequences,
    isLoading
  }
}
