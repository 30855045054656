// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SsW0uNOWYXJCYm6y7G1g{color:var(--recart-night-blue-1)}.p_3Yj1Rf3ZFl53gpDgAE{color:#ff4d4f;padding-top:var(--recart-spacing-1)}`, "",{"version":3,"sources":["webpack://./src/components/editor-drawer/components/loopwork/loopwork.component.scss"],"names":[],"mappings":"AACE,sBACE,gCAAA,CAGF,sBACE,aAAA,CACA,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `SsW0uNOWYXJCYm6y7G1g`,
	"error": `p_3Yj1Rf3ZFl53gpDgAE`
};
export default ___CSS_LOADER_EXPORT___;
