import { removeFormattingHTMLTags, removeHTMLEntities } from './convert-html'
import { convertUserVariables } from './get-variables'
import { removeUnwantedTags } from './remove-unwanted-tags'
import {
  replaceUserVariablesToRawVariables,
  replaceVariableTagsToRawVariables
} from './replace-variable'
import { variableOptionNames } from './variable-type-options'

export function replaceHtmlValueToRawValue(htmlValue: string): string {
  let newRawValue = convertUserVariables(htmlValue)
  newRawValue = replaceVariableTagsToRawVariables(newRawValue, variableOptionNames)
  newRawValue = replaceUserVariablesToRawVariables(newRawValue)
  newRawValue = removeFormattingHTMLTags(newRawValue)
  newRawValue = removeUnwantedTags(newRawValue)
  newRawValue = removeHTMLEntities(newRawValue)
  return newRawValue
}
