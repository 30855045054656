import { SequenceUI } from '@ghostmonitor/recartapis'
import difference from 'lodash/difference'
import { useMemo } from 'react'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'

export function useSequencesByTags(
  tags: string[],
  excludeTags: string[] = []
): UseResource<SequenceUI[], Partial<SequenceUI>> {
  const {
    isLoading,
    data: sequences,
    isError,
    patch,
    removeFromList: remove,
    refetch,
    invalidateCache,
    toggle
  } = hooks.useSequences()

  const tagFilteredSequences = useMemo(() => {
    const sequencesWithTags = sequences?.filter(
      (sequence) => difference(tags, sequence.tags).length === 0
    )

    return sequencesWithTags?.filter((sequence) =>
      sequence.tags.every((tag) => !excludeTags.includes(tag))
    )
  }, [sequences, tags, excludeTags])

  return {
    data: sequences && tagFilteredSequences,
    isLoading,
    isError,
    patch,
    removeFromList: remove,
    refetch,
    invalidateCache,
    toggle
  }
}
