import { useSelector } from 'react-redux'
import { hooks } from '../../../../hooks/hooks'
import { SequenceItemsStatsById } from '../../../../hooks/resources/use-sequence-items-stat'
import { UseResourceCache } from '../../../../hooks/types/use-resource-cache.type'
import { UseResource } from '../../../../hooks/types/use-resource.type'
import { selectEditorSequence, selectEditorSequenceMeta } from '../../../../store/selectors'

export function useEditorSequenceItemsStat(): UseResource<SequenceItemsStatsById> {
  const sequence = useSelector(selectEditorSequence)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)

  const { data, isLoading, isError } = hooks.useSequenceItemsStat(
    sequence?._id,
    sequenceMeta.isEverSaved
  )
  return {
    data,
    isLoading,
    isError
  }
}
export function useEditorSequenceItemsStatCache(): UseResourceCache {
  const sequence = useSelector(selectEditorSequence)
  const { invalidateCache } = hooks.useSequenceItemsStatCache(sequence?._id)
  return {
    invalidateCache
  }
}
