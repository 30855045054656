export const emptyQuietHoursConfig = {
  start: {
    hour: 0,
    minute: 0
  },
  end: {
    hour: 0,
    minute: 0
  }
}
