import { MessengerMessageUI, URLGenerationSettings } from '@ghostmonitor/recartapis'
import { Action, SerializedError, Store } from '@reduxjs/toolkit'
import debuncePromise from 'debounce-promise'
import memoize from 'lodash/memoize'
import { batch } from 'react-redux'
import type { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import {
  selectEditorBlast,
  selectEditorSequenceMeta,
  selectIsReadOnly,
  selectMessage,
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../store/selectors'
import {
  CREATE_MESSAGE_ITEM,
  CreateMessageItemAction,
  REMOVE_MESSAGE_ITEM,
  RemoveMessageItemAction,
  UPDATE_MESSAGE,
  UpdateMessageAction,
  mediaUploadCompleted,
  messageValidationError,
  messageValidationSuccess
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { serializedError } from '../../../../utils/serialized-error'
import { SequenceEditorError } from '../../types/sequence-editor-errors'
import { getSequenceEditorSMSStat } from '../../utils/get-sms-stat'
import { messageValidator } from '../../validators/message.validator'

const DEBOUNCE_DELAY = 400
export type ValidableMessageAction =
  | UpdateMessageAction
  | CreateMessageItemAction
  | RemoveMessageItemAction

function isValidableAction(action: Action): action is ValidableMessageAction {
  return [
    UPDATE_MESSAGE,
    CREATE_MESSAGE_ITEM,
    REMOVE_MESSAGE_ITEM,
    mediaUploadCompleted.type
  ].includes(action.type)
}

function getValidateMessage(dispatch: AppDispatch) {
  const memoizedMessageValidator = memoize(messageValidator)

  return debuncePromise(
    async (
      message: MessengerMessageUI,
      sequenceItemId: string,
      messageIndex: number,
      actionType: string,
      excludeDiscountCodes: string[],
      characterCount?: number
    ) => {
      if (!message) {
        return
      }
      const messageErrors = memoizedMessageValidator(message, characterCount)

      const discountCodeErrors: SerializedError[] = []

      if (messageErrors.length > 0 || discountCodeErrors.length > 0) {
        dispatch(
          messageValidationError(
            [
              ...messageErrors,
              ...(discountCodeErrors.length > 0
                ? [serializedError(SequenceEditorError.MessageInvalidDiscountCode)]
                : [])
            ],
            {
              sequenceItemId,
              messageIndex
            }
          )
        )
      } else {
        dispatch(
          messageValidationSuccess(null, {
            sequenceItemId,
            messageIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true }
  )
}

export function validateMessageMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateMessage = getValidateMessage(dispatch)

  return (next) => (action: ValidableMessageAction) => {
    next(action)

    if (isValidableAction(action)) {
      const state = store.getState()
      const sequenceMeta = selectEditorSequenceMeta(state)
      const siteName = selectSiteName(state)
      const urlGenerationSettings = selectUrlGenerationSettings(state)
      const supportEmail = selectSupportEmail(state)
      const blast = selectEditorBlast(state)
      const isReadOnly = selectIsReadOnly(state)
      const { sequenceItemId, messageIndex } = mediaUploadCompleted.match(action)
        ? action.payload
        : action
      const message = selectMessage(sequenceItemId, messageIndex)(state)
      const { characterCount } = getSequenceEditorSMSStat(message?.text ?? '', {
        siteName,
        supportEmail,
        urlGenerationSettings: urlGenerationSettings as URLGenerationSettings,
        blast,
        isReadOnly
      })

      batch(() => {
        validateMessage(
          message,
          sequenceItemId,
          messageIndex,
          action.type,
          sequenceMeta.validDiscountCodes,
          characterCount
        )
      })
    }
  }
}
