// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QTjc1ttXHfElxKAXTUG2{align-items:center;display:flex;flex-direction:column;height:100vh;justify-content:center}.HlcIEKGGyJ6duDoOgyFw{height:66px}.mvdMI1TthBgQy_TyCqtL{margin-left:26px;margin-top:25px}.mvdMI1TthBgQy_TyCqtL i{background-color:var(--recart-night-blue-1)!important;height:11px!important;width:11px!important}`, "",{"version":3,"sources":["webpack://./src/components/app-loader/app-loader.component.scss"],"names":[],"mappings":"AACE,sBAGE,kBAAA,CADA,YAAA,CAGA,qBAAA,CAJA,YAAA,CAGA,sBACA,CAGF,sBACE,WAAA,CAGF,sBACE,gBAAA,CACA,eAAA,CAEA,wBAGE,qDAAA,CADA,qBAAA,CADA,oBAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `QTjc1ttXHfElxKAXTUG2`,
	"logo": `HlcIEKGGyJ6duDoOgyFw`,
	"spinner": `mvdMI1TthBgQy_TyCqtL`
};
export default ___CSS_LOADER_EXPORT___;
