import {
  convertSequenceAPIToSequenceUI,
  convertSequenceItemAPIToSequenceItemUI,
  isMessengerMessageSequenceItemUI
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { attachQuickRepliesIdsOnSequenceItem } from '../../../routes/SequenceEditor/middlewares/utils/attach-id-to-quick-replies'
import { api } from '../../../utils/api'
import { SyncThunk } from '../../redux-helpers'
import {
  sequenceCreated,
  updateSequence,
  updateSequenceItem
} from '../../slices/sequence-editor/sequence-editor.actions'
import { patchSequence } from './sequence.actions'

export const updateSequenceName =
  (sequenceId: string, name: string): SyncThunk =>
  (dispatch) => {
    dispatch(patchSequence({ name }, { sequenceId }))
  }

export const duplicateSequenceThunk = createAsyncThunk(
  'sequence/duplicateSequence',
  async (sequenceId: string, store) => {
    const sequence = await api.duplicateSequence(sequenceId)

    batch(() => {
      store.dispatch(updateSequence({ sequence: convertSequenceAPIToSequenceUI(sequence) }))

      sequence.sequenceItems.forEach((sequenceItemAPI) => {
        let sequenceItem = convertSequenceItemAPIToSequenceItemUI(sequenceItemAPI)
        if (isMessengerMessageSequenceItemUI(sequenceItem)) {
          sequenceItem = attachQuickRepliesIdsOnSequenceItem(
            sequenceItem,
            sequence.quickReplyIndexToId
          )
        }
        store.dispatch(updateSequenceItem({ sequenceItem }))
      })

      store.dispatch(sequenceCreated())
    })

    return sequence._id
  }
)
