import type { SyncThunk } from '../../../../store/redux-helpers'
import { selectEntrySequenceItem } from '../../../../store/selectors'
import { setSequenceEditorSetting } from '../../hooks/use-sequence-editor-settings-context'
import { isAbandonmentSequenceItem } from '../../utils/assert-sequence-item-type'
import { SequenceEditorSettings } from '../context/sequence-editor-settings.type'

export function abandonedSequenceThunk(): SyncThunk {
  return (dispatch, getState) => {
    const state = getState()
    const entrySequenceItem = selectEntrySequenceItem(state)
    const isAbandonmentSequence = isAbandonmentSequenceItem(entrySequenceItem)
    if (isAbandonmentSequence) {
      setSequenceEditorSetting(SequenceEditorSettings.hideMessengerSendTestButton, true)
    }
  }
}
