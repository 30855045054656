import { DatePicker } from 'antd'
import { RangePickerProps as AntRangePickerProps } from 'antd/lib/date-picker'
import cn from 'classnames'
import { Moment } from 'moment'
import React from 'react'
import { ReactComponent as CalendarIcon } from '../../../static/ui-kit/icons/small/calendar.svg'
import { dateFormat } from '../../../utils/utils'
import { RangePickerSeparator } from './range-picker-separator.component'
import styles from './range-picker.component.scss'

export interface SelectedDateProps {
  dateFrom: Moment
  dateTo: Moment
}

type RangePickerProps = AntRangePickerProps

export function RangePicker(props: RangePickerProps) {
  const { className, ...restProps } = props

  return (
    <DatePicker.RangePicker
      separator={<RangePickerSeparator />}
      size='large'
      format={dateFormat}
      className={cn(styles.rangepicker, className)}
      suffixIcon={<CalendarIcon className='icon-xs text-secondary' />}
      allowClear={false}
      {...restProps}
    />
  )
}
