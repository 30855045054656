import cn from 'classnames'
import React from 'react'
import { DefaultLinkFactory, DiagramEngine, Toolkit } from 'storm-react-diagrams'
import { INPUT_PORT } from '../sequence-item/port-names'
import { SequenceEditorLinkModel } from './link-model'
import { SequenceEditorLinkWidget, SequenceEditorLinkWidgetProps } from './link-widget'

enum Direction {
  FORWARD,
  BACKWARD
}

export class SequenceEditorLinkFactory extends DefaultLinkFactory {
  public modelChange?: (serializeDiagram: any) => void

  constructor(onModelChange = (serializeDiagram: any) => {}) {
    super()
    this.type = 'sequence-editor'
    this.modelChange = onModelChange
  }

  public generateReactWidget(
    diagramEngine: DiagramEngine,
    link: SequenceEditorLinkModel
  ): JSX.Element {
    const LinkWidget = SequenceEditorLinkWidget
    const linkWidgetProps: SequenceEditorLinkWidgetProps = {
      link,
      diagramEngine,
      modelChange: this.modelChange
    }
    return React.createElement(LinkWidget, linkWidgetProps)
  }

  public getNewInstance(): SequenceEditorLinkModel {
    return new SequenceEditorLinkModel()
  }

  public generateLinkSegment(
    model: SequenceEditorLinkModel,
    widget: SequenceEditorLinkWidget,
    selected: boolean,
    path: string
  ): JSX.Element {
    const connected = model.sourcePort && model.targetPort
    const markerId = Toolkit.UID()
    const markerEndUrl = `url(#${markerId})`
    let direction = Direction.FORWARD
    if (connected) {
      const p1 = model.getSourcePort()
      direction = p1.name === INPUT_PORT ? Direction.BACKWARD : Direction.FORWARD
    }

    return (
      <g>
        <defs>
          <marker
            id={markerId}
            markerWidth='6'
            markerHeight='6'
            viewBox='0 0 10 10'
            refX='13'
            refY='5'
            markerUnits='strokeWidth'
          >
            <path
              d='M 0 0 L 8 5 L 0 10 z'
              className={selected ? widget.bem('--marker-selected') : widget.bem('-marker')}
            />
          </marker>
        </defs>
        <path
          className={cn({
            [widget.bem('--path-selected')]: selected && !connected,
            [widget.bem('--path-delete')]: selected && connected
          })}
          strokeWidth={model.width}
          stroke={model.color}
          d={path}
          markerEnd={connected && direction === Direction.FORWARD ? markerEndUrl : 'none'}
          markerStart={connected && direction === Direction.BACKWARD ? markerEndUrl : 'none'}
        />
      </g>
    )
  }
}
