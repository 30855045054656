import { FlowTemplateSlug, FlowUI, SequenceTag, SiteEngineSlug } from '@ghostmonitor/recartapis'
import difference from 'lodash/difference'

export type PlaceholderFlow = Pick<FlowUI, 'name' | 'tags' | 'source'> & {
  _id?: string
  description?: string
  supportedSiteEngines: string[]
  isEnabled: boolean
}

export const smsWelcomePlaceholderFlows: PlaceholderFlow[] = [
  {
    name: 'Welcome Save User Input',
    source: { templateSlug: FlowTemplateSlug.SMS_WELCOME_SAVE_USER_INPUT },
    tags: [SequenceTag.WELCOME, SequenceTag.SMS, SequenceTag.SAVE_USER_INPUT],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  },
  {
    name: 'Welcome Checkout Subscription',
    source: { templateSlug: FlowTemplateSlug.SMS_WELCOME_CHECKOUT_SUBSCRIPTION },
    tags: [SequenceTag.ORDER_PAGE_WIDGET_OPTIN, SequenceTag.WELCOME, SequenceTag.SMS],
    supportedSiteEngines: [SiteEngineSlug.SHOPIFY, SiteEngineSlug.WOOCOMMERCE],
    isEnabled: false
  }
]

export function isSMSFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.SMS)
}

export function isSMSCampaignFlowUI(flow: FlowUI): boolean {
  return isSMSFlowUI(flow) && flow?.tags.includes(SequenceTag.CAMPAIGN)
}

export function isJoinedASegmentFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.JOINED_A_SEGMENT)
}

export function isAutomatedFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.AUTOMATED)
}

export function isWelcomeFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.WELCOME)
}

export function isBuiltInWelcomeFlowUI(flow: FlowUI): boolean {
  const placeholders = [...smsWelcomePlaceholderFlows]
  return placeholders.some((placeholder) => difference(placeholder.tags, flow.tags).length === 0)
}

export function isTypeformFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.TYPEFORM)
}

export function isWheelioFlowUI(flow: FlowUI): boolean {
  return flow?.tags.includes(SequenceTag.WHEELIO_OPTIN)
}

export function isWelcomeCustomIntegrationFlowUI(flow: FlowUI): boolean {
  return flow?.source?.templateSlug === 'sms-welcome-custom-integration'
}

// todo: remove this if no more placeholder logic will be in fer templates system
export function isCustomWelcomeFlowUI(flow: FlowUI): boolean {
  return (
    isWelcomeFlowUI(flow) &&
    !isBuiltInWelcomeFlowUI(flow) &&
    !isTypeformFlowUI(flow) &&
    !isWheelioFlowUI(flow)
  )
}
