import { useQuery } from '@tanstack/react-query'
import { request } from '../utils/request'
import { QUERY_NAME } from './resources/query-names'

export function useLandingPageCode(optinToolId: string): { data: string; isLoading: boolean } {
  const { data, isInitialLoading } = useQuery<string>(
    [QUERY_NAME.landingPageCode],
    async () => {
      return request.get<string>(`landing-page/${optinToolId}/embed-code`)
    },
    { enabled: !!optinToolId }
  )

  return {
    data,
    isLoading: isInitialLoading
  }
}
