import { User } from '@ghostmonitor/recartapis'
import * as site from '../site/site.selectors'
import { MeState } from './me.types'

export const selectSite = (state: MeState) => state.site
export const selectSiteId = (state: MeState) => site.selectSiteId(state.site)
export const selectUrlGenerationSettings = (state: MeState) =>
  site.selectUrlGenerationSettings(state.site)
export const selectSiteUrl = (state: MeState) => site.selectSiteUrl(state.site)
export const selectSiteName = (state: MeState) => site.selectSiteName(state.site)
export const selectShopify = (state: MeState) => site.selectShopify(state.site)
export const selectSupportEmail = (state: MeState) => site.selectSupportEmail(state.site)
export const selectAISettingsPersonas = (state: MeState) =>
  site.selectAISettingsPersonas(state.site)
export const selectCurrencySlug = (state: MeState) => site.selectCurrencySlug(state.site)
export const selectTimeZone = (state: MeState) => site.selectTimeZone(state.site)
export const selectUser = (state: MeState): User => ({
  id: state.id,
  email: state.email,
  site: state.site.id,
  role: state.role.id,
  firstName: state.firstName,
  lastName: state.lastName
})
export const selectUserRole = (state: MeState) => state.role
