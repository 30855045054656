import { isRandomSplitSequenceItemUI, LogicRandomSplitVariant } from '@ghostmonitor/recartapis'
import debounce from 'lodash/debounce'
import { Action, Store } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSplit,
  selectSplitMeta
} from '../../../../store/selectors'
import {
  splitValidationError,
  splitValidationSuccess,
  updateSplit,
  UpdateSplitAction,
  UPDATE_SPLIT
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { isUpdateModelOnSequenceAction } from '../../utils/assert-action-type'
import { splitValidator } from '../../validators/split.validator'

const DEBOUNCE_DELAY = 400

function getValidateSplit(dispatch: AppDispatch) {
  return debounce(
    (split: LogicRandomSplitVariant, action: UpdateSplitAction) => {
      const splitErrors = splitValidator(split)
      if (splitErrors.length > 0) {
        dispatch(
          splitValidationError(splitErrors[0], {
            sequenceItemId: action.sequenceItemId,
            splitIndex: action.splitIndex
          })
        )
      } else {
        dispatch(
          splitValidationSuccess(null, {
            sequenceItemId: action.sequenceItemId,
            splitIndex: action.splitIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

function isUpdateSplitAction(action: Action): action is UpdateSplitAction {
  return action.type === UPDATE_SPLIT
}

export function validateSplitMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateSplit = getValidateSplit(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)

    const state = store.getState()

    if (isUpdateSplitAction(action)) {
      const split = selectSplit(action.sequenceItemId, action.splitIndex)(state)
      validateSplit(split, action)
    }

    if (isUpdateModelOnSequenceAction(action)) {
      const sequence = selectEditorSequence(state)
      sequence.sequenceItemIds.forEach((sequenceItemId) => {
        const sequenceItem = selectSequenceItem(sequenceItemId)(state)
        if (isRandomSplitSequenceItemUI(sequenceItem)) {
          sequenceItem.logic.randomSplit.variants.forEach((split, splitIndex) => {
            const splitMeta = selectSplitMeta(sequenceItemId, splitIndex)(state)
            const validateSplitAtIndex = getValidateSplit(dispatch)
            if (splitMeta.edited) {
              validateSplitAtIndex(
                split,
                updateSplit(sequenceItem._id, splitIndex, split.percentage)
              )
            }
          })
        }
      })
    }
  }
}
