import { Variable } from '@ghostmonitor/recartapis'
import {
  isDiscountUrlVariable,
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable
} from '../inline-editor/inline-editor-utils'
import { DiscountCodeInfo } from './types/varible-info.type'

export function getDiscountCodeInfoFromVariable(variable: Variable): DiscountCodeInfo {
  const { params } = variable
  const { discount_code: discountCode, discount_pool_id: discountPoolId, value } = params
  return {
    type: 'discount',
    ...(isStaticDiscountCodeVariable(variable) && {
      discountCodeType: 'static',
      discount: discountCode
    }),
    ...(isUniqueDiscountCodeVariable(variable) && {
      discountCodeType: 'unique',
      discount: discountPoolId
    }),
    ...(isDiscountUrlVariable(variable) && { url: value })
  }
}
