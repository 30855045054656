// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hK37dEy7b1CsFMGyI7CP{color:#212b36;font-family:Lato,sans-serif;font-size:16px;margin-left:12px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/header/components/header-view-only.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAGE,aCJG,CDEH,2BAAA,CACA,cAAA,CAEA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `hK37dEy7b1CsFMGyI7CP`
};
export default ___CSS_LOADER_EXPORT___;
