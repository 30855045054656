import { SequenceTag, Stat } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useAutomatedSMSStat(): UseResource<Stat> {
  const { dateFrom: timeFrameFilterDateFrom, dateTo: timeFrameFilterDateTo } =
    useContext(TimeFrameFilterContext)

  const queryParams: {
    startDate?: string
    endDate?: string
    tags?: string[]
  } = { tags: [SequenceTag.AUTOMATED] }

  if (timeFrameFilterDateFrom) {
    queryParams.startDate = timeFrameFilterDateFrom.toISOString()
  }

  if (timeFrameFilterDateTo) {
    queryParams.endDate = timeFrameFilterDateTo.toISOString()
  }

  const queryName = [QUERY_NAME.smsStat, queryParams]
  const {
    isInitialLoading,
    data: automatedSmsStat,
    isError
  } = useQuery<Stat>(
    queryName,
    async () => {
      return request.get<Stat>('statistics/sms', {
        params: queryParams
      })
    },
    {
      enabled: true
    }
  )

  return {
    data: automatedSmsStat,
    isLoading: isInitialLoading,
    isError
  }
}
