import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import memoize from 'lodash/memoize'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../../store/create-store'
import { meLoaded } from '../../store/slices/me/me.reducer'
import { MeState } from '../../store/slices/me/me.types'
import { api } from '../../utils/api'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

const getDispatchMeLoaded = memoize((dispatch: AppDispatch) =>
  debounce(
    (meResult: MeState) => {
      dispatch(meLoaded(meResult))
    },
    200,
    { leading: true, trailing: true }
  )
)

export function useMe(settings?: UseQueryOptions<MeState>): UseResource<MeState, Partial<MeState>> {
  const dispatch: AppDispatch = useDispatch()
  const dispatchMeLoaded = useMemo(() => getDispatchMeLoaded(dispatch), [dispatch])

  const {
    isLoading,
    isError,
    data: me
  } = useQuery<MeState>([QUERY_NAME.me], api.getMe, {
    notifyOnChangeProps: ['data', 'isLoading', 'isError'],
    ...(settings?.refetchInterval && { refetchInterval: settings.refetchInterval })
  })

  useEffect(() => {
    if (me !== undefined) {
      dispatchMeLoaded(me)
    }
  }, [me, dispatchMeLoaded])

  return {
    data: me,
    isLoading,
    isError
  }
}
