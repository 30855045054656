import { SMSCampaign, SMSCampaignStatus } from '@ghostmonitor/recartapis'

export function getSMSCampaignUIStatus(smsCampaign: SMSCampaign): SMSCampaignStatus {
  switch (smsCampaign.status) {
    case SMSCampaignStatus.QUEUED:
    case SMSCampaignStatus.QUEUING:
      if (smsCampaign?.firstTextMessagesSent > 0) {
        return SMSCampaignStatus.SENDING
      }

      return SMSCampaignStatus.PROCESSING
    case SMSCampaignStatus.SCHEDULED_FOR_NOW:
      return SMSCampaignStatus.PROCESSING
    case SMSCampaignStatus.SENDING:
      if (smsCampaign?.firstTextMessagesSent / smsCampaign?.subscriberCount >= 0.8) {
        return SMSCampaignStatus.SENT
      } else {
        return SMSCampaignStatus.SENDING
      }
    default:
      return smsCampaign.status
  }
}
