import { SMSCampaignScheduleType, SMSSettings } from '@ghostmonitor/recartapis'
import moment from 'moment'
import { phone } from 'phone'
import { dateFormat, dateTimeFormat } from '../utils'

export function getDecimalSeparator(locale: string = navigator.language): string {
  const numberWithDecimalSeparator = 1.1
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find((part) => part.type === 'decimal').value
}

// Note: doesnt handle thousand separators
export function parseLocaleNumber(value: string, locale: string = navigator.language): number {
  const separator = getDecimalSeparator(locale)
  const floatValue = value.replace(separator, '.')

  return Number.parseFloat(floatValue)
}

function formatNumber(value = 0, digits = 2, locale = navigator.language): string {
  return new Intl.NumberFormat(locale, { maximumFractionDigits: digits }).format(value)
}

function formatNumberCompact(value = 0, digits = 1, locale = navigator.language): string {
  return new Intl.NumberFormat(locale, {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: digits
  }).format(value)
}

export function roundToDecimals(value: number, decimals = 2): number {
  const multiplier = 10 ** decimals
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier
}

function formatRoi(value: number): string {
  let formattedRoi: number
  if (value > 10) {
    formattedRoi = roundToDecimals(value, 0)
  } else if (value > 1 && value < 10) {
    formattedRoi = roundToDecimals(value, 1)
  } else {
    formattedRoi = roundToDecimals(value ?? 0, 2)
  }
  return `${formattedRoi}X`
}

function formatCurrency(
  value = 0,
  currencySlug = 'USD',
  locale = 'en-US',
  maximumFractionDigits = 0
): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySlug,
    minimumFractionDigits: 0,
    maximumFractionDigits
  }).format(value)
}

function formatCurrencyBilling(value = 0, currencySlug = 'USD', locale = 'en-US'): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySlug,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

function formatCurrencyCompact(
  value = 0,
  currencySlug = 'USD',
  locale = 'en-US',
  maximumFractionDigits = 1
): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySlug,
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits
  }).format(value)
}

function formatPercentage(value = 0, digits = 1, locale = navigator.language): string {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: digits
  }).format(value)
}

function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber === undefined) {
    return
  }
  return `${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
    5,
    8
  )} ${phoneNumber.slice(8)}`
}

function formatInternationalPhoneNumber(phoneNumber: string): any {
  if (phoneNumber === undefined) {
    return
  }
  return phone(phoneNumber).phoneNumber
}

function formatArray(values: string[]): string {
  if (values == null) {
    return ''
  }

  if (values.length === 1) {
    return values[0]
  }

  const lastElement = values[values.length - 1]
  const rest = values.slice(0, -1)

  return `${rest.join(', ')} or ${lastElement}`
}

function formatRemainingTime(seconds: number): string {
  const hrs = seconds / 3600
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingMinutes = seconds % 3600

  if (hrs < 1) {
    if (seconds < 1) {
      return null
    }
    if (minutes < 1) {
      return `${Math.floor(seconds)} ${Math.floor(seconds) === 1 ? 'sec left' : 'secs left'}`
    }
    return `${minutes} ${minutes < 2 ? 'min left' : 'mins left'}`
  }
  if (hrs >= 1 && remainingMinutes !== 0) {
    return `${Math.floor(hrs)} ${hrs < 2 ? 'hr' : 'hrs'} ${minutes} ${
      minutes < 2 ? 'min left' : 'mins left'
    }`
  }
  return `${Math.floor(hrs)} ${hrs < 2 ? 'hr left' : 'hrs left'}`
}

function formatDate(
  date: string | SMSSettings.QuietHoursConfig_TimeOfDay | Date | undefined,
  format = dateFormat
): string {
  if (!date) {
    return '-'
  }

  return moment(date).format(format)
}

function sendingDate(
  scheduleType: SMSCampaignScheduleType,
  scheduledFor: string | null,
  timezone
): string {
  if (scheduleType === 'subscriberTimezone') {
    return `${moment(scheduledFor).utc().format(dateTimeFormat)} (subscriber's timezone)`
  }

  return `${moment.tz(scheduledFor, timezone).format(dateTimeFormat)} (store's timezone)`
}

export const formatters = {
  array: formatArray,
  billingCurrency: formatCurrencyBilling,
  currency: formatCurrency,
  currencyCompact: formatCurrencyCompact,
  number: formatNumber,
  numberCompact: formatNumberCompact,
  percentage: formatPercentage,
  phone: formatPhoneNumber,
  internationalPhone: formatInternationalPhoneNumber,
  hrsAndMins: formatRemainingTime,
  roi: formatRoi,
  date: formatDate,
  sendingDate
}
