import { Divider, Input } from 'antd'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import React, { useState } from 'react'

interface ControlledTextFieldProps {
  label: string
  value: string
  onChange: (value: string) => void
  onBlur: () => void
  type?: string
  placeholder?: string
}

export function ControlledTextField(props: ControlledTextFieldProps) {
  const [value, setValue] = useState(props.value)
  const onChange = debounce(throttle(props.onChange, 1400), 700)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value.trim()
    if (value === newValue) {
      return
    }
    setValue(newValue)
    onChange(newValue)
  }

  function handleBlur() {
    if (props.value !== value) {
      props.onChange(value)
    }
    props.onBlur()
  }

  function handleMouseDown(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    event.stopPropagation()
  }

  return (
    <>
      <Divider orientation='left'>{props.label}</Divider>
      <Input
        value={value}
        type={props.type}
        placeholder={props.placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        onMouseDown={handleMouseDown}
      />
    </>
  )
}
