export enum SequenceEditorSettings {
  canvasToolbarItems = 'canvasToolbarItems',
  conditionalSplitConditions = 'conditionalSplitConditions',
  dragAndDrop = 'dragAndDrop',
  drawer = 'drawer',
  entrySequenceItemStatsBarComponent = 'entrySequenceItemStatsBarComponent',
  hasDaysDelay = 'hasDaysDelay',
  header = 'header',
  hideMessengerSendTestButton = 'hideMessengerSendTestButton',
  isConditionalSplitEnabled = 'isConditionalSplitEnabled',
  isEnabledLocked = 'isEnabledLocked',
  isFacebookComment = 'isFacebookComment',
  isLegalMessageNeeded = 'isLegalMessageNeeded',
  leaveEditorModal = 'leaveEditorModal',
  maxDelay = 'maxDelay',
  maxDelayNotificationText = 'maxDelayNotificationText',
  saveModalComponent = 'saveModalComponent',
  saveThunks = 'saveThunks',
  sequenceItemStatsBarComponent = 'sequenceItemStatsBarComponent',
  showSaveDraftButton = 'showSaveDraftButton',
  toggleThunks = 'toggleThunks',
  tray = 'tray',
  variableTypeOptions = 'variableTypeOptions'
}
