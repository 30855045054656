// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e1H6hn0An3FPjsxrnVz_{color:#595959;font-family:Lato,sans-serif;line-height:14px}.x0KQvhhl34mYo6Wxf6g4 h2{font-family:Lato,sans-serif}.MebB6CFBL52feaqtZdcA label{font-family:Lato,sans-serif;font-size:var(--recart-font-size-tiny);line-height:var(--recart-line-height-tiny)}.MebB6CFBL52feaqtZdcA .ant-form-item-label{padding:0}.MebB6CFBL52feaqtZdcA .ant-form-item-explain.ant-form-item-explain-error{margin-bottom:var(--recart-spacing-2)}.MebB6CFBL52feaqtZdcA .ant-input-number{width:100%}.MebB6CFBL52feaqtZdcA .ant-form-item-tooltip svg{height:12px;width:12px}.PsEvDvHkRmAPmrDuTpgY{margin-bottom:0!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-edit-discount-code-unique/components/step-edit-unique/edit-unique-discount-code.scss"],"names":[],"mappings":"AAGE,sBAEE,aAAA,CADA,2BAAA,CAEA,gBAAA,CAIA,yBACE,2BAAA,CAKF,4BACE,2BAAA,CACA,sCAAA,CACA,0CAAA,CAIA,2CACE,SAAA,CAGF,yEACE,qCAAA,CAGF,wCACE,UAAA,CAIA,iDAEE,WAAA,CADA,UACA,CAMR,sBACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `e1H6hn0An3FPjsxrnVz_`,
	"container": `x0KQvhhl34mYo6Wxf6g4`,
	"formItem": `MebB6CFBL52feaqtZdcA`,
	"formButtonWrapper": `PsEvDvHkRmAPmrDuTpgY`
};
export default ___CSS_LOADER_EXPORT___;
