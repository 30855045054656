import { FlowDiagram } from '@ghostmonitor/recartapis'
import { addEdge } from 'reactflow'
import { selectEditorFlow } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'
export function connectNodeTo(
  state: FlowEditorState,
  node: FlowDiagram.Node,
  sourceNodeId: string,
  sourceHandleId: string,
  undeletable = false
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const targetHandle = node.data.inputHandle

  if (!targetHandle) {
    throw new Error('Target handle not found')
  }

  flow.flowEditorDiagram.edges = addEdge(
    {
      type: undeletable ? FlowDiagram.EdgeType.undeletable : FlowDiagram.EdgeType.custom,
      source: sourceNodeId,
      sourceHandle: sourceHandleId,
      target: node.id,
      targetHandle: targetHandle.id
    },
    flow.flowEditorDiagram.edges
  )
}
