// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cyIzjIXOCXW9CHeEJcQU .ant-popover-inner{background-color:#262626;border-radius:var(--recart-spacing-1)}.cyIzjIXOCXW9CHeEJcQU .ant-popover-arrow-content{background-color:#262626}.cyIzjIXOCXW9CHeEJcQU .ant-popover-inner-content{color:#fff}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-node-widget/sms-message-node-widget/components/variable-info.component.scss"],"names":[],"mappings":"AAGM,yCAEE,wBAAA,CADA,qCACA,CAGF,iDACE,wBAAA,CAGF,iDACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discountInfo": `cyIzjIXOCXW9CHeEJcQU`
};
export default ___CSS_LOADER_EXPORT___;
