import { Blast, BlastStatus } from '@ghostmonitor/recartapis'

export function getBlastUIStatus(blast: Blast): BlastStatus {
  switch (blast.status) {
    case BlastStatus.QUEUED:
    case BlastStatus.QUEUING:
    case BlastStatus.SCHEDULED_FOR_NOW:
      return BlastStatus.PROCESSING
    case BlastStatus.SENDING:
      if (
        blast?.firstTextMessagesSent &&
        blast?.subscriberCount &&
        blast.firstTextMessagesSent / blast.subscriberCount >= 0.8
      ) {
        return BlastStatus.SENT
      } else {
        return BlastStatus.SENDING
      }
    default:
      return blast.status
  }
}
