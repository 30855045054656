// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O45whDmc9QPlWSLpKWe1 .QzhZxrrNlOLl6faEH4fy{background-color:#476dff;border-radius:0 0 8px 8px;color:#fff;cursor:pointer;font-family:Lato,sans-serif;font-size:12px;height:0;left:31%;line-height:20px;margin:0 auto;opacity:0;position:absolute;scale:.8;text-align:center;transform:scale(.6);transform-origin:center;width:40%;z-index:13}.O45whDmc9QPlWSLpKWe1:hover .QzhZxrrNlOLl6faEH4fy{height:20px;opacity:1;transform:scale(1);transform-origin:center}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message/message.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAII,4CAQE,wBCTU,CDUV,yBAAA,CACA,UAAA,CAMA,cAAA,CAVA,2BAAA,CADA,cAAA,CAOA,QAAA,CAVA,QAAA,CAWA,gBAAA,CAEA,aAAA,CAZA,SAAA,CAFA,iBAAA,CAGA,QAAA,CAGA,iBAAA,CAWA,mBAAA,CADA,uBAAA,CAHA,SAAA,CAHA,UAOA,CAIA,kDAEE,WAAA,CADA,SAAA,CAGA,kBAAA,CADA,uBACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-item": `O45whDmc9QPlWSLpKWe1`,
	"add-button-first": `QzhZxrrNlOLl6faEH4fy`
};
export default ___CSS_LOADER_EXPORT___;
