// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wBZfyn3XZNZOZY_QbCkB{border:1px solid var(--recart-grey-3);height:40px;width:256px}.wBZfyn3XZNZOZY_QbCkB.ant-picker-focused{border-color:var(--recart-primary-blue-1);box-shadow:0 0 4px rgba(3,117,255,.33)}.wBZfyn3XZNZOZY_QbCkB:hover:not(.ant-picker-focused){border:1px solid var(--recart-grey-3)!important}.wBZfyn3XZNZOZY_QbCkB:hover:not(.wBZfyn3XZNZOZY_QbCkB:focus){border:1px solid var(--recart-grey-3)!important}.wBZfyn3XZNZOZY_QbCkB input{color:var(--recart-night-blue-1)!important;font-size:var(--recart-font-size-base)!important}.wBZfyn3XZNZOZY_QbCkB .ant-picker-active-bar{background-color:var(--recart-primary-blue-1);height:4px}.wBZfyn3XZNZOZY_QbCkB .ant-picker-range-separator{padding:0}.wBZfyn3XZNZOZY_QbCkB .ant-picker-input{width:unset}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/range-picker/range-picker.component.scss"],"names":[],"mappings":"AACE,sBAGE,qCAAA,CADA,WAAA,CADA,WAEA,CAEA,yCACE,yCAAA,CACA,sCAAA,CAIF,qDACE,+CAAA,CAGF,6DACE,+CAAA,CAGF,4BACE,0CAAA,CACA,gDAAA,CAIA,6CAEE,6CAAA,CADA,UACA,CAGF,kDACE,SAAA,CAGF,wCACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangepicker": `wBZfyn3XZNZOZY_QbCkB`
};
export default ___CSS_LOADER_EXPORT___;
