// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vj7ukaUF9sE2bw3QlTl4{align-items:center;background:#476dff;border-radius:5px 5px 0 0;display:inline-flex;height:30px;overflow:hidden}.vj7ukaUF9sE2bw3QlTl4 .DV9RE_ZQwtQxSC8UQ7zw{align-items:center;color:#fff;display:flex;height:18px;justify-content:center;padding:2px 0 1px 10px;width:27px}.vj7ukaUF9sE2bw3QlTl4 .vmtV3ZV2EAz0o3XjFbV9{align-items:center;color:#fff;display:block;height:inherit;line-height:30px;max-width:220px;overflow:hidden;padding:0 35px 0 20px;text-overflow:ellipsis;white-space:nowrap}.x3oRJHHoZvnGEmM8KJ9U .DV9RE_ZQwtQxSC8UQ7zw{background-color:#f1f0f0;border:1px solid #627283;border-right:none;color:#627283}.x3oRJHHoZvnGEmM8KJ9U .vmtV3ZV2EAz0o3XjFbV9{background-color:#f1f0f0;border:1px solid #627283;border-left:none;color:#627283}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/entry-tag/entry-tag.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAIE,kBAAA,CAGA,kBCPY,CDKZ,yBAAA,CAFA,mBAAA,CADA,WADmB,CAKnB,eCNY,CDSZ,4CAKE,kBAAA,CAJA,UAAA,CAGA,YAAA,CAFA,WAAA,CAIA,sBAAA,CACA,sBAAA,CAJA,UAIA,CAGF,4CAKE,kBAAA,CAHA,UAAA,CAEA,aAAA,CADA,cAAA,CAOA,gBAAA,CAJA,eAAA,CAEA,eAAA,CAPA,qBAAA,CAMA,sBAAA,CAEA,kBACA,CAKF,4CACE,wBAAA,CAGA,wBAAA,CAAA,iBAAA,CADA,aACA,CAGF,4CACE,wBAAA,CAGA,wBAAA,CAAA,gBAAA,CADA,aACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"entry-tag-component": `vj7ukaUF9sE2bw3QlTl4`,
	"icon": `DV9RE_ZQwtQxSC8UQ7zw`,
	"label": `vmtV3ZV2EAz0o3XjFbV9`,
	"inactive": `x3oRJHHoZvnGEmM8KJ9U`
};
export default ___CSS_LOADER_EXPORT___;
