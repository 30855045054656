import { FlowItemUI } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { hasMessageOnFlowItemType } from '../../../../types/guards/flow-item-ui.guards'
import { selectFlowItemsMetaById } from '../flow-editor.selectors'
import { FlowEditorState, messageInitialMeta } from '../flow-editor.state'
import { replaceRawValueToCanvasHtmlValue, replaceRawValueToHtmlValue } from '../helpers'

export function updateFlowItemMessageMeta(state: FlowEditorState, flowItem: FlowItemUI) {
  const flowItemsMetaById = selectFlowItemsMetaById(state)

  if (hasMessageOnFlowItemType(flowItem)) {
    for (let messageIndex = 0; messageIndex < flowItem.item.messages.length; messageIndex++) {
      flowItemsMetaById[flowItem._id].messageMeta[messageIndex] = cloneDeep(messageInitialMeta)
      const message = flowItem.item.messages[messageIndex]

      flowItemsMetaById[flowItem._id].messageMeta[messageIndex].textHTML =
        replaceRawValueToHtmlValue(state, message.text, flowItem._id)

      flowItemsMetaById[flowItem._id].messageMeta[messageIndex].canvasHTML =
        replaceRawValueToCanvasHtmlValue(state, message.text)
    }
  }
}
