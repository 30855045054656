import { PortType } from '@ghostmonitor/recartapis'
import { DiagramEngine } from 'storm-react-diagrams'
import { SequenceEditorLinkFactory } from '../../models/link/link-factory'
import { ButtonPortModel } from '../../models/port/button-port-model'
import { FollowUpPortModel } from '../../models/port/follow-up-port-model'
import { LogicPortModel } from '../../models/port/logic-port-model'
import { SequenceEditorPortFactory } from '../../models/port/port-factory'
import { QuickReplyPortModel } from '../../models/port/quick-reply-port-model'
import { SequenceItemPortModel } from '../../models/port/sequence-item-port-model'
import { ConditionalSplitSequenceItemNodeFactory } from '../../models/sequence-item/conditional-split/conditional-split-sequence-item-node-factory'
import { DelaySequenceItemNodeFactory } from '../../models/sequence-item/delay/delay-sequence-item-node-factory'
import { MessageSequenceItemNodeFactory } from '../../models/sequence-item/message/message-sequence-item-node-factory'
import { RandomSplitSequenceItemNodeFactory } from '../../models/sequence-item/random-split/random-split-sequence-item-node-factory'
import { SMSMessageSequenceItemNodeFactory } from '../../models/sequence-item/sms-message/sms-message-sequence-item-node-factory'
import { UserInputSequenceItemNodeFactory } from '../../models/sequence-item/user-input/user-input-sequence-item-node-factory'

let diagramEngine: DiagramEngine

export function getDiagramEngine(
  onModelChange?: (serializeDiagram: any) => void,
  repaintCanvas?: VoidFunction
): DiagramEngine {
  if (diagramEngine) {
    return diagramEngine
  }

  diagramEngine = new DiagramEngine()
  diagramEngine.setMaxNumberPointsPerLink(0)
  diagramEngine.installDefaultFactories()

  diagramEngine.registerLinkFactory(new SequenceEditorLinkFactory(onModelChange))

  diagramEngine.registerPortFactory(
    new SequenceEditorPortFactory(PortType.QUICK_REPLY, (config) => new QuickReplyPortModel())
  )
  diagramEngine.registerPortFactory(
    new SequenceEditorPortFactory(PortType.BUTTON, (config) => new ButtonPortModel())
  )
  diagramEngine.registerPortFactory(
    new SequenceEditorPortFactory(PortType.SEQUENCE_ITEM, (config) => new SequenceItemPortModel())
  )
  diagramEngine.registerPortFactory(
    new SequenceEditorPortFactory(PortType.LOGIC, (config) => new LogicPortModel())
  )
  diagramEngine.registerPortFactory(
    new SequenceEditorPortFactory(PortType.FOLLOW_UP, (config) => new FollowUpPortModel())
  )

  diagramEngine.registerNodeFactory(new MessageSequenceItemNodeFactory(repaintCanvas))
  diagramEngine.registerNodeFactory(new DelaySequenceItemNodeFactory(repaintCanvas))
  diagramEngine.registerNodeFactory(new ConditionalSplitSequenceItemNodeFactory(repaintCanvas))
  diagramEngine.registerNodeFactory(new RandomSplitSequenceItemNodeFactory(repaintCanvas))
  diagramEngine.registerNodeFactory(new UserInputSequenceItemNodeFactory(repaintCanvas))
  diagramEngine.registerNodeFactory(new SMSMessageSequenceItemNodeFactory(repaintCanvas))

  return diagramEngine
}
