const scripts = null

export class Scripts {
  constructor() {
    this.hubspot()
  }

  hubspot() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'hs-script-loader'
    script.async = true
    script.defer = true
    script.src = '//js.hs-scripts.com/4890437.js'
    document.body.appendChild(script)
  }
}

export default scripts || new Scripts()
