import { PatchSubdomainsRequest, Site, SiteSubdomain } from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

const logger = createScope('dashboard')

async function patch(payload: PatchSubdomainsRequest) {
  await api.patchSubdomains(payload)
}

export function useSubdomains(): UseResource<SiteSubdomain, PatchSubdomainsRequest> {
  const queryName = useMemo(() => [QUERY_NAME.subdomains], [])
  const {
    isLoading,
    data: site,
    isError
  } = useQuery(queryName, () => api.getSite(['urlGenerationSettings', 'subdomain']))
  const queryClient = useQueryClient()
  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
    queryClient.invalidateQueries([QUERY_NAME.site])
  }, [queryClient, queryName])

  const handlePatchError = useCallback((err, _updatedSubdomain, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Updating subdomain settings failed. Please try again or contact support.')
  }, [])

  const handlePatchMutate = useCallback(
    (patchPayload: PatchSubdomainsRequest) => {
      queryClient.cancelQueries(queryName)

      const subdomainsQuery = queryClient.getQueryCache().find<Site>(queryName)
      const previousSite = cloneDeep(subdomainsQuery.state.data)
      const { subdomain } = patchPayload
      const updatedData: Site = {
        ...previousSite,
        urlGenerationSettings: {
          ...previousSite.urlGenerationSettings,
          subdomain: {
            history: Array.from(new Set([...(previousSite.subdomain?.history ?? []), subdomain])),
            current: subdomain
          }
        }
      }

      queryClient.setQueryData(queryName, { data: updatedData })

      return () => queryClient.setQueryData(queryName, previousSite)
    },
    [queryClient, queryName]
  )

  const { mutateAsync: patchSubdomains } = useMutation<void, unknown, PatchSubdomainsRequest>(
    patch,
    {
      onMutate: handlePatchMutate,
      onSettled: handleSettled,
      onError: handlePatchError
    }
  )

  return {
    isLoading,
    data: site?.data?.urlGenerationSettings?.subdomain,
    isError,
    patch: patchSubdomains
  }
}
