import {
  PopupImageField,
  PopupButtonField,
  PopupInputField,
  PopupOptionalInputField,
  PopupTextField,
  PopupTextAreaField,
  GeneralStyle
} from '@ghostmonitor/recartapis'
import {
  previewLabels,
  accordionLabels
} from '../../../../popup/editor/preview/components/utils/preview-labels'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import flatMapDeep from 'lodash/flatMapDeep'

export function convertPathToLabel(path: string) {
  const [field, key] = path.split('.')

  // email success is on the sms step visually
  if (path === 'email.success') {
    return `${previewLabels.sms} / ${accordionLabels[key]}`
  }

  // success uses description property which is normally translated to legal instead of subheading
  if (path === 'success.description') {
    return `${previewLabels.success} / ${accordionLabels.paragraph}`
  }

  return `${previewLabels[field]} / ${accordionLabels[key]}`
}

export function getOverwrittenProperties(
  field:
    | PopupImageField
    | PopupButtonField
    | PopupInputField
    | PopupOptionalInputField
    | PopupTextField
    | PopupTextAreaField
) {
  const ignoreKeys = ['type', 'generalStyleSlug']

  if (field.type === 'button') {
    ignoreKeys.push('text')
  }

  if (field.type === 'text' || field.type === 'textarea') {
    ignoreKeys.push(...['text', 'font.size'])
  }

  if (field.type === 'input' || field.type === 'optional-input') {
    ignoreKeys.push(...['defaultState.placeholderText', 'errorState.text', 'required'])
  }

  // remove keys that are not defined in general style
  return omit(field, ignoreKeys)
}

export function hasOverWrittenStyle(
  field:
    | PopupImageField
    | PopupButtonField
    | PopupInputField
    | PopupOptionalInputField
    | PopupTextField
    | PopupTextAreaField
) {
  const settings = getOverwrittenProperties(field)
  // remove empty nested objects that are left over after omitting
  const cleaned = flatMapDeep(settings).filter((x) => !isEmpty(x))

  return Object.keys(cleaned).length > 0
}

export function convertFieldSettingsToGeneralStyle(
  field:
    | PopupImageField
    | PopupButtonField
    | PopupInputField
    | PopupOptionalInputField
    | PopupTextField
    | PopupTextAreaField,
  generalStyle: GeneralStyle
) {
  return merge({}, generalStyle, getOverwrittenProperties(field))
}
