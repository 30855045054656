// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j86Ax8ob21_EQMJWTvfB{background-color:#98a6b4;height:3px;margin:20px auto 10px;width:50%}.f5OubBUzQ7Na2VtU8jPb{display:inline-block}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/tray/tray.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAGE,wBCSG,CDXH,UAAA,CAGA,qBAAA,CAFA,SAEA,CAGF,sBACE,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"line": `j86Ax8ob21_EQMJWTvfB`,
	"trayitems": `f5OubBUzQ7Na2VtU8jPb`
};
export default ___CSS_LOADER_EXPORT___;
