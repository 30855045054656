import { selectEditorFlow, selectFlowItemsMetaById } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'

export function deleteNode(state: FlowEditorState, nodeId: string) {
  const flow = selectEditorFlow(state)
  const flowItemsMetaById = selectFlowItemsMetaById(state)

  if (!flow) {
    return
  }

  const node = flow.flowEditorDiagram.nodes.find((node) => node.id === nodeId)

  if (!node) {
    throw new Error(`Cannot find node for nodeId: ${nodeId}`)
  }

  const flowItemId = node.data.flowItemId

  if (!flowItemId) {
    throw new Error(`Cannot find flowItemId (${flowItemId}) on node data: ${nodeId}`)
  }

  if (flowItemId === flow.entrySequenceItemId) {
    delete flow.entrySequenceItemId
  }

  flow.flowEditorDiagram.edges = flow.flowEditorDiagram.edges.filter(
    (edge) => edge.source !== nodeId && edge.target !== nodeId
  )

  flow.flowEditorDiagram.nodes = flow.flowEditorDiagram.nodes.filter((node) => node.id !== nodeId)

  if (flow.quickReplyIndexToId) {
    delete flow.quickReplyIndexToId[flowItemId]
  }

  flow.sequenceItemIds = flow.sequenceItemIds.filter(
    (sequenceItemId) => sequenceItemId !== flowItemId
  )

  flow.sequenceItems = flow.sequenceItems.filter((sequenceItem) => sequenceItem._id !== flowItemId)

  delete flowItemsMetaById[flowItemId]
}
