import { DelayType, DelayUnit, FlowItemUI, FlowUI } from '@ghostmonitor/recartapis'
import { isDelayFlowItemUI } from '../../../../types/guards/flow-item-ui.guards'
import { oneHourInNanosecs } from '../../../../utils/time-conversion'
import { selectEditorFlow, selectFlowItem, selectFlowItemsById } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'

export function setScheduledDelayFlowItemToDefault(flowItem: FlowItemUI, flow: FlowUI) {
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Node with flowItemId ${flowItem._id} not found`)
  }

  if (isDelayFlowItemUI(flowItem)) {
    if (flowItem.item.logic.delay.type === DelayType.delayScheduled) {
      flowItem.item.logic.delay.type = DelayType.delay

      delete flowItem.item.logic.delay.scheduled_date
      delete flowItem.item.logic.delay.schedule_type
      flowItem.item.logic.delay.delay_duration = oneHourInNanosecs
      node.data.delayUnit = DelayUnit.HOURS
    }
  }
}

export function setScheduledDelayFlowItemsToDefault(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('No flow found')
  }

  const flowItems = selectFlowItemsById(state)

  if (!flowItems) {
    throw new Error('No flow items found')
  }

  Object.keys(flowItems).forEach((flowItemId) => {
    const flowItemUI: FlowItemUI = selectFlowItem(flowItemId)(state)
    setScheduledDelayFlowItemToDefault(flowItemUI, flow)
  })
}
