import { createSlice } from '@reduxjs/toolkit'
import { hideModal, showModal } from './modal.actions'
import { modalInitialState } from './modal.type'

const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showModal, (state, action) => {
      state.type = action.payload.modalType
    })
    builder.addCase(hideModal, (state, action) => {
      if (state.type === action.payload.modalType) {
        state.type = null
      }
    })
  }
})

export const { reducer: modalReducer } = modalSlice
