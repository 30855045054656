import { HttpResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { config } from '../../config'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export type SubscriberImportSampleResponse = HttpResponse<{ [key: string]: string }>

export function useSubscriberImportSample(fileKey: string): UseResource<{ [key: string]: string }> {
  const queryName = [QUERY_NAME.subscriberImportSample, fileKey]

  const { isInitialLoading, data, isError } = useQuery(
    queryName,
    async () => {
      // this request doesn't go through the site api, so the path is set via config
      return request.get<SubscriberImportSampleResponse>('', {
        url: `${config.UPLOAD_API_URL}media/subscriber-imports/${fileKey}/sample`
      })
    },
    {
      enabled: !!fileKey
    }
  )

  return {
    data: data?.data,
    isLoading: isInitialLoading,
    isError
  }
}
