import cn from 'classnames'
import React, { useRef, useState } from 'react'
import { ReactComponent as FlagIcon } from '../../../../../src/static/images/svg/sequence-editor/entry-tag-flag.svg'
import { Tooltip } from '../../../../components/ui-kit/tooltip/tooltip.component'
import styles from './entry-tag.component.scss'

interface EntryTagProps {
  label: string
  inactive?: boolean
}

export function EntryTag(props: EntryTagProps) {
  const entryTagClasses = cn({
    [styles['entry-tag-component']]: true,
    [styles.inactive]: props.inactive
  })
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const entryTagRef = useRef<HTMLDivElement>()

  // element max width is 220px minus the padding
  const ellipsisIsVisible = entryTagRef?.current?.scrollWidth > 165

  return (
    <div className={entryTagClasses} data-testid='entry-tag-container'>
      <FlagIcon className={styles.icon} />
      <Tooltip title={props.label} placement='top' open={ellipsisIsVisible && tooltipVisible}>
        <div
          className={styles.label}
          data-testid='entry-tag-label'
          ref={entryTagRef}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          {props.label}
        </div>
      </Tooltip>
    </div>
  )
}
