import { ButtonUI, isMessengerMessageSequenceItemUI } from '@ghostmonitor/recartapis'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { RemoveButtonAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function removeButtonActionHandler(state: SequenceEditorState, action: RemoveButtonAction) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    // Sequence Item
    const message = sequenceItem.messages[action.messageIndex]
    let button: ButtonUI
    if (isMessageGenericGalleryTemplate(message) && action.messageItemIndex !== undefined) {
      button = message.messengerTemplatePayload[action.messageItemIndex].buttons[action.buttonIndex]
      message.messengerTemplatePayload[action.messageItemIndex].buttons.splice(
        action.buttonIndex,
        1
      )

      if (
        sequenceItemMeta.messages[action.messageIndex].messageItems[action.messageItemIndex]
          .buttons[action.buttonIndex].error
      ) {
        state.errorCount -= 1
      }

      sequenceItemMeta.messages[action.messageIndex].messageItems[
        action.messageItemIndex
      ].buttons.splice(action.buttonIndex, 1)
    } else if (hasMessengerButtonOnMessageType(message)) {
      button = message.messengerTemplatePayload.buttons[action.buttonIndex]
      message.messengerTemplatePayload.buttons.splice(action.buttonIndex, 1)

      if (sequenceItemMeta.messages[action.messageIndex].buttons[action.buttonIndex].error) {
        state.errorCount -= 1
      }

      sequenceItemMeta.messages[action.messageIndex].buttons.splice(action.buttonIndex, 1)
    }

    if (button === undefined) {
      info('removeButtonActionHandler:1', { action, sequenceItem })
      return
    }

    if (isMessengerMessageSequenceItemUI(sequenceItem)) {
      const diagramModel = getDiagramModel(sequence.serializedDiagram)
      const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
      node.removeButton(
        button.type,
        action.messageIndex,
        action.buttonIndex,
        action.messageItemIndex
      )
      sequence.serializedDiagram = diagramModel.serializeDiagram() as any
    }
  } else {
    info('removeButtonActionHandler:2', { action, sequenceItem })
  }
}
