import cn from 'classnames'
import React from 'react'
import { hooks } from '../../../../hooks/hooks'
import { ReactComponent as UploadIcon } from '../../../../static/images/svg/sequence-editor/upload.svg'
import styles from './image-upload-button.component.scss'

export interface ImageUploadButtonProps {
  loading: boolean
  percentage?: number
  isHovered?: boolean
}

export function ImageUploadButton(props: ImageUploadButtonProps) {
  const isMMSImageResizingEnabled = hooks.useIsMMSImageResizingEnabled()

  return (
    <div
      className={cn(styles.container, {
        [styles.hovered]: props.isHovered
      })}
    >
      <div className='flex flex-col justify-center items-center h-full'>
        <div className='flex justify-center'>
          <div className='icon-sm'>
            <UploadIcon />
          </div>
        </div>
        {props.loading ? (
          <>
            <p className={styles.uploadLabel} data-testid='image-upload-button-loading'>
              Uploading
            </p>
            <p className={styles.progressBar}>
              <span
                className={styles.progress}
                data-testid='image-upload-button-progress'
                style={{ width: `${props.percentage}%` }}
              />
            </p>
          </>
        ) : (
          <>
            <p className={styles.uploadLabel} data-testid='image-upload-button-cta'>
              Click or drag file to this area to upload
            </p>
            <p className={styles.uploadText}>
              {isMMSImageResizingEnabled
                ? 'File size must be under 25MB, except GIF, where the limit is 500KB.'
                : 'File size must be under 500KB.'}
              <br />
              Supported file formats are
              <br />
              JPEG{isMMSImageResizingEnabled ? ', PNG, WEBP' : ''} and GIF (limited to 500 KB by
              carriers).
              <br />
              The recommended image ratio is 16:9.
            </p>
          </>
        )}
      </div>
    </div>
  )
}
