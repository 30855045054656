import { hooks } from './hooks'

export function useSupportedSMSCountryCodes() {
  const { data: availableCountries, isLoading } = hooks.useAvailableCountries()
  const supportedSMSCountries = availableCountries?.map((country) => country.countryCode) ?? []

  return {
    supportedSMSCountries,
    isLoading
  }
}
