import {
  CreateOptinToolExperimentPayload,
  HttpResponse,
  OptinToolExperiment,
  UpdateOptinToolExperimentPayload
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

export function useOptinToolExperimentCreate() {
  const queryName = useMemo(() => [QUERY_NAME.optinToolExperiments], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  const {
    mutateAsync: createOptinToolExperiment,
    isLoading: isCreatingOptinToolExperiment,
    error: createOptinToolExperimentError
  } = useMutation<HttpResponse<OptinToolExperiment>, unknown, CreateOptinToolExperimentPayload>(
    api.createOptinToolExperiment,
    { onSettled: handleSettled }
  )

  const {
    mutateAsync: updateOptinToolExperiment,
    isLoading: isUpdatingOptinToolExperiment,
    error: updateOptinToolExperimentError
  } = useMutation<
    HttpResponse<OptinToolExperiment>,
    unknown,
    { experimentId: string; updatedValues: UpdateOptinToolExperimentPayload }
  >(
    ({ experimentId, updatedValues }) => api.updateOptinToolExperiment(experimentId, updatedValues),
    { onSettled: handleSettled }
  )

  return {
    createOptinToolExperiment,
    isCreatingOptinToolExperiment,
    createOptinToolExperimentError,
    updateOptinToolExperiment,
    isUpdatingOptinToolExperiment,
    updateOptinToolExperimentError
  }
}
