import { createContext } from 'react'
import type { MultiCategoryFilterState } from '../../../../control-components/make-filterable/filter-plugins/multicategory-filter.plugin'

export interface SubscribersListContextState extends MultiCategoryFilterState {
  search: string
  setSearch: (search: string) => void
  currentPage: number
  setCurrentPage: (page: number) => void
}

export const defaultSubscribersListFilters: SubscribersListContextState = {
  currentPage: 1,
  setCurrentPage: () => undefined,
  search: '',
  setSearch: () => undefined,
  categories: {
    locales: [],
    channels: [],
    sources: []
  },
  setCategory: () => undefined,
  setCategories: () => undefined,
  clearCategories: () => undefined
}

export const SubscribersListFilterContext = createContext<SubscribersListContextState>(
  defaultSubscribersListFilters
)
