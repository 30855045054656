import { HttpResponse, SubscriberDetail } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { api } from '../../utils/api'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscriberDetails(): UseResource<SubscriberDetail[]> {
  const queryName = [QUERY_NAME.subscriberDetails]

  const {
    data: subscriberDetailsData,
    isLoading,
    error
  } = useQuery<HttpResponse<SubscriberDetail[]>>(queryName, api.getSubscriberDetails)

  const sortedSubscriberDetails = useMemo(
    () => orderBy(subscriberDetailsData?.data, 'slug', 'asc'),
    [subscriberDetailsData?.data]
  )

  return {
    data: sortedSubscriberDetails,
    error,
    isLoading
  }
}
