// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VeMxo_IrAexUxQ_qi1Hy{margin-bottom:6px}.VeMxo_IrAexUxQ_qi1Hy .ant-btn{margin-top:10px;width:100%}.VeMxo_IrAexUxQ_qi1Hy .ant-btn-link{color:#ff4d4f;margin-top:10px}.VeMxo_IrAexUxQ_qi1Hy .ant-btn-link span{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-buttons.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAGE,+BACE,eAAA,CACA,UAAA,CAGF,oCAEE,aCuBA,CDxBA,eCwBA,CDrBA,yCACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VeMxo_IrAexUxQ_qi1Hy`
};
export default ___CSS_LOADER_EXPORT___;
