import { Switch, SwitchProps } from 'antd'
import cn from 'classnames'
import React from 'react'
import { ReactComponent as InfoIcon } from '../../../../static/images/svg/sequence-editor/info-icon.svg'
import { Tooltip } from '../../../ui-kit/tooltip/tooltip.component'
import styles from './toggle.component.scss'

type ToggleProps = SwitchProps & { title: string; tooltipText: string; subTitle?: string }

export function Toggle(props: ToggleProps) {
  const { title, tooltipText, subTitle, ...switchProps } = props
  return (
    <div className='mb-3 flex'>
      <div>
        <Switch {...switchProps} />
      </div>
      <div className='ml-2'>
        <div className={cn(styles.title, 'flex items-center')}>
          <div className='text-sm'>{title}</div>
          <Tooltip placement='top' title={tooltipText} arrowPointAtCenter>
            <div className={cn(styles.tooltipIcon, 'icon-tiny block')}>
              <InfoIcon />
            </div>
          </Tooltip>
        </div>
        {subTitle && (
          <p className={cn('text-tiny text-secondary', styles.optionSubTitle)}>{subTitle}</p>
        )}
      </div>
    </div>
  )
}
