// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PqhEL_Y7kzvyM1oU_1fS{display:inline-flex}.PqhEL_Y7kzvyM1oU_1fS svg{color:#476dff;height:35px}.Y901Gz1LBztbQcah1TEv{display:inline-flex}.Y901Gz1LBztbQcah1TEv svg{color:#6d56f7;height:35px}.EYuKzqjvUvBani7OuNs8{display:inline-flex}.EYuKzqjvUvBani7OuNs8 svg{color:#1ca09d;height:35px}.HUHpDi6zcTpTqg3Zn1TS{display:inline-flex}.HUHpDi6zcTpTqg3Zn1TS svg{height:40px;stroke-width:1.5px}.iiMl0nCV63fgyooGVQm5{display:inline-flex}.iiMl0nCV63fgyooGVQm5 svg{color:#ffc53d;height:40px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/tray-item/sequence-item/tray-item-sequence-item.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,mBAAA,CAEA,0BAEE,aCLU,CDIV,WCJU,CDSd,sBACE,mBAAA,CAEA,0BAEE,aCoDO,CDrDP,WCqDO,CDhDX,sBACE,mBAAA,CAEA,0BAEE,aCoDS,CDrDT,WCqDS,CDhDb,sBACE,mBAAA,CAEA,0BACE,WAAA,CACA,kBAAA,CAIJ,sBACE,mBAAA,CAEA,0BAEE,aC6CoB,CD9CpB,WC8CoB","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageIcon": `PqhEL_Y7kzvyM1oU_1fS`,
	"conditionIcon": `Y901Gz1LBztbQcah1TEv`,
	"delayIcon": `EYuKzqjvUvBani7OuNs8`,
	"userInputIcon": `HUHpDi6zcTpTqg3Zn1TS`,
	"randomSplitIcon": `iiMl0nCV63fgyooGVQm5`
};
export default ___CSS_LOADER_EXPORT___;
