import he from 'he'

export function removeHTMLEntities(html: string): string {
  return he.decode(html)
}

export function convertHTMLtoPlainText(html: string): string {
  if (html === '') return ''
  html = removeHTMLEntities(html)
  return html.replace(/(<((?!img)[^>]+)>)|/gi, '')
}

export function removeFormattingHTMLTags(html: string): string {
  if (html === '') return ''
  html = html.replace(/<\/?[bdo][^>]*>/gi, '')
  if (/^<br[^>]*>$/.test(html)) {
    html = ''
  }
  return html
}
