import { getFlowTrigger, Trigger } from '@ghostmonitor/recartapis'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../store/selectors'

export function useFlowTrigger(): Trigger | undefined {
  const flow = useSelector(flowEditorSelectors.selectEditorFlow)
  const [trigger, setTrigger] = useState<Trigger | undefined>()

  useEffect(() => {
    if (flow) {
      const trigger = getFlowTrigger(flow.tags)
      setTrigger(trigger)
    }
  }, [flow])

  return trigger
}
