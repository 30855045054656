import { Variable } from '@ghostmonitor/recartapis'
import { getIndexFromId, getVariableFromId } from './get-variables'

export function removeVariableByCondition(
  html: string,
  condition: (varibale: Variable, index?: number) => boolean
): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html
  let newHtml = ''
  for (const childNode of holderElement.childNodes) {
    if (childNode.nodeType === Node.ELEMENT_NODE && (childNode as any).tagName === 'SPAN') {
      const id = (childNode as any).getAttribute('id')
      const variable = getVariableFromId(id)
      const index = getIndexFromId(id)
      const removable = condition(variable, index)
      if (!removable) {
        newHtml += (childNode as any).outerHTML
      }
    }
    if (childNode.nodeType === Node.TEXT_NODE) {
      newHtml += childNode.nodeValue
    }
  }
  return newHtml
}

export function removeVariableByIndex(html: string, indexToRemove: number): string {
  const newHtml = removeVariableByCondition(html, (_: Variable, index: number) => {
    return index === indexToRemove
  })
  return newHtml
}
