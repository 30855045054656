import { hooks } from './hooks'

interface TFNProviderConfig {
  temporary: string
  dedicated: string
}

export function useIsLeasePossible(): boolean {
  const providerConfig = hooks.useFeatureFlag('tfn-assignment-provider-config') as TFNProviderConfig
  const { data: smsSettingsData } = hooks.useSMSSettings()
  const { data: leasablePhoneNumbersCount } = hooks.useLeasablePhoneNumbersCount()

  if (
    smsSettingsData === undefined ||
    leasablePhoneNumbersCount === undefined ||
    providerConfig === undefined
  ) {
    return false
  }

  const dedicatedProvider = providerConfig.dedicated

  const subAccountId = smsSettingsData.twilio?.subaccountId

  const isDedicatedLeasePossible =
    dedicatedProvider === 'twilio' ? !!subAccountId : leasablePhoneNumbersCount.unverifiedCount > 0

  const isTemporaryLeasePossible = leasablePhoneNumbersCount?.verifiedCount > 0

  return isTemporaryLeasePossible && isDedicatedLeasePossible
}
