import {
  CheckboxGroupField,
  DateInputField,
  DropdownField,
  FormField,
  NumberInputField,
  RadioGroupField,
  SubscriberDetail,
  TextInputField,
  TextLocationInputField
} from '@ghostmonitor/recartapis'

export function getDefaultTextLocationInputField(
  subscriberDetail: SubscriberDetail
): TextLocationInputField {
  return {
    type: 'text-location-input',
    targetLocationUS: 'country',
    targetLocationWorld: 'country',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    shape: 'pill',
    backgroundColor: '#FFFFFF',
    placeholderText: 'Enter country',
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    textColor: '#333333',
    required: true
  }
}

export function getDefaultTextInputField(subscriberDetail: SubscriberDetail): TextInputField {
  return {
    type: 'text-input',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    shape: 'pill',
    backgroundColor: '#FFFFFF',
    placeholderText: `Enter ${subscriberDetail.label}`,
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    textColor: '#333333',
    required: true
  }
}

export function getDefaultNumberInputField(subscriberDetail: SubscriberDetail): NumberInputField {
  return {
    type: 'number-input',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    unit: subscriberDetail.unit ?? '',
    shape: 'pill',
    backgroundColor: '#FFFFFF',
    placeholderText: `Enter ${subscriberDetail.label}`,
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    textColor: '#333333',
    required: true
  }
}

export function getDefaultDateInputField(subscriberDetail: SubscriberDetail): DateInputField {
  return {
    type: 'date-input',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    shape: 'pill',
    backgroundColor: '#FFFFFF',
    placeholderText: `Enter ${subscriberDetail.label}`,
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    textColor: '#333333',
    required: true
  }
}

export function getDefaultCheckboxGroupField(
  subscriberDetail: SubscriberDetail
): CheckboxGroupField {
  return {
    type: 'checkbox-group',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    layout: 'horizontal',
    optionLabel: {
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    borderColor: '#E2E7F1',
    backgroundColor: '#FFFFFF',
    selectedBorderColor: '#0375FF',
    selectedBackgroundColor: '#0375FF',
    selectedColor: '#FFFFFF',
    options: subscriberDetail.id ? [] : (subscriberDetail.options ?? []),
    required: true
  }
}

export function getDefaultRadioGroupField(subscriberDetail: SubscriberDetail): RadioGroupField {
  return {
    type: 'radio-group',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    layout: 'horizontal',
    optionLabel: {
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    borderColor: '#E2E7F1',
    backgroundColor: '#FFFFFF',
    selectedBorderColor: '#E2E7F1',
    selectedBackgroundColor: '#FFFFFF',
    selectedColor: '#0375FF',
    options: subscriberDetail.id ? [] : (subscriberDetail.options ?? []),
    required: true
  }
}

export function getDefaultDropdownField(subscriberDetail: SubscriberDetail): DropdownField {
  return {
    type: 'dropdown',
    label: {
      text: '',
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '700',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      textDecoration: 'none'
    },
    name: subscriberDetail.slug,
    shape: 'pill',
    placeholderText: `Enter ${subscriberDetail.label}`,
    placeholderColor: 'rgba(51, 51, 51, 0.5)',
    backgroundColor: '#FFFFFF',
    textColor: '#333333',
    options: subscriberDetail.id ? [] : (subscriberDetail.options ?? []),
    required: true
  }
}

export function isOptinFormField(field: any): field is FormField {
  return (
    'type' in field &&
    (field.type === 'text-input' ||
      field.type === 'number-input' ||
      field.type === 'date-input' ||
      field.type === 'checkbox-group' ||
      field.type === 'radio-group' ||
      field.type === 'dropdown')
  )
}

export function hasPlaceholder(
  formField: FormField
): formField is TextInputField | NumberInputField | DateInputField | DropdownField {
  return 'placeholderText' in formField
}

function hasOptions(
  formField: FormField
): formField is CheckboxGroupField | RadioGroupField | DropdownField {
  return 'options' in formField
}

export function getNewFormFieldValues<T extends FormField>({
  fieldName,
  value,
  restProps,
  subscriberDetails
}: {
  fieldName: keyof T
  value: T[typeof fieldName]
  restProps: T
  subscriberDetails: SubscriberDetail[]
}): T {
  const newValues = {
    ...restProps,
    [fieldName]: value
  }

  if (fieldName === 'name') {
    const newSubscriberDetail = subscriberDetails.find((detail) => detail.slug === newValues.name)
    if (hasPlaceholder(newValues)) {
      newValues.placeholderText = `Enter ${newSubscriberDetail?.label}`
    }

    if (hasOptions(newValues)) {
      if (newSubscriberDetail?.id) {
        newValues.options = []
      } else if (!newSubscriberDetail?.id && newSubscriberDetail?.options) {
        newValues.options = newSubscriberDetail.options
      }
    }

    if (value === 'location') {
      newValues.targetLocationUS = 'country'
      newValues.targetLocationWorld = 'country'
      newValues.type = 'text-location-input'
    } else {
      delete newValues.targetLocationUS
      delete newValues.targetLocationWorld
      newValues.type = 'text-input'
    }
  }

  return newValues
}
