import {
  isMessengerMessageSequenceItemUI,
  isSMSMessageContactCardUI,
  isSMSMessageMMSUI,
  MessageType,
  SMSMessageTextUI
} from '@ghostmonitor/recartapis'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { SequenceEditorError } from '../../../../routes/SequenceEditor/types/sequence-editor-errors'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { RemoveMessageAction, REMOVE_BUTTON, REMOVE_MESSAGE_ITEM } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
import { removeButtonActionHandler } from './remove-button.handler'
import { removeMessageItemActionHandler } from './remove-message-item.handler'

const { info } = createScope('sequence-editor')

export function removeMessageActionHandler(
  state: SequenceEditorState,
  action: RemoveMessageAction
) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  function removeMessageItem(messageItemIndex: number) {
    removeMessageItemActionHandler(state, {
      type: REMOVE_MESSAGE_ITEM,
      sequenceItemId: action.sequenceItemId,
      messageIndex: action.messageIndex,
      messageItemIndex
    })
  }

  function removeButton(buttonIndex: number, messageItemIndex?: number) {
    removeButtonActionHandler(state, {
      type: REMOVE_BUTTON,
      sequenceItemId: action.sequenceItemId,
      messageIndex: action.messageIndex,
      buttonIndex,
      messageItemIndex
    })
  }

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]

    const messageMeta = sequenceItemMeta.messages[action.messageIndex]
    sequenceItemMeta.edited = true

    // This is the case when we remove an SMS Media message from a sequence item
    // Then it will switch back to a simple SMS
    if (isSMSMessageMMSUI(message)) {
      delete message.attachments

      const emptyMediaErrorIndex = messageMeta.errors.findIndex(
        (error) => error.name === SequenceEditorError.MessageEmptyMediaAttachment
      )
      if (emptyMediaErrorIndex !== -1) {
        messageMeta.errors.splice(emptyMediaErrorIndex, 1)
        state.errorCount -= 1
      }

      const smsMessageText: SMSMessageTextUI = message as any
      smsMessageText.type = MessageType.SMS_TEXT
      return
    } else if (isSMSMessageContactCardUI(message)) {
      const smsMessageText: SMSMessageTextUI = message as any
      smsMessageText.type = MessageType.SMS_TEXT
      return
    }

    // In any other case, we delete the whole message
    else {
      state.errorCount -= messageMeta.errors.length
    }

    if (isMessageGenericGalleryTemplate(message)) {
      const messageItemsLength = message.messengerTemplatePayload.length
      for (let i = messageItemsLength - 1; i >= 0; i--) {
        removeMessageItem(i)
      }
    } else if (hasMessengerButtonOnMessageType(message)) {
      const buttonsLength = message.messengerTemplatePayload.buttons.length
      for (let i = buttonsLength - 1; i >= 0; i--) {
        removeButton(i)
      }
    }

    sequenceItem.messages.splice(action.messageIndex, 1)
    sequenceItemMeta.messages.splice(action.messageIndex, 1)

    if (isMessengerMessageSequenceItemUI(sequenceItem)) {
      const diagramModel = getDiagramModel(sequence.serializedDiagram)
      const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
      node.removeMessage(action.messageIndex)
      sequence.serializedDiagram = diagramModel.serializeDiagram() as any
    }
  } else {
    info('removeMessageActionHandler:1', { action, sequenceItem })
  }
}
