import {
  PortType,
  SerializedDiagram,
  SerializedPortModel,
  SerializedSequenceItemNodeModel
} from '@ghostmonitor/recartapis'

function* allPorts(serializedDiagram: SerializedDiagram): Generator<SerializedPortModel> {
  for (const node of serializedDiagram.nodes) {
    for (const port of node.ports) {
      yield port
    }
  }
}

function getPortById(serializedDiagram: SerializedDiagram, portId: string): SerializedPortModel {
  for (const port of allPorts(serializedDiagram)) {
    if (port.id === portId) {
      return port
    }
  }
}

function getNodeById(
  serializedDiagram: SerializedDiagram,
  nodeId: string
): SerializedSequenceItemNodeModel {
  return serializedDiagram.nodes.find((node) => node.id === nodeId)
}

export function isSequenceItemLinked(
  sequenceItemId: string,
  serializedDiagram: SerializedDiagram
): boolean {
  for (const link of serializedDiagram.links) {
    const {
      source: sourceNodeId,
      sourcePort: sourcePortId,
      target: targetNodeId,
      targetPort: targetPortId
    } = link
    const sourceNode = getNodeById(serializedDiagram, sourceNodeId)
    const targetNode = getNodeById(serializedDiagram, targetNodeId)
    const sourcePort = getPortById(serializedDiagram, sourcePortId)
    const targetPort = getPortById(serializedDiagram, targetPortId)
    if (
      (sourceNode?.sequenceItemId === sequenceItemId &&
        sourcePort?.portType === PortType?.SEQUENCE_ITEM) ||
      (targetNode?.sequenceItemId === sequenceItemId &&
        targetPort?.portType === PortType.SEQUENCE_ITEM)
    ) {
      return true
    }
  }
  return false
}
