import { FlowAPI } from '@ghostmonitor/recartapis'

export function checkLoyaltyLionFlowIntegrity(flow: FlowAPI): void {
  if (flow.sendingFrequency !== undefined) {
    throw new Error(
      'sendingFrequency is defined on flow, but it only should be defined on Custom Trigger flows'
    )
  }

  if (flow.isQuietHoursEnabled === undefined) {
    throw new Error(`isQuietHoursEnabled should be defined for all LoyaltyLion flows`)
  }

  if (flow.isSmartSendingEnabled !== false) {
    throw new Error(`isSmartSendingEnabled should be false for all LoyaltyLion flows`)
  }
}
