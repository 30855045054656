import { ButtonUI, MessengerButtonTemplatePayload } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback } from 'react'
import {
  ButtonInitialMeta,
  ButtonMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import { MESSENGER_MESSAGE_MAX_LENGTH } from '../../../config'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import { isAnyButtonSelected } from '../../../utils/is-any-button-selected'
import { ThrottledInlineEditor } from '../../inline-editor/throttled-inline-editor.component'
import {
  InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import { ButtonProps, MessengerButton } from '../../messenger-button/messenger-button.component'
import { MessengerTemplateProps } from '../messenger-template-props.type'
import classNames from './messenger-button-template.component.scss'

const InteractiveContent = makeInteractive<
  MessengerTemplateProps<MessengerButtonTemplatePayload> & InteractiveComponentProps
>(function Content(props) {
  const { onMessageChange } = props
  const handleChange = useCallback(
    (newValue: string) => onMessageChange({ text: newValue }),
    [onMessageChange]
  )

  return (
    <div
      className={cn(classNames.text, {
        [classNames['has-buttons']]: props.messengerTemplatePayload.buttons.length > 0,
        [classNames.blurred]: props.isBlurred
      })}
    >
      <ThrottledInlineEditor
        placeholder=''
        isEditable
        multiline
        defaultValue={props.messengerTemplatePayload.text}
        onChange={handleChange}
        onFocus={props.onMessageFocus}
        onBlur={props.onMessageBlur}
        characterLimit={MESSENGER_MESSAGE_MAX_LENGTH}
        type={props.hasVariable ? 'messenger' : 'basic'}
      />
    </div>
  )
})

const InteractiveButton = makeInteractive<ButtonProps>(MessengerButton)

export function MessengerButtonTemplate(
  props: MessengerTemplateProps<MessengerButtonTemplatePayload>
) {
  function handleAddButton(buttonIndex: number) {
    if (props.messengerTemplatePayload.buttons.length >= 3) {
      return false
    }
    props.onAddButton(buttonIndex)
    props.clearSelection()
  }

  function handleRemoveButton(buttonIndex: number) {
    if (props.messengerTemplatePayload.buttons[buttonIndex]) {
      props.onRemoveButton(buttonIndex)
    }
  }

  const isDragAndDropEnabled = useSequenceEditorSettings().dragAndDrop

  return (
    <div className={classNames.container}>
      <InteractiveContent
        // MessengerTemplateProps
        {...props}
        // InteractiveComponentProps
        errorMessage={getErrorMessage(props.messageMeta.errors[0])}
        isFirst
        isLast={!props.messengerTemplatePayload.buttons.length}
        isSelected={props.messageMeta.selected && !isAnyButtonSelected(props.messageMeta)}
        isBlurred={
          (props.sequenceItemMeta.selected && !props.messageMeta.selected) ||
          (props.messageMeta.selected && isAnyButtonSelected(props.messageMeta))
        }
        hasAddButton={props.messengerTemplatePayload.buttons.length < 3}
        isDraggable={isDragAndDropEnabled}
        onRemoveElement={props.onRemove}
        onAddButtonClick={() => handleAddButton(0)}
        onMessageFocus={props.onMessageFocus}
      />
      {props.messengerTemplatePayload.buttons.length > 0 && (
        <div className={classNames.buttons}>
          {props.messengerTemplatePayload.buttons.map((buttonPayload, buttonIndex) => {
            const buttonMeta: ButtonMeta = props.messageMeta.buttons[buttonIndex]
              ? props.messageMeta.buttons[buttonIndex]
              : cloneDeep(ButtonInitialMeta)
            const handleChange = (buttonChange: Partial<ButtonUI>) =>
              props.onButtonChange(buttonIndex, buttonChange)
            const handleFocus = () => props.onButtonFocus(buttonIndex)
            const handleBlur = () => props.onButtonBlur()
            return (
              <div key={`button-${buttonIndex}`}>
                <InteractiveButton
                  // ButtonProps
                  payload={buttonPayload}
                  buttonTypeOptions={props.buttonTypeOptions}
                  sequenceItemId={props.sequenceItemNodeModel.sequenceItemId}
                  sequenceItemNodeId={props.sequenceItemNodeModel.getID()}
                  port={props.sequenceItemNodeModel.getButtonPort(
                    props.sequenceItemNodeModel.sequenceItemId,
                    props.messageIndex,
                    buttonIndex
                  )}
                  linking={props.linking}
                  linkingMeta={props.linkingMeta}
                  disableTypeChange={props.disableButtonTypeChange}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  clearSelection={props.clearSelection}
                  // InteractiveComponentProps
                  errorMessage={buttonMeta.error}
                  isLast={buttonIndex === props.messengerTemplatePayload.buttons.length - 1}
                  isSelected={buttonMeta.selected}
                  isBlurred={!buttonMeta.selected && props.sequenceItemMeta.selected}
                  onRemoveElement={() => handleRemoveButton(buttonIndex)}
                  onAddButtonClick={() => handleAddButton(buttonIndex + 1)}
                  hasAddButton={props.messengerTemplatePayload.buttons.length < 3}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
