import { ConditionSlug, DateFilterType, Operator } from '@ghostmonitor/recartapis'
import { RuleObject } from 'antd/lib/form'
import { Moment } from 'moment'
import { ConditionUI } from '../../types/segment/condition-ui.type'
import { ConditionConfig } from './conditions/conditions'

// https://www.notion.so/recart/Segmentation-v2-MVP-66e9a0b8d74247ada1f203b2e643a6b6#4065a47ffac04e13a4e2dd3962412b9a
export const MAX_LAST_X_DAYS = 548

function isConditionType(obj: any): obj is ConditionConfig {
  return obj?.description !== undefined
}

function hasDate(
  conditionType: ConditionConfig | ConditionUI
): conditionType is ConditionUI & { date: Moment | null } {
  return 'date' in conditionType
}

function hasDateRange(
  conditionType: ConditionConfig | ConditionUI
): conditionType is ConditionUI & { dateRange: [Moment | null, Moment | null] } {
  return 'dateRange' in conditionType
}

export function createExpressionValidators(
  conditionType?: ConditionConfig | ConditionUI,
  subConditionCategory?: string
) {
  return {
    validateSlug: async (_: RuleObject, value: string) => {
      if (!value) {
        return Promise.reject(new Error('Please select a condition'))
      }
    },
    validateConditionValue: async (_: RuleObject, value: string | string[]) => {
      const inputValue = !isConditionType(conditionType) ? conditionType.value : value

      if (conditionType.type === 'number') {
        if (value === null) {
          return Promise.reject(new Error('Please enter a number'))
        }
        const number = +value

        const integerConditions = [
          ConditionSlug.NUMBER_OF_ORDERS,
          ConditionSlug.NUMBER_OF_PRODUCTS,
          ConditionSlug.CURRENT_CART_VALUE,
          ConditionSlug.ALL_ORDER_VALUE,
          ConditionSlug.LAST_ORDER_VALUE
        ]
        if (integerConditions.includes(conditionType.slug)) {
          if (number < 0 || !Number.isInteger(number)) {
            return Promise.reject(new Error('Please enter a positive whole number'))
          }
        }

        return
      }

      if (conditionType.slug === ConditionSlug.SHOPIFY_TAG) {
        if (Array.isArray(value) && value.length > 10) {
          return Promise.reject(new Error('Maximum 10 tags allowed'))
        }
        if (Array.isArray(value) && value.some((tag) => tag.length > 255)) {
          return Promise.reject(new Error('Maximum 255 characters per tag'))
        }
      }

      // check empty for subscriber detail date type
      if (conditionType.slug === ConditionSlug.SUBSCRIBER_DETAIL) {
        if (conditionType.type === 'date') {
          if (hasDate(conditionType)) {
            if (conditionType.date === null) {
              return Promise.reject(new Error('Please select a date'))
            }
          }
          if (hasDateRange(conditionType)) {
            if (conditionType.dateRange === null) {
              return Promise.reject(new Error('Please select a date range'))
            }
          }
          return
        }
      }

      const isValueEmpty = Array.isArray(inputValue) ? inputValue.length === 0 : !inputValue
      if (isValueEmpty && !isConditionValidWithoutValue(conditionType as ConditionUI)) {
        return Promise.reject(new Error('Please enter a value'))
      }
    },
    validateDateOffset: async (_: RuleObject, value: string) => {
      if (value) {
        const number = +value
        if (number < 0 || !Number.isInteger(number)) {
          return Promise.reject(new Error('Please enter a positive whole number'))
        }

        if (number < 0 || number > MAX_LAST_X_DAYS) {
          return Promise.reject(new Error('Maximum days for the filter is 548 days (1,5 years)'))
        }
      } else {
        return Promise.reject(new Error('Please enter a number'))
      }
    },
    validateDate: async () => {
      let hasError = false
      if (!isConditionType(conditionType)) {
        switch (conditionType.dateType) {
          case DateFilterType.Since:
          case DateFilterType.Until:
          case DateFilterType.On:
            if (!conditionType.date) {
              hasError = true
            }
            break
          default:
            break
        }
      }

      if (hasError) {
        return Promise.reject(new Error('Please select a date filter'))
      }
    },
    validateDateRange: async (_: RuleObject, value: string[] | Moment[]) => {
      if (value?.length < 2) {
        return Promise.reject(new Error('Please select a date range'))
      }
    },
    validateSubconditionValue: async (_: RuleObject, value: string) => {
      const isValueEmpty = Array.isArray(value) ? value.length === 0 : !value
      if (isValueEmpty) {
        return Promise.reject(new Error('Please select a value'))
      }

      if (subConditionCategory === 'url' && value.length > 1) {
        return Promise.reject(new Error('Only one URL allowed'))
      }
    }
  }
}

export function isConditionValidWithoutValue(condition: ConditionUI): boolean {
  return (
    condition.slug === ConditionSlug.PLACED_AN_ORDER ||
    condition.slug === ConditionSlug.ABANDONED_A_CART ||
    condition.slug === ConditionSlug.ABANDONED_A_CHECKOUT ||
    condition.slug === ConditionSlug.LAST_ORDERED_AT ||
    condition.slug === ConditionSlug.CLICKED_A_FLOW ||
    condition.slug === ConditionSlug.RECEIVED_A_FLOW ||
    condition.slug === ConditionSlug.LAST_VISITED_PRODUCT ||
    condition.operator === Operator.EXIST ||
    condition.operator === Operator.NOT_EXIST ||
    condition.dateType === DateFilterType.Exist ||
    condition.dateType === DateFilterType.NotExist
  )
}
