import { MessengerReceiptTemplatePayload } from '@ghostmonitor/recartapis'
import React from 'react'
import { ReactComponent as IconBag } from '../../../../../static/images/svg/icon-bag.svg'
import {
  InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import { MessengerTemplateProps } from '../messenger-template-props.type'
import classNames from './messenger-receipt-template.component.scss'

const InteractiveContent = makeInteractive<
  MessengerTemplateProps<MessengerReceiptTemplatePayload> & InteractiveComponentProps
>((props) => {
  return (
    <div className={classNames.container}>
      <div className={classNames.containerTop}>
        <div className={classNames.title}>Order confirmation</div>
        <div className={classNames.item}>
          <div className={classNames.itemImg}>
            <IconBag />
          </div>
          <div>
            <div>Classic White T-shirt</div>
            <div className={classNames.itemSubtitle}>100% Soft Cotton</div>
          </div>
        </div>
        <div className={classNames.item}>
          <div className={classNames.itemImg}>
            <IconBag />
          </div>
          <div>
            <div>Classic White T-shirt</div>
            <div className={classNames.itemSubtitle}>100% Soft Cotton</div>
          </div>
        </div>
        <div className={classNames.optionItem}>
          <div>Paid with</div>
          <div className={classNames.itemSubtitle}>Visa 2345</div>
        </div>
        <div className={classNames.optionItem}>
          <div>Ship to</div>
          <div className={classNames.itemSubtitle}>1 Hacker Way, Menlo Park, CA 94025</div>
        </div>
      </div>
      <div className={classNames.containerBottom}>
        <div className={classNames.total}>
          <div className={classNames.totalLabel}>Total</div>
          <div>$42.98</div>
        </div>
      </div>
    </div>
  )
})

export function MessengerReceiptTemplate(
  props: MessengerTemplateProps<MessengerReceiptTemplatePayload>
) {
  return (
    <InteractiveContent
      {...props}
      onRemoveElement={props.onRemove}
      isSelected={false}
      isBlurred={false}
      isFirst
      isLast
      isDraggable
    />
  )
}
