import { EditSegmentRequest } from '@ghostmonitor/recartapis'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

export function useSegmentListUpdateSubscribers() {
  const queryClient = useQueryClient()

  const { mutateAsync: updateSegmentSubscribers, isLoading } = useMutation<
    null,
    unknown,
    { operationType: 'add' | 'remove'; segmentId: string; subscriberId: string }
  >(
    ({ operationType, segmentId, subscriberId }) => {
      const payload: EditSegmentRequest[] = [
        {
          operationType,
          data: {
            id: subscriberId
          }
        }
      ]

      return api.updateSegmentSubscribers(segmentId, payload)
    },
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries([QUERY_NAME.subscriber, params.subscriberId])
        queryClient.invalidateQueries([QUERY_NAME.subscribersList])
        queryClient.invalidateQueries([QUERY_NAME.segmentsList])
      }
    }
  )

  const addSubscriberToSegment = useCallback(
    (subscriberId: string, segmentId: string) => {
      updateSegmentSubscribers({ operationType: 'add', segmentId, subscriberId })
    },
    [updateSegmentSubscribers]
  )

  const removeSubscriberFromSegment = useCallback(
    (subscriberId: string, segmentId: string) => {
      updateSegmentSubscribers({ operationType: 'remove', segmentId, subscriberId })
    },
    [updateSegmentSubscribers]
  )

  return { addSubscriberToSegment, removeSubscriberFromSegment, isLoading }
}
