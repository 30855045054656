import {
  ButtonUI,
  isMessageMessengerUI,
  MessengerGenericTemplatePayload
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { CREATE_BUTTON, CREATE_MESSAGE_ITEM, CreateMessageAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { messageInitialMeta, SequenceEditorState } from '../sequence-editor.state'
import { createButtonActionHandler } from './create-button.handler'
import { createMessageItemActionHandler } from './create-message-item.handler'

const { info } = createScope('sequence-editor')

export function createMessageActionHandler(
  state: SequenceEditorState,
  action: CreateMessageAction
) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)
  const isEntrySequenceItem = sequence.entrySequenceItemId === sequenceItem._id

  sequenceItemMeta.edited = true

  function createMessageItem(
    messageItemPayload: MessengerGenericTemplatePayload,
    messageIndex: number
  ) {
    createMessageItemActionHandler(state, {
      type: CREATE_MESSAGE_ITEM,
      sequenceItemId: action.sequenceItemId,
      messageIndex,
      messageItemPayload
    })
  }

  function createButton(button: ButtonUI, messageIndex: number) {
    createButtonActionHandler(state, {
      type: CREATE_BUTTON,
      sequenceItemId: action.sequenceItemId,
      messageIndex,
      button
    })
  }

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const messageIndex = action.messageIndex ?? sequenceItem.messages.length
    sequenceItem.messages.splice(messageIndex, 0, cloneDeep(action.message))
    sequenceItemMeta.messages.splice(messageIndex, 0, cloneDeep(messageInitialMeta))

    const message = sequenceItem.messages[messageIndex]

    if (isMessageMessengerUI(message) && isMessageGenericGalleryTemplate(action.message)) {
      message.messengerTemplatePayload = []
      action.message.messengerTemplatePayload.forEach((messageItemPayload) => {
        createMessageItem(messageItemPayload, messageIndex)
      })
    } else if (
      hasMessengerButtonOnMessageType(action.message) &&
      hasMessengerButtonOnMessageType(message)
    ) {
      message.messengerTemplatePayload.buttons = []
      action.message.messengerTemplatePayload.buttons.forEach((button) => {
        createButton(button, messageIndex)
      })
    }
  } else {
    info('createMessageActionHandler:2', { action, sequenceItem })
  }
}
