import { FlowItemUI, SequenceTag } from '@ghostmonitor/recartapis'
import { hasKeywordRepliesOnFlowItem } from '../../../../types/guards/flow-item-ui.guards'

export function updateKeywordReplyTagOnFlowItem(flowItem: FlowItemUI) {
  if (!hasKeywordRepliesOnFlowItem(flowItem)) {
    flowItem.tags = flowItem.tags.filter((tag) => tag !== SequenceTag.KEYWORD_REPLY)
    return
  }

  if (flowItem.tags.includes(SequenceTag.KEYWORD_REPLY)) {
    return
  }

  flowItem.tags = [...new Set([...flowItem.tags, SequenceTag.KEYWORD_REPLY])]
}
