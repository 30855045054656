import { SMSSettingsService } from '@ghostmonitor/recartapis'
import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

interface UseLeasablePhoneNumbers {
  leasePhoneNumbers: UseMutateAsyncFunction<
    SMSSettingsService.LeasePhoneNumbersResponse,
    unknown,
    void,
    unknown
  >
  isLoading: boolean
}

export function useLeasablePhoneNumbers(): UseLeasablePhoneNumbers {
  const queryClient = useQueryClient()
  const { handleSettled, handleError } = useMutationHelpers([QUERY_NAME.phoneNumbers])

  const { mutateAsync: mutateLeasePhoneNumbers, isLoading } = useMutation<
    SMSSettingsService.LeasePhoneNumbersResponse,
    unknown,
    void
  >(() => api.leasePhoneNumbers(), {
    onSettled: handleSettled,
    onError: handleError,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_NAME.phoneNumbers])
    }
  })

  return {
    leasePhoneNumbers: mutateLeasePhoneNumbers,
    isLoading
  }
}
