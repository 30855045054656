import { Button, Form, Input } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import React, { ReactNode, useState } from 'react'
import { hooks } from '../../../../../../hooks/hooks'
import { SequenceEditorError } from '../../../../types/sequence-editor-errors'
import styles from './discount-select-existing.component.scss'

const fullWidthLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  }
}

interface DiscountSelectExistingProps {
  onBack: () => void
  onAddCode: (code: string) => void
}

export function DiscountSelectExisting(props: DiscountSelectExistingProps) {
  const [discountCodeInputValue, setDiscountCodeInputValue] = useState('')
  const { data: discountCode, error, isLoading } = hooks.useDiscountCode(discountCodeInputValue)

  function handleBackClick() {
    props.onBack()
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setDiscountCodeInputValue(value)
  }

  function handleMouseDown(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    event.stopPropagation()
  }

  function handleAddCodeClick() {
    props.onAddCode(discountCodeInputValue)
  }

  function getValidateStatus(): ValidateStatus {
    if (!discountCodeInputValue) {
      return ''
    }
    if (isLoading) {
      return 'validating'
    }
    if (error || !discountCode) {
      return 'error'
    }
    if (discountCode) {
      return 'success'
    }
    return ''
  }

  function getHelp(): ReactNode {
    if (isLoading) {
      return ''
    }

    if (error) {
      if (error.name === SequenceEditorError.DiscountCodeNotExists)
        return 'Discount code unavailable'

      return 'Error occured while checking code'
    }

    if (discountCode === undefined) {
      return 'Discount code unavailable'
    }

    if (discountCode !== undefined) {
      return ''
    }
    return ''
  }

  return (
    <div className={styles.selectExistingContainer}>
      <Form className={styles.codeInputForm}>
        <Form.Item
          {...fullWidthLayout}
          label='Selected discount code'
          labelAlign='left'
          colon={false}
          validateStatus={getValidateStatus()}
          help={getHelp()}
          hasFeedback
          data-testid='discount-code-field'
        >
          <Input
            defaultValue=''
            onChange={handleChange}
            onMouseDown={handleMouseDown}
            size='middle'
            placeholder='Insert or type here'
            data-testid='discount-code-input'
          />
        </Form.Item>
      </Form>
      <Button type='primary' disabled={discountCode === undefined} onClick={handleAddCodeClick}>
        Add discount code
      </Button>
      <Button type='link' onClick={handleBackClick}>
        Back
      </Button>
    </div>
  )
}
