import {
  isSMSMessageContactCardUI,
  isSMSMessageMMSUI,
  isSMSMessageTextUI,
  isSMSSequenceItemUI
} from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { selectEditorSequence, selectSequenceItemsById } from '../store/selectors'

export function useHasMMS(): boolean {
  const sequence = useSelector(selectEditorSequence)

  const sequenceItemsById = useSelector(selectSequenceItemsById)
  const sequenceItems = sequence.sequenceItemIds.map(
    (sequenceItemId) => sequenceItemsById[sequenceItemId]
  )

  const { numberOfSMSsOnSequence, numberOfMMSsOnSequence } = sequenceItems.reduce(
    (itemAccumulator, sequenceItem) => {
      if (!isSMSSequenceItemUI(sequenceItem)) {
        return itemAccumulator
      }
      sequenceItem.messages.forEach((message) => {
        if (isSMSMessageTextUI(message)) {
          itemAccumulator.numberOfSMSsOnSequence++
        }
        if (isSMSMessageMMSUI(message) || isSMSMessageContactCardUI(message)) {
          itemAccumulator.numberOfMMSsOnSequence++
        }
      })

      return {
        numberOfSMSsOnSequence: itemAccumulator.numberOfSMSsOnSequence,
        numberOfMMSsOnSequence: itemAccumulator.numberOfMMSsOnSequence
      }
    },
    { numberOfSMSsOnSequence: 0, numberOfMMSsOnSequence: 0 }
  )

  return numberOfMMSsOnSequence > 0
}
