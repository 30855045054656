import { FlowItemUI } from '@ghostmonitor/recartapis'
import { ActionCreator, ThunkAction } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import {
  FlowEditorError,
  FlowEditorErrorLevel
} from '../../../../utils/flow-editor/flow-editor-errors'
import { DashboardState } from '../../../dashboard.state'
import { flowEditorSelectors } from '../../../selectors'
import {
  flowItemValidationFailed,
  flowItemValidationSucceeded,
  messageValidationFailed,
  messageValidationSucceeded
} from '../flow-editor.actions'
import { ValidateFlowThunkBypassWarnings } from './validate-flow.thunk'
import { getValidationResult } from './validators/flow-item-validator'

export type ThunkResult<R> = ThunkAction<R, DashboardState, undefined, any>

export type ValidatorConfigValue = 'onlyIfValidationSucceeded' | 'onlyIfAlreadyFailed' | boolean

export interface ValidateFlowItemThunkArgs extends ValidateFlowThunkBypassWarnings {
  flowItemId: string
  validators: {
    logicFlowItemConnections?: ValidatorConfigValue
    flowItemContent?: ValidatorConfigValue
    conditionExpression?: ValidatorConfigValue
    conditionalSplitV1Deprecated?: ValidatorConfigValue
    scheduledDelay?: ValidatorConfigValue
    keywordReplyConnections?: ValidatorConfigValue
  }
}

export interface ValidationThunkResult {
  hasError: boolean
  hasWarning: boolean
}

// The reason why it's a "pure" thunk instead of a createAsyncThunk is that
// createAsyncThunk automatically dispatches 3 actions for each thunk call
// ond in FER, for 30+ items, it's 100 actions, which is a lot, and slows down
// loading and saving flows.
export function validateFlowItemThunk(
  args: ValidateFlowItemThunkArgs
): ThunkResult<Promise<ValidationThunkResult>> {
  return async (dispatch, getState) => {
    const state: DashboardState = getState() as any
    const { flowItemId } = args

    const validatorConfig: ValidateFlowItemThunkArgs['validators'] = {
      logicFlowItemConnections: false,
      flowItemContent: false,
      conditionExpression: false,
      conditionalSplitV1Deprecated: false,
      scheduledDelay: false,
      keywordReplyConnections: false,
      ...(args.validators ?? {})
    }

    const flow = flowEditorSelectors.selectEditorFlow(state)
    const flowItem = flowEditorSelectors.selectFlowItem<FlowItemUI>(flowItemId)(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    if (!flowItem) {
      throw new Error(FlowEditorError.FlowItemIsNotLoaded)
    }

    const actions: ReturnType<ActionCreator<unknown>>[] = []

    const validationResult = await getValidationResult(flowItem, state, {
      ...args,
      validators: validatorConfig
    })

    for (const failedPayload of validationResult.message.failed) {
      actions.push(messageValidationFailed(failedPayload))
    }

    for (const succeededPayload of validationResult.message.succeeded) {
      actions.push(messageValidationSucceeded(succeededPayload))
    }

    if (validationResult.item.errors.length > 0) {
      actions.push(flowItemValidationFailed({ flowItemId, errors: validationResult.item.errors }))
    } else {
      actions.push(flowItemValidationSucceeded({ flowItemId }))
    }

    batch(() => {
      actions.forEach(dispatch)
    })

    const hasError =
      validationResult.message.failed.length > 0 ||
      validationResult.item.errors.some((error) => error.level === FlowEditorErrorLevel.Error)
    const hasWarning = validationResult.item.errors.some(
      (error) => error.level === FlowEditorErrorLevel.Warning
    )

    return { hasError, hasWarning }
  }
}
