import { FlowDiagram, FlowItemUI, NodeType, SMSMessageFlowItemUI } from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { getMemberOfSegmentConditionalSplitV2FlowItem } from '../../../../routes/flow-editor/utils/get-member-of-segment-conditional-split-v2-item'
import { getNewFlowItem } from '../../../../routes/flow-editor/utils/get-new-flow-item'
import { selectEditorFlow } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'
import { connectNodeTo } from './connect-node'
import { attachEntryFlowItem, detachEntryFlowItem } from './entry-flow-item'
import { insertFlowItem } from './insert-flow-item'
import { insertNode, insertNodeBetweenNodes } from './insert-node'

export function insertAIGeneratedFlowItem(
  state: FlowEditorState,
  nodeType: NodeType,
  flowItem: FlowItemUI,
  logicHandlesLength: number,
  isEntry = false,
  // we need to know when to connect the given message to the else branch
  isConditionUnmet = false,
  expressionIndex?: number
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  insertFlowItem(state, flowItem)
  const newNode = insertNode(state, flowItem._id, nodeType, undefined, logicHandlesLength)

  const entryFlowItemId = flow.entrySequenceItemId

  if (isEntry) {
    const sourceNode = flow.flowEditorDiagram.nodes.find((node) => node.type === NodeType.TRIGGER)
    if (!sourceNode) {
      throw new Error('Source node not found')
    }
    const sourceHandle: FlowDiagram.Handle | undefined = sourceNode.data.followUpHandle

    if (!sourceHandle) {
      throw new Error('Source handle not found')
    }

    if (entryFlowItemId) {
      detachEntryFlowItem(state)

      const targetNode = flow.flowEditorDiagram.nodes.find(
        (node) => node.data.flowItemId === entryFlowItemId
      )

      if (!targetNode) {
        throw new Error('Target node not found')
      }

      const targetHandle = targetNode.data.inputHandle

      if (!targetHandle) {
        throw new Error('Target handle not found')
      }

      insertNodeBetweenNodes(
        state,
        sourceNode.id,
        sourceHandle.id,
        targetNode.id,
        targetHandle.id,
        newNode
      )
    } else {
      connectNodeTo(state, newNode, sourceNode.id, sourceHandle.id)
    }
    attachEntryFlowItem(state, flowItem._id)
  } else {
    // this is AI MVP specific solution where we connect the given segment specific text to the related expression index
    const sourceNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === entryFlowItemId
    )

    if (!sourceNode) {
      throw new Error('Source node not found')
    }

    let sourceHandle: FlowDiagram.Handle | undefined

    // we want to connect the base text to the condition unmet handle
    if (isConditionUnmet) {
      sourceHandle = sourceNode.data.logicHandles?.find(
        (handle) => handle.name === 'logic-port-condition-unmet'
      )
    } else {
      sourceHandle = sourceNode.data.logicHandles?.find(
        (handle) => handle.expressionIndex === expressionIndex
      )
    }

    if (!sourceHandle) {
      throw new Error('Source handle not found')
    }
    connectNodeTo(state, newNode, sourceNode.id, sourceHandle.id)
  }
}

// AI Text generation MVP insert the a conditional split v2 item as entry with all the selected segments

export function addConditionalSpLitV2FlowItemWithMemberOfSegmentContent(
  state: FlowEditorState,
  segmentIds: string[]
) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }
  if (!segmentIds || segmentIds.length === 0) {
    throw new Error('Segments not found')
  }

  const conditionalSplitV2FlowItemId = new ObjectId().toHexString()
  const conditionalSplitV2FlowItem: FlowItemUI = getMemberOfSegmentConditionalSplitV2FlowItem(
    conditionalSplitV2FlowItemId,
    flow,
    segmentIds
  )

  insertAIGeneratedFlowItem(
    state,
    NodeType.CONDITIONAL_SPLIT_FLOW_ITEM_V2,
    conditionalSplitV2FlowItem,
    segmentIds.length + 1,
    true
  )
}

// AI Text generation MVP connects the generated segment specific text to the related member of segment expression

export function addAIGeneratedSMSMessageFlowItem(
  state: FlowEditorState,
  segmentId: string,
  text: string,
  expressionIndex?: number,
  isCustomMessageHeaderEnabled?: boolean
) {
  const flow = selectEditorFlow(state)
  if (!flow) {
    throw new Error('Flow not found')
  }
  if (!segmentId) {
    throw new Error('Segment not found')
  }

  const AIGeneratedSMSMessageFlowItemId = new ObjectId().toHexString()

  const generatedText = `{{site.name|required}}: ${text}`

  const AIGeneratedSMSMessageFlowItem: FlowItemUI = getNewFlowItem<SMSMessageFlowItemUI>({
    nodeType: NodeType.SMS_FLOW_ITEM,
    flowItemId: AIGeneratedSMSMessageFlowItemId,
    flow,
    isEntry: false,
    populatedValues: {
      item: { messages: [{ text: generatedText }] }
    },
    isCustomMessageHeaderEnabled
  })

  insertAIGeneratedFlowItem(
    state,
    NodeType.SMS_FLOW_ITEM,
    AIGeneratedSMSMessageFlowItem,
    0,
    false,
    // 'else' id is used for the condition unmet text
    segmentId === 'else',
    segmentId === 'else' ? undefined : expressionIndex
  )
}
