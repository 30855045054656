import {
  DelaySequenceItemUI,
  DelayUnit,
  isDelaySequenceItemUI,
  SequenceItemUI,
  SequenceUI
} from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import { convertUnitToNanosec } from '../../../utils/time-conversion'
import { sequenceEditorGlobalSettings } from '../config'
import { BaseSequenceItemNodeModel } from '../models/sequence-item/base-sequence-item-model'
import { getDiagramModel } from '../types/get-diagram-model'
import { getNodeModel } from '../types/get-node-model'
import { SequenceItemValidationErrors } from './sequence-item.validator'
import { getLongestConsecutiveDelay } from './utils/get-longest-consecutive-delay'

interface SequenceItemErrors {
  [sequenceItemId: string]: SerializedError[]
}

interface SequenceErrors {
  sequenceErrors: SerializedError[]
  sequenceItemErrors: SequenceItemErrors
}

function validateDelaySequenceItem(
  sequenceItem: DelaySequenceItemUI,
  sequence: SequenceUI,
  sequenceItems: SequenceItemUI[],
  hasMaxDelay: boolean
): SerializedError[] {
  const errors: SerializedError[] = []
  const MIN_DELAY = 60000000000 // 1 minute in ns
  const MAX_DELAY_NANOSEC = convertUnitToNanosec(
    sequenceEditorGlobalSettings.maxDelay,
    DelayUnit.HOURS
  )

  const diagramModel = getDiagramModel(sequence.serializedDiagram)
  const delayNode = getNodeModel<BaseSequenceItemNodeModel>(diagramModel, sequenceItem._id)
  const delayFromPreviousNodes = getLongestConsecutiveDelay(
    sequence,
    sequenceItems,
    delayNode,
    delayNode
  )
  if (
    hasMaxDelay &&
    delayFromPreviousNodes + sequenceItem.logic.delay.delayDuration > MAX_DELAY_NANOSEC
  ) {
    errors.push(new Error(sequenceEditorGlobalSettings.maxDelayNotificationText))
  }
  if (sequenceItem.logic.delay.delayDuration < MIN_DELAY) {
    errors.push(new Error(SequenceItemValidationErrors.DELAY_INVALID_VALUE))
  }
  return errors
}

export function sequenceValidator(
  sequence: SequenceUI,
  sequenceItems: SequenceItemUI[]
): SequenceErrors {
  const hasMaxDelay = sequenceEditorGlobalSettings.maxDelay !== undefined
  const sequenceItemErrors: SequenceItemErrors = sequenceItems.filter(isDelaySequenceItemUI).reduce(
    (acc, sequenceItem) => ({
      ...acc,
      [sequenceItem._id]: validateDelaySequenceItem(
        sequenceItem,
        sequence,
        sequenceItems,
        hasMaxDelay
      )
    }),
    {}
  )
  return {
    sequenceErrors: [],
    sequenceItemErrors
  }
}
