import { ExpressionAPI, Segment } from '@ghostmonitor/recartapis'
import { ExpressionUI, SegmentUI } from '../../condition-ui.type'
import { convertConditionUIToAPI } from './condition'

export function convertExpressionUIToAPI(segmentUI: ExpressionUI): ExpressionAPI {
  const conditionGroups = segmentUI.and.map((conditionGroup) => {
    // Special null condition only used for UI
    const conditionsUI = conditionGroup.or.filter((condition) => condition.slug !== null)
    const conditions = conditionsUI.map((condition) => convertConditionUIToAPI(condition))

    return { or: conditions }
  })

  return { and: conditionGroups }
}

export function convertSegmentUIToAPI(segment: SegmentUI): Segment {
  return {
    ...segment,
    expression: convertExpressionUIToAPI(segment.expression),
    triggers: []
  }
}
