import { UseResourceCache } from '../types/use-resource-cache.type'
import { useBlastsCache } from './use-blasts-cache'

export function useBlastCache(blastId: string): UseResourceCache {
  const { invalidateCache } = useBlastsCache()

  return {
    invalidateCache
  }
}
