import { ButtonActionType } from '@ghostmonitor/recartapis'

const defaultButtonTitles = {
  [ButtonActionType.web_url]: 'Shop Now',
  [ButtonActionType.phone_number]: 'Call number',
  [ButtonActionType.cart_url]: 'Open cart'
}

export function getDefaultButtonTitle(type: string) {
  return defaultButtonTitles[type] ?? 'Shop Now'
}
