import { ReactComponent as ExclamationIcon } from '../../../../../static/ui-kit/icons/normal/exclamation.svg'
import { BlastStatus } from '@ghostmonitor/recartapis'
import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { QUERY_NAME } from '../../../../../hooks/resources/query-names'
import { AppDispatch } from '../../../../../store/create-store'
import { selectEditorBlast } from '../../../../../store/selectors'
import { patchBlastThunk } from '../../../../../store/slices/sequence-editor/thunks/patch-blast.thunk'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'
import styles from './blast-header-edit-button.component.scss'
import { Modal } from '../../../../../components/ui-kit/modal/modal.component'

export function BlastHeaderEditButton() {
  const dispatch: AppDispatch = useDispatch()
  const blast = useSelector(selectEditorBlast)
  const queryClient = useQueryClient()
  const { saveSequenceEditor } = useSaveSequenceEditor()

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function handleClick() {
    setIsOpen(true)
  }

  async function handleEdit() {
    setIsLoading(true)
    try {
      await saveSequenceEditor()
      const patchResult = await dispatch(patchBlastThunk({ status: BlastStatus.DRAFT }))
      if (patchBlastThunk.fulfilled.match(patchResult)) {
        queryClient.invalidateQueries([QUERY_NAME.blasts])
        queryClient.invalidateQueries([QUERY_NAME.blasts, blast._id])
      }
      setIsOpen(false)
    } finally {
      setIsLoading(false)
    }
  }

  function handleCancel() {
    setIsOpen(false)
  }

  function renderModalContent() {
    return (
      <div data-testid='blast-edit-confirm-modal' className={styles.modalContainer}>
        <div className='flex flex-row'>
          <div className='mr-3 icon-sm'>
            <ExclamationIcon />
          </div>
          <div>
            <div className={styles.title}>
              Are you sure you want to edit your scheduled campaign?
            </div>
            <span className={styles.text}>
              It will become a draft and you need to schedule it again.
            </span>
          </div>
        </div>
        <div className='flex justify-end mt-4 items-center'>
          <EditorButton onClick={handleCancel} type='text' data-testid='blast-edit-not-now'>
            Not now
          </EditorButton>
          <EditorButton type='primary' onClick={handleEdit} data-testid='blast-edit-as-draft'>
            Edit as draft
          </EditorButton>
        </div>
      </div>
    )
  }
  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        onCancel={handleCancel}
        width={580}
        className={styles.modalContainer}
      >
        {renderModalContent()}
      </Modal>
      <div className='flex items-center'>
        <EditorButton
          type='primary'
          onClick={handleClick}
          loading={isLoading}
          data-testid='header-edit-button'
        >
          Edit
        </EditorButton>
      </div>
    </>
  )
}
