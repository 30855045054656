import React from 'react'
import styles from './sequence-editor-tray.component.scss'

interface SequenceEditorTrayProps {
  children?: React.ReactNode
}

export function SequenceEditorTray(props: SequenceEditorTrayProps) {
  return <div className={styles.tray}>{props.children}</div>
}
