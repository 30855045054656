import { FlowAPI, Trigger, getFlowTrigger } from '@ghostmonitor/recartapis'

export function checkOtherAutomatedFlowIntegrity(flow: FlowAPI): void {
  const trigger = getFlowTrigger(flow.tags)

  if (
    ![
      Trigger.smsUnsubscribe,
      Trigger.shipmentConfirmed,
      Trigger.shipmentDelivered,
      Trigger.shipmentFailure,
      Trigger.orderCancelled,
      Trigger.orderRefunded,
      Trigger.optimonk
    ].includes(trigger)
  ) {
    throw new Error(
      `Other triggers have to be explicitly listed when checking. Trigger is not found: ${trigger}`
    )
  }

  if (flow.sendingFrequency !== undefined) {
    throw new Error(
      'sendingFrequency is defined on flow, but it only should be defined on Custom Trigger flows'
    )
  }

  if (flow.isSmartSendingEnabled !== true) {
    throw new Error(
      `isSmartSendingEnabled should be defined and true for all other automated flows`
    )
  }

  if (flow.isQuietHoursEnabled !== true) {
    throw new Error(`isQuietHoursEnabled should be defined and true for all other automated flows`)
  }
}
