import { Blast, BlastStatus } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ObjectId } from 'bson'
import { updateBlast } from '../../slices/sequence-editor/sequence-editor.actions'
import { duplicateSequenceThunk } from '../sequence/sequence.thunks'

export const duplicateBlastThunk = createAsyncThunk(
  'sequence/duplicateBlast',
  async ({ sequenceId, blast }: { sequenceId: string; blast: Blast }, store) => {
    const resultAction = await store.dispatch(duplicateSequenceThunk(sequenceId))
    if (duplicateSequenceThunk.rejected.match(resultAction)) {
      throw resultAction.error
    }

    if (duplicateSequenceThunk.fulfilled.match(resultAction)) {
      const newSequenceId = resultAction.payload
      const duplicatedBlast = {
        _id: new ObjectId().toHexString(),
        siteId: blast.siteId,
        sequenceId: newSequenceId,
        name: '',
        scheduledFor: null,
        scheduleType: 'siteTimezone' as Blast['scheduleType'],
        isQuietHoursEnabled: true,
        status: BlastStatus.DRAFT,
        targetRules: blast.targetRules,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }

      store.dispatch(updateBlast({ blast: duplicatedBlast }))

      return newSequenceId
    }
  }
)
