import React from 'react'
import classNames from './canvas-button.component.scss'
import cn from 'classnames'

interface CanvasButtonProps {
  title: string
  active?: boolean
  readOnly?: boolean
  icon?: JSX.Element
  // eslint-disable-next-line react/no-unused-prop-types
  extraStyle?: React.CSSProperties // used via canvas-button-group
  disabled?: boolean
  disabledTitle?: string
  onClick?: () => void
  children?: React.ReactNode
}

export function CanvasButton(props: CanvasButtonProps) {
  return (
    <button
      disabled={props.disabled}
      title={props.disabled ? props.disabledTitle : props.title}
      className={cn(
        classNames['canvas-button'],
        { [classNames.active]: props.active },
        { [classNames.readOnly]: props.readOnly }
      )}
      onClick={props.onClick}
    >
      {props.icon}
      <div className={classNames.label}>{props.children}</div>
    </button>
  )
}
