import { AnyAction, Store } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../store/create-store'
import { DashboardState } from '../../../store/dashboard.state'
import { loadEditorThunk } from '../../../store/slices/sequence-editor/thunks/load-editor.thunk'
import { onEditorLoadedThunk } from '../settings/on-editor-loaded.thunk'

export function editorLifecycleMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  return (next) => (action: AnyAction) => {
    next(action)

    if (action.type === loadEditorThunk.fulfilled.type) {
      dispatch(onEditorLoadedThunk())
    }
  }
}
