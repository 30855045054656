import { useMutation, useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import type {
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  ListApiKeyResponse
} from '@ghostmonitor/recartapis'
import { QUERY_NAME } from './query-names'
import { useMutationHelpers } from '../utils/use-mutation-helpers'

export function useApiKeys() {
  const queryName = [QUERY_NAME.apiKeys]
  const { handleError, handleSettled, handleRemove, handlePatch } = useMutationHelpers(queryName)

  const { isLoading, data, error } = useQuery<ListApiKeyResponse>(queryName, () =>
    request.get<ListApiKeyResponse>(`apikeys`)
  )

  function createApiKey(newApiKey: CreateApiKeyRequest) {
    return request.post<CreateApiKeyResponse>(`apikeys`, newApiKey)
  }

  const createApiKeyMutation = useMutation(createApiKey, {
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  function deleteApiKey(apiKeyId: string) {
    return request.del(`apikeys/${apiKeyId}`)
  }

  const deleteApiKeyMutation = useMutation(deleteApiKey, {
    onMutate: handleRemove,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: data?.data,
    isLoading,
    error,
    createApiKey: createApiKeyMutation.mutateAsync,
    deleteApiKey: deleteApiKeyMutation.mutateAsync
  }
}
