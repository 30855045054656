import {
  DiscountCodePool,
  GetDiscountCodePoolResponse,
  GetDiscountCodePoolsResponse,
  UpdateDiscountCodePoolTargetCountRequest,
  UpdateDiscountCodePoolTargetCountResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export type UpdateDiscountCodePoolTargetCountRequestUI = Omit<
  UpdateDiscountCodePoolTargetCountRequest,
  'siteId' | 'discountCodePoolId'
>

export function useDiscountCodePool(discountCodePoolId?: string): UseResource<DiscountCodePool> {
  const queryClient = useQueryClient()
  const queryName = [QUERY_NAME.discountCodePool, discountCodePoolId]

  const { isInitialLoading, data, error, refetch } = useQuery<GetDiscountCodePoolResponse>(
    queryName,
    async () => {
      return request.get<GetDiscountCodePoolResponse>(`discount-code-pools/${discountCodePoolId}`)
    },
    {
      enabled: discountCodePoolId !== undefined
    }
  )

  const { mutateAsync: updateDiscountCodePool, isLoading: isUpdateDiscountCodePoolLoading } =
    useMutation<
      UpdateDiscountCodePoolTargetCountResponse,
      unknown,
      UpdateDiscountCodePoolTargetCountRequestUI
    >(
      (discountCodePool) => {
        return request.patch<UpdateDiscountCodePoolTargetCountResponse>(
          `discount-code-pools/${discountCodePoolId}`,
          discountCodePool
        )
      },
      {
        onSuccess: (
          updateDiscountCodePoolTargetCountResponse: UpdateDiscountCodePoolTargetCountResponse
        ) => {
          const discountCodePools: GetDiscountCodePoolsResponse = queryClient.getQueryData([
            QUERY_NAME.discountCodePools
          ])
          if (discountCodePools) {
            const newDiscountCodePools = {
              data: discountCodePools.data.map((discountCodePool) =>
                discountCodePool.id === discountCodePoolId
                  ? updateDiscountCodePoolTargetCountResponse.data
                  : discountCodePool
              )
            }
            queryClient.setQueryData([QUERY_NAME.discountCodePools], newDiscountCodePools)
            queryClient.invalidateQueries([QUERY_NAME.discountCodePools])
          }
          queryClient.invalidateQueries(queryName)
        }
      }
    )

  return {
    isLoading: isInitialLoading,
    data: data?.data,
    error,
    refetch: refetch as any,
    patch: updateDiscountCodePool,
    isUpdateDiscountCodePoolLoading
  }
}
