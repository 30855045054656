import { Carousel } from 'antd'
import { CarouselRef as CarouselRefType } from 'antd/lib/carousel'
import cn from 'classnames'
import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SortableHandle } from 'react-sortable-hoc'
import { ReactComponent as DeleteIcon } from '../../../../static/images/svg/streamline/outline/01-Interface-Essential/23-Delete/bin-1.svg'
import { ReactComponent as MenuIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/03-Menu/navigation-menu.svg'
import { ReactComponent as ArrowLeftIcon } from '../../../../static/ui-kit/icons/small/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../static/ui-kit/icons/small/arrow-right.svg'
import { selectSequenceItemMeta } from '../../../../store/selectors'
import {
  galleryStepLeft,
  galleryStepRight,
  galleryStepToIndex
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { SequenceItemButton } from '../sequence-item-button/sequence-item-button.component'
import styles from './gallery-carousel.component.scss'

const DragHandle = SortableHandle(() => <MenuIcon />)

export interface GalleryCarouselProps {
  items: React.ReactElement[]
  onAddCard: (jumpToLast: (index: number) => void) => void
  onRemoveCard: (cardIndex: number) => void
  disableControls?: boolean
  messageIndex: number
  sequenceItemId: string
}

export const GalleryCarousel: React.FunctionComponent<GalleryCarouselProps> = (props) => {
  const dispatch = useDispatch()
  const stateSelector = selectSequenceItemMeta(props.sequenceItemId)
  const state = useSelector(stateSelector)
  const carouselRef = useRef<CarouselRefType>(null)

  const { activeIndex } = state.messages[props.messageIndex]

  function jumpToIndex(itemIndex: number) {
    if (carouselRef?.current) {
      carouselRef.current.goTo(itemIndex)
    }
    dispatch(
      galleryStepToIndex({
        sequenceItemId: props.sequenceItemId,
        messageIndex: props.messageIndex,
        index: itemIndex
      })
    )
  }

  function handleStepLeft() {
    if (carouselRef?.current) {
      carouselRef.current.prev()
    }
    dispatch(
      galleryStepLeft({
        sequenceItemId: props.sequenceItemId,
        messageIndex: props.messageIndex
      })
    )
  }

  function handleStepRight() {
    if (carouselRef?.current) {
      carouselRef.current.next()
    }
    dispatch(
      galleryStepRight({
        sequenceItemId: props.sequenceItemId,
        messageIndex: props.messageIndex
      })
    )
  }

  function removeCard(index: number) {
    props.onRemoveCard(index)
    setTimeout(() => {
      if (index === 0) {
        jumpToIndex(0)
      } else {
        handleStepLeft()
      }
    }, 200)
  }

  function handleAddCard() {
    props.onAddCard(jumpToIndex)
  }

  function onMouseOverHandle(event: React.MouseEvent) {
    event.stopPropagation()
    return false
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.handle}
        onMouseOver={onMouseOverHandle}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <DragHandle />
      </div>
      <Carousel ref={carouselRef}>
        {props.items.map((item, itemIndex) => (
          <div key={`caroussel-item-${itemIndex}-${props.items.length}`}>{item}</div>
        ))}
      </Carousel>
      <div className={styles.toolbar}>
        {!props.disableControls && (
          <SequenceItemButton
            icon={<DeleteIcon />}
            onClick={() => removeCard(activeIndex)}
            disabled={props.items.length === 1}
            className={cn({ [styles.hidden]: props.items.length <= 1 })}
          />
        )}
        <SequenceItemButton
          onClick={handleStepLeft}
          icon={<ArrowLeftIcon />}
          disabled={activeIndex === 0}
        />
        <SequenceItemButton
          icon={<ArrowRightIcon />}
          onClick={handleStepRight}
          disabled={props.items.length - 1 <= activeIndex}
        />
      </div>
      {!props.disableControls && (
        <div className={styles['add-card']} onClick={handleAddCard}>
          + Add card
        </div>
      )}
    </div>
  )
}
