import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QUERY_NAME } from '../../../../hooks/resources/query-names'
import { UseResource } from '../../../../hooks/types/use-resource.type'
import { flowEditorSelectors } from '../../../../store/selectors'
import { api } from '../../../../utils/api'
import { getSMSCampaignUIStatus } from '../../../../utils/sms/get-sms-campaign-ui-status'

// This hook is meant to be used in Flow Editor to poll SMS Campaign status
// In lists, use useSMSCampaigns instead
export function useSMSCampaignStatus(
  smsCampaignId: string,
  refetchInterval = 3000,
  isEnabled = true
): UseResource<ReturnType<typeof getSMSCampaignUIStatus>> {
  const queryName = [QUERY_NAME.smsCampaign, smsCampaignId]
  const isEverSaved = useSelector(flowEditorSelectors.selectEditorFlowMeta)?.isEverSaved

  const { isInitialLoading, data: smsCampaign } = useQuery(
    queryName,
    async () => {
      return api.getSMSCampaign(smsCampaignId)
    },
    { enabled: smsCampaignId !== undefined && isEverSaved === true && isEnabled, refetchInterval }
  )

  return {
    data: smsCampaign ? getSMSCampaignUIStatus(smsCampaign) : undefined,
    isLoading: isInitialLoading
  }
}
