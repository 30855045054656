import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hooks } from '../../../../hooks/hooks'
import { quietHoursTooltipText } from '../../../../routes/flow-editor/utils/sms-campaign-settings'
import { AppDispatch } from '../../../../store/create-store'
import { selectEditorSequence } from '../../../../store/selectors'
import {
  updateQuietHours,
  updateSequenceName
} from '../../../../store/slices/sequence/sequence.actions'
import { formatters } from '../../../../utils/formatters/formatters'
import { quietHoursTimeFormat } from '../../../../utils/utils'
import { UIKit } from '../../../ui-kit/ui-kit'
import { Label } from '../label/label.component'
import { Title } from '../title/title.component'
import { Toggle } from '../toggle/toggle.component'
import styles from './loopwork.component.scss'

export function LoopWorkDrawer() {
  const sequence = useSelector(selectEditorSequence)
  const { data: quietHoursSettings } = hooks.useSMSQuietHoursSettings()
  const [isNameChanged, setIsNameChanged] = useState(false)
  const dispatch: AppDispatch = useDispatch()

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setIsNameChanged(true)
    dispatch(updateSequenceName({ name: event.target.value }))
  }

  function handleQuietHoursChange(isChecked: boolean) {
    dispatch(updateQuietHours({ isQuietHoursEnabled: isChecked }))
  }

  return (
    <div data-testid='loopwork-settings-drawer'>
      <Title>Settings</Title>
      <Label title='Flow name'>
        <UIKit.Input
          key='sequence-name'
          data-testid='loopwork-name'
          value={sequence.name ?? ''}
          onChange={handleNameChange}
        />
        {isNameChanged && sequence.name === '' && (
          <div className={styles.error}>Flow name cannot be empty.</div>
        )}
      </Label>
      <Label title='Sending options'>
        <Toggle
          title='Quiet hours'
          subTitle={
            quietHoursSettings?.start &&
            quietHoursSettings?.end &&
            `From ${formatters.date(
              quietHoursSettings?.start,
              quietHoursTimeFormat
            )} to ${formatters.date(
              quietHoursSettings?.end,
              quietHoursTimeFormat
            )} in the subscriber’s time zone`
          }
          tooltipText={quietHoursTooltipText}
          onChange={handleQuietHoursChange}
          checked={sequence.isQuietHoursEnabled}
          data-testid='quiet-hours-toggle'
        />
      </Label>
    </div>
  )
}
