import { useMutation } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { Toast } from '../../components/ui-kit/toast/toast.component'
import { config } from '../../config'
import { api } from '../../utils/api'

export function useAuth() {
  const navigate = useNavigate()
  const location = useLocation()

  const login = useMutation(api.login, {
    onSuccess: (response) => {
      const { token } = response
      localStorage.setItem('x-auth-token', token as string)

      const searchParams = new URLSearchParams(location.search)
      const redirectTo = searchParams.get('redirectTo') ?? '/'
      const redirectUrl = decodeURIComponent(redirectTo) || config.START_URL
      navigate(redirectUrl)
    },
    onError: () => {
      Toast({
        type: 'error',
        key: `login-error`,
        content: 'Failed to login'
      })
    }
  })

  const demoLogin = useMutation(api.demoLogin, {
    onSuccess: (response) => {
      const { token } = response
      localStorage.setItem('x-auth-token', token as string)

      const searchParams = new URLSearchParams(location.search)
      const redirectTo = searchParams.get('redirectTo') ?? '/'
      const redirectUrl = decodeURIComponent(redirectTo) || config.START_URL
      navigate(redirectUrl)
    },
    onError: () => {
      Toast({
        type: 'error',
        key: `login-error`,
        content: 'Failed to login'
      })
    }
  })

  return {
    login: login.mutateAsync,
    demoLogin: demoLogin.mutateAsync,
    isLoginLoading: login.isLoading
  }
}
