import {
  isMessengerMessageSequenceItemUI,
  isSMSMessageMMSUI,
  isSMSSequenceItemUI,
  SequenceItemUI
} from '@ghostmonitor/recartapis'
import React, { ReactElement } from 'react'
import {
  isMessageGenericGalleryTemplate,
  isMessageGenericTemplate,
  isMessageMediaTemplate
} from '../../routes/SequenceEditor/utils/assert-message-template-type'
import { ReactComponent as PlaceholderIcon } from '../../static/ui-kit/illustrations/custom/list-placeholder.svg'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'

function getThumbnailFromSequenceItem(
  sequenceItem: SequenceItemUI
): ReactElement<HTMLImageElement> | ReactElement<React.SVGProps<SVGElement>> {
  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    for (const message of sequenceItem.messages) {
      if (isMessageMediaTemplate(message)) {
        return <img src={message.messengerTemplatePayload.url} />
      }

      if (isMessageGenericTemplate(message)) {
        return <img src={message.messengerTemplatePayload.image_url} />
      }

      if (isMessageGenericGalleryTemplate(message)) {
        return <img src={message.messengerTemplatePayload[0].image_url} />
      }

      if (message.attachmentPayload !== undefined) {
        return <img src={message.attachmentPayload.url} />
      }
    }
  }
  if (isSMSSequenceItemUI(sequenceItem)) {
    for (const message of sequenceItem.messages) {
      if (isSMSMessageMMSUI(message)) {
        return <img src={message.attachments[0]} />
      }
    }
  }
  return <PlaceholderIcon />
}

export function useThumbnail(
  sequenceId: string
): UseResource<ReactElement<HTMLImageElement> | ReactElement<React.SVGProps<SVGElement>>> {
  const { data: sequence, isLoading: isSequenceLoading } = hooks.useSequence(sequenceId)

  const { isLoading: isSequenceItemLoading, data: sequenceItem } = hooks.useSequenceItem(
    sequence?.entrySequenceItemId
  )

  return {
    isLoading: isSequenceLoading || isSequenceItemLoading,
    data: getThumbnailFromSequenceItem(sequenceItem)
  }
}
