import { isMessageGenericGalleryTemplate } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { RemoveMessageItemAction, REMOVE_BUTTON } from '../sequence-editor.actions'
import { selectSequenceItem, selectSequenceItemMeta } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
import { removeButtonActionHandler } from './remove-button.handler'

const { info } = createScope('sequence-editor')

export function removeMessageItemActionHandler(
  state: SequenceEditorState,
  action: RemoveMessageItemAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  function removeButton(buttonIndex: number) {
    removeButtonActionHandler(state, {
      type: REMOVE_BUTTON,
      sequenceItemId: action.sequenceItemId,
      messageIndex: action.messageIndex,
      buttonIndex,
      messageItemIndex: action.messageItemIndex
    })
  }

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    if (isMessageGenericGalleryTemplate(message)) {
      const messageItem = message.messengerTemplatePayload[action.messageItemIndex]

      const messageItemsLength = messageItem.buttons.length
      for (let i = messageItemsLength - 1; i >= 0; i--) {
        removeButton(i)
      }

      message.messengerTemplatePayload.splice(action.messageItemIndex, 1)

      if (
        sequenceItemMeta.messages[action.messageIndex].messageItems[action.messageItemIndex].errors
          .length > 0
      ) {
        state.errorCount -=
          sequenceItemMeta.messages[action.messageIndex].messageItems[
            action.messageItemIndex
          ].errors.length
      }

      sequenceItemMeta.messages[action.messageIndex].messageItems.splice(action.messageItemIndex, 1)
    } else {
      info('removeMessageItemActionHandler:1', { action, sequenceItem })
    }
  } else {
    info('removeMessageItemActionHandler:2', { action, sequenceItem })
  }
}
