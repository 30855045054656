import { Stat } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useEmailStatWithoutContext(dateFrom?: string, dateTo?: string): UseResource<Stat> {
  const queryName = [QUERY_NAME.emailStatNoContext]

  const queryParams: {
    startDate?: string
    endDate?: string
  } = {}

  if (dateFrom) {
    queryParams.startDate = dateFrom
    queryName.push(dateFrom)
  }

  if (dateTo) {
    queryParams.endDate = dateTo
    queryName.push(dateTo)
  }

  const {
    isInitialLoading,
    data: emailStat,
    isError
  } = useQuery<Stat>(
    queryName,
    async () => {
      return request.get<Stat>('statistics/email', {
        params: queryParams
      })
    },
    {
      enabled: dateFrom !== undefined && dateTo !== undefined
    }
  )

  return {
    data: emailStat,
    isLoading: isInitialLoading,
    isError
  }
}
