import { isSMSSequenceItemUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import {
  selectEditorBlast,
  selectEditorSequence,
  selectSequenceItemsById
} from '../store/selectors'
import { hooks } from './hooks'
export interface UseDiscountPoolSizeAndAvailability {
  isLoading: boolean
  poolSizeEnough: boolean
  availableCountEnough: boolean
  smallestSizePoolId: string
}

export function useDiscountPoolSizeAndAvailability(): UseDiscountPoolSizeAndAvailability {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } =
    hooks.useDiscountCodePools()
  const blast = useSelector(selectEditorBlast)
  const { data: subscribersCount, isLoading: isSubscriberCountLoading } = hooks.useSubscribersCount(
    'sms',
    blast?.targetRules
  )
  const sequence = useSelector(selectEditorSequence)

  const sequenceItemsById = useSelector(selectSequenceItemsById)
  const sequenceItems = sequence.sequenceItemIds.map(
    (sequenceItemId) => sequenceItemsById[sequenceItemId]
  )
  if (isDiscountCodePoolsLoading || isSubscriberCountLoading) {
    return {
      isLoading: true,
      poolSizeEnough: false,
      availableCountEnough: false,
      smallestSizePoolId: undefined
    }
  }

  const attachedDiscountPoolIds = sequenceItems
    ?.filter((item) => {
      if (isSMSSequenceItemUI(item) && item.discountCodePoolId) {
        return item
      }
      return null
    })
    .map((item) => {
      if (isSMSSequenceItemUI(item)) {
        return item.discountCodePoolId
      }
      return null
    })

  const attachedPoolSizes = discountCodePools
    .filter((pool) => attachedDiscountPoolIds.includes(pool.id))
    .map((pool) => pool.targetCount)

  const minAttachedPoolSize = Math.min(...attachedPoolSizes)
  const attachedPoolAvailableCount = discountCodePools
    .filter((pool) => attachedDiscountPoolIds.includes(pool.id))
    .map((pool) => pool.availableCount)

  const minAttachedAvailableCount = Math.min(...attachedPoolAvailableCount)
  const numberOfSubscribers = subscribersCount?.count ?? 0
  const smallestSizePoolId = discountCodePools.find(
    (pool) => pool.targetCount === minAttachedPoolSize
  )?.id

  return {
    isLoading: isDiscountCodePoolsLoading || isSubscriberCountLoading,
    poolSizeEnough: minAttachedPoolSize >= numberOfSubscribers,
    availableCountEnough: minAttachedAvailableCount >= numberOfSubscribers,
    smallestSizePoolId
  }
}
