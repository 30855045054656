import cn from 'classnames'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from '../../../../components/ui-kit/tooltip/tooltip.component'
import { hooks } from '../../../../hooks/hooks'
import { ReactComponent as InfoIcon } from '../../../../static/images/svg/sequence-editor/info-icon.svg'
import { selectEditorBlast, selectIsReadOnly } from '../../../../store/selectors'
import {
  selectSiteId,
  selectSiteName,
  selectUrlGenerationSettings,
  selectSupportEmail
} from '../../../../store/slices/site/site.selectors'
import { getSequenceEditorSMSStat } from '../../utils/get-sms-stat'
import styles from './sms-counter-box.component.scss'

export interface SMSCounterBoxProps {
  unit?: string
  messageText: string
  isCharCountHidden?: boolean
  tooltipText?: string | ReactNode
}

export function SMSCounterBox(props: SMSCounterBoxProps) {
  const [characterCount, setCharacterCount] = useState(0)
  const [segmentCount, setSegmentCount] = useState(0)
  const [hasSpecialValue, setHasSpecialValue] = useState(false)

  const siteName = hooks.useSiteSelector(selectSiteName)
  const siteId = hooks.useSiteSelector(selectSiteId)
  const supportEmail = hooks.useSiteSelector(selectSupportEmail)
  const urlGenerationSettings = hooks.useSiteSelector(selectUrlGenerationSettings)
  const blast = useSelector(selectEditorBlast)
  const isReadOnly = useSelector(selectIsReadOnly)

  useEffect(() => {
    const { characterCount, segmentCount, hasSpecialValue } = getSequenceEditorSMSStat(
      props.messageText,
      {
        siteName: siteName ?? '',
        supportEmail,
        urlGenerationSettings,
        blast,
        isReadOnly
      }
    )
    setCharacterCount(characterCount)
    setSegmentCount(props.unit === 'MMS' ? 1 : segmentCount)
    setHasSpecialValue(hasSpecialValue)
  }, [props.messageText, siteName, urlGenerationSettings, supportEmail, props.unit])

  if (siteName === undefined || siteId === undefined) {
    return null
  }

  return (
    <div className='flex items-center'>
      <div className={styles.counterText}>
        {!props.isCharCountHidden && `${characterCount} / `}
        <span
          className={cn({
            [styles.isBlue]: hasSpecialValue || props.unit === 'MMS',
            [styles.counterText]: !hasSpecialValue
          })}
        >
          {segmentCount} {props.unit ?? 'SMS'}
        </span>
      </div>
      {!props.isCharCountHidden && (
        <Tooltip
          placement='top'
          title={
            props.tooltipText ?? (
              <div>
                The number of SMS is determined by many elements, like adding an image, using
                emojis, or adding variables.{' '}
                <a
                  href='https://help.recart.com/en/articles/5905127-sms-character-counting-and-limits'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Learn more
                </a>
              </div>
            )
          }
          arrowPointAtCenter
        >
          <InfoIcon
            className={cn(styles.icon, {
              [styles.isBlue]: hasSpecialValue || props.unit === 'MMS'
            })}
          />
        </Tooltip>
      )}
    </div>
  )
}
