import throttle from 'lodash/throttle'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { DiagramEngine } from 'storm-react-diagrams'
import { selectLaunchDarklyFlag } from '../../../store/selectors'
import { updateModelOnSequence } from '../../../store/slices/sequence-editor/sequence-editor.actions'

export function useCanvasZoom(diagramEngine: DiagramEngine, defaultZoomLevel = 100) {
  const dispatch = useDispatch()
  const isWheelZoomEnabled = useSelector(selectLaunchDarklyFlag('wheel-zoom'))
  const [zoomLevel, setZoomLevel] = useState(defaultZoomLevel)
  const diagramModel = diagramEngine.getDiagramModel()

  function handleZoom(direction: string) {
    const oldZoomLevel = diagramModel.getZoomLevel()
    let newZoomlevel = 100
    switch (direction) {
      case 'in':
        newZoomlevel = oldZoomLevel + 10
        break
      case 'out':
        newZoomlevel = oldZoomLevel - 10
        break
      default:
        diagramEngine.zoomToFit()
        diagramModel.setOffsetX(diagramModel.getOffsetX() + 150)
        diagramModel.setOffsetY(diagramModel.getOffsetY() + 150)
        break
    }

    // Don't let zoom too far
    if (newZoomlevel > 300 || newZoomlevel < 10) {
      return false
    }
    diagramModel.setZoomLevel(newZoomlevel)

    // force modal change to update the zoom level on the serialized diagram
    const serializedDiagram = diagramModel.serializeDiagram()
    dispatch(updateModelOnSequence(serializedDiagram as any))
    setZoomLevel(Math.round(diagramModel.getZoomLevel() * 100) / 100)

    diagramEngine.setDiagramModel(diagramModel)
    diagramEngine.repaintCanvas()
  }

  const zoomOnWheel = useCallback(
    throttle((direction: string) => {
      handleZoom(direction)
    }, 100),
    [diagramModel]
  )

  function handleWheel(event: React.WheelEvent<HTMLDivElement>) {
    if (!isWheelZoomEnabled) {
      return
    }
    if (event.deltaY > 0) {
      zoomOnWheel('out')
    } else {
      zoomOnWheel('in')
    }
  }

  return {
    zoomLevel,
    handleZoom,
    handleWheel
  }
}
