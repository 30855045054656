import React from 'react'
import { ReactComponent as ShopifyBagLogo } from '../../../../../../static/images/svg/logo-shopify-bag.svg'
import { ReactComponent as RecartLogoSmall } from '../../../../../../static/images/svg/recart-logo-sm.svg'
import styles from './discount-code-logos.component.scss'
import cn from 'classnames'

export function DiscountCodeLogos() {
  return (
    <div className={cn(styles.container, 'flex flex-col')}>
      <div className={cn(styles.optionContainer, 'flex flex-row')}>
        <div className={styles.logo}>
          <ShopifyBagLogo />
        </div>
        <div>Add a Shopify discount code to your message</div>
      </div>
      <div className={cn(styles.optionContainer, 'flex flex-row')}>
        <div className={styles.logo}>
          <RecartLogoSmall />
        </div>
        <div>Orders with this discount code will be tracked as Recart conversions</div>
      </div>
    </div>
  )
}
