import { Button } from 'antd'
import cn from 'classnames'
import React from 'react'
import styles from './discount-added.component.scss'
import { DiscountTypeLabels } from './types/discount-type-labels'

export interface DiscountAddedProps {
  code: string
  type?: string
  onRemove: () => void
}

export function DiscountAdded(props: DiscountAddedProps) {
  function handleRemoveClick() {
    props.onRemove()
  }

  return (
    <div className={cn(styles.container, 'flex flex-col items-center')}>
      <span className={styles.codeBox}>{props.code}</span>
      {props.type && (
        <>
          <div className={styles.codeType}>{DiscountTypeLabels[props.type]}</div>
          <div className={styles.info}>
            You can change the discount settings anytime in your Shopify admin.
          </div>
        </>
      )}
      <Button type='link' onClick={handleRemoveClick}>
        Remove
      </Button>
    </div>
  )
}
