export enum DashboardErrorMessages {
  API_REQUEST_FAILED = 'Your request cannot be completed right now. Please try again later.',
  CAN_ONLY_BE_CREATED_ON_ENTRY = 'This type of message can only be created on the first item.',
  CAN_UPLOAD_ONLY_ONE_FILE = 'Each message can contain only one image or one contact card. Please add a follow-up message to add another file.',
  CONVERSARION_FLOW_NAME_EMPTY_ERROR = "Your conversation flow's name cannot be empty. Please enter a valid name for it.",
  COULDNT_LOAD_FLOW = 'Could not find conversation flow. Please try again or contact support.',
  FACEBOOK_CONNECTION_ERROR_NO_RESPONSE = "Couldn't connect to your Facebook account.",
  FACEBOOK_NOT_SAME_ID_ERROR = 'Please use the same account that was used to connect the Facebook Page, or reconnect the Page with the same user before adding an ad account.',
  FACEBOOK_PAGE_CONNECTION_ERROR = "Couldn't connect your Facebook Page. Please try again or contact support.",
  FACEBOOK_PAGE_INSUFFICIENT_ROLE_ERROR = 'You need to have Admin role to connect your Facebook Page to Recart.',
  LEGAL_CANT_HAVE_FILE = 'Legal message can not contain image or contact card.',
  SEQUENCE_ITEM_TYPE_NOT_FOUND = 'Unknown SequenceItem type.',
  SEQUENCE_NAME_ALREADY_EXISTS = 'You already have a flow with this name.',
  LOCAL_STORAGE_QUOTA_EXCEED_RETRY_LIMIT_REACHED = 'LocalStorageQuotaExceeded after 5 retries',
  LOCAL_STORAGE_ERROR_NOT_EXCEED_QUOTA = 'LocalStorage error not quota related'
}

export enum StatsErrorTypes {
  LOAD_STATS_FAILED = "Couldn't load statistics"
}

export enum DashboardErrors {
  FACEBOOK_CONNECT_ERROR_NOT_SAME_ID = 'dashboardErrors/FACEBOOK_CONNECT_ERROR_NOT_SAME_ID',
  FACEBOOK_CONNECT_ERROR_NO_RESPONSE = 'dashboardErrors/FACEBOOK_CONNECT_ERROR_NO_RESPONSE',
  CannotParseStoreUrl = 'dashboardErrors/CannotParseStoreUrl',
  DataLayerIsNotDefined = 'dashboardErrors/DataLayerIsNotDefined',
  ToggleFlowFailed = 'dashboardErrors/ToggleFlowFailed',
  FailedToLoadSubscriptions = 'FailedToLoadSubscriptions',
  BillingIsActiveButNoActiveChannel = 'BillingIsActiveButNoActiveChannel',
  UnknownSubscriptionChannel = 'UnknownSubscriptionChannel'
}
