import { UpdateDisplayMetaAction } from '../sequence-editor.actions'
import { selectEditorSequence } from '../sequence-editor.selectors'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { DelaySequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/delay/delay-sequence-item-model'
import { SequenceEditorState } from '../sequence-editor.state'

export function updateDisplayMetaHandler(
  state: SequenceEditorState,
  action: UpdateDisplayMetaAction
) {
  const sequence = selectEditorSequence(state)

  const diagramModel = getDiagramModel(sequence.serializedDiagram)
  const node = getNodeModel<DelaySequenceItemNodeModel>(diagramModel, action.sequenceItemId)

  node.setDisplayMeta(action.displayMeta)
  sequence.serializedDiagram = diagramModel.serializeDiagram() as any
}
