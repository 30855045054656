import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import type { ModalFuncProps as AntModalFuncProps } from 'antd/lib/modal'
import cn from 'classnames'
import React from 'react'
import commonStyles from '../modals.component.scss'
import styles from './error-modal.component.scss'

type ModalProps = AntModalFuncProps

export function showErrorModal({
  className,
  icon = <CloseCircleOutlined />,
  ...restProps
}: ModalProps): void {
  const iconClasses = cn('anticon icon-sm', styles.icon)

  Modal.error({
    className: cn(commonStyles.container, className),
    okButtonProps: {
      type: 'primary',
      // @ts-expect-error data-testid
      'data-testid': 'error-modal-ok-button'
    },
    centered: true,
    icon: <i className={iconClasses}>{icon}</i>,
    width: 576,
    ...restProps
  })
}
