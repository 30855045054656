import { Form } from 'antd'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { EditorButton } from '../../../../../../components/ui-kit/editor-button/editor-button.component'
import { Input } from '../../../../../../components/ui-kit/input/input.component'
import { useDiscountCode } from '../../../../../../hooks/resources/use-discount-code'
import { SequenceEditorError } from '../../../../types/sequence-editor-errors'
import { DiscountCodeLogos } from '../discount-code-logos/discount-code-logos.component'
import styles from './create-static-discount-code.scss'

interface CreateStaticDiscountCodeProps {
  footer: React.FC<{ discountCodeExists: boolean }>
  onNext: (discountCodeName: string) => void
}

const fullWidthLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  }
}

export function CreateStaticDiscountCode(props: CreateStaticDiscountCodeProps) {
  const { onNext } = props
  const [discountCodeName, setDiscountCodeName] = useState<string>()
  const [validateDiscountCode, setValidateDiscountCode] = useState(false)
  const [errorHint, setErrorHint] = useState<string>()
  const {
    data: discountCode,
    isLoading: isDiscountCodeValidationLoading,
    isError: isDiscountCodeValidationError,
    error: discountCodeValidationError
  } = useDiscountCode(validateDiscountCode ? discountCodeName : undefined)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDiscountCodeName(event.target.value)
  }

  function handleNext() {
    setValidateDiscountCode(true)
  }

  useEffect(() => {
    if (validateDiscountCode && !isDiscountCodeValidationLoading) {
      setValidateDiscountCode(false)

      if (
        isDiscountCodeValidationError &&
        discountCodeValidationError.name === SequenceEditorError.DiscountCodeExpired
      ) {
        setErrorHint('The discount code is expired')
      } else {
        onNext(discountCodeName)
      }
    }
  }, [
    onNext,
    discountCodeName,
    validateDiscountCode,
    discountCode,
    isDiscountCodeValidationLoading,
    isDiscountCodeValidationError,
    discountCodeValidationError
  ])

  return (
    <div data-testid='create-discount-code-form' className={styles.container}>
      <h2 className='px-0 pt-2 pb-1 text-sm font-bold'>Add general discount code</h2>
      <p className={cn(styles.text, 'text-tiny mb-2')}>
        Send the same discount code to all of your subscribers. Create a new discount code or use an
        existing one in your messages.{' '}
      </p>
      <p className={cn(styles.text, ' text-tiny font-semibold mb-3')}>
        Pro tip: We recommend you always use a new discount code.{' '}
      </p>
      <DiscountCodeLogos showShopify />
      <Form layout='vertical'>
        <Form.Item
          {...fullWidthLayout}
          label='Discount code name'
          labelAlign='left'
          validateStatus={errorHint ? 'error' : 'success'}
          help={errorHint}
          className={styles.formItem}
        >
          <Input
            value={discountCodeName}
            data-testid='discount-code-name'
            size='middle'
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item className={styles.formButtonWrapper}>
          <EditorButton
            onClick={handleNext}
            loading={isDiscountCodeValidationLoading}
            disabled={discountCodeName === undefined}
            type='primary'
            className='w-full'
            data-testid='button-next'
          >
            Next
          </EditorButton>
          <props.footer discountCodeExists={false} />
        </Form.Item>
      </Form>
    </div>
  )
}
