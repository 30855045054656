import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom-v5-compat'
import { useMe } from '../../../hooks/resources/use-me'
import { useMeCache } from '../../../hooks/resources/use-me-cache'
import { flowEditorSelectors } from '../../../store/selectors'

export function useFlowIsLoaded(): boolean {
  const params = useParams<{ flowId: string }>()

  useMe()
  const { invalidateCache } = useMeCache()
  const variablePlaceholder = useSelector(flowEditorSelectors.selectVariablePlaceholder)

  useEffect(() => {
    return () => {
      invalidateCache()
    }
  }, [invalidateCache])

  return params.flowId !== undefined && variablePlaceholder !== null
}
