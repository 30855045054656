import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { useEstimatedCost } from '../../../../../hooks/resources/use-estimated-cost'
import { useSegmentsList } from '../../../../../hooks/resources/use-segments-list'
import { useSiteSelector } from '../../../../../hooks/use-site-selector'
import { selectEditorBlast } from '../../../../../store/selectors'
import { selectSiteId } from '../../../../../store/slices/site/site.selectors'
import { useSMSSegmentCountInSequence } from './use-sms-segment-count-in-sequence'

export interface UseSMSEstimation {
  isLoading: boolean
  numberOfSubscribers: number
  numberOfSMSs: number
  numberOfMMSs: number
  totalNumber: number
  estimatedCost: number
  excludedSegments: string[]
  includedSegments: string[]
}

export function useSMSEstimation(isEnabled: boolean): UseSMSEstimation {
  const { smsSegmentCount, mmsSegmentCount, mmsToSmsSegmentCount } = useSMSSegmentCountInSequence()

  const blast = useSelector(selectEditorBlast)
  const includeSegmentIds = get(blast, 'targetRules.includeSegmentIds', [])
  const excludeSegmentIds = get(blast, 'targetRules.excludeSegmentIds', [])

  const { data: estimatedCost, isLoading: isEstimatedCostLoading } = useEstimatedCost(
    blast._id,
    smsSegmentCount,
    mmsSegmentCount,
    mmsToSmsSegmentCount,
    includeSegmentIds,
    excludeSegmentIds,
    'max',
    isEnabled
  )

  const { data: segments } = useSegmentsList()
  const includedSegments = segments
    ?.filter((segment) => includeSegmentIds.includes(segment.id))
    .map((segment) => segment.name)
  const excludedSegments = segments
    ?.filter((segment) => excludeSegmentIds.includes(segment.id))
    .map((segment) => segment.name)

  const siteId = useSiteSelector(selectSiteId)

  if (!siteId) {
    return {
      isLoading: true,
      numberOfSubscribers: 0,
      numberOfSMSs: 0,
      numberOfMMSs: 0,
      totalNumber: 0,
      estimatedCost: 0,
      includedSegments: undefined,
      excludedSegments: undefined
    }
  }

  const numberOfMMSs = estimatedCost?.segmentsToSend?.mms ?? 0
  const numberOfSMSs = estimatedCost?.segmentsToSend?.sms ?? 0

  return {
    isLoading: isEstimatedCostLoading,
    numberOfSubscribers: estimatedCost?.subscribersToSendTo,
    numberOfMMSs,
    numberOfSMSs,
    totalNumber: numberOfMMSs + numberOfSMSs,
    estimatedCost: estimatedCost?.total,
    excludedSegments,
    includedSegments
  }
}
