import cn from 'classnames'
import React, { useState } from 'react'
import { Toast } from '../../../../../components/ui-kit/toast/toast.component'

interface ImageProps {
  input: any
  loadFinished: VoidFunction
  isGallery: boolean
}

export function Image(props: ImageProps) {
  const [isLoading, setIsLoading] = useState(true)

  function handleLoad() {
    props.loadFinished()
    setIsLoading(false)
  }

  function handleError() {
    props.loadFinished()
    setIsLoading(false)
    Toast({
      type: 'error',
      key: 'image-error',
      content: 'Something went wrong.'
    })
  }

  const encodedValue = encodeURI(props.input.value)
  return (
    <div
      className={cn({
        'file-uploader__preview-image--is-loading': isLoading,
        'file-uploader__preview-image-wrapper': true
      })}
    >
      <div
        style={{ backgroundImage: `url("${encodedValue}")` }}
        className={cn({
          'file-uploader__preview-image': true,
          hide: !props.isGallery
        })}
      />
      <img
        src={encodedValue}
        onLoad={handleLoad}
        onError={handleError}
        className={cn({
          hide: props.isGallery
        })}
      />
    </div>
  )
}
