import {
  isDelaySequenceItemUI,
  PortType,
  SequenceItemUI,
  SequenceUI
} from '@ghostmonitor/recartapis'
import { BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import { LOGIC_PORT_USER_INPUT_SKIP } from '../../models/sequence-item/port-names'
import { getDiagramModel } from '../../types/get-diagram-model'

const userInteractionPortTypes = [PortType.BUTTON, PortType.QUICK_REPLY]
const userInteractionPortNames = [LOGIC_PORT_USER_INPUT_SKIP]

export function getLongestConsecutiveDelay(
  sequence: SequenceUI,
  sequenceItems: SequenceItemUI[],
  node: BaseSequenceItemNodeModel,
  originNode: BaseSequenceItemNodeModel
): number {
  const inputPort = node.getInputPort()
  const links = sequence.serializedDiagram.links.filter(
    (link) => link.targetPort === inputPort.id || link.sourcePort === inputPort.id
  )
  const sum: number[] = [0]

  links.forEach((link, idx) => {
    sum[idx] = 0
    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const previousNode = Object.values(diagramModel.getNodes()).find(
      (diagramNode) =>
        (diagramNode.id === link.source || diagramNode.id === link.target) &&
        diagramNode.id !== node.id &&
        diagramNode.id !== originNode.id
    ) as BaseSequenceItemNodeModel
    if (previousNode && previousNode.sequenceItemId !== sequence.entrySequenceItemId) {
      const previousPort = Object.values(previousNode.getPorts()).find(
        (diagramPort) => diagramPort.id === link.sourcePort || diagramPort.id === link.targetPort
      )
      const previousPortType = previousPort?.type as PortType
      if (
        userInteractionPortTypes.includes(previousPortType) ||
        userInteractionPortNames.includes(previousPort?.name)
      ) {
        return 0
      }
      const previousSequenceItem = sequenceItems.find(
        (item) => item._id === previousNode.sequenceItemId
      )
      if (isDelaySequenceItemUI(previousSequenceItem)) {
        sum[idx] += previousSequenceItem.logic.delay.delayDuration
      }
      sum[idx] += getLongestConsecutiveDelay(sequence, sequenceItems, previousNode, originNode)
    }
  })

  return Math.max(...sum)
}
