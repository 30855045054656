// @ts-nocheck
/*
  global
    SITES_URL,
    RECART_API_URL,
    NODE_ENV,
    GA_ID,
    UPLOAD_API_URL,
    TRACKING_URL,
    FB_PIXEL_ID,
    MESSENGER_APP_ID,
    FACEBOOK_LOGIN_CONFIG_ID,
    STRIPE_PUBLIC_KEY,
    LAUNCHDARKLY_KEY,
    SHOPIFY_AUTH_URL,
    LOGROCKET,
    GA_ID,
    __DEV__,
    __PROD__,
    __TEST__,
    __BASENAME__,
    CALENDLY_URL,
    AI_PROXY,
    APP_VERSION
*/

/**
 * Default values are in charge when run with ts-node, becase in this case Webpack doesn't create magic global variables
 */

export const config = {
  START_URL: '/',
  ERROR_PAGE_URL: '/error',
  NODE_ENV: typeof NODE_ENV !== 'undefined' ? NODE_ENV : 'node',
  SITES_URL: typeof SITES_URL !== 'undefined' ? SITES_URL : '',
  RECART_API_URL: typeof RECART_API_URL !== 'undefined' ? RECART_API_URL : '',
  UPLOAD_API_URL: typeof UPLOAD_API_URL !== 'undefined' ? UPLOAD_API_URL : '',
  TRACKING_URL: typeof TRACKING_URL !== 'undefined' ? TRACKING_URL : 'https://storefront.recart.co',
  FB_PIXEL_ID: typeof FB_PIXEL_ID !== 'undefined' ? FB_PIXEL_ID : '1220957584692433',
  MESSENGER_APP_ID: typeof MESSENGER_APP_ID !== 'undefined' ? MESSENGER_APP_ID : '',
  FACEBOOK_LOGIN_CONFIG_ID:
    typeof FACEBOOK_LOGIN_CONFIG_ID !== 'undefined' ? FACEBOOK_LOGIN_CONFIG_ID : '',
  STRIPE_PUBLIC_KEY:
    typeof STRIPE_PUBLIC_KEY !== 'undefined'
      ? STRIPE_PUBLIC_KEY
      : 'pk_test_F5vtauyibN9euzA2XuWDGxFo',
  LAUNCHDARKLY_KEY:
    typeof LAUNCHDARKLY_KEY !== 'undefined' ? LAUNCHDARKLY_KEY : '59f6f66a387ac00ac875afbd',
  SHOPIFY_AUTH_URL:
    typeof SHOPIFY_AUTH_URL !== 'undefined'
      ? SHOPIFY_AUTH_URL
      : 'https://install.ghostmonitor.info',
  SHOPIFY_THEME_EXTENSION_ID:
    typeof SHOPIFY_THEME_EXTENSION_ID !== 'undefined'
      ? SHOPIFY_THEME_EXTENSION_ID
      : 'bde6eb70-a320-4106-a015-b4760668898a',
  LOGROCKET: typeof LOGROCKET !== 'undefined' ? LOGROCKET : 'xx0xf8/core-client',
  GA_ID: typeof GA_ID !== 'undefined' ? GA_ID : 'UA-41217394-41',
  __DEV__: typeof __DEV__ !== 'undefined' ? __DEV__ : true,
  __PROD__: typeof __PROD__ !== 'undefined' ? __PROD__ : false,
  __TEST__: typeof __TEST__ !== 'undefined' ? __TEST__ : false,
  __BASENAME__: typeof __BASENAME__ !== 'undefined' ? __BASENAME__ : '',
  DATADOG_CLIENT_TOKEN:
    typeof DATADOG_CLIENT_TOKEN !== 'undefined' ? DATADOG_CLIENT_TOKEN : 'invalid_token',
  CALENDLY_URL:
    typeof CALENDLY_URL !== 'undefined'
      ? CALENDLY_URL
      : 'https://calendly.com/recart-sales/recart-demo-call-3',
  AI_PROXY: typeof AI_PROXY !== 'undefined' ? AI_PROXY : false,
  APP_VERSION: typeof APP_VERSION !== 'undefined' ? APP_VERSION : '0.0.0'
}
