import { FlowEditorSettingsContextType } from './flow-editor-settings.context.type'

export const initialFlowEditorSettings: FlowEditorSettingsContextType = {
  entryLinkTypeOptions: [],
  entryVariableTypeOptions: [],
  footer: () => null,
  header: () => null,
  isAddAICampaignWizardEnabled: false,
  isCustomMessageHeaderEnabled: false,
  isTriggerCanBeChanged: false,
  linkTypeOptions: [],
  variableTypeOptions: [],
  isScheduledDelayEnabled: false,
  isKeywordReplyCanBeAdded: false
}
