import { useContext } from 'react'
import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query'
import {
  getDefaultTimeFrameFilter,
  TimeFrameFilterContext
} from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { SequenceStatsById } from '../../types/sequence-stat-by-id.type'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
import moment from 'moment/moment'
import { SequenceTag } from '@ghostmonitor/recartapis'
import { queryClient } from '../../components/app/query-client'

interface SequencesStatQueryParams {
  startDate?: string
  endDate?: string
  tags?: SequenceTag | SequenceTag[]
}

interface SequencesStatProps {
  isEnabled: boolean
  tags?: SequenceTag | SequenceTag[]
}

function getQueryKey(queryParams: SequencesStatQueryParams): QueryKey {
  return [QUERY_NAME.sequencesStat, queryParams]
}

function buildQuery(
  dateFrom: moment.Moment | null,
  dateTo: moment.Moment | null,
  defaultQueryParams: Partial<SequencesStatQueryParams> = {}
): SequencesStatQueryParams {
  const queryParams: SequencesStatQueryParams = {
    ...defaultQueryParams
  }

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
  }

  return queryParams
}

function fetchSequencesStats(
  queryParams: SequencesStatQueryParams
): QueryFunction<SequenceStatsById> {
  return async () =>
    request.get<SequenceStatsById>(`statistics/sequences`, {
      params: queryParams
    })
}

function useSequencesStats(
  { isEnabled, tags }: SequencesStatProps = { isEnabled: true }
): UseResource<SequenceStatsById> {
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)
  const queryParams = buildQuery(dateFrom, dateTo, { tags })
  const queryName = getQueryKey(queryParams)

  const { isLoading, data } = useQuery<SequenceStatsById>(
    queryName,
    fetchSequencesStats(queryParams),
    {
      enabled: isEnabled && dateFrom !== undefined && dateTo !== undefined
    }
  )

  return {
    data,
    isLoading
  }
}

function prefetchSequencesStats(tags?: SequenceTag | SequenceTag[]): void {
  const { dateFrom, dateTo } = getDefaultTimeFrameFilter()
  const queryParams = buildQuery(dateFrom, dateTo, { tags })
  const queryName = getQueryKey(queryParams)

  queryClient.prefetchQuery(queryName, fetchSequencesStats(queryParams))
}

export function useSequencesStat(
  props: SequencesStatProps = { isEnabled: true }
): UseResource<SequenceStatsById> & { prefetch: (tags: SequenceTag | SequenceTag[]) => void } {
  const { isLoading, data } = useSequencesStats(props)

  function prefetch(tags: SequenceTag | SequenceTag[]): void {
    prefetchSequencesStats(tags)
  }

  return {
    data,
    isLoading,
    prefetch
  }
}
