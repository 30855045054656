export function generateButtonPortName(
  sequenceItemId: string,
  messageIndex: number,
  buttonIndex: number,
  messageItemIndex?: number
): string {
  return messageItemIndex !== undefined
    ? `${sequenceItemId}-${messageIndex}-${messageItemIndex}-${buttonIndex}`
    : `${sequenceItemId}-${messageIndex}-${buttonIndex}`
}
