import {
  Blast,
  GraphemeSplitter,
  SMSEncoding,
  SegmentedSMSMessage,
  variablePattern,
  URLGenerationSettings
} from '@ghostmonitor/recartapis'
import {
  getVariableFromRawVariableSequenceEditor,
  replaceNonBreakingSpaces
} from '../../../utils/inline-editor/inline-editor-utils'
import { getSubdomainInUseSequenceEditor } from './get-subdomain-in-use'

const SHORTENED_LINK_LENGTH_RECARTSMS = 32

export const customShortenedLinkLengths: { [key: string]: number } = {
  '5eb9cf003d192d07a15b9600': 39 // VampireFreaks
}

const urlVariables = [
  'url',
  'site.domain',
  'fulfillment.tracking_url',
  'cart.url',
  'order.status_url',
  'product.url',
  'wonderment.tracking_url',
  'wonderment.wonderment_tracking_url',
  'loopwork.quick_action_reactivate_subscription',
  'loopwork.quick_action_update_payment_method',
  'loopwork.quick_action_resume_subscription',
  'loopwork.quick_action_add_product',
  'integration.update_payment_method_url'
]

const variablesWithCalculatedLength = [
  ...urlVariables,
  'site.name',
  'site.email',
  'wonderment.carrier_name',
  'wonderment.order_id',
  'wonderment.order_number'
]

interface VariableReplacer {
  variableName: string
  value: string
}

function getValueReplacers(siteName: string, supportEmail: string | undefined): VariableReplacer[] {
  return [
    {
      variableName: 'site.name',
      value: siteName
    }
  ].concat(
    supportEmail
      ? [
          {
            variableName: 'site.email',
            value: supportEmail
          }
        ]
      : []
  )
}

// These variables originate from wonderment events. Some have a fixed length, such as the order ID,
// while others have a variable length. The variables with a fixed length are included in the
// variablesWithCalculatedLength group, and will not be reported as having an indeterminate length.
const defaultVariableLengths: Record<string, number> = {
  'wonderment.carrier_name': 10, // fixed
  'wonderment.product_names': 24, // varied
  'wonderment.order_id': 13, // fixed
  'wonderment.order_number': 10, // fixed
  'wonderment.tracking_code': 22, // varied
  'loopwork.billing_attempt_error_message': 30, // varied
  'loopwork.billing_retry_delay_days': 2, // varied
  'loopwork.first_name': 10, // varied
  'loopwork.last_name': 12, // varied
  'loopwork.product_names': 24, // varied
  'loopwork.next_billing_date_display': 10, // fixed
  'loopwork.subscription_shopify_id': 13 // fixed
}

const graphemeSplitter = new GraphemeSplitter()

interface SMSStat {
  characterCount: number
  segmentCount: number
  hasSpecialValue: boolean
}

export interface SMSStatOptions {
  siteName: string
  supportEmail?: string
  urlGenerationSettings?: URLGenerationSettings
  blast?: Blast
  isReadOnly?: boolean
}

export function getSequenceEditorSMSStat(
  smsText: string,
  { siteName, supportEmail, urlGenerationSettings, blast, isReadOnly }: SMSStatOptions
): SMSStat {
  if (!smsText) {
    return {
      characterCount: 0,
      segmentCount: 0,
      hasSpecialValue: false
    }
  }

  const valueReplacers = getValueReplacers(siteName, supportEmail)
  const pattern = new RegExp(variablePattern, 'g')
  let match: string[]
  let calculatedValue = smsText
  let hasNoncalculableVariableLength = false

  while ((match = pattern.exec(smsText))) {
    const variableRaw = match[0]
    const variable = getVariableFromRawVariableSequenceEditor(variableRaw)
    const subdomainInUse = getSubdomainInUseSequenceEditor(
      isReadOnly ?? false,
      blast,
      urlGenerationSettings
    )
    if (urlVariables.includes(variable.name)) {
      const shortenedLinkLength =
        subdomainInUse.length > 0
          ? subdomainInUse.length + 1 + SHORTENED_LINK_LENGTH_RECARTSMS
          : SHORTENED_LINK_LENGTH_RECARTSMS
      calculatedValue = calculatedValue.replace(variableRaw, '-'.repeat(shortenedLinkLength))
    }
    for (const { variableName, value } of valueReplacers) {
      if (variable.name === variableName) {
        calculatedValue = calculatedValue.replace(variableRaw, value)
      }
    }
    for (const [variableName, defaultLength] of Object.entries(defaultVariableLengths)) {
      if (variable.name === variableName) {
        calculatedValue = calculatedValue.replace(variableRaw, '-'.repeat(defaultLength))
      }
    }
    if (!variablesWithCalculatedLength.includes(variable.name)) {
      calculatedValue = calculatedValue.replace(
        variableRaw,
        variable.params.default ?? variable.name
      )
      hasNoncalculableVariableLength = true
    }
  }

  calculatedValue = replaceNonBreakingSpaces(calculatedValue)
  const segmentedMessage = new SegmentedSMSMessage(
    calculatedValue,
    SMSEncoding.auto,
    graphemeSplitter
  )

  return {
    characterCount: segmentedMessage.rawLength,
    segmentCount: segmentedMessage.numOfSegments,
    hasSpecialValue:
      segmentedMessage.getEncodingName() === SMSEncoding.ucs2 || hasNoncalculableVariableLength
  }
}
