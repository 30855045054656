import { SMSConversations } from '@ghostmonitor/recartapis'
import { useMemo } from 'react'
import { selectSiteId } from '../../store/slices/site/site.selectors'
import { getSMSInboxDraftKey } from '../../utils/sms-inbox-draft-key'
import { UseResource } from '../types/use-resource.type'
import { useSiteSelector } from '../use-site-selector'
import { useSMSConversations } from './use-sms-conversations'

export function useSMSConversationsWithDraft(): UseResource<SMSConversations.SmsConversation[]> {
  const { data: smsConversations, isLoading, error } = useSMSConversations()
  const siteId = useSiteSelector(selectSiteId)

  const smsConversationsWithDraft = useMemo(() => {
    return (
      smsConversations?.map((smsConversation) => {
        const draftText = localStorage.getItem(
          getSMSInboxDraftKey(siteId, smsConversation.phoneNumber)
        )
        return draftText !== null && draftText !== ''
          ? {
              ...smsConversation,
              lastMessageText: draftText
            }
          : smsConversation
      }) ?? []
    )
  }, [smsConversations, siteId])

  return {
    isLoading,
    data: smsConversationsWithDraft,
    error
  }
}
