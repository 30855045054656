import { isSMSMessageUI } from '@ghostmonitor/recartapis'
import { AnyAction, isAnyOf, Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../../store/selectors'
import {
  messageValidationFailed,
  messageValidationSucceeded
} from '../../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../../store/slices/flow-editor/flow-editor.reducer'
import {
  SMSMessageValidationArgs,
  validateSMSMessage
} from '../../../../utils/validators/flow/validate-sms-message'

const DEBOUNCE_DELAY = 500

export interface MessageValidationArgs extends SMSMessageValidationArgs {
  flowItemId: string
  messageIndex: number
}

function getValidateSMSMessage(dispatch: AppDispatch) {
  return debounce(
    (args: MessageValidationArgs) => {
      const { flowItemId, messageIndex, ...smsValidationArgs } = args
      const error = validateSMSMessage(smsValidationArgs)
      if (error) {
        dispatch(
          messageValidationFailed({
            flowItemId,
            messageIndex,
            errors: [error]
          })
        )
      } else {
        dispatch(
          messageValidationSucceeded({
            flowItemId,
            messageIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export const getValidateMessageChangedAction = (store: Store<DashboardState>) => {
  const dispatch: AppDispatch = store.dispatch
  const validateMessage = getValidateSMSMessage(dispatch)

  return (store: Store<DashboardState>, action: AnyAction) => {
    const state = store.getState()

    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      validateMessage.cancel()
    }

    if (
      isAnyOf(
        flowEditorActions.messageChanged,
        flowEditorActions.rteInsertEmoji,
        flowEditorActions.rteInsertLink,
        flowEditorActions.rteInsertVariable,
        flowEditorActions.rteInsertDiscountCode,
        flowEditorActions.rteEditLinkVariable,
        flowEditorActions.rteRemoveVariable,
        flowEditorActions.rteRemoveLinkVariable,
        flowEditorActions.rteRemoveDiscountCode,
        flowEditorActions.rteEditVariable
      )(action)
    ) {
      const { flowItemId, messageIndex } = action.payload
      const message = flowEditorSelectors.selectMessage(flowItemId, messageIndex)(state)
      const flow = flowEditorSelectors.selectEditorFlow(state)
      const entryItemId = flow?.entrySequenceItemId
      const isEntryItem = entryItemId === flowItemId

      if (isSMSMessageUI(message)) {
        const variableTypeOptions = flowEditorSelectors.selectVariableTypeOptions(state)
        const entryVariableTypeOptions =
          flowEditorSelectors.selectEntryVariableTypeOptions(state) ?? []
        const linkTypeOptions = flowEditorSelectors.selectLinkTypeOptions(state)
        const entryLinkTypeOptions = flowEditorSelectors.selectEntryLinkTypeOptions(state) ?? []

        validateMessage({
          entryLinkTypeOptions,
          entryVariableTypeOptions,
          flowItemId,
          isEntryItem,
          linkTypeOptions,
          message,
          messageIndex,
          variableTypeOptions
        })
      }
    }
  }
}
