// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VmhToVboSyNWCg3wwDno{margin-bottom:20px}.YxNGM85Xw8l0LkE5sUww{border:1px solid #c7c7c7;border-top-left-radius:22px;border-top-right-radius:22px;overflow:hidden}.YxNGM85Xw8l0LkE5sUww img{width:100%}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message-templates/legal-disclaimer-template/legal-disclaimer-template.component.scss"],"names":[],"mappings":"AACE,sBACE,kBAAA,CAGF,sBAGE,wBAAA,CAFA,2BAAA,CACA,4BAAA,CAEA,eAAA,CAEA,0BACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VmhToVboSyNWCg3wwDno`,
	"image": `YxNGM85Xw8l0LkE5sUww`
};
export default ___CSS_LOADER_EXPORT___;
