import {
  FlowItemUI,
  MessageUI,
  ReplyKeyword,
  getTextFromMessage,
  hasTextOnMessage
} from '@ghostmonitor/recartapis'
import uniqBy from 'lodash/uniqBy'
import { isSMSMessageFlowItemUI } from '../../../../types/guards/flow-item-ui.guards'
import { getKeywordRepliesFromText } from '../../../../utils/rich-text-editor/get-keyword-reply-variable'

export function updateKeywordRepliesOnFlowItem(flowItem: FlowItemUI) {
  if (!isSMSMessageFlowItemUI(flowItem)) {
    return
  }

  const keywordReplies: ReplyKeyword[] = []

  flowItem.item.messages.forEach((message: MessageUI) => {
    if (!hasTextOnMessage(message)) {
      return
    }

    const text = getTextFromMessage(message)
    if (!text) {
      return
    }

    keywordReplies.push(...getKeywordRepliesFromText(text))
  })

  const uniqueKeywordReplies = uniqBy(keywordReplies, 'keyword')

  // todo extend this with fallback in the next epic
  if (isSMSMessageFlowItemUI(flowItem)) {
    flowItem.item.replyKeywordSettings = {
      keywords: uniqueKeywordReplies,
      fallback: {
        trigger: '',
        timeout: 0
      }
    }
  }
}
