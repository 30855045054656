import {
  ButtonUI,
  MessageChange as MessagePayloadChange,
  MessengerMessageSequenceItemUI,
  MessengerMessageUI,
  SiteEngineSlug
} from '@ghostmonitor/recartapis'
import { Skeleton } from 'antd'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import React, { useCallback, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { createStructuredSelector } from 'reselect'
import { hooks } from '../../../../hooks/hooks'
import {
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../../../../store/selectors'
import {
  clearSelection,
  createButton,
  createMessageItem,
  focusButton,
  focusMessage,
  removeButton,
  removeMessage,
  removeMessageItem,
  updateButton,
  updateMessage,
  updateMessageItem
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import {
  MessageMeta,
  messageInitialMeta
} from '../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  selectSiteEngineSlug,
  selectSiteId,
  selectSiteUrl
} from '../../../../store/slices/site/site.selectors'
import { MessageSequenceItemNodeModel } from '../../models/sequence-item/message/message-sequence-item-model'
import { SMSMessageSequenceItemNodeModel } from '../../models/sequence-item/sms-message/sms-message-sequence-item-model'
import { MessageComponent } from '../message/message.component'
import styles from './sequence-item-messages.component.scss'

const SortableMessage = SortableElement(MessageComponent)

const SortableMessagesList = SortableContainer(
  ({
    items,
    sequenceItemMeta,
    onMessageChange,
    onMessageFocus,
    onMessageBlur,

    onButtonChange,
    onButtonFocus,
    onButtonBlur,

    onRemoveMessage,
    onAddMessageItem,
    onRemoveMessageItem,
    onAddButton,
    onRemoveButton,
    clearSelection,
    sequenceItemId,
    sequenceItemNodeModel,

    linking,
    linkingMeta,

    isFulfillmentEntry,
    isReviewEntry,
    isAbandonmentSequence,
    hasVariable,
    siteId
  }) => {
    const messages: MessengerMessageUI[] = items

    return (
      <div className={styles.messages}>
        {messages.map((message, messageIndex: number) => {
          const messageMeta: MessageMeta = get(sequenceItemMeta, `messages[${messageIndex}]`)
            ? sequenceItemMeta.messages[messageIndex]
            : cloneDeep(messageInitialMeta)

          return (
            <SortableMessage
              sequenceItemId={sequenceItemId}
              index={messageIndex}
              key={`message-${messageIndex}`}
              message={message}
              messageMeta={messageMeta}
              sequenceItemMeta={sequenceItemMeta}
              onMessageChange={(...args) => onMessageChange(messageIndex, ...args)}
              onMessageFocus={(...args) => onMessageFocus(messageIndex, ...args)}
              onMessageBlur={() => onMessageBlur(messageIndex)}
              onButtonChange={(...args) => onButtonChange(messageIndex, ...args)}
              onButtonFocus={(...args) => onButtonFocus(messageIndex, ...args)}
              onButtonBlur={onButtonBlur}
              onRemove={() => onRemoveMessage(messageIndex)}
              onAddMessageItem={(...args) => onAddMessageItem(messageIndex, ...args)}
              onRemoveMessageItem={(...args) => onRemoveMessageItem(messageIndex, ...args)}
              onAddButton={(...args) => onAddButton(messageIndex, ...args)}
              onRemoveButton={(...args) => onRemoveButton(messageIndex, ...args)}
              clearSelection={clearSelection}
              messageIndex={messageIndex}
              sequenceItemNodeModel={sequenceItemNodeModel}
              linking={linking}
              linkingMeta={linkingMeta}
              isFulfillmentEntry={isFulfillmentEntry}
              isReviewEntry={isReviewEntry}
              isAbandonmentSequence={isAbandonmentSequence}
              hasVariable={hasVariable}
              siteId={siteId}
            />
          )
        })}
      </div>
    )
  }
)

export interface SequenceItemMessagesProps {
  // Props from sequence-item-node-widget
  sequenceItemId: string
  // eslint-disable-next-line react/no-unused-prop-types
  onSortStart: () => void
  onSortEnd: (oldIndex: number, newIndex: number) => void
  sequenceItemNode: MessageSequenceItemNodeModel | SMSMessageSequenceItemNodeModel
  isFulfillmentEntry?: boolean
  isReviewEntry?: boolean
  isAbandonmentSequence?: boolean
  hasVariable?: boolean
}

export function SequenceItemMessages(props: SequenceItemMessagesProps) {
  const [sorting, setSorting] = useState(false)

  const dispatch = useDispatch()
  const stateSelector = useCallback(
    createStructuredSelector({
      sequenceItem: selectSequenceItem<MessengerMessageSequenceItemUI>(props.sequenceItemId),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemId),
      linking: selectLinking,
      linkingMeta: selectLinkingMeta
    }),
    [props.sequenceItemId]
  )
  const state = useSelector(stateSelector)
  const siteEngineSlug = hooks.useSiteSelector(selectSiteEngineSlug)
  const siteUrl = hooks.useSiteSelector(selectSiteUrl)
  const siteId = hooks.useSiteSelector(selectSiteId)
  const isShopify = siteEngineSlug === SiteEngineSlug.SHOPIFY

  if (
    (props.sequenceItemId && !state.sequenceItem) ||
    siteUrl === undefined ||
    siteEngineSlug === undefined
  ) {
    return (
      <div className={styles.container}>
        <Skeleton active title={false} paragraph={{ rows: 3 }} />
      </div>
    )
  }
  if (state.sequenceItem?.messages?.length === 0) {
    return (
      <div className={styles.container}>
        <div
          className={cn(styles['add-message'], {
            [styles.error]: state.sequenceItemMeta.error !== null
          })}
        >
          Drop items here
          <div />
        </div>
      </div>
    )
  }

  function handleSortEnd(oldIndex: number, newIndex: number) {
    setSorting(false)
    if (props.onSortEnd) {
      props.onSortEnd(oldIndex, newIndex)
    }
  }

  function handleMessageChange(
    messageIndex: number,
    messagePayloadChange: MessagePayloadChange,
    messageItemIndex?: number
  ) {
    if (sorting) {
      return false
    }
    if (messageItemIndex !== undefined) {
      dispatch(
        updateMessageItem(
          props.sequenceItemId,
          messageIndex,
          messageItemIndex,
          messagePayloadChange
        )
      )
    } else {
      dispatch(updateMessage(props.sequenceItemId, messageIndex, messagePayloadChange))
    }
  }

  function handleButtonChange(
    messageIndex: number,
    buttonIndex: number,
    buttonChange: Partial<ButtonUI>,
    messageItemIndex?: number
  ) {
    dispatch(
      updateButton(
        props.sequenceItemId,
        messageIndex,
        buttonIndex,
        buttonChange,
        messageItemIndex,
        isShopify
      )
    )
    if (props.isAbandonmentSequence && messageItemIndex !== undefined) {
      // Keep buttons in sync if abandoned cart carousel
      const otherMessageItemIndex = messageItemIndex === 0 ? 1 : 0
      dispatch(
        updateButton(
          props.sequenceItemId,
          messageIndex,
          buttonIndex,
          buttonChange,
          otherMessageItemIndex,
          isShopify
        )
      )
    }
  }

  function handleMessageAddButton(
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) {
    dispatch(
      createButton(props.sequenceItemId, messageIndex, buttonIndex, messageItemIndex, siteUrl)
    )
    if (props.isAbandonmentSequence && messageItemIndex !== undefined) {
      // Keep buttons in sync if abandoned cart carousel
      const otherMessageItemIndex = messageItemIndex === 0 ? 1 : 0
      dispatch(
        createButton(
          props.sequenceItemId,
          messageIndex,
          buttonIndex,
          otherMessageItemIndex,
          siteUrl
        )
      )
    }
  }

  function handleMessageRemoveButton(
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex: number
  ) {
    dispatch(removeButton(props.sequenceItemId, messageIndex, buttonIndex, messageItemIndex))
    if (props.isAbandonmentSequence && messageItemIndex !== undefined) {
      // Keep buttons in sync if abandoned cart carousel
      const otherMessageItemIndex = messageItemIndex === 0 ? 1 : 0
      dispatch(removeButton(props.sequenceItemId, messageIndex, buttonIndex, otherMessageItemIndex))
    }
  }

  function handleRemoveMessage(messageIndex: number) {
    dispatch(removeMessage(props.sequenceItemId, messageIndex))
  }

  function handleAddMessageItem(
    messageIndex: number,
    indexToPlace: number,
    jumpToIndex: (indexToJump: number) => void
  ) {
    dispatch(createMessageItem(props.sequenceItemId, messageIndex, indexToPlace))
    jumpToIndex(indexToPlace)
  }

  function handleRemoveMessageItem(messageIndex: number, messageItemIndex: number) {
    dispatch(removeMessageItem(props.sequenceItemId, messageIndex, messageItemIndex))
  }

  function handleMessageFocus(messageIndex: number, messageItemIndex?: number) {
    batch(() => {
      dispatch(clearSelection())
      dispatch(
        focusMessage({ sequenceItemId: props.sequenceItemId, messageIndex, messageItemIndex })
      )
    })
  }

  function handleMessageBlur() {
    dispatch(clearSelection())
  }

  function handleButtonFocus(messageIndex: number, buttonIndex: number, messageItemIndex?: number) {
    batch(() => {
      dispatch(clearSelection())
      dispatch(
        focusButton({
          sequenceItemId: props.sequenceItemId,
          messageIndex,
          buttonIndex,
          messageItemIndex
        })
      )
    })
  }

  function handleButtonBlur() {
    dispatch(clearSelection())
  }

  function handleClearSelection() {
    dispatch(clearSelection())
  }

  return (
    <div className={styles.container}>
      <SortableMessagesList
        sequenceItemId={props.sequenceItemId}
        items={state.sequenceItem?.messages}
        sequenceItemMeta={state.sequenceItemMeta}
        lockAxis='y'
        distance={10}
        useDragHandle
        onMessageChange={handleMessageChange}
        onRemoveMessage={handleRemoveMessage}
        onAddMessageItem={handleAddMessageItem}
        onRemoveMessageItem={handleRemoveMessageItem}
        onMessageFocus={handleMessageFocus}
        onMessageBlur={handleMessageBlur}
        onButtonChange={handleButtonChange}
        onButtonFocus={handleButtonFocus}
        onButtonBlur={handleButtonBlur}
        onAddButton={handleMessageAddButton}
        onRemoveButton={handleMessageRemoveButton}
        onSortStart={() => setSorting(true)}
        onSortEnd={({ oldIndex, newIndex }) => handleSortEnd(oldIndex, newIndex)}
        clearSelection={handleClearSelection}
        sequenceItemNodeModel={props.sequenceItemNode}
        linking={state.linking}
        linkingMeta={state.linkingMeta}
        isFulfillmentEntry={props.isFulfillmentEntry}
        isReviewEntry={props.isReviewEntry}
        isAbandonmentSequence={props.isAbandonmentSequence}
        hasVariable={props.hasVariable}
        siteId={siteId}
      />
    </div>
  )
}
