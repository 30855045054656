import { ExpressionAPI, FlowUI } from '@ghostmonitor/recartapis'
import { produce } from 'immer'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UseResource } from '../../../hooks/types/use-resource.type'
import { AppDispatch } from '../../../store/create-store'
import { flowEditorSelectors } from '../../../store/selectors'
import { isConditionalSplitV2FlowItemUI } from '../../../types/guards/flow-item-ui.guards'
import { convertExpressionAPIToUI } from '../../../types/segment/converters/api-to-ui/segment'
import { createScope } from '../../../utils/logger/logger'
import { request } from '../../../utils/request'

const logger = createScope('flow-editor')

export function useFlowTemplate(
  templateSlug?: string
): UseResource<FlowUI> & { isLoading: boolean; isError: boolean } {
  const [flow, setFlow] = useState<FlowUI | undefined>()
  const [isError, setIsError] = useState(false)
  const siteId = useSelector(flowEditorSelectors.selectEditorSiteId)

  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    async function loadTemplate() {
      try {
        const flowFromTemplate = await request.get<FlowUI>(`flow-templates/${templateSlug}/flow`)
        if (!siteId) {
          throw new Error('There is no siteId in Flow Editor state')
        }
        const sequenceItemsWithConvertedExpressions = flowFromTemplate.sequenceItems.map(
          (flowItem) => {
            return produce(flowItem, (draft) => {
              draft.site = siteId
              if (isConditionalSplitV2FlowItemUI(draft)) {
                draft.item.logic.conditionalSplitV2.cases.forEach((split) => {
                  split.expression = convertExpressionAPIToUI(split.expression as ExpressionAPI)
                })
              }
            })
          }
        )
        flowFromTemplate.siteId = siteId

        setFlow({
          ...flowFromTemplate,
          sequenceItems: sequenceItemsWithConvertedExpressions
        })
      } catch (err: any) {
        logger.error(new Error('CannotLoadFlowTemplate'), { err })
        setIsError(true)
      }
    }

    if (templateSlug) {
      loadTemplate()
    }
  }, [templateSlug, dispatch, siteId])

  return {
    data: flow,
    // Once it's loaded, it will be redirected to the flow id
    isLoading: templateSlug !== undefined,
    isError
  }
}
