import { useQuery } from '@tanstack/react-query'
import type { TemplatesPopularity } from '../../types/template/templates-popularity.type'
import { request } from '../../utils/request'
import type { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useTemplatesPopularity(): UseResource<TemplatesPopularity> {
  const queryName = [QUERY_NAME.templatesPopularity]

  const { isLoading, data } = useQuery(queryName, async () => {
    return request.get<TemplatesPopularity>('/templates/popularity')
  })

  return {
    data,
    isLoading
  }
}
