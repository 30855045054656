import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { createScope } from '../../../utils/logger/logger'
import { mergeLaunchDarklyFlags } from './app.actions'
import { initLaunchDarklyClient } from './app.thunks'

const { error } = createScope('dashboard')

export interface AppState {
  sidebarOpen: boolean
  isAdblockEnabled: boolean
  launchDarklyFlags: { [flagName: string]: any } | null
}

export const appInitialState: AppState = {
  sidebarOpen: false,
  isAdblockEnabled: false,
  launchDarklyFlags: null
}

const launchDarklyFallbackValues = {
  'clear-local-cache': true,
  'duplicate-sequence-internal': false,
  'force-reload-sequence-editor': false,
  'stripe-billing-enabled': false,
  'wheel-zoom': false
}

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    toggleSidebar(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload
    },
    adBlockDetected(state) {
      state.isAdblockEnabled = true
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initLaunchDarklyClient.fulfilled, (state, action) => {
        state.launchDarklyFlags = action.payload
      })
      .addCase(initLaunchDarklyClient.rejected, (state, action) => {
        error(new Error('CannotLoadLaunchDarklyFlags'), { error: action.error })
        state.launchDarklyFlags = launchDarklyFallbackValues
      })
      .addCase(mergeLaunchDarklyFlags, (state, action) => {
        merge(state.launchDarklyFlags, action.payload)
      })
  }
})

export const { toggleSidebar, adBlockDetected } = appSlice.actions

export const { reducer: appReducer } = appSlice
