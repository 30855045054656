// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mj97puYuqlJEawYpjj4s{color:var(--recart-primary-blue-1)!important}.kGxDHIJgJQXmPjsoD32B{color:var(--recart-red-1)!important}.h7NKsKqIsGbykrMRGUWx{color:var(--recart-yellow-1)!important}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/modals/confirm-modal/confirm-modal.component.scss"],"names":[],"mappings":"AACE,sBACE,4CAAA,CAGF,sBACE,mCAAA,CAGF,sBACE,sCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": `Mj97puYuqlJEawYpjj4s`,
	"danger": `kGxDHIJgJQXmPjsoD32B`,
	"warning": `h7NKsKqIsGbykrMRGUWx`
};
export default ___CSS_LOADER_EXPORT___;
