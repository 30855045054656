import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../redux-helpers'

// Explicitly list of updateable keys, because updating
// most of the properties needs special attention
export interface PatchSequencePayload {
  name?: string
  isEnabled?: boolean
}
export const patchSequence = createAction(
  'sequence/PATCH_SEQUENCE',
  withPayloadType<
    PatchSequencePayload,
    {
      sequenceId: string
    }
  >()
)
