// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YfeGbFXZ40G5IOE1Jdq1{color:#476dff;cursor:pointer}.jvhEU9yJXpkgIv8DnQWc{color:#ff7a45;cursor:pointer}.AqPFNJAKzA9s06hxIkBy{color:#000}.mjhNoJN_AZmUm8CySjl7{background-color:#ff7a45;border-radius:50%;color:#fff;display:inline-block;font-size:10px;height:12px;line-height:12px;margin-left:3px;text-align:center;vertical-align:middle;width:12px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-variable-input/sequence-variable.style.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,aCDY,CDEZ,cAAA,CAGF,sBACE,aCiCO,CDhCP,cAAA,CAGF,sBACE,UAAA,CAGF,sBAKE,wBCoBO,CDrBP,iBAAA,CAEA,UAAA,CALA,oBAAA,CASA,cAAA,CAPA,WAAA,CAQA,gBAAA,CAJA,eAAA,CACA,iBAAA,CACA,qBAAA,CAPA,UASA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sequenceVariable": `YfeGbFXZ40G5IOE1Jdq1`,
	"customSequenceVariable": `jvhEU9yJXpkgIv8DnQWc`,
	"ignoredSequenceVariable": `AqPFNJAKzA9s06hxIkBy`,
	"questionMark": `mjhNoJN_AZmUm8CySjl7`
};
export default ___CSS_LOADER_EXPORT___;
