import {
  Channel,
  Device,
  GeneralStyle,
  NetworkOptin,
  OptinToolStep,
  PopupOneTimePasswordSettings,
  PopupOptionalInputField,
  PopupSettings,
  PopupSMSSettings,
  PopupSuccessSettings,
  PopupTeaserSettings
} from '@ghostmonitor/recartapis'
import { MessagingType } from '@ghostmonitor/recartapis/cjs/typescript-types/messaging-type.type'
import intersection from 'lodash/intersection'
import { GeotargetingCountry } from '../../../../hooks/use-geotargeting-countries'
import { getAudienceNetworkLegalText } from '../../../../routes/optin-tools/utils/network-optin'
import {
  oneCountrySMSDescriptionText,
  oneWaySMSDescriptionText,
  twoWaySMSDescriptionText,
  twoWaySMSEmailDescriptionText
} from './legal-texts'

const defaultGeneralStyles: GeneralStyle[] = [
  {
    name: 'Logo',
    slug: 'logo',
    type: 'image',
    source: 'https://static.ghostmonitor.com/optin-tools/popup/logo-placeholder.svg',
    width: '160px',
    height: 'auto',
    shadow: null
  },
  {
    name: 'Heading',
    slug: 'heading',
    type: 'text',
    font: {
      type: 'google',
      family: 'Poppins',
      variant: '700',
      fallback: 'Arial, sans-serif'
    },
    color: 'rgba(51,51,51,1)',
    shadow: null,
    textDecoration: 'none'
  },
  {
    name: 'Body text',
    slug: 'body-text',
    type: 'text',
    font: {
      type: 'google',
      family: 'Poppins',
      variant: '400',
      fallback: 'Arial, sans-serif'
    },
    color: 'rgba(51,51,51,1)',
    shadow: null,
    textDecoration: 'none'
  },
  {
    name: 'Legal text',
    slug: 'legal-text',
    type: 'text',
    font: {
      type: 'google',
      family: 'Poppins',
      variant: '400',
      fallback: 'Arial, sans-serif'
    },
    color: 'rgba(51,51,51,0.5)',
    shadow: null,
    textDecoration: 'none'
  },
  {
    name: 'Primary button',
    slug: 'primary-button',
    type: 'button',
    font: {
      type: 'google',
      family: 'Poppins',
      variant: '700',
      size: '22px',
      fallback: 'Arial, sans-serif'
    },
    shape: 'pill',
    textColor: '#FFFFFF',
    backgroundColor: 'rgba(51,51,51,1)',
    borderColor: 'rgba(255, 255, 255, 0)',
    borderWidth: '1px',
    size: '20px',
    shadow: {
      offsetX: '0px',
      offsetY: '5px',
      blur: '15px',
      color: 'rgba(0,0,0,0.2)'
    },
    textDecoration: 'none'
  },
  {
    name: 'Secondary button',
    slug: 'secondary-button',
    type: 'button',
    font: {
      type: 'google',
      family: 'Poppins',
      variant: '400',
      size: '18px',
      fallback: 'Arial, sans-serif'
    },
    shape: 'pill',
    textColor: 'rgba(51,51,51,1)',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    borderColor: 'rgba(51,51,51,0.21)',
    borderWidth: '0px',
    size: '13px',
    shadow: null,
    textDecoration: 'underline'
  },
  {
    name: 'Input field',
    slug: 'input-field',
    type: 'input',
    shape: 'pill',
    defaultState: {
      font: {
        type: 'google',
        family: 'Poppins',
        variant: '400',
        size: '16px',
        fallback: 'Arial, sans-serif'
      },
      backgroundColor: '#FFFFFF',
      borderColor: 'rgba(51,51,51,0.4)',
      placeholderColor: 'rgba(51,51,51,0.5)'
    },
    activeState: {
      filledTextColor: '#333333',
      backgroundColor: '#FFFFFF',
      borderColor: '#0375FF'
    },
    errorState: {
      textColor: '#FFFFFF',
      textBackgroundColor: '#FF6565',
      filledTextColor: '#FF6565',
      backgroundColor: '#FFF8F8',
      borderColor: '#FF6565'
    },
    shadow: null
  }
]

export function getBottomHeadingText(steps: OptinToolStep[]): string {
  const hasMessenger = steps.includes('messenger')
  const hasEmail = steps.includes('email')
  const hasSms = steps.includes('sms')
  const hasSmsWithEmail = steps.includes('smsWithEmail')

  if (hasSmsWithEmail) {
    return 'when you sign up to email and texts'
  }

  if (hasMessenger) {
    return hasEmail ? 'when you sign up to email and Messenger' : 'when you sign up to Messenger'
  }

  if (hasSms) {
    return hasEmail ? 'when you sign up to email and texts' : 'when you sign up to texts'
  }

  return 'when you sign up to email'
}

interface getPopupSettingsTemplateParams {
  steps: OptinToolStep[]
  devices: Device[]
  isNetworkOptinEnabled: boolean
  messagingType?: 'one-way' | 'two-way'
  smsCountries?: GeotargetingCountry[]
  siteId?: string
}

export function getPopupSettingsTemplate({
  steps,
  devices,
  isNetworkOptinEnabled,
  messagingType,
  smsCountries,
  siteId
}: getPopupSettingsTemplateParams): PopupSettings {
  const isDesktop = devices.includes('desktop')

  let template: PopupSettings = {
    generalStyles: defaultGeneralStyles,
    size: 'fullscreen',
    borderRadius: '16px',
    mobilePosition: 'middle',
    background: {
      image: {
        type: 'image',
        source: isDesktop
          ? 'https://static.ghostmonitor.com/optin-tools/popup/desktop-default-bg.png'
          : 'https://static.ghostmonitor.com/optin-tools/popup/mobile-default-bg.png',
        shadow: null
      },
      color: '#FFFFFF',
      overlayColor: 'rgba(0, 0, 0, 0.4)'
    },
    additionalImage: null,
    shadow: null,
    closeIcon: {
      iconColor: 'rgba(51, 51, 51, 0.4)',
      backgroundColor: 'rgba(51, 51, 51, 0)',
      delayInSeconds: 2
    },
    sequenceId: null,
    minimized: {
      isEnabled: true,
      behavior: 'popup-to-minimized',
      position: 'bottom-left',
      button: {
        type: 'minimized',
        text: 'GET 15% OFF',
        ...(steps.includes('oneTimePassword') && { oneTimePasswordStepText: 'CONFIRM NUMBER' }),
        textColor: '#FFFFFF',
        font: {
          type: 'google',
          family: 'Poppins',
          variant: '700',
          size: '15px',
          fallback: 'Arial, sans-serif'
        },
        backgroundColor: 'rgba(51,51,51,1)',
        shadow: {
          offsetX: '0px',
          offsetY: '4px',
          blur: '10px',
          color: 'rgba(0,0,0,0.2)'
        },
        textDecoration: 'none'
      }
    },
    customCSS: '',
    displayRules: {
      userAction: {
        type: 'delay',
        value: 0
      }
    }
  }

  if (steps.includes('teaser')) {
    template.teaser = getPopupTeaserSettingsTemplate(isDesktop)
  }

  if (steps.includes('email')) {
    template.email = {
      logo: {
        type: 'image',
        generalStyleSlug: 'logo'
      },
      headingTop: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: 'Unlock',
        font: {
          size: isDesktop ? '32px' : '24px'
        }
      },
      headingMiddle: {
        type: 'text',
        text: '15% OFF',
        generalStyleSlug: 'heading',
        font: {
          size: isDesktop ? '80px' : '64px'
        }
      },
      headingBottom: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: getBottomHeadingText(steps),
        font: {
          size: isDesktop ? '18px' : '15px'
        }
      },
      input: {
        type: 'input',
        generalStyleSlug: 'input-field',
        defaultState: {
          placeholderText: 'Enter your email'
        },
        errorState: {
          text: 'Please enter a valid email'
        }
      },
      success: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: '',
        font: {
          size: '14px'
        }
      },
      button: {
        type: 'button',
        generalStyleSlug: 'primary-button',
        text: 'CONTINUE'
      },
      description: {
        type: 'textarea',
        generalStyleSlug: 'legal-text',
        text: 'By submitting your email, you agree to receiving promotional content. You can unsubscribe at any time.',
        font: {
          size: '10px'
        }
      }
    }

    if (!steps.includes('messenger')) {
      template.email.form = {
        items: [],
        validationRules: {
          required: {
            message: 'Please fill in this input field'
          }
        }
      }
    }

    if (intersection(steps, ['sms', 'smsWithEmail', 'messenger']).length === 0) {
      template.success = getSuccessTemplate(steps, messagingType, isDesktop).success
    }
  }

  if (steps.includes('messenger')) {
    template.messenger = {
      logo: {
        type: 'image',
        generalStyleSlug: 'logo'
      },
      headingTop: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: 'Unlock',
        font: {
          size: '24px'
        }
      },
      headingMiddle: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: '15% OFF',
        font: {
          size: '64px'
        }
      },
      headingBottom: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: getBottomHeadingText(steps),
        font: {
          size: '15px'
        }
      },
      button: {
        type: 'button',
        generalStyleSlug: 'primary-button',
        text: 'GET 15% OFF'
      },
      description: {
        type: 'textarea',
        generalStyleSlug: 'body-text',
        text: 'Discount will be unlocked in Facebook Messenger app',
        font: {
          size: '10px'
        }
      }
    }

    // Both Messenger and Email
    if (steps.includes('email')) {
      template = getTemplateWithCopiedFields(template, 'messenger')
    }
  }

  if (steps.includes('sms') || steps.includes('smsWithEmail')) {
    template.displayRules = {
      ...template.displayRules,
      location: {
        type: 'include',
        values:
          smsCountries
            ?.filter((country) => country.canSend)
            .map((country) => country.countryCode) ?? []
      }
    }
  }

  const shouldHaveSMSInputField =
    isDesktop || messagingType === 'one-way' || steps.includes('oneTimePassword')

  if (steps.includes('sms')) {
    if (shouldHaveSMSInputField) {
      template.sms = {
        ...getPopupSMSTemplate(steps, isDesktop),
        ...getDesktopPopupSMSInputTemplate()
      }

      template.success = getSuccessTemplate(steps, messagingType, isDesktop).success
    } else {
      template.sms = getPopupSMSTemplate(steps, isDesktop)
    }

    // Both SMS and Email
    if (steps.includes('email')) {
      template = getTemplateWithCopiedFields(template, 'sms')
    }

    if (messagingType === 'one-way') {
      template.sms.description.text = oneWaySMSDescriptionText
    }

    // one country custom legal
    if (siteId === '65e08e30de6082b33362db56') {
      template.sms.description.text = oneCountrySMSDescriptionText
    }
  }

  if (steps.includes('smsWithEmail')) {
    template.smsWithEmail = {
      ...getPopupSMSTemplate(steps, isDesktop),
      email: {
        input: {
          type: 'optional-input',
          generalStyleSlug: 'input-field',
          required: true,
          defaultState: {
            placeholderText: 'Enter your email'
          },
          errorState: {
            text: 'Please enter a valid email'
          }
        } as PopupOptionalInputField
      },
      description: {
        type: 'textarea',
        generalStyleSlug: 'legal-text',
        text:
          messagingType === 'one-way' ? oneWaySMSDescriptionText : twoWaySMSEmailDescriptionText,
        font: {
          size: '8px'
        }
      }
    }

    if (shouldHaveSMSInputField) {
      template.smsWithEmail = {
        ...template.smsWithEmail,
        ...getDesktopPopupSMSInputTemplate()
      }

      template.success = getSuccessTemplate(steps, messagingType, isDesktop).success
    }
  }

  if (steps.includes('oneTimePassword')) {
    template.oneTimePassword = getPopupOneTimePasswordTemplate(isDesktop)
  }

  if (isNetworkOptinEnabled) {
    addDefaultNetworkOptin(template)
  }

  return template
}

export function addDefaultNetworkOptin(template: PopupSettings): void {
  const defaultNetworkOptin: NetworkOptin = {
    isEnabled: true,
    label: {
      text: 'Save my contact for a faster opt-in',
      font: {
        type: 'google',
        family: 'Poppins',
        size: '13px',
        variant: '400',
        fallback: 'Arial, sans-serif'
      },
      color: '#333333',
      shadow: null
    },
    backgroundColor: '#ffffff',
    borderColor: 'rgba(51, 51, 51, 0.4)',
    selectedBackgroundColor: '#333333',
    selectedBorderColor: '#333333',
    selectedColor: '#ffffff',
    shadow: null,
    description: {
      type: 'textarea',
      generalStyleSlug: 'legal-text',
      text: getAudienceNetworkLegalText(),
      font: {
        size: '8px'
      }
    }
  }

  if (template.messenger) {
    return
  }

  if (template.success) {
    template.success.networkOptin = {
      ...defaultNetworkOptin,
      description: {
        ...defaultNetworkOptin.description,
        font: {
          ...defaultNetworkOptin.description.font,
          size: '9px'
        }
      }
    }
    return
  }

  if (template.sms) {
    template.sms.networkOptin = defaultNetworkOptin
    return
  }

  if (template.smsWithEmail) {
    template.smsWithEmail.networkOptin = defaultNetworkOptin
  }
}

export function getPopupOneTimePasswordTemplate(isDesktop: boolean): PopupOneTimePasswordSettings {
  return {
    logo: {
      type: 'image',
      generalStyleSlug: 'logo'
    },
    heading: {
      type: 'text',
      generalStyleSlug: 'heading',
      text: 'Confirm your phone number',
      font: {
        size: isDesktop ? '40px' : '32px'
      }
    },
    paragraph: {
      type: 'textarea',
      generalStyleSlug: 'body-text',
      text: 'We’ve just sent you a one-time-confirmation code. Might take a few seconds to arrive.',
      font: {
        size: isDesktop ? '16px' : '12px'
      }
    },
    input: {
      type: 'input',
      generalStyleSlug: 'input-field',
      defaultState: {
        placeholderText: 'Enter code'
      },
      errorState: {
        text: 'The entered code is incorrect'
      }
    },
    button: {
      type: 'button',
      generalStyleSlug: 'primary-button',
      text: 'SUBMIT'
    },
    resend: {
      description: {
        type: 'textarea',
        generalStyleSlug: 'body-text',
        text: "Haven't got a code?",
        font: {
          size: isDesktop ? '16px' : '12px'
        }
      },
      button: {
        type: 'resend-button',
        text: 'Resend',
        font: {
          type: 'google',
          family: 'Poppins',
          variant: '400',
          size: isDesktop ? '16px' : '12px',
          fallback: 'Arial, sans-serif'
        },
        activeTextColor: 'rgba(51,51,51,1)',
        inactiveTextColor: 'rgba(51,51,51,0.5)',
        textDecoration: 'underline',
        shadow: null
      }
    }
  }
}

export function getPopupTeaserSettingsTemplate(isDesktop: boolean): PopupTeaserSettings {
  return {
    isEnabled: true,
    buttonAlignment: 'vertical',
    logo: {
      type: 'image',
      generalStyleSlug: 'logo'
    },
    headingTop: {
      type: 'text',
      generalStyleSlug: 'heading',
      text: 'Would you like an',
      font: {
        size: isDesktop ? '32px' : '24px'
      }
    },
    headingMiddle: {
      type: 'text',
      generalStyleSlug: 'heading',
      text: 'EXCLUSIVE DEAL?',
      font: {
        size: isDesktop ? '56px' : '48px'
      }
    },
    headingBottom: {
      type: 'text',
      generalStyleSlug: 'body-text',
      text: '',
      font: {
        size: '18px'
      }
    },
    confirmButton: {
      type: 'button',
      generalStyleSlug: 'primary-button',
      text: 'YES, PLEASE'
    },
    declineButton: {
      type: 'button',
      generalStyleSlug: 'secondary-button',
      text: 'NO, THANKS'
    }
  }
}

function getPopupSMSTemplate(steps: OptinToolStep[], isDesktop: boolean): PopupSMSSettings {
  return {
    logo: {
      type: 'image',
      generalStyleSlug: 'logo'
    },
    headingTop: {
      type: 'text',
      generalStyleSlug: 'heading',
      text: 'Unlock',
      font: {
        size: isDesktop ? '32px' : '24px'
      }
    },
    headingMiddle: {
      type: 'text',
      generalStyleSlug: 'heading',
      text: '15% OFF',
      font: {
        size: isDesktop ? '80px' : '64px'
      }
    },
    headingBottom: {
      type: 'text',
      generalStyleSlug: 'body-text',
      text: getBottomHeadingText(steps),
      font: {
        size: isDesktop ? '18px' : '15px'
      }
    },
    form: {
      items: [],
      validationRules: {
        required: {
          message: 'Please fill in this input field'
        }
      }
    },
    button: {
      type: 'button',
      text: 'GET 15% OFF',
      generalStyleSlug: 'primary-button'
    },
    description: {
      type: 'textarea',
      generalStyleSlug: 'legal-text',
      text: twoWaySMSDescriptionText,
      font: {
        size: '8px'
      }
    }
  }
}

function getSuccessTemplate(
  steps: OptinToolStep[],
  messagingType: MessagingType,
  isDesktop: boolean
): { success: PopupSuccessSettings } {
  if (messagingType === 'one-way') {
    return getOneWayPopupSuccessTemplate(isDesktop)
  }

  if (steps.includes('oneTimePassword')) {
    return getOneTimePasswordSuccessTemplate()
  }

  if (
    steps.includes('email') &&
    intersection(steps, ['sms', 'smsWithEmail', 'messenger']).length === 0
  ) {
    const template = getOneWayPopupSuccessTemplate(isDesktop)
    template.success.description.text = 'Check your email for the discount code.'

    return template
  }

  return getTwoWayPopupSuccessTemplate(isDesktop)
}

function getTwoWayPopupSuccessTemplate(isDesktop: boolean): { success: PopupSuccessSettings } {
  return {
    success: {
      logo: {
        type: 'image',
        generalStyleSlug: 'logo'
      },
      heading: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: 'Check your phone!',
        font: {
          size: isDesktop ? '40px' : '32px'
        }
      },
      description: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: 'Send "Yes" to subscribe for the discounts.',
        font: {
          size: isDesktop ? '24px' : '18px'
        }
      },
      button: {
        type: 'button',
        generalStyleSlug: 'primary-button',
        text: 'OK'
      }
    }
  }
}

function getOneWayPopupSuccessTemplate(isDesktop: boolean): { success: PopupSuccessSettings } {
  return {
    success: {
      logo: {
        type: 'image',
        generalStyleSlug: 'logo'
      },
      heading: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: 'Congratulations!',
        font: {
          size: isDesktop ? '40px' : '30px'
        }
      },
      description: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: 'Check your phone for the discount code.',
        font: {
          size: '18px'
        }
      },
      button: {
        type: 'button',
        generalStyleSlug: 'primary-button',
        text: 'GO SHOPPING'
      }
    }
  }
}

function getOneTimePasswordSuccessTemplate(): {
  success: PopupSuccessSettings
} {
  return {
    success: {
      logo: {
        type: 'image',
        generalStyleSlug: 'logo'
      },
      heading: {
        type: 'text',
        generalStyleSlug: 'heading',
        text: 'Congratulations!',
        font: {
          size: '30px'
        }
      },
      description: {
        type: 'text',
        generalStyleSlug: 'body-text',
        text: 'Check your phone for the discount code.',
        font: {
          size: '18px'
        }
      },
      button: {
        type: 'button',
        generalStyleSlug: 'primary-button',
        text: 'GO SHOPPING'
      }
    }
  }
}

function getDesktopPopupSMSInputTemplate(): {
  input: PopupSMSSettings['input']
} {
  return {
    input: {
      type: 'input',
      generalStyleSlug: 'input-field',
      defaultState: {
        placeholderText: 'Enter phone number'
      },
      errorState: {
        text: 'Please enter a valid phone number'
      }
    }
  }
}

function getTemplateWithCopiedFields(template: PopupSettings, channel: Channel): PopupSettings {
  return {
    ...template,
    [channel]: {
      ...template[channel],
      logo: template.email.logo,
      headingTop: template.email.headingTop,
      headingMiddle: template.email.headingMiddle,
      headingBottom: template.email.headingBottom
    },
    email: {
      ...template.email,
      button: {
        ...template.email.button,
        text: 'CONTINUE'
      }
    }
  }
}
