import { HttpResponse, SegmentVariant } from '@ghostmonitor/recartapis'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { MeState } from '../../store/slices/me/me.types'
import { SegmentUI } from '../../types/segment/condition-ui.type'
import { convertSegmentAPIToUI } from '../../types/segment/converters/api-to-ui/segment'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

// todo update it as soon as it is available from recartapis
export type AISegmentResponse = HttpResponse<SegmentVariant[]>

export function useAISegments(): UseResource<SegmentUI[]> {
  const queryName = [QUERY_NAME.AIsegmentsList]

  const { isLoading, data, isError, refetch } = useQuery<AISegmentResponse, AxiosError>(
    queryName,
    async () => {
      return request.get<AISegmentResponse>('ai-text-generation-segments')
    }
  )
  const AISegmentUIData = data?.data?.map((segment) => convertSegmentAPIToUI(segment) as SegmentUI)

  const fakeSegmentSizes = [84712, 31104, 7257, 2189, 570]
  const AISegmentUIWithFakeDemoData = AISegmentUIData?.map((segment, index) => ({
    ...segment,
    size: fakeSegmentSizes[index]
  }))

  const queryClient = useQueryClient()
  const me = queryClient.getQueryData<MeState>([QUERY_NAME.me])
  const isDemoRole = me?.role?.slug === 'demo_user'

  return {
    isLoading,
    data: isDemoRole ? AISegmentUIWithFakeDemoData : AISegmentUIData,
    isError,
    refetch: refetch as any
  }
}
