export type DiscountCodeType = 'static' | 'unique'

interface VariableInfoBase {
  type: 'discount' | 'url'
}

export interface DiscountCodeInfo extends VariableInfoBase {
  type: 'discount'
  discountCodeType: DiscountCodeType
  discount: string
  url?: string
}

export interface UrlInfo extends VariableInfoBase {
  type: 'url'
  url: string
}

export function isDiscountCodeInfo(info: VariableInfoBase): info is DiscountCodeInfo {
  return info.type === 'discount'
}

export function isUrlInfo(info: VariableInfoBase): info is UrlInfo {
  return info.type === 'url'
}

export type VariableInfo = DiscountCodeInfo | UrlInfo
