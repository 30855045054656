import {
  DiagramPoint,
  SubscriberDiagram,
  SubscriberStackedDiagramResponse
} from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { TimeFrameFilterContext } from '../../control-components/make-filterable/contexts/time-frame-filter.context'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export interface StackedDiagrams {
  allChannelSubscribers: DiagramPoint[]
  sms: SubscriberDiagram
  facebook: SubscriberDiagram
}

export function useAggregatedSubscribersChart(resolution: string): UseResource<StackedDiagrams> {
  const { dateFrom, dateTo } = useContext(TimeFrameFilterContext)

  const queryName = [QUERY_NAME.subscribersStackedChart, resolution]

  const queryParams: {
    startDate?: string
    endDate?: string
    targetResolution?: string
  } = {}

  if (dateFrom) {
    queryParams.startDate = dateFrom.toISOString()
    queryName.push(dateFrom.toISOString())
  }

  if (dateTo) {
    queryParams.endDate = dateTo.toISOString()
    queryName.push(dateTo.toISOString())
  }

  if (resolution) {
    queryParams.targetResolution = resolution
  }

  const { isInitialLoading, data } = useQuery<SubscriberStackedDiagramResponse>(
    queryName,
    async () => {
      return request.get<SubscriberStackedDiagramResponse>('subscribers-diagrams/stacked', {
        params: queryParams
      })
    },
    {
      enabled: dateFrom !== undefined && dateTo !== undefined
    }
  )

  return {
    data: data?.data,
    isLoading: isInitialLoading
  }
}
