import { EmbeddedForm } from '@ghostmonitor/recartapis'
import { intersection } from 'lodash'
import { OptinToolMenuItem } from '../../components/editor/left-panel/left-panel.component'
import {
  getErrorAccordionFirstItem,
  getMenuSlugForDevice,
  getMobileSMSWithEmailErrorLabel,
  shouldShowMenuItemForDevice
} from '../../landing-page/editor/menu-items'

export enum EmbeddedFormMenuItemSlug {
  EMBEDDED_FORM = 'embeddedForm',
  SETTINGS = 'settings',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  LAYOUT = 'layout',
  EMAIL = 'email',
  EMAIL_FORM_ERROR = 'emailFormError',
  SMS = 'sms',
  SMS_WITH_EMAIL = 'smsWithEmail',
  SMS_SUCCESS = 'smsSuccess',
  SMS_FORM_ERROR = 'smsFormError',
  SMS_WITH_EMAIL_FORM_ERROR = 'smsWithEmailFormError',
  GEOTARGETING_ERROR = 'geotargetingError'
}

export function getEmbeddedFormMenuItems(embeddedForm: EmbeddedForm): OptinToolMenuItem[] {
  const hasMoreSteps = embeddedForm.steps.length > 1

  const menuItems: OptinToolMenuItem[] = [
    { slug: EmbeddedFormMenuItemSlug.DESKTOP, label: 'Desktop', type: 'divider' },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.LAYOUT),
      device: 'desktop',
      label: 'Layout',
      items: ['additionalImage', 'background', 'margin']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.EMAIL),
      steps: ['email'],
      device: 'desktop',
      label: 'Email opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.EMAIL_FORM_ERROR),
      steps: ['email'],
      device: 'desktop',
      label: 'Form item errors (email)',
      items: [...getErrorAccordionFirstItem(embeddedForm, 'desktop', 'email'), 'emailError']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS),
      steps: ['sms'],
      device: 'desktop',
      label: 'SMS opt-in',
      items: ['logo', 'heading', 'emailSuccess', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL),
      steps: ['smsWithEmail'],
      device: 'desktop',
      label: 'Email and SMS opt-in',
      items: ['logo', 'heading', 'emailInput', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS_FORM_ERROR),
      steps: ['sms'],
      device: 'desktop',
      label: `Form item errors ${hasMoreSteps ? '(SMS)' : ''}`,
      items: [...getErrorAccordionFirstItem(embeddedForm, 'desktop', 'sms')]
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR),
      steps: ['smsWithEmail'],
      device: 'desktop',
      label: 'Form item errors',
      items: [...getErrorAccordionFirstItem(embeddedForm, 'desktop', 'smsWithEmail')]
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.SMS_SUCCESS),
      steps: ['sms', 'smsWithEmail'],
      device: 'desktop',
      label: 'SMS success',
      items: ['logo', 'heading', 'description', 'button']
    },
    {
      slug: getMenuSlugForDevice('desktop', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR),
      device: 'desktop',
      label: 'Geotargeting error',
      items: ['logo', 'heading', 'description', 'button']
    },
    { slug: EmbeddedFormMenuItemSlug.MOBILE, label: 'Mobile', type: 'divider' },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.LAYOUT),
      device: 'mobile',
      label: 'Layout',
      items: ['additionalImage', 'background', 'margin']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.EMAIL),
      steps: ['email'],
      device: 'mobile',
      label: 'Email opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.EMAIL_FORM_ERROR),
      steps: ['email'],
      device: 'mobile',
      label: 'Form item errors (email)',
      items: [...getErrorAccordionFirstItem(embeddedForm, 'mobile', 'email'), 'emailError']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS),
      steps: ['sms'],
      device: 'mobile',
      label: 'SMS opt-in',
      items: ['logo', 'heading', 'emailSuccess', 'smsInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS_FORM_ERROR),
      steps: ['sms'],
      device: 'mobile',
      label: `Form item errors ${hasMoreSteps ? '(SMS)' : ''}`,
      items: [...getErrorAccordionFirstItem(embeddedForm, 'mobile', 'sms')]
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL),
      steps: ['smsWithEmail'],
      device: 'mobile',
      label: 'Email and SMS opt-in',
      items: ['logo', 'heading', 'emailInput', 'button', 'description']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR),
      steps: ['smsWithEmail'],
      device: 'mobile',
      label: getMobileSMSWithEmailErrorLabel(embeddedForm.messagingType!, hasMoreSteps),
      items:
        embeddedForm.messagingType === 'one-way'
          ? [...getErrorAccordionFirstItem(embeddedForm, 'mobile', 'smsWithEmail')]
          : [...getErrorAccordionFirstItem(embeddedForm, 'mobile', 'smsWithEmail'), 'emailError']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.SMS_SUCCESS),
      steps: ['sms', 'smsWithEmail'],
      device: 'mobile',
      label: 'SMS success',
      items: ['logo', 'heading', 'description', 'button']
    },
    {
      slug: getMenuSlugForDevice('mobile', EmbeddedFormMenuItemSlug.GEOTARGETING_ERROR),
      device: 'mobile',
      label: 'Geotargeting error',
      items: ['logo', 'heading', 'description', 'button']
    },
    { slug: EmbeddedFormMenuItemSlug.EMBEDDED_FORM, label: 'Embedded form', type: 'divider' },
    { slug: EmbeddedFormMenuItemSlug.SETTINGS, label: 'Settings' }
  ]

  return menuItems.filter(
    (menuItem) =>
      (!menuItem.steps || intersection(embeddedForm.steps, menuItem.steps).length > 0) &&
      (!menuItem.device || shouldShowMenuItemForDevice(embeddedForm, menuItem))
  )
}
