import { RandomSplitSequenceItemUI } from '@ghostmonitor/recartapis'
import {
  DEFAULT_SPLIT_VALUE,
  RandomSplitSequenceItemNodeModel
} from '../../../../routes/SequenceEditor/models/sequence-item/random-split/random-split-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { CreateSplitAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { SequenceEditorState, splitInitialMeta } from '../sequence-editor.state'

export function createSplitActionHandler(state: SequenceEditorState, action: CreateSplitAction) {
  const sequence = selectEditorSequence(state)
  const sequenceMeta = selectEditorSequenceMeta(state)
  const sequenceItem = selectSequenceItem<RandomSplitSequenceItemUI>(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)
  const diagramModel = getDiagramModel(sequence.serializedDiagram)
  const sequenceItemNode = getNodeModel<RandomSplitSequenceItemNodeModel>(
    diagramModel,
    action.sequenceItemId
  )

  sequenceItemNode.createSplitPort()
  sequence.serializedDiagram = diagramModel.serializeDiagram() as any
  sequenceMeta.splitsTouched = false

  sequenceItem.logic.randomSplit.variants.push({ ...DEFAULT_SPLIT_VALUE })
  sequenceItemMeta.splits.push({ ...splitInitialMeta })
}
