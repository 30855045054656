import cloneDeep from 'lodash/cloneDeep'
import moment, { Moment } from 'moment'
import { DateConditionUI, ExpressionUI } from '../../types/segment/condition-ui.type'

type DateConditionWithMomentDate = DateConditionUI & {
  date: Moment
}

type DateConditionWithStringDate = DateConditionUI & {
  date: string
}

type DateRangeConditionWithMomentDate = DateConditionUI & {
  dateRange: [Moment, Moment]
}

type DateRangeConditionWithStringDate = DateConditionUI & {
  dateRange: [string, string]
}

export function mapStringDateToMoment(expression: ExpressionUI): ExpressionUI {
  const clonedExpression = cloneDeep(expression)
  if (clonedExpression?.and) {
    clonedExpression.and.forEach((and) => {
      if (and.or) {
        and.or.forEach((or) => {
          if (
            (or as DateConditionWithStringDate).date &&
            typeof (or as DateConditionWithStringDate).date === 'string'
          ) {
            (or as DateConditionWithMomentDate).date = moment(
              (or as DateConditionWithStringDate).date
            ).utc()
          }
          if ((or as DateRangeConditionWithMomentDate).dateRange) {
            const [dateFrom, dateTo] = (or as DateRangeConditionWithMomentDate).dateRange
            if (dateFrom && typeof dateFrom === 'string') {
              (or as DateRangeConditionWithMomentDate).dateRange[0] = moment(dateFrom).utc()
            }

            if (dateTo && typeof dateTo === 'string') {
              (or as DateRangeConditionWithMomentDate).dateRange[1] = moment(dateTo).utc()
            }
          }
        })
      }
    })
  }
  return clonedExpression
}

export function mapMomentToStringDate(expression: ExpressionUI): ExpressionUI {
  const clonedExpression = cloneDeep(expression)
  if (clonedExpression.and) {
    clonedExpression.and.forEach((and) => {
      if (and.or) {
        and.or.forEach((or) => {
          if (
            (or as DateConditionWithMomentDate).date &&
            moment.isMoment((or as DateConditionWithMomentDate).date)
          ) {
            (or as DateConditionWithStringDate).date = (or as DateConditionWithMomentDate).date
              .utc(true)
              .startOf('day')
              .toISOString()
          }
          if ((or as DateRangeConditionWithMomentDate).dateRange) {
            const [dateFrom, dateTo] = (or as DateRangeConditionWithMomentDate).dateRange
            if (dateFrom && moment.isMoment(dateFrom)) {
              (or as DateRangeConditionWithStringDate).dateRange[0] = dateFrom
                .utc(true)
                .startOf('day')
                .toISOString()
            }

            if (dateTo && moment.isMoment(dateTo)) {
              (or as DateRangeConditionWithStringDate).dateRange[1] = dateTo
                .utc(true)
                .startOf('day')
                .toISOString()
            }
          }
        })
      }
    })
  }
  return clonedExpression
}
