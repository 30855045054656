import { Button as AntButton } from 'antd'
import { ButtonProps as AntButtonProps } from 'antd/lib/button'
import cn from 'classnames'
import React from 'react'
import styles from './editor-button.component.scss'

export type ButtonProps = AntButtonProps

export function EditorButton(props: ButtonProps) {
  const { className, ...restProps } = props

  return (
    <AntButton className={cn(styles[props.type ?? 'default'], className)} {...restProps}>
      {props.children}
    </AntButton>
  )
}
