import {
  isConditionalSplitSequenceItemUI,
  isDelaySequenceItemUI,
  isMessengerMessageSequenceItemUI,
  isMessengerUserInputSequenceItemUI,
  isRandomSplitSequenceItemUI,
  isSequenceItemLinkButton,
  SequenceItemUI
} from '@ghostmonitor/recartapis'
import { DiagramModel } from 'storm-react-diagrams'
import { BaseSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/base-sequence-item-model'
import { generateButtonPortName } from '../../../../routes/SequenceEditor/models/sequence-item/message/generate-button-parent-name'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import {
  FOLLOW_UP_PORT,
  LOGIC_PORT_CONDITION_MET,
  LOGIC_PORT_CONDITION_UNMET,
  LOGIC_PORT_DELAY_TIMEOUT_REACHED,
  LOGIC_PORT_USER_INPUT_IS_VALID,
  LOGIC_PORT_USER_INPUT_SKIP
} from '../../../../routes/SequenceEditor/models/sequence-item/port-names'
import { generateSplitPortName } from '../../../../routes/SequenceEditor/models/sequence-item/random-split/random-split-sequence-item-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { hasMessengerButtonOnMessageType } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasFollowUpButtonOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { selectSplitMeta } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
const { info } = createScope('sequence-editor')

function getTargetSequenceItemId(portName: string, node: BaseSequenceItemNodeModel): string | null {
  const port = node.getPort(portName)

  if (!port) {
    info('getTargetSequenceItemId:0')
    return undefined
  }

  const targetPort = node.getConnectedPorts(port)[0]

  if (!targetPort) {
    // TODO: should be null but need to check if that change would break anything
    return undefined
  }

  const targetNode = targetPort.getNode() as BaseSequenceItemNodeModel
  return targetNode.sequenceItemId
}

// ! Mutates diagramModel and sequenceItem
export function updateLinkTargets(
  state: SequenceEditorState,
  diagramModel: DiagramModel,
  sequenceItem: SequenceItemUI
) {
  if (hasFollowUpButtonOnSequenceItemType(sequenceItem)) {
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, sequenceItem._id)
    // follow ups are created manually so it might not exists yet
    const followUpPort = node.getFollowUpPort()
    if (followUpPort) {
      sequenceItem.nextSequenceItemTrigger = getTargetSequenceItemId(FOLLOW_UP_PORT, node)
    }
  }

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, sequenceItem._id)
    sequenceItem.quickReplies.forEach((quickReply) => {
      quickReply.trigger = getTargetSequenceItemId(quickReply.id, node)
    })

    sequenceItem.messages.forEach((message, messageIndex) => {
      if (hasMessengerButtonOnMessageType(message)) {
        message.messengerTemplatePayload.buttons.forEach((button, buttonIndex) => {
          if (isSequenceItemLinkButton(button)) {
            const buttonPortName = generateButtonPortName(
              sequenceItem._id,
              messageIndex,
              buttonIndex
            )
            button.trigger = getTargetSequenceItemId(buttonPortName, node)
          }
        })
      }
    })
  }

  const node = getNodeModel(diagramModel, sequenceItem._id)
  if (isMessengerUserInputSequenceItemUI(sequenceItem)) {
    sequenceItem.logic.userInput.trigger = getTargetSequenceItemId(
      LOGIC_PORT_USER_INPUT_IS_VALID,
      node
    )
    sequenceItem.logic.userInput.input_skipped_trigger = getTargetSequenceItemId(
      LOGIC_PORT_USER_INPUT_SKIP,
      node
    )
    sequenceItem.logic.userInput.validation_item = sequenceItem.sequenceItemIdsMap.validation
  }
  if (isDelaySequenceItemUI(sequenceItem)) {
    sequenceItem.logic.delay.trigger = getTargetSequenceItemId(
      LOGIC_PORT_DELAY_TIMEOUT_REACHED,
      node
    )
  } else if (isConditionalSplitSequenceItemUI(sequenceItem)) {
    sequenceItem.logic.conditionalSplit.variants[0].trigger = getTargetSequenceItemId(
      LOGIC_PORT_CONDITION_MET,
      node
    )
    sequenceItem.logic.conditionalSplit.defaultTrigger = getTargetSequenceItemId(
      LOGIC_PORT_CONDITION_UNMET,
      node
    )
  } else if (isRandomSplitSequenceItemUI(sequenceItem)) {
    sequenceItem.logic.randomSplit.variants = sequenceItem.logic.randomSplit.variants.map(
      (variant, index) => {
        const newTrigger = getTargetSequenceItemId(
          generateSplitPortName(sequenceItem._id, index),
          node
        )
        if (variant.trigger !== newTrigger) {
          const splitMeta = selectSplitMeta(sequenceItem._id, index)(state)
          splitMeta.edited = true
        }
        return {
          ...variant,
          trigger: newTrigger
        }
      }
    )
  }
}
