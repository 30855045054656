// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V_tozOLzfLD7ser5P0Y1 .ant-popover-content{border-radius:var(--recart-border-radius)!important}.V_tozOLzfLD7ser5P0Y1 .ant-popover-arrow{height:15px!important;right:-1px!important;width:15px!important}.V_tozOLzfLD7ser5P0Y1 .ant-popover-arrow .ant-popover-arrow-content{box-shadow:none!important;height:15px!important;width:15px!important}.V_tozOLzfLD7ser5P0Y1 .ant-popover-inner{border-radius:var(--recart-border-radius)!important;box-shadow:0 5px 20px rgba(28,69,99,.2)!important}.V_tozOLzfLD7ser5P0Y1 .ant-popover-inner-content{padding:var(--recart-spacing-2)!important}.A3wl0GfEOVl0BBuLTQ8g{align-items:center;border-radius:var(--recart-border-radius-sm);color:var(--recart-night-blue-1);cursor:pointer;display:flex;font-size:var(--recart-font-size-base);line-height:22px;padding:var(--recart-spacing-2) var(--recart-spacing-3)}.A3wl0GfEOVl0BBuLTQ8g:hover{background-color:var(--recart-light-blue-1)}.A3wl0GfEOVl0BBuLTQ8g svg{height:16px;margin-right:var(--recart-spacing-2);width:16px}.A3wl0GfEOVl0BBuLTQ8g.QPAUrXL1tMq3zKwaYrwo{color:var(--recart-grey-5);cursor:not-allowed}.A3wl0GfEOVl0BBuLTQ8g.QPAUrXL1tMq3zKwaYrwo svg{color:var(--recart-grey-5)}.A3wl0GfEOVl0BBuLTQ8g.QPAUrXL1tMq3zKwaYrwo:hover{background-color:transparent}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/popover/popover.component.scss"],"names":[],"mappings":"AAGM,2CACE,mDAAA,CAGF,yCAEE,qBAAA,CACA,oBAAA,CAFA,oBAEA,CAEA,oEAGE,yBAAA,CADA,qBAAA,CADA,oBAEA,CAIJ,yCACE,mDAAA,CACA,iDAAA,CAGF,iDACE,yCAAA,CAKN,sBAEE,kBAAA,CAKA,4CAAA,CADA,gCAAA,CAEA,cAAA,CAPA,YAAA,CAEA,sCAAA,CACA,gBAAA,CACA,uDAGA,CAEA,4BACE,2CAAA,CAGF,0BAEE,WAAA,CACA,oCAAA,CAFA,UAEA,CAGF,2CACE,0BAAA,CACA,kBAAA,CAEA,+CACE,0BAAA,CAGF,iDACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `V_tozOLzfLD7ser5P0Y1`,
	"row": `A3wl0GfEOVl0BBuLTQ8g`,
	"disabled": `QPAUrXL1tMq3zKwaYrwo`
};
export default ___CSS_LOADER_EXPORT___;
