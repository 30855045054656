import { AppAuth } from '@ghostmonitor/recartapis'
import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  IntegrationSlug,
  UpdateIntegrationPayload
} from '../../routes/integrations/integrations.type'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

export function useIntegration<TIntegration = AppAuth>(
  slug: IntegrationSlug
): UseResource<TIntegration, UpdateIntegrationPayload> {
  const queryName = useMemo(() => [QUERY_NAME.integration, slug], [slug])

  const { handleError, handleSettled, handlePatch, handleRemove } =
    useMutationHelpers<UpdateIntegrationPayload>(queryName)

  const { isLoading, isFetching, data } = useQuery<TIntegration>(queryName, () =>
    request.get<TIntegration>(`integrations/${slug}`)
  )

  async function patchIntegration(integration: UpdateIntegrationPayload): Promise<void> {
    // The HTTP method can be misleading here, because here the patch actually does upsert
    await request.patch<TIntegration>(`integrations/${slug}`, integration)
  }

  async function removeIntegration(): Promise<void> {
    await request.del<TIntegration>(`integrations/${slug}`)
  }

  const { mutateAsync: mutatePatchIntegration } = useMutation<
    void,
    unknown,
    UpdateIntegrationPayload
  >(patchIntegration, {
    onMutate: handlePatch,
    onError: handleError,
    onSettled: handleSettled
  })

  const { mutateAsync: mutateRemoveIntegration } = useMutation<void, unknown, unknown>(
    removeIntegration,
    {
      onMutate: handleRemove,
      onSettled: handleSettled,
      onError: handleError
    }
  )

  return {
    data,
    patch: mutatePatchIntegration,
    remove: mutateRemoveIntegration,
    isLoading,
    isFetching
  }
}
