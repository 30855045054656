import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as BugIcon } from '../../../../../static/images/svg/streamline/stroke/04-Programing-Apps-Websites/05-Bug-Security/computer-bug-1.svg'
import { selectShowDebugView } from '../../../../../store/selectors'
import { toggleDebugView } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { setLocalDebugView } from '../../../utils/localstorage'
import { CanvasButtonGroup } from '../../canvas-toolbar/canvas-button-group/canvas-button-group.component'
import { CanvasButton } from '../../canvas-toolbar/canvas-button/canvas-button.component'
import { CanvasToolbarItem } from '../canvas-toolbar-item.component'

export function CanvasToolbarDebug() {
  const showDebugView = useSelector(selectShowDebugView)
  const dispatch = useDispatch()

  function handleToggleDebugView() {
    dispatch(toggleDebugView({ visible: !showDebugView }))
    setLocalDebugView(!showDebugView)
  }

  if (showDebugView === null) {
    return null
  }

  return (
    <CanvasToolbarItem>
      <CanvasButtonGroup>
        <CanvasButton
          title='Toggle debug'
          icon={<BugIcon />}
          onClick={handleToggleDebugView}
          active={showDebugView}
        />
        <CanvasButton
          title={showDebugView ? 'Hide debug' : 'Show debug'}
          readOnly
          onClick={handleToggleDebugView}
          extraStyle={{ minWidth: '110px' }}
          active={showDebugView}
        >
          {showDebugView ? 'Hide debug' : 'Show debug'}
        </CanvasButton>
      </CanvasButtonGroup>
    </CanvasToolbarItem>
  )
}
