import { hooks } from './hooks'

export function useHasInternationPhoneNumber(): boolean {
  const { data: phoneNumbers } = hooks.useSMSPhoneNumbers()

  const hasInternationalPhoneNumber =
    phoneNumbers?.filter((number) => number.handsetDialInCodes[0] !== 1).length > 0

  return hasInternationalPhoneNumber
}
