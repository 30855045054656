import cn from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { Popover } from '../../../../../../components/ui-kit/popover/popover.component'
import { hooks } from '../../../../../../hooks/hooks'
import { selectSequenceItem } from '../../../../../../store/selectors'
import { getItemVariableInfo } from '../../../../../../utils/variables/item-variable-info'
import {
  isDiscountCodeInfo,
  isUrlInfo
} from '../../../../../../utils/variables/types/varible-info.type'
import styles from './variable-info.component.scss'

interface VariableInfoPopoverProps {
  sequenceItemId: string
  children?: React.ReactNode
}

export function VariableInfoPopover(props: VariableInfoPopoverProps) {
  const { sequenceItemId } = props
  const sequenceItem = useSelector(selectSequenceItem(sequenceItemId))
  const variableInfo = getItemVariableInfo(sequenceItem)
  const { data: discountCodePools } = hooks.useDiscountCodePools()

  function getDiscountInfoPopoverContent() {
    return (
      <>
        {variableInfo.map((info, index) => {
          if (isDiscountCodeInfo(info)) {
            return (
              <div
                key={`${index}${info.discount}`}
                className={cn('text-tiny', { 'mt-3': index > 0 })}
              >
                <div className='font-bold'>
                  {info.discountCodeType === 'unique' ? 'Unique' : 'General'} discount code
                </div>
                <div className='mt-1'>
                  {info.url && <div className='m-0'>{info.url}</div>}
                  <div className='m-0 leading-tight'>
                    {info.discountCodeType === 'unique'
                      ? `Applied discount code pool: ${
                          discountCodePools?.find((pool) => pool.id === info.discount).name ?? ''
                        }`
                      : `Applied discount code: ${info.discount}`}
                  </div>
                </div>
              </div>
            )
          }
          if (isUrlInfo(info)) {
            return (
              <div key={`${index}${info.url}`} className={cn('text-tiny', { 'mt-3': index > 0 })}>
                <div className='font-bold'>Link</div>
                <div>
                  <div className='m-0'>{info.url}</div>
                </div>
              </div>
            )
          }
          return null
        })}
      </>
    )
  }

  return variableInfo.length > 0 ? (
    <div>
      <Popover
        placement='right'
        trigger='hover'
        content={getDiscountInfoPopoverContent}
        overlayClassName={styles.discountInfo}
        items={[]}
      >
        {props.children}
      </Popover>
    </div>
  ) : (
    <>{props.children}</>
  )
}
