// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.esboKkSBegi7S9LFir7W{align-items:center;background:rgba(172,183,195,.85);display:flex;height:100%;justify-content:center;left:0;position:fixed;top:0;width:100%;z-index:100000}.P9qszNcxKMdGhmP5saig{padding:2em;position:relative}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-editor-overlay/sequence-editor-overlay.component.scss"],"names":[],"mappings":"AAAA,sBAUE,kBAAA,CAHA,gCAAA,CACA,YAAA,CAFA,WAAA,CAGA,sBAAA,CALA,MAAA,CAHA,cAAA,CAEA,KAAA,CAEA,UAAA,CAHA,cAQA,CAGF,sBAEE,WAAA,CADA,iBACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `esboKkSBegi7S9LFir7W`,
	"content": `P9qszNcxKMdGhmP5saig`
};
export default ___CSS_LOADER_EXPORT___;
