import { SequenceTag, SequenceUI } from '@ghostmonitor/recartapis'

export function isSMSSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.SMS)
}

// TODO: add tag to identify messenger sequences
export function isMessengerSequence(sequence: SequenceUI): boolean {
  return !isSMSSequence(sequence)
}

export function isFacebookCommentsSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.FACEBOOK_COMMENTS)
}

export function isWelcomeSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.WELCOME)
}

export function isBlastSequence(sequence: SequenceUI): boolean {
  return (
    isSMSSequence(sequence) && !!sequence?.tags && sequence?.tags.includes(SequenceTag.CAMPAIGN)
  )
}

export function isCampaignSequence(sequence: SequenceUI): boolean {
  return isBlastSequence(sequence)
}

export function isAutomatedSequence(sequence: SequenceUI): boolean {
  return !isCampaignSequence(sequence)
}

export function isJoinedASegmentSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.JOINED_A_SEGMENT)
}

export function isWondermentSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.WONDERMENT)
}

export function isLoopWorkSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.LOOPWORK)
}
export function isTypeformSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.TYPEFORM)
}

export function isWheelioSequence(sequence: SequenceUI): boolean {
  return !!sequence?.tags && sequence?.tags.includes(SequenceTag.WHEELIO_OPTIN)
}
