import { ButtonActionType } from '@ghostmonitor/recartapis'
import { CartUrlPopoverForm } from './popover-forms/form-cart-url.component'
import { PhoneNumberPopoverForm } from './popover-forms/form-phone-number.component'
import { WebUrlPopoverForm } from './popover-forms/form-web-url.component'
import { ButtonTypeOption } from './types/button-type-option.type'

export const buttonTypeOptions: {
  [value: string]: ButtonTypeOption
} = {
  [ButtonActionType.web_url]: {
    value: ButtonActionType.web_url,
    label: 'Open a website',
    content: WebUrlPopoverForm
  },
  [ButtonActionType.phone_number]: {
    value: ButtonActionType.phone_number,
    label: 'Call a number',
    content: PhoneNumberPopoverForm
  },
  [ButtonActionType.postback]: {
    value: ButtonActionType.postback,
    label: 'Links to other message'
  },
  [ButtonActionType.shipment_tracking_url]: {
    value: ButtonActionType.shipment_tracking_url,
    label: 'Shipment tracking'
  },
  [ButtonActionType.review_url]: {
    value: ButtonActionType.review_url,
    label: 'Review'
  },
  [ButtonActionType.cart_url]: {
    value: ButtonActionType.cart_url,
    label: 'Link to customer cart',
    content: CartUrlPopoverForm
  }
}
