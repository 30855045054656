import {
  isSMSMessageContactCardUI,
  isSMSMessageMMSUI,
  isSMSMessageTextUI,
  SMSMessageUI
} from '@ghostmonitor/recartapis'
import { Space } from 'antd'
import debounce from 'lodash/debounce'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsEntrySequenceItem, selectMessage } from '../../../../../store/selectors'
import { updateMessage } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { makeInteractiveMessage } from '../../../higher-order-components/make-interactive-message/make-interactive-message.hoc'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { SMSMessageSequenceItemNodeModel } from '../../../models/sequence-item/sms-message/sms-message-sequence-item-model'
import { MMSImageUploaderTemplate } from '../../message-templates/mms-image-uploader-template/mms-image-uploader-template.component'
import { SMSContactCardTemplate } from '../../message-templates/sms-contact-card-template/sms-contact-card-template.component'
import { SMSTextLegalMessage } from '../../message-templates/sms-text-legal-message/sms-text-legal-message.component'
import { SMSTextTemplate } from '../../message-templates/sms-text-template/sms-text-template.component'
import { SMSCounterRow } from '../../sms-counter-row/sms-counter-row.component'
import { makeSequenceItemWidget } from './make-sequence-item-widget.hoc'

export interface SMSMessageSequenceItemNodeWidgetProps {
  sequenceItemNode: SMSMessageSequenceItemNodeModel
}

const SMSTextTemplateInteractive = makeInteractiveMessage(SMSTextTemplate)
const SMSTextLegalMessageInteractive = makeInteractiveMessage(SMSTextLegalMessage)
const MMSImageUploaderInteractive = makeInteractiveMessage(MMSImageUploaderTemplate)
const SMSContactCardInteractive = makeInteractiveMessage(SMSContactCardTemplate)

export function SMSMessageSequenceItemNodeWidgetComponent(
  props: SMSMessageSequenceItemNodeWidgetProps
) {
  const sequenceItemId = props.sequenceItemNode.sequenceItemId
  const editorSettings = useSequenceEditorSettings()
  const isEntry = useSelector(selectIsEntrySequenceItem(sequenceItemId))
  const message = useSelector(selectMessage<SMSMessageUI>(sequenceItemId, 0))
  const isEntryLegal = isEntry && editorSettings.isLegalMessageNeeded
  const [editorText, setEditorText] = useState(message.text)
  const dispatch = useDispatch()

  const dispatchChange = useCallback(
    (text: string) => {
      dispatch(updateMessage(sequenceItemId, 0, { text }))
    },
    [dispatch, sequenceItemId]
  )
  const debouncedHandleChange = useMemo(
    () => debounce(dispatchChange, 750, { leading: true, trailing: true }),
    [dispatchChange]
  )

  function handleChange(text: string) {
    setEditorText(text)
    debouncedHandleChange(text)
  }

  if (isEntryLegal) {
    return (
      <>
        <SMSTextLegalMessageInteractive
          isRemovable={false}
          sequenceItemId={sequenceItemId}
          messageIndex={0}
          onChange={handleChange}
        />
        <SMSCounterRow messageText={editorText} isCharCountHidden={isEntryLegal} />
      </>
    )
  }

  if (isSMSMessageTextUI(message)) {
    return (
      <>
        <SMSTextTemplateInteractive
          isRemovable={false}
          sequenceItemId={sequenceItemId}
          messageIndex={0}
          onChange={handleChange}
        />
        <SMSCounterRow messageText={editorText} isCharCountHidden={isEntryLegal} />
      </>
    )
  }

  if (isSMSMessageMMSUI(message)) {
    const unit = message.attachments.length >= 0 ? 'MMS' : 'SMS'
    return (
      <>
        <Space size={19} direction='vertical'>
          <SMSTextTemplateInteractive
            isRemovable={false}
            sequenceItemId={sequenceItemId}
            messageIndex={0}
            onChange={handleChange}
          />
          <MMSImageUploaderInteractive
            isRemovable
            sequenceItemId={sequenceItemId}
            messageIndex={0}
            onChange={handleChange}
          />
        </Space>
        <SMSCounterRow messageText={editorText} isCharCountHidden={isEntryLegal} unit={unit} />
      </>
    )
  }
  if (isSMSMessageContactCardUI(message)) {
    const unit = 'MMS'
    return (
      <>
        <Space size={19} direction='vertical'>
          <SMSTextTemplateInteractive
            isRemovable={false}
            sequenceItemId={sequenceItemId}
            messageIndex={0}
            onChange={handleChange}
          />
          <SMSContactCardInteractive
            isRemovable
            sequenceItemId={sequenceItemId}
            messageIndex={0}
            onChange={handleChange}
            width={240}
          />
        </Space>
        <SMSCounterRow messageText={editorText} isCharCountHidden={isEntryLegal} unit={unit} />
      </>
    )
  }
}

export const SMSMessageSequenceItemNodeWidget = makeSequenceItemWidget(
  SMSMessageSequenceItemNodeWidgetComponent
)
