import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { SET_USER } from './me.actions'
import { meInitialState, MeState } from './me.types'

const meSlice = createSlice({
  name: 'me',
  initialState: meInitialState,
  reducers: {
    meLoaded: (state, action: PayloadAction<MeState>) => action.payload
  },
  extraReducers: (builder) => {
    builder.addCase(SET_USER, (state, action: any) => {
      return merge(state, action.data)
    })
  }
})

export const { meLoaded } = meSlice.actions

export const meActions = meSlice.actions

export const { reducer: meReducer } = meSlice
