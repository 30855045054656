import {
  ButtonActionType,
  ButtonUI,
  isMessengerMessageSequenceItemUI
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { CreateButtonAction } from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta
} from '../sequence-editor.selectors'
import { ButtonInitialMeta, SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function createButtonActionHandler(state: SequenceEditorState, action: CreateButtonAction) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  const buttonPayload: ButtonUI = action.button ?? {
    title: 'Shop Now',
    type: ButtonActionType.web_url,
    url: action.siteUrl || ''
  }

  // Sequence Item
  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    if (isMessageGenericGalleryTemplate(message) && action.messageItemIndex !== undefined) {
      const buttonIndex =
        action.buttonIndex ??
        message.messengerTemplatePayload[action.messageItemIndex].buttons.length
      message.messengerTemplatePayload[action.messageItemIndex].buttons.splice(
        buttonIndex,
        0,
        buttonPayload
      )
      sequenceItemMeta.messages[action.messageIndex].messageItems[
        action.messageItemIndex
      ].buttons.splice(buttonIndex, 0, cloneDeep(ButtonInitialMeta))
    } else if (hasMessengerButtonOnMessageType(message)) {
      const buttonIndex = action.buttonIndex ?? message.messengerTemplatePayload.buttons.length
      message.messengerTemplatePayload.buttons.splice(buttonIndex, 0, buttonPayload)
      sequenceItemMeta.messages[action.messageIndex].buttons.splice(
        buttonIndex,
        0,
        cloneDeep(ButtonInitialMeta)
      )

      if (isMessengerMessageSequenceItemUI(sequenceItem)) {
        const sequence = selectEditorSequence(state)
        const diagramModel = getDiagramModel(sequence.serializedDiagram)
        const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
        node.createButton(
          buttonPayload.type,
          action.messageIndex,
          buttonIndex,
          action.messageItemIndex
        )
        sequence.serializedDiagram = diagramModel.serializeDiagram() as any
      }
    } else {
      info('createButtonActionHandler:1', { action, sequenceItem })
    }
  } else {
    info('createButtonActionHandler:2', { action, sequenceItem })
  }
}
