import React from 'react'
import styles from './stat.component.scss'

interface StatProps {
  value: number | string
  label: string
  tooltip?: string
  title?: string
}

export function Stat(props: StatProps) {
  return (
    <div title={props.title} className={styles.wrapper}>
      <div className={styles.value}>
        {props.value}
        {props.tooltip && <span className={styles.tooltip}>{props.tooltip}</span>}
      </div>
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}
