'use strict'
import { useEffect, useState } from 'react'

export function useImagesLoaded(): boolean {
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const images = document.getElementsByTagName('img')

  useEffect(() => {
    const references: { img: HTMLImageElement; handler: (value: unknown) => void }[] = []
    const promisesArray = [...images].map((img) => {
      if (!img.complete) {
        return new Promise((resolve) => {
          references.push({ img, handler: resolve })
          img.addEventListener('load', resolve, { once: true })
        })
      } else return null
    })

    if (promisesArray.length > 0) {
      Promise.all(promisesArray).then(() => {
        setImagesLoaded(true)
      })
    }

    return () => {
      references.forEach((reference) => {
        reference.img.removeEventListener('load', reference.handler)
      })
    }
  }, [images.length])

  return imagesLoaded
}
