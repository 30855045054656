import cn from 'classnames'
import React from 'react'
import { ReactComponent as ShopifyBagLogo } from '../../../../../../static/images/svg/logo-shopify-bag.svg'
import { ReactComponent as RecartLogoSmall } from '../../../../../../static/images/svg/recart-logo-sm.svg'
import styles from './discount-code-logos.component.scss'

interface DiscountCodeLogosProps {
  showShopify: boolean
}

export function DiscountCodeLogos(props: DiscountCodeLogosProps) {
  return (
    <div className={cn(styles.container, 'flex flex-col mt-3')}>
      {props.showShopify && (
        <div className='flex flex-row mb-2'>
          <div className={styles.logo}>
            <ShopifyBagLogo />
          </div>
          <div className={cn(styles.text, 'text-tiny')}>
            Your discount code will be added to your Shopify store by default.
          </div>
        </div>
      )}
      <div className='flex flex-row mb-2'>
        <div className={styles.logo}>
          <RecartLogoSmall />
        </div>
        <div className={cn(styles.text, 'text-tiny')}>
          Orders with this discount code will be tracked as Recart conversions.{' '}
          <a
            href='https://help.recart.com/en/articles/5905797-how-to-add-general-discount-codes-to-your-recart-text-messages'
            target='_blank'
            rel='noreferrer'
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  )
}
