import {
  MessageType,
  NodeType,
  PortType,
  SequenceItemType,
  SequenceUI,
  SerializedSequenceItemNodeModel,
  SMSSequenceItemUI
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import merge from 'lodash/merge'
import { DiagramEngine, PortModel } from 'storm-react-diagrams'
import { attachCanConvertToNewSMSMessageSequenceItem } from '../../../middlewares/utils/attach-can-convert'
import { FollowUpPortModel } from '../../port/follow-up-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'

export function getNewSMSMessageSequenceItem(
  sequence: SequenceUI,
  siteId: string
): SMSSequenceItemUI {
  const sequenceItemId = new ObjectId().toHexString()
  let smsMessageSequenceItem: SMSSequenceItemUI = {
    _id: sequenceItemId,
    type: SequenceItemType.SMS,
    siteId,
    channel: 'sms',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    messages: [
      {
        type: MessageType.SMS_TEXT,
        text: ''
      }
    ],
    isEnabled: false,
    tags: [],
    trigger: [sequenceItemId],
    canConvert: true
  }
  smsMessageSequenceItem = attachCanConvertToNewSMSMessageSequenceItem(
    sequence,
    smsMessageSequenceItem
  )
  return smsMessageSequenceItem
}

export class SMSMessageSequenceItemNodeModel extends BaseSequenceItemNodeModel {
  constructor(sequenceItemId?: string, sequenceItem?: SMSSequenceItemUI, id?: string) {
    super(NodeType.SMS_SEQUENCE_ITEM, sequenceItemId, id)
  }

  public portOrder: string[] = []

  public addPort<T extends PortModel>(portModel: T): T {
    if (this.getInputPort() !== undefined && portModel.type === PortType.SEQUENCE_ITEM) {
      throw new Error("There can't be more than one input port.")
    }
    return super.addPort(portModel)
  }

  public removePort(port: PortModel): void {
    return super.removePort(port)
  }

  public renamePort(oldPortName: string, newPortName: string): void {
    const port = this.getPort(oldPortName)
    this.removePort(port)
    port.name = newPortName
    this.addPort(port)
  }

  public serialize() {
    return merge(super.serialize(), {
      portOrder: this.portOrder
    })
  }

  public deSerialize(
    serializedDiagram: SerializedSequenceItemNodeModel,
    engine: DiagramEngine
  ): void {
    super.deSerialize(serializedDiagram, engine)
    this.portOrder = serializedDiagram.portOrder
  }

  public remove(): void {
    super.remove()
    this.iterateListeners((listener: any) => {
      if (listener.removeNode) {
        listener.removeNode(this.sequenceItemId)
      }
    })
  }

  public getFollowUpPort(): FollowUpPortModel {
    return this.getPortsByType(PortType.FOLLOW_UP)[0] as FollowUpPortModel
  }

  public getFollowUpPortTargetPortId(): string {
    const followUpPort = this.getFollowUpPort()
    if (followUpPort) {
      const targetPort = this.getConnectedPorts(followUpPort)[0]

      return targetPort?.id
    }
  }

  public removeFollowUpPort(): void {
    const followUpPort = this.getFollowUpPort()
    Object.values(followUpPort.getLinks()).forEach((link) => {
      link.remove()
    })
    this.removePort(followUpPort)
    followUpPort.remove()
  }
}
