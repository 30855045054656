// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E9DufRcXb7XmavClafZJ{position:relative}.E9DufRcXb7XmavClafZJ:hover .KmIuTOqpr3qCqXZLOjk5.kyztIOefSEAYuAuJm19e{opacity:1;transform:scale(1)}.E9DufRcXb7XmavClafZJ .KmIuTOqpr3qCqXZLOjk5{background:#fff1f0;border:1px solid #ff7875;border-radius:50%;color:#ff7875;cursor:pointer;font-size:21px;font-weight:700;height:20px;left:-10px;line-height:17px;opacity:0;position:absolute;scale:.8;text-align:center;top:-10px;transform:scale(.6);transform-origin:center;transition:all .2s cubic-bezier(.68,-.55,.265,1.55);width:20px;z-index:13}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/removable-element/removable-element.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAGE,uEACE,SAAA,CACA,kBAAA,CAIJ,4CAKE,kBCkBK,CDnBL,wBAAA,CADA,iBAAA,CAQA,aCUM,CDDN,cAAA,CAJA,cAAA,CACA,eAAA,CAPA,WAAA,CAKA,UAAA,CAHA,gBAAA,CANA,SAAA,CAJA,iBAAA,CAMA,QAAA,CAKA,iBAAA,CACA,SAAA,CAKA,mBAAA,CADA,uBAAA,CAXA,mDAAA,CAEA,UAAA,CARA,UAmBA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `E9DufRcXb7XmavClafZJ`,
	"remove": `KmIuTOqpr3qCqXZLOjk5`,
	"visible": `kyztIOefSEAYuAuJm19e`
};
export default ___CSS_LOADER_EXPORT___;
