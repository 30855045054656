import { PortType, RandomSplitSequenceItemUI } from '@ghostmonitor/recartapis'
import { Divider, Form, Input, Skeleton } from 'antd'
import { ObjectId } from 'bson'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ReactComponent as RandomSplitIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/42-Multimedia-Controls/button-split.svg'
import {
  selectCurrencySlug,
  selectDraggedItemType,
  selectEditorSequence,
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView
} from '../../../../../store/selectors'
import {
  createSequenceItem,
  createSplit,
  removeSequenceItem,
  removeSplit,
  updateSplit
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { SequenceItemMeta } from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import {
  formatters,
  getDecimalSeparator,
  parseLocaleNumber,
  roundToDecimals
} from '../../../../../utils/formatters/formatters'
import {
  SequenceItemNodeWidgetBaseProps,
  makeSequenceItem
} from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc'
import hocStyles from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'
import { LogicPortModel } from '../../../models/port/logic-port-model'
import { RandomSplitSequenceItemNodeModel } from '../../../models/sequence-item/random-split/random-split-sequence-item-model'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import {
  AddElementButton,
  AddElementButtonType
} from '../../add-element-button/add-element-button.component'
import { Port } from '../../port/port.component'
import { RemovableElement } from '../../removable-element/removable-element.component'
import { SequenceItemWidgetTitle } from '../sequence-item-widget-title.component'
import styles from './random-split-node-widget.component.scss'

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export interface RandomSplitSequenceItemWidgetProps extends SequenceItemNodeWidgetBaseProps {
  sequenceItemNode: RandomSplitSequenceItemNodeModel
  sequenceItem: RandomSplitSequenceItemUI
  sequenceItemMeta: SequenceItemMeta
  repaintCanvas: () => void
  onAddSplit: () => void
  onRemoveSplit: (index: number) => void
  onUpdateSplit: (index: number, percentage: number) => void
}

function isValidInput(value: string) {
  const decimalSeparator = getDecimalSeparator()
  const numberFormat = new RegExp(`^\\d{0,3}(\\${decimalSeparator}\\d*)?$`)

  return numberFormat.test(value)
}

const defaultSplitValues = ['50', '50']

export function RandomSplitSequenceItemWidgetComponent(props: RandomSplitSequenceItemWidgetProps) {
  const [splitValues, setSplitValues] = useState(defaultSplitValues)

  useEffect(() => {
    if (props.sequenceItem) {
      const { variants } = props.sequenceItem.logic.randomSplit
      if (splitValues === defaultSplitValues || variants.length !== splitValues.length) {
        const percentages = variants.map((variant) => formatters.number(variant.percentage))
        setSplitValues(percentages)
      }
    }
  }, [props.sequenceItem])

  useEffect(() => {
    props.repaintCanvas()
  }, [props.sequenceItemMeta?.splits])

  function handleAddNewSplit() {
    props.onAddSplit()
  }

  function handleRemoveSplit(index: number) {
    props.onRemoveSplit(index)
  }

  function updateSplitValues(index: number, value: string) {
    setSplitValues((prevSplitValues) =>
      prevSplitValues.map((splitValue, valueIndex) => {
        if (index === valueIndex) {
          if (value === getDecimalSeparator()) {
            return `0${value}`
          }

          // remove leading 0s
          return value.replace(/^0+(?=\d+)/, '')
        }

        return splitValue
      })
    )
  }

  function handleSplitValueChange(index: number) {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      const { value } = event.target

      if (isValidInput(value)) {
        updateSplitValues(index, value)

        const numberValue = parseLocaleNumber(value)
        props.onUpdateSplit(index, roundToDecimals(numberValue))
      }
    }
  }

  function handleSplitValueBlur(index: number) {
    return function () {
      if (splitValues[index] === '') {
        updateSplitValues(index, '0')
        props.onUpdateSplit(index, 0)
      }
    }
  }

  function renderSplits() {
    const splits = props.sequenceItem?.logic.randomSplit.variants ?? []

    return splits.map((_, index) => {
      const portId = `${props.sequenceItem._id}-${index}`
      const port = props.sequenceItemNode.getPort(portId) as LogicPortModel

      // wait for the diagram to be updated
      if (!port) {
        return null
      }

      const links = Object.values(port.getLinks())
      const isConnected = links?.[0]?.getTargetPort() !== undefined
      const shouldPulse =
        props.linking &&
        props.linkingMeta.portType === PortType.SEQUENCE_ITEM &&
        props.sequenceItemNode.sequenceItemId !== props.linkingMeta.portParentSequenceItemId &&
        !isConnected

      const errorMessage = props.sequenceItemMeta.splits[index]?.error

      return (
        <div key={ALPHABET[index]} className={styles.splitContainer}>
          {errorMessage && (
            <div className={styles.inputErrorContainer}>
              <span className={styles.inputError}>{errorMessage}</span>
            </div>
          )}
          <div className={styles.inputContainer}>
            <RemovableElement onRemove={() => handleRemoveSplit(index)} visible={splits.length > 2}>
              <Form.Item
                validateStatus={errorMessage || props.sequenceItemMeta.error ? 'error' : ''}
              >
                <Input
                  className={styles.input}
                  value={splitValues[index]}
                  onChange={handleSplitValueChange(index)}
                  onBlur={handleSplitValueBlur(index)}
                  addonBefore={ALPHABET[index]}
                  suffix='%'
                  data-testid={`split-value-${index}`}
                />
              </Form.Item>
              <div className={styles.portContainer}>
                <Port
                  name={port.name}
                  nodeId={props.sequenceItemNode.getID()}
                  isLinked={isConnected}
                  isPulsing={shouldPulse}
                  hintOrientation='right'
                  hasHint
                />
              </div>
            </RemovableElement>
          </div>
        </div>
      )
    })
  }

  const splitCount = props.sequenceItem?.logic.randomSplit.variants.length ?? 0

  if (!props.sequenceItem) {
    return <Skeleton active title={false} paragraph={{ rows: 3 }} />
  }

  return (
    <div>
      <p className={hocStyles.title}>Split traffic</p>
      <div>{renderSplits()}</div>
      {splitCount < ALPHABET.length && (
        <>
          <Divider className={styles.divider} />
          <div className={styles.center}>
            <AddElementButton
              buttonType={AddElementButtonType.SPLIT}
              label='Add new split'
              onClick={handleAddNewSplit}
              data-testid='add-new-split'
            />
          </div>
        </>
      )}
    </div>
  )
}

export interface RandomSplitWidgetProps {
  sequenceItemNode: RandomSplitSequenceItemNodeModel
  repaintCanvas: VoidFunction
}

export function RandomSplitWidgetContainer(props: RandomSplitWidgetProps) {
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      sequenceItem: selectSequenceItem<RandomSplitSequenceItemUI>(
        props.sequenceItemNode.sequenceItemId
      ),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId),
      currencySlug: selectCurrencySlug,
      showDebugView: selectShowDebugView,
      linking: selectLinking,
      linkingMeta: selectLinkingMeta,
      draggedItemType: selectDraggedItemType
    }),
    [props.sequenceItemNode.sequenceItemId]
  )

  const state = useSelector(stateSelector)
  const dispatch = useDispatch()
  const [removing, setRemoving] = useState(false)

  function handleRemoveSequenceItem() {
    setRemoving(true)
    dispatch(removeSequenceItem(state.sequenceItem._id))
  }

  function handleRepaintCanvas() {
    if (!removing) {
      props.repaintCanvas()
    }
  }

  function handleCloneSequenceItem() {
    const newSequenceItem = cloneDeep(state.sequenceItem)
    newSequenceItem._id = new ObjectId().toHexString()
    newSequenceItem.trigger = [newSequenceItem._id]
    newSequenceItem.tags = [...state.sequence.tags]

    newSequenceItem.logic.randomSplit.variants.forEach((variant) => {
      variant.trigger = null
    })

    dispatch(
      createSequenceItem(newSequenceItem, {
        x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
        y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
      })
    )
  }

  function handleAddSplit() {
    dispatch(createSplit(state.sequenceItem._id))
  }

  function handleRemoveSplit(index: number) {
    dispatch(removeSplit(state.sequenceItem._id, index))
  }

  function handleUpdateSplit(index: number, percentage: number) {
    dispatch(updateSplit(state.sequenceItem._id, index, percentage))
  }

  const isEntry = props.sequenceItemNode.sequenceItemId === state.sequence?.entrySequenceItemId
  const inputPort = props.sequenceItemNode.getInputPort()

  return (
    <RandomSplitSequenceItemWidget
      faded={!!state.draggedItemType}
      currencySlug={state.currencySlug}
      inputPort={inputPort}
      isEntry={isEntry}
      linking={state.linking}
      linkingMeta={state.linkingMeta}
      sequenceItem={state.sequenceItem}
      sequenceItemMeta={state.sequenceItemMeta}
      sequenceItemNode={props.sequenceItemNode}
      showDropZone={false}
      showSequenceItemStatistics={false}
      showDebugView={state.showDebugView}
      repaintCanvas={handleRepaintCanvas}
      onClone={handleCloneSequenceItem}
      onRemove={handleRemoveSequenceItem}
      onAddSplit={handleAddSplit}
      onRemoveSplit={handleRemoveSplit}
      onUpdateSplit={handleUpdateSplit}
    />
  )
}

export const RandomSplitSequenceItemWidget = makeSequenceItem(
  RandomSplitSequenceItemWidgetComponent,
  [hocStyles['random-split-wrapper']],
  <SequenceItemWidgetTitle wrapperClasses={[]} text='Randomizer' icon={<RandomSplitIcon />} />
)

export const RandomSplitSequenceItemNodeWidget = React.memo(RandomSplitWidgetContainer)
