// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._nMB4ksg6a38xJeAAiOg{padding:var(--recart-spacing-3)}.kUqsuxRs1JDCyySXus42{inset:0;position:absolute}.N3pb50JXFFnEzKcflPMF{color:#262626;font-family:Lato,sans-serif;font-size:14px;line-height:24px;margin:var(--recart-spacing-2) 0 var(--recart-spacing-1);text-align:center}.EohhRNrgJj2jqi6i4q60{color:#8c8c8c;font-family:Lato,sans-serif;font-size:12px;line-height:14px;margin-bottom:var(--recart-spacing-1);text-align:center}.a0Xry3enp3mQcLCGIb5b{background:#d9d9d9;border-radius:2px;height:5px;margin-top:var(--recart-spacing-3);position:relative;width:165px}.PU3EIKKxhKBoYw7OzfSu{background:#4268f2;border-radius:2px;bottom:0;left:0;position:absolute;top:0;transition:width .75s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/image-upload/image-upload-button.component.scss"],"names":[],"mappings":"AACE,sBACE,+BAAA,CAGF,sBAEE,OAAA,CADA,iBACA,CAGF,sBAIE,aAAA,CAHA,2BAAA,CACA,cAAA,CACA,gBAAA,CAEA,wDAAA,CACA,iBAAA,CAGF,sBAIE,aAAA,CAHA,2BAAA,CACA,cAAA,CACA,gBAAA,CAGA,qCAAA,CADA,iBACA,CAGF,sBAGE,kBAAA,CACA,iBAAA,CAFA,UAAA,CAIA,kCAAA,CADA,iBAAA,CAJA,WAKA,CAGF,sBAEE,kBAAA,CACA,iBAAA,CAIA,QAAA,CADA,MAAA,CAFA,iBAAA,CACA,KAAA,CAJA,iCAMA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `_nMB4ksg6a38xJeAAiOg`,
	"hovered": `kUqsuxRs1JDCyySXus42`,
	"uploadLabel": `N3pb50JXFFnEzKcflPMF`,
	"uploadText": `EohhRNrgJj2jqi6i4q60`,
	"progressBar": `a0Xry3enp3mQcLCGIb5b`,
	"progress": `PU3EIKKxhKBoYw7OzfSu`
};
export default ___CSS_LOADER_EXPORT___;
