import {
  DiscountCodePool,
  FlowUI,
  Site,
  SMSCampaign,
  SMSSettings,
  URLGenerationSettingsInUse
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { Connection } from 'reactflow'
import { FlowEditorSettingsContextType } from '../../../types/flow-editor/flow-editor-settings.context.type'
import { initialFlowEditorSettings } from '../../../types/flow-editor/flow-editor-settings.initial'
import { FlowEditorErrorLevel } from '../../../utils/flow-editor/flow-editor-errors'

export interface FlowEditorLocalStorage {
  flow: FlowUI
  unsavedChanges: boolean
  isEverSaved: boolean
  isPreviewOn: boolean
  isAITextGeneratorWizardClosed: boolean
  isWelcomeFlowWarningModalClosed: boolean
}

interface Error {
  message: string
  level: FlowEditorErrorLevel
  discountCode?: string
}

export type MessageError = Error

export interface MessageMeta {
  errors: MessageError[]
  edited: boolean
  caretPosition: number
  textHTML?: string
  canvasHTML?: string
  AIGeneratedTextHTML?: string
}

export type FlowItemError = Error

export interface FlowItemMeta {
  messageMeta: Record<number, MessageMeta>
  edited: boolean
  discountCodesToValidate: string[]
  errors: FlowItemError[]
  logicMeta?: Record<string, string>
  isInQuietHours?: boolean
}

export const flowItemInitialMeta: FlowItemMeta = {
  messageMeta: [],
  edited: false,
  discountCodesToValidate: [],
  errors: [],
  isInQuietHours: false
}

export const messageInitialMeta: MessageMeta = {
  edited: false,
  errors: [],
  caretPosition: 0
}

export enum EditPanelType {
  FLOW_ITEM = 'flow-item',
  TRIGGER = 'trigger'
}
export enum InsertNodePanelType {
  INSERT_NODE_ON_CANVAS = 'insert-node-on-canvas',
  INSERT_NODE_BETWEEN_NODES = 'insert-node-between-nodes',
  INSERT_NODE_AFTER_SOURCE_HANDLE = 'insert-node-after-source-handle'
}

export enum SecondaryEditPanelType {
  LINK = 'link',
  EMOJI = 'emoji',
  DISCOUNT_CODE = 'discount-code',
  VARIABLE = 'variable',
  USER_VARIABLE = 'user-variable',
  SEND_TEST = 'send-test',
  AI_REPHRASE = 'ai-rephrase',
  IMAGE_EDITOR = 'image-editor',
  KEYWORD_REPLY = 'keyword-reply'
}

export enum AutoAlignOnNodesChangeStatus {
  NONE = 'none',
  FITVIEW = 'fitview',
  ALIGN = 'align'
}

export interface FlowError {
  message: string
  level: FlowEditorErrorLevel
  flowItemId?: string
  nodeId?: string
  messageIndex?: number
  edgeId?: string
}

export interface FlowMeta {
  unsavedChanges: boolean | null
  isEverSaved: boolean | null
  isFlowSaving: boolean
  isSMSCampaignSaving: boolean
  isToggling: boolean
  connectionInProgressFromSourceHandle?: string
  connectionDroppedOnCanvas?: boolean
  insertNodeConnection: Connection
  targetPosition: { x: number; y: number } | null
  warningCount: number
  errorCount: number
  errors: FlowError[]
  editingNodeId?: string
  editingFlowItemId?: string
  editingMessageIndex?: number
  editingVariableData?: string
  editPanelType?: EditPanelType
  secondaryEditPanelType?: SecondaryEditPanelType
  insertNodePanelType?: InsertNodePanelType | string
  optinToolId?: string
  invalidDiscountCodes: string[]
  validDiscountCodes: string[]
  expiredDiscountCodes: string[]
  settings: Partial<FlowEditorSettingsContextType>
  isPreviewOn: boolean
  autoAlignOnNodesChange: AutoAlignOnNodesChangeStatus
  isAITextGeneratorWizardClosed: boolean
  isLegalFlowItemDeletable: boolean
  isWelcomeFlowWarningModalClosed: boolean
}

const { variableTypeOptions, linkTypeOptions } = cloneDeep(initialFlowEditorSettings)

export const flowInitialMeta: FlowMeta = {
  unsavedChanges: null,
  isEverSaved: null,
  isFlowSaving: false,
  isSMSCampaignSaving: false,
  isToggling: false,
  insertNodeConnection: {
    source: null,
    sourceHandle: null,
    target: null,
    targetHandle: null
  },
  targetPosition: null,
  warningCount: 0,
  errorCount: 0,
  errors: [],
  editingNodeId: undefined,
  editPanelType: undefined,
  editingFlowItemId: undefined,
  editingMessageIndex: undefined,
  editingVariableData: undefined,
  secondaryEditPanelType: undefined,
  optinToolId: undefined,
  insertNodePanelType: undefined,
  invalidDiscountCodes: [],
  validDiscountCodes: [],
  expiredDiscountCodes: [],
  settings: { variableTypeOptions, linkTypeOptions },
  isPreviewOn: true,
  autoAlignOnNodesChange: AutoAlignOnNodesChangeStatus.NONE,
  isAITextGeneratorWizardClosed: false,
  isLegalFlowItemDeletable: false,
  isWelcomeFlowWarningModalClosed: false
}

export interface FormMeta {
  error: string | null
  edited: boolean | null
}

export interface SMSCampaignMeta {
  name: FormMeta
  scheduledFor: FormMeta
  isPoolSizeEnough?: boolean
  isAvailableCountEnough?: boolean
}

export const smsCampaignInitialMeta: SMSCampaignMeta = {
  name: {
    error: null,
    edited: null
  },
  scheduledFor: {
    error: null,
    edited: null
  }
}

export interface VariablePlaceholderData {
  urlGenerationSettings: URLGenerationSettingsInUse
  supportEmail: string
  siteName: string
  siteUrl: string
}

export interface FlowEditorState {
  isReadOnly: boolean
  site?: Site
  flow?: FlowUI
  flowMeta: FlowMeta
  flowItemsMetaById: {
    [flowItemId: string]: FlowItemMeta
  }
  variablePlaceholders: VariablePlaceholderData | null
  smsCampaign?: SMSCampaign
  smsCampaignMeta?: SMSCampaignMeta
  discountCodePools: DiscountCodePool[]
  quietHoursConfig?: SMSSettings.QuietHoursConfig
}

export const flowEditorInitialState: FlowEditorState = {
  isReadOnly: false,
  site: undefined,
  flow: undefined,
  flowMeta: cloneDeep(flowInitialMeta),
  flowItemsMetaById: {},
  variablePlaceholders: null,
  discountCodePools: [],
  quietHoursConfig: undefined
}
