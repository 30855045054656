import { Button } from 'antd'
import React from 'react'
import { ReactComponent as AlertTriangleIcon } from '../../../../../../static/ui-kit/icons/small/alert.svg'
import styles from './discount-code-error.component.scss'

interface DiscountCodeErrorProp {
  onRetry: () => void
}

export function DiscountCodeError(props: DiscountCodeErrorProp) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Error adding discount code</div>
        <AlertTriangleIcon />
      </div>
      <div className={styles.message}>
        We could not add the discount code to your message. Please try again or contact support.
      </div>
      <Button
        type='primary'
        className={styles.button}
        onClick={props.onRetry}
        data-testid='retry-button'
      >
        Try again
      </Button>
    </div>
  )
}
