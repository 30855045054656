import { FlowUI, HttpResponse, SequenceTag } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '../../components/app/query-client'
import { api } from '../../utils/api'
import { request } from '../../utils/request'
import { PatchResourceArgs, UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

type PatchFlowPayload = Pick<FlowUI, 'name'>

async function patch({ id, payload }: PatchResourceArgs<PatchFlowPayload>) {
  await api.patchFlow(id, payload)
}

export function useFlows(enabled?: boolean): UseResource<FlowUI[], Partial<FlowUI>> & {
  handlePatchMutate: (args: PatchResourceArgs<PatchFlowPayload>) => void
} {
  const queryName = [QUERY_NAME.flows]
  const { handleSettled, handleError } = useMutationHelpers(queryName)

  const {
    isLoading,
    data: flows,
    isError,
    refetch
  } = useQuery(
    queryName,
    async () => {
      const response = await request.get<HttpResponse<FlowUI[]>>('flows', {
        params: { tags: SequenceTag.SMS, without_sequence_items: true }
      })
      return response.data
    },
    {
      enabled
    }
  )

  const handlePatchMutate = useCallback((patchPayload: PatchResourceArgs<PatchFlowPayload>) => {
    queryClient.cancelQueries(queryName)

    const flowsQuery = queryClient.getQueryCache().find(queryName)
    const previousFlows = cloneDeep(flowsQuery.state.data as FlowUI[])
    const flows = cloneDeep(flowsQuery.state.data as FlowUI[])
    const previousFlow = flows.find((flow) => flow._id === patchPayload.id)

    if (!previousFlow) {
      return
    }

    previousFlow.name = patchPayload.payload.name
    queryClient.setQueryData(queryName, flows)

    return () => queryClient.setQueryData(queryName, previousFlows)
  }, [])

  const { mutateAsync: mutatePatchFlow } = useMutation<
    void,
    unknown,
    PatchResourceArgs<PatchFlowPayload>
  >(patch, {
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handleError
  })

  return {
    data: flows,
    isLoading,
    isError,
    refetch: refetch as any,
    patch: mutatePatchFlow,
    handlePatchMutate
  }
}
