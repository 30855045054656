import { Site } from '@ghostmonitor/recartapis'

export const STRIPE_PRICE_ID_SHOPIFY_SHOWCASE = 'shopify-showcase'

export function hasShopifyShowcase(site: Site | undefined): boolean {
  if (
    (!site?.stripe?.subscriptions || site.stripe.subscriptions.length === 0) &&
    site?.stripe?.paymentGateway === 'shopify'
  ) {
    return true
  }

  return (
    site?.stripe?.subscriptions?.some(
      (subscription) => subscription.priceId === STRIPE_PRICE_ID_SHOPIFY_SHOWCASE
    ) ?? false
  )
}
