import type { Moment } from 'moment'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { RangePickerSeparator } from '../../../../components/ui-kit/range-picker/range-picker-separator.component'
import {
  RangePicker,
  SelectedDateProps
} from '../../../../components/ui-kit/range-picker/range-picker.component'
import { Option, Select } from '../../../../components/ui-kit/select/select.component'
import { Tooltip } from '../../../../components/ui-kit/tooltip/tooltip.component'
import { ReactComponent as StatisticIcon } from '../../../../static/ui-kit/icons/small/statistic.svg'
import { AnalyticsContext } from '../../../../utils/analytics.context'
import { gaEvent } from '../../../../utils/analytics/google-analytics'
import { dateFormat } from '../../../../utils/utils'
import { TimeFrameFilterContext } from '../../contexts/time-frame-filter.context'
import styles from './time-frame-filter.component.scss'
import { TimeFrameOption, timeFrameOptions } from './time-frame-option.type'

export interface TimeFrameFilterProps {
  defaultTimeFrameFilter?: TimeFrameOption
  toolTipTitle?: string
}

export function TimeFrameFilter(props: TimeFrameFilterProps) {
  const defaultTimeFrameOptionKey = props.defaultTimeFrameFilter ?? TimeFrameOption.ALLTIME
  const [timeFrameOptionKey, setTimeFrameOptionKey] = useState(defaultTimeFrameOptionKey)
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const { setDateFrom, setDateTo, dateFrom, dateTo } = useContext(TimeFrameFilterContext)
  const { category } = useContext(AnalyticsContext)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)

  const [selectedDate, setSelectedDate] = useState<SelectedDateProps>({
    dateFrom: null,
    dateTo: null
  })

  const [isSelectVisible, setIsSelectVisible] = useState(false)

  function handleSelectDateChange(selectedDate: SelectedDateProps) {
    setSelectedDate(selectedDate)
    setDateFrom(selectedDate.dateFrom)
    setDateTo(selectedDate.dateTo)
  }

  useEffect(() => {
    if (isDateDisabled(dateFrom) || isDateDisabled(dateTo)) {
      setDefaultDate()
      return
    }

    const timeFrameOptionIndex = Object.values(timeFrameOptions).findIndex(
      (option) => option.from?.isSame(dateFrom) && option.to?.isSame(dateTo)
    )
    if (timeFrameOptionIndex !== -1) {
      const key = Object.keys(timeFrameOptions)[timeFrameOptionIndex] as TimeFrameOption
      setTimeFrameOptionKey(key)
    } else if (dateFrom === null && dateTo === null) {
      setTimeFrameOptionKey(TimeFrameOption.ALLTIME)
    } else {
      setTimeFrameOptionKey(TimeFrameOption.CALENDAR)
    }

    setSelectedDate({
      dateFrom,
      dateTo
    })
  }, [dateFrom, dateTo])

  function setDefaultDate() {
    const timeFrameOption = timeFrameOptions[defaultTimeFrameOptionKey]
    setTimeFrameOptionKey(defaultTimeFrameOptionKey)
    handleSelectDateChange({
      dateFrom: timeFrameOption.from,
      dateTo: timeFrameOption.to
    })
  }

  function handleCalendarChange(date: [Moment, Moment] | null) {
    if (date === null) {
      setDefaultDate()
      setIsCalendarVisible(false)
      return
    }

    const [dateFrom, dateTo] = date
    handleSelectDateChange({
      dateFrom: dateFrom ? dateFrom.utc().startOf('day') : null,
      dateTo: dateTo ? dateTo.utc().endOf('day') : null
    })

    if (dateFrom !== null && dateTo !== null) {
      setIsCalendarVisible(false)
    }
  }

  function handleSelectChange(value: TimeFrameOption) {
    gaEvent({
      category,
      action: 'used-timeframe-filter',
      label: value
    })

    setTimeFrameOptionKey(value)
    if (value === TimeFrameOption.CALENDAR) {
      if (!!selectedDate.dateFrom || !!selectedDate.dateTo) {
        handleSelectDateChange({
          dateFrom: null,
          dateTo: null
        })
      }
      setIsCalendarVisible(true)
    } else {
      const timeFrameOption = timeFrameOptions[value]
      handleSelectDateChange({
        dateFrom: timeFrameOption.from,
        dateTo: timeFrameOption.to
      })
    }
  }

  function isDateDisabled(current: Moment) {
    if (current) {
      const isCurrentInTheFuture = current > moment().utc().endOf('day')
      const isCurrentOlderThan18month =
        current < moment().utc().subtract(18, 'months').startOf('day')
      const isCurrentBeforeDateFrom = dateFrom && current < dateFrom
      const isCurrentAfterDateTo = dateTo && current > dateTo

      return (
        isCurrentInTheFuture ||
        isCurrentOlderThan18month ||
        isCurrentBeforeDateFrom ||
        isCurrentAfterDateTo
      )
    }
    return false
  }

  function getOptionLabel(option: { title: string; from: Moment; to: Moment }) {
    if (timeFrameOptionKey === TimeFrameOption.CALENDAR) {
      return (
        <>
          <span className={styles.rangeInput}>
            {`${selectedDate.dateFrom?.format(dateFormat)}`}
          </span>
          <RangePickerSeparator />
          <span className={styles.rangeInput}>{`${selectedDate.dateTo?.format(dateFormat)}`}</span>
        </>
      )
    }

    return option.title
  }

  function handleSelectVisibleChange(visible: boolean) {
    setIsSelectVisible(visible)
  }

  function handleTooltipVisible(tooltipVisible: boolean): void {
    if (!props.toolTipTitle || isSelectVisible) {
      setIsTooltipVisible(false)
    } else {
      if (tooltipVisible && isCalendarVisible) {
        setIsTooltipVisible(false)
      } else {
        setIsTooltipVisible(tooltipVisible)
      }
    }
  }

  function handleTimeFrameFilterClick() {
    setIsTooltipVisible(false)
  }

  const handleOpenChange = useCallback(
    (visible: boolean) => {
      if (!visible && timeFrameOptionKey === TimeFrameOption.CALENDAR) {
        setDefaultDate()
        setIsCalendarVisible(false)
      }
    },
    [timeFrameOptionKey]
  )

  if (isCalendarVisible) {
    return (
      <RangePicker
        defaultOpen
        autoFocus
        onCalendarChange={handleCalendarChange}
        disabledDate={isDateDisabled}
        onOpenChange={handleOpenChange}
      />
    )
  }

  return (
    <Tooltip
      title={props.toolTipTitle ?? ''}
      onOpenChange={handleTooltipVisible}
      open={isTooltipVisible}
    >
      <Select
        style={{ width: timeFrameOptionKey === TimeFrameOption.CALENDAR ? 264 : 150 }}
        value={timeFrameOptionKey}
        onSelect={handleSelectChange}
        suffixIcon={<StatisticIcon className='icon-xs text-secondary' />}
        listHeight={314}
        className={styles.timeFrameSelect}
        optionLabelProp='label'
        data-testid='time-frame-filter-select'
        onClick={handleTimeFrameFilterClick}
        onDropdownVisibleChange={handleSelectVisibleChange}
      >
        {Object.entries(timeFrameOptions).map(([key, option]) => (
          <Option value={key} key={key} label={getOptionLabel(option)}>
            {option.title}
          </Option>
        ))}
      </Select>
    </Tooltip>
  )
}
