// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NIKHOrl7sbqzkBEhc7cK{margin-bottom:6px}.NIKHOrl7sbqzkBEhc7cK .ant-btn-link{color:#ff4d4f;margin-top:10px}.NIKHOrl7sbqzkBEhc7cK .ant-btn-link span{text-decoration:underline}.CPn5SXiGFO36oai6ki0i{background-color:#e4e4ff;border:1px solid #d3adf7;border-radius:2px;box-sizing:border-box;color:#722ed1;line-height:20px;padding:1px 8px}.X6JQhCZJAaQrSqSZ3wDl{background-color:#fff1f0;border:1px solid #ffa39e;color:#f5222d}.Mpux_785ZPUpuM6V1U_z{font-size:12px;line-height:14px;margin-top:7px}.QfAGXSS6q7BmDR7guv9h{font-size:14px;line-height:22px;margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-added.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAGE,oCAEE,aC4BA,CD7BA,eC6BA,CD1BA,yCACE,yBAAA,CAMR,sBAIE,wBCiBO,CDhBP,wBAAA,CAEA,iBAAA,CADA,qBAAA,CAJA,aCqBO,CDpBP,gBAAA,CAFA,eAMA,CAGF,sBAEE,wBAAA,CACA,wBAAA,CAFA,aAEA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,cAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NIKHOrl7sbqzkBEhc7cK`,
	"codeBox": `CPn5SXiGFO36oai6ki0i`,
	"codeBoxError": `X6JQhCZJAaQrSqSZ3wDl`,
	"codeType": `Mpux_785ZPUpuM6V1U_z`,
	"info": `QfAGXSS6q7BmDR7guv9h`
};
export default ___CSS_LOADER_EXPORT___;
