import { AppDispatch } from '../../../../../store/create-store'
import type { SyncThunk } from '../../../../../store/redux-helpers'
import { selectEditorSequence } from '../../../../../store/selectors'
import { toggleSequenceThunk } from '../../../../../store/slices/sequence-editor/thunks/toggle-sequence.thunk'
import { isCustomWelcomeSequence } from '../../../../../utils/is-custom-welcome-sequence'
import { setSequenceEditorSetting } from '../../../hooks/use-sequence-editor-settings-context'
import { isMessengerSequence } from '../../../utils/assert-sequence-type'
import { initialSaveThunks } from '../../context/defaults/sequence-thunks'
import { SequenceEditorSettings } from '../../context/sequence-editor-settings.type'

function enableSequence(): SyncThunk {
  return async (dispatch: AppDispatch) => {
    await dispatch(toggleSequenceThunk(true))
  }
}

export function messengerThunk(): SyncThunk {
  return (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)

    /*
      Messenger Custom Welcome Sequence
    */
    if (isMessengerSequence(sequence) && isCustomWelcomeSequence(sequence)) {
      setSequenceEditorSetting(
        SequenceEditorSettings.saveThunks,
        [...initialSaveThunks, enableSequence],
        'isCustomWelcomeSequence - Messenger'
      )
    }
  }
}
