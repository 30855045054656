import React from 'react'
import { SegmentListView } from '@ghostmonitor/recartapis'
import { Tooltip } from '../../../../ui-kit/tooltip/tooltip.component'
import styles from './segment-option.component.scss'

interface SegmentOptionProps {
  segment: SegmentListView
  usingSequenceName: string | null | undefined
}

export function SegmentOption(props: SegmentOptionProps) {
  if (props.usingSequenceName != null) {
    return (
      <Tooltip
        title={`This segment is already in use by another active Custom Triggers flow: ${props.usingSequenceName}`}
        placement='left'
      >
        <div className={styles.disabledOption}>
          {props.segment.name} ({props.segment.size})
        </div>
      </Tooltip>
    )
  }

  if (props.segment.creationStatus === 'calculating') {
    return <div className={styles.disabledOption}>{props.segment.name} (Calculating)</div>
  }

  return (
    <>
      {props.segment.name} ({props.segment.size})
    </>
  )
}
