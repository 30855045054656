import React from 'react'
import styles from './short-link-variable.style.scss'

interface ShortLinkVariableProps {
  placeholder: string
  id: string
}

export function ShortLinkVariable(props: ShortLinkVariableProps) {
  return (
    <span
      className={styles.sequenceVariable}
      contentEditable={false}
      id={props.id}
      data-testid='variable-with-fallback'
    >
      {props.placeholder}
    </span>
  )
}
