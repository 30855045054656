// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pnnlmoosTlDqDceIUXdl{color:#595959;font-family:Lato,sans-serif;font-size:14px;width:188px}.pnnlmoosTlDqDceIUXdl .MfGrlxmCIdDpo6C55mCp{color:#595959}.pnnlmoosTlDqDceIUXdl .MfGrlxmCIdDpo6C55mCp input{color:#595959;font-size:12px;height:24px}.pnnlmoosTlDqDceIUXdl .jIUI653_kJfinbQjEbzG{font-size:9px;margin-top:2px}.pnnlmoosTlDqDceIUXdl .G9jInrcUV6VWDX2KYYEf{color:var(--recart-grey-5);font-size:14px}.pnnlmoosTlDqDceIUXdl .G9jInrcUV6VWDX2KYYEf span{text-decoration:underline}.pnnlmoosTlDqDceIUXdl .h5hj4azjwtmgrClAHgwP{font-size:14px;width:100%}.pnnlmoosTlDqDceIUXdl .ant-form-item-explain.ant-form-item-explain-error{font-size:var(--recart-font-size-tiny)}.pnnlmoosTlDqDceIUXdl .ant-form-item{margin-bottom:var(--recart-spacing-3)}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-link-shortening/link-shortening-popover.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAGE,aCYK,CDdL,2BAAA,CAGA,cAAA,CAFA,WAEA,CAEA,4CACE,aCQG,CDNH,kDAEE,aCIC,CDLD,cAAA,CAEA,WAAA,CAIJ,4CAEE,aAAA,CADA,cACA,CAGF,4CAEE,0BAAA,CADA,cACA,CAEA,iDACE,yBAAA,CAIJ,4CACE,cAAA,CACA,UAAA,CAIA,yEACE,sCAAA,CAGF,qCACE,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pnnlmoosTlDqDceIUXdl`,
	"input": `MfGrlxmCIdDpo6C55mCp`,
	"inputSuffix": `jIUI653_kJfinbQjEbzG`,
	"backButton": `G9jInrcUV6VWDX2KYYEf`,
	"saveButton": `h5hj4azjwtmgrClAHgwP`
};
export default ___CSS_LOADER_EXPORT___;
