import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  UploadAttachmentResponse,
  uploadFacebookAttachment
} from '../../../../routes/SequenceEditor/utils/facebook-api'
import { DashboardState } from '../../../dashboard.state'
import { selectFacebookPageConfig } from '../../../selectors'

interface UploadMediaToFacebookArgs {
  url: string
  mediaType: string
}

export const uploadMediaToFacebookThunk = createAsyncThunk<
  UploadAttachmentResponse,
  UploadMediaToFacebookArgs,
  {
    state: DashboardState
  }
>('sequence-editor/uploadMediaToFacebook', async (args, store) => {
  const state = store.getState()
  const token = selectFacebookPageConfig(state)?.accessToken
  return uploadFacebookAttachment(args.url, token, args.mediaType)
})
