import { arrayMove } from '@dnd-kit/sortable'
import {
  ButtonField,
  Channel,
  CopiedField,
  Device,
  EditorField,
  EmbeddedFormDesktopEmailSettings,
  EmbeddedFormMobileEmailSettings,
  EmbeddedFormSMSSettings,
  EmbeddedFormSMSWithEmailSettings,
  FormField,
  GeneralStyle,
  ImageField,
  LandingPage,
  LandingPageDesktopEmailSettings,
  LandingPageMobileEmailSettings,
  LandingPageSMSSettings,
  LandingPageSMSWithEmailSettings,
  MinimizedField,
  OptinTool,
  OptinToolCustomFont,
  OptinToolFont,
  OptinToolStep,
  Popup,
  PopupButtonField,
  PopupEmailSettings,
  PopupFont,
  PopupSMSSettings,
  PopupSMSWithEmailSettings,
  PopupSettings,
  SubscriberDetail,
  TextField,
  UserActionRule
} from '@ghostmonitor/recartapis'
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { isObjectLike, omit } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { GeotargetingCountry } from '../../../hooks/use-geotargeting-countries'
import { OptinToolMenuItem } from '../../../routes/optin-tools/components/editor/left-panel/left-panel.component'
import { convertFieldSettingsToGeneralStyle } from '../../../routes/optin-tools/components/editor/right-panel/general-style-define/utils'
import { customerChatMenuItems } from '../../../routes/optin-tools/customer-chat/editor/utils/menu-items'
import {
  EmbeddedFormMenuItemSlug,
  getEmbeddedFormMenuItems
} from '../../../routes/optin-tools/embedded-form/editor/menu-items'
import {
  LandingPageMenuItemSlug,
  getLandingPageMenuItems,
  getMenuSlugForDevice
} from '../../../routes/optin-tools/landing-page/editor/menu-items'
import { OptinType } from '../../../routes/optin-tools/popup/create/create-popup.component'
import { getPopupMenuItems } from '../../../routes/optin-tools/popup/editor/menu-items'
import { getDefaultCustomCSSClasses } from '../../../routes/optin-tools/popup/editor/preview/components/utils/custom-css'
import { resetAllSettingsByGeneralStyle } from '../../../routes/optin-tools/utils/general-style-apply-all'
import {
  getActiveAccordionItemSlug,
  getCopiedFieldName,
  getFirstMenuItem,
  isEmailOnlyOptinTool,
  isSMSOptinTool
} from '../../../routes/optin-tools/utils/helpers'
import { patchLocalOptinTool } from '../../../routes/optin-tools/utils/localstorage'
import {
  validateAdditionalImage,
  validateCustomerChatGreeting,
  validateFormItems,
  validateSequenceId,
  validateURLRules
} from '../../../routes/optin-tools/utils/validators'
import { DeepPartial } from '../../../types/utils.type'
import {
  getDefaultCheckboxGroupField,
  getDefaultDateInputField,
  getDefaultDropdownField,
  getDefaultNumberInputField,
  getDefaultRadioGroupField,
  getDefaultTextInputField,
  getDefaultTextLocationInputField
} from '../../../utils/form/form-helper'
import { sequenceSaved } from '../sequence-editor/sequence-editor.actions'
import {
  selectOptinTool,
  selectOptinToolMeta,
  selectOptinToolSettings
} from './optin-tool-editor.selectors'
import { getCustomerChatTemplate } from './templates/customer-chat'
import { getEmbeddedFormSettingsTemplate } from './templates/embedded-form'
import { getLandingPageSettingsTemplate } from './templates/landing-page'
import {
  initCustomerChatErrorMeta,
  initEmbeddedFormErrorMeta,
  initLandingPageErrorMeta,
  initPopupErrorMeta
} from './templates/optin-tool-error-meta'
import { getPopupSettingsTemplate, getPopupTeaserSettingsTemplate } from './templates/popup'
import { loadEditorThunk } from './thunks/load-editor.thunk'

export type InputState = 'defaultState' | 'activeState' | 'errorState'

export interface OptinToolMeta {
  saved: boolean
  isLoading: boolean
  isPreviewInFullscreen: boolean
  errors: OptinToolMetaErrors
  menuItems: OptinToolMenuItem[]
  activeMenuItem: OptinToolMenuItem | null
  activeAccordionItem: string | null
  activePreviewDevice?: Device
  inputState?: InputState
  preview: PreviewUrlSettings
  previewScale: number | null
  showActivatedModalForLandingPageId?: string
  showActivatedModalForEmbeddedFormId?: string
  recentlyUsedFontTypes: string[]
  temporaryGeneralStyle: GeneralStyle | null
}

export interface OptinToolMetaErrorField {
  errorMessage: string
  errorValues?: string[] | { [key: string]: string }
  fieldErrors?: { [key: string]: string }
}

export interface OptinToolMetaErrors {
  [view: string]: {
    [accordionSlug: string]: {
      [fieldName: string]: OptinToolMetaErrorField | OptinToolMetaErrorField[]
    }
  }
}

export interface OptinToolEditorState<TOptinTool extends OptinTool> {
  optinTool: TOptinTool | null
  optinToolMeta: OptinToolMeta
}

export interface PreviewUrlSettings {
  url: string
  isEnabled: boolean
}

export interface AccordionError {
  [fieldName: string]: OptinToolMetaErrorField
}

export interface MenuItemError {
  [accordionSlug: string]: {
    [fieldName: string]: OptinToolMetaErrorField
  }
}

export const optinToolEditorInitialState: OptinToolEditorState<OptinTool> = {
  optinTool: null,
  optinToolMeta: {
    saved: true,
    isLoading: false,
    isPreviewInFullscreen: false,
    errors: {},
    menuItems: [],
    activeMenuItem: null,
    activeAccordionItem: null,
    activePreviewDevice: 'mobile',
    inputState: 'defaultState',
    preview: { url: '', isEnabled: false },
    previewScale: null,
    recentlyUsedFontTypes: [],
    temporaryGeneralStyle: null
  }
}

function isEditorUpdateAction() {
  return isAnyOf(
    updatePopupSettings,
    updateOptinToolSettingsField,
    updateOptinToolField,
    updateLandingPageLink,
    addFormItem,
    updateFormItem,
    deleteFormItem,
    reorderFormItems,
    updateFormInputPlaceholderColor,
    copyOptinToolSettingsField,
    copyAdditionalImageField,
    resetCustomCSS,
    sequenceSaved,
    createTeaserSettings,
    toggleTeaserStep,
    updateGeneralStyleField,
    overwriteGeneralStyleSettings,
    resetToGeneralStyleSettings,
    resetAllPopupSettingsByGeneralStyle
  )
}

// these values should be copied even if the destination settings doesn't have them
const keysToMerge = [
  'teaser',
  'secondaryImage',
  'url',
  'location',
  'userAction',
  'shadow',
  'generalStyles',
  'networkOptin'
]

const editorFieldTypes = [
  'image',
  'input',
  'optional-input',
  'text',
  'textarea',
  'button',
  'minimized',
  'resend-button'
]

function isEditorField(field: any): field is EditorField {
  return isObjectLike(field) && 'type' in field && editorFieldTypes.includes(field.type)
}

function getMinimizedSettings(
  templateSettings: MinimizedField,
  originalSettings: MinimizedField
): MinimizedField {
  if (templateSettings.oneTimePasswordStepText) {
    const oneTimePasswordStepText =
      originalSettings.oneTimePasswordStepText ?? templateSettings.oneTimePasswordStepText
    return { ...originalSettings, oneTimePasswordStepText }
  } else {
    return omit(originalSettings, 'oneTimePasswordStepText')
  }
}

function mergePopupSettings(templateSettings: any, originalSettings: any) {
  // shadow can be null
  if (originalSettings === null) {
    return
  }

  // TODO: [general-styles] remove after general styles release, popups won't have copied fields
  if (
    (templateSettings as CopiedField).type === 'copied' &&
    (originalSettings as CopiedField).type !== 'copied'
  ) {
    Object.assign(templateSettings, originalSettings)
    delete (templateSettings as CopiedField).copyOf
    return
  }

  Object.keys(originalSettings).forEach((key) => {
    if (isEditorField(originalSettings[key]) && isEditorField(templateSettings[key])) {
      if (
        templateSettings[key].type === 'minimized' &&
        originalSettings[key].type === 'minimized'
      ) {
        templateSettings[key] = getMinimizedSettings(templateSettings[key], originalSettings[key])
      } else {
        templateSettings[key] = originalSettings[key]
      }
      return
    }

    if (!(key in templateSettings || keysToMerge.includes(key))) {
      return
    }

    if (
      typeof templateSettings[key] === 'object' &&
      templateSettings[key] !== null &&
      !Array.isArray(templateSettings[key])
    ) {
      mergePopupSettings(templateSettings[key], originalSettings[key])
      return
    }

    templateSettings[key] = originalSettings[key]
  })
}

// Duplicate from popup-preview, should be deleted after general style release
export function resolveCopiedField<T>(
  settings: PopupSettings,
  key: OptinToolStep,
  path: string
): T {
  const field = get(settings[key], path)

  if (field.type === 'copied') {
    if (field.copyOf === 'sms' || field.copyOf === 'messenger' || field.copyOf === 'email') {
      return resolveCopiedField(settings, field.copyOf, path)
    }

    throw Error('unsupported copyField')
  }

  return field
}

function resolveAllCopiedFields(settings: PopupSettings) {
  if (settings.messenger) {
    settings.messenger.logo = resolveCopiedField<ImageField>(settings, 'messenger', 'logo')
    settings.messenger.headingTop = resolveCopiedField<TextField>(
      settings,
      'messenger',
      'headingTop'
    )
    settings.messenger.headingMiddle = resolveCopiedField<TextField>(
      settings,
      'messenger',
      'headingMiddle'
    )
    settings.messenger.headingBottom = resolveCopiedField<TextField>(
      settings,
      'messenger',
      'headingBottom'
    )
  }

  if (settings.sms) {
    settings.sms.logo = resolveCopiedField<ImageField>(settings, 'sms', 'logo')
    settings.sms.headingTop = resolveCopiedField<TextField>(settings, 'sms', 'headingTop')
    settings.sms.headingMiddle = resolveCopiedField<TextField>(settings, 'sms', 'headingMiddle')
    settings.sms.headingBottom = resolveCopiedField<TextField>(settings, 'sms', 'headingBottom')
  }
}

export function getMergedSettings(
  templateSettings: PopupSettings,
  originalSettings: PopupSettings
): PopupSettings {
  const mutableTemplateSettings = cloneDeep(templateSettings)
  const mutableOriginalSettings = cloneDeep(originalSettings)

  mergePopupSettings(mutableTemplateSettings, mutableOriginalSettings)

  return mutableTemplateSettings
}

function mergeButtonStyles(
  to: ButtonField | PopupButtonField,
  from: ButtonField | PopupButtonField
) {
  to.backgroundColor = from.backgroundColor
  to.shape = from.shape
  to.textColor = from.textColor
}

export function mergeUserActions(to: UserActionRule, from: UserActionRule, isFromMobile: boolean) {
  const desktopOnlyInteractions = ['leaving-view-port']
  to.type = from.type

  switch (from.type) {
    case 'exit-intent':
      // @ts-expect-error: TS only infers type of from but not type of to
      to.values = isFromMobile
        ? from.values.filter((trigger) => !desktopOnlyInteractions.includes(trigger))
        : from.values
      // @ts-expect-error: TS only infers type of from but not type of to
      to.behaviorWithMinimized = from.behaviorWithMinimized
      // @ts-expect-error: value does not exist on UserExitIntentRule
      delete to.value
      break
    case 'delay':
      // @ts-expect-error: value exist on UserDelayRule
      to.value = from.value
      break
  }
}

function getPopupSteps(popup: Popup, optinType: OptinType): OptinToolStep[] {
  const steps: OptinToolStep[] = [...popup.steps]

  const isTeaserEnabled = popup.settings === null || popup.settings.teaser?.isEnabled === true

  if (isTeaserEnabled) {
    steps.unshift('teaser')
  }

  if (optinType === 'in-popup-confirmation') {
    steps.push('oneTimePassword')
  }

  const shouldHaveSuccessStep =
    (isSMSOptinTool(popup) && popup.devices.includes('desktop')) ||
    popup.messagingType === 'one-way' ||
    optinType === 'in-popup-confirmation' ||
    isEmailOnlyOptinTool(popup)

  if (shouldHaveSuccessStep) {
    steps.push('success')
  }

  return steps
}

const optinToolEditorSlice = createSlice({
  name: 'optinToolEditor',
  initialState: optinToolEditorInitialState,
  reducers: {
    createOptinTool(
      state,
      action: PayloadAction<{
        optinTool: OptinTool
        smsCountries?: GeotargetingCountry[]
        siteUrl?: string
        siteId?: string
        optinType?: OptinType
        isNetworkOptinEnabled?: boolean
      }>
    ) {
      const { optinTool, smsCountries, siteUrl, optinType, siteId, isNetworkOptinEnabled } =
        action.payload

      state.optinTool = cloneDeep(optinTool)
      state.optinToolMeta.saved = false

      if (state.optinTool.type === 'popup') {
        state.optinTool.steps = getPopupSteps(state.optinTool, optinType)
        state.optinTool.settings = getPopupSettingsTemplate({
          steps: state.optinTool.steps,
          devices: state.optinTool.devices,
          isNetworkOptinEnabled,
          messagingType: state.optinTool.messagingType,
          smsCountries,
          siteId
        })
        state.optinToolMeta.errors = initPopupErrorMeta(state.optinTool.steps)

        state.optinToolMeta.menuItems = getPopupMenuItems(state.optinTool)
      }
      if (state.optinTool.type === 'landing-page') {
        state.optinTool.settings = getLandingPageSettingsTemplate(
          state.optinTool.steps,
          smsCountries,
          siteUrl,
          state.optinTool.messagingType
        )
        state.optinToolMeta.errors = initLandingPageErrorMeta(state.optinTool.steps)

        state.optinToolMeta.menuItems = getLandingPageMenuItems(state.optinTool)
      }
      if (state.optinTool.type === 'embedded-form') {
        state.optinTool.settings = getEmbeddedFormSettingsTemplate(
          state.optinTool.steps,
          smsCountries,
          state.optinTool.messagingType
        )

        state.optinToolMeta.errors = initEmbeddedFormErrorMeta(state.optinTool.steps)

        state.optinToolMeta.menuItems = getEmbeddedFormMenuItems(state.optinTool)
      }
      if (state.optinTool.type === 'customer-chat') {
        state.optinTool.settings = getCustomerChatTemplate()
        state.optinToolMeta.menuItems = customerChatMenuItems
        state.optinToolMeta.activePreviewDevice = 'mobile'
        state.optinToolMeta.errors = initCustomerChatErrorMeta()
      }

      state.optinToolMeta.activeMenuItem = getFirstMenuItem(state.optinToolMeta.menuItems)
      state.optinToolMeta.activeAccordionItem = getActiveAccordionItemSlug(
        state.optinToolMeta.activeMenuItem.slug,
        state.optinToolMeta.activeMenuItem.items?.[0]
      )
    },
    loadOptinTool(
      state,
      action: PayloadAction<{ optinTool: OptinTool; optinToolMeta: OptinToolMeta }>
    ) {
      state.optinTool = action.payload.optinTool
      state.optinToolMeta = action.payload.optinToolMeta
    },
    duplicateOptinTool(
      state,
      action: PayloadAction<{
        optinTool: OptinTool
        smsCountries: GeotargetingCountry[]
        optinType?: OptinType
        isPopupGeneralStylingEnabled?: boolean
        isNetworkOptinEnabled?: boolean
      }>
    ) {
      const {
        optinTool,
        smsCountries,
        optinType,
        isPopupGeneralStylingEnabled,
        isNetworkOptinEnabled
      } = action.payload

      state.optinTool = cloneDeep(optinTool)
      state.optinToolMeta.saved = false

      if (state.optinTool.type === 'popup') {
        state.optinTool.steps = getPopupSteps(state.optinTool, optinType)

        const templateSettings = getPopupSettingsTemplate({
          steps: state.optinTool.steps,
          devices: optinTool.devices,
          isNetworkOptinEnabled,
          messagingType: state.optinTool.messagingType,
          smsCountries
        })

        if (isPopupGeneralStylingEnabled) {
          resolveAllCopiedFields(state.optinTool.settings)
        }

        if (state.optinTool.settings.generalStyles) {
          const newGeneralStyleSettings = templateSettings.generalStyles.filter(
            (templateGeneralStyles) =>
              !(state.optinTool.settings as PopupSettings).generalStyles.some(
                (generalStyle) => generalStyle.slug === templateGeneralStyles.slug
              )
          )

          if (newGeneralStyleSettings.length > 0) {
            state.optinTool.settings.generalStyles.push(...newGeneralStyleSettings)
          }
        }

        state.optinTool.settings = getMergedSettings(templateSettings, state.optinTool.settings)

        mergeUserActions(
          state.optinTool.settings.displayRules.userAction,
          (optinTool as Popup).settings.displayRules.userAction,
          state.optinTool.devices.includes('mobile')
        )

        if (state.optinTool.devices.includes('desktop')) {
          state.optinTool.settings.mobilePosition = templateSettings.mobilePosition

          if (state.optinTool.steps.includes('sms')) {
            // the SMS success button styles could use the SMS opt-in button styles
            if (!state.optinTool.steps.includes('success')) {
              mergeButtonStyles(
                state.optinTool.settings.success.button,
                state.optinTool.settings.sms.button
              )

              if (state.optinTool.steps.includes('email')) {
                // the SMS opt-in phone number input field could use the email opt-in email input field
                mergePopupSettings(
                  state.optinTool.settings.sms.input,
                  state.optinTool.settings.email.input
                )
              }
            }

            // update success copy to match the optin type
            if (
              ((optinTool as Popup).settings.oneTimePassword &&
                optinType === 'reply-sms-consent') ||
              (!(optinTool as Popup).settings.oneTimePassword &&
                optinType === 'in-popup-confirmation')
            ) {
              state.optinTool.settings.success.logo = templateSettings.success.logo
              state.optinTool.settings.success.description.text =
                templateSettings.success.description.text
            }
          }
        }
        if (state.optinTool.devices.includes('mobile')) {
          state.optinTool.settings.additionalImage = templateSettings.additionalImage
        }

        state.optinToolMeta.menuItems = getPopupMenuItems(state.optinTool)
        state.optinToolMeta.errors = initPopupErrorMeta(state.optinTool.steps)
      }

      if (optinTool.type === 'landing-page') {
        state.optinToolMeta.menuItems = getLandingPageMenuItems(optinTool)
        state.optinToolMeta.errors = initLandingPageErrorMeta(optinTool.steps)
      }

      if (optinTool.type === 'embedded-form') {
        state.optinToolMeta.menuItems = getEmbeddedFormMenuItems(optinTool)
        state.optinToolMeta.errors = initEmbeddedFormErrorMeta(optinTool.steps)
      }

      state.optinToolMeta.activeMenuItem = getFirstMenuItem(state.optinToolMeta.menuItems)
      state.optinToolMeta.activeAccordionItem = getActiveAccordionItemSlug(
        state.optinToolMeta.activeMenuItem.slug,
        state.optinToolMeta.activeMenuItem.items?.[0]
      )
    },
    updateOptinToolField(
      state,
      action: PayloadAction<{ value: string | string[]; fieldName: string }>
    ) {
      state.optinTool[action.payload.fieldName] = action.payload.value
    },
    updateLandingPageLink(
      state,
      action: PayloadAction<{ value: LandingPage['link']; fieldName: string }>
    ) {
      state.optinTool[action.payload.fieldName] = action.payload.value
    },
    updatePopupSettings(
      state: OptinToolEditorState<Popup>,
      action: PayloadAction<{ popupSettings: PopupSettings }>
    ) {
      state.optinTool.settings = action.payload.popupSettings
    },
    updateOptinToolSettingsField(state, action: PayloadAction<{ fieldName: string; value: any }>) {
      const settings = selectOptinToolSettings(state)
      set(settings, action.payload.fieldName, action.payload.value)
    },
    copyOptinToolSettingsField(
      state,
      action: PayloadAction<{
        copyFrom: Channel | Device
        fieldName: string
        isEnabled: boolean
      }>
    ) {
      const { copyFrom, fieldName, isEnabled } = action.payload
      const settings = selectOptinToolSettings(state)
      if (isEnabled) {
        set(settings, fieldName, {
          type: 'copied',
          copyOf: copyFrom
        })
      } else {
        const copiedFieldName = getCopiedFieldName(fieldName, copyFrom)
        const copiedSettings = get(settings, copiedFieldName)
        if (copiedSettings?.type === 'copied') {
          const copyOfCopy = get(
            settings,
            getCopiedFieldName(copiedFieldName, copiedSettings.copyOf)
          )
          set(settings, fieldName, copyOfCopy)
        } else {
          set(settings, fieldName, copiedSettings)
        }
      }
    },
    copyAdditionalImageField(
      state,
      action: PayloadAction<{
        copyFrom: string
        fieldName: string
        position: string
        isEnabled: boolean
      }>
    ) {
      const { copyFrom, fieldName, position, isEnabled } = action.payload
      const settings = selectOptinToolSettings(state)
      if (isEnabled) {
        set(settings, fieldName, {
          image: {
            type: 'copied',
            copyOf: copyFrom
          },
          position
        })
      } else {
        const copiedSettings = get(settings, getCopiedFieldName(fieldName, copyFrom))
        set(settings, fieldName, copiedSettings)
      }
    },
    createTeaserSettings(state) {
      const popup = selectOptinTool(state) as Popup
      popup.settings.teaser = getPopupTeaserSettingsTemplate(popup.devices.includes('desktop'))
      popup.steps.unshift('teaser')
    },
    toggleTeaserStep(state, action: PayloadAction<{ isEnabled: boolean }>) {
      const popup = selectOptinTool(state) as Popup
      popup.settings.teaser.isEnabled = action.payload.isEnabled

      if (action.payload.isEnabled) {
        popup.steps.unshift('teaser')
      } else {
        const index = popup.steps.findIndex((step) => step === 'teaser')
        if (index !== -1) {
          popup.steps.splice(index, 1)
        }
      }
    },
    addFormItem(
      state,
      action: PayloadAction<{
        subscriberDetail: SubscriberDetail
        step: OptinToolStep
        device?: Device
        isPopupGeneralStylingEnabled?: boolean
      }>
    ) {
      const { subscriberDetail, step, device, isPopupGeneralStylingEnabled } = action.payload

      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)
      const { settings } = optinTool

      let newFormItem: FormField
      switch (subscriberDetail.type) {
        case 'text-input':
          newFormItem = getDefaultTextInputField(subscriberDetail)
          break
        case 'number-input':
          newFormItem = getDefaultNumberInputField(subscriberDetail)
          break
        case 'date-input':
          newFormItem = getDefaultDateInputField(subscriberDetail)
          break
        case 'checkbox-group':
          newFormItem = getDefaultCheckboxGroupField(subscriberDetail)
          break
        case 'radio-group':
          newFormItem = getDefaultRadioGroupField(subscriberDetail)
          break
        case 'dropdown':
          newFormItem = getDefaultDropdownField(subscriberDetail)
          break
        case 'text-location-input':
          newFormItem = getDefaultTextLocationInputField(subscriberDetail)
          break
        default:
          throw new Error(`Unknown form item type: ${subscriberDetail.type}`)
      }

      if (optinTool.type === 'popup') {
        if (!settings[step].form) {
          settings[step].form = {
            items: [],
            validationRules: {
              required: {
                message: 'Please fill in this input field'
              }
            }
          }
        }

        settings[step].form.items.push(newFormItem)

        if (settings[step].form.items.length === 1) {
          optinToolMeta.menuItems = getPopupMenuItems(optinTool)
        }
      } else if (optinTool.type === 'landing-page') {
        if (!settings[device][step].form) {
          settings[device][step].form = {
            items: [],
            validationRules: {
              required: {
                message: 'Please fill in this input field'
              }
            }
          }
        }
        settings[device][step].form.items.push(newFormItem)

        if (settings[device][step].form.items.length === 1) {
          optinToolMeta.menuItems = getLandingPageMenuItems(optinTool)
        }
      } else if (optinTool.type === 'embedded-form') {
        if (!settings[device][step].form) {
          settings[device][step].form = {
            items: [],
            validationRules: {
              required: {
                message: 'Please fill in this input field'
              }
            }
          }
        }
        settings[device][step].form.items.push(newFormItem)

        if (settings[device][step].form.items.length === 1) {
          optinToolMeta.menuItems = getEmbeddedFormMenuItems(optinTool)
        }
      }
    },
    updateFormItem(
      state,
      action: PayloadAction<{
        index: number
        step: OptinToolStep
        newValues: FormField
        device?: Device
      }>
    ) {
      const { index, step, newValues, device } = action.payload
      const optinTool = selectOptinTool(state)
      const { settings } = optinTool

      if (optinTool.type === 'popup') {
        settings[step].form.items[index] = newValues
      } else if (optinTool.type === 'landing-page' || optinTool.type === 'embedded-form') {
        settings[device][step].form.items[index] = newValues
      }
    },
    deleteFormItem(
      state,
      action: PayloadAction<{
        index: number
        step: OptinToolStep
        device?: Device
        isPopupGeneralStylingEnabled?: boolean
      }>
    ) {
      const { index, step, device, isPopupGeneralStylingEnabled } = action.payload
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)
      const { settings } = optinTool
      const formItems =
        optinTool.type === 'popup' ? settings[step].form.items : settings[device][step].form.items
      formItems.splice(index, 1)

      if (optinTool.type === 'popup') {
        const formItemErrors = optinToolMeta.errors[step].form.items as OptinToolMetaErrorField[]
        if (formItemErrors) {
          formItemErrors.splice(index, 1)
        }
        if (formItems.length === 0) {
          optinToolMeta.menuItems = getPopupMenuItems(optinTool)
        }
      } else if (optinTool.type === 'landing-page') {
        const errorMetaField = getMenuSlugForDevice(device, step as LandingPageMenuItemSlug)
        const formItemErrors = optinToolMeta.errors[errorMetaField].form
          .items as OptinToolMetaErrorField[]
        if (formItemErrors) {
          formItemErrors.splice(index, 1)
        }

        if (formItems.length === 0) {
          optinToolMeta.menuItems = getLandingPageMenuItems(optinTool)
        }
      } else if (optinTool.type === 'embedded-form') {
        const errorMetaField = getMenuSlugForDevice(device, step as EmbeddedFormMenuItemSlug)
        const formItemErrors = optinToolMeta.errors[errorMetaField].form
          .items as OptinToolMetaErrorField[]
        if (formItemErrors) {
          formItemErrors.splice(index, 1)
        }

        if (formItems.length === 0) {
          optinToolMeta.menuItems = getEmbeddedFormMenuItems(optinTool)
        }
      }
    },
    reorderFormItems(
      state,
      action: PayloadAction<{
        oldIndex: number
        newIndex: number
        step: OptinToolStep
        device?: Device
      }>
    ) {
      const { oldIndex, newIndex, step, device } = action.payload

      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)
      const { settings } = optinTool

      if (optinTool.type === 'popup') {
        const formItems = settings[step].form.items

        const newItems = arrayMove(formItems, oldIndex, newIndex)
        settings[step].form.items = newItems

        if (
          optinToolMeta.errors[step].form.items[oldIndex] &&
          optinToolMeta.errors[step].form.items[newIndex]
        ) {
          const newErrors = arrayMove(
            optinToolMeta.errors[step].form.items as OptinToolMetaErrorField[],
            oldIndex,
            newIndex
          )
          optinToolMeta.errors[step].form.items = newErrors
        }
      } else if (optinTool.type === 'landing-page' || optinTool.type === 'embedded-form') {
        const formItems = settings[device][step].form.items

        const newItems = arrayMove(formItems, oldIndex, newIndex)
        settings[device][step].form.items = newItems

        const errorMetaField = getMenuSlugForDevice(
          action.payload.device,
          step as LandingPageMenuItemSlug | EmbeddedFormMenuItemSlug
        )
        if (
          optinToolMeta.errors[errorMetaField].form.items[oldIndex] &&
          optinToolMeta.errors[errorMetaField].form.items[newIndex]
        ) {
          const newErrors = arrayMove(
            optinToolMeta.errors[errorMetaField].form.items as OptinToolMetaErrorField[],
            oldIndex,
            newIndex
          )
          optinToolMeta.errors[errorMetaField].form.items = newErrors
        }
      }
    },
    updateFormInputPlaceholderColor(state, action: PayloadAction<{ color: string }>) {
      const optinTool = selectOptinTool(state)
      const { steps, devices } = optinTool as Popup | LandingPage

      if (optinTool.type === 'popup') {
        steps.forEach((step) => {
          const { form } = optinTool.settings[step] as
            | PopupSMSSettings
            | PopupEmailSettings
            | PopupSMSWithEmailSettings

          if (form) {
            form.items.forEach((item) => {
              if ('placeholderColor' in item) {
                item.placeholderColor = action.payload.color
              }
            })
          }
        })
      } else if (optinTool.type === 'landing-page') {
        steps.forEach((step) => {
          devices.forEach((device) => {
            const { form } = optinTool.settings[device][step] as
              | LandingPageSMSSettings
              | LandingPageSMSWithEmailSettings
              | LandingPageDesktopEmailSettings
              | LandingPageMobileEmailSettings
              | EmbeddedFormMobileEmailSettings
              | EmbeddedFormSMSSettings
              | EmbeddedFormSMSWithEmailSettings
              | EmbeddedFormDesktopEmailSettings

            if (form) {
              form.items.forEach((item) => {
                if ('placeholderColor' in item) {
                  item.placeholderColor = action.payload.color
                }
              })
            }
          })
        })
      }
    },
    updateErrorMetaField(
      state,
      action: PayloadAction<{
        menuSlug: string
        accordionSlug: string
        fieldName: string
        value: OptinToolMetaErrorField
      }>
    ) {
      const optinToolMeta = selectOptinToolMeta(state)
      const { menuSlug, accordionSlug, fieldName, value } = action.payload

      optinToolMeta.errors = {
        ...optinToolMeta.errors,
        [menuSlug]: {
          ...optinToolMeta.errors[menuSlug],
          [accordionSlug]: {
            ...optinToolMeta.errors[menuSlug]?.[accordionSlug],
            [fieldName]: value
          }
        }
      }
    },
    updateErrorMeta(state, action: PayloadAction<{ errors: any }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      const { errors } = action.payload

      optinToolMeta.errors = {
        ...optinToolMeta.errors,
        ...errors
      }
    },
    selectMenuItem(state, action: PayloadAction<{ menuItem: OptinToolMenuItem }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      const { menuItem } = action.payload

      optinToolMeta.activeMenuItem = menuItem
      optinToolMeta.activeAccordionItem = getActiveAccordionItemSlug(
        menuItem.slug,
        menuItem.items?.[0]
      )
      optinToolMeta.inputState = 'defaultState'
      optinToolMeta.temporaryGeneralStyle = null
    },
    showLandingPageActivatedModal(state, action: PayloadAction<{ optinToolId: string }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.showActivatedModalForLandingPageId = action.payload.optinToolId
    },
    hideLandingPageActivatedModal(state: OptinToolEditorState<OptinTool>) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.showActivatedModalForLandingPageId = null
    },
    showEmbeddedFormActivatedModal(state, action: PayloadAction<{ optinToolId: string }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.showActivatedModalForEmbeddedFormId = action.payload.optinToolId
    },
    hideEmbeddedFormActivatedModal(state: OptinToolEditorState<OptinTool>) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.showActivatedModalForEmbeddedFormId = null
    },
    updateActiveAccordionItem(state, action: PayloadAction<{ slug: string }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.activeAccordionItem = action.payload.slug
      optinToolMeta.inputState = 'defaultState'
    },
    updateActivePreviewDevice(state, action: PayloadAction<{ device: Device }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.activePreviewDevice = action.payload.device
    },
    updateInputState(state, action: PayloadAction<{ inputState: InputState }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.inputState = action.payload.inputState
    },
    updatePreview(state, action: PayloadAction<{ previewUrl: PreviewUrlSettings }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.preview = action.payload.previewUrl
    },
    togglePreviewFullscreenMode(state, action: PayloadAction<{ visible: boolean }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.isPreviewInFullscreen = action.payload.visible
    },
    updatePreviewScale(state, action: PayloadAction<{ scale: number }>) {
      const optinToolMeta = selectOptinToolMeta(state)

      optinToolMeta.previewScale = action.payload.scale
    },
    openStyleSettings(
      state,
      action: PayloadAction<{
        menuItemSlug: string
        accordionSlug: string
      }>
    ) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      let menuItems: OptinToolMenuItem[]
      if (optinTool.type === 'popup') {
        menuItems = getPopupMenuItems(optinTool)
      } else if (optinTool.type === 'landing-page') {
        menuItems = getLandingPageMenuItems(optinTool)
      } else if (optinTool.type === 'embedded-form') {
        menuItems = getEmbeddedFormMenuItems(optinTool)
      }

      const menuItem = menuItems.find((menuItem) => menuItem.slug === action.payload.menuItemSlug)
      if (menuItem) {
        optinToolMeta.activeMenuItem = menuItem
      }
      optinToolMeta.activeAccordionItem = getActiveAccordionItemSlug(
        action.payload.menuItemSlug,
        action.payload.accordionSlug
      )
    },
    resetCustomCSS(state) {
      if (state.optinTool.type === 'popup') {
        state.optinTool.settings.customCSS = getDefaultCustomCSSClasses(state.optinTool.steps)
      }
    },
    validateURLRulesField(state) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type === 'popup') {
        const urlRulesError = validateURLRules(optinTool.settings.displayRules.url)
        if (!optinToolMeta.errors.settings?.displayRules?.url) {
          optinToolMeta.errors = initPopupErrorMeta(optinTool.steps)
        }
        optinToolMeta.errors.settings.displayRules.url = urlRulesError
      }
    },
    validateAdditionalImageField(state, action: PayloadAction<{ device: Device }>) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type === 'popup') {
        const additionalImageError = validateAdditionalImage(optinTool.settings.additionalImage)
        optinToolMeta.errors.layout.additionalImage.additionalImage = additionalImageError
      }

      if (optinTool.type === 'landing-page' || optinTool.type === 'embedded-form') {
        let additionalImageError: OptinToolMetaErrorField
        const menuItemSlug =
          optinTool.type === 'landing-page'
            ? LandingPageMenuItemSlug.LAYOUT
            : EmbeddedFormMenuItemSlug.LAYOUT
        const menuSlug = getMenuSlugForDevice(action.payload.device, menuItemSlug)

        if (action.payload.device === 'desktop') {
          additionalImageError = validateAdditionalImage(optinTool.settings.desktop.additionalImage)
        } else if (action.payload.device === 'mobile') {
          if (
            (optinTool.settings.mobile.additionalImage?.image as CopiedField)?.type === 'copied'
          ) {
            additionalImageError = { errorMessage: '' }
          } else {
            additionalImageError = validateAdditionalImage(
              optinTool.settings.mobile.additionalImage
            )
          }
        }

        optinToolMeta.errors[menuSlug].additionalImage = {
          ...optinToolMeta.errors[menuSlug].additionalImage,
          additionalImage: additionalImageError
        }
      }
    },
    validateWelcomeFlowField(state: OptinToolEditorState<OptinTool>) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type === 'popup') {
        if (!isEmailOnlyOptinTool(optinTool)) {
          const sequenceIdError = validateSequenceId(optinTool.settings.sequenceId)
          optinToolMeta.errors.settings.welcomeFlow.sequenceId = sequenceIdError
        }
      }
      if (optinTool.type === 'landing-page' || optinTool.type === 'embedded-form') {
        const sequenceIdError = validateSequenceId(optinTool.settings.sequenceId)
        optinToolMeta.errors.settings.displayRules.sequenceId = sequenceIdError
      }
      if (optinTool.type === 'customer-chat') {
        const sequenceIdError = validateSequenceId(optinTool.settings.sequenceId)
        optinToolMeta.errors.settings.welcomeFlow.sequenceId = sequenceIdError
      }
    },
    validateGreetingField(
      state: OptinToolEditorState<OptinTool>,
      action: PayloadAction<{ fieldName: string }>
    ) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type === 'customer-chat') {
        const error = validateCustomerChatGreeting(optinTool.settings[action.payload.fieldName])
        if (action.payload.fieldName === 'greetingMessage') {
          optinToolMeta.errors.layoutBehavior.greeting[action.payload.fieldName] = error
        }
      }
    },
    validateFormField(
      state,
      action: PayloadAction<{
        device: Device
        step: string
      }>
    ) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)
      const { device, step } = action.payload
      let formItems: FormField[]
      let newError: OptinToolMetaErrorField[]

      if (optinTool.type === 'popup') {
        formItems =
          (
            optinTool.settings[step] as
              | PopupEmailSettings
              | PopupSMSSettings
              | PopupSMSWithEmailSettings
          ).form?.items ?? []
        newError = validateFormItems(formItems)

        optinToolMeta.errors[step].form.items = newError
      } else if (optinTool.type === 'landing-page' || optinTool.type === 'embedded-form') {
        const menuSlug = getMenuSlugForDevice(
          device,
          step as LandingPageMenuItemSlug | EmbeddedFormMenuItemSlug
        )
        formItems = optinTool.settings[device][step].form?.items ?? []
        newError = validateFormItems(formItems)
        optinToolMeta.errors[menuSlug].form.items = newError
      }
    },
    fontTypeSelected(state, action: PayloadAction<{ font: string }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      const recentlyUsedFontsLimit = 3

      const index = optinToolMeta.recentlyUsedFontTypes.indexOf(action.payload.font)
      if (index > -1) {
        optinToolMeta.recentlyUsedFontTypes.splice(index, 1)
      } else if (optinToolMeta.recentlyUsedFontTypes.length >= recentlyUsedFontsLimit) {
        optinToolMeta.recentlyUsedFontTypes.pop()
      }
      optinToolMeta.recentlyUsedFontTypes.unshift(action.payload.font)
    },
    loadRecentlyUsedFonts(state, action: PayloadAction<{ fonts: string[] }>) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.recentlyUsedFontTypes = action.payload.fonts
    },
    clearOptinTool() {
      return optinToolEditorInitialState
    },
    // this is used to escape the "unsaved changes" warning
    setOptinToolSaved(state) {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.saved = true
    },
    setFieldStyleAsTemporaryGeneralStyle(state, action: PayloadAction<{ path: string }>) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type !== 'popup') {
        throw new Error('invalid optin tool type')
      }

      if (action.payload.path === '') {
        optinToolMeta.temporaryGeneralStyle = null
        return
      }

      const fieldSettings = get(optinTool.settings, action.payload.path)

      const generalStyle = optinTool.settings.generalStyles.find(
        (generalStyle) => generalStyle.slug === fieldSettings.generalStyleSlug
      )

      optinToolMeta.temporaryGeneralStyle = convertFieldSettingsToGeneralStyle(
        fieldSettings,
        generalStyle
      )
    },
    applyTemporaryGeneralStyle(state) {
      const optinTool = selectOptinTool(state)
      const optinToolMeta = selectOptinToolMeta(state)

      if (optinTool.type !== 'popup') {
        throw new Error('invalid optin tool type')
      }

      const generalStyleIndex = optinTool.settings.generalStyles.findIndex(
        (generalStyle) => generalStyle.slug === optinToolMeta.temporaryGeneralStyle?.slug
      )
      optinTool.settings.generalStyles[generalStyleIndex] = optinToolMeta.temporaryGeneralStyle
      optinToolMeta.temporaryGeneralStyle = null
    },
    updateGeneralStyleField(
      state,
      action: PayloadAction<{ slug: string; newValues: GeneralStyle }>
    ) {
      const settings = selectOptinToolSettings(state) as PopupSettings
      const index = settings.generalStyles.findIndex((style) => style.slug === action.payload.slug)

      if (index !== -1) {
        settings.generalStyles[index] = action.payload.newValues
      }
    },
    overwriteGeneralStyleSettings(
      state,
      action: PayloadAction<{ fieldName: string; fieldKey: string }>
    ) {
      const settings = selectOptinToolSettings(state) as PopupSettings
      const { fieldName, fieldKey } = action.payload
      const settingsField = get(settings, fieldName)
      const generalStyle = settings.generalStyles?.find(
        (style) => style.slug === settingsField.generalStyleSlug
      )

      set(settings, `${fieldName}.${fieldKey}`, get(generalStyle, fieldKey))
    },
    overwriteFieldFontFamilyGeneralStyleSettings(
      state,
      action: PayloadAction<{ fieldName: string; fieldKey: string }>
    ) {
      const settings = selectOptinToolSettings(state) as PopupSettings
      const { fieldName, fieldKey } = action.payload
      const settingsField = get(settings, fieldName)
      const generalStyle = settings.generalStyles?.find(
        (style) => style.slug === settingsField.generalStyleSlug
      )
      const generalStyleFontSettings = get(generalStyle, fieldKey) as OptinToolFont
      const settingsFieldFontSettings = get(settingsField, fieldKey) as PopupFont
      const overWrittenSettings: PopupFont = {
        ...settingsFieldFontSettings,
        type: generalStyleFontSettings.type,
        family: generalStyleFontSettings.family,
        variant: generalStyleFontSettings.variant,
        fallback: generalStyleFontSettings.fallback
      }

      if (generalStyleFontSettings.type === 'custom') {
        (overWrittenSettings as OptinToolCustomFont).url = generalStyleFontSettings.url
      }

      set(settings, `${fieldName}.${fieldKey}`, overWrittenSettings)
    },
    resetToGeneralStyleSettings(
      state,
      action: PayloadAction<{ fieldName: string; fieldKey: string }>
    ) {
      const settings = selectOptinToolSettings(state) as PopupSettings
      const { fieldName, fieldKey } = action.payload
      const path = `${fieldName}.${fieldKey}`
      unset(settings, path)

      const parentSplitPath = path.split('.').slice(0, -1)
      let currentPath = parentSplitPath[0]

      for (let i = 1; i < parentSplitPath.length; i++) {
        currentPath += `.${parentSplitPath[i]}`
        const parent = get(settings, currentPath)
        if (isEmpty(parent)) {
          unset(settings, currentPath)
          break
        }
      }
    },
    resetAllPopupSettingsByGeneralStyle(
      state,
      action: PayloadAction<{ generalStyle: GeneralStyle }>
    ) {
      const popup = selectOptinTool(state) as Popup
      const mutablePopup = cloneDeep(popup)
      resetAllSettingsByGeneralStyle(mutablePopup, action.payload.generalStyle)
      state.optinTool = mutablePopup
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadEditorThunk.pending, (state) => {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.isLoading = true
    })
    builder.addCase(loadEditorThunk.rejected, (state) => {
      const optinToolMeta = selectOptinToolMeta(state)
      optinToolMeta.isLoading = false
    })
    builder.addCase(loadEditorThunk.fulfilled, (state, action) => {
      const optinToolMeta = selectOptinToolMeta(state)

      state.optinTool = action.payload

      optinToolMeta.isLoading = false
      optinToolMeta.saved = true
      optinToolMeta.previewScale = state.optinTool.devices.includes('desktop') ? 0.2 : 0.5

      switch (state.optinTool.type) {
        case 'popup': {
          optinToolMeta.menuItems = getPopupMenuItems(state.optinTool)
          optinToolMeta.errors = initPopupErrorMeta(state.optinTool.steps)
          break
        }
        case 'landing-page':
          optinToolMeta.menuItems = getLandingPageMenuItems(state.optinTool)
          break
        case 'embedded-form':
          optinToolMeta.menuItems = getEmbeddedFormMenuItems(state.optinTool)
          break
        case 'customer-chat':
          optinToolMeta.menuItems = customerChatMenuItems
          break
      }

      optinToolMeta.activeMenuItem = getFirstMenuItem(optinToolMeta.menuItems)
      optinToolMeta.activeAccordionItem = getActiveAccordionItemSlug(
        optinToolMeta.activeMenuItem.slug,
        optinToolMeta.activeMenuItem.items?.[0]
      )
    })
    builder.addCase(sequenceSaved, (state, action) => {
      const { optinToolId, sequenceId } = action.payload
      const patchOptinTool: DeepPartial<OptinTool> = {
        settings: {
          sequenceId
        }
      }
      const patchOptinToolMeta: DeepPartial<OptinToolMeta> = {
        saved: false
      }
      patchLocalOptinTool(optinToolId, patchOptinTool, patchOptinToolMeta)
    })

    builder.addMatcher(isEditorUpdateAction(), (state) => {
      state.optinToolMeta.saved = false
    })
  }
})

export const {
  clearOptinTool,
  createOptinTool,
  loadOptinTool,
  duplicateOptinTool,
  updateOptinToolField,
  updateLandingPageLink,
  updatePopupSettings,
  updateOptinToolSettingsField,
  copyOptinToolSettingsField,
  copyAdditionalImageField,
  createTeaserSettings,
  toggleTeaserStep,
  addFormItem,
  updateFormItem,
  deleteFormItem,
  reorderFormItems,
  updateFormInputPlaceholderColor,
  updateErrorMeta,
  updateErrorMetaField,
  selectMenuItem,
  showLandingPageActivatedModal,
  hideLandingPageActivatedModal,
  showEmbeddedFormActivatedModal,
  hideEmbeddedFormActivatedModal,
  updateActiveAccordionItem,
  updateActivePreviewDevice,
  updateInputState,
  updatePreview,
  togglePreviewFullscreenMode,
  updatePreviewScale,
  openStyleSettings,
  resetCustomCSS,
  validateURLRulesField,
  validateFormField,
  validateWelcomeFlowField,
  validateGreetingField,
  validateAdditionalImageField,
  fontTypeSelected,
  loadRecentlyUsedFonts,
  setOptinToolSaved,
  updateGeneralStyleField,
  setFieldStyleAsTemporaryGeneralStyle,
  applyTemporaryGeneralStyle,
  overwriteGeneralStyleSettings,
  overwriteFieldFontFamilyGeneralStyleSettings,
  resetToGeneralStyleSettings,
  resetAllPopupSettingsByGeneralStyle
} = optinToolEditorSlice.actions

export const { reducer: optinToolEditorReducer } = optinToolEditorSlice
