import React, { ReactElement } from 'react'
import cn from 'classnames'
import styles from './canvas-button-group.component.scss'

interface CanvasButtonGroupProps {
  label?: string
  children?: React.ReactNode
}

export function CanvasButtonGroup(props: CanvasButtonGroupProps) {
  return (
    <div className={styles.container}>
      {props.label && (
        <div className={styles['label-wrapper']}>
          <span className={styles.label}>{props.label}</span>
        </div>
      )}
      <div className={styles.group}>
        {React.Children.map(props.children, (child: ReactElement) => (
          <div
            className={cn(styles['group-item'], { [styles.active]: child.props.active })}
            style={child.props.extraStyle}
          >
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}
