import {
  Variable,
  getVariableFromRawVariable,
  variablePattern,
  variableRawAndTagPattern
} from '@ghostmonitor/recartapis'
import { getHtmlFromVariable } from './replace-raw-value-to-html-value'
import { isGeneralVariable, isKeywordReplyVariable, isLinkVariable } from './variable-assertion'

interface VariablesInsideTextInterface {
  linkVariables: Variable[]
  generalVariables: Variable[]
  keywordReplyVariables: Variable[]
}

export function collectVariablesFromText(rawValue: string): VariablesInsideTextInterface {
  let newHtml = rawValue
  let match: string[]
  let index = 0
  const linkVariablesInMessageItem = []
  const generalVariablesInMessageItem = []
  const keywordReplyVariablesInMessageItem = []
  const allVarPattern = new RegExp(variableRawAndTagPattern, 'g')
  const varPattern = new RegExp(variablePattern, 'g')

  while ((match = allVarPattern.exec(newHtml))) {
    if (!match[0].match(varPattern)) {
      index++
      continue
    }

    const variable = getVariableFromRawVariable(match[0])
    if (isLinkVariable(variable)) {
      linkVariablesInMessageItem.push(variable)
    }
    if (isGeneralVariable(variable)) {
      generalVariablesInMessageItem.push(variable)
    }

    if (isKeywordReplyVariable(variable)) {
      keywordReplyVariablesInMessageItem.push(variable)
    }

    if (!variable) {
      index++
      continue
    }

    let prefix: string | undefined

    newHtml = newHtml.replace(
      match[0],
      getHtmlFromVariable(
        variable,
        index,
        false,
        { subdomain: '', domain: '' },
        false,
        prefix,
        false
      )
    )

    index++
  }

  return {
    linkVariables: linkVariablesInMessageItem,
    generalVariables: generalVariablesInMessageItem,
    keywordReplyVariables: keywordReplyVariablesInMessageItem
  }
}
