import { Variable } from '@ghostmonitor/recartapis'
import React, { useEffect, useState } from 'react'
import { showConfirmModal } from '../../../../components/ui-kit/modals/confirm-modal/confirm-modal.component'
import { ReactComponent as ExclamationIcon } from '../../../../static/ui-kit/icons/normal/exclamation.svg'
import { DiscountCodePartialDeletionType } from '../../../../store/slices/sequence-editor/sequence-editor.state'

interface DiscountCodeDeletedModalInterface {
  open: boolean
  discountVariable: Variable
  deletionType: DiscountCodePartialDeletionType
  onOk: (discountCodeVariable: Variable) => void
  onCancel: () => void
}

export function DiscountCodeDeletedModal(props: DiscountCodeDeletedModalInterface) {
  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false)

  useEffect(() => {
    setIsNotificationPopupOpen(props.open)
  }, [props.open])

  function handleCancelClicked() {
    props.onCancel()
  }

  function handleOkClicked() {
    props.onOk(props.discountVariable)
  }

  function getTitle() {
    return props.deletionType === 'plain'
      ? 'Your discount code name has been removed from the message.'
      : 'Your destination URL has been removed from the message.'
  }

  function getContent() {
    return props.deletionType === 'plain'
      ? 'If you want to remove the discount code completely, you must remove the destination URL too. Do you want to delete it?'
      : 'If you want to delete completely the discount code from your message, you need to remove also the discount code name. Do you want to delete it?'
  }

  useEffect(() => {
    if (isNotificationPopupOpen) {
      showConfirmModal({
        title: getTitle(),
        icon: <ExclamationIcon />,
        modalType: 'warning',
        content: getContent(),
        okText: props.deletionType === 'plain' ? 'Delete destination URL' : 'Delete discount code',
        onOk: handleOkClicked,
        cancelText: 'Back to editor',
        onCancel: handleCancelClicked,
        maskClosable: true
      })
    }
  }, [isNotificationPopupOpen])

  return null
}
