import { Selector } from 'reselect'
import { facebookInitialState, FacebookState } from '../store/slices/facebook/facebook.reducer'
import { hooks } from './hooks'

export function useFacebookSelector<T extends Selector<FacebookState, any>>(
  selector: T
): ReturnType<T> | undefined {
  const { data: site } = hooks.useSite()

  const facebookState: FacebookState = site && {
    ...facebookInitialState,
    // ! WARNING messenger and facebookPages prop is not maintained here
    // ! https://app.clubhouse.io/recart/story/18938/move-all-facebook-slice-data-into-usefacebook-hook
    // messenger: {}
    // facebookPages: {}
    fbMessengerConfig: site?.fbMessengerConfig
  }

  return facebookState && (selector ? selector(facebookState) : facebookState)
}
