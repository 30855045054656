// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U8b8pw0TiTKpNXLlQANn{font-size:14px}.Au_LHhHqqVdQ781kEms7{flex-shrink:0;padding:17px 20px 21px}.vz6VucNnk7nZLyFg6q3g{display:flex;font-size:12px;margin-top:20px}.JfGZbYbWSBXYq3hYOQ0U{margin-right:11px}.JfGZbYbWSBXYq3hYOQ0U svg{height:auto;width:25px}.B6CSjSUMvMZBW8UQl3PQ{color:#8c8c8c}.ZznrcFTzb0oAuJgTX5kB{margin-top:11px}.WCImLOF84iK9RQhmZGDr{color:#222324;padding:13px 13px 15px 19px}.rM9KrD3VxY7W3LamMqu7{display:flex;justify-content:space-between}.RP2vrgtvybNU5PGgCtX6{font-weight:700}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message-templates/messenger-receipt-template/messenger-receipt-template.component.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CAGF,sBACE,aAAA,CACA,sBAAA,CAGF,sBACE,YAAA,CAEA,cAAA,CADA,eACA,CAGF,sBACE,iBAAA,CAEA,0BAEE,WAAA,CADA,UACA,CAIJ,sBACE,aAAA,CAGF,sBACE,eAAA,CAGF,sBACE,aAAA,CACA,2BAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CAGF,sBACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `U8b8pw0TiTKpNXLlQANn`,
	"containerTop": `Au_LHhHqqVdQ781kEms7`,
	"item": `vz6VucNnk7nZLyFg6q3g`,
	"itemImg": `JfGZbYbWSBXYq3hYOQ0U`,
	"itemSubtitle": `B6CSjSUMvMZBW8UQl3PQ`,
	"optionItem": `ZznrcFTzb0oAuJgTX5kB`,
	"containerBottom": `WCImLOF84iK9RQhmZGDr`,
	"total": `rM9KrD3VxY7W3LamMqu7`,
	"totalLabel": `RP2vrgtvybNU5PGgCtX6`
};
export default ___CSS_LOADER_EXPORT___;
