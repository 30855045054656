import { nanoid } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { SyncThunk } from '../../redux-helpers'
import {
  hideNotification,
  NotificationPayload,
  NotificationType,
  NotificationUpdatePayload,
  showNotification,
  updateNotification
} from './notification.reducer'

export interface NotificationControl {
  update: (notificationPayload: NotificationUpdatePayload) => void
  close: () => void
}

type NotificationThunk = (
  dispatch: (action: SyncThunk) => NotificationControl
) => NotificationControl

function show(notificationPayload: NotificationPayload): SyncThunk {
  return (dispatch): NotificationControl => {
    const notificationId = nanoid()
    const notificationConfig = {
      ...notificationPayload,
      id: notificationId,
      visible: true
    }
    dispatch(showNotification(notificationConfig))
    return {
      update: (_notificationPayload: NotificationUpdatePayload) =>
        dispatch(updateNotification(_notificationPayload, { notificationId })),
      close: () => dispatch(hideNotification(null, { notificationId }))
    }
  }
}

export function critical(title: string, content: string | ReactNode): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.critical,
        title,
        content
      })
    )
  }
}

export function error(title: ReactNode, content?: ReactNode): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.actionError,
        title,
        content
      })
    )
  }
}

export function warn(title: ReactNode, content?: ReactNode): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.actionWarn,
        title,
        content
      })
    )
  }
}

export function info(title: ReactNode, content?: ReactNode): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.actionInfo,
        title,
        content
      })
    )
  }
}

export function staticError(message: string): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.staticError,
        title: message
      })
    )
  }
}

export function staticWarn(message: string): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.staticWarn,
        title: message
      })
    )
  }
}

export function staticInfo(message: string): NotificationThunk {
  return (dispatch) => {
    return dispatch(
      show({
        type: NotificationType.staticInfo,
        title: message
      })
    )
  }
}
