import {
  isConditionalSplitSequenceItemUI,
  isDelaySequenceItemUI,
  isMessengerMessageSequenceItemUI,
  isMessengerUserInputSequenceItemUI,
  isRandomSplitSequenceItemUI,
  isSMSSequenceItemUI,
  MessageUI,
  QuickReply
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { attachCanConvert } from '../../../../routes/SequenceEditor/middlewares/utils/attach-can-convert'
import { BaseSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/base-sequence-item-model'
import { ConditionalSplitSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/conditional-split/conditional-split-sequence-item-model'
import { DelaySequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/delay/delay-sequence-item-model'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { RandomSplitSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/random-split/random-split-sequence-item-model'
import { SMSMessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/sms-message/sms-message-sequence-item-model'
import { UserInputSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/user-input/user-input-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { DashboardErrorMessages } from '../../../../types/dashboard-errors'
import {
  CREATE_MESSAGE,
  CREATE_QUICK_REPLY,
  CreateSequenceItemAction,
  createSplit
} from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectSequenceItem,
  selectSequenceItemsById,
  selectSequenceItemsMetaById
} from '../sequence-editor.selectors'
import { SequenceEditorState, sequenceItemInitialMeta } from '../sequence-editor.state'
import { createMessageActionHandler } from './create-message.handler'
import { createQuickReplyActionHandler } from './create-quick-reply.handler'
import { createSplitActionHandler } from './create-split.handler'

export function createSequenceItemActionHandler(
  state: SequenceEditorState,
  action: CreateSequenceItemAction
) {
  const sequenceItemsById = selectSequenceItemsById(state)
  const sequenceItemsMetaById = selectSequenceItemsMetaById(state)
  const sequence = selectEditorSequence(state)
  const sequenceMeta = selectEditorSequenceMeta(state)

  function createMessage(message: MessageUI) {
    createMessageActionHandler(state, {
      type: CREATE_MESSAGE,
      sequenceItemId: action.sequenceItem._id,
      message
    })
  }

  function createQuickReply(quickReply: QuickReply) {
    createQuickReplyActionHandler(state, {
      type: CREATE_QUICK_REPLY,
      sequenceItemId: action.sequenceItem._id,
      quickReply
    })
  }

  /**
   * Prepare sequence item
   */
  let sequenceItem = cloneDeep(action.sequenceItem)
  sequenceItem.siteId = sequence.siteId
  sequenceItem.tags = Array.from(new Set(sequenceItem.tags.concat(sequence.tags)))

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    sequenceItem = attachCanConvert(sequence, sequenceItem)
  }

  /**
   * Add to Diagram
   */
  let sequenceItemModel: BaseSequenceItemNodeModel
  if (isSMSSequenceItemUI(sequenceItem)) {
    sequenceItemModel = new SMSMessageSequenceItemNodeModel(sequenceItem._id)
  } else if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    sequenceItemModel = new MessageSequenceItemNodeModel(sequenceItem._id)
  } else if (isMessengerUserInputSequenceItemUI(sequenceItem)) {
    sequenceItemModel = new UserInputSequenceItemNodeModel(sequenceItem._id)
  } else if (isDelaySequenceItemUI(sequenceItem)) {
    sequenceItemModel = new DelaySequenceItemNodeModel(sequenceItem._id, action.displayMeta)
  } else if (isConditionalSplitSequenceItemUI(sequenceItem)) {
    sequenceItemModel = new ConditionalSplitSequenceItemNodeModel(sequenceItem._id)
  } else if (isRandomSplitSequenceItemUI(sequenceItem)) {
    sequenceItemModel = new RandomSplitSequenceItemNodeModel(sequenceItem._id)
  } else {
    throw new Error(DashboardErrorMessages.SEQUENCE_ITEM_TYPE_NOT_FOUND)
  }

  if (action.points !== undefined && !isNaN(action.points.x) && !isNaN(action.points.y)) {
    sequenceItemModel.setPosition(Math.round(action.points.x), Math.round(action.points.y))
  } else {
    sequenceItemModel.setPosition(0, 0)
  }

  const diagramModel = getDiagramModel(sequence.serializedDiagram)
  diagramModel.addNode(sequenceItemModel)

  sequence.serializedDiagram = diagramModel.serializeDiagram() as any

  /**
   * Add to Store
   */
  sequenceItemsById[action.sequenceItem._id] = cloneDeep(sequenceItem)
  sequenceItemsMetaById[action.sequenceItem._id] = cloneDeep(sequenceItemInitialMeta)
  sequenceMeta.sequenceItemsTouched = false

  sequenceItem = selectSequenceItem(sequenceItem._id)(state)

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    // Create Messages
    const messages = cloneDeep(sequenceItem.messages)
    sequenceItem.messages = []
    messages.forEach(createMessage)
  }

  sequence.quickReplyIndexToId[sequenceItem._id] = {}

  if (sequence.isQuietHoursEnabled !== undefined) {
    sequenceItem.isQuietHoursEnabled = sequence.isQuietHoursEnabled
  }

  if (sequence.sendingFrequency !== undefined) {
    sequenceItem.sendingFrequency = sequence.sendingFrequency
  }

  // On user input, we don't want quick reply ports to be created
  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    // Create Quick Replies
    const quickReplies = cloneDeep(sequenceItem.quickReplies)
    sequenceItem.quickReplies = []
    quickReplies.forEach(createQuickReply)
  }

  if (isRandomSplitSequenceItemUI(sequenceItem)) {
    // Create Splits
    const splits = cloneDeep(sequenceItem.logic.randomSplit.variants)
    sequenceItem.logic.randomSplit.variants = []
    splits.forEach((_) => {
      createSplitActionHandler(state, createSplit(action.sequenceItem._id))
    })
  }

  /**
   * Add to Sequence
   */
  if (sequence.sequenceItemIds.length === 0) {
    sequence.entrySequenceItemId = sequenceItem._id

    // Entry sequence items can be triggered via sequence id
    sequenceItem.trigger.push(`sequence_${sequence._id}`)
  }

  sequence.sequenceItemIds.push(sequenceItem._id)
}
