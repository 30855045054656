// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rTPBv00UaijEzT7aG6wK{background-color:#dee4f5;border:2px solid #476dff;border-radius:12px;color:#212b36;cursor:pointer;display:flex;height:24px;justify-content:center;line-height:12px;margin-right:5px;padding:4px 8px;width:36px}.rTPBv00UaijEzT7aG6wK .vTJagQ_mSYaQAP_7jN0Q{display:inline-block;font-size:12px;height:11px;position:relative}.XsIJCmvUxzE2WF2sxO6t{cursor:default;inset:0;position:fixed}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-variable-input/make-variable-input.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAOE,wBCTc,CDWd,wBAAA,CACA,kBAAA,CAFA,aCTG,CDaH,cAAA,CATA,YAAA,CAFA,WAAA,CAGA,sBAAA,CACA,gBAAA,CAMA,gBAAA,CALA,eAAA,CAJA,UAUA,CAEA,4CACE,oBAAA,CAGA,cAAA,CADA,WAAA,CADA,iBAEA,CAIJ,sBAGE,cAAA,CADA,OAAA,CADA,cAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addonContainer": `rTPBv00UaijEzT7aG6wK`,
	"variablePickerIcon": `vTJagQ_mSYaQAP_7jN0Q`,
	"pickerWrapper": `XsIJCmvUxzE2WF2sxO6t`
};
export default ___CSS_LOADER_EXPORT___;
