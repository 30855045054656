import { SerializedDiagram } from '@ghostmonitor/recartapis'
import { DiagramModel } from 'storm-react-diagrams'
import { getDiagramEngine } from '../components/sequence-editor/get-diagram-engine'

export function getDiagramModel(serializedDiagram: SerializedDiagram): DiagramModel {
  const diagramModel = new DiagramModel()
  diagramModel.deSerializeDiagram(serializedDiagram, getDiagramEngine())

  return diagramModel
}
