import {
  Channel,
  CustomerChat,
  Device,
  EmbeddedForm,
  LandingPage,
  OptinTool,
  OptinToolListView,
  OptinToolStatus,
  OptinToolStep,
  Popup,
  SiteFontConfig
} from '@ghostmonitor/recartapis'
import isEqual from 'lodash/isEqual'
import { config } from '../../../config'
import { GeotargetingCountry } from '../../../hooks/use-geotargeting-countries'
import { OptinToolsListData } from '../../../hooks/use-optin-tools-list-data'
import { OptinToolMenuItem } from '../components/editor/left-panel/left-panel.component'
import { EmbeddedFormMenuItemSlug } from '../embedded-form/editor/menu-items'
import { LandingPageMenuItemSlug, getMenuSlugForDevice } from '../landing-page/editor/menu-items'
import { getFontStyle, getFontWeight } from '../popup/editor/preview/components/utils/style-helpers'

export function isUniqueOptinToolName(
  optinTools: OptinToolListView[] | undefined,
  value: string
): boolean {
  return !optinTools?.find(
    (optinTool) => optinTool.name.toLowerCase() === value.toLowerCase().trim()
  )
}

export function getCopiedFieldName(fieldName: string, copySettingsFrom: string) {
  const firstPartReplaced = `${copySettingsFrom}.${fieldName.split('.').slice(1).join('.')}`

  const newFieldName = fieldName.split('.')
  newFieldName[1] = copySettingsFrom
  const secondPartReplaced = newFieldName.join('.')

  const isLandingPage = fieldName.includes('mobile') || fieldName.includes('desktop')

  if (isLandingPage) {
    return ['desktop', 'mobile'].includes(copySettingsFrom) ? firstPartReplaced : secondPartReplaced
  }
  return firstPartReplaced
}

export function getLandingPageUrl(linkText: string) {
  return linkText.length > 66 ? `${linkText.substring(0, 60)}...` : linkText
}

export function getLandingPageTitle(titleText: string) {
  return titleText.length > 27 ? `${titleText.substring(0, 35)}...` : titleText
}

export function getFirstMenuItem(menuItems: OptinToolMenuItem[]): OptinToolMenuItem {
  return menuItems.filter((menuItem) => menuItem.type !== 'divider')[0]
}

export function getActiveAccordionItemSlug(menuItem: string, slug: string): string {
  return `${menuItem}-${slug}`
}

export function getOptinToolMainChannel(optinTool: OptinTool): Channel | undefined {
  if (!optinTool) {
    return undefined
  }

  if (
    optinTool.type === 'popup' ||
    optinTool.type === 'landing-page' ||
    optinTool.type === 'embedded-form'
  ) {
    return isSMSOptinTool(optinTool) ? 'sms' : 'messenger'
  }

  return optinTool.channels[0]
}

export function getOptinToolMessagingType(optinTool: OptinTool): 'two-way' | 'one-way' | undefined {
  return optinTool?.type === 'popup' ||
    optinTool?.type === 'landing-page' ||
    optinTool?.type === 'embedded-form'
    ? optinTool.messagingType
    : undefined
}

export function isSMSOptinTool(optinTool: Popup | LandingPage | EmbeddedForm): boolean {
  return optinTool.channels.includes('sms')
}

export function isEmailOnlyOptinTool(optinTool: OptinTool | OptinToolsListData): boolean {
  return isEqual(optinTool.channels, ['email'])
}

export function isDoubleOptinConsentNeeded(optinTool: OptinTool): boolean {
  if (optinTool.type === 'customer-chat' || optinTool.type === 'keyword') {
    return false
  }

  const isDesktop = optinTool.devices.includes('desktop')
  const isTwoWay = optinTool.messagingType === 'two-way'
  const hasInPopupConfirmation = optinTool.steps.includes('oneTimePassword')

  return isDesktop && isTwoWay && !hasInPopupConfirmation
}

export function isActivePhoneNumberStatus(phoneNumberStatus: string): boolean {
  return ['completed', 'shortcode-extension'].includes(phoneNumberStatus)
}

export function isOneWayPhoneNumberType(phoneNumberType: string): boolean {
  return ['shared-short-code', 'alphanumeric', 'non-provisioned'].includes(phoneNumberType)
}

export function isTwoWayPhoneNumberType(phoneNumberType: string): boolean {
  return ['toll-free-number', 'short-code', 'long-code'].includes(phoneNumberType)
}

export function getAvailableMessagingTypes(
  geotargetingCountries: GeotargetingCountry[],
  isInternationalOptinToolGeotargetingEnabled: boolean,
  isOneWayForTwoWayMandatoryCountriesEnabled: boolean
): string[] {
  const hasSMSSetup = geotargetingCountries.length > 0

  // If there are no geotargeting countries specified, we allow selecting both messaging types to enable saving draft optins for both types
  if (isInternationalOptinToolGeotargetingEnabled || !hasSMSSetup) {
    return ['two-way', 'one-way']
  }

  const messagingTypes: string[] = []
  const hasOneWay = geotargetingCountries.some(
    (geotargetingCountry) => geotargetingCountry.messagingType === 'one-way'
  )
  const hasTwoWay = geotargetingCountries.some(
    (geotargetingCountry) => geotargetingCountry.messagingType === 'two-way'
  )

  if (hasTwoWay) {
    messagingTypes.push('two-way')
  }

  if (hasOneWay || isOneWayForTwoWayMandatoryCountriesEnabled) {
    messagingTypes.push('one-way')
  }

  return messagingTypes
}

interface GetAvailableChannelsParams {
  isSMSEnabled: boolean
  isEmailOnlyPopupEnabled: boolean
  isFacebookPageConnected: boolean
}

export function getAvailableChannels({
  isSMSEnabled,
  isEmailOnlyPopupEnabled,
  isFacebookPageConnected
}: GetAvailableChannelsParams): Channel[][] {
  const optinToolChannels: Channel[][] = [
    ['email', 'sms'],
    ['sms'],
    ['email'],
    ['email', 'messenger'],
    ['messenger']
  ]

  return optinToolChannels.filter((channels) => {
    if (!isSMSEnabled && channels.includes('sms')) {
      return false
    }

    if (!isEmailOnlyPopupEnabled && isEqual(channels, ['email'])) {
      return false
    }

    if (!isFacebookPageConnected && channels.includes('messenger')) {
      return false
    }

    return true
  })
}

export function getAvailableSteps(channels: Channel[]): OptinToolStep[][] {
  if (isEqual(channels, ['email', 'sms'])) {
    return [['email', 'sms'], ['smsWithEmail']]
  }

  return [channels]
}

export const defaultCustomerChat: Omit<CustomerChat, 'id'> = {
  name: 'Customer chat',
  type: 'customer-chat',
  devices: ['mobile', 'desktop'],
  channels: ['messenger'],
  status: OptinToolStatus.DRAFT,
  // @ts-ignore
  settings: null
}

export function getEmbeddedFormEmbedCode(optinToolId: string, siteId: string, domain: string) {
  return `<div class="recart-page-loader-${optinToolId}"></div>
<div class="recart-embedded-form-root-${optinToolId}"></div>
<script id="recart-embedded-form-script-${optinToolId}" src="${config.TRACKING_URL}/recart-page-loader.js?domain=${domain}&amp;account=${siteId}&amp;hasGMInitParams=1"></script>`
}

function hasVariant(fontFaces: FontFace[], font: { family: string; variant: string }) {
  return fontFaces.some(
    (loadedFont) => loadedFont.family === font.family && loadedFont.weight === font.variant
  )
}

export async function loadCustomFonts(fonts: SiteFontConfig[], logger: any) {
  const alreadyAddedFonts: FontFace[] = []
  document.fonts.forEach((fontFace) => {
    if (
      !hasVariant(alreadyAddedFonts, {
        family: fontFace.family,
        variant: fontFace.weight
      })
    ) {
      alreadyAddedFonts.push(fontFace)
    }
  })

  const fontsToLoad: SiteFontConfig[] = []
  for (const font of fonts) {
    const newVariants: SiteFontConfig['variants'] = []

    for (const variant of font.variants) {
      if (
        !hasVariant(alreadyAddedFonts, {
          family: font.family,
          variant: getFontWeight(variant.name)
        })
      ) {
        newVariants.push(variant)
        break
      }
    }

    if (newVariants.length > 0) {
      fontsToLoad.push({
        ...font,
        variants: newVariants
      })
    }
  }

  const fontFaces = fontsToLoad.flatMap((font) =>
    font.variants.map((variant) => {
      return new FontFace(font.family, `url(${variant.url})`, {
        display: 'swap',
        weight: getFontWeight(variant.name),
        style: getFontStyle(variant.name)
      })
    })
  )

  try {
    const loadedFonts = await Promise.all(fontFaces.map((fontFace) => fontFace.load()))
    loadedFonts.forEach((fontFace) => document.fonts.add(fontFace))
  } catch (error) {
    logger.error(new Error('FailedToLoadCustomFont', { cause: error }))
  }
}

export function getFilteredMenuItems(
  menuItems: OptinToolMenuItem[],
  optinTool: LandingPage | EmbeddedForm
): OptinToolMenuItem[] {
  function getFormItems(device: Device, step: OptinToolStep) {
    return optinTool.settings[device]?.[step]?.form?.items ?? []
  }
  function hasFormItems(device: Device, step: OptinToolStep) {
    return getFormItems(device, step).length > 0
  }

  const steps = ['email', 'sms', 'smsWithEmail']
  const devices: Device[] = ['desktop', 'mobile']
  const errorSlugMapping = {
    'embedded-form': {
      email: EmbeddedFormMenuItemSlug.EMAIL_FORM_ERROR,
      sms: EmbeddedFormMenuItemSlug.SMS_FORM_ERROR,
      smsWithEmail: EmbeddedFormMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR
    },
    'landing-page': {
      email: LandingPageMenuItemSlug.EMAIL_FORM_ERROR,
      sms: LandingPageMenuItemSlug.SMS_FORM_ERROR,
      smsWithEmail: LandingPageMenuItemSlug.SMS_WITH_EMAIL_FORM_ERROR
    }
  }

  const menuItemSlugsToHide: string[] = []

  devices.forEach((device) => {
    steps.forEach((step) => {
      if (!hasFormItems(device, step as OptinToolStep)) {
        menuItemSlugsToHide.push(
          getMenuSlugForDevice(device, errorSlugMapping[optinTool.type][step])
        )
      }
    })
  })

  const filteredMenuItems = menuItems.filter(
    (menuItem) => !menuItemSlugsToHide.includes(menuItem.slug)
  )

  return filteredMenuItems
}
