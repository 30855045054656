import {
  getBrowserSessionSiteId,
  getPageSessionSiteId,
  pollAuthChange,
  setBrowserSessionSiteId,
  setPageSessionSiteId
} from '../../utils/auth'
import { hasSessionId, setNewSessionId } from '../../utils/session-id'
import { meLoaded } from '../slices/me/me.reducer'

export function detectAuthChangeMiddleware() {
  return (next) => (action: any) => {
    next(action)
    if (meLoaded.match(action)) {
      if (!action.payload.site) {
        return
      }
      const user = {
        userId: action.payload._id,
        siteId: action.payload.site?.id,
        email: action.payload.email
      }

      const pageSesssionSiteId = getPageSessionSiteId()
      // Logged in in the current tab
      if (pageSesssionSiteId !== null && pageSesssionSiteId !== user.siteId) {
        setPageSessionSiteId(user.siteId)
        window.location.reload()
      }

      if (getBrowserSessionSiteId() !== user.siteId || !hasSessionId()) {
        setNewSessionId()
      }

      setBrowserSessionSiteId(user.siteId)
      setPageSessionSiteId(user.siteId)

      pollAuthChange()
    }
  }
}
