import { OptinTool } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../../../utils/api'
import type { AppDispatch } from '../../../create-store'
import { DashboardState } from '../../../dashboard.state'

export interface LoadEditorArgs {
  optinToolId?: string
}

export const loadEditorThunk = createAsyncThunk<
  OptinTool,
  LoadEditorArgs,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('optin-tool-editor/loadEditor', async (args) => {
  return await api.getOptinToolById(args.optinToolId)
})
