import {
  AI,
  BillingService,
  Blast,
  BlastGetResponse,
  BlastListResponse,
  BlastUpdateResponse,
  CreateCustomFontPayload,
  CreateOptinToolExperimentPayload,
  CreateSegmentListRequest,
  CreateSegmentRequest,
  CreateSegmentResponse,
  CreateSubscriberDetailPayload,
  EditSegmentRequest,
  FacebookPage,
  FlowUI,
  GetBillingSubscriptionsResponse,
  GetDiscountCodePoolResponse,
  GetFontsResponse,
  GetStaticDiscountCodeResponse,
  GetSubdomainsResponse,
  GetUsedKeywordsResponse,
  HttpErrorResponse,
  HttpResponse,
  OptinTool,
  OptinToolExperiment,
  OptinToolResponse,
  PatchSubdomainsRequest,
  PatchSubscriberDetailPayload,
  SMSCampaign,
  SMSCampaignGetResponse,
  SMSCampaignListResponse,
  SMSCampaignUpdateResponse,
  SMSConversations,
  SMSSettings,
  SMSSettingsService,
  SegmentListView,
  SequenceAPI,
  Site,
  SiteFontConfig,
  SubscriberDetail,
  UpdateCustomFontPayload,
  UpdateOptinToolExperimentPayload,
  User
} from '@ghostmonitor/recartapis'
import { SmsConversationMessage } from '@ghostmonitor/recartapis/clients/cjs/sms/sms_conversations'
import { AxiosError, AxiosResponse } from 'axios'
import { SequenceItemsStatsById } from '../hooks/resources/use-sequence-items-stat'
import { PatchSMSQuietHoursSettingRequest } from '../hooks/resources/use-sms-quiet-hours-settings'
import { PatchSMSSmartSendingSettingRequest } from '../hooks/resources/use-sms-smart-sending-settings'
import { type LoginForm } from '../routes/auth/login/login.component'
import { MeState } from '../store/slices/me/me.types'
import { FbMessengerConfig } from '../types/facebook/facebook-messenger-config.type'
import { DeepPartial } from '../types/utils.type'
import { request } from './request'

async function login(data: LoginForm): Promise<{ token: string }> {
  return request.post('auth/process', data)
}

async function demoLogin(email: string): Promise<{ token: string }> {
  return request.post('auth/demo-login', { email })
}

async function resetPassword(data: { email: string }): Promise<void> {
  return request.post('validate/resetpassword', data)
}

async function validateDomain(domain: string): Promise<{ validationStatus: string }> {
  return request.get('validate/existingdomain', {
    params: { domain: domain.trim().replace(/^https?:\/\//gi, '') }
  })
}

async function validateEmail(email: string): Promise<boolean> {
  return request.get('validate/email', { params: { email: email.trim() } })
}

async function getMe(): Promise<MeState> {
  return request.get<MeState>(`me`)
}

async function sequenceExistsById(id: string): Promise<boolean> {
  return request.get<boolean>(`sequences/${id}/exists`)
}

async function getSequenceById(id: string): Promise<SequenceAPI> {
  return request.get(`sequences/${id}`)
}

async function getBlast(blastId: string): Promise<Blast | undefined> {
  const blastGetResponse = await request.get<BlastGetResponse>(`blasts/${blastId}`)
  return blastGetResponse?.data
}

async function getBlastBySequenceId(sequenceId: string): Promise<Blast | null> {
  const blastListResponse = await request.get<BlastListResponse>(`blasts/?sequenceId=${sequenceId}`)
  return blastListResponse?.data?.[0] ?? null
}

async function getSMSCampaign(smsCampaignId: string): Promise<SMSCampaign | undefined> {
  const blastGetResponse = await request.get<SMSCampaignGetResponse>(
    `sms-campaigns/${smsCampaignId}`
  )
  return blastGetResponse?.data
}

async function saveSMSCampaign(smsCampaign: SMSCampaign): Promise<SMSCampaignUpdateResponse> {
  return request.put<SMSCampaignUpdateResponse>(`/sms-campaigns/${smsCampaign._id}`, smsCampaign)
}

async function removeSMSCampaign(smsCampaignId: string): Promise<void> {
  return request.del(`/sms-campaigns/${smsCampaignId}`)
}

async function patchSMSCampaign(
  smsCampaignId: string,
  patchPayload: Partial<SMSCampaign>
): Promise<SMSCampaignUpdateResponse> {
  return request.patch<SMSCampaignUpdateResponse>(`/sms-campaigns/${smsCampaignId}`, patchPayload)
}

async function getSMSCampaignByFlowId(flowId: string): Promise<SMSCampaign | null> {
  const smsCampaignListResponse = await request.get<SMSCampaignListResponse>(
    `sms-campaigns/?sequenceId=${flowId}`
  )
  return smsCampaignListResponse?.data?.[0] ?? null
}

async function patchBlast(
  blastId: string,
  patchPayload: Partial<Blast>
): Promise<BlastUpdateResponse> {
  return request.patch<BlastUpdateResponse>(`/blasts/${blastId}`, patchPayload)
}

async function updateSequence(sequence: SequenceAPI): Promise<AxiosResponse<SequenceAPI>> {
  return request.axiosApi.put<SequenceAPI>(`sequences/${sequence._id}`, sequence)
}

async function duplicateSequence(sequenceId: string): Promise<SequenceAPI> {
  return request.get<SequenceAPI>(`sequences/${sequenceId}/duplicate`)
}

async function getSequenceByTemplateSlug(templateSlug: string): Promise<SequenceAPI> {
  return request.get<SequenceAPI>(`sequence-templates/${templateSlug}/sequence`)
}

async function getDiscountCode(discountCode: string): Promise<GetStaticDiscountCodeResponse> {
  return request.get<GetStaticDiscountCodeResponse>(`discount-codes/${discountCode}`)
}

async function getDiscountCodePool(
  discountCodePoolId: string
): Promise<GetDiscountCodePoolResponse> {
  return request.get<GetDiscountCodePoolResponse>(`discount-code-pools/${discountCodePoolId}`)
}

async function getSequenceItemsStat(
  queryParams: Record<string, unknown>
): Promise<SequenceItemsStatsById> {
  return request.get<SequenceItemsStatsById>(`statistics/sequence-items`, {
    params: queryParams
  })
}

async function getSMSSettings(): Promise<SMSSettingsService.GetSMSSettingsResponse> {
  return request.get<SMSSettingsService.GetSMSSettingsResponse>('sms-settings')
}

async function getSMSQuietHoursSettings(): Promise<SMSSettingsService.GetQuietHoursSettingsResponse> {
  return request.get<SMSSettingsService.GetQuietHoursSettingsResponse>('sms-settings/quiet-hours')
}

async function getSMSSmartSendingSettings(): Promise<SMSSettingsService.GetSmartSendingSettingsResponse> {
  return request.get<SMSSettingsService.GetSmartSendingSettingsResponse>(
    'sms-settings/smart-sending'
  )
}

async function getPhoneNumbersCurrentPrices(): Promise<SMSSettingsService.GetCurrentPricesByPhoneNumbersResponse> {
  return request.get<SMSSettingsService.GetCurrentPricesByPhoneNumbersResponse>(
    'sms-phone-numbers/prices/current',
    {
      params: { activePhoneNumbersOnly: true }
    }
  )
}

async function getSMSTestPhoneNumbers(): Promise<SMSSettingsService.GetTestPhoneNumbersResponse> {
  return request.get<SMSSettingsService.GetTestPhoneNumbersResponse>(
    'sms-settings/test-phone-numbers'
  )
}

async function patchSubdomains(subdomains: PatchSubdomainsRequest): Promise<Site> {
  return request.patch<Site>(`subdomains`, subdomains)
}

async function getSubdomains(subdomain: string): Promise<GetSubdomainsResponse> {
  return request.get<GetSubdomainsResponse>(`subdomains/${subdomain}`)
}

async function patchSMSQuietHoursSettings(
  smsQuietHoursSettings: PatchSMSQuietHoursSettingRequest
): Promise<SMSSettingsService.GetQuietHoursSettingsResponse> {
  return request.put<SMSSettingsService.GetQuietHoursSettingsResponse>(
    'sms-settings/quiet-hours',
    smsQuietHoursSettings
  )
}

async function patchSMSSmartSendingSettings(
  smsSmartSendingSettings: PatchSMSSmartSendingSettingRequest
): Promise<SMSSettingsService.GetSmartSendingSettingsResponse> {
  return request.put<SMSSettingsService.GetSmartSendingSettingsResponse>(
    'sms-settings/smart-sending',
    smsSmartSendingSettings
  )
}

async function putSMSTestPhoneNumbers(
  phoneNumbers: SMSSettings.TestPhoneNumbers
): Promise<HttpResponse> {
  return request.put<HttpResponse>('sms-settings/test-phone-numbers', phoneNumbers)
}

async function getSMSPhoneNumbers(): Promise<SMSSettingsService.GetPhoneNumbersResponse> {
  return request.get<SMSSettingsService.GetPhoneNumbersResponse>('sms-phone-numbers')
}

async function getAvailableCountries(): Promise<SMSSettingsService.GetAvailableCountriesResponse> {
  return request.get<SMSSettingsService.GetAvailableCountriesResponse>(
    'sms-settings/available-countries'
  )
}

async function getUser(): Promise<HttpResponse<User>> {
  return request.get<HttpResponse<User>>('user')
}

async function patchUser(payload: Partial<User>): Promise<HttpResponse<User>> {
  return request.patch<HttpResponse<User>>('user', payload)
}

async function getSite(fields?: string[]): Promise<HttpResponse<Site>> {
  return request.get<HttpResponse<Site>>('site', { params: { fields } })
}

async function patchSite(payload: DeepPartial<Site>): Promise<HttpResponse<Site>> {
  return request.patch<HttpResponse<Site>>('site', payload)
}

async function exchangeFacebookAuthorizationToken(
  authorizationCode: string
): Promise<FacebookPage.Page[]> {
  const requestPayload: FacebookPage.ExchangeAuthorizationCodeRequest = {
    authorizationCode
  }
  const response: FacebookPage.ExchangeAuthorizationCodeResponse =
    await request.post<FacebookPage.ExchangeAuthorizationCodeResponse>(
      'facebook-authorization-code',
      requestPayload
    )
  return response.data
}

async function getFacebookPage(): Promise<HttpResponse<FbMessengerConfig>> {
  return request.get<HttpResponse<FbMessengerConfig>>('connected-facebook-page')
}

async function hasUsedFacebookPage(): Promise<{ statusCode: number }> {
  return request.head('connected-facebook-page')
}

async function removeFacebookPage(): Promise<{ result: 'success' | 'error'; error: any }> {
  return request.post('facebook-pages/disconnect')
}

async function getSMSConversations(): Promise<HttpResponse<SMSConversations.SmsConversation[]>> {
  return request.get<HttpResponse<SMSConversations.SmsConversation[]>>(`/sms-conversations`)
}

async function getSMSConversation(
  phoneNumber: string
): Promise<HttpResponse<SMSConversations.SmsConversationMessage[]>> {
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]+/, '')
  return request.get<HttpResponse<SmsConversationMessage[]>>(
    `/sms-conversations/${cleanedPhoneNumber}`
  )
}

async function sendSMSMessage(phoneNumber: string, text: string): Promise<HttpResponse> {
  const cleanedPhoneNumber = phoneNumber.replace(/[^\d]+/, '')
  return request.post<HttpResponse>(`/sms-conversations/${cleanedPhoneNumber}/message`, { text })
}

async function sendSMSTest(
  phoneNumber: string,
  sequenceItemId: string,
  blockFollowupMessage?: boolean
): Promise<HttpResponse> {
  const recipient = { phoneNumber }
  return request.post<HttpResponse>(`sequence-item/${sequenceItemId}/test`, {
    recipient,
    blockFollowupMessage
  })
}

async function getOptinToolById(id: string): Promise<OptinTool | undefined> {
  const optinToolResponse = await request.get<OptinToolResponse>(`optin-tools/${id}`)
  return optinToolResponse?.data
}

async function getSubscriberDetails(): Promise<HttpResponse<SubscriberDetail[]>> {
  return request.get<HttpResponse<SubscriberDetail[]>>('site/subscriber-details')
}

async function createSubscriberDetail(subscriberPersonalDetail: CreateSubscriberDetailPayload) {
  return request.post<HttpResponse<SubscriberDetail>>(
    'site/subscriber-details',
    subscriberPersonalDetail
  )
}

async function updateSubscriberDetail(
  patchPayload: PatchSubscriberDetailPayload
): Promise<HttpResponse<SubscriberDetail>> {
  return request.put<HttpResponse<SubscriberDetail>>('site/subscriber-details', patchPayload)
}

async function getOptinToolFonts(): Promise<GetFontsResponse> {
  return request.get<GetFontsResponse>('site/fonts')
}

async function createOptinToolFont(
  font: CreateCustomFontPayload
): Promise<HttpResponse<SiteFontConfig>> {
  return request.post<HttpResponse<SiteFontConfig>>('site/fonts', font)
}

async function updateOptinToolFont(
  fontId: string,
  font: UpdateCustomFontPayload
): Promise<HttpResponse<SiteFontConfig>> {
  return request.patch<HttpResponse<SiteFontConfig>>(`site/fonts/${fontId}`, font)
}

async function getOptinToolExperiments(): Promise<HttpResponse<OptinToolExperiment[]>> {
  return request.get<HttpResponse<OptinToolExperiment[]>>('optin-tool-experiments')
}

async function createOptinToolExperiment(
  optinToolExperiment: CreateOptinToolExperimentPayload
): Promise<HttpResponse<OptinToolExperiment>> {
  return request.post<HttpResponse<OptinToolExperiment>>(
    'optin-tool-experiments',
    optinToolExperiment
  )
}

async function updateOptinToolExperiment(
  experimentId: string,
  updatedValues: UpdateOptinToolExperimentPayload
): Promise<HttpResponse<OptinToolExperiment>> {
  return request.patch<HttpResponse<OptinToolExperiment>>(
    `optin-tool-experiments/${experimentId}`,
    updatedValues
  )
}

async function createSegment(segment: CreateSegmentRequest | CreateSegmentListRequest) {
  return request.post<CreateSegmentResponse>('segments', segment)
}

async function getSegments() {
  return request.get<HttpResponse<SegmentListView[]>>('segments', {
    params: {
      includeRemoved: true
    }
  })
}

async function updateSegmentSubscribers(
  segmentId: string,
  payload: EditSegmentRequest[]
): Promise<null> {
  return request.post(`segments/${segmentId}/subscribers`, payload)
}

async function getFlowIfNewerExists(
  flowId: string,
  updatedAt: string
): Promise<HttpResponse<FlowUI> | null> {
  try {
    const response = await request.axiosApi.get(`flows/${flowId}`, {
      headers: {
        'If-Modified-Since': new Date(updatedAt).toUTCString()
      }
    })
    return response.data
  } catch (err: any) {
    const axiosError: AxiosError<HttpErrorResponse> = err
    if (axiosError.response?.status === 304) {
      return null
    }
    throw axiosError
  }
}

async function getFlow(flowId: string): Promise<FlowUI> {
  const response = await request.get<HttpResponse<FlowUI>>(`flows/${flowId}`)
  return response.data!
}

export type UpdateFlowFullRequestBody = Partial<FlowUI> & { smsCampaign?: Partial<SMSCampaign> }

async function updateFlow(flow: UpdateFlowFullRequestBody): Promise<FlowUI> {
  const flowResponse = await request.put<HttpResponse<FlowUI>>(`flows/${flow._id}`, flow)
  return flowResponse.data!
}

async function toggleFlow(flowId: string, isEnabled: boolean): Promise<'OK'> {
  return request.put(`flows/${flowId}/toggle`, {
    isEnabled
  })
}

async function patchFlow(flowId: string, payload: Pick<FlowUI, 'name'>): Promise<FlowUI> {
  const flowResponse = await request.patch<HttpResponse<FlowUI>>(`flows/${flowId}`, payload)
  return flowResponse.data!
}

async function duplicateFlow(flowId: string): Promise<FlowUI> {
  const flowResponse = await request.get<HttpResponse<FlowUI>>(`flows/${flowId}/duplicate`)
  return flowResponse.data!
}

async function getBillingSubscriptions(): Promise<GetBillingSubscriptionsResponse> {
  return request.get<GetBillingSubscriptionsResponse>('billing/subscriptions')
}
async function getUsedKeywords(): Promise<GetUsedKeywordsResponse> {
  return request.get<GetUsedKeywordsResponse>('used-keywords')
}

async function getAISMSConversationResponse(
  model: 'gpt-3.5-turbo' | 'gpt-4',
  messages: SmsConversationMessage[]
) {
  const response = await request.post<HttpResponse<string>>(
    `/ai-inbox-conversations?model=${model}`,
    messages
  )
  return response.data!
}

async function generateSMSMessage<
  TUserInputs extends Record<string, string> = Record<string, string>
>(
  promptType: AI.PromptType,
  userInputs: TUserInputs,
  options?: Record<string, any>
): Promise<string> {
  const response = await request.post<HttpResponse<string>>('ai-text-generations', {
    type: promptType,
    userInputs,
    options
  })

  return response.data
}

async function generateConditionalSplit<
  TUserInputs extends Record<string, string> = Record<string, string>
>(userInputs: TUserInputs): Promise<string> {
  const response = await request.post<HttpResponse<string>>('ai-text-generations', {
    type: AI.PromptType.InsertConditionalSplitInContext,
    userInputs
  })

  if (!response.data) {
    throw new Error('Failed to generate conditional split')
  }

  const jsonString = response.data.match(/{[\s\S]+}/)[0]

  return jsonString.replace(/\\"/g, '"')
}

async function getLeasablePhoneNumber(
  phoneNumber: string
): Promise<SMSSettingsService.GetLeasablePhoneNumberResponse> {
  return request.get<SMSSettingsService.GetLeasablePhoneNumberResponse>(
    `sms-phone-numbers/leasable-numbers/${phoneNumber}`
  )
}

async function getLeasablePhoneNumbersCount(): Promise<SMSSettingsService.GetLeasablePhoneNumbersCountResponse> {
  return request.get<SMSSettingsService.GetLeasablePhoneNumbersCountResponse>(
    'sms-phone-numbers/leasable-numbers-count'
  )
}

async function leasePhoneNumbers(
  verificationStatus?: string
): Promise<SMSSettingsService.LeasePhoneNumbersResponse> {
  return request.post<SMSSettingsService.LeasePhoneNumbersResponse>('sms-phone-numbers/lease', {
    verificationStatus
  })
}
async function verifyPhoneNumber(
  details: SMSSettings.VerificationDetails,
  isReadyToSubmit: boolean,
  phoneNumber: string
): Promise<SMSSettingsService.VerifyPhoneNumberResponse> {
  return request.post<SMSSettingsService.VerifyPhoneNumberResponse>(
    `sms-phone-numbers/leasable-numbers/${phoneNumber}/verification`,
    {
      details,
      isReadyToSubmit,
      phoneNumber
    }
  )
}

async function getCurrentBillingPeriods(): Promise<BillingService.GetBillingPeriodsResponse> {
  return request.get<BillingService.GetBillingPeriodsResponse>(
    'billing/billing-periods?time=' + new Date().toISOString()
  )
}

async function patchSiteIntegrationEvent(
  slug: string,
  optinType: 'one-way' | 'two-way'
): Promise<Site> {
  return request.patch(`site/integration-events/${slug}`, { optinType })
}

export const api = {
  demoLogin,
  login,
  resetPassword,
  validateDomain,
  validateEmail,
  getMe,
  createSegment,
  getSegments,
  getSubscriberDetails,
  createSubscriberDetail,
  updateSubscriberDetail,
  duplicateSequence,
  getBlast,
  getBlastBySequenceId,
  getBillingSubscriptions,
  getSMSCampaign,
  saveSMSCampaign,
  removeSMSCampaign,
  getSMSCampaignByFlowId,
  patchSMSCampaign,
  getDiscountCode,
  getDiscountCodePool,
  getFacebookPage,
  hasUsedFacebookPage,
  exchangeFacebookAuthorizationToken,
  getOptinToolById,
  getOptinToolExperiments,
  createOptinToolExperiment,
  updateOptinToolExperiment,
  getOptinToolFonts,
  createOptinToolFont,
  updateOptinToolFont,
  getSequenceById,
  getSequenceByTemplateSlug,
  getSequenceItemsStat,
  getSite,
  getSMSConversation,
  getSMSConversations,
  getSMSPhoneNumbers,
  getSMSSettings,
  getSMSTestPhoneNumbers,
  getAvailableCountries,
  getSubdomains,
  getUser,
  patchBlast,
  patchSite,
  patchSubdomains,
  patchUser,
  putSMSTestPhoneNumbers,
  removeFacebookPage,
  sendSMSMessage,
  sendSMSTest,
  sequenceExistsById,
  updateSegmentSubscribers,
  updateSequence,
  getFlowIfNewerExists,
  getFlow,
  generateConditionalSplit,
  updateFlow,
  patchFlow,
  duplicateFlow,
  getSMSQuietHoursSettings,
  getSMSSmartSendingSettings,
  patchSMSQuietHoursSettings,
  patchSMSSmartSendingSettings,
  getPhoneNumbersCurrentPrices,
  toggleFlow,
  getAISMSConversationResponse,
  generateSMSMessage,
  getLeasablePhoneNumber,
  getLeasablePhoneNumbersCount,
  leasePhoneNumbers,
  getCurrentBillingPeriods,
  patchSiteIntegrationEvent,
  verifyPhoneNumber,
  getUsedKeywords
}
