export function removeUnwantedTags(input: string): string {
  // Define the allowed tags
  const allowedTags = ['span', 'div', 'img', 'br']

  // Create a regular expression pattern to match all HTML tags
  const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi

  // Replace unwanted tags while keeping the allowed ones
  const cleanedHtml = input.replace(tagPattern, (match, tag) => {
    // Check if the tag is in the allowed list
    if (allowedTags.includes(tag.toLowerCase())) {
      return match // Keep the tag as it is
    }
    return '' // Remove the tag
  })

  return cleanedHtml
}
