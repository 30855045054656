import { SyncThunk } from '../../../../store/redux-helpers'
import { selectEntrySequenceItem } from '../../../../store/selectors'
import { setSequenceEditorSetting } from '../../hooks/use-sequence-editor-settings-context'
import { isFacebookCommentsSequenceItem } from '../../utils/assert-sequence-item-type'
import { SequenceEditorSettings } from '../context/sequence-editor-settings.type'

export function setSequenceTypeThunk(): SyncThunk {
  return (_, getState) => {
    const state = getState()
    const entrySequenceItem = selectEntrySequenceItem(state)

    setSequenceEditorSetting(
      SequenceEditorSettings.isFacebookComment,
      isFacebookCommentsSequenceItem(entrySequenceItem)
    )
  }
}
