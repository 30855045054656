import { AnyAction, Store } from '@reduxjs/toolkit'
import debouncePromise from 'debounce-promise'
import { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../../store/selectors'
import {
  discountCodeValidationFailed,
  discountCodeValidationSucceeded
} from '../../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../../store/slices/flow-editor/flow-editor.reducer'
import { validateDiscountCode } from '../../../../utils/validators/flow/validate-discount-code'

const DEBOUNCE_DELAY = 500

function getValidateDiscountCode(dispatch: AppDispatch) {
  return debouncePromise(
    async (discountCodeName: string) => {
      const error = await validateDiscountCode(discountCodeName)
      if (error !== undefined) {
        dispatch(
          discountCodeValidationFailed({
            discountCodeName,
            error
          })
        )
      } else {
        dispatch(
          discountCodeValidationSucceeded({
            discountCodeName
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: false }
  )
}

export const getValidateUpdateDiscountCodesOnFlowItemAction = (store: Store<DashboardState>) => {
  const dispatch: AppDispatch = store.dispatch
  const validateDiscountCode = getValidateDiscountCode(dispatch)

  return (store: Store<DashboardState>, action: AnyAction) => {
    if (flowEditorActions.updateDiscountCodesOnFlowItem.match(action)) {
      const state = store.getState()
      const flowItemId = action.payload.flowItemId
      const flowItemMeta = flowEditorSelectors.selectFlowItemMeta(flowItemId)(state)
      flowItemMeta.discountCodesToValidate.forEach((discountCode) => {
        validateDiscountCode(discountCode)
      })
    }
  }
}
