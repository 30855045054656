import { createContext } from 'react'

interface AnalyticsContextType {
  category: string
  label?: string
}

const defaultAnalyticsContext: AnalyticsContextType = {
  category: null,
  label: null
}

export const AnalyticsContext = createContext(defaultAnalyticsContext)
