import { jwtDecode } from 'jwt-decode'
import { showErrorModal } from '../components/ui-kit/modals/error-modal/error-modal.component'
import { history } from '../routing-utilities/history/history'
import { createScope } from './logger/logger'
import { setLocalStorageItem } from './set-local-storage-item'
const { error } = createScope('dashboard')

const AUTH_CHANGE_POLL_INTERVAL = 500

export interface Auth {
  userId: string
  siteId: string
  role: string
}

export function getAuthToken(): string | null {
  return localStorage.getItem('x-auth-token')
}

export function getAuth(): Auth | null {
  const token = getAuthToken()
  if (token !== null) {
    try {
      const { id, userId, siteId, role } = jwtDecode<{
        id: string
        userId: string
        siteId: string
        role: string
      }>(token)
      return {
        userId: userId || id,
        siteId,
        role
      }
    } catch (err) {
      error(new Error('CannotDecodeAuthToken'), { err })
    }
  }

  return null
}

let notificationVisible = false

function checkIfAuthHasChanged() {
  const browserSessionSiteId = getBrowserSessionSiteId()
  const pageSessionSiteId = getPageSessionSiteId()
  if (
    !notificationVisible &&
    browserSessionSiteId !== null &&
    pageSessionSiteId !== null &&
    browserSessionSiteId !== pageSessionSiteId
  ) {
    notificationVisible = true
    showErrorModal({
      title: 'You have been logged out.',
      content: 'Please login again to continue.',
      onOk: () => {
        redirectToLogin()
        notificationVisible = false
        sessionStorage.removeItem('recart-siteId')
        localStorage.removeItem('recart-siteId')
      },
      width: 416
    })
  }
  pollAuthChange()
}

let pollAuthChangeTimer = null
export async function pollAuthChange() {
  if (pollAuthChangeTimer !== null) {
    clearTimeout(pollAuthChangeTimer)
  }

  pollAuthChangeTimer = setTimeout(checkIfAuthHasChanged, AUTH_CHANGE_POLL_INTERVAL)
}

export function getBrowserSessionSiteId(): string | null {
  return localStorage.getItem('recart-siteId')
}

export function setBrowserSessionSiteId(siteId: string): void {
  setLocalStorageItem('recart-siteId', siteId)
}

export function getPageSessionSiteId(): string | null {
  return sessionStorage.getItem('recart-siteId')
}

export function setPageSessionSiteId(siteId: string): void {
  sessionStorage.setItem('recart-siteId', siteId)
}

export function redirectToLogin() {
  const { pathname, search } = window.location
  let loginRoute = '/login'
  const redirectTo = encodeURIComponent(`${pathname}${search}`)
  if (!/login/.test(pathname)) {
    loginRoute += `?redirectTo=${redirectTo}`
    history.replace(loginRoute)
  }
}
