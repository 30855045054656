import { LinkingMeta, NodeType, SequenceItemUI } from '@ghostmonitor/recartapis'
import { Button, notification } from 'antd'
import cn from 'classnames'
import React, { ReactNode, useRef } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSelector } from 'react-redux'
import { ShimmerRow } from '../../../../components/facebook-account-list/shimmer-row/shimmer-row.component'
import { hooks } from '../../../../hooks/hooks'
import { ReactComponent as CopyIcon } from '../../../../static/images/svg/iconmonstr-copy-thin.svg'
import { ReactComponent as DeleteIcon } from '../../../../static/images/svg/iconmonstr-trash-can-thin.svg'
import { selectIsReadOnly } from '../../../../store/selectors'
import {
  SequenceItemMeta,
  SequenceMeta
} from '../../../../store/slices/sequence-editor/sequence-editor.state'
import { formatters } from '../../../../utils/formatters/formatters'
import { EntryTag } from '../../components/entry-tag/entry-tag.component'
import { Port } from '../../components/port/port.component'
import { SequenceItemButton } from '../../components/sequence-item-button/sequence-item-button.component'
import { Stat } from '../../components/stat/stat.component'
import { LogicPortModel } from '../../models/port/logic-port-model'
import { SequenceItemPortModel } from '../../models/port/sequence-item-port-model'
import { BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import styles from './make-sequence-item.hoc.scss'

export interface SequenceItemComponentProps {
  showDropZone: boolean
  faded: boolean
  sequenceItem: SequenceItemUI
  sequenceMeta?: SequenceMeta
  sequenceItemMeta: SequenceItemMeta
  inputPort: SequenceItemPortModel
  isEntry: boolean
  onClone: () => void
  onRemove: () => void
  showSequenceItemStatistics: boolean
  showDebugView: boolean
  currencySlug: string
}

export interface SequenceItemNodeWidgetBaseProps {
  sequenceName?: string
  linking: boolean
  linkingMeta?: LinkingMeta
  sequenceItemNode: BaseSequenceItemNodeModel
  skipPort?: LogicPortModel
  inputIsValidPort?: LogicPortModel
}

export function makeSequenceItem<WrappedComponentProps extends SequenceItemNodeWidgetBaseProps>(
  WrappedComponent: React.ComponentType<WrappedComponentProps>,
  wrapperClassNames: string[],
  title?: ReactNode
): React.FC<WrappedComponentProps & SequenceItemComponentProps> {
  const SequenceItemComponent: React.FC<WrappedComponentProps & SequenceItemComponentProps> = ({
    showDropZone,
    faded,
    inputPort,
    isEntry,
    onClone,
    onRemove,
    ...props
  }) => {
    const nodeRef = useRef<HTMLDivElement>(null)

    const isReadOnly = useSelector(selectIsReadOnly)

    const {
      data: sequenceItemStat,
      isError: isSequenceItemStatLoadingError,
      isLoading: isSequenceItemsStatLoading
    } = hooks.useEditorSequenceItemStat(props.sequenceItem?._id)

    function setZIndex(value: number) {
      if (nodeRef.current?.parentElement) {
        nodeRef.current.parentElement.style.zIndex = value.toString()
      }
    }

    function handleCloneSequenceItem() {
      onClone()
    }

    function handleRemoveSequenceItem() {
      onRemove()
    }

    function renderStatistics() {
      if (
        props.sequenceItemNode.type !== NodeType.MESSAGE_SEQUENCE_ITEM ||
        !props.showSequenceItemStatistics
      ) {
        return title
      }

      if (isSequenceItemsStatLoading) {
        return <ShimmerRow />
      }

      if (isSequenceItemStatLoadingError) {
        return title
      }

      const sales = sequenceItemStat?.salesTotal[props.currencySlug] ?? 0
      const openRate = sequenceItemStat
        ? sequenceItemStat.sent > 0 && sequenceItemStat.sentToPSID === 0
          ? {
              value: '?',
              tooltip: 'Open rate not available due to Facebook policy'
            }
          : { value: formatters.percentage(sequenceItemStat.openRate) }
        : undefined
      return [
        <Stat key='sent' value={formatters.number(sequenceItemStat?.sent)} label='sent' />,
        <Stat
          key='open-rate'
          value={openRate?.value ?? 'n.a.'}
          label='open rate'
          tooltip={openRate?.tooltip}
        />,
        <Stat
          key='click-rate'
          value={formatters.percentage(sequenceItemStat?.clickRate)}
          label='link CTR'
          title='link click rate'
        />,
        <Stat
          key='sales'
          value={formatters.currency(Math.ceil(sales), props.currencySlug)}
          label='sales'
        />
      ]
    }

    let isLinked = false
    let isMoving = false

    if (inputPort) {
      const links = inputPort && Object.values(inputPort.getLinks())
      if (links.length > 0 && links[0].getTargetPort()) {
        isLinked = true
      }

      isMoving =
        !props.linking ||
        props.linkingMeta?.portType === inputPort.type ||
        props.linkingMeta?.portParentSequenceItemId === props.sequenceItemNode.sequenceItemId
    }

    return (
      <div
        className={cn(wrapperClassNames, styles['node-wrapper'], {
          [styles.dragging]: showDropZone,
          [styles.faded]: faded
        })}
        ref={nodeRef}
      >
        {props.showDebugView && props.sequenceItem && (
          <div className={styles['debug-id']}>
            <span>{props.sequenceItem._id}</span>
            <CopyToClipboard text={props.sequenceItem._id}>
              <Button onClick={() => notification.open({ message: 'Copied' })}>Copy ID</Button>
            </CopyToClipboard>
          </div>
        )}
        {showDropZone && <div className={styles['drop-label']}>Drop here</div>}
        <div
          onMouseDown={() => setZIndex(4)}
          onMouseUp={() => setZIndex(0)}
          className={cn(styles['sequence-item-node-widget'], {
            [styles.selected]: props.sequenceItemMeta.selected
          })}
          data-testid={
            props.sequenceItem !== undefined ? `node-${props.sequenceItem._id}` : undefined
          }
        >
          <div className={styles.header}>
            {inputPort && (
              <div className={styles['port-container']}>
                <Port
                  name={inputPort.name}
                  nodeId={props.sequenceItemNode.getID()}
                  isEntry={isEntry}
                  isLinked={isLinked}
                  isPulsing={!isMoving}
                  doCorrectAlignment
                />
              </div>
            )}
            {isEntry && props.sequenceName && !props.sequenceItemMeta.error && (
              <div title={props.sequenceName} className={styles['entry-label']}>
                <EntryTag label={props.sequenceName} />
              </div>
            )}
            {props.sequenceItemMeta.error && (
              <div className={styles['error-container']}>
                <div className={styles['error-message']}>{props.sequenceItemMeta.error}</div>
              </div>
            )}
            <div className={styles['button-bar']}>
              <div className={cn({ [styles['extra-bar']]: props.showSequenceItemStatistics })}>
                {renderStatistics()}
              </div>
              <div className={styles['icon-container']}>
                <SequenceItemButton
                  title='Clone'
                  icon={<CopyIcon />}
                  onClick={handleCloneSequenceItem}
                  disabled={isReadOnly}
                />
                <SequenceItemButton
                  title='Delete'
                  icon={<DeleteIcon />}
                  onClick={handleRemoveSequenceItem}
                  disabled={isEntry || isReadOnly}
                />
              </div>
            </div>
          </div>
          <div className={styles['content-wrapper']}>
            <WrappedComponent {...(props as WrappedComponentProps)} hasVariable />
          </div>
        </div>
        {props.showDebugView && (
          <div className={styles['debug-payload']}>
            <textarea readOnly value={JSON.stringify(props.sequenceItem, undefined, 4)} />
            <CopyToClipboard text={JSON.stringify(props.sequenceItem)}>
              <Button onClick={() => notification.open({ message: 'Copied' })}>Copy payload</Button>
            </CopyToClipboard>
          </div>
        )}
      </div>
    )
  }

  return SequenceItemComponent
}
