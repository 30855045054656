import {
  isMessengerMessageSequenceItemUI,
  isRandomSplitSequenceItemUI
} from '@ghostmonitor/recartapis'
import { BaseSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/base-sequence-item-model'
import { ConditionalSplitSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/conditional-split/conditional-split-sequence-item-model'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import {
  hasFollowUpButtonOnSequenceItemType,
  hasLogicButtonOnSequenceItemType,
  hasMessageOnSequenceItemType
} from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import {
  RemoveSequenceItemAction,
  REMOVE_MESSAGE_ITEM,
  REMOVE_QUICK_REPLY,
  REMOVE_SPLIT
} from '../sequence-editor.actions'
import {
  selectEditorSequence,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectSequenceItemsById,
  selectSequenceItemsMetaById
} from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
import { updateLinkTargets } from '../utils/update-link-targets'
import { removeMessageActionHandler } from './remove-message.handler'
import { removeQuickReplyActionHandler } from './remove-quick-reply.handler'
import { removeSplitActionHandler } from './remove-split.handler'
const { info } = createScope('sequence-editor')

export function removeSequenceItemActionHandler(
  state: SequenceEditorState,
  action: RemoveSequenceItemAction
) {
  function removeMessage(messageIndex: number) {
    removeMessageActionHandler(state, {
      type: REMOVE_MESSAGE_ITEM,
      sequenceItemId: action.sequenceItemId,
      messageIndex
    })
  }

  function removeQuickReply(quickReplyIndex: number) {
    removeQuickReplyActionHandler(state, {
      type: REMOVE_QUICK_REPLY,
      sequenceItemId: action.sequenceItemId,
      quickReplyIndex
    })
  }

  function removeSplit(splitIndex: number) {
    removeSplitActionHandler(state, {
      type: REMOVE_SPLIT,
      sequenceItemId: action.sequenceItemId,
      splitIndex
    })
  }

  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  const sequenceItemsById = selectSequenceItemsById(state)
  const sequenceItemsMetaById = selectSequenceItemsMetaById(state)
  const sequenceItemMeta = selectSequenceItemMeta(action.sequenceItemId)(state)

  if (sequence.sequenceItemIds.length === 1) {
    info('removeSequenceItemActionHandler:1')
    return
  }

  if (sequence.entrySequenceItemId === action.sequenceItemId) {
    info('removeSequenceItemActionHandler:2')
    return
  }

  /**
   * Remove elements by their remove handler
   */
  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const messagesLength = sequenceItem.messages.length
    for (let i = messagesLength - 1; i >= 0; i--) {
      removeMessage(i)
    }
  }

  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const quickRepliesLength = sequenceItem.quickReplies.length
    for (let j = quickRepliesLength - 1; j >= 0; j--) {
      removeQuickReply(j)
    }
  }

  if (isRandomSplitSequenceItemUI(sequenceItem)) {
    const splitsLength = sequenceItem.logic.randomSplit.variants.length
    for (let j = splitsLength - 1; j >= 0; j--) {
      removeSplit(j)
    }
  }

  /**
   * Handle ports ON THIS sequence item
   */
  const diagramModel = getDiagramModel(sequence.serializedDiagram)

  if (hasFollowUpButtonOnSequenceItemType(sequenceItem)) {
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
    if (node.getFollowUpPort() !== undefined) {
      node.removeFollowUpPort()
    }
  }

  if (hasLogicButtonOnSequenceItemType(sequenceItem)) {
    const node = getNodeModel<ConditionalSplitSequenceItemNodeModel>(
      diagramModel,
      action.sequenceItemId
    )
    node.removeLogicPorts()
  }

  /**
   * Remove things
   */
  const node = getNodeModel<BaseSequenceItemNodeModel>(diagramModel, action.sequenceItemId)
  diagramModel.removeNode(node)
  node.remove()

  sequence.serializedDiagram = diagramModel.serializeDiagram() as any

  if (sequenceItemMeta.error !== null) {
    state.errorCount -= 1
  }

  delete sequenceItemsById[action.sequenceItemId]
  delete sequenceItemsMetaById[action.sequenceItemId]

  sequence.sequenceItemIds = sequence.sequenceItemIds.filter(
    (sequenceItemId) => sequenceItemId !== action.sequenceItemId
  )
  delete sequence.quickReplyIndexToId[action.sequenceItemId]

  /**
   * After removing the sequence item, update targets on sequence items pointing TO THIS sequence item
   */
  sequence.sequenceItemIds.forEach((sequenceItemId) => {
    const sequenceItem = selectSequenceItem(sequenceItemId)(state)
    updateLinkTargets(state, diagramModel, sequenceItem)
  })
}
