// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Av6_6W0aFUnCgKZNIjUM{width:210px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-edit-discount-code-unique/popover-edit-discount-code.component.scss"],"names":[],"mappings":"AACE,sBACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discountPopoverContentContainer": `Av6_6W0aFUnCgKZNIjUM`
};
export default ___CSS_LOADER_EXPORT___;
