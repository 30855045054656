export const localStorageWhitelist = [
  'facebook-nov-14-changes-confirmed',
  'x-auth-token',
  'promobox-',
  'accordion-activekeys',
  'conditional-split-modal-opened',
  'recart-sequence-editor-debug-view',
  'subscribers-page-selected-chart',
  'gm_show_onboarding',
  'optinToolEditor-recentlyUsedFonts',
  'recart-siteId',
  'sms-inbox-draft-'
]

const cleanableItems = [
  'optinToolEditor-',
  'blast-sequenceId:',
  'sequenceEditorInstance-',
  'sequenceItem-',
  'sms-campaign-flow-id:',
  'flowEditor-'
]

export function cleanLocalStorage(currentKey: string) {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)

    if (currentKey !== key && cleanableItems.some((item) => new RegExp(`^${item}.*$`).test(key))) {
      localStorage.removeItem(key)
    }
  }
}
