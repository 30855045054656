import {
  CreateCustomFontPayload,
  HttpResponse,
  SiteFontConfig,
  UpdateCustomFontPayload
} from '@ghostmonitor/recartapis'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

export function useOptinToolFontCreate() {
  const queryName = useMemo(() => [QUERY_NAME.optinToolFonts], [])
  const queryClient = useQueryClient()

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  // CREATE
  const {
    mutateAsync: createOptinToolFont,
    isLoading: isCreatingOptinToolFont,
    error: createOptinToolFontError
  } = useMutation<HttpResponse<SiteFontConfig>, unknown, CreateCustomFontPayload>(
    api.createOptinToolFont,
    {
      onSettled: handleSettled
    }
  )

  // UPDATE
  const {
    mutateAsync: updateOptinToolFont,
    isLoading: isUpdatingOptinToolFont,
    error: updateOptinToolFontError
  } = useMutation<
    HttpResponse<SiteFontConfig>,
    unknown,
    { fontId: string; font: UpdateCustomFontPayload }
  >(({ fontId, font }) => api.updateOptinToolFont(fontId, font), {
    onSettled: handleSettled
  })

  return {
    createOptinToolFont,
    isCreatingOptinToolFont,
    createOptinToolFontError,
    updateOptinToolFont,
    isUpdatingOptinToolFont,
    updateOptinToolFontError
  }
}
