import { getTextFromMessage, hasTextOnMessage, MessageUI } from '@ghostmonitor/recartapis'
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import memoize from 'lodash/memoize'
import { batch } from 'react-redux'
import { SequenceEditorError } from '../../../../routes/SequenceEditor/types/sequence-editor-errors'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { discountCodeValidator } from '../../../../routes/SequenceEditor/validators/discount-code.validator'
import { messageValidator } from '../../../../routes/SequenceEditor/validators/message.validator'
import { getDiscountCodesFromText } from '../../../../routes/SequenceEditor/validators/utils/get-discount-codes-from-text'
import { serializedError } from '../../../../utils/serialized-error'
import type { AppDispatch } from '../../../create-store'
import { DashboardState } from '../../../dashboard.state'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectMessageMeta,
  selectSequenceItem,
  selectValidDiscountCodes
} from '../../../selectors'
import {
  inlineDiscountCodeValidationErrorExpired,
  inlineDiscountCodeValidationErrorNotExists,
  inlineDiscountCodeValidationSuccess,
  messageValidationError,
  messageValidationSuccess,
  touchAllSequenceItems
} from '../sequence-editor.actions'

export const validateDiscountCodesThunk = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/validateDiscountCodes', async (args, store) => {
  const state = store.getState()
  const dispatch = store.dispatch
  const sequence = cloneDeep(selectEditorSequence(state))
  const sequenceMeta = selectEditorSequenceMeta(state)
  const validDiscountCodes = selectValidDiscountCodes(state)
  const memoizedDiscountCodeValidator = memoize(discountCodeValidator)

  if (!sequenceMeta.sequenceItemsTouched) {
    batch(() => {
      dispatch(touchAllSequenceItems())
    })
  }

  const actions = []
  let hasError = false

  await Promise.all(
    sequence.sequenceItemIds.map(async (sequenceItemId) => {
      const sequenceItem = selectSequenceItem(sequenceItemId)(state)

      if (hasMessageOnSequenceItemType(sequenceItem)) {
        await Promise.all(
          sequenceItem.messages.map(async (message: MessageUI, messageIndex: number) => {
            const discountCodeErrors: SerializedError[] = []
            const messageMeta = selectMessageMeta(sequenceItem._id, messageIndex)(state)

            if (hasTextOnMessage(message)) {
              const text = getTextFromMessage(message)
              const discountCodes = getDiscountCodesFromText(text)

              await Promise.all(
                discountCodes.map(async (discountCode) => {
                  if (validDiscountCodes.includes(discountCode)) {
                    return
                  }
                  const discountCodeError = await memoizedDiscountCodeValidator(discountCode)
                  if (discountCodeError) {
                    if (discountCodeError.name === SequenceEditorError.DiscountCodeExpired) {
                      discountCodeErrors.push(discountCodeError)
                      dispatch(
                        inlineDiscountCodeValidationErrorExpired({
                          discountCode
                        })
                      )
                    } else if (
                      discountCodeError.name === SequenceEditorError.DiscountCodeNotExists &&
                      (sequenceMeta.isEverSaved || messageMeta.edited)
                    ) {
                      discountCodeErrors.push(discountCodeError)
                      dispatch(
                        inlineDiscountCodeValidationErrorNotExists({
                          discountCode
                        })
                      )
                    }
                  } else {
                    dispatch(
                      inlineDiscountCodeValidationSuccess({
                        discountCode
                      })
                    )
                  }
                })
              )
            }

            const messageErrors = messageValidator(message)
            if (messageErrors.length > 0 || discountCodeErrors.length > 0) {
              hasError = true
              actions.push(
                messageValidationError(
                  [
                    ...messageErrors,
                    ...(discountCodeErrors.length > 0
                      ? [serializedError(SequenceEditorError.MessageInvalidDiscountCode)]
                      : [])
                  ],
                  {
                    sequenceItemId,
                    messageIndex
                  }
                )
              )
            } else {
              actions.push(
                messageValidationSuccess(null, {
                  sequenceItemId,
                  messageIndex
                })
              )
            }
          })
        )
      }
    })
  )

  batch(() => {
    actions.forEach(dispatch)
  })

  if (hasError) {
    throw new Error('Discount codes validation failed')
  }
})
