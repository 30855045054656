import {
  CUSTOM_INTEGRATION_SLUG_PREFIX,
  FlowAPI,
  NodeType,
  SMSCampaign,
  Trigger,
  checkFlowDiagramIntegrity,
  getAllowedFlowItemTags,
  getAllowedFlowTags,
  getEntryFlowItemTags,
  getEntryFlowItemTrigger,
  getFlowTags,
  getFlowTrigger,
  getInheritableFlowTags,
  getIsQuietHoursEnabledByFlowItemId,
  getLegalFlowItemTags,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  isAutomatedFlowTrigger,
  isConditionalSplitFlowItemAPI,
  isConditionalSplitV2FlowItemAPI,
  isCustomIntegrationTag,
  isCustomIntegrationTrigger,
  isCustomTrigger,
  isDelayFlowItemAPI,
  isEntryFlowItem,
  isFulfillmentTrigger,
  isLoopWorkTrigger,
  isLoyaltyLionTrigger,
  isBoldSubscriptionTrigger,
  isOneOffFlowTrigger,
  isOrderAndReceiptTrigger,
  isRandomSplitFlowItemAPI,
  isRechargeTrigger,
  isSMSAbandonmentTrigger,
  isSMSMessageFlowItemAPI,
  isTypeformTrigger,
  isWelcomeTrigger,
  isWheelioTrigger,
  isWondermentTrigger,
  isYotpoTrigger,
  IsYotpoReviewTrigger,
  shouldBeLegalFlowItem,
  tagsBubbleUpFromFlowItems,
  isSmsResubscribeTrigger,
  isSmsUnsubscribeTrigger
} from '@ghostmonitor/recartapis'
import { difference, isEqual } from 'lodash'
// import diff from 'snapshot-diff'
import { getAllowedEntryFlowItemTriggers } from '@ghostmonitor/recartapis/clients/cjs/typescript-types/utils/get-flow-item-trigger'
import { checkCustomIntegrationFlowIntegrity } from './flow-integrity/check-custom-integration-flow-integrity'
import { checkCustomTriggerFlowIntegrity } from './flow-integrity/check-custom-trigger-flow-integrity'
import { checkDefaultWelcomeFlowIntegrity } from './flow-integrity/check-default-welcome-flow-integrity'
import { checkFulfillmentFlowIntegrity } from './flow-integrity/check-fulfillment-flow-integrity'
import { checkHelpFlowIntegrity } from './flow-integrity/check-help-flow-integrity'
import { checkLoopFlowIntegrity } from './flow-integrity/check-loop-work-flow-integrity'
import { checkOtherAutomatedFlowIntegrity } from './flow-integrity/check-other-automated-flow-integrity'
import { checkOrderAndReceiptFlowIntegrity } from './flow-integrity/check-receipt-flow-integrity'
import { checkRechargeFlowIntegrity } from './flow-integrity/check-recharge-flow-integrity'
import { checkSMSAbandonmentFlowIntegrity } from './flow-integrity/check-sms-abandonment-flow-integrity'
import { checkSMSCampaignFlowIntegrity } from './flow-integrity/check-sms-campaign-flow-integrity'
import { checkTypeformFlowIntegrity } from './flow-integrity/check-typeform-flow-integrity'
import { checkWheelioFlowIntegrity } from './flow-integrity/check-wheelio-flow-integrity'
import { checkWondermentFlowIntegrity } from './flow-integrity/check-wonderment-flow-integrity'
import { checkYotpoFlowIntegrity } from './flow-integrity/check-yotpo-flow-integrity'
import { checkConditionalSplitFlowItemIntegrity } from './flow-item-integrity/check-conditional-split-flow-item-integrity'
import { checkConditionalSplitV2Integrity } from './flow-item-integrity/check-conditional-split-v2-integrity'
import { checkDelayFlowItemIntegrity } from './flow-item-integrity/check-delay-flow-item-integrity'
import { checkRandomSplitFlowItemIntegrity } from './flow-item-integrity/check-random-split-flow-item-integrity'
import { checkSMSMessageFlowItemIntegrity } from './flow-item-integrity/check-sms-message-flow-item-integrity'
import { checkLoyaltyLionFlowIntegrity } from './flow-integrity/check-loyalty-lion-flow-integrity'
import { checkBoldFlowIntegrity } from './flow-integrity/check-bold-flow-integrity'
import { checkSmsResubscribeFlowIntegrity } from './flow-integrity/check-sms-resubscribe-flow-integrity'
import { checkSmsUnsubscribeFlowIntegrity } from './flow-integrity/check-sms-unsubscribe-flow-integrity'

export interface FlowIntegrityOptions {
  isLegalFlowItemDeletable: boolean
}

/***********************************************
 * Checkers that can be done on FE and BE too
 * Will be moved to BE in the future
 * !! Cannot involve UI types
 * ********************************************/
export function checkFlowIntegrity(
  flow: FlowAPI,
  options: FlowIntegrityOptions,
  smsCampaign?: SMSCampaign
): void {
  checkFlowDiagramIntegrity(flow.flowEditorDiagram)

  if (flow.hasEverBeenEnabled !== true && flow.isEnabled === true) {
    throw new Error('Flow hasEverBeenEnabled is false, but its enabled')
  }

  const triggerNode = flow.flowEditorDiagram.nodes.find((node) => node.type === NodeType.TRIGGER)
  const triggerHandle = triggerNode?.data.followUpHandle

  const edges = flow.flowEditorDiagram.edges
  if (flow.entrySequenceItemId === undefined) {
    if (
      edges.find(
        (edge) => edge.source === triggerNode.id || edge.sourceHandle === triggerHandle.id
      ) !== undefined
    ) {
      throw new Error(
        'There is no entrySequenceItemId, still there is connection from trigger node'
      )
    }
  } else {
    const edgeFromTrigger = edges.find(
      (edge) => edge.source === triggerNode.id && edge.sourceHandle === triggerHandle.id
    )
    if (edgeFromTrigger === undefined) {
      throw new Error('There is entrySequenceItemId, but there is no connection from trigger node')
    }
    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.id === edgeFromTrigger.target
    )
    if (targetNode === undefined) {
      throw new Error('Cannot found node connected from trigger')
    }
    if (targetNode.data.flowItemId !== flow.entrySequenceItemId) {
      throw new Error(
        `entrySequenceItemId (${flow.entrySequenceItemId}) does not match the target node ${targetNode.data.flowItemId}`
      )
    }
  }

  const nodesWithoutTrigger = flow.flowEditorDiagram.nodes.filter(
    (node) => node.type !== NodeType.TRIGGER
  )

  if (nodesWithoutTrigger.length !== flow.sequenceItemIds.length) {
    throw new Error(
      `Length of nodes and sequenceItemIds should be equal. Nodes: ${nodesWithoutTrigger.length}, sequenceItemIds: ${flow.sequenceItemIds.length}`
    )
  }

  const flowItems = flow.sequenceItems

  if (nodesWithoutTrigger.length !== flowItems.length) {
    throw new Error('Length of nodes and sequenceItems should be equal')
  }

  const trigger = getFlowTrigger(flow.tags)

  /*********************
   * REQUIRED Flow tags
   * ********************/
  if (difference(getFlowTags('sms', trigger), flow.tags).length !== 0) {
    throw new Error(
      `Not all the required flow tags (${getFlowTags('sms', trigger).join(
        ', '
      )}) can be found among the flow tags: ${flow.tags.join(', ')}`
    )
  }

  for (const flowItem of flowItems) {
    for (const tag of flowItem.tags) {
      if (tagsBubbleUpFromFlowItems.includes(tag)) {
        if (!flow.tags.includes(tag)) {
          throw new Error(
            `Flow tag ${tag} is required because it present on one of the flow items, but it is not present on the flow tags: ${flow.tags.join(
              ', '
            )}`
          )
        }
      }
    }
  }

  /*********************
   * ALLOWED Flow tags
   * ********************/
  const notAllowedFlowTags = difference(flow.tags, getAllowedFlowTags('sms', trigger)).filter(
    (tag) => !isCustomIntegrationTag(tag)
  )
  const notAllowedDynamicTags = flow.tags.filter(
    (tag) => tag.startsWith(CUSTOM_INTEGRATION_SLUG_PREFIX) && !isCustomIntegrationTag(tag)
  )

  if (notAllowedDynamicTags.length > 0) {
    throw new Error(
      `Invalid dynamic tags: ${notAllowedDynamicTags.join(
        ', '
      )}. Dynamic tags must follow the 'integration-event-*' pattern.`
    )
  }
  if (notAllowedFlowTags.length !== 0) {
    throw new Error(
      `The following flow tags (${notAllowedFlowTags.join(
        ', '
      )}) are not allowed for the flow. All the allowed flow tags are: ${getAllowedFlowTags(
        'sms',
        trigger
      ).join(', ')}`
    )
  }

  /*****************
   * Automated flows
   ******************/
  if (isAutomatedFlowTrigger(trigger)) {
    switch (true) {
      case isWelcomeTrigger(trigger): {
        checkDefaultWelcomeFlowIntegrity(flow)
        break
      }
      case isCustomTrigger(trigger):
        checkCustomTriggerFlowIntegrity(flow)
        break
      case isSMSAbandonmentTrigger(trigger):
        checkSMSAbandonmentFlowIntegrity(flow)
        break
      case isOrderAndReceiptTrigger(trigger):
        checkOrderAndReceiptFlowIntegrity(flow)
        break
      case isWondermentTrigger(trigger):
        checkWondermentFlowIntegrity(flow)
        break
      case isLoopWorkTrigger(trigger):
        checkLoopFlowIntegrity(flow)
        break
      case isFulfillmentTrigger(trigger):
        checkFulfillmentFlowIntegrity(flow)
        break
      case isRechargeTrigger(trigger):
        checkRechargeFlowIntegrity(flow)
        break
      case isTypeformTrigger(trigger):
        checkTypeformFlowIntegrity(flow)
        break
      case isWheelioTrigger(trigger):
        checkWheelioFlowIntegrity(flow)
        break
      case trigger === Trigger.smsHelp:
        checkHelpFlowIntegrity(flow)
        break
      case isSmsResubscribeTrigger(trigger):
        checkSmsResubscribeFlowIntegrity(flow)
        break
      case isSmsUnsubscribeTrigger(trigger):
        checkSmsUnsubscribeFlowIntegrity(flow)
        break
      case isCustomIntegrationTrigger(trigger):
        checkCustomIntegrationFlowIntegrity(flow)
        break
      case isYotpoTrigger(trigger):
        checkYotpoFlowIntegrity(flow)
        break
      case IsYotpoReviewTrigger(trigger):
        checkYotpoFlowIntegrity(flow)
        break
      case isLoyaltyLionTrigger(trigger):
        checkLoyaltyLionFlowIntegrity(flow)
        break
      case isBoldSubscriptionTrigger(trigger):
        checkBoldFlowIntegrity(flow)
        break
      default:
        checkOtherAutomatedFlowIntegrity(flow)
        break
    }
  }

  /*****************
   * SMS campaigns
   *****************/
  if (isOneOffFlowTrigger(trigger)) {
    checkSMSCampaignFlowIntegrity(flow, smsCampaign)
  }

  /***********************
   * FlowItems integrity
   * **********************/

  flowItems.forEach((flowItem) => {
    /******************************
     * Check identical properties
     * ****************************/
    if (flow.sendingFrequency !== undefined || flowItem.sendingFrequency !== undefined) {
      if (
        flow.sendingFrequency.numberOfSentMessages !==
          flowItem.sendingFrequency.numberOfSentMessages ||
        flow.sendingFrequency.type !== flowItem.sendingFrequency.type
      ) {
        throw new Error(
          `Flow sendingFrequency settings (${JSON.stringify(
            flow.sendingFrequency,
            null,
            2
          )}) and flowItem (${flowItem._id}) sendingFrequency settings (${JSON.stringify(
            flowItem.sendingFrequency,
            null,
            2
          )}) should be the same`
        )
      }
    }

    if (flow.isEnabled !== flowItem.isEnabled) {
      throw new Error(
        `Flow isEnabled (${flow.isEnabled}) and flowItem (${flowItem._id}) isEnabled (${flowItem.isEnabled}) should be the same`
      )
    }

    if (flow.siteId !== flowItem.site) {
      throw new Error(
        `the siteId on the flow ${flow.siteId} should be the same as the siteId ${flowItem.site} on the flowItem with ${flowItem._id} id `
      )
    }

    if (flow.isSmartSendingEnabled !== flowItem.isSmartSendingEnabled) {
      throw new Error(
        `Flow isSmartSendingEnabled (${flow.isSmartSendingEnabled}) and flowItem (${flowItem._id}) isSmartSendingEnabled (${flowItem.isSmartSendingEnabled}) should be the same`
      )
    }

    /******************************
     * Check isQuietHoursEnabled properties - this check will be deprecated because BE sets item level QH
     * ****************************/
    if (flow.entrySequenceItemId) {
      const isQuietHoursEnabledByFlowItemId = getIsQuietHoursEnabledByFlowItemId(
        flow.sequenceItems,
        {
          isQuietHoursEnabled: flow.isQuietHoursEnabled,
          entrySequenceItemId: flow.entrySequenceItemId
        }
      )
      if (isQuietHoursEnabledByFlowItemId[flowItem._id] !== flowItem.isQuietHoursEnabled) {
        throw new Error(
          `FlowItem (${flowItem._id}) isQuietHoursEnabled (${
            flowItem.isQuietHoursEnabled
          }) should be ${isQuietHoursEnabledByFlowItemId[flowItem._id]}`
        )
      }
    }

    /****************
     * Check by type
     * **************/
    if (isDelayFlowItemAPI(flowItem)) {
      checkDelayFlowItemIntegrity(flow, flowItem)
    }

    if (isRandomSplitFlowItemAPI(flowItem)) {
      checkRandomSplitFlowItemIntegrity(flow, flowItem)
    }

    if (isConditionalSplitFlowItemAPI(flowItem)) {
      checkConditionalSplitFlowItemIntegrity(flow, flowItem)
    }

    if (isConditionalSplitV2FlowItemAPI(flowItem)) {
      checkConditionalSplitV2Integrity(flow, flowItem)
    }

    if (isSMSMessageFlowItemAPI(flowItem)) {
      checkSMSMessageFlowItemIntegrity(flow, flowItem, options)
    }

    /*****************
     * FlowItem TAGS
     * ****************/
    // 1: Inheritance
    const inheritableFlowTags = getInheritableFlowTags('sms', trigger)
    for (const flowTag of flow.tags) {
      if (inheritableFlowTags.includes(flowTag)) {
        if (!flowItem.tags.includes(flowTag)) {
          throw new Error(
            `FlowItem tag ${flowTag} is required because it present on one of the flow tags, but it is not present on the flowItem: ${flowItem._id}`
          )
        }
      }
    }

    // 2: Required tags
    let requiredTags = []

    if (isEntryFlowItem(flow, flowItem)) {
      requiredTags = getEntryFlowItemTags(flow.tags)
      if (shouldBeLegalFlowItem(flow, flowItem) && !options.isLegalFlowItemDeletable) {
        requiredTags = getLegalFlowItemTags(flow.tags)
      }
    } else {
      requiredTags = getNonEntryFlowItemTags(flow.tags)
    }
    if (difference(requiredTags, flowItem.tags).length !== 0) {
      throw new Error(
        `Not all the required flowItem tags (${requiredTags.join(
          ', '
        )}) can be found among the flowItem tags: ${flowItem.tags.join(', ')}`
      )
    }

    // 3: Allowed tags
    const allowedTags = getAllowedFlowItemTags('sms', trigger)
    const notAllowedTags = difference(flowItem.tags, allowedTags).filter(
      (tag) => !isCustomIntegrationTag(tag)
    )
    if (notAllowedTags.length !== 0) {
      throw new Error(
        `The following flowItem tags (${notAllowedTags.join(
          ', '
        )}) are not allowed for the flowItem. All the allowed flowItem tags are: ${allowedTags.join(
          ', '
        )}`
      )
    }

    /*****************
     * Entry TRIGGER
     *****************/
    if (isEntryFlowItem(flow, flowItem)) {
      // flowItem.trigger >= getEntryFlowItemTrigger()
      const diffRequired = difference(
        getEntryFlowItemTrigger(trigger, flow._id, flowItem._id),
        flowItem.trigger
      )
      if (diffRequired.length !== 0) {
        throw new Error(
          `Entry FlowItem has doesn't have these required trigger(s): ${JSON.stringify(
            diffRequired
          )}. Required triggers are: ${JSON.stringify(
            getEntryFlowItemTrigger(trigger, flow._id, flowItem._id)
          )}`
        )
      }

      // getAllowedEntryFlowItemTrigger() >= flowItem.trigger
      const diffAllowed = difference(
        flowItem.trigger,
        getAllowedEntryFlowItemTriggers(trigger, flow._id, flowItem._id, flow.segmentId)
      ).filter((tag) => !isCustomIntegrationTag(tag))
      const diffAllowedDynamicTags = flowItem.trigger.filter(
        (tag) => tag.startsWith(CUSTOM_INTEGRATION_SLUG_PREFIX) && !isCustomIntegrationTag(tag)
      )
      if (diffAllowedDynamicTags.length > 0) {
        throw new Error(
          `Invalid dynamic tags on EntryFlowItem: ${diffAllowedDynamicTags.join(
            ', '
          )}. Dynamic tags must follow the 'integration-event-*' pattern.`
        )
      }
      if (diffAllowed.length !== 0) {
        throw new Error(
          `Entry FlowItem has unallowed trigger(s): ${JSON.stringify(
            diffAllowed
          )}. Allowed triggers are: ${JSON.stringify(
            getAllowedEntryFlowItemTriggers(trigger, flow._id, flowItem._id, flow.segmentId)
          )}`
        )
      }
    }

    /**********************
     * Non entry TRIGGER
     *********************/
    if (!isEntryFlowItem(flow, flowItem)) {
      if (!isEqual(flowItem.trigger, getNonEntryFlowItemTrigger(flowItem._id))) {
        throw new Error(
          `Non entry FlowItem trigger (${JSON.stringify(
            flowItem.trigger
          )}) is not valid, it should be: ${JSON.stringify(
            getNonEntryFlowItemTrigger(flowItem._id)
          )}`
        )
      }
    }
  })
}
