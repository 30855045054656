import { FacebookPage } from '@ghostmonitor/recartapis'
import { createSlice } from '@reduxjs/toolkit'
import { FacebookAdAccount } from '../../../types/facebook/facebook-list-item.type'
import { FbMessengerConfig } from '../../../types/facebook/facebook-messenger-config.type'
import {
  connectFacebookPageThunk,
  disconnectFacebookPageThunk
} from '../../modules/facebook/facebook.thunks'
import { meLoaded } from '../me/me.reducer'
import {
  authFacebookFailure,
  authFacebookSuccess,
  clearFacebookAuth,
  loadFacebookPagesFailure,
  loadFacebookPagesRequest,
  loadFacebookPagesSuccess
} from './facebook.actions'

export interface FacebookPageMeta {
  error: string | null
  nextPageLink: string | null
}

export interface FacebookState {
  authorizationToken: string | null
  messenger: {
    userId: string | null
  }
  facebookPages: FacebookPage.Page[]
  facebookPagesMeta: FacebookPageMeta
  fbMessengerConfig: FbMessengerConfig | null
  fbMessengerConfigMeta: {
    fbConnectionError: string | null
  }
  // todo remove fbAdAccountConfig completely
  fbAdAccountConfig?: FacebookAdAccount
  // todo remove fbAdAccountConfigMeta completely

  fbAdAccountConfigMeta?: {
    error: string | null
  }
}

export const facebookInitialState: FacebookState = {
  authorizationToken: null,
  messenger: {
    userId: null
  },
  facebookPages: [],
  facebookPagesMeta: {
    error: null,
    nextPageLink: null
  },
  fbMessengerConfig: null,
  fbMessengerConfigMeta: {
    fbConnectionError: null
  },
  // todo remove fbAdAccountConfig completely
  fbAdAccountConfig: undefined,
  fbAdAccountConfigMeta: undefined
}

const facebookSlice = createSlice({
  name: 'facebook',
  initialState: facebookInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLoaded, (state, action) => {
        state.fbMessengerConfig = action.payload.site.fbMessengerConfig

        // TODO https://app.clubhouse.io/recart/story/17975/me-should-return-correct-fbadaccount-and-fbmessengerconfig-schema
        if (action.payload.site.fbMessengerConfig?.isEnabled === undefined) {
          state.fbMessengerConfig = null
        }
      })
      .addCase(clearFacebookAuth, (state) => {
        state.messenger.userId = null
        state.fbMessengerConfigMeta.fbConnectionError = null
      })
      .addCase(authFacebookSuccess, (state, action) => {
        state.messenger.userId = action.payload.userId
        state.authorizationToken = action.payload.authorizationToken
      })
      .addCase(authFacebookFailure, (state, action) => {
        state.messenger.userId = null
        state.fbMessengerConfigMeta.fbConnectionError = action.payload.error
      })
      .addCase(connectFacebookPageThunk.fulfilled, (state, action) => {
        state.fbMessengerConfig = action.payload
      })
      .addCase(loadFacebookPagesRequest, (state) => {
        state.facebookPages = []
        state.facebookPagesMeta.error = null
        state.facebookPagesMeta.nextPageLink = null
      })
      .addCase(loadFacebookPagesSuccess, (state, action) => {
        state.facebookPages = action.payload.pages
      })
      .addCase(loadFacebookPagesFailure, (state, action) => {
        state.facebookPagesMeta.error = action.payload.error
      })

      .addCase(disconnectFacebookPageThunk.fulfilled, (state) => {
        state.fbMessengerConfig = null
      })
  }
})

export const { reducer: facebookReducer } = facebookSlice
