import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectFacebookPageId
} from '../../../../../store/selectors'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { CopyButton } from '../../copy-button/copy-button.component'

export function HeaderCopyButtons() {
  const sequence = useSelector(selectEditorSequence)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const pageId = useSelector(selectFacebookPageId)
  const editorSettings = useSequenceEditorSettings()

  if (editorSettings.hideMessengerSendTestButton) {
    return null
  }

  return (
    <>
      <CopyButton
        buttonText='Copy m.me link'
        copyText={`https://m.me/${pageId}?ref=sequence_${sequence._id}`}
        disabled={sequenceMeta.unsavedChanges}
      />
      <CopyButton
        buttonText='Copy bot payload'
        copyText={`sequence_${sequence._id}`}
        disabled={sequenceMeta.unsavedChanges}
      />
    </>
  )
}
