import { DashboardErrorMessages } from '../types/dashboard-errors'
import { cleanLocalStorage } from './clean-local-storage'
import { createScope } from './logger/logger'

const { error } = createScope('dashboard')

export function setLocalStorageItem(key: string, value: string, retries = 5): void {
  try {
    localStorage.setItem(key, value)
  } catch (err) {
    if (retries > 0 && err instanceof DOMException && err.name === 'QuotaExceededError') {
      cleanLocalStorage(key)
      setLocalStorageItem(key, value, retries - 1)
    } else {
      error(
        new Error(
          err instanceof DOMException && err.name === 'QuotaExceededError'
            ? DashboardErrorMessages.LOCAL_STORAGE_QUOTA_EXCEED_RETRY_LIMIT_REACHED
            : DashboardErrorMessages.LOCAL_STORAGE_ERROR_NOT_EXCEED_QUOTA
        )
      )
    }
  }
}
