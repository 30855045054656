export function changeIndexInTagId(tagId: string, index: number): string {
  const idParts = tagId.split('|')
  const idIndex = idParts.pop()
  if (!isNaN(Number(idIndex))) {
    idParts.push(index.toString())
  } else {
    idParts.push(idIndex)
  }
  return idParts.join('|')
}

export function getContentLength(content: string): number {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = content
  const child = holderElement.firstChild
  if (!child) {
    return 0
  }
  if (child.nodeType === Node.TEXT_NODE) {
    return child.nodeValue.length
  }
  if (child.nodeType === Node.ELEMENT_NODE) {
    return child.textContent.length
  }
  return 0
}

export function reindexVariableNodes(html: string): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html
  let newHtml = ''
  let index = 0
  for (const node of holderElement.childNodes) {
    const childNode = node as any
    if (childNode.nodeType === Node.ELEMENT_NODE && childNode.tagName === 'SPAN') {
      const tagId = childNode.getAttribute('id')
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttribute
      if (tagId !== null && tagId !== '') {
        const reindexedTagId = changeIndexInTagId(tagId, index)
        childNode.setAttribute('id', reindexedTagId)
        index += 1
      }
      newHtml += childNode.outerHTML
    } else if (childNode.nodeType === Node.TEXT_NODE) {
      newHtml += childNode.nodeValue
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      newHtml += (childNode as any).outerHTML
    }
  }
  return newHtml
}

export function insertContentAtCaretPosition(
  html: string,
  content: string,
  insertPosition: number
): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html

  let newHtml = ''
  let position = 0
  let inserted = false

  if (insertPosition <= 0) {
    newHtml += content
    inserted = true
  }

  for (const childNode of holderElement.childNodes) {
    if (childNode.nodeName === 'BR') {
      newHtml += (childNode as any).outerHTML
      position += 1
    } else if (childNode.nodeType === Node.ELEMENT_NODE) {
      newHtml += (childNode as any).outerHTML
      position += childNode.textContent.length
    } else if (childNode.nodeType === Node.TEXT_NODE) {
      const { nodeValue } = childNode
      if (
        !inserted &&
        position <= insertPosition &&
        insertPosition <= position + nodeValue.length
      ) {
        const startPosition = insertPosition - position
        newHtml += `${nodeValue.slice(0, startPosition)}${content}${nodeValue.slice(startPosition)}`
        position += nodeValue.length + getContentLength(content)
        inserted = true
      } else {
        newHtml += nodeValue
        position += nodeValue.length
      }
    }
    if (!inserted && position >= insertPosition) {
      newHtml += content
      inserted = true
    }
  }
  if (!inserted) {
    newHtml += content
  }

  return reindexVariableNodes(newHtml)
}
