import { batch, useDispatch } from 'react-redux'
import {
  clearSelection,
  focusMessage
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'

interface UseMessageControl {
  handleFocus: () => void
  handleBlur: () => void
}

export function useMessageControl(sequenceItemId: string, messageIndex: number): UseMessageControl {
  const dispatch = useDispatch()

  function handleFocus() {
    batch(() => {
      dispatch(clearSelection())
      dispatch(focusMessage({ sequenceItemId, messageIndex }))
    })
  }

  function handleBlur() {
    dispatch(clearSelection())
  }

  return {
    handleFocus,
    handleBlur
  }
}
