import { SequenceUI } from '@ghostmonitor/recartapis'
import {
  isTypeformSequence,
  isWelcomeSequence,
  isWheelioSequence
} from '../routes/SequenceEditor/utils/assert-sequence-type'
import { isBuiltInWelcomeSequence } from './is-built-in-welcome-sequence'

export function isCustomWelcomeSequence(sequence: SequenceUI): boolean {
  return (
    isWelcomeSequence(sequence) &&
    !isBuiltInWelcomeSequence(sequence) &&
    !isTypeformSequence(sequence) &&
    !isWheelioSequence(sequence)
  )
}
