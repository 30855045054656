import { BillingSubscription } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'

export function useMessengerSubscription(): UseResource<BillingSubscription> {
  const { data: billingSubscriptions, isLoading } = hooks.useBillingSubscriptions()

  const messengerSubscription = billingSubscriptions?.find(
    (subscription) => subscription.type === 'messenger'
  )

  return {
    data: messengerSubscription,
    isLoading
  }
}
