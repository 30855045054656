import { GetUsedKeywordsResponse, UsedKeywordsByCategory } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { api } from '../utils/api'
import { QUERY_NAME } from './resources/query-names'

export function useUsedKeywordNameValidator(isKeywordOptinValidation = false) {
  const { data } = useQuery<GetUsedKeywordsResponse>([QUERY_NAME.reservedKeywords], () => {
    return api.getUsedKeywords()
  })

  let reservedKeywordsByCategory: UsedKeywordsByCategory

  if (data) {
    reservedKeywordsByCategory = data.data
  }

  const validate = useCallback(
    (value: string): Promise<string | void> => {
      if (!reservedKeywordsByCategory) {
        return Promise.resolve()
      }

      const isReservedKeyword = reservedKeywordsByCategory.reserved
        ?.map((keyword) => keyword.toUpperCase())
        .includes(value.toUpperCase())

      const isReplyKeyword = reservedKeywordsByCategory.replyKeywords
        ?.map((keyword) => keyword.toUpperCase())
        .includes(value.toUpperCase())

      const isKeywordOptin = reservedKeywordsByCategory.optinTools
        ?.map((keyword) => keyword.toUpperCase())
        .includes(value.toUpperCase())

      if (isKeywordOptinValidation) {
        if (isReservedKeyword) {
          return Promise.reject(
            new Error(
              'Operational keywords such as Opt-in, Help, and Opt-out keywords cannot be added as they are reserved for system functionality.'
            )
          )
        }
        if (isReplyKeyword) {
          return Promise.reject(
            new Error(
              'Keywords used as keyword replies cannot be added as they are reserved for other functionality.'
            )
          )
        }
      }

      if (isReservedKeyword || isKeywordOptin) {
        return Promise.reject(new Error('Operational keywords cannot be added as keyword replies.'))
      }
      return Promise.resolve()
    },
    [reservedKeywordsByCategory, isKeywordOptinValidation]
  )

  return {
    validate
  }
}
