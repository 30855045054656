import {
  getTextFromMessage,
  hasTextOnMessage,
  MessengerMessageUI,
  SequenceItemUI,
  SMSMessageUI
} from '@ghostmonitor/recartapis'
import { hasMessageOnSequenceItemType } from '../../routes/SequenceEditor/utils/assert-sequence-item-type'
import {
  getVariablesFromRawValue,
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable,
  isUrlVariable
} from '../inline-editor/inline-editor-utils'
import { getDiscountCodeInfoFromVariable } from './get-discount-code-from-variable'
import { VariableInfo } from './types/varible-info.type'

type VariableInfoItem = VariableInfo & {
  uniqueKey?: string
}

export function getItemVariableInfo(sequenceItem: SequenceItemUI): VariableInfo[] {
  if (!hasMessageOnSequenceItemType(sequenceItem)) {
    return []
  }

  const info: VariableInfoItem[] = []

  sequenceItem.messages.forEach((message: MessengerMessageUI | SMSMessageUI) => {
    if (hasTextOnMessage(message)) {
      const text = getTextFromMessage(message)
      const variables = getVariablesFromRawValue(text)
      variables.forEach((variable, index) => {
        if (isStaticDiscountCodeVariable(variable) || isUniqueDiscountCodeVariable(variable)) {
          const discountCodeInfo = getDiscountCodeInfoFromVariable(variable)
          info.push({
            ...discountCodeInfo,
            uniqueKey: discountCodeInfo.discount
          })
        }
        if (isUrlVariable(variable)) {
          const url = variable.params.value
          info.push({
            type: 'url',
            uniqueKey: `${index}${url}`,
            url
          })
        }
      })
    }
  })

  const mergedInfo: Record<string, VariableInfo> = info.reduce((accumulatedMap, info) => {
    return {
      ...accumulatedMap,
      [info.uniqueKey]: {
        ...info
      }
    }
  }, {})

  return Object.values(mergedInfo)
}
