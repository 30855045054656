import { FlowAPI } from '@ghostmonitor/recartapis'

export function checkCustomIntegrationFlowIntegrity(flow: FlowAPI): void {
  if (flow.sendingFrequency !== undefined) {
    throw new Error('Trigger is Custom integration, but sendingFrequency is set')
  }

  if (flow.isQuietHoursEnabled === undefined) {
    throw new Error('isQuietHoursEnabled should be defined for custom integration flows')
  }
  if (flow.isSmartSendingEnabled !== false) {
    throw new Error(
      'isSmartSendingEnabled should be defined and false for custom integration flows'
    )
  }
}
