import { isMessengerMessageSequenceItemUI } from '@ghostmonitor/recartapis'
import { createScope } from '../../../../utils/logger/logger'
import { UpdateQuickReplyAction } from '../sequence-editor.actions'
import { selectQuickReplyMeta, selectSequenceItem } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')

export function updateQuickReplyActionHandler(
  state: SequenceEditorState,
  action: UpdateQuickReplyAction
) {
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  if (isMessengerMessageSequenceItemUI(sequenceItem)) {
    const quickReplyMeta = selectQuickReplyMeta(sequenceItem._id, action.quickReplyIndex)(state)
    if (action.quickReplyIndex !== -1) {
      sequenceItem.quickReplies[action.quickReplyIndex].title = action.title
      quickReplyMeta.edited = true
    } else {
      info('updateQuickReplyActionHandler:1', { action, sequenceItem })
    }
  } else {
    info('updateQuickReplyActionHandler:2', { action, sequenceItem })
  }
}
