import { createAsyncThunk } from '@reduxjs/toolkit'
import { setFlowEditorReloadNeededOnAllTabs } from '../../../../routes/flow-editor/utils/local-storage'
import { api } from '../../../../utils/api'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors } from '../../../selectors'
import { ThunkAPI } from '../../../types/thunk-api.type'
import { saveFlowThunk } from './save-flow.thunk'
import { validateFlowThunk, ValidateFlowThunkBypassWarnings } from './validate-flow.thunk'

export interface ToggleFlowEditorFlowThunkPayload extends ValidateFlowThunkBypassWarnings {
  isEnabled: boolean
}

// This thunk differs from toggleFlowThunk, because here, we are inside of FER and we do extra validations
// However toggleFlowThunk can be called from outside of FER, so it does not do extra validations
export const toggleFlowEditorFlowThunk = createAsyncThunk(
  'flowEditor/toggleFlow',
  async (payload: ToggleFlowEditorFlowThunkPayload, thunkAPI: any) => {
    const { getState, dispatch }: ThunkAPI = thunkAPI
    const state = getState()
    const flow = flowEditorSelectors.selectEditorFlow(state)
    const flowMeta = flowEditorSelectors.selectEditorFlowMeta(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    /***********************
     * BLOCKING validations
     ***********************/
    // Joined a Segments (UI name: Custom triggers) can be saved in draft without having segment
    // this is the reason why this validation is here instead of saveFlowThunk
    if (payload.isEnabled) {
      const flowValidationResult = await dispatch(
        validateFlowThunk({ joinedASegmentMustHaveSegment: true, ...payload })
      )
      if (validateFlowThunk.rejected.match(flowValidationResult)) {
        throw new Error(FlowEditorError.FlowValidationFailed, {
          cause: flowValidationResult.error
        })
      }
    }

    if (flowMeta.unsavedChanges || !flowMeta.isEverSaved) {
      const saveFlowResult = await dispatch(saveFlowThunk(payload))
      if (saveFlowThunk.rejected.match(saveFlowResult)) {
        throw new Error(FlowEditorError.ToggleFlowFailed, { cause: saveFlowResult.error })
      }
    }

    let response: 'OK' | undefined
    try {
      response = await api.toggleFlow(flow._id, payload.isEnabled)
    } catch (err: any) {
      throw new Error(FlowEditorError.ToggleFlowFailed, { cause: err })
    }

    if (response !== 'OK') {
      throw new Error(FlowEditorError.ToggleFlowFailed, {
        cause: `Response is "${response}", but it should be "OK"`
      })
    }

    setFlowEditorReloadNeededOnAllTabs(flow._id)

    return { isEnabled: payload.isEnabled }
  },
  { serializeError }
)
