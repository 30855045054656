import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../store/selectors'
import { hooks } from './hooks'
import { isSMSMessageFlowItemUI } from '../types/guards/flow-item-ui.guards'

export interface AttachedDiscountCodes {
  label: string
  discountCodeValue: string
  type: string
  fromSearch: boolean
}
export interface UseDiscountCodesAttachedToFlow {
  data: AttachedDiscountCodes[]
  isLoading: boolean
}

export function useDiscountCodesAttachedToFlow(): UseDiscountCodesAttachedToFlow {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } =
    hooks.useDiscountCodePools()

  const flowItems = useSelector(flowEditorSelectors.selectEditorFlowItems)

  const attachedDiscountPoolIds = flowItems
    ?.filter((flowItem) => isSMSMessageFlowItemUI(flowItem) && flowItem.item.discountCodePoolId)
    .map((flowItem) => {
      if (isSMSMessageFlowItemUI(flowItem)) {
        return flowItem.item.discountCodePoolId
      }
      return null
    })

  const attachedDiscountPools = discountCodePools
    ?.filter((pool) => attachedDiscountPoolIds.includes(pool.id))
    .map((pool) => {
      return { label: pool.name, discountCodeValue: pool.id, type: 'unique' }
    })

  const attachedGeneralDiscountCodes: AttachedDiscountCodes[] = []

  flowItems.forEach((flowItem) => {
    if (isSMSMessageFlowItemUI(flowItem) && flowItem.item.discountCodes) {
      flowItem.item.discountCodes.forEach((discountCode) => {
        attachedGeneralDiscountCodes.push({
          label: discountCode,
          discountCodeValue: discountCode,
          type: 'static',
          fromSearch: false
        })
      })
    }
  })

  return {
    isLoading: isDiscountCodePoolsLoading || discountCodePools === undefined,
    data: [
      ...uniqWith(attachedDiscountPools, isEqual),
      ...uniqWith(attachedGeneralDiscountCodes, isEqual)
    ]
  }
}
