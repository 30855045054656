import chalk from 'chalk'

let lastDate = new Date()
export function debug(...args) {
  if (/debug=/.test(window.location.search)) {
    console.log(
      `+${chalk.bold(
        Math.round(new Date().getMilliseconds() - lastDate.getMilliseconds()).toString()
      )}ms ${chalk.blue(...args)}`
    )
  }
  lastDate = new Date()
}
