import {
  DiscountCode,
  DiscountType,
  DiscountTypes,
  StaticDiscountCode
} from '@ghostmonitor/recartapis'
import { Form, InputNumber } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { EditorButton } from '../../../../../../components/ui-kit/editor-button/editor-button.component'
import { Input } from '../../../../../../components/ui-kit/input/input.component'
import { Option, Select } from '../../../../../../components/ui-kit/select/select.component'
import { Toast } from '../../../../../../components/ui-kit/toast/toast.component'
import { hooks } from '../../../../../../hooks/hooks'
import { useDiscountCode } from '../../../../../../hooks/resources/use-discount-code'
import { useDiscountCodes } from '../../../../../../hooks/resources/use-discount-codes'
import { ReactComponent as ShopifyBagLogo } from '../../../../../../static/images/svg/logo-shopify-bag.svg'
import { ReactComponent as RecartLogoSmall } from '../../../../../../static/images/svg/recart-logo-sm.svg'
import { AppDispatch } from '../../../../../../store/create-store'
import { validateDiscountCodesThunk } from '../../../../../../store/slices/sequence-editor/thunks/validate-discount-codes.thunk'
import { createScope } from '../../../../../../utils/logger/logger'
import { webURLRegexp } from '../../../../../../utils/regexps/web-url-regexp'
import { DiscountTypeLabels } from '../../types/discount-type-labels'
import styles from './edit-static-discount-code.scss'

interface EditStaticDiscountCodeProps {
  discountCodeName: string
  url?: string
  footer: React.FC<{ discountCodeExists: boolean }>
  onAdd: (discountCode: DiscountCode, url?: string) => void
  onUpdate: (discountCode: DiscountCode, url: string) => void
  isEditing: boolean
}

const fullWidthLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  }
}

const { error } = createScope('sequence-editor')

export function EditStaticDiscountCode(props: EditStaticDiscountCodeProps) {
  const { data: me } = hooks.useMe()
  const siteDomain = me?.site.domain

  const defaultType = DiscountTypes.PERCENTAGE
  const defaultUrl = props.url || siteDomain

  const [type, setType] = useState<DiscountType>(defaultType)
  const [amount, setAmount] = useState(undefined)
  const [url, setUrl] = useState(defaultUrl)
  const [isLoading, setIsLoading] = useState(false)
  const { create, invalidateCache } = useDiscountCodes()
  const dispatch: AppDispatch = useDispatch()
  const { data: discountCode, isLoading: isDiscountCodeLoading } = useDiscountCode(
    props.discountCodeName
  )

  function clearForm() {
    setType(defaultType)
    setAmount(undefined)
    setUrl(defaultUrl)
  }

  useEffect(() => {
    if (discountCode) {
      setType(discountCode.type)
      if (discountCode.type !== DiscountTypes.FREE_SHIPPING) {
        setAmount(discountCode.amount * -1)
      }
    }
  }, [discountCode])

  function getEditedDiscountCode(): StaticDiscountCode {
    return {
      amount: -amount,
      codeType: 'STATIC',
      discountCode: props.discountCodeName,
      title: props.discountCodeName,
      type
    } as StaticDiscountCode
  }

  async function handleAdd() {
    const discountCode = getEditedDiscountCode()
    setIsLoading(true)
    try {
      await create(discountCode)
      invalidateCache(discountCode.discountCode)
      // TODO check result, error handling, reporting, etc.
      await dispatch(validateDiscountCodesThunk())
      props.onAdd(discountCode, url)
    } catch (err) {
      error(new Error('CreatingDiscountCodeError'), { discountCode })
      Toast({
        type: 'error',
        key: 'create-discount-code-error',
        content: 'Something went wrong, please try again later.'
      })
    }
    setIsLoading(false)
    clearForm()
  }

  function handleUpdate() {
    const discountCode = getEditedDiscountCode()
    props.onUpdate(discountCode, url)
  }

  function handleTypeChange(value: DiscountType) {
    setType(value)
  }

  function handleDiscountValueChange(value: number) {
    setAmount(value)
  }

  function handleDiscountUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUrl(event.target.value)
  }

  function isCreateDiscountCodeButtonDisabled(): boolean {
    const isInvalidAmount = isNaN(amount) || amount < 0
    if (isInvalidAmount && type !== DiscountTypes.FREE_SHIPPING) {
      return true
    }
    if (type === DiscountTypes.PERCENTAGE && amount > 100) {
      return true
    }
    if (props.isEditing && !webURLRegexp.test(url)) {
      return true
    }
    if (!props.isEditing && url !== '' && !webURLRegexp.test(url)) {
      return true
    }
    return false
  }

  function parseNumber(val: string) {
    if (val === '') {
      return 0
    }
    return parseInt(val)
  }

  function getAmountValidation(type: DiscountType): {
    helpText: string | null
    validationResult: ValidateStatus
  } {
    if (type === DiscountTypes.PERCENTAGE && (amount < 0 || amount > 100)) {
      return {
        helpText: 'Should be between 0 and 100',
        validationResult: 'error'
      }
    } else if (type !== DiscountTypes.PERCENTAGE && amount < 0) {
      return {
        helpText: 'Could not be below 0',
        validationResult: 'error'
      }
    }
    return { helpText: null, validationResult: 'success' }
  }

  return (
    <div className={styles.container}>
      <h2 className='px-0 pt-2 pb-1 text-sm font-bold'>Add discount code</h2>
      <p className={cn(styles.text, 'text-tiny mb-2')}>
        Your discount code will be added to your Shopify store by default.
      </p>
      <Form layout='vertical'>
        <Form.Item
          {...fullWidthLayout}
          label='Discount code name'
          labelAlign='left'
          colon={false}
          data-testid='discount-code-input'
          className={styles.formItem}
        >
          <Input
            data-testid='discount-code-input'
            size='middle'
            disabled
            value={props.discountCodeName}
          />
          <div className='absolute top-0 right-2 bottom-0 flex items-center'>
            <div className='icon-xs'>
              {discountCode === undefined ? <RecartLogoSmall /> : <ShopifyBagLogo />}
            </div>
          </div>
        </Form.Item>

        <Form.Item
          {...fullWidthLayout}
          label='Discount type'
          labelAlign='left'
          colon={false}
          data-testid='discount-type-input'
          className={styles.formItem}
        >
          {discountCode !== undefined || isDiscountCodeLoading ? (
            <Input
              data-testid='discount-code-type'
              size='middle'
              disabled
              value={DiscountTypeLabels[discountCode?.type]}
            />
          ) : (
            <Select
              defaultValue={DiscountTypes.PERCENTAGE}
              size='middle'
              onChange={handleTypeChange}
            >
              {Object.keys(DiscountTypeLabels).map((value) => {
                return (
                  <Option key={value} value={value}>
                    {DiscountTypeLabels[value]}
                  </Option>
                )
              })}
            </Select>
          )}
        </Form.Item>

        {type !== DiscountTypes.FREE_SHIPPING && (
          <Form.Item
            {...fullWidthLayout}
            label='Discount value'
            labelAlign='left'
            colon={false}
            data-testid='discount-code-value'
            className={styles.formItem}
            tooltip={
              type === DiscountTypes.FIXED_AMOUNT ? 'Discount value is in your store currency' : ''
            }
            validateStatus={getAmountValidation(type).validationResult}
            help={getAmountValidation(type).helpText}
          >
            <InputNumber
              type='number'
              parser={parseNumber}
              placeholder='e.g.: 10'
              value={amount}
              size='middle'
              onChange={handleDiscountValueChange}
              data-testid='discount-code-value'
              disabled={discountCode !== undefined || isDiscountCodeLoading}
            />
          </Form.Item>
        )}
        <Form.Item
          {...fullWidthLayout}
          label='Destination URL'
          labelAlign='left'
          colon={false}
          data-testid='discount-code-url'
          className={styles.formItem}
          validateStatus={url && !webURLRegexp.test(url) ? 'error' : 'success'}
          help={url && !webURLRegexp.test(url) ? 'Invalid URL format.' : null}
          tooltip="Enter the link your subscribers should be directed to. Your store's home page is added by default. Your discount code is embedded in this link."
        >
          <Input
            defaultValue={props.url || siteDomain}
            onChange={handleDiscountUrlChange}
            data-testid='discount-code-url'
            size='middle'
          />
        </Form.Item>
        <Form.Item className={styles.formButtonWrapper}>
          {discountCode !== undefined ? (
            <>
              <EditorButton
                type='primary'
                className='w-full'
                onClick={handleUpdate}
                disabled={isCreateDiscountCodeButtonDisabled()}
                loading={isLoading}
              >
                Save changes
              </EditorButton>
              <props.footer discountCodeExists={discountCode !== undefined} />
            </>
          ) : (
            <>
              <EditorButton
                type='primary'
                className='w-full'
                onClick={handleAdd}
                disabled={isCreateDiscountCodeButtonDisabled()}
                loading={isLoading}
              >
                Add discount code
              </EditorButton>
              <props.footer discountCodeExists={discountCode !== undefined} />
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
