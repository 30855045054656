import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import { MessageMeta } from '../../../../store/slices/sequence-editor/sequence-editor.state'
import { replaceNonBreakingSpaces } from '../../../../utils/inline-editor/inline-editor-utils'
import { InlineEditorPlugin } from '../../../../utils/inline-editor/types/inline-editor-plugin.type'
import { convertHTMLtoPlainText } from '../../../../utils/rich-text-editor/convert-html'
import { debug } from '../../utils/debug'
export interface VariableClickEventTarget extends HTMLSpanElement {
  id: string
}

export interface VariableMouseClickEvent extends React.MouseEvent<HTMLSpanElement> {
  target: VariableClickEventTarget
}

export interface InlineEditorProps {
  onChange?: (rawValue: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onClick?: (event: VariableMouseClickEvent) => void
  editorRef?: React.MutableRefObject<HTMLDivElement>
  onMouseMove?: (event: MouseEvent<HTMLDivElement>) => void
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void
  onCaretPositionChange?: (value: number) => void
  onCharCountChange?: (value: number) => void
  onInsertMethodChange?: (value: (insertedValue: string, position: number) => string) => void
  defaultValue?: string
  isEditable: boolean
  characterLimit?: number
  html?: string
  rawValue?: string
  plugins?: InlineEditorPlugin[]
  getCharacterCount?: (value: string) => number
  multiline?: boolean
  placeholder?: string
  messageMeta?: MessageMeta
  sequenceItemId?: string
  subdomain?: string
}

interface UseInlineEditorHOCControl {
  html: string
  setHtml: (html: string) => void
  editorRef: React.MutableRefObject<HTMLDivElement>
  rawValue: string
  setRawValue: (value: string) => void
  caretPosition: number
  charCount: number
  isFocus: boolean
  currentNodeIndex: number
  insertContent: (insertedValue: string, position: number) => string
  handlers: {
    onChange: (value: string) => void
    onCaretPositionChange: (value: number) => void
    onCharCountChange: (value: number) => void
    onInsertMethodChange: (value: (insertedValue: string, position: number) => string) => void
    onFocus: () => void
    onBlur: () => void
  }
}

export function useInlineEditorHOCControl(
  name: string,
  props: InlineEditorProps,
  replaceHtmlValueToRawValue: (value: string) => string,
  replaceRawValueToHtmlValue: (value: string, subdomain?: string) => string
): UseInlineEditorHOCControl {
  const [rawValue, setRawValue] = useState(null)
  const [html, setHtml] = useState(null)
  const [currentNodeIndex, setCurrentNodeIndex] = useState(0)
  const [caretPosition, setCaretPosition] = useState(null)
  const [charCount, setCharCount] = useState(0)
  const [isFocus, setIsFocus] = useState(false)
  const [insertContent, setInsertContent] =
    useState<(insertedValue: string, position: number) => string>()
  const defaultEditorRef = useRef<HTMLDivElement>()
  const editorRef = props.editorRef ?? defaultEditorRef

  // Data goes UP
  function handleChange(newHtmlValue: string) {
    const newRawValue = replaceHtmlValueToRawValue(newHtmlValue)
    debug(`RAW up [${name}] - ${newHtmlValue} ==> ${newRawValue}`)

    // DATA TURNAROUND
    // There is NO plugin above me
    if (props.html === undefined) {
      // It's NOT the initial round
      if (html !== null && rawValue !== newRawValue) {
        const plainText = replaceNonBreakingSpaces(convertHTMLtoPlainText(newRawValue))
        props.onChange?.(plainText)
      }
      debug(`RAW turn down [${name}] - ${newRawValue}`)
      setRawValue(newRawValue)
      setHtml(replaceRawValueToHtmlValue(newRawValue, props.subdomain))
      // There is a plugin above me
    } else {
      props.onChange?.(newRawValue)
    }
  }

  // Data comes DOWN, CONTROLLED MODE
  useEffect(() => {
    if (props.html !== undefined) {
      debug(
        `HTML down [${name}] - ${props.html} ==> ${replaceRawValueToHtmlValue(
          props.html,
          props.subdomain
        )}`
      )
      setHtml(replaceRawValueToHtmlValue(props.html, props.subdomain))
    }
  }, [props.html])

  // Only completely processed raw data from the TOP
  useEffect(() => {
    if (props.rawValue !== undefined && rawValue !== props.rawValue) {
      setRawValue(props.rawValue)
    }
  }, [props.rawValue, rawValue])

  function handleCaretPositionChange(value: number | null): void {
    if (value !== null) {
      setCaretPosition(value)
      const nodeAtCaret = document.getSelection().anchorNode
      if (nodeAtCaret !== null && defaultEditorRef?.current) {
        defaultEditorRef.current.childNodes.forEach((node, index) => {
          if (node === nodeAtCaret) {
            setCurrentNodeIndex(index)
          }
        })
      }
      props.onCaretPositionChange?.(value)
    }
  }

  function handleCharCountChange(value: number): void {
    setCharCount(value)
    props.onCharCountChange?.(value)
  }

  function handleFocus() {
    setIsFocus(true)
    props.onFocus?.()
  }

  function handleBlur() {
    setIsFocus(false)
    props.onBlur?.()
  }

  function handleInsertMethodChange(func: (insertedValue: string, position: number) => string) {
    setInsertContent(() => func)
    props.onInsertMethodChange?.(func)
  }

  return {
    html,
    setHtml,
    editorRef,
    rawValue,
    setRawValue,
    caretPosition,
    charCount,
    isFocus,
    currentNodeIndex,
    insertContent,
    handlers: {
      onChange: handleChange,
      onCaretPositionChange: handleCaretPositionChange,
      onCharCountChange: handleCharCountChange,
      onInsertMethodChange: handleInsertMethodChange,
      onFocus: handleFocus,
      onBlur: handleBlur
    }
  }
}
