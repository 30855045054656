import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Location } from 'history'
import { history } from '../../../routing-utilities/history/history'

export interface LocationState {
  previousLocation: {
    pathname: string
    search: string
    hash: string
  } | null
  currentLocation: {
    pathname: string
    search: string
    hash: string
  }
}

export const locationInitialState: LocationState = {
  previousLocation: null,
  currentLocation: history.location
}

const locationSlice = createSlice({
  name: 'location',
  initialState: locationInitialState,
  reducers: {
    updateLocation(state, action: PayloadAction<Location>) {
      const pathname = state.currentLocation?.pathname
      // Because we have to avoid to store the /sequence|flow-editor/** path as a previous location
      const shouldUpdatePreviousLocation =
        !pathname.includes('/sequence-editor') && !pathname.includes('flow-editor')
      if (shouldUpdatePreviousLocation) {
        state.previousLocation = state.currentLocation
      }
      state.currentLocation = action.payload
    }
  }
})

export const { updateLocation } = locationSlice.actions

export const { reducer: locationReducer } = locationSlice
