import { WebUrlButton } from '@ghostmonitor/recartapis'
import React from 'react'
import { ControlledTextField } from '../controlled-text-field.component'
import { ButtonPopoverFormProps } from '../types/button-popover-form.type'

export function WebUrlPopoverWithoutDiscountCodeForm(props: ButtonPopoverFormProps<WebUrlButton>) {
  return (
    <ControlledTextField
      label='Button url'
      value={props.payload.url}
      placeholder='http://example.com'
      onChange={(url) => props.onChange({ url })}
      onBlur={props.onBlur}
    />
  )
}
