import { HttpError } from '@ghostmonitor/recartapis'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { redirectToLogin } from './auth'
import { axiosInstance } from './axios-instance'
import { getSerializedErrorFromAxiosResponse, isHttpErrorResponse } from './errors'
import { config } from '../config'

const aiEndpoints = ['ai-text-generations']

if (config.AI_PROXY) {
  axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('x-auth-token')
    if (authToken) {
      config.headers['X-Auth-Token'] = authToken
    }

    if (aiEndpoints.includes(config.url)) {
      config.baseURL = `http://localhost:8888/api`
    }
    return config
  })
}

export function handleSuccess<T>(response: AxiosResponse): T {
  return response.data
}

export function handleError(err: AxiosError<HttpError & { error: { inputErrors: any[] } }>): void {
  if ([401, 403].includes(err.response?.status)) {
    // https://app.clubhouse.io/recart/story/17525/recator-login-redirect-from-api
    redirectToLogin()
  }

  // todo remove this extra condition as soon as getserializederror is ready to handle params prop
  if (err.response.data?.error?.inputErrors === undefined && isHttpErrorResponse(err.response)) {
    throw getSerializedErrorFromAxiosResponse(err.response)
  }

  throw err
}

const defaultGetOptions = {
  paramsSerializer: {
    serialize: (params) => {
      let paramsPlainObject = {}
      if (params instanceof URLSearchParams) {
        params.forEach((value, key) => {
          paramsPlainObject[key] = value
        })
      } else {
        paramsPlainObject = params
      }

      return qs.stringify(paramsPlainObject, {
        arrayFormat: 'repeat',
        encode: false
      })
    }
  }
}

async function axiosRequest<T>(
  method: string,
  path: string,
  data?: AxiosRequestConfig['data'],
  config?: AxiosRequestConfig
): Promise<T> {
  return axiosInstance({
    url: path,
    method,
    data,
    ...config
  })
    .then((response) => handleSuccess<T>(response))
    .catch(handleError) as Promise<T>
}

const get = <T>(path: string, config: AxiosRequestConfig = {}): Promise<T> =>
  axiosRequest<T>('get', path, undefined, Object.assign({}, defaultGetOptions, config))
const post = <T>(path: string, data = {}, config: AxiosRequestConfig = {}): Promise<T> =>
  axiosRequest<T>('post', path, data, config)
const put = <T>(path: string, data = {}, config: AxiosRequestConfig = {}): Promise<T> =>
  axiosRequest<T>('put', path, data, config)
const patch = <T>(path: string, data = {}, config: AxiosRequestConfig = {}): Promise<T> =>
  axiosRequest<T>('patch', path, data, config)
const del = <T>(path: string, config: AxiosRequestConfig = {}): Promise<T> =>
  axiosRequest<T>('delete', path, undefined, config)
const head = <T>(
  path: string,
  config: AxiosRequestConfig = {}
): Promise<{ headers: { [key: string]: string }; statusCode: number }> =>
  axiosRequest('head', path, undefined, config)

export const request = {
  axiosApi: axiosInstance,
  get,
  post,
  put,
  patch,
  del,
  head
}
