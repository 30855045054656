import { SerializedDiagram } from '@ghostmonitor/recartapis'

export function sanitizeSerializedDiagram(serializedDiagram: SerializedDiagram): SerializedDiagram {
  // Removes undefined values
  const newSerializedDiagram: SerializedDiagram = JSON.parse(JSON.stringify(serializedDiagram))

  // Moving the canvas doesn't count as change
  delete newSerializedDiagram.offsetX
  delete newSerializedDiagram.offsetY

  // Zooming doesn't count as change
  delete newSerializedDiagram.zoom

  // Link position calculation is not reliable, because it provides floating numbers
  // If sequence item position is changed, we can detect it by other features
  newSerializedDiagram.links?.forEach((link) => {
    link.points[0].x = 0
    link.points[0].y = 0
    link.points[1].x = 0
    link.points[1].y = 0
  })

  newSerializedDiagram.nodes?.forEach((node) => {
    delete node.selected

    // TODO: remove after data flow it enabled and users had a chance to update their localStorage instance
    node.ports.forEach((port) => {
      delete port.buttonIndex
      delete port.messageIndex
      delete port.messageItemIndex
    })
  })

  return newSerializedDiagram
}
