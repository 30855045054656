import React from 'react'
import { useSelector } from 'react-redux'
import { ShimmerRow } from '../../../../components/facebook-account-list/shimmer-row/shimmer-row.component'
import { hooks } from '../../../../hooks/hooks'
import { selectCurrencySlug } from '../../../../store/selectors'
import { formatters } from '../../../../utils/formatters/formatters'
import { BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import { Stat } from '../stat/stat.component'

interface SequenceItemStatsBarSMSProps {
  sequenceItemNode: BaseSequenceItemNodeModel
}

export function SequenceItemStatsBarSMS(props: SequenceItemStatsBarSMSProps) {
  const sequenceItemId = props.sequenceItemNode.sequenceItemId
  const {
    data: sequenceItemStat,
    isError: isSequenceItemStatLoadingError,
    isLoading: isSequenceItemsStatLoading
  } = hooks.useEditorSequenceItemStat(sequenceItemId)
  const currencySlug = useSelector(selectCurrencySlug)

  if (isSequenceItemsStatLoading) {
    return <ShimmerRow />
  }

  if (isSequenceItemStatLoadingError) {
    return null
  }

  const sales = sequenceItemStat.salesTotal[currencySlug] ?? 0

  return (
    <>
      <Stat key='sent' value={formatters.number(sequenceItemStat.sent)} label='sent' />
      <Stat
        key='click-rate'
        value={formatters.percentage(sequenceItemStat.clickRate, 1)}
        label='CTR'
        title='click rate'
      />
      <Stat key='sales' value={formatters.currency(Math.ceil(sales), currencySlug)} label='sales' />
      <Stat
        key='opt-out-rate'
        value={formatters.percentage(sequenceItemStat.optOutRate?.sms, 1)}
        label='opt-out'
        title='opt-out rate'
      />
    </>
  )
}
