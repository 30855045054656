// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GLinUsJ7U38uXxs5sXLf{color:#979ba1;font-size:14px}.vauCiKpN80OmVTSKUY0p{color:#979ba1;height:13px;margin-left:4px;width:13px}.ozAZptgI25nZCfDYHnk3{color:#476dff}.vauCiKpN80OmVTSKUY0p:hover{color:#476dff}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sms-counter-box copy/sms-counter-box.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAEE,aCSU,CDVV,cCUU,CDNZ,sBACE,aAAA,CAEA,WAAA,CACA,eAAA,CAFA,UAEA,CAGF,sBACE,aCbY,CDgBd,4BACE,aCjBY","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counterText": `GLinUsJ7U38uXxs5sXLf`,
	"icon": `vauCiKpN80OmVTSKUY0p`,
	"isBlue": `ozAZptgI25nZCfDYHnk3`
};
export default ___CSS_LOADER_EXPORT___;
