// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dhjeaXdVMCLNE5Ec0AY2{margin-top:10px;position:relative}.dhjeaXdVMCLNE5Ec0AY2:hover .qLsjvN0MVqVY9rIXTHYq{opacity:1}.dhjeaXdVMCLNE5Ec0AY2 .VcjhSmdvJWxFyRGOp0q0{display:flex}.dhjeaXdVMCLNE5Ec0AY2 .qLsjvN0MVqVY9rIXTHYq{left:0;opacity:0;position:absolute;right:0;text-align:center;top:-22px;transition:all .15s linear;z-index:10}.dhjeaXdVMCLNE5Ec0AY2 .qLsjvN0MVqVY9rIXTHYq .WPmIOPGidB9JKxI5_sng{background-color:#476dff;border-top-left-radius:5px;border-top-right-radius:5px;color:#fff;display:inline-block;font-size:10px;min-width:60px;padding:1px 5px 2px;text-align:center}.dhjeaXdVMCLNE5Ec0AY2 .saQMKFr6OsQMjSTSzZIP{border-right:1px solid #c8c8c8;box-shadow:0 0 25px 0 rgba(0,0,0,.2);box-sizing:border-box;overflow:hidden;z-index:2}.dhjeaXdVMCLNE5Ec0AY2 .saQMKFr6OsQMjSTSzZIP:first-child{border-bottom-left-radius:7px;border-top-left-radius:7px}.dhjeaXdVMCLNE5Ec0AY2 .saQMKFr6OsQMjSTSzZIP:last-child{border-bottom-right-radius:7px;border-right:none;border-top-right-radius:7px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/canvas-toolbar/canvas-button-group/canvas-button-group.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAEE,eAAA,CADA,iBACA,CAGE,kDACE,SAAA,CAIJ,4CACE,YAAA,CAGF,4CAEE,MAAA,CAEA,SAAA,CAHA,iBAAA,CAEA,OAAA,CAEA,iBAAA,CACA,SAAA,CACA,0BAAA,CACA,UAAA,CAEA,kEAEE,wBC1BQ,CD2BR,0BAAA,CACA,2BAAA,CAEA,UAAA,CALA,oBAAA,CAMA,cAAA,CACA,cAAA,CAHA,mBAAA,CAIA,iBAAA,CAIJ,4CAEE,8BAAA,CAGA,oCAAA,CADA,qBAAA,CADA,eAAA,CAFA,SAIA,CAEA,wDAEE,6BAAA,CADA,0BACA,CAGF,uDAGE,8BAAA,CAFA,iBAAA,CACA,2BACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `dhjeaXdVMCLNE5Ec0AY2`,
	"label-wrapper": `qLsjvN0MVqVY9rIXTHYq`,
	"group": `VcjhSmdvJWxFyRGOp0q0`,
	"label": `WPmIOPGidB9JKxI5_sng`,
	"group-item": `saQMKFr6OsQMjSTSzZIP`
};
export default ___CSS_LOADER_EXPORT___;
