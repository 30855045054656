// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AtZg18wG2EoytPLnrCSh{margin-bottom:6px}.o5coOl_jvTtp5D1RDIov .ant-btn{margin-top:12px;width:100%}.o5coOl_jvTtp5D1RDIov .ant-btn-link{color:#8c8c8c}.o5coOl_jvTtp5D1RDIov .ant-btn-link span{text-decoration:underline}.o5coOl_jvTtp5D1RDIov .ant-form-item{margin-bottom:6px}.o5coOl_jvTtp5D1RDIov .ant-input-number{width:100%}.o5coOl_jvTtp5D1RDIov .ant-input-number-handler-wrap{display:none}.o5coOl_jvTtp5D1RDIov .ant-form-item-has-error{background-color:#fff1f0}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-create.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,iBAAA,CAKE,+BAEE,eAAA,CADA,UACA,CAGF,oCACE,aCEC,CAAA,yCDCC,yBAAA,CAIJ,qCACE,iBAAA,CAGF,wCACE,UAAA,CAGF,qDACE,YAAA,CAGF,+CACE,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AtZg18wG2EoytPLnrCSh`,
	"form": `o5coOl_jvTtp5D1RDIov`
};
export default ___CSS_LOADER_EXPORT___;
