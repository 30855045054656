import {
  Channel,
  ConditionalSplitSequenceItemUI,
  LogicType,
  NodeType,
  PortType,
  SequenceItemType
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { conditionList } from '../../../components/sequence-item-node-widget/conditional-split-node-widget/conditional-split-sequence-item-node-widget.component'
import { LogicPortModel } from '../../port/logic-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'
import { LOGIC_PORT_CONDITION_MET, LOGIC_PORT_CONDITION_UNMET } from '../port-names'

export function getNewConditionalSplitSequenceItem(
  siteId: string,
  channel: Channel
): ConditionalSplitSequenceItemUI {
  const sequenceItemId = new ObjectId().toHexString()
  const now = new Date().toISOString()

  return {
    _id: sequenceItemId,
    type: SequenceItemType.LOGIC,
    siteId,
    trigger: [sequenceItemId],
    createdAt: now,
    updatedAt: now,
    canConvert: false,
    channel,
    tags: [],
    logic: {
      type: LogicType.CONDITIONAL_SPLIT,
      conditionalSplit: {
        defaultTrigger: undefined,
        variants: [
          {
            conditions: [conditionList.hasItemsInCart],
            trigger: undefined
          }
        ]
      }
    }
  }
}

export class ConditionalSplitSequenceItemNodeModel extends BaseSequenceItemNodeModel {
  constructor(sequenceItemId?: string, id?: string) {
    super(NodeType.CONDITIONAL_SPLIT_SEQUENCE_ITEM, sequenceItemId, id)
    this.generatePorts()
  }

  private generatePorts() {
    this.addPort(new LogicPortModel(LOGIC_PORT_CONDITION_MET))
    this.addPort(new LogicPortModel(LOGIC_PORT_CONDITION_UNMET))
  }

  public getLogicPorts(): LogicPortModel[] {
    return this.getPortsByType(PortType.LOGIC) as LogicPortModel[]
  }

  public removeLogicPorts(): void {
    const logicPorts = this.getLogicPorts()
    logicPorts.forEach((logicPort) => {
      Object.values(logicPort.getLinks()).forEach((link) => {
        link.remove()
      })
      this.removePort(logicPort)
      logicPort.remove()
    })
  }
}
