import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { Modal } from '../../../../../components/ui-kit/modal/modal.component'
import { Toast } from '../../../../../components/ui-kit/toast/toast.component'
import { ReactComponent as ArrowLeftIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/40-Keyboard/keyboard-arrow-left.svg'
import { ReactComponent as ExclamationIcon } from '../../../../../static/ui-kit/icons/normal/exclamation.svg'
import { AppDispatch } from '../../../../../store/create-store'
import { DashboardState } from '../../../../../store/dashboard.state'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectErrorCount,
  selectIsReadOnly,
  selectUnsavedChanges
} from '../../../../../store/selectors'
import { removeSequence } from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import styles from '../header.component.scss'

export function BlastHeaderBack() {
  const dispatch: AppDispatch = useDispatch()
  const editorSettings = useSequenceEditorSettings()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [isBlastModalOpen, setIsBlastModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isReadOnly = useSelector(selectIsReadOnly)
  const hasUnsavedChanges = useSelector(selectUnsavedChanges)
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const errorCount = useSelector(selectErrorCount)
  const sequence = useSelector(selectEditorSequence)

  const previousLocation = useSelector((state: DashboardState) => state.location.previousLocation)

  const searchParams = new URLSearchParams(location.search)
  const backTo = searchParams.get('backTo')
  const locationFrom = {
    fromSlug: pathname,
    id: sequence._id
  }
  const { saveSequenceEditor } = useSaveSequenceEditor()

  function handleCloseModal() {
    setIsBlastModalOpen(false)
  }

  function handleBlastCancel() {
    dispatch(removeSequence())
    if (backTo) {
      if (sequenceMeta.isEverSaved) {
        navigate(backTo, { state: locationFrom })
      }
      navigate(backTo)
    } else {
      navigate(
        previousLocation?.pathname?.length > 0 ? previousLocation.pathname : '/campaign-flows'
      )
    }
    setIsBlastModalOpen(false)
  }

  async function handleBlastOk() {
    setIsLoading(true)
    try {
      await saveSequenceEditor()
      navigate('/campaign-flows')
      Toast({
        type: 'success',
        key: `toast-draft-saved`,
        content: 'Your campaign is saved as draft.'
      })
    } catch (err) {
      setIsBlastModalOpen(false)
      setIsLoading(false)
    }
  }

  function renderBlastOnLeaveModalContent() {
    return (
      <div data-testid='editor-blast-exit-modal' className={styles.modalContainer}>
        <div className='flex flex-row'>
          <div className='mr-3 icon-sm'>
            <ExclamationIcon />
          </div>
          <div>
            <div className={styles.blasModaltitle}>{editorSettings.leaveEditorModal.title}</div>
            <span className={styles.blastModaltext}>
              {editorSettings.leaveEditorModal.content}{' '}
            </span>
          </div>
        </div>
        <div className='flex justify-end mt-4 items-center'>
          <EditorButton onClick={handleBlastCancel} type='text' disabled={isLoading}>
            {editorSettings.leaveEditorModal.cancelText}
          </EditorButton>
          <EditorButton
            type='primary'
            onClick={handleBlastOk}
            disabled={isLoading || errorCount > 0}
          >
            {editorSettings.leaveEditorModal.okText}
          </EditorButton>
        </div>
      </div>
    )
  }

  function handleEditorLeave() {
    if (isReadOnly || !hasUnsavedChanges) {
      navigate('/campaign-flows')
    } else {
      setIsBlastModalOpen(true)
    }
  }

  return (
    <>
      <Modal
        open={isBlastModalOpen}
        onCancel={handleCloseModal}
        width={580}
        className={styles.blastModalContainer}
        centered
        maskClosable
        closable
        footer={null}
      >
        {renderBlastOnLeaveModalContent()}
      </Modal>
      <div
        className={styles.back}
        onClick={handleEditorLeave}
        data-testid='editor-blast-exit-button'
      >
        <ArrowLeftIcon />
        Back
      </div>
    </>
  )
}
