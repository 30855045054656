import { FlowItemUI } from '@ghostmonitor/recartapis'
import { Action, isAnyOf, Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { AppDispatch } from '../../../store/create-store'
import { DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../store/selectors'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'

const DEBOUNCE_DELAY = 500

const getUpdateKeywordRepliesOnFlowItem = (dispatch: AppDispatch) =>
  debounce(
    (flowItem: FlowItemUI) => {
      dispatch(
        flowEditorActions.updateKeywordRepliesOnFlowItem({
          flowItemId: flowItem._id
        })
      )
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )

export function updateKeywordRepliesOnFlowItemMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch

  const updateKeywordRepliesOnFlowItem = getUpdateKeywordRepliesOnFlowItem(dispatch)

  return (next) => (action: Action) => {
    next(action)

    const state = store.getState()

    if (isAnyOf(flowEditorActions.messageChanged, flowEditorActions.rteInsertVariable)(action)) {
      const flowItem = flowEditorSelectors.selectFlowItem(action.payload.flowItemId)(state)
      updateKeywordRepliesOnFlowItem(flowItem)
    }
    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      updateKeywordRepliesOnFlowItem.cancel()
    }
  }
}
