import { FlowUI, SequenceUI } from '@ghostmonitor/recartapis'

export interface UseJoinedASegmentFlowCheck {
  existingSequenceWithName: FlowUI | SequenceUI
  isValidSequenceToActivate: boolean
  isValidSequenceToSave: boolean
}

export function useJoinedASegmentFlowCheck(
  sequence: SequenceUI,
  joinedASegmentSequences: (FlowUI | SequenceUI)[] | undefined
): UseJoinedASegmentFlowCheck {
  const existingSequenceWithName = joinedASegmentSequences?.find(
    (joinedASegmentSequence) =>
      joinedASegmentSequence.name === sequence?.name && joinedASegmentSequence._id !== sequence?._id
  )
  const existingSequenceWithSegmentId = joinedASegmentSequences?.find(
    (joinedASegmentSequence) =>
      joinedASegmentSequence.segmentId !== undefined &&
      joinedASegmentSequence.segmentId === sequence?.segmentId &&
      joinedASegmentSequence._id !== sequence?._id
  )
  const isValidSequenceName = sequence?.name !== undefined && sequence?.name !== ''
  const isValidSegmentIdToActivate = sequence?.segmentId !== undefined
  const isValidSegmentIdToSave = isValidSegmentIdToActivate || !sequence?.isEnabled

  const isValidSequence =
    existingSequenceWithName === undefined &&
    existingSequenceWithSegmentId === undefined &&
    isValidSequenceName
  const isValidSequenceToActivate = isValidSequence && isValidSegmentIdToActivate
  const isValidSequenceToSave = isValidSequence && isValidSegmentIdToSave

  return {
    existingSequenceWithName,
    isValidSequenceToActivate,
    isValidSequenceToSave
  }
}
