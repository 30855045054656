import { config } from '../config'
import { MeState } from '../store/slices/me/me.types'
import { createScope } from './logger/logger'

const { error } = createScope('dashboard')

declare const window: Window &
  typeof globalThis & {
    _hsq: any
    HubSpotConversations: {
      widget: {
        remove: () => void
        load: (options?: { widgetOpen: boolean }) => void
        open: () => void
        refresh: (options?: { openToNewThread: boolean }) => void
        status: () => { loaded: boolean; pending: boolean }
      }
    }
    hsConversationsOnReady: [() => void]
  }

interface OnboardingUpdate {
  id: string
  email: string
  myshopify_domain: string
  site_id: string
  store_url: string
  firstname: string
  lastname: string
  email_onboarding: string
  email_cc_onboarding: string
  phone_onboarding: string
}

// @ts-ignore
window.hsConversationsOnReady = window.hsConversationsOnReady ?? []

class HubspotService {
  loadedCheckedCount: number

  constructor() {
    this.loadedCheckedCount = 0

    this.refreshOnLoaded()
  }

  refreshOnLoaded() {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    if (window.HubSpotConversations?.widget?.status().loaded) {
      this.refreshCustomerChat({ openToNewThread: true })
      return
    }

    if (this.loadedCheckedCount > 50) {
      console.error('Hubspot widget not loaded')
      return
    }

    this.loadedCheckedCount++

    setTimeout(() => this.refreshOnLoaded(), 200)
  }

  showCustomerChat() {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open()
    } else {
      window.hsConversationsOnReady.push(() => window.HubSpotConversations.widget.open())
    }
  }

  refreshCustomerChat(options?: { openToNewThread: boolean }) {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.refresh(options)
    } else {
      window.hsConversationsOnReady.push(() => window.HubSpotConversations.widget.refresh(options))
    }
  }

  removeCustomerChat() {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove()
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.remove()
        }
      ]
    }
  }

  setUser(user: MeState) {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    window._hsq?.push([
      'identify',
      {
        id: user.site.shopify?.shop ?? user.site.domain
      }
    ])

    this.trackPageView()
  }

  setPath(path: string) {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    window._hsq?.push(['setPath', path])
  }

  trackPageView() {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    window._hsq?.push(['trackPageView'])
  }

  saveOnboardingForm(payload: OnboardingUpdate) {
    if (config.NODE_ENV === 'development' || config.NODE_ENV === 'test') {
      return
    }

    window._hsq?.push([
      'identify',
      {
        ...payload,
        form_submission_type: 'High-intent',
        recart_sign_up: true
      }
    ])
    this.trackPageView()
  }
}

const hubspotService = new HubspotService()

export default hubspotService
