/* global __DEV__ __PROD__ */
import { configureStore } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { enablePatches } from 'immer'
import debounce from 'lodash/debounce'
import LogRocket from 'logrocket'
import logger from 'redux-logger'
import { assertSequenceEditorIntegrity } from '../routes/SequenceEditor/utils/check-integrity'
import { checkFlowEditorIntegrity } from '../utils/flow-editor/check-flow-editor-integrity'
import { FlowEditorError } from '../utils/flow-editor/flow-editor-errors'
import { error } from '../utils/logger/flow-editor.logger'
import { createScope } from '../utils/logger/logger'
import { DashboardState } from './dashboard.state'
import { setGlobalStore } from './global-store'
import { defaultMiddlewares } from './middlewares'
import { makeRootReducer } from './reducers'
import { flowEditorSelectors, selectEditorSequence, selectSiteId } from './selectors'

enablePatches()

const { developerError } = createScope('sequence-editor')

const middlewares = [...defaultMiddlewares]

// @ts-ignore
if (__DEV__) {
  // @ts-expect-error redux-logger types are not up to date
  middlewares.push(logger)
}

// @ts-ignore
if (__PROD__) {
  middlewares.unshift(LogRocket.reduxMiddleware())
}

export const store = configureStore({
  reducer: makeRootReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
})

export type AppDispatch = typeof store.dispatch

setGlobalStore(store)

let currentDashboardState: DashboardState
function handleSequenceEditorStoreChange() {
  if (!/sequence-editor/.test(document.location.href)) {
    return
  }

  const previousDashboardState = currentDashboardState
  const state: DashboardState = store.getState() as any
  currentDashboardState = state

  if (previousDashboardState === undefined || currentDashboardState === undefined) {
    return
  }

  const previousSequence = selectEditorSequence(previousDashboardState)
  const currentSequence = selectEditorSequence(currentDashboardState)

  if (previousSequence !== currentSequence) {
    try {
      assertSequenceEditorIntegrity(currentDashboardState)
    } catch (err) {
      developerError(err)
    }
  }
}

function handleFlowEditorStoreChange() {
  if (!/flow-editor/.test(document.location.href)) {
    return
  }

  const previousDashboardState = currentDashboardState
  const state: DashboardState = store.getState() as any
  const siteId = selectSiteId(state)
  currentDashboardState = state

  if (previousDashboardState === undefined || currentDashboardState === undefined) {
    return
  }

  const previousFlow = flowEditorSelectors.selectEditorFlow(previousDashboardState)
  const currentFlow = flowEditorSelectors.selectEditorFlow(currentDashboardState)
  const flowEditorState = currentDashboardState.flowEditor

  if (previousFlow && currentFlow && previousFlow !== currentFlow) {
    try {
      checkFlowEditorIntegrity({
        flowUI: flowEditorState.flow,
        flowMeta: flowEditorState.flowMeta,
        isReadOnly: flowEditorState.isReadOnly,
        smsCampaign: flowEditorState.smsCampaign,
        siteIdInFlowEditor: flowEditorState.site.id,
        siteId: currentDashboardState.me.site.id
      })
    } catch (err) {
      const errKey = `${FlowEditorError.IntegrityError}-${
        err.message ?? 'general'
      }-${siteId}-reported`
      if (sessionStorage.getItem(errKey) === null) {
        sessionStorage.setItem(errKey, 'true')
        error(FlowEditorError.IntegrityError, { cause: err })
      }
      console.error(FlowEditorError.IntegrityError, err)
      // @ts-ignore
      if (__DEV__) {
        notification.error({
          message: err.message,
          description: 'Check console for more information!'
        })
      }
    }
  }
}

// @ts-ignore
if (__DEV__) {
  store.subscribe(debounce(handleSequenceEditorStoreChange, 500))
}

store.subscribe(
  debounce(handleFlowEditorStoreChange, 1000, {
    leading: false,
    trailing: true
  })
)
