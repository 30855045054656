import { Recipient } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '../../../../utils/request'

export interface SendTestArgs {
  sequenceId: string
  sequenceItemId: string
  recipient: Recipient
}
export const sendTestThunk = createAsyncThunk(
  'sequence-editor/sendTest',
  async (args: SendTestArgs) => {
    const { sequenceItemId, recipient } = args
    return request.post(`sequence-item/${sequenceItemId}/test`, recipient)
  }
)
