// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes placeHolderShimmer{0%{background-position:-468px 0}to{background-position:468px 0}}.SDxy7zHTeGPa1v7SQvj9 .ant-skeleton-paragraph li{margin:4px 0!important}.QJREhgg4hb58oMEoBJEO .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-tiny)}.xzoHsSXdsmoWRGhmgoZu .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-caption)}.CyNY6obcdvZYiirM1ouS .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-sm)}._xQxcL9okf5QkBg_g4VF .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-base)}.D5hmN6cptIuL7YkuVcmC .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-lg)}.eSnSPqg2L2SAOq5BGYpP .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-h2)}.M89jqkr85jmXNaeA4iuE .ant-skeleton-paragraph li{height:var(--recart-skeleton-text-size-h1)}.U7XSl0WiGT1H5I9yUEsX .ant-skeleton-paragraph li{height:74px;width:400px}@media(min-width:1440px){.U7XSl0WiGT1H5I9yUEsX .ant-skeleton-paragraph li{width:350px}}`, "",{"version":3,"sources":["webpack://./src/styles/mixins.scss","webpack://./src/components/ui-kit/loader/text-skeleton.style.scss"],"names":[],"mappings":"AAwHA,8BACE,GACE,4BAAA,CAGF,GACE,2BAAA,CAAA,CCxHI,iDACE,sBAAA,CASF,iDACE,4CAAA,CASF,iDACE,+CAAA,CASF,iDACE,0CAAA,CASF,iDACE,4CAAA,CASF,iDACE,0CAAA,CASF,iDACE,0CAAA,CASF,iDACE,0CAAA,CASF,iDACE,WAAA,CACA,WAAA,CDTN,yBCOI,iDAKI,WAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `SDxy7zHTeGPa1v7SQvj9`,
	"tiny": `QJREhgg4hb58oMEoBJEO`,
	"caption": `xzoHsSXdsmoWRGhmgoZu`,
	"sm": `CyNY6obcdvZYiirM1ouS`,
	"base": `_xQxcL9okf5QkBg_g4VF`,
	"lg": `D5hmN6cptIuL7YkuVcmC`,
	"h2": `eSnSPqg2L2SAOq5BGYpP`,
	"h1": `M89jqkr85jmXNaeA4iuE`,
	"message": `U7XSl0WiGT1H5I9yUEsX`
};
export default ___CSS_LOADER_EXPORT___;
