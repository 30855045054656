// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mTVOB9mDWuBr2DpoPMfF{border-radius:var(--recart-border-radius-sm)}.Ujo1F27j1Mw7GltLVaP7 span{height:var(--recart-skeleton-avatar-size-sm)!important;width:var(--recart-skeleton-avatar-size-sm)!important}.BuEdw2mc1maZn2Ez8fIc span{height:var(--recart-skeleton-avatar-size-md)!important;width:var(--recart-skeleton-avatar-size-md)!important}.gxU4_tuZtTBeDHV4fEnT span{height:var(--recart-skeleton-avatar-size-base)!important;width:var(--recart-skeleton-avatar-size-base)!important}.l97uSNFYbQzd63NWaf6I span{height:var(--recart-skeleton-avatar-size-lg)!important;width:var(--recart-skeleton-avatar-size-lg)!important}.ucAk4FVXOu9bl0Do8hrk span{height:var(--recart-skeleton-avatar-size-xl)!important;width:var(--recart-skeleton-avatar-size-xl)!important}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/loader/avatar-skeleton.style.scss"],"names":[],"mappings":"AACE,sBACE,4CAAA,CAIA,2BAEE,sDAAA,CADA,qDACA,CAKF,2BAEE,sDAAA,CADA,qDACA,CAKF,2BAEE,wDAAA,CADA,uDACA,CAKF,2BAEE,sDAAA,CADA,qDACA,CAKF,2BAEE,sDAAA,CADA,qDACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `mTVOB9mDWuBr2DpoPMfF`,
	"sm": `Ujo1F27j1Mw7GltLVaP7`,
	"md": `BuEdw2mc1maZn2Ez8fIc`,
	"base": `gxU4_tuZtTBeDHV4fEnT`,
	"lg": `l97uSNFYbQzd63NWaf6I`,
	"xl": `ucAk4FVXOu9bl0Do8hrk`
};
export default ___CSS_LOADER_EXPORT___;
