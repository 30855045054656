// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ye0TRwMm12Qb0AaF8gBf em-emoji-picker{--background-rgb:248,249,250;--border-radius:8px;--category-icon-size:16px;--color-border-hover:rgba(0,0,0,.1);--color-border:rgba(0,0,0,.05);--font-family:source sans pro,sans-serif;--font-size:15px;--rgb-accent:3,117,255;--rgb-background:255,255,255;--rgb-color:91,106,135;--rgb-input:226,231,241;--shadow:4px 4px 8px -4px #e2e7f1;margin-left:12px;margin-top:30px;position:absolute;width:310px;z-index:99}.uTtr99Qh0WFJ4Zw_PjMx em-emoji-picker{bottom:100px}.xmpSzg0skliYDTPry3MZ{cursor:default;inset:0;position:fixed}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/emoji-picker/emoji-picker.component.scss"],"names":[],"mappings":"AAGM,sCACE,4BAAA,CACA,mBAAA,CACA,yBAAA,CACA,mCAAA,CACA,8BAAA,CACA,wCAAA,CACA,gBAAA,CACA,sBAAA,CACA,4BAAA,CACA,sBAAA,CACA,uBAAA,CACA,iCAAA,CAGA,gBAAA,CADA,eAAA,CAEA,iBAAA,CAHA,WAAA,CAIA,UAAA,CAOF,sCACE,YAAA,CAKN,sBAGE,cAAA,CADA,OAAA,CADA,cAEA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pickerContainer": `ye0TRwMm12Qb0AaF8gBf`,
	"openTowardDirection": `uTtr99Qh0WFJ4Zw_PjMx`,
	"pickerMask": `xmpSzg0skliYDTPry3MZ`
};
export default ___CSS_LOADER_EXPORT___;
