import { ExpressionAPI } from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { setFlowEditorLocalStorage } from '../../../routes/flow-editor/utils/local-storage'
import { isConditionalSplitV2FlowItemUI } from '../../../types/guards/flow-item-ui.guards'
import { convertExpressionAPIToUI } from '../../../types/segment/converters/api-to-ui/segment'
import { api } from '../../../utils/api'

export const duplicateFlowThunk = createAsyncThunk('flow/duplicate', async (flowId: string) => {
  const flow = await api.duplicateFlow(flowId)

  // convert expression API to UI
  flow.sequenceItems = flow.sequenceItems.map((flowItem) => {
    return produce(flowItem, (draft) => {
      if (isConditionalSplitV2FlowItemUI(draft)) {
        draft.item.logic.conditionalSplitV2.cases.forEach((split) => {
          split.expression = convertExpressionAPIToUI(split.expression as ExpressionAPI)
        })
      }
    })
  })

  setFlowEditorLocalStorage(flow._id, {
    flow,
    unsavedChanges: true,
    isEverSaved: false,
    isPreviewOn: true,
    isAITextGeneratorWizardClosed: false,
    isWelcomeFlowWarningModalClosed: false
  })

  return flow._id
})
