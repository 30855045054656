import React from 'react'
import type { AppDispatch } from '../../../store/create-store'
import { DashboardState } from '../../../store/dashboard.state'
import { selectEditorSequence } from '../../../store/selectors'
import { settingsInitialized } from '../../../store/slices/sequence-editor/sequence-editor.actions'
import { setSequenceEditorSetting } from '../hooks/use-sequence-editor-settings-context'
import { initialLeaveEditorModal } from './context/defaults/leave-editor-modal'
import { SequenceEditorSettings } from './context/sequence-editor-settings.type'
import { abandonedSequenceThunk } from './setup-thunks/abandoned-sequence.thunk'
import { customWelcomeSequencesThunk } from './setup-thunks/custom-welcome-sequences-thunk/custom-welcome-sequences.thunk'
import { facebookCommentThunk } from './setup-thunks/facebook-comment/facebook-comment.thunk'
import { messengerThunk } from './setup-thunks/messenger/messenger.thunk'
import { setSequenceTypeThunk } from './setup-thunks/set-sequence-type.thunk'
import { smsThunk } from './setup-thunks/sms/sms.thunk'
import { tueWelcomeSequencesThunk } from './setup-thunks/tue-welcome-sequences-thunk/tue-welcome-sequences.thunk'
import { unsubscribeThunk } from './setup-thunks/unscubscribe/unsubscribe.thunk'

export function onEditorLoadedThunk() {
  return async (dispatch: AppDispatch, getState: () => DashboardState): Promise<void> => {
    const state = getState()
    const sequence = selectEditorSequence(state)
    setSequenceEditorSetting(SequenceEditorSettings.leaveEditorModal, {
      ...initialLeaveEditorModal,
      content: (
        <>
          Exiting will discard '<strong>{sequence.name}</strong>'.
          <br />
          To keep the Conversation Flow, please complete selecting the entry point.
        </>
      )
    })

    dispatch(setSequenceTypeThunk())
    dispatch(abandonedSequenceThunk())
    dispatch(tueWelcomeSequencesThunk())
    dispatch(customWelcomeSequencesThunk())
    dispatch(facebookCommentThunk())
    await dispatch(smsThunk())
    dispatch(messengerThunk())
    dispatch(unsubscribeThunk())
    dispatch(settingsInitialized())
  }
}
