// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._5cvyGCcW4bJ3KA6aUMs{color:var(--recart-night-blue-1)}.pFG0zBab0BwiDkYxIZjo{background-color:#fff1f0;border:1px solid #ff4d4f}.YPJlcTQtEfe_Mr53I68F{color:#ff4d4f;padding-top:var(--recart-spacing-1)}.SMFRuIYOrNW0Kr4fMtlA{margin-top:7px}.SMFRuIYOrNW0Kr4fMtlA:first-child{margin-bottom:5px}.bljANEUdrNGnZUXpCM9_{margin-left:24px}.MwgXTplO7NS4vC346DAn{width:100%}.HE0MZ0vzgw2pGZYuC77A{border-radius:var(--recart-border-radius);box-shadow:0 5px 20px rgba(28,69,99,.2);padding:var(--recart-spacing-2);width:unset!important}.xeC_JmK9SL6n80wRMcej{height:13px;margin-bottom:-2px;margin-left:-4px;width:13px}.xeC_JmK9SL6n80wRMcej svg{display:block}.Ew4ep9YQzI7YcoESj3FE{margin-left:52px}.ly03bLPOS8l1bYs3N8EA svg{width:18px}.nbgqJuIxXng05QCttux9 .ant-skeleton{width:100%!important}.nbgqJuIxXng05QCttux9 .ant-skeleton li{width:95%!important}`, "",{"version":3,"sources":["webpack://./src/components/editor-drawer/components/joined-a-segment/joined-a-segment.component.scss"],"names":[],"mappings":"AACE,sBACE,gCAAA,CAGF,sBACE,wBAAA,CACA,wBAAA,CAGF,sBACE,aAAA,CACA,mCAAA,CAGF,sBACE,cAAA,CAEA,kCACE,iBAAA,CAIJ,sBACE,gBAAA,CAGF,sBACE,UAAA,CAGF,sBAEE,yCAAA,CAEA,uCAAA,CADA,+BAAA,CAFA,qBAGA,CAGF,sBAEE,WAAA,CAEA,kBAAA,CADA,gBAAA,CAFA,UAGA,CAEA,0BACE,aAAA,CAIJ,sBACE,gBAAA,CAIA,0BACE,UAAA,CAKF,oCACE,oBAAA,CAEA,uCACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `_5cvyGCcW4bJ3KA6aUMs`,
	"bgError": `pFG0zBab0BwiDkYxIZjo`,
	"error": `YPJlcTQtEfe_Mr53I68F`,
	"radio": `SMFRuIYOrNW0Kr4fMtlA`,
	"pickerContainer": `bljANEUdrNGnZUXpCM9_`,
	"select": `MwgXTplO7NS4vC346DAn`,
	"dropdown": `HE0MZ0vzgw2pGZYuC77A`,
	"drawerIcon": `xeC_JmK9SL6n80wRMcej`,
	"optionSubTitle": `Ew4ep9YQzI7YcoESj3FE`,
	"warningIcon": `ly03bLPOS8l1bYs3N8EA`,
	"loader": `nbgqJuIxXng05QCttux9`
};
export default ___CSS_LOADER_EXPORT___;
