import {
  ConditionalSplitV2FlowItemUI,
  ConditionSlug,
  DateFilterType,
  FlowUI,
  LogicConditionalSplitV2CaseUI,
  LogicType,
  NodeType,
  Operator
} from '@ghostmonitor/recartapis'
import { getNewFlowItem } from './get-new-flow-item'

export function getMemberOfSegmentConditionalSplitV2FlowItem(
  flowItemId: string,
  flow: FlowUI,
  segmentIds: string[]
): ConditionalSplitV2FlowItemUI {
  const cases: LogicConditionalSplitV2CaseUI[] = segmentIds.map((segmentId) => ({
    expression: {
      and: [
        {
          or: [
            {
              slug: ConditionSlug.MEMBER_OF_A_SEGMENT,
              type: 'boolean',
              operator: Operator.EQUAL,
              dateType: DateFilterType.All,
              value: [segmentId]
            }
          ]
        }
      ]
    },
    nextFlowItemTrigger: ''
  }))

  const flowItem: ConditionalSplitV2FlowItemUI = getNewFlowItem<ConditionalSplitV2FlowItemUI>({
    nodeType: NodeType.CONDITIONAL_SPLIT_FLOW_ITEM_V2,
    flowItemId,
    flow,
    isEntry: true,
    populatedValues: {
      item: {
        logic: {
          type: LogicType.CONDITIONAL_SPLIT_V2,
          conditionalSplitV2: {
            cases,
            defaultNextFlowItemTrigger: ''
          }
        }
      }
    }
  })

  return flowItem
}
