import { getVariablesFromRawValue } from '../../../../utils/inline-editor/inline-editor-utils'

export function getDiscountPoolIdsFromText(text: string): string[] {
  const variables = getVariablesFromRawValue(text)

  const discountPoolIds = variables.reduce((accDiscountPoolIds, variable) => {
    if (variable.params.discount_pool_id) {
      accDiscountPoolIds.add(variable.params.discount_pool_id)
    }
    return accDiscountPoolIds
  }, new Set<string>())

  return Array.from(discountPoolIds)
}
