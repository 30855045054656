import { nanoid } from '@reduxjs/toolkit'

const SESSION_ID_KEY = 'recart-session-id'

export function getSessionId(): string | null {
  return sessionStorage.getItem(SESSION_ID_KEY)
}

export function setNewSessionId(): string {
  sessionStorage.setItem(SESSION_ID_KEY, nanoid())
  return getSessionId()
}

export function hasSessionId(): boolean {
  return getSessionId() !== null
}
