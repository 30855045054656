// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q6hTJmV2XxmQzKDBCtig{color:#212b36;font-size:14px;line-height:17px;padding:14px 19px 14px 17px}.q6hTJmV2XxmQzKDBCtig.GPPOKgLfQuarMynqvJFf{color:#666974}.hJZCSbRxuH_uXZr5Ivu6{border-bottom-left-radius:0;border-bottom-right-radius:0}.wngkevx3vdQLUNJDLYpB{height:40px;overflow:visible;position:relative}.wngkevx3vdQLUNJDLYpB:hover .fOkqfRSN4jziWxF2Hr53{height:20px;opacity:1;transform:scale(1);transform-origin:center}.wngkevx3vdQLUNJDLYpB:hover .E4I6BDv6eJvhMm92UEHw{opacity:1;transform:scale(1);transform-origin:center}.fOkqfRSN4jziWxF2Hr53{background-color:#476dff;border-radius:0 0 8px 8px;color:#fff;cursor:pointer;font-family:Lato,sans-serif;font-size:12px;height:0;left:31%;line-height:20px;margin:0 auto;opacity:0;position:absolute;scale:.8;text-align:center;transform:scale(.6);transform-origin:center;width:40%;z-index:13}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/message-templates/messenger-button-template/messenger-button-template.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAEA,sBAIE,aAAA,CADA,cAAA,CADA,gBAAA,CADA,2BAGA,CAEA,2CACE,aCcW,CDVf,sBACE,2BAAA,CACA,4BAAA,CAGF,sBAEE,WAAA,CACA,gBAAA,CAFA,iBAEA,CAGE,kDAEE,WAAA,CADA,SAAA,CAGA,kBAAA,CADA,uBACA,CAGF,kDACE,SAAA,CAEA,kBAAA,CADA,uBACA,CAKN,sBAQE,wBC5Cc,CD6Cd,yBAAA,CACA,UAAA,CAMA,cAAA,CAVA,2BAAA,CADA,cAAA,CAOA,QAAA,CARA,QAAA,CASA,gBAAA,CAEA,aAAA,CAdA,SAAA,CAEA,iBAAA,CADA,QAAA,CAKA,iBAAA,CAWA,mBAAA,CADA,uBAAA,CAHA,SAAA,CAHA,UAOA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `q6hTJmV2XxmQzKDBCtig`,
	"blurred": `GPPOKgLfQuarMynqvJFf`,
	"has-buttons": `hJZCSbRxuH_uXZr5Ivu6`,
	"button-container": `wngkevx3vdQLUNJDLYpB`,
	"add-button": `fOkqfRSN4jziWxF2Hr53`,
	"remove-button": `E4I6BDv6eJvhMm92UEHw`
};
export default ___CSS_LOADER_EXPORT___;
