import {
  CartUrlButton,
  isCartUrlButton,
  isPhoneNumberButton,
  isReviewButton,
  isSequenceItemLinkButton,
  isShipmentTrackingButton,
  isWebUrlButton,
  PhoneNumberButton,
  ReviewButton,
  SequenceItemLinkButton,
  ShipmentTrackingButton,
  WebUrlButton
} from '@ghostmonitor/recartapis'
import { ButtonProps } from './messenger-button.component'

export function isWebUrlButtonProps(props: ButtonProps): props is ButtonProps<WebUrlButton> {
  return isWebUrlButton(props.payload)
}

export function isPhoneNumberButtonProps(
  props: ButtonProps
): props is ButtonProps<PhoneNumberButton> {
  return isPhoneNumberButton(props.payload)
}

export function isShipmentTrackingButtonProps(
  props: ButtonProps
): props is ButtonProps<ShipmentTrackingButton> {
  return isShipmentTrackingButton(props.payload)
}

export function isReviewButtonProps(props: ButtonProps): props is ButtonProps<ReviewButton> {
  return isReviewButton(props.payload)
}

export function isCartUrlButtonProps(props: ButtonProps): props is ButtonProps<CartUrlButton> {
  return isCartUrlButton(props.payload)
}

export function isSequenceItemLinkButtonProps(
  props: ButtonProps
): props is ButtonProps<SequenceItemLinkButton> {
  return isSequenceItemLinkButton(props.payload)
}
