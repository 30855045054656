import { useMutation, useQueryClient } from '@tanstack/react-query'
import { request } from '../../utils/request'
import type { RotateWebhookSecretResponse } from '@ghostmonitor/recartapis'
import { QUERY_NAME } from './query-names'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { useSite } from './use-site'
import { useCallback } from 'react'

export function useWebhookSecrets() {
  const queryClient = useQueryClient()
  const { data: site, isLoading } = useSite()
  const queryName = [QUERY_NAME.rotateWebhookSecret]
  const siteQueryName = [QUERY_NAME.site]
  const { handleError, handlePatch } = useMutationHelpers(queryName)

  function rotateWebhookSecret() {
    return request.post<RotateWebhookSecretResponse>(`site/rotate-webhook-secret`)
  }

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(siteQueryName)
  }, [queryClient, queryName])

  const createWebhookSecretMutation = useMutation(rotateWebhookSecret, {
    onMutate: handlePatch,
    onSettled: handleSettled,
    onError: handleError
  })

  const webhookSecrets = site?.webhookSecrets?.filter((secret) => {
    if (!secret.expiresAt) {
      return true
    }

    return new Date(secret.expiresAt) > new Date()
  })

  return {
    data: webhookSecrets ?? [],
    isLoading,
    rotateWebhookSecret: createWebhookSecretMutation.mutateAsync
  }
}
