import { PortType } from '@ghostmonitor/recartapis'
import { SequenceEditorPortModel } from './port-model'
import { SequenceItemPortModel } from './sequence-item-port-model'

export class LogicPortModel extends SequenceEditorPortModel {
  constructor(name?: string) {
    super(PortType.LOGIC, name)
  }

  public canLinkToPort(port: SequenceItemPortModel): boolean {
    // At this point, link is already added, and this.getLinks() counts this +1 link,
    // even if we return with false at this point. If we return with false, the link is
    // going to be removed
    const linkingToSameNode = port.parent && port.parent.id === this.getParent().id
    return (
      port.portType === PortType.SEQUENCE_ITEM &&
      port.portType !== this.portType &&
      Object.values(this.getLinks()).length === 1 &&
      !linkingToSameNode
    )
  }
}
