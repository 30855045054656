import { Modal } from 'antd'
import type { ModalFuncProps as AntModalFuncProps } from 'antd/lib/modal'
import cn from 'classnames'
import React from 'react'
import commonStyles from '../modals.component.scss'
import styles from './confirm-modal.component.scss'

interface ModalProps extends AntModalFuncProps {
  modalType?: 'default' | 'destructive' | 'warning'
}

export function showConfirmModal({
  className,
  icon,
  modalType = 'default',
  ...restProps
}: ModalProps): void {
  const iconClasses = cn('anticon icon-sm', {
    [styles.default]: modalType === 'default',
    [styles.danger]: modalType === 'destructive',
    [styles.warning]: modalType === 'warning'
  })

  Modal.confirm({
    className: cn(commonStyles.container, className),
    okButtonProps: {
      type: 'primary',
      danger: modalType === 'destructive',
      // @ts-expect-error data-testid
      'data-testid': 'modal-ok-button'
    },
    cancelButtonProps: {
      type: 'link',
      // @ts-expect-error data-testid
      'data-testid': 'modal-cancel-button'
    },
    centered: true,
    icon: <i className={iconClasses}>{icon}</i>,
    width: 576,
    ...restProps
  })
}
