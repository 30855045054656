import type { ModalProps as AntModalProps } from 'antd'
import { Modal as AntModal } from 'antd'
import cn from 'classnames'
import React from 'react'
import { ReactComponent as CloseIcon } from '../../../static/ui-kit/icons/small/close.svg'
import styles from './modal.component.scss'

export function Modal(props: AntModalProps) {
  const { className, ...restProps } = props

  return (
    <AntModal
      className={cn(styles.modalContainer, className)}
      data-testid='tooltip'
      {...restProps}
      width={props.width ?? '600px'}
      closeIcon={<CloseIcon className='icon-xs' />}
    >
      {props.children}
    </AntModal>
  )
}
