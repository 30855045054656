// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Goj3vCUbPSeDW7AEBywo{color:var(--recart-night-blue-1)}.OAvAXs9zQqxuXUQMSsLl{color:#ff4d4f;padding-top:var(--recart-spacing-1)}`, "",{"version":3,"sources":["webpack://./src/components/editor-drawer/components/wonderment/wonderment.component.scss"],"names":[],"mappings":"AACE,sBACE,gCAAA,CAGF,sBACE,aAAA,CACA,mCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Goj3vCUbPSeDW7AEBywo`,
	"error": `OAvAXs9zQqxuXUQMSsLl`
};
export default ___CSS_LOADER_EXPORT___;
