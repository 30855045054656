import { SequenceTag } from '@ghostmonitor/recartapis'
import { Button, Switch } from 'antd'
import cn from 'classnames'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { showConfirmModal } from '../../../../../components/ui-kit/modals/confirm-modal/confirm-modal.component'
import { hooks } from '../../../../../hooks/hooks'
import { ReactComponent as ExclamationIcon } from '../../../../../static/ui-kit/icons/normal/exclamation.svg'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectErrorCount
} from '../../../../../store/selectors'
import { isCustomWelcomeSequence } from '../../../../../utils/is-custom-welcome-sequence'
import { useEditorFacebookCommentPostId } from '../../../hooks/use-editor-post-id'
import { useSaveModal } from '../../../hooks/use-save-modal'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { useToggleSequence } from '../../../hooks/use-toggle-sequence'
import { isSMSSequence } from '../../../utils/assert-sequence-type'
import { SaveButton } from '../../save-button/save-button.component'
import styles from '../header.component.scss'
import { HeaderSendTest } from './header-send-test.component'

export function HeaderSaveToggle() {
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      sequenceMeta: selectEditorSequenceMeta,
      errorCount: selectErrorCount
    }),
    []
  )

  const state = useSelector(stateSelector)

  const [showTogglePopover, setShowTogglePopover] = useState(false)
  const sequence = useSelector(selectEditorSequence)
  const { showSaveModal } = useSaveModal()
  const editorSettings = useSequenceEditorSettings()
  const { saveSequenceEditor } = useSaveSequenceEditor()
  const { toggleSequence } = useToggleSequence()
  const facebookPostId = useEditorFacebookCommentPostId()

  const hasMMS = hooks.useHasMMS()
  const hasInternationalPhoneNumber = hooks.useHasInternationPhoneNumber()

  async function isRenameNeeded(): Promise<boolean> {
    return (
      (state.sequence.tags.includes(SequenceTag.CUSTOM_CONVERSATION_FLOW) ||
        state.sequence.tags.includes(SequenceTag.FACEBOOK_COMMENTS) ||
        isCustomWelcomeSequence(sequence)) &&
      !state.sequenceMeta.isEverSaved
    )
  }

  async function handleSave() {
    try {
      await saveSequenceEditor()
    } catch {}
  }

  async function handleSaveButtonClicked() {
    const renameIsNeeded = await isRenameNeeded()
    if (renameIsNeeded) {
      showSaveModal()
    } else {
      if (
        sequence.tags.includes(SequenceTag.AUTOMATED) &&
        isSMSSequence(sequence) &&
        hasMMS &&
        hasInternationalPhoneNumber
      ) {
        showConfirmModal({
          icon: <ExclamationIcon className='icon-sm' />,
          title: 'Messages are SMS only in some countries',
          cancelText: 'Back to editing',
          okText: 'Save',
          onOk: handleSave,
          content:
            'Subscribers outside of US and Canada will only receive the text part of the message without attachments (as an SMS).'
        })
      } else {
        handleSave()
      }
    }
  }

  async function handleToggle() {
    if (state.sequenceMeta.toggling || state.errorCount !== 0) {
      return
    }

    if (await isRenameNeeded()) {
      showSaveModal()
      return
    }

    if (state.sequenceMeta.unsavedChanges && !showTogglePopover) {
      setShowTogglePopover(true)
      return
    }

    setShowTogglePopover(false)
    toggleSequence()
  }

  function handleBackToEditing() {
    setShowTogglePopover(false)
  }

  async function handleDisableSequence() {
    toggleSequence()
  }

  function getStatusText() {
    if (state.sequenceMeta.toggling) {
      return state.sequence.isEnabled ? 'Deactivating...' : 'Activating...'
    }

    return state.sequence.isEnabled ? 'Active' : 'Inactive'
  }

  return (
    <div className='flex items-center'>
      <SaveButton
        disabled={state.errorCount > 0 || !state.sequenceMeta.unsavedChanges}
        saving={state.sequenceMeta.saving}
        onClick={handleSaveButtonClicked}
      />
      {!editorSettings.hideMessengerSendTestButton && <HeaderSendTest />}
      <div className='flex'>
        {!editorSettings.isEnabledLocked && (
          <>
            <div className={styles.toggleText}>{getStatusText()}</div>
            <Switch
              checked={state.sequence.isEnabled}
              disabled={
                showTogglePopover ||
                state.errorCount !== 0 ||
                (editorSettings.isFacebookComment && !facebookPostId)
              }
              loading={state.sequenceMeta.toggling}
              onChange={(checked: boolean) => (checked ? handleToggle() : handleDisableSequence())}
              data-testid='toggle'
            />
          </>
        )}
        <div
          className={cn(styles['unsaved-changes-popup'], {
            [styles.visible]: showTogglePopover
          })}
        >
          <h4 className='font-semibold'>Review changes</h4>
          <p>
            You have recently edited this flow but didn't save the changes. Would you like to save
            the changes and activate the flow?
          </p>
          <Button
            className={cn('w-full', styles['save-button'])}
            type='primary'
            onClick={() => handleToggle()}
            data-testid='save-and-activate'
          >
            Save changes & activate
          </Button>
          <Button className='w-full' onClick={handleBackToEditing} data-testid='back-to-editing'>
            Back to editing
          </Button>
        </div>
      </div>
    </div>
  )
}
