import { NodeType } from '@ghostmonitor/recartapis'

export type TrayItem = MessageTrayItem | NodeType

export enum MessageTrayItem {
  messengerMessage = 'messengerMessage',
  smsTextMessage = 'smsTextMessage',
  smsMedia = 'smsMedia',
  smsContactCard = 'smsContactCard'
}

export function isMessageTrayItem(trayItem: TrayItem): trayItem is MessageTrayItem {
  return Object.values(MessageTrayItem).includes(trayItem as MessageTrayItem)
}

export function isNodeTrayItem(trayItem: TrayItem): trayItem is NodeType {
  return Object.values(NodeType).includes(trayItem as NodeType)
}
