import { miniSerializeError, SerializedError } from '@reduxjs/toolkit'

export interface GenericSerializedError<TName extends string = string> extends SerializedError {
  name: TName
}

export function serializedError<TName extends string = string>(
  name: TName,
  message?: string
): GenericSerializedError<TName> {
  const error = new Error(message)
  error.name = name
  return miniSerializeError(error) as GenericSerializedError<TName>
}
