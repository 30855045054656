import {
  Channel,
  ConditionalSplitSequenceItemUI,
  LogicConditionalSplitCondition,
  LogicConditionOperator
} from '@ghostmonitor/recartapis'
import { Divider, Select } from 'antd'
import { ObjectId } from 'bson'
import cloneDeep from 'lodash/cloneDeep'
import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ReactComponent as DeleteIcon } from '../../../../../static/images/svg/streamline/outline/01-Interface-Essential/23-Delete/bin-1.svg'
import { ReactComponent as ConditionalSplitIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/15-Filter/filter-1.svg'
import {
  selectCurrencySlug,
  selectDraggedItemType,
  selectEditorSequence,
  selectLinking,
  selectLinkingMeta,
  selectSequenceItem,
  selectSequenceItemMeta,
  selectShowDebugView
} from '../../../../../store/selectors'
import {
  addCondition,
  createSequenceItem,
  removeCondition,
  removeSequenceItem,
  updateCondition
} from '../../../../../store/slices/sequence-editor/sequence-editor.actions'
import {
  makeSequenceItem,
  SequenceItemNodeWidgetBaseProps
} from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc'
import hocStyles from '../../../higher-order-components/make-sequence-item/make-sequence-item.hoc.scss'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { LogicPortModel } from '../../../models/port/logic-port-model'
import { ConditionalSplitSequenceItemNodeModel } from '../../../models/sequence-item/conditional-split/conditional-split-sequence-item-model'
import {
  LOGIC_PORT_CONDITION_MET,
  LOGIC_PORT_CONDITION_UNMET
} from '../../../models/sequence-item/port-names'
import { DEFAULT_SEQUENCE_ITEM_OFFSET } from '../../../types/sequence-editor-constants'
import {
  AddElementButton,
  AddElementButtonType
} from '../../add-element-button/add-element-button.component'
import { LogicSequenceItemPortWidget } from '../../logic-sequence-item-port-widget/logic-sequence-item-port-widget.component'
import { SequenceItemButton } from '../../sequence-item-button/sequence-item-button.component'
import { SequenceItemWidgetTitle } from '../sequence-item-widget-title.component'
import styles from './conditional-split-sequence-item-node-widget.component.scss'

export interface ConditionalSplitWidgetComponentProps extends SequenceItemNodeWidgetBaseProps {
  sequenceItem: ConditionalSplitSequenceItemUI
  trueOutputPort: LogicPortModel
  falseOutputPort: LogicPortModel
  isEntry: boolean
  onAddCondition: (condition: LogicConditionalSplitCondition) => void
  onRemoveCondition: (index: number) => void
  onUpdateCondition: (index: number, condition: LogicConditionalSplitCondition) => void
  repaintCanvas: () => void
}

interface Condition {
  value: string
  label: string
  channels: Channel[]
}

export type ConditionalSplitConditions = Condition[]

type ConditionList = Record<string, LogicConditionalSplitCondition & { name: string }>
export const conditionList: ConditionList = {
  hasItemsInCart: {
    name: 'hasItemsInCart',
    variable: 'shopper.hasItemsInCart',
    operation: LogicConditionOperator.EQ,
    value: 'true'
  },
  hasPlacedOrderWithin30Days: {
    name: 'hasPlacedOrderWithin30Days',
    variable: 'shopper.hasPlacedOrderWithin30Days',
    operation: LogicConditionOperator.EQ,
    value: 'true'
  },
  male: {
    name: 'male',
    variable: 'user_variables.gender',
    operation: LogicConditionOperator.EQ,
    value: 'male'
  },
  female: {
    name: 'female',
    variable: 'user_variables.gender',
    operation: LogicConditionOperator.EQ,
    value: 'female'
  }
}

function ConditionalSplitComponent(props: ConditionalSplitWidgetComponentProps) {
  const editorSettings = useSequenceEditorSettings()
  const { conditionalSplitConditions } = editorSettings

  function getConditions(): LogicConditionalSplitCondition[] {
    return props.sequenceItem?.logic.conditionalSplit.variants[0].conditions ?? []
  }

  function findName(condition: LogicConditionalSplitCondition): string {
    for (const i of Object.keys(conditionList)) {
      if (
        conditionList[i].operation === condition.operation &&
        conditionList[i].value === condition.value &&
        conditionList[i].variable === condition.variable
      ) {
        return conditionList[i].name
      }
    }
    return ''
  }

  function handleAddCondition() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, ...newCondition } = conditionList.hasItemsInCart
    props.onAddCondition(newCondition)
    props.repaintCanvas()
  }

  function handleRemoveCondition(index: number) {
    props.onRemoveCondition(index)
    props.repaintCanvas()
  }

  function changeCondition(index: number, newCondition: LogicConditionalSplitCondition) {
    props.onUpdateCondition(index, newCondition)
  }

  const conditions = getConditions()

  return (
    <div>
      <div
        className={hocStyles.title}
        style={{ display: conditions.length > 0 ? 'block' : 'none' }}
      >
        {conditions.length > 1 ? 'Conditions' : 'Condition'}
      </div>
      {conditions.map((condition, idx) => (
        <div key={`condition-${idx}`}>
          <>
            <div className={styles.line} data-testid='condition'>
              {conditions.length > 1 && (
                <SequenceItemButton
                  title='remove'
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveCondition(idx)}
                />
              )}
              <div
                style={{ width: '100%' }}
                onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
              >
                <Select
                  value={findName(condition)}
                  onChange={(selected) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { name, ...condition } = conditionList[selected]
                    changeCondition(idx, condition)
                  }}
                  size='large'
                  aria-controls='test-uuid'
                  style={{ width: '100%' }}
                  data-testid='conditional-split-dropdown'
                >
                  {conditionalSplitConditions.map((conditionOption) => (
                    <Select.Option
                      key={conditionOption.value}
                      value={conditionOption.value}
                      data-testid={`conditional-split-option-${conditionOption.value}`}
                    >
                      {conditionOption.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <Divider className={styles.divider}>
              {conditions.length - 1 === idx ? '' : 'AND'}
            </Divider>
          </>
        </div>
      ))}
      <div className={styles.center}>
        <AddElementButton
          buttonType={AddElementButtonType.CONDITION}
          label='Add condition'
          onClick={handleAddCondition}
          data-testid='conditional-split-add-button'
        />
      </div>
      <div
        style={{ display: conditions.length > 0 ? 'block' : 'none' }}
        className={styles['port-wrapper']}
      >
        <Divider className={styles.divider} />
        <div className={styles.row}>
          <div className={styles.question}>Does the user meet these conditions?</div>
        </div>
        <div className={styles.row}>
          <LogicSequenceItemPortWidget
            node={props.sequenceItemNode}
            name={props.trueOutputPort.name}
            port={props.trueOutputPort}
            isEntry={props.isEntry}
            linking={props.linking}
            linkingMeta={props.linkingMeta}
          />
        </div>
        <div className={styles.row}>
          <LogicSequenceItemPortWidget
            node={props.sequenceItemNode}
            name={props.falseOutputPort.name}
            port={props.falseOutputPort}
            isEntry={props.isEntry}
            linking={props.linking}
            linkingMeta={props.linkingMeta}
          />
        </div>
      </div>
    </div>
  )
}

export const ConditionalSplitSequenceItemWidgetComponent = makeSequenceItem(
  ConditionalSplitComponent,
  [hocStyles['conditional-split-wrapper']],
  <SequenceItemWidgetTitle
    wrapperClasses={[]}
    icon={<ConditionalSplitIcon />}
    text='Conditional Split'
  />
)

export interface ConditionalSplitSequenceItemNodeWidgetComponentProps {
  sequenceItemNode: ConditionalSplitSequenceItemNodeModel
  repaintCanvas: () => void
}

export function ConditionalSplitSequenceItemNodeWidgetComponent(
  props: ConditionalSplitSequenceItemNodeWidgetComponentProps
) {
  const stateSelector = useCallback(
    createStructuredSelector({
      sequence: selectEditorSequence,
      sequenceItem: selectSequenceItem<ConditionalSplitSequenceItemUI>(
        props.sequenceItemNode.sequenceItemId
      ),
      sequenceItemMeta: selectSequenceItemMeta(props.sequenceItemNode.sequenceItemId),
      linking: selectLinking,
      linkingMeta: selectLinkingMeta,
      draggedItemType: selectDraggedItemType,
      currencySlug: selectCurrencySlug,
      showDebugView: selectShowDebugView
    }),
    [props.sequenceItemNode.sequenceItemId]
  )

  const state = useSelector(stateSelector)
  const dispatch = useDispatch()
  const [removing, setRemoving] = useState(false)

  function handleRepaintCanvas() {
    if (!removing) {
      props.repaintCanvas()
    }
  }

  function handleRemoveSequenceItem() {
    setRemoving(true)
    dispatch(removeSequenceItem(state.sequenceItem._id))
  }

  function handleCloneSequenceItem() {
    const newSequenceItem = cloneDeep(state.sequenceItem)
    newSequenceItem._id = new ObjectId().toHexString()
    newSequenceItem.trigger = [newSequenceItem._id]
    newSequenceItem.tags = [...state.sequence.tags]

    newSequenceItem.logic.conditionalSplit.defaultTrigger = null
    newSequenceItem.logic.conditionalSplit.variants.forEach((variant) => {
      variant.trigger = null
    })

    dispatch(
      createSequenceItem(newSequenceItem, {
        x: props.sequenceItemNode.x + DEFAULT_SEQUENCE_ITEM_OFFSET.x,
        y: props.sequenceItemNode.y + DEFAULT_SEQUENCE_ITEM_OFFSET.y
      })
    )
  }

  function handleAddCondition(condition: LogicConditionalSplitCondition) {
    dispatch(addCondition(state.sequenceItem._id, condition))
  }

  function handleRemoveCondition(index: number) {
    dispatch(removeCondition(state.sequenceItem._id, index))
  }

  function handleUpdateCondition(index: number, condition: LogicConditionalSplitCondition) {
    dispatch(updateCondition(state.sequenceItem._id, index, condition))
  }

  const isEntry = props.sequenceItemNode.sequenceItemId === state.sequence?.entrySequenceItemId
  const inputPort = props.sequenceItemNode.getInputPort()
  const trueOutputPort = props.sequenceItemNode.getPort(LOGIC_PORT_CONDITION_MET) as LogicPortModel
  const falseOutputPort = props.sequenceItemNode.getPort(
    LOGIC_PORT_CONDITION_UNMET
  ) as LogicPortModel

  return (
    <ConditionalSplitSequenceItemWidgetComponent
      currencySlug={state.currencySlug}
      faded={!!state.draggedItemType}
      inputPort={inputPort}
      isEntry={isEntry}
      linking={state.linking}
      linkingMeta={state.linkingMeta}
      trueOutputPort={trueOutputPort}
      falseOutputPort={falseOutputPort}
      sequenceItem={state.sequenceItem}
      sequenceItemMeta={state.sequenceItemMeta}
      sequenceItemNode={props.sequenceItemNode}
      showDropZone={false}
      showSequenceItemStatistics={false}
      showDebugView={state.showDebugView}
      repaintCanvas={handleRepaintCanvas}
      onClone={handleCloneSequenceItem}
      onRemove={handleRemoveSequenceItem}
      onAddCondition={handleAddCondition}
      onRemoveCondition={handleRemoveCondition}
      onUpdateCondition={handleUpdateCondition}
    />
  )
}

export const ConditionalSplitSequenceItemNodeWidget = React.memo(
  ConditionalSplitSequenceItemNodeWidgetComponent
)
