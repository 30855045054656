// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WQVIxhhByJpEFhkPf0Js{display:flex;flex-direction:column;width:220px}.nduOua12PSD2kYKhDSId{display:flex;flex-direction:row;justify-content:space-between}.nduOua12PSD2kYKhDSId .QDtyXD8F6gC7pjyPYnLq{font-weight:700;margin-bottom:12px}.nduOua12PSD2kYKhDSId svg{color:#73d13d;width:24px}.pFE8yA6SyuWeVgG4swMB{align-items:center;display:flex;flex-direction:column;margin:16px 0}.zs9gBhuBSXX72WAePVgo{background-color:#e4e4ff;border:1px solid #d3adf7;border-radius:2px;box-sizing:border-box;color:#722ed1;line-height:20px;padding:1px 8px}.y5xLsK08GHGpp3UQ4QIi{font-size:12px;line-height:14px;margin-top:7px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-code-success.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAEE,YAAA,CACA,qBAAA,CAFA,WAEA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,4CACE,eAAA,CACA,kBAAA,CAGF,0BAEE,aAAA,CADA,UACA,CAIJ,sBAGE,kBAAA,CAFA,YAAA,CACA,qBAAA,CAEA,aAAA,CAGF,sBAIE,wBCGO,CDFP,wBAAA,CAEA,iBAAA,CADA,qBAAA,CAJA,aCOO,CDNP,gBAAA,CAFA,eAMA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `WQVIxhhByJpEFhkPf0Js`,
	"header": `nduOua12PSD2kYKhDSId`,
	"title": `QDtyXD8F6gC7pjyPYnLq`,
	"codeContainer": `pFE8yA6SyuWeVgG4swMB`,
	"codeBox": `zs9gBhuBSXX72WAePVgo`,
	"codeType": `y5xLsK08GHGpp3UQ4QIi`
};
export default ___CSS_LOADER_EXPORT___;
