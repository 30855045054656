// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i7wJ8SqX5__7gDC7XMpf{display:inline-block;width:82px}.SBIgkVbFn9XALCLw59E9{font-size:var(--recart-font-size-base)}.SBIgkVbFn9XALCLw59E9 .ant-select-arrow{top:18px}`, "",{"version":3,"sources":["webpack://./src/control-components/make-filterable/filter-components/time-frame-filter/time-frame-filter.component.scss"],"names":[],"mappings":"AACE,sBACE,oBAAA,CACA,UAAA,CAGF,sBACE,sCAAA,CAGE,wCACE,QAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeInput": `i7wJ8SqX5__7gDC7XMpf`,
	"timeFrameSelect": `SBIgkVbFn9XALCLw59E9`
};
export default ___CSS_LOADER_EXPORT___;
