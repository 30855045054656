import { ButtonUI, MessengerMediaTemplatePayload } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import {
  ButtonInitialMeta,
  ButtonMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import { isAnyButtonSelected } from '../../../utils/is-any-button-selected'
import { FacebookFileEditor } from '../../facebook-file-editor/facebook-file-editor.component'
import {
  InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import { ButtonProps, MessengerButton } from '../../messenger-button/messenger-button.component'
import { MessengerTemplateProps } from '../messenger-template-props.type'
import styles from './messenger-media-template.component.scss'

export interface MessengerButtonTemplateProps {
  messengerTemplatePayload: MessengerMediaTemplatePayload
}

const InteractiveContent = makeInteractive<
  MessengerTemplateProps<MessengerMediaTemplatePayload> & InteractiveComponentProps
>((props) => {
  return (
    <FacebookFileEditor
      contentEditable
      attachmentType={props.messengerTemplatePayload.media_type}
      input={{ name: '', value: props.messengerTemplatePayload.url }}
      onFacebookUploadComplete={({ attachmentId, url }) =>
        props.onMessageChange({ attachment_id: attachmentId, url })
      }
      messageMeta={props.messageMeta}
      siteId={props.siteId}
    />
  )
})

const InteractiveButton = makeInteractive<ButtonProps>(MessengerButton)

export function MessengerMediaTemplate(
  props: MessengerTemplateProps<MessengerMediaTemplatePayload>
) {
  function handleAddButton(buttonIndex: number) {
    if (props.messengerTemplatePayload.buttons.length >= 3) {
      return false
    }
    props.onAddButton(buttonIndex)
    props.clearSelection()
  }

  function handleRemoveButton(buttonIndex: number) {
    if (props.messengerTemplatePayload.buttons[buttonIndex]) {
      props.onRemoveButton(buttonIndex)
    }
  }

  const isDragAndDropEnabled = useSequenceEditorSettings().dragAndDrop

  return (
    <div className={styles.container}>
      <InteractiveContent
        // MessengerTemplateProps
        {...props}
        // InteractiveComponentProps
        errorMessage={getErrorMessage(props.messageMeta.errors[0])}
        isFirst
        isRounded
        isLast={!props.messengerTemplatePayload.buttons.length}
        isSelected={props.messageMeta.selected && !isAnyButtonSelected(props.messageMeta)}
        isBlurred={
          (props.sequenceItemMeta.selected && !props.messageMeta.selected) ||
          (props.messageMeta.selected && isAnyButtonSelected(props.messageMeta))
        }
        hasAddButton={props.messengerTemplatePayload.buttons.length < 3}
        isDraggable={isDragAndDropEnabled}
        onRemoveElement={props.onRemove}
        onAddButtonClick={() => handleAddButton(0)}
        onMessageFocus={props.onMessageFocus}
      />
      {props.messengerTemplatePayload.buttons.length > 0 && (
        <div className={styles.buttons}>
          {props.messengerTemplatePayload.buttons.map((buttonPayload, buttonIndex) => {
            const buttonMeta: ButtonMeta = props.messageMeta.buttons[buttonIndex]
              ? props.messageMeta.buttons[buttonIndex]
              : cloneDeep(ButtonInitialMeta)
            return (
              <div key={`button-${buttonIndex}`} data-testid='media-button'>
                <InteractiveButton
                  // ButtonProps
                  payload={buttonPayload}
                  buttonTypeOptions={props.buttonTypeOptions}
                  sequenceItemId={props.sequenceItemNodeModel.sequenceItemId}
                  sequenceItemNodeId={props.sequenceItemNodeModel.getID()}
                  port={props.sequenceItemNodeModel.getButtonPort(
                    props.sequenceItemNodeModel.sequenceItemId,
                    props.messageIndex,
                    buttonIndex
                  )}
                  linking={props.linking}
                  linkingMeta={props.linkingMeta}
                  disableTypeChange={props.disableButtonTypeChange}
                  onChange={(buttonChange: Partial<ButtonUI>) =>
                    props.onButtonChange(buttonIndex, buttonChange)
                  }
                  onFocus={() => props.onButtonFocus(buttonIndex)}
                  onBlur={() => props.onButtonBlur()}
                  clearSelection={props.clearSelection}
                  // InteractiveComponentProps
                  errorMessage={buttonMeta.error}
                  isLast={buttonIndex === props.messengerTemplatePayload.buttons.length - 1}
                  isSelected={buttonMeta.selected}
                  isBlurred={!buttonMeta.selected && props.sequenceItemMeta.selected}
                  onRemoveElement={() => handleRemoveButton(buttonIndex)}
                  onAddButtonClick={() => handleAddButton(buttonIndex + 1)}
                  hasAddButton
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
