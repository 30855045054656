import {
  BooleanCondition,
  Condition,
  ConditionSlug,
  DateCondition,
  DateFilter,
  DateFilterType,
  NumberCondition,
  StringCondition,
  Subcondition,
  SubconditionSlug
} from '@ghostmonitor/recartapis'
import moment from 'moment'
import { BooleanConditionUI, ConditionUI } from '../../condition-ui.type'

/**
 *
 * In segmentation we have moment date types, but in conditionalSplitV2 we have ISO strings in redux store
 */
function convertDateFilterUItoAPI(conditionUI: ConditionUI): DateFilter {
  switch (conditionUI.dateType) {
    case DateFilterType.All:
    case DateFilterType.Exist:
    case DateFilterType.NotExist:
      return {
        type: conditionUI.dateType
      }
    case DateFilterType.Between:
    case DateFilterType.NotBetween: {
      const [dateFrom, dateTo] = conditionUI.dateRange
      return {
        type: conditionUI.dateType,
        dateFrom: moment.isMoment(dateFrom)
          ? dateFrom.utc().startOf('day').toISOString()
          : moment(dateFrom).utc().startOf('day').toISOString(),
        dateTo: moment.isMoment(dateTo)
          ? dateTo.utc().endOf('day').toISOString()
          : moment(dateTo).utc().endOf('day').toISOString()
      }
    }
    case DateFilterType.Since:
      return {
        type: conditionUI.dateType,
        dateFrom: moment.isMoment(conditionUI.date)
          ? conditionUI.date.utc().startOf('day').toISOString()
          : moment(conditionUI.date).utc().startOf('day').toISOString()
      }
    case DateFilterType.Until:
      return {
        type: conditionUI.dateType,
        dateTo: moment.isMoment(conditionUI.date)
          ? conditionUI.date.utc().endOf('day').toISOString()
          : moment(conditionUI.date).utc().endOf('day').toISOString()
      }
    case DateFilterType.On:
      return {
        type: conditionUI.dateType,
        dateFrom: moment.isMoment(conditionUI.date)
          ? conditionUI.date.utc().startOf('day').toISOString()
          : moment(conditionUI.date).utc().startOf('day').toISOString(),
        dateTo: moment.isMoment(conditionUI.date)
          ? conditionUI.date.utc().endOf('day').toISOString()
          : moment(conditionUI.date).utc().endOf('day').toISOString()
      }
    case DateFilterType.InTheLast:
    case DateFilterType.NotInTheLast:
      return {
        type: conditionUI.dateType,
        daysOffset: +conditionUI.daysOffset
      }
  }
}

function convertSubconditionUIToAPI(conditionUI: ConditionUI): Subcondition {
  switch (conditionUI.subconditionSlug) {
    case SubconditionSlug.PRODUCT:
      return {
        slug: conditionUI.subconditionSlug,
        category: conditionUI.subconditionCategory,
        operator: conditionUI.subconditionOperator,
        value: (Array.isArray(conditionUI.subconditionValue)
          ? conditionUI.subconditionValue
          : [conditionUI.subconditionValue]) as Subcondition['value']
      }
    case SubconditionSlug.SEQUENCE:
      return {
        slug: conditionUI.subconditionSlug,
        category: conditionUI.subconditionCategory,
        operator: conditionUI.subconditionOperator,
        value: conditionUI.subconditionValue
      }
    case SubconditionSlug.SUBSCRIPTION_SOURCE:
      return {
        slug: conditionUI.subconditionSlug,
        category: conditionUI.subconditionCategory,
        operator: conditionUI.subconditionOperator,
        value: ['integration-event', 'external-id'].includes(conditionUI.subconditionCategory)
          ? ([conditionUI.subconditionValue, ...conditionUI.subconditionSlugValue] as string[])
          : conditionUI.subconditionValue
      }
    case SubconditionSlug.LOCATION_CATEGORY:
      return {
        slug: conditionUI.subconditionSlug,
        category: conditionUI.subconditionCategory,
        operator: conditionUI.subconditionOperator,
        value: conditionUI.subconditionValue
      }
  }
}

function getConditionValue(conditionUI: ConditionUI): Partial<ConditionUI['value']> {
  if (conditionUI.value === null) {
    return undefined
  }

  return conditionUI.type === 'number' ? +conditionUI.value : conditionUI.value
}

export function convertConditionUIToAPI(conditionUI: ConditionUI): Condition {
  let condition: Condition

  if (conditionUI.slug === null) {
    // the reason of the null conditions is to represent an empty expression on the FE
    // they are only allowed in the form and can't go outside of it
    // If they accidentally have reached this point, it's considered a bug
    throw new Error('Condition slug cannot be null at this point')
  }

  switch (conditionUI.type) {
    case 'string':
      condition = {
        slug: conditionUI.slug,
        type: conditionUI.type,
        operator: conditionUI.operator,
        value: getConditionValue(conditionUI)
      } as StringCondition
      break
    case 'number':
      condition = {
        slug: conditionUI.slug,
        type: conditionUI.type,
        operator: conditionUI.operator,
        value: getConditionValue(conditionUI)
      } as NumberCondition
      break
    case 'boolean': {
      condition = {
        slug: conditionUI.slug,
        type: conditionUI.type,
        operator: conditionUI.operator,
        value: getConditionValue(conditionUI)
      } as BooleanCondition
      break
    }
    case 'date':
      condition = {
        slug: conditionUI.slug,
        type: conditionUI.type,
        operator: conditionUI.operator
      } as DateCondition
      break
  }

  if (conditionUI.filters) {
    condition.filters = conditionUI.filters
  }

  const isShopifyTagCondition = conditionUI.slug === ConditionSlug.SHOPIFY_TAG
  if (isShopifyTagCondition) {
    condition.slug =
      (conditionUI as BooleanConditionUI).shopifyTagType === 'order'
        ? ConditionSlug.ORDER_TAG
        : ConditionSlug.SUBSCRIBER_SHOPIFY_TAG
  }

  const isSubscriberDetailCondition = conditionUI.slug === ConditionSlug.SUBSCRIBER_DETAIL
  if (isSubscriberDetailCondition) {
    condition.field = conditionUI.field
  }

  if (condition) {
    condition.dateFilter = convertDateFilterUItoAPI(conditionUI)
    condition.subcondition = convertSubconditionUIToAPI(conditionUI)
  }

  return condition
}
