import { hooks } from './hooks'

export interface PhoneNumberTypes {
  hasTemporaryOnly: boolean
  hasTemporary: boolean
  hasInternational: boolean
}

export function usePhoneNumberTypes(): PhoneNumberTypes {
  const { data: phoneNumbers } = hooks.useSMSPhoneNumbers()

  if (!phoneNumbers) {
    return {
      hasTemporaryOnly: false,
      hasTemporary: false,
      hasInternational: false
    }
  }

  const hasTemporary = phoneNumbers.some(
    (phone) => phone.leaseType === 'temporary' && phone.status === 'completed'
  )
  const hasDedicated = phoneNumbers.some(
    (phone) => phone.leaseType !== 'temporary' && phone.status === 'completed'
  )

  const hasInternational = phoneNumbers.some(
    (phone) =>
      phone.supportedCountries.some((country) => country.handsetDialInCode !== 1) &&
      phone.status === 'completed'
  )

  return {
    hasTemporaryOnly: hasTemporary && !hasDedicated,
    hasTemporary,
    hasInternational
  }
}
