import { Channel, SMSSettings } from '@ghostmonitor/recartapis'
import sortBy from 'lodash/sortBy'
import {
  getCountryNameByCountryCode,
  smsCountries as countryOptions
} from '../utils/phonenumbers/phone-number-countries'
import { hooks } from './hooks'

export interface GeotargetingCountry {
  countryName: string
  countryCode: string
  canSend: boolean
  messagingType: string
}

export function useGeotargetingCountries(channel: Channel, messagingType?: 'one-way' | 'two-way') {
  const isOneWayForTwoWayMandatoryCountriesEnabled = hooks.useFeatureFlag(
    'enable-one-way-sms-for-two-way-mandatory-countries'
  )
  const isInternationalOptinToolGeotargetingEnabled = hooks.useFeatureFlag(
    'enable-international-optin-tool-geotargeting'
  )
  const { data: availableCountries, isLoading } = hooks.useAvailableCountries()

  let geotargetingCountries: GeotargetingCountry[] = []

  function getCanSendSMSToCountry(
    optinToolMessagingType: 'one-way' | 'two-way',
    country: SMSSettings.AvailableCountry
  ) {
    // for two-way messaging both needs to be two-way
    if (optinToolMessagingType === 'two-way') {
      return country.messagingType === 'two-way'
    }

    // for one-way optin tools we let to use two-way countries
    if (optinToolMessagingType === 'one-way') {
      const mandatoryTwoWayCountries = isOneWayForTwoWayMandatoryCountriesEnabled
        ? []
        : ['US', 'CA']
      return !mandatoryTwoWayCountries.includes(country.countryCode)
    }
  }

  if (channel === 'sms') {
    if (isInternationalOptinToolGeotargetingEnabled) {
      geotargetingCountries = countryOptions.map((country) => {
        const smsConfig = availableCountries?.find(
          (availableCountry) => availableCountry.countryCode === country.countryCode
        )

        if (smsConfig) {
          return {
            countryName: getCountryNameByCountryCode(smsConfig.countryCode),
            countryCode: smsConfig.countryCode,
            canSend: getCanSendSMSToCountry(messagingType, smsConfig),
            messagingType: smsConfig.messagingType
          }
        }

        return {
          countryName: country.country,
          countryCode: country.countryCode,
          canSend: false,
          messagingType
        }
      })
    } else {
      geotargetingCountries = availableCountries?.map((country) => ({
        countryName: getCountryNameByCountryCode(country.countryCode),
        countryCode: country.countryCode,
        canSend: getCanSendSMSToCountry(messagingType, country),
        messagingType: country.messagingType
      }))
    }
  } else {
    geotargetingCountries = countryOptions.map((country) => {
      return {
        countryName: country.country,
        countryCode: country.countryCode,
        canSend: true,
        messagingType: ''
      }
    })
  }

  return {
    geotargetingCountries: sortBy(geotargetingCountries, 'countryName'),
    isLoading
  }
}
