// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qWACfKdYWJHQjpNyMTqw .ant-form-item-label{margin-bottom:-5px!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/custom-conversation-wizard/comment-capture-input/comment-capture-input.component.scss"],"names":[],"mappings":"AAGM,2CACE,4BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment-capture-form": `qWACfKdYWJHQjpNyMTqw`
};
export default ___CSS_LOADER_EXPORT___;
