// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gkSpvzNkRmubEAgxzaKC{background:#fff;border:1px dashed #476dff;border-radius:23px;color:#476dff;cursor:pointer;font-size:16px;letter-spacing:-.1px;line-height:32px;margin-bottom:10px;position:relative;text-align:center;transition:all .1s}.gkSpvzNkRmubEAgxzaKC.TgYALowjlEF823YxU1Vn{background:#ecf0ff;border:1px dashed #7d97ff;color:#7d97ff}.gkSpvzNkRmubEAgxzaKC:hover{transform:scale(1.05);transform-origin:center}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/quick-reply-placeholder/quick-reply-placeholder.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAWE,eAAA,CARA,yBAAA,CAMA,kBAAA,CAPA,aCFY,CDuBZ,cAAA,CAlBA,cAAA,CACA,oBAAA,CAIA,gBAAA,CATA,kBAAA,CAMA,iBAAA,CAHA,iBAAA,CAIA,kBAeA,CAVA,2CAGE,kBAAA,CAFA,yBAAA,CACA,aACA,CAGF,4BAEE,qBAAA,CADA,uBACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quick-reply-port": `gkSpvzNkRmubEAgxzaKC`,
	"blurred": `TgYALowjlEF823YxU1Vn`
};
export default ___CSS_LOADER_EXPORT___;
