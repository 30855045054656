import { SequenceTag, SequenceUI } from '@ghostmonitor/recartapis'
import { useCallback } from 'react'
import { useSequencesByTags } from './resources/use-sequences-by-tags'

const tags = [SequenceTag.WELCOME]

export function useUniqueCustomWelcomeFlowNameValidator() {
  const { data: sequences } = useSequencesByTags(tags)

  const validate = useCallback(
    (sequence: SequenceUI): string | null => {
      return sequences.find((_sequence) => _sequence.name === sequence.name) === undefined
        ? null
        : 'This name is already in use.'
    },
    [sequences]
  )

  return {
    validate
  }
}
