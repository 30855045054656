import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'
import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../store/selectors'
import { isSMSMessageFlowItemUI } from '../types/guards/flow-item-ui.guards'
import { hooks } from './hooks'

export interface AttachedDiscountCodes {
  label: string
  discountCodeValue: string
  type: string
  fromSearch: boolean
}
export interface UseAttachableDiscountCodePools {
  data: AttachedDiscountCodes[]
  isLoading: boolean
}

export function useAttachableDiscountCodePoolsToFlow(): UseAttachableDiscountCodePools {
  const { data: discountCodePools, isLoading: isDiscountCodePoolsLoading } =
    hooks.useDiscountCodePools()

  const flowItems = useSelector(flowEditorSelectors.selectEditorFlowItems)

  const attachedDiscountPoolIds = flowItems
    ?.filter((flowItem) => isSMSMessageFlowItemUI(flowItem) && flowItem.item.discountCodePoolId)
    .map((flowItem) => {
      if (isSMSMessageFlowItemUI(flowItem)) {
        return flowItem.item.discountCodePoolId
      }
      return null
    })

  const convertedDiscountPools = discountCodePools?.map((discountCodePool) => {
    return {
      label: discountCodePool.name,
      discountCodeValue: discountCodePool.id,
      type: 'unique',
      fromSearch: !attachedDiscountPoolIds.includes(discountCodePool.id)
    }
  })

  return {
    isLoading: isDiscountCodePoolsLoading || discountCodePools === undefined,
    data: [...uniqWith(convertedDiscountPools, isEqual)]
  }
}
