import { useMemo } from 'react'
import { hooks } from '../hooks'

export function useAISegmentIds(): string[] {
  const { data: AISegments } = hooks.useAISegments()

  const AISegmentIds = useMemo(() => AISegments?.map((segment) => segment.id) ?? [], [AISegments])

  return AISegmentIds
}
