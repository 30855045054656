import type { SyncThunk } from '../../../../../store/redux-helpers'
import { selectEditorSequence } from '../../../../../store/selectors'
import { isTueWelcomeSequence } from '../../../../../utils/is-tue-welcome-sequence'
import { setSequenceEditorSetting } from '../../../hooks/use-sequence-editor-settings-context'
import { SequenceEditorSettings } from '../../context/sequence-editor-settings.type'

export function tueWelcomeSequencesThunk(): SyncThunk {
  return (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)

    if (isTueWelcomeSequence(sequence)) {
      setSequenceEditorSetting(SequenceEditorSettings.isEnabledLocked, true)
      setSequenceEditorSetting(SequenceEditorSettings.showSaveDraftButton, false)
    }
  }
}
