import { config } from '../config'

export async function initFacebookSdk() {
  loadFacebookJsSdk()
  await waitForFacebookInit()
  FB.XFBML.parse()
}

/* global FB */

let fbJsSdkInited = false
export function loadFacebookJsSdk(): void {
  // @ts-ignore
  if (NODE_ENV === 'test') {
    return
  }

  if (fbJsSdkInited) {
    return
  }

  /* eslint-disable */
  window.fbAsyncInit = function () {
    FB.init({
      appId: config.MESSENGER_APP_ID,
      xfbml: true,
      version: 'v18.0'
    })
    FB.AppEvents.logPageView()
    FB.XFBML.parse()
    fbJsSdkInited = true
  }
  ;(function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.setAttribute('defer', '')
    js.setAttribute('async', '')
    js.src = '//connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
  /* eslint-enable */
}

export async function waitForFacebookInit(): Promise<void> {
  function checkInited(resolve: () => void) {
    return () => {
      if (fbJsSdkInited) {
        resolve()
      } else {
        setTimeout(checkInited(resolve), 50)
      }
    }
  }

  return new Promise((resolve) => {
    checkInited(resolve)()
  })
}
