// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jx0IdyswzLdgmdCpp29V h2{font-family:Lato,sans-serif}.TmBSuRmqrY_wzxfZ8dCV{color:#595959;font-family:Lato,sans-serif;line-height:14px}.cBFRHjBFQM3XoYkKd2TU label{font-family:Lato,sans-serif;font-size:var(--recart-font-size-tiny);line-height:var(--recart-line-height-tiny)}.cBFRHjBFQM3XoYkKd2TU .ant-form-item-label{padding:0}.h6jDXzKCD1xDVwE48mpf{margin-bottom:0!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-edit-discount-code-unique/components/step-create-static/create-static-discount-code.scss"],"names":[],"mappings":"AAII,yBACE,2BAAA,CAIJ,sBAEE,aAAA,CADA,2BAAA,CAEA,gBAAA,CAIA,4BACE,2BAAA,CACA,sCAAA,CACA,0CAAA,CAIA,2CACE,SAAA,CAKN,sBACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jx0IdyswzLdgmdCpp29V`,
	"text": `TmBSuRmqrY_wzxfZ8dCV`,
	"formItem": `cBFRHjBFQM3XoYkKd2TU`,
	"formButtonWrapper": `h6jDXzKCD1xDVwE48mpf`
};
export default ___CSS_LOADER_EXPORT___;
