import {
  ConditionalSplitV2FlowItemAPI,
  ExpressionAPI,
  FlowAPI,
  LOGIC_PORT_CONDITION_UNMET
} from '@ghostmonitor/recartapis'

function isEmptyExpression(expression: ExpressionAPI): boolean {
  return expression.and[0].or.length === 0
}

export function checkConditionalSplitV2Integrity(
  flow: FlowAPI,
  flowItem: ConditionalSplitV2FlowItemAPI
) {
  const edges = flow.flowEditorDiagram.edges
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)
  const cases = flowItem.item.logic.conditionalSplitV2.cases

  if (cases.length > 0 && cases.length !== node.data.logicHandles.length - 1) {
    throw new Error(
      `Length of conditional split v2 cases and logicHandles should be equal. Variants: ${
        flowItem.item.logic.conditionalSplitV2.cases.length
      }, logicHandles (excluding else): ${node.data.logicHandles.length - 1}`
    )
  }

  flowItem.item.logic.conditionalSplitV2.cases.forEach((conditionalSplitCase, i) => {
    const logicHandle = node.data.logicHandles.filter(
      (handle) => handle.name !== LOGIC_PORT_CONDITION_UNMET
    )[i]
    const outgoingEdge = edges.find((edge) => edge.sourceHandle === logicHandle?.id)

    if (isEmptyExpression(conditionalSplitCase.expression)) {
      if (outgoingEdge !== undefined) {
        throw new Error(`Empty expression should not have outgoing edge. CaseIndex ${i}`)
      } else {
        return
      }
    }

    /**************************************
     * Expression case connected from trigger perspective
     *************************************/
    if (conditionalSplitCase.nextFlowItemTrigger) {
      if (outgoingEdge === undefined) {
        throw new Error(
          `There is expression case with next flow item trigger, but there is no outgoing edge. CaseIndex: ${i}`
        )
      }

      const targetNode = flow.flowEditorDiagram.nodes.find(
        (node) => node.data.flowItemId === conditionalSplitCase.nextFlowItemTrigger
      )

      if (targetNode === undefined) {
        throw new Error(`Cannot find target node for expression case. CaseIndex: ${i}`)
      }

      if (targetNode.id !== outgoingEdge.target) {
        throw new Error(`Target node id does not match for expression case. CaseIndex: ${i}`)
      }
    }

    /*****************************************
     * Expression case connected from connection perspective
     ****************************************/
    if (outgoingEdge !== undefined) {
      if (
        !isEmptyExpression(conditionalSplitCase.expression) &&
        !conditionalSplitCase.nextFlowItemTrigger
      ) {
        throw new Error(
          `There is outgoing edge, but there is no expression case with next flow item trigger. CaseIndex: ${i}`
        )
      }

      const targetNode = flow.flowEditorDiagram.nodes.find(
        (node) => node.id === outgoingEdge.target
      )

      if (targetNode === undefined) {
        throw new Error(`Cannot find target node for expression case. CaseIndex: ${i}`)
      }

      if (targetNode.id !== outgoingEdge.target) {
        throw new Error(`Target node id does not match for expression case. CaseIndex: ${i}`)
      }
    }
  })

  const conditionUnmetVariantTrigger =
    flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger
  const conditionUnmetLogicHandle = node.data.logicHandles.find(
    (handle) => handle.name === LOGIC_PORT_CONDITION_UNMET
  )
  const conditionUnmetOutgoingEdge = edges.find(
    (edge) => edge.sourceHandle === conditionUnmetLogicHandle?.id
  )

  /**************************************
   * Else connected from trigger perspective
   *************************************/
  if (conditionUnmetVariantTrigger) {
    if (conditionUnmetOutgoingEdge === undefined) {
      throw new Error(
        'There is else case with default next flow item trigger, but there is no outgoing edge'
      )
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.data.flowItemId === conditionUnmetVariantTrigger
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for else case`)
    }

    if (targetNode.id !== conditionUnmetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for else case`)
    }
  }

  /*****************************************
   * Else connected from connection perspective
   ****************************************/
  if (conditionUnmetOutgoingEdge !== undefined) {
    if (!conditionUnmetVariantTrigger) {
      throw new Error(
        'There is outgoing edge, but there is no else case with default next flow item trigger'
      )
    }

    const targetNode = flow.flowEditorDiagram.nodes.find(
      (node) => node.id === conditionUnmetOutgoingEdge.target
    )

    if (targetNode === undefined) {
      throw new Error(`Cannot find target node for else case}`)
    }

    if (targetNode.id !== conditionUnmetOutgoingEdge.target) {
      throw new Error(`Target node id does not match for else case`)
    }
  }
}
