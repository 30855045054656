import { SequenceTag, SequenceUI } from '@ghostmonitor/recartapis'
import { isWelcomeSequence } from '../routes/SequenceEditor/utils/assert-sequence-type'

// TODO: remove this file
export const tueFlows = [
  { tueSlug: 'messenger-atc-popup', tag: SequenceTag.MESSENGER_ATC_POPOUP_OPTIN },
  { tueSlug: 'discount-widget', tag: SequenceTag.DISCOUNT_WIDGET_OPTIN },
  { tueSlug: 'messenger-customer-chat', tag: SequenceTag.CUSTOMER_CHAT_OPTIN },
  { tueSlug: 'sticky-discount-box', tag: SequenceTag.STICKY_DISCOUNT_BOX_OPTIN },
  { tueSlug: 'order-page-widget', tag: SequenceTag.ORDER_PAGE_WIDGET_OPTIN },
  { tueSlug: 'atc-widget-optin', tag: SequenceTag.ATC_WIDGET_OPTIN }
]

export function getTueSlugForSequence(sequence: SequenceUI): string | undefined {
  return tueFlows.find((tue) => sequence.tags.includes(tue.tag))?.tueSlug
}

export function isTueWelcomeSequence(sequence: SequenceUI): boolean {
  const isTueSequence =
    sequence.tags.includes(SequenceTag.FBMESSAGE) &&
    sequence.tags.some((tag) => tueFlows.find((tue) => tue.tag === tag))

  return isWelcomeSequence(sequence) && isTueSequence
}
