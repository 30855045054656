import { Blast } from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import moment from 'moment'
import { loadScheduledFor } from '../../../utils/scheduled-for-date-handling'

export const blastValidationErrors = {
  EMPTY_NAME: 'Campaign name cannot be empty.',
  INVALID_DATE: 'You cannot select date in the past.'
}

export function blastNameValidator(blast: Blast): SerializedError {
  let error = null

  if (!blast) {
    return
  }

  if (blast.name.length === 0) {
    error = { message: blastValidationErrors.EMPTY_NAME }
  }

  return error
}
export function blastScheduledForValidator(blast: Blast, timezone: string): SerializedError {
  let error = null

  if (!blast) {
    return
  }

  const scheduledFor = loadScheduledFor(blast.scheduledFor, blast.scheduleType, timezone)
  const now = moment.tz(timezone)

  if (blast.scheduledFor && scheduledFor < now) {
    error = { message: blastValidationErrors.INVALID_DATE }
  }

  return error
}
