import { getVariablesFromRawValue } from '../../../../utils/inline-editor/inline-editor-utils'

export function getDiscountCodesFromText(text: string): string[] {
  const variables = getVariablesFromRawValue(text)

  const discountCodes = variables.reduce((accDiscountCodes, variable) => {
    if (variable.params.discount_code) {
      accDiscountCodes.add(variable.params.discount_code)
    }
    return accDiscountCodes
  }, new Set<string>())

  return Array.from(discountCodes)
}
