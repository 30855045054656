import {
  ButtonUI,
  isMessengerMessageSequenceItemUI,
  MessengerMessageUI,
  QuickReply,
  RandomSplitSequenceItemUI,
  SequenceItemUI,
  SMSMessageTextUI
} from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import memoize from 'lodash/memoize'
import { createSelector, Selector } from 'reselect'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import {
  SequenceEditorState,
  sequenceInitialMeta,
  sequenceItemInitialMeta
} from './sequence-editor.state'

export const selectSequenceEditor = (state: SequenceEditorState): SequenceEditorState => state
export const selectInitializedSettings = (state: SequenceEditorState) => state.initializedSettings
export const selectIsSettingsInitialized = (state: SequenceEditorState) =>
  state.isSettingsInitialized
export const selectEditorSequence = (state: SequenceEditorState) => state.sequence
export const selectEditorBlast = (state: SequenceEditorState) => state.blast
export const selectEditorBlastMeta = (state: SequenceEditorState) => state.blastMeta
export const selectEditorSequenceMeta = (state: SequenceEditorState) =>
  state.sequenceMeta ?? cloneDeep(sequenceInitialMeta)

export const selectSequenceItemsById = (state: SequenceEditorState) => state.sequenceItemsById
export const selectSequenceItemsMetaById = (state: SequenceEditorState) =>
  state.sequenceItemsMetaById

export const selectDraggedItemType = (state: SequenceEditorState) => state.draggedItemType
export const selectShowSequenceItemStatistics = (state: SequenceEditorState) =>
  state.showSequenceItemStatistics
export const selectShowDebugView = (state: SequenceEditorState) => state.showDebugView
export const selectLinking = (state: SequenceEditorState) => state.linking
export const selectLinkingMeta = (state: SequenceEditorState) => state.linkingMeta

export const selectEntrySequenceItem = createSelector(
  selectEditorSequence,
  selectSequenceItemsById,
  (currentSequence, sequenceItemsById) => {
    if (!currentSequence) {
      return undefined
    }
    const entrySequenceItemId = currentSequence.entrySequenceItemId
    return sequenceItemsById[entrySequenceItemId]
  }
)

export const selectValidDiscountCodes = createSelector(
  selectEditorSequenceMeta,
  (sequenceMeta) => sequenceMeta.validDiscountCodes
)
export const selectInvalidDiscountCodes = createSelector(
  selectEditorSequenceMeta,
  (sequenceMeta) => sequenceMeta.invalidDiscountCodes
)

export const selectExpiredDiscountCodes = createSelector(
  selectEditorSequenceMeta,
  (sequenceMeta) => sequenceMeta.expiredDiscountCodes
)

export const selectSequenceItem = memoize(
  <T extends SequenceItemUI>(sequenceItemId: string): Selector<SequenceEditorState, T> =>
    createSelector(
      selectSequenceItemsById,
      (sequenceItemsById) => sequenceItemsById[sequenceItemId] as T
    )
)

export const selectIsEntrySequenceItem = memoize((sequenceItemId: string) =>
  createSelector(
    selectEditorSequence,
    selectSequenceItem<SequenceItemUI>(sequenceItemId),
    (sequence, sequenceItem) => {
      return sequence?.entrySequenceItemId === sequenceItem?._id
    }
  )
)

export const selectSequenceItemMeta = memoize((sequenceItemId: string) =>
  createSelector(selectSequenceItemsMetaById, (sequenceItemsMetaById) => {
    return sequenceItemsMetaById[sequenceItemId] || cloneDeep(sequenceItemInitialMeta)
  })
)

export const selectMessage = memoize(
  <TMessage extends MessengerMessageUI | SMSMessageTextUI = MessengerMessageUI>(
    sequenceItemId: string,
    messageIndex: number
  ) =>
    createSelector(selectSequenceItemsById, (sequenceItemsById) => {
      const sequenceItem = sequenceItemsById[sequenceItemId]
      if (hasMessageOnSequenceItemType(sequenceItem)) {
        return sequenceItem.messages[messageIndex] as TMessage
      }
    }),
  (...args) => JSON.stringify(args)
)

export const selectMessageMeta = memoize(
  (sequenceItemId: string, messageIndex: number) =>
    createSelector(selectSequenceItemMeta(sequenceItemId), (sequenceItemMeta) => {
      return sequenceItemMeta.messages[messageIndex]
    }),
  (...args) => JSON.stringify(args)
)

export const selectVariableMeta = memoize(
  (sequenceItemId: string, messageIndex: number, variableIndex: number) =>
    createSelector(selectMessageMeta(sequenceItemId, messageIndex), (messageMeta) => {
      return messageMeta.variables[variableIndex]
    }),
  (...args) => JSON.stringify(args)
)

export const selectMessageItem = memoize(
  (sequenceItemId: string, messageIndex: number, messageItemIndex: number) =>
    createSelector(selectSequenceItemsById, (sequenceItemsById) => {
      const sequenceItem = sequenceItemsById[sequenceItemId]
      if (isMessengerMessageSequenceItemUI(sequenceItem)) {
        const message = sequenceItem.messages[messageIndex]
        if (isMessageGenericGalleryTemplate(message)) {
          return message.messengerTemplatePayload[messageItemIndex]
        }
      }
    }),
  (...args) => JSON.stringify(args)
)

export const selectMessageItemMeta = memoize(
  (sequenceItemId: string, messageIndex: number, messageItemIndex: number) =>
    createSelector(selectSequenceItemMeta(sequenceItemId), (sequenceItemMeta) => {
      return sequenceItemMeta?.messages[messageIndex]?.messageItems[messageItemIndex]
    }),
  (...args) => JSON.stringify(args)
)

export const selectButton = memoize(
  <T extends ButtonUI>(
    sequenceItemId: string,
    messageIndex: number,
    buttonIndex: number,
    messageItemIndex?: number
  ) =>
    createSelector(selectSequenceItemsById, (sequenceItemsById): T => {
      const sequenceItem = sequenceItemsById[sequenceItemId]
      if (hasMessageOnSequenceItemType(sequenceItem)) {
        const message = sequenceItem.messages[messageIndex]

        if (isMessageGenericGalleryTemplate(message)) {
          return message.messengerTemplatePayload[messageItemIndex].buttons[buttonIndex] as T
        }

        if (hasMessengerButtonOnMessageType(message)) {
          return message.messengerTemplatePayload.buttons[buttonIndex] as T
        }
      }
    }),
  (...args) => JSON.stringify(args)
)

export const selectButtonMeta = memoize(
  (sequenceItemId: string, messageIndex: number, buttonIndex: number, messageItemIndex?: number) =>
    createSelector(selectSequenceItemMeta(sequenceItemId), (sequenceItemMeta) => {
      return messageItemIndex !== undefined
        ? sequenceItemMeta?.messages[messageIndex]?.messageItems[messageItemIndex]?.buttons[
            buttonIndex
          ]
        : sequenceItemMeta?.messages[messageIndex]?.buttons[buttonIndex]
    }),
  (...args) => JSON.stringify(args)
)

export const selectQuickReplies = memoize((sequenceItemId: string) =>
  createSelector(selectSequenceItemsById, (sequenceItemsById): QuickReply[] => {
    const sequenceItem = sequenceItemsById[sequenceItemId]
    if (isMessengerMessageSequenceItemUI(sequenceItem)) {
      return sequenceItem.quickReplies
    }
  })
)

export const selectQuickReply = memoize(
  (sequenceItemId: string, quickReplyIndex: number) =>
    createSelector(selectSequenceItemsById, (sequenceItemsById): QuickReply => {
      const sequenceItem = sequenceItemsById[sequenceItemId]
      if (isMessengerMessageSequenceItemUI(sequenceItem)) {
        return sequenceItem.quickReplies[quickReplyIndex]
      }
    }),
  (...args) => JSON.stringify(args)
)

export const selectQuickReplyMeta = memoize(
  (sequenceItemId: string, quickReplyIndex: number) =>
    createSelector(
      selectSequenceItemMeta(sequenceItemId),
      (sequenceItemMeta) => sequenceItemMeta.quickReplies[quickReplyIndex]
    ),
  (...args) => JSON.stringify(args)
)

export const selectSplit = memoize(
  (sequenceItemId: string, splitIndex: number) =>
    createSelector(
      selectSequenceItem<RandomSplitSequenceItemUI>(sequenceItemId),
      (sequenceItem) => sequenceItem.logic.randomSplit.variants[splitIndex]
    ),
  (...args) => JSON.stringify(args)
)

export const selectSplitMeta = memoize(
  (sequenceItemId: string, splitIndex: number) =>
    createSelector(
      selectSequenceItemMeta(sequenceItemId),
      (sequenceItemMeta) => sequenceItemMeta.splits[splitIndex]
    ),
  (...args) => JSON.stringify(args)
)

export const selectIsReadOnly = (state: SequenceEditorState): boolean => state.isReadOnly
export const selectErrorCount = (state: SequenceEditorState): number => state.errorCount

export const selectSequenceItemErrorCount = memoize((sequenceItemId: string) =>
  createSelector(
    selectSequenceItem<SequenceItemUI>(sequenceItemId),
    selectSequenceItemMeta(sequenceItemId),
    (sequenceItem, sequenceItemMeta) => {
      let errorCount = 0
      if (sequenceItemMeta.error !== null) {
        errorCount += 1
      }

      sequenceItemMeta.splits.forEach((splitMeta) => {
        if (splitMeta.error !== null) {
          errorCount += 1
        }
      })

      sequenceItemMeta.messages.forEach((messageMeta) => {
        if (messageMeta.errors.length > 0) {
          errorCount += messageMeta.errors.length
        }

        messageMeta.messageItems.forEach((messageItemMeta) => {
          if (messageItemMeta.errors.length > 0) {
            errorCount += messageItemMeta.errors.length
          }
        })

        messageMeta.buttons.forEach((buttonMeta) => {
          if (buttonMeta.error !== null) {
            errorCount += 1
          }
        })
      })

      if (isMessengerMessageSequenceItemUI(sequenceItem)) {
        sequenceItemMeta.quickReplies.forEach((quickReplyMeta) => {
          if (quickReplyMeta.error !== null) {
            errorCount += 1
          }
        })
      }

      return errorCount
    }
  )
)

export const selectSaving = createSelector(selectEditorSequenceMeta, (sequenceMeta) => {
  return sequenceMeta.saving
})

export const selectSaved = createSelector(selectEditorSequenceMeta, (sequenceMeta) => {
  return sequenceMeta.isEverSaved
})

export const selectUnsavedChanges = createSelector(selectEditorSequenceMeta, (sequenceMeta) => {
  return sequenceMeta.unsavedChanges
})

export const selectIsSettingsDrawerVisible = (state: SequenceEditorState): boolean =>
  state.isSettingsDrawerVisible
