// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KbzhxhfUPkQhcZHIeZhn{color:#6d56f7;font-size:10px;letter-spacing:-.06px;margin-top:-12px;text-align:center}.PhotcRjivebV4iVh4xSn{padding-bottom:9px;padding-top:9px}.dGg3Jo3PiqZx_4PXmG1S{text-align:center}.pDDhEneFAdg3RkfJ88qW{border-color:#b6aaff;margin:18px 0}.pDDhEneFAdg3RkfJ88qW .ant-divider-inner-text{color:#b6aaff;font-size:10px;line-height:normal}.pDDhEneFAdg3RkfJ88qW:after,.pDDhEneFAdg3RkfJ88qW:before{border-color:#b6aaff!important}.wzZjSdCE4rTp3XZsQfQ8{align-items:center;display:flex}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-node-widget/conditional-split-node-widget/conditional-split-sequence-item-node-widget.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAIE,aC8DS,CDjET,cAAA,CACA,qBAAA,CAGA,gBAAA,CAFA,iBAEA,CAGF,sBAEE,kBAAA,CADA,eACA,CAGF,sBACE,iBAAA,CAGF,sBACE,oBC0CY,CDzCZ,aAAA,CAGE,8CAEE,aCoCQ,CDrCR,cAAA,CAEA,kBAAA,CAIJ,yDAEE,8BAAA,CAIJ,sBAEE,kBAAA,CADA,YACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"question": `KbzhxhfUPkQhcZHIeZhn`,
	"row": `PhotcRjivebV4iVh4xSn`,
	"center": `dGg3Jo3PiqZx_4PXmG1S`,
	"divider": `pDDhEneFAdg3RkfJ88qW`,
	"line": `wzZjSdCE4rTp3XZsQfQ8`
};
export default ___CSS_LOADER_EXPORT___;
