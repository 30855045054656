import { PortType } from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import { DiagramEngine, LinkModel, PortModel } from 'storm-react-diagrams'
import { SequenceEditorLinkModel } from '../link/link-model'
import { SequenceItemPortModel } from './sequence-item-port-model'

export abstract class SequenceEditorPortModel extends PortModel {
  public portType: PortType

  constructor(type: PortType, name?: string) {
    super(name, type.toString())
    this.portType = type
  }

  public serialize() {
    return merge(super.serialize(), {
      portType: this.portType
    })
  }

  public deSerialize(data: any, engine: DiagramEngine) {
    super.deSerialize(data, engine)
    this.portType = data.portType
  }

  public createLinkModel(): LinkModel {
    const linkModel = new SequenceEditorLinkModel()
    linkModel.color = 'rgb(207, 212, 221)'
    return linkModel
  }

  public canLinkToPort(port: SequenceItemPortModel): boolean {
    return port.portType !== this.portType
  }
}
