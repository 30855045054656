import { PortType } from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import { DiagramEngine } from 'storm-react-diagrams'
import { generateButtonParentId } from '../sequence-item/message/generate-button-parent-id'
import { generateButtonPortName } from '../sequence-item/message/generate-button-parent-name'
import { SequenceEditorPortModel } from './port-model'
import { SequenceItemPortModel } from './sequence-item-port-model'

export function getMessageIndexByName(name: string): number | undefined {
  const messageIndex = name.split('-')[1]
  return messageIndex === undefined ? undefined : parseInt(messageIndex, 10)
}

export function getButtonIndexByName(name: string): number | undefined {
  const buttonIndex = name.split('-')[2]
  return buttonIndex === undefined ? undefined : parseInt(buttonIndex, 10)
}

export function getMessageItemIndexByName(name: string): number | undefined {
  const messageItemIndex = name.split('-')[3]
  return messageItemIndex === undefined ? undefined : parseInt(messageItemIndex, 10)
}

export class ButtonPortModel extends SequenceEditorPortModel {
  public parentId: string
  public messageIndex: number
  public buttonIndex: number
  public messageItemIndex: number

  constructor(
    sequenceItemId?: string,
    messageIndex?: number,
    buttonIndex?: number,
    messageItemIndex?: number
  ) {
    let name: string
    if (sequenceItemId !== undefined && messageIndex !== undefined && buttonIndex !== undefined) {
      name = generateButtonPortName(sequenceItemId, messageIndex, buttonIndex, messageItemIndex)
    }
    super(PortType.BUTTON, name)
    if (sequenceItemId !== undefined && messageIndex !== undefined) {
      this.parentId = generateButtonParentId(sequenceItemId, messageIndex, messageItemIndex)
    }
    this.messageIndex = messageIndex
    this.buttonIndex = buttonIndex
    this.messageItemIndex = messageItemIndex
  }

  public serialize() {
    return merge(super.serialize(), {
      portType: this.portType,
      parentId: this.parentId,
      messageIndex: this.messageIndex,
      buttonIndex: this.buttonIndex,
      messageItemIndex: this.messageItemIndex
    })
  }

  public deSerialize(data: any, engine: DiagramEngine) {
    super.deSerialize(data, engine)
    this.portType = data.portType
    this.parentId = data.parentId

    this.messageIndex = data.messageIndex ?? getMessageIndexByName(this.name)
    this.buttonIndex = data.buttonIndex ?? getButtonIndexByName(this.name)
    this.messageItemIndex = data.messageItemIndex ?? getMessageItemIndexByName(this.name)
  }

  public canLinkToPort(port: SequenceItemPortModel): boolean {
    // At this point, link is already added, and this.getLinks() counts this +1 link,
    // even if we return with false at this point. If we return with false, the link is
    // going to be removed
    const linkingToSameNode = port.parent && port.parent.id === this.getParent().id
    return (
      port.portType === PortType.SEQUENCE_ITEM &&
      port.portType !== this.portType &&
      Object.values(this.getLinks()).length === 1 &&
      !linkingToSameNode
    )
  }
}
