import { OptinTool } from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import {
  OptinToolEditorState,
  OptinToolMeta
} from '../../../store/slices/optin-tool-editor/optin-tool-editor.reducer'
import { DeepPartial } from '../../../types/utils.type'
import { setLocalStorageItem } from '../../../utils/set-local-storage-item'

export function setLocalOptinTool({
  optinTool,
  optinToolMeta
}: OptinToolEditorState<OptinTool>): void {
  try {
    setLocalStorageItem(
      `optinToolEditor-${optinTool.id}`,
      JSON.stringify({ optinTool, optinToolMeta })
    )
  } catch (err) {
    console.log(err)
  }
}

export function getLocalOptinTool(optinToolId: string): OptinToolEditorState<OptinTool> {
  const optinToolEditor = localStorage.getItem(`optinToolEditor-${optinToolId}`)
  if (optinToolEditor === null) {
    return null
  }
  return JSON.parse(optinToolEditor)
}
export function removeLocalOptinTool(optinToolId: string): void {
  localStorage.removeItem(`optinToolEditor-${optinToolId}`)
}

export function patchLocalOptinTool(
  optinToolId: string,
  patchOptinTool: DeepPartial<OptinTool>,
  patchOptinToolMeta?: DeepPartial<OptinToolMeta>
): void {
  const optinToolEditor = getLocalOptinTool(optinToolId)
  if (optinToolEditor === null) {
    return
  }
  merge(optinToolEditor.optinTool, patchOptinTool)
  merge(optinToolEditor.optinToolMeta, patchOptinToolMeta)
  setLocalOptinTool(optinToolEditor)
}
