import {
  ConditionalSplitFlowItemUI,
  ConditionalSplitV2FlowItemUI,
  DelayFlowItemUI,
  DelayType,
  FlowItemType,
  FlowItemUI,
  FlowUI,
  getCanConvert,
  getEntryFlowItemTags,
  getEntryFlowItemTrigger,
  getFlowTrigger,
  getNonEntryFlowItemTags,
  getNonEntryFlowItemTrigger,
  LogicType,
  MessageType,
  NodeType,
  RandomSplitFlowItemUI,
  SequenceTag,
  SMSMessageFlowItemUI,
  SMSMessageTextUI
} from '@ghostmonitor/recartapis'
import { DeepPartial } from '@reduxjs/toolkit'
import { produce } from 'immer'
import merge from 'lodash/merge'
import { oneHourInNanosecs } from '../../../utils/time-conversion'
import { DEFAULT_SPLIT_VALUE } from '../../SequenceEditor/models/sequence-item/random-split/random-split-sequence-item-model'
import { conditionList } from '../flow-editor-config'

const twoWayLegalText =
  'Welcome to {{site.name|required}}! You are now subscribed - message & data rates may apply. Messages are recurring and frequency varies. Reply HELP for help. Reply STOP to cancel receiving further messages.'
const oneWayLegalText =
  'Welcome to {{site.name|required}}! You are now subscribed - message & data rates may apply. Messages are recurring and frequency varies. Click {{subscriber.unsubscribe_url|required|url_decorate|url_shorten}} to unsubscribe.'

export const legalMessages: SMSMessageTextUI[] = [
  {
    condition: { slug: 'messaging-type', value: 'two-way' },
    type: MessageType.SMS_TEXT,
    text: twoWayLegalText
  },
  {
    condition: { slug: 'messaging-type', value: 'one-way' },
    type: MessageType.SMS_TEXT,
    text: oneWayLegalText
  }
]

export function attachCanConvert<TFlowItemUI extends FlowItemUI>(
  flowItem: TFlowItemUI
): TFlowItemUI {
  return {
    ...flowItem,
    canConvert: getCanConvert(flowItem)
  }
}

function getNewSMSMessageFlowItem(
  flowItemId: string,
  flow: FlowUI,
  isCustomMessageHeaderEnabled: boolean
): SMSMessageFlowItemUI {
  return {
    _id: flowItemId,
    itemType: FlowItemType.SMS,
    site: flow.siteId,
    channel: 'sms',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    item: {
      messages: [
        {
          type: MessageType.SMS_TEXT,
          text: isCustomMessageHeaderEnabled ? '' : '{{site.name|required}}: '
        }
      ]
    },
    isEnabled: flow.isEnabled,
    tags: flow.tags,
    trigger: [flowItemId],
    canConvert: false
  }
}

function getNewDelayFlowItem(flowItemId: string, flow: FlowUI): DelayFlowItemUI {
  return {
    _id: flowItemId,
    itemType: FlowItemType.LOGIC,
    site: flow.siteId,
    channel: 'sms',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    item: {
      logic: {
        type: LogicType.DELAY,
        delay: {
          delay_duration: oneHourInNanosecs,
          // TODO it should be undefined
          trigger: '',
          type: DelayType.delay
        }
      }
    },
    isEnabled: flow.isEnabled,
    tags: flow.tags,
    trigger: [flowItemId],
    canConvert: false
  }
}

function getNewRandomizerFlowItem(flowItemId: string, flow: FlowUI): RandomSplitFlowItemUI {
  return {
    _id: flowItemId,
    itemType: FlowItemType.LOGIC,
    site: flow.siteId,
    channel: 'sms',

    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    item: {
      logic: {
        type: LogicType.RANDOM_SPLIT,
        split: {
          is_sticky: false,
          variants: [{ ...DEFAULT_SPLIT_VALUE }, { ...DEFAULT_SPLIT_VALUE }]
        }
      }
    },
    isEnabled: flow.isEnabled,
    tags: flow.tags,
    trigger: [flowItemId],
    canConvert: false
  }
}

function getNewConditionFlowItem(flowItemId: string, flow: FlowUI): ConditionalSplitFlowItemUI {
  return {
    _id: flowItemId,
    itemType: FlowItemType.LOGIC,
    site: flow.siteId,
    channel: 'sms',

    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    item: {
      logic: {
        type: LogicType.CONDITIONAL_SPLIT,
        conditional_split: {
          // default_trigger: undefined,
          variants: [
            {
              conditions: [conditionList.hasItemsInCart]
              // trigger: undefined
            }
          ]
        }
      }
    },
    isEnabled: flow.isEnabled,
    tags: flow.tags,
    trigger: [flowItemId],
    canConvert: false
  }
}

function getNewConditionV2FlowItem(flowItemId: string, flow: FlowUI): ConditionalSplitV2FlowItemUI {
  return {
    _id: flowItemId,
    itemType: FlowItemType.LOGIC,
    site: flow.siteId,
    channel: 'sms',

    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    item: {
      logic: {
        type: LogicType.CONDITIONAL_SPLIT_V2,
        conditionalSplitV2: {
          cases: [
            {
              expression: {
                and: [
                  {
                    or: [
                      {
                        slug: null,
                        type: null,
                        operator: null,
                        value: null
                      } as any
                    ]
                  }
                ]
              },
              nextFlowItemTrigger: ''
            }
          ],
          defaultNextFlowItemTrigger: ''
        }
      }
    },
    isEnabled: flow.isEnabled,
    tags: getNonEntryFlowItemTags(flow.tags),
    trigger: getNonEntryFlowItemTrigger(flowItemId),
    canConvert: false
  }
}

interface GetNewFlowItemConfig {
  nodeType: NodeType
  flowItemId: string
  flow: FlowUI
  isEntry?: boolean
  populatedValues?: DeepPartial<FlowItemUI>
  isCustomMessageHeaderEnabled?: boolean
  isAIGenerated?: boolean
}

export function getNewFlowItem<TFlowItemUI extends FlowItemUI>({
  nodeType,
  flowItemId,
  flow,
  isEntry = false,
  populatedValues,
  isCustomMessageHeaderEnabled = false,
  isAIGenerated = false
}: GetNewFlowItemConfig): TFlowItemUI {
  let flowItem: FlowItemUI | undefined

  if (nodeType === NodeType.SMS_FLOW_ITEM) {
    flowItem = getNewSMSMessageFlowItem(flowItemId, flow, isCustomMessageHeaderEnabled)
  }
  if (nodeType === NodeType.DELAY_FLOW_ITEM) {
    flowItem = getNewDelayFlowItem(flowItemId, flow)
  }
  if (nodeType === NodeType.RANDOM_SPLIT_FLOW_ITEM) {
    flowItem = getNewRandomizerFlowItem(flowItemId, flow)
  }
  if (nodeType === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM) {
    flowItem = getNewConditionFlowItem(flowItemId, flow)
  }
  if (nodeType === NodeType.CONDITIONAL_SPLIT_FLOW_ITEM_V2) {
    flowItem = getNewConditionV2FlowItem(flowItemId, flow)
  }

  if (!flowItem) {
    throw new Error(`Unknown node type: ${nodeType}`)
  }

  if (flow.sendingFrequency !== undefined) {
    flowItem.sendingFrequency = flow.sendingFrequency
  }

  if (flow.isSmartSendingEnabled !== undefined) {
    flowItem.isSmartSendingEnabled = flow.isSmartSendingEnabled
  }

  if (isEntry) {
    flowItem.tags = getEntryFlowItemTags(flow.tags, isAIGenerated)
    flowItem.trigger = getEntryFlowItemTrigger(getFlowTrigger(flow.tags), flow._id, flowItemId)
  } else {
    flowItem.tags = getNonEntryFlowItemTags(flow.tags, isAIGenerated)
    flowItem.trigger = getNonEntryFlowItemTrigger(flowItemId)
  }

  if (nodeType === NodeType.SMS_FLOW_ITEM && isAIGenerated) {
    flowItem.tags = Array.from(
      new Set([...flowItem.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
    )
    flow.tags = Array.from(
      new Set([...flow.tags, ...[SequenceTag.AI_TEXT_GENERATED, SequenceTag.AI]])
    )
  }

  // If there are additional tags, we merge them with the default ones
  flowItem = produce(flowItem, (draft) => {
    merge(draft, populatedValues)
  })

  // With the additional tags we can determine if the flow item can convert
  flowItem = attachCanConvert(flowItem)

  return flowItem as TFlowItemUI
}

export function getNewLegalSMSMessageFlowItem(
  flowItemId: string,
  flow: FlowUI,
  populatedValues: DeepPartial<SMSMessageFlowItemUI> = {}
): SMSMessageFlowItemUI {
  return getNewFlowItem<SMSMessageFlowItemUI>({
    nodeType: NodeType.SMS_FLOW_ITEM,
    flowItemId,
    flow,
    isEntry: true, // Legal is always an entry point
    populatedValues: merge(
      {
        item: { messages: legalMessages },
        tags: [SequenceTag.LEGAL_MESSAGE]
      },
      populatedValues
    )
  })
}
