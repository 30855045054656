import { FlowItemUI } from '@ghostmonitor/recartapis'
import { isSMSMessageFlowItemUI } from '../types/guards/flow-item-ui.guards'

export function getDiscountCodesFromFlowItem(flowItem: FlowItemUI): string[] {
  const discountCodes: Set<string> = new Set()

  if (isSMSMessageFlowItemUI(flowItem)) {
    (flowItem.item.discountCodes ?? []).forEach((discountCode) => {
      discountCodes.add(discountCode)
    })
  }

  return Array.from(discountCodes)
}
