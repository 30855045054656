import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectInitializedSettings } from '../../../store/selectors'
import { initializeSetting } from '../../../store/slices/sequence-editor/sequence-editor.actions'
import { createScope } from '../../../utils/logger/logger'
import { sequenceEditorGlobalSettings } from '../config'
import { SequenceEditorSettingsContextType } from '../settings/context/sequence-editor-settings.context.type'
import { initialSequenceEditorSettingsContext } from '../settings/context/sequence-editor-settings.initial'
import { SequenceEditorSettings } from '../settings/context/sequence-editor-settings.type'
const { developerError } = createScope('sequence-editor')

const context: {
  setSequencEditorSettings: (
    key: keyof SequenceEditorSettingsContextType,
    val: unknown,
    description?: string
  ) => void
} = {
  setSequencEditorSettings: () => undefined
}

export function setSequenceEditorSetting(
  key: keyof SequenceEditorSettingsContextType,
  val: unknown,
  description?: string
): void {
  context.setSequencEditorSettings(key, val, description)
  sequenceEditorGlobalSettings[key] = val
}

const oneTimeInitializedSettings = [
  SequenceEditorSettings.saveThunks,
  SequenceEditorSettings.toggleThunks
]

export function useSequenceEditorSettingsContext(): {
  sequenceEditorSettingsContext: SequenceEditorSettingsContextType
} {
  const [sequenceEditorSettingsContext, setSequenceEditorSettingsContext] = useState(
    initialSequenceEditorSettingsContext
  )
  const initializedSettings = useSelector(selectInitializedSettings)
  const dispatch = useDispatch()

  function patchSequenceEditorSettings(
    key: keyof SequenceEditorSettingsContextType,
    val: unknown,
    description?: string
  ) {
    if (initializedSettings.includes(key) && oneTimeInitializedSettings.includes(key)) {
      const error = new Error(
        `${key} is already set in this context. Check the initializeSetting actions for the first set.`
      )
      developerError(error)
      throw error
    }

    setSequenceEditorSettingsContext((prevState) => {
      return {
        ...prevState,
        [key]: val
      }
    })

    dispatch(initializeSetting({ name: key, description }))
  }

  context.setSequencEditorSettings = patchSequenceEditorSettings
  return {
    sequenceEditorSettingsContext
  }
}
