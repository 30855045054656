import ReactGA from 'react-ga'
import { config } from '../../config'

ReactGA.initialize(config.GA_ID, {
  gaOptions: {
    allowLinker: true
  }
})

ReactGA.plugin.require('linker')
ReactGA.plugin.execute('linker', 'autoLink', [
  'recart.com',
  'app.recart.com',
  'app.recart.co',
  'blog.recart.com',
  'help.recart.com'
])

export const gaPageView = ReactGA.pageview

export const gaEvent = ReactGA.event
