import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as ZoomInIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/26-Zoom/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from '../../../../../static/images/svg/streamline/stroke/01-Interface-Essential/26-Zoom/zoom-out.svg'
import { selectEditorSequence } from '../../../../../store/selectors'
import { useCanvasZoom } from '../../../hooks/use-canvas-zoom'
import { useDiagramEngine } from '../../../hooks/use-diagram-engine'
import { CanvasButtonGroup } from '../../canvas-toolbar/canvas-button-group/canvas-button-group.component'
import { CanvasButton } from '../../canvas-toolbar/canvas-button/canvas-button.component'
import { CanvasToolbarItem } from '../canvas-toolbar-item.component'

export function CanvasToolbarZoom() {
  const sequence = useSelector(selectEditorSequence)
  const { diagramEngine } = useDiagramEngine()

  const { zoomLevel, handleZoom } = useCanvasZoom(diagramEngine, sequence.serializedDiagram.zoom)

  return (
    <CanvasToolbarItem>
      <CanvasButtonGroup label='Zoom'>
        <CanvasButton title='Zoom out' icon={<ZoomOutIcon />} onClick={() => handleZoom('out')} />
        <CanvasButton
          title='Reset zoom level'
          readOnly
          onClick={() => handleZoom('reset')}
          extraStyle={{ minWidth: '70px' }}
          active
        >
          {`${zoomLevel}%`}
        </CanvasButton>
        <CanvasButton title='Zoom in' icon={<ZoomInIcon />} onClick={() => handleZoom('in')} />
      </CanvasButtonGroup>
    </CanvasToolbarItem>
  )
}
