import { LinkingMeta, PortType } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import React, { FunctionComponent } from 'react'
import { SequenceItemPortModel } from '../../models/port/sequence-item-port-model'
import { BaseSequenceItemNodeModel } from '../../models/sequence-item/base-sequence-item-model'
import {
  FOLLOW_UP_PORT,
  LOGIC_PORT_CONDITION_MET,
  LOGIC_PORT_CONDITION_UNMET,
  LOGIC_PORT_DELAY_TIMEOUT_REACHED,
  LOGIC_PORT_USER_INPUT_IS_VALID
} from '../../models/sequence-item/port-names'
import { Port } from '../port/port.component'
import styles from './logic-sequence-item-port-widget.component.scss'

export interface LogicSequenceIemPortProps {
  name?: string
  node: BaseSequenceItemNodeModel
  port: SequenceItemPortModel
  // eslint-disable-next-line react/no-unused-prop-types
  isEntry?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  blurred?: boolean
  linking: boolean
  linkingMeta?: LinkingMeta
}

export const LogicSequenceItemPortWidget: FunctionComponent<LogicSequenceIemPortProps> = (
  props
) => {
  let portTitle: string

  switch (props.port.name) {
    case LOGIC_PORT_DELAY_TIMEOUT_REACHED:
      portTitle = 'Next step'
      break
    case LOGIC_PORT_CONDITION_MET:
      portTitle = 'True'
      break
    case LOGIC_PORT_CONDITION_UNMET:
      portTitle = 'False'
      break
    case LOGIC_PORT_USER_INPUT_IS_VALID:
    case FOLLOW_UP_PORT:
      portTitle = 'Follow-up message'
      break
    default:
      portTitle = 'Next step'
      break
  }

  const links = Object.values(props.port.getLinks())
  const isConnected = links?.[0]?.getTargetPort() !== undefined
  const shouldPulse =
    props.linking &&
    props.linkingMeta?.portType === PortType.SEQUENCE_ITEM &&
    props.linkingMeta?.portParentSequenceItemId !== props.node.sequenceItemId &&
    !isConnected

  return (
    <div
      className={cn(styles['logic-sequence-item-port-wrapper'], 'logic-sequence-item-port-wrapper')}
    >
      <div className={styles.title}>{portTitle}</div>
      <div className={styles['port-container']}>
        <Port
          name={props.port.name}
          nodeId={props.node.getID()}
          isLinked={isConnected}
          isPulsing={shouldPulse}
          hintOrientation='right'
        />
      </div>
    </div>
  )
}
