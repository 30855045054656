import {
  DiscountType,
  DiscountTypes,
  GetStaticDiscountCodeResponse,
  StaticDiscountCode
} from '@ghostmonitor/recartapis'
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { hooks } from '../../../../../../hooks/hooks'
import { SequenceEditorError } from '../../../../types/sequence-editor-errors'
import { buttonValidationErrors } from '../../../../validators/button.validator'
import { DiscountAdded } from './discount-added.component'
import { DiscountButtons } from './discount-buttons.component'
import { DiscountCodeError } from './discount-code-error.component'
import { DiscountCodeLogos } from './discount-code-logos.component'
import { DiscountCodeSuccess } from './discount-code-success.component'
import { DiscountCreate } from './discount-create.component'
import { DiscountError } from './discount-error.component'
import { DiscountSelectExisting } from './discount-select-existing.component'

export interface DiscountCodeProps {
  code: string
  errorMessage?: string
  onChange: (code: string) => void
  onShowInfo: (info: React.ReactElement) => void
}

enum DiscountPhases {
  BUTTONS = 'buttons',
  SELECT_EXISTING = 'existing',
  DISPLAY_ADDED = 'added',
  IMPORT_ERROR = 'importError',
  CREATE = 'create'
}

export function DiscountCode(props: DiscountCodeProps) {
  const [discountPhase, setDiscountPhase] = useState<DiscountPhases>()
  const [selectedCode, setSelectedCode] = useState('')
  const [addedCode, setAddedCode] = useState('')
  const defaultType = DiscountTypes.PERCENTAGE
  const [addedType, setAddedType] = useState<DiscountType>(defaultType)
  const [isDiscountCodeValidating, setIsDiscountCodeValidating] = useState(false)

  const { create: createDiscountCode, invalidateCache } = hooks.useDiscountCodes()
  const {
    data: discountCodeFromDB,
    error: errorDiscountCodeFromDB,
    isLoading: isDiscountCodeFromDBLoading
  } = hooks.useDiscountCode(isDiscountCodeValidating ? selectedCode : undefined)

  useEffect(() => {
    if (props.code === undefined || props.code.length === 0) {
      setDiscountPhase(DiscountPhases.BUTTONS)
    } else if (props.errorMessage === buttonValidationErrors.INVALID_DISCOUNT_CODE) {
      setSelectedCode(props.code)
      setDiscountPhase(DiscountPhases.IMPORT_ERROR)
    } else {
      setAddedCode(props.code)
      setSelectedCode(props.code)
      setDiscountPhase(DiscountPhases.DISPLAY_ADDED)
      setIsDiscountCodeValidating(true)
    }
  }, [])

  useEffect(() => {
    if (props.errorMessage === buttonValidationErrors.INVALID_DISCOUNT_CODE && props.code) {
      setSelectedCode(props.code)
      setDiscountPhase(DiscountPhases.IMPORT_ERROR)
    }
  }, [props.errorMessage])

  function handleImportError() {
    setAddedCode('')
    setAddedType(defaultType)
    setDiscountPhase(DiscountPhases.IMPORT_ERROR)
  }

  useEffect(() => {
    if (errorDiscountCodeFromDB) {
      handleImportError()
    }
  }, [errorDiscountCodeFromDB])

  useEffect(() => {
    if (isDiscountCodeValidating && !isDiscountCodeFromDBLoading) {
      setIsDiscountCodeValidating(false)

      if (
        errorDiscountCodeFromDB &&
        errorDiscountCodeFromDB.name === SequenceEditorError.DiscountCodeExpired
      ) {
        handleImportError()
      } else if (!errorDiscountCodeFromDB) {
        applySelectedCode(discountCodeFromDB)
      }
    }
  }, [discountCodeFromDB, discountPhase, errorDiscountCodeFromDB])

  function handleRetry() {
    props.onShowInfo(undefined)
  }

  function renderSuccessInfo(code: string, type: DiscountType) {
    return <DiscountCodeSuccess code={code} type={type} />
  }

  function renderErrorInfo() {
    return <DiscountCodeError onRetry={handleRetry} />
  }

  function handleCreateError() {
    setAddedCode('')
    setAddedType(defaultType)
    props.onShowInfo(renderErrorInfo())
  }

  function applySelectedCode(discountCode: StaticDiscountCode, showInfo = false) {
    const code = discountCode?.title
    const id = discountCode?.priceRuleId
    setAddedCode(code)
    setAddedType(DiscountTypes[discountCode?.type])

    setDiscountPhase(DiscountPhases.DISPLAY_ADDED)

    const change = JSON.stringify({ code, id })
    props.onChange(change)
    if (showInfo) {
      props.onShowInfo(renderSuccessInfo(discountCode.title, DiscountTypes[discountCode.type]))
    }
  }

  async function handleCreateCode(code: string, type: DiscountType, amount?: number) {
    setSelectedCode(code)
    const discountCodeToCreate = {
      discountCode: code,
      type,
      amount: amount ? amount * -1 : undefined,
      title: code
    } as StaticDiscountCode

    try {
      const response: GetStaticDiscountCodeResponse = await createDiscountCode(discountCodeToCreate)
      const createdCode = response.data
      invalidateCache(code)
      setIsDiscountCodeValidating(true)

      if (createdCode !== undefined) {
        applySelectedCode(createdCode, true)
      }
    } catch (err) {
      handleCreateError()
    }
  }

  function handleAddExistingCode(code: string) {
    setSelectedCode(code)
    setIsDiscountCodeValidating(true)
  }

  function handleCreateNew() {
    setDiscountPhase(DiscountPhases.CREATE)
  }

  function handleSelectExisting() {
    setDiscountPhase(DiscountPhases.SELECT_EXISTING)
  }

  function handleBack() {
    if (props.errorMessage) {
      setDiscountPhase(DiscountPhases.IMPORT_ERROR)
    } else {
      setDiscountPhase(DiscountPhases.BUTTONS)
    }
  }

  function handleRemove() {
    const change = JSON.stringify({ code: '', _id: '' })
    props.onChange(change)
    setSelectedCode('')
    setAddedCode('')
    setAddedType(defaultType)
    setDiscountPhase(DiscountPhases.BUTTONS)
  }

  function renderDiscountPhases(phase: DiscountPhases) {
    return {
      [DiscountPhases.BUTTONS]: (
        <DiscountButtons onCreateNew={handleCreateNew} onSelectExisting={handleSelectExisting} />
      ),
      [DiscountPhases.SELECT_EXISTING]: (
        <DiscountSelectExisting onBack={handleBack} onAddCode={handleAddExistingCode} />
      ),
      [DiscountPhases.CREATE]: <DiscountCreate onBack={handleBack} onCreate={handleCreateCode} />,
      [DiscountPhases.DISPLAY_ADDED]: (
        <DiscountAdded code={addedCode} type={addedType} onRemove={handleRemove} />
      ),
      [DiscountPhases.IMPORT_ERROR]: (
        <>
          <DiscountError code={selectedCode} />
          <DiscountButtons
            onCreateNew={handleCreateNew}
            onSelectExisting={handleSelectExisting}
            onRemove={handleRemove}
          />
        </>
      )
    }[phase]
  }

  return (
    <>
      <Divider orientation='left'>Discount Code</Divider>
      <DiscountCodeLogos />
      {renderDiscountPhases(discountPhase)}
    </>
  )
}
