import { FlowItemUI, FlowUI, LOGIC_PORT_CONDITION_UNMET } from '@ghostmonitor/recartapis'
import {
  generateKeywordReplyHandleName,
  parseReplyKeywordFromHandleName
} from '../../../../routes/flow-editor/utils/generate-keyword-reply-handle-name'
import {
  hasKeywordRepliesOnFlowItem,
  isConditionalSplitFlowItemUI,
  isConditionalSplitV2FlowItemUI,
  isDelayFlowItemUI,
  isRandomSplitFlowItemUI,
  isSMSMessageFlowItemUI
} from '../../../../types/guards/flow-item-ui.guards'
import { selectEditorFlow, selectFlowItem, selectFlowItemsById } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'

export function updateFlowItemTrigger(flowItem: FlowItemUI, flow: FlowUI) {
  const node = flow.flowEditorDiagram.nodes.find((node) => node.data.flowItemId === flowItem._id)

  if (!node) {
    throw new Error(`Node with flowItemId ${flowItem._id} not found`)
  }

  const edges = flow.flowEditorDiagram.edges.filter((edge) => edge.source === node.id)

  if (isSMSMessageFlowItemUI(flowItem)) {
    if (hasKeywordRepliesOnFlowItem(flowItem)) {
      const keywordReplyHandles = node.data.keywordReplyHandles

      if (!keywordReplyHandles) {
        throw new Error(`Node with id ${node.id} has no keywordReplyHandles`)
      }

      if (edges.length > 0) {
        edges.forEach((edge) => {
          const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
          if (!targetNode) {
            throw new Error(`Target node with id ${edge.target} not found`)
          }

          if (!targetNode.data.flowItemId) {
            throw new Error(`Target node with id ${edge.target} has no flowItemId`)
          }

          const keywordReplyHandle = keywordReplyHandles.find((h) => h.id === edge.sourceHandle)

          if (keywordReplyHandle) {
            const replyKeyword = parseReplyKeywordFromHandleName(keywordReplyHandle.name)
            flowItem.item.replyKeywordSettings?.keywords.forEach((keyword) => {
              if (keyword.keyword === replyKeyword) {
                keyword.trigger = targetNode.data.flowItemId!
              }
            })
          }
        })
      }

      flowItem.item.replyKeywordSettings?.keywords.forEach((keyword) => {
        if (keyword.trigger) {
          const handleName = generateKeywordReplyHandleName(flowItem._id, keyword.keyword)
          const handleId = keywordReplyHandles.find((h) => h.name === handleName)?.id
          const isThereAnEdgeConnected = edges.some((edge) => edge.sourceHandle === handleId)

          if (!isThereAnEdgeConnected) {
            keyword.trigger = ''
          }
        }
      })
    }
    if (
      edges.length > 0 &&
      (!flowItem.item.replyKeywordSettings ||
        flowItem.item.replyKeywordSettings.keywords.length === 0)
    ) {
      const edge = edges[0]
      const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
      if (!targetNode) {
        throw new Error(`Target node with id ${edge.target} not found`)
      }

      if (!targetNode.data.flowItemId) {
        throw new Error(`Target node with id ${edge.target} has no flowItemId`)
      }

      flowItem.item.nextSequenceItemTrigger = targetNode.data.flowItemId
    } else {
      delete flowItem.item.nextSequenceItemTrigger
    }
  } else if (isDelayFlowItemUI(flowItem)) {
    if (edges.length > 0) {
      const edge = edges[0]
      const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
      if (!targetNode) {
        throw new Error(`Target node with id ${edge.target} not found`)
      }

      if (!targetNode.data.flowItemId) {
        throw new Error(`Target node with id ${edge.target} has no flowItemId`)
      }

      flowItem.item.logic.delay.trigger = targetNode.data.flowItemId
    } else {
      // TODO https://github.com/ghostmonitor/recartapis/pull/696/files
      flowItem.item.logic.delay.trigger = ''
    }
  } else if (isRandomSplitFlowItemUI(flowItem)) {
    const logicHandles = node.data.logicHandles

    if (!logicHandles) {
      throw new Error(`Node with id ${node.id} has no logicHandles`)
    }

    flowItem.item.logic.split.variants.forEach((variant) => {
      // TODO https://github.com/ghostmonitor/recartapis/pull/696/files
      variant.trigger = null
    })

    if (edges.length > 0) {
      edges.forEach((edge) => {
        const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
        if (!targetNode) {
          throw new Error(`Target node with id ${edge.target} not found`)
        }

        if (!targetNode.data.flowItemId) {
          throw new Error(`Target node with id ${edge.target} has no flowItemId`)
        }

        const logicHandleIndex = logicHandles.findIndex(
          (logicHandle) => logicHandle.id === edge.sourceHandle
        )

        const variant = flowItem.item.logic.split.variants[logicHandleIndex]
        if (!variant) {
          throw new Error(`Variant with index ${logicHandleIndex} not found`)
        }

        variant.trigger = targetNode.data.flowItemId
      })
    }
  } else if (isConditionalSplitFlowItemUI(flowItem)) {
    delete flowItem.item.logic.conditional_split.default_trigger
    delete flowItem.item.logic.conditional_split.variants[0].trigger

    const logicHandles = node.data.logicHandles

    if (!logicHandles) {
      throw new Error(`Node with id ${node.id} has no logicHandles`)
    }

    if (edges.length > 0) {
      edges.forEach((edge) => {
        const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
        if (!targetNode) {
          throw new Error(`Target node with id ${edge.target} not found`)
        }

        if (!targetNode.data.flowItemId) {
          throw new Error(`Target node with id ${edge.target} has no flowItemId`)
        }

        const logicHandle = logicHandles.find((handle) => handle.id === edge.sourceHandle)
        if (!logicHandle) {
          throw new Error(`Logic handle with id ${edge.sourceHandle} not found`)
        }

        if (logicHandle.name === LOGIC_PORT_CONDITION_UNMET) {
          flowItem.item.logic.conditional_split.default_trigger = targetNode.data.flowItemId
        } else {
          flowItem.item.logic.conditional_split.variants[0].trigger = targetNode.data.flowItemId
        }
      })
    }
  } else if (isConditionalSplitV2FlowItemUI(flowItem)) {
    flowItem.item.logic.conditionalSplitV2.cases.forEach((conditionalCase) => {
      conditionalCase.nextFlowItemTrigger = ''
    })
    flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger = ''

    const logicHandles = node.data.logicHandles

    if (!logicHandles) {
      throw new Error(`Node with id ${node.id} has no logicHandles`)
    }

    if (edges.length > 0) {
      edges.forEach((edge) => {
        const targetNode = flow.flowEditorDiagram.nodes.find((node) => node.id === edge.target)
        if (!targetNode) {
          throw new Error(`Target node with id ${edge.target} not found`)
        }

        if (!targetNode.data.flowItemId) {
          throw new Error(`Target node with id ${edge.target} has no flowItemId`)
        }
        const conditionHandle = node.data.logicHandles?.find((h) => h.id === edge.sourceHandle)
        if (conditionHandle) {
          if (conditionHandle.name === LOGIC_PORT_CONDITION_UNMET) {
            flowItem.item.logic.conditionalSplitV2.defaultNextFlowItemTrigger =
              targetNode.data.flowItemId!
          } else {
            flowItem.item.logic.conditionalSplitV2.cases[
              conditionHandle.expressionIndex!
            ].nextFlowItemTrigger = targetNode.data.flowItemId!
          }
        }
      })
    }
  }
}
export function updateFlowItemsTrigger(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('No flow found')
  }

  const flowItems = selectFlowItemsById(state)

  if (!flowItems) {
    throw new Error('No flow items found')
  }

  Object.keys(flowItems).forEach((flowItemId) => {
    const flowItemUI: FlowItemUI = selectFlowItem(flowItemId)(state)
    updateFlowItemTrigger(flowItemUI, flow)
  })
}
