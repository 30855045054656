export enum EntryPointType {
  BOT_PAYLOAD = 'bot-payload',
  CTM = 'click-to-messenger',
  SPONSORED_MESSAGE = 'sponsored-message',
  COMMENT_CAPTURE = 'comment-capture'
}

export enum WizardStep {
  ENTRY_POINT = 'entry-point',
  CAMPAIGN = 'campaign',
  AD_SET = 'ad-set',
  AD = 'ad',
  AD_SET_CONFIG = 'ad-set-config',
  OVERVIEW = 'overview'
}

export interface BaseFbAdObject {
  id: string
  name: string
  status: string
  delivery: string
  updatedAt: string
  purchaseRoas: number
}

export interface AdSetConfig {
  scheduling: {
    startDate: string
    endDate: string
  }
  budget: {
    type: string
    budget: number
    bidControl: number
  }
}

export interface CampaignObject extends BaseFbAdObject {
  objective: string
}

export interface AdSetObject extends BaseFbAdObject {
  destination: string
  startTime: string
  endTime?: string
  pageId?: string
}

export interface AdObject extends BaseFbAdObject {
  adType: string
}

export interface BudgetRules {
  bid: {
    default: number
    minimum: number
    maximum: number
  }
  dailyBudget: {
    minimum: number
  }
  lifetimeBudget: {
    default: number
    maximum: number
  }
}

export interface FacebookPixel {
  id: string
  name: string
}

export interface FacebookPost {
  // eslint-disable-next-line camelcase
  created_time: string
  id: string
}
