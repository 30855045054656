import type { SyncThunk } from '../../../../../store/redux-helpers'
import { selectEditorSequence } from '../../../../../store/selectors'
import { isCustomWelcomeSequence } from '../../../../../utils/is-custom-welcome-sequence'
import { CustomWelcomeSaveModal } from '../../../components/custom-welcome-save-modal/custom-welcome-save-modal.component'
import { setSequenceEditorSetting } from '../../../hooks/use-sequence-editor-settings-context'
import { SequenceEditorSettings } from '../../context/sequence-editor-settings.type'

export function customWelcomeSequencesThunk(): SyncThunk {
  return (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)

    if (isCustomWelcomeSequence(sequence)) {
      setSequenceEditorSetting(SequenceEditorSettings.saveModalComponent, CustomWelcomeSaveModal)
      setSequenceEditorSetting(SequenceEditorSettings.isEnabledLocked, true)
      setSequenceEditorSetting(SequenceEditorSettings.showSaveDraftButton, false)

      // SequenceEditorSettings.saveThunks is set in
      // 1: sms.thunk.ts
      // 2: messenger.thunk.ts
    }
  }
}
