import { SequenceItemUI, SequenceUI } from '@ghostmonitor/recartapis'
import { Action, Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import type { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { selectEditorSequence, selectSequenceItem } from '../../../../store/selectors'
import {
  CREATE_BUTTON,
  CREATE_MESSAGE,
  CREATE_MESSAGE_ITEM,
  CREATE_QUICK_REPLY,
  CREATE_SPLIT,
  CreateButtonAction,
  CreateMessageAction,
  CreateMessageItemAction,
  CreateQuickReplyAction,
  CreateSplitAction,
  REMOVE_BUTTON,
  REMOVE_MESSAGE,
  REMOVE_MESSAGE_ITEM,
  REMOVE_QUICK_REPLY,
  REMOVE_SPLIT,
  REORDER_MESSAGE,
  RemoveButtonAction,
  RemoveMessageAction,
  RemoveMessageItemAction,
  RemoveQuickReplyAction,
  RemoveSplitAction,
  ReorderMessageAction,
  UPDATE_MESSAGE,
  UPDATE_SPLIT,
  UpdateMessageAction,
  UpdateSplitAction,
  sequenceItemValidationError,
  sequenceItemValidationSuccess
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { isFacebookCommentsSequenceItem } from '../../utils/assert-sequence-item-type'
import { facebookCommentsValidator } from '../../validators/facebook-comments.validator'
import { sequenceItemValidator } from '../../validators/sequence-item.validator'

const DEBOUNCE_DELAY = 400

function getValidateSequenceItem(dispatch: AppDispatch) {
  return debounce(
    (sequence: SequenceUI, sequenceItem: SequenceItemUI, action: ValidableSequenceItemAction) => {
      const sequenceItemErrors = sequenceItemValidator(sequence, sequenceItem)

      if (
        isFacebookCommentsSequenceItem(sequenceItem) &&
        sequence.entrySequenceItemId === sequenceItem._id
      ) {
        sequenceItemErrors.push(...facebookCommentsValidator(sequenceItem))
      }

      if (sequenceItemErrors.length > 0) {
        dispatch(
          sequenceItemValidationError(sequenceItemErrors[0], {
            sequenceItemId: action.sequenceItemId
          })
        )
      } else {
        dispatch(
          sequenceItemValidationSuccess(null, {
            sequenceItemId: action.sequenceItemId
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

type ValidableSequenceItemAction =
  | CreateButtonAction
  | RemoveButtonAction
  | CreateMessageAction
  | RemoveMessageAction
  | CreateMessageItemAction
  | RemoveMessageItemAction
  | CreateQuickReplyAction
  | RemoveQuickReplyAction
  | CreateSplitAction
  | UpdateSplitAction
  | RemoveSplitAction
  | ReorderMessageAction
  | UpdateMessageAction

function isValidableAction(action: Action): action is ValidableSequenceItemAction {
  return [
    CREATE_BUTTON,
    REMOVE_BUTTON,
    CREATE_MESSAGE,
    REMOVE_MESSAGE,
    CREATE_MESSAGE_ITEM,
    REMOVE_MESSAGE_ITEM,
    CREATE_QUICK_REPLY,
    REMOVE_QUICK_REPLY,
    CREATE_SPLIT,
    REMOVE_SPLIT,
    UPDATE_SPLIT,
    REORDER_MESSAGE,
    UPDATE_MESSAGE
  ].includes(action.type)
}

export function validateSequenceItemMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateSequenceItem = getValidateSequenceItem(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)

    if (isValidableAction(action)) {
      const state = store.getState()

      const sequence = selectEditorSequence(state)
      const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)

      validateSequenceItem(sequence, sequenceItem, action)
    }
  }
}
