import { OptinToolStep } from '@ghostmonitor/recartapis'

export const popupCustomCSSPrefix = 'recart-popup-custom-'

export const generalCustomCSSClasses = {
  overlay: `${popupCustomCSSPrefix}overlay`,
  wrapper: `${popupCustomCSSPrefix}wrapper`,
  additionalImage: `${popupCustomCSSPrefix}additional-image`,
  close: `${popupCustomCSSPrefix}close`,
  minimized: `${popupCustomCSSPrefix}minimized`,
  minimizedClose: `${popupCustomCSSPrefix}minimized-close`
}

export const teaserCustomCSSClasses = {
  content: `${popupCustomCSSPrefix}teaser-content`,
  imageWrapper: `${popupCustomCSSPrefix}teaser-image-wrapper`,
  image: `${popupCustomCSSPrefix}teaser-image`,
  secondaryImageWrapper: `${popupCustomCSSPrefix}teaser-secondary-image-wrapper`,
  secondaryImage: `${popupCustomCSSPrefix}teaser-secondary-image`,
  headingTop: `${popupCustomCSSPrefix}teaser-heading-top`,
  headingMiddle: `${popupCustomCSSPrefix}teaser-heading-middle`,
  headingBottom: `${popupCustomCSSPrefix}teaser-heading-bottom`,
  buttonWrapper: `${popupCustomCSSPrefix}teaser-button-wrapper`,
  confirmButton: `${popupCustomCSSPrefix}teaser-confirm-button`,
  declineButton: `${popupCustomCSSPrefix}teaser-decline-button`
}

const smsWithInputFieldCustomCSSClasses = {
  countryCodeSelect: `${popupCustomCSSPrefix}sms-country-code-select`,
  smsInput: `${popupCustomCSSPrefix}sms-input`,
  smsInputError: `${popupCustomCSSPrefix}sms-input-error`
}

export const oneTimePasswordCustomCSSClasses = {
  content: `${popupCustomCSSPrefix}one-time-password-content`,
  imageWrapper: `${popupCustomCSSPrefix}one-time-password-image-wrapper`,
  image: `${popupCustomCSSPrefix}one-time-password-image`,
  secondaryImageWrapper: `${popupCustomCSSPrefix}one-time-password-secondary-image-wrapper`,
  secondaryImage: `${popupCustomCSSPrefix}one-time-password-secondary-image`,
  heading: `${popupCustomCSSPrefix}one-time-password-heading`,
  paragraph: `${popupCustomCSSPrefix}one-time-password-paragraph`,
  oneTimePasswordInput: `${popupCustomCSSPrefix}one-time-password-input`,
  oneTimePasswordInputError: `${popupCustomCSSPrefix}one-time-password-input-error`,
  cta: `${popupCustomCSSPrefix}one-time-password-cta`,
  resendDescription: `${popupCustomCSSPrefix}one-time-password-resend-description`,
  resendButton: `${popupCustomCSSPrefix}one-time-password-resend-button`
}

export const successCustomCSSClasses = {
  content: `${popupCustomCSSPrefix}success-content`,
  successImageWrapper: `${popupCustomCSSPrefix}success-image-wrapper`,
  successImage: `${popupCustomCSSPrefix}success-image`,
  successSecondaryImageWrapper: `${popupCustomCSSPrefix}success-secondary-image-wrapper`,
  successSecondaryImage: `${popupCustomCSSPrefix}success-secondary-image`,
  successHeading: `${popupCustomCSSPrefix}success-heading-top`,
  successDescription: `${popupCustomCSSPrefix}success-heading-middle`,
  successCta: `${popupCustomCSSPrefix}success-cta`
}

export interface ChannelCustomCSSClasses {
  content: string
  imageWrapper: string
  image: string
  secondaryImageWrapper: string
  secondaryImage: string
  headingTop: string
  headingMiddle: string
  headingBottom: string
  cta: string
  description: string
  emailInput?: string
  emailInputError?: string
  smsInput?: string
  smsInputError?: string
  success?: string
  countryCodeSelect?: string
  successImageWrapper?: string
  successImage?: string
  successSecondaryImageWrapper?: string
  successSecondaryImage?: string
  successHeading?: string
  successDescription?: string
  successCta?: string
  emailFormWrapper?: string
  emailFormLabel?: string
  emailFormItem?: string
  smsFormWrapper?: string
  smsFormLabel?: string
  smsFormItem?: string
}

export function getCustomCSSClassesForChannel({
  step,
  canHaveForm
}: {
  step: OptinToolStep
  canHaveForm?: boolean
}) {
  const simplifiedStep: OptinToolStep = step === 'smsWithEmail' ? 'sms' : step

  let classes: ChannelCustomCSSClasses = {
    content: `${popupCustomCSSPrefix}${simplifiedStep}-content`,
    imageWrapper: `${popupCustomCSSPrefix}${simplifiedStep}-image-wrapper`,
    image: `${popupCustomCSSPrefix}${simplifiedStep}-image`,
    secondaryImageWrapper: `${popupCustomCSSPrefix}${simplifiedStep}-secondary-image-wrapper`,
    secondaryImage: `${popupCustomCSSPrefix}${simplifiedStep}-secondary-image`,
    headingTop: `${popupCustomCSSPrefix}${simplifiedStep}-heading-top`,
    headingMiddle: `${popupCustomCSSPrefix}${simplifiedStep}-heading-middle`,
    headingBottom: `${popupCustomCSSPrefix}${simplifiedStep}-heading-bottom`,
    cta: `${popupCustomCSSPrefix}${simplifiedStep}-cta`,
    description: `${popupCustomCSSPrefix}${simplifiedStep}-description`
  }

  const isSMSStep = step === 'sms' || step === 'smsWithEmail'
  const isEmailStep = step === 'email' || step === 'smsWithEmail'

  if (isEmailStep) {
    classes.emailInput = `${popupCustomCSSPrefix}email-input`
    classes.emailInputError = `${popupCustomCSSPrefix}email-input-error`

    if (step === 'email') {
      classes.success = `${popupCustomCSSPrefix}${simplifiedStep}-success`

      if (canHaveForm) {
        classes.emailFormWrapper = `${popupCustomCSSPrefix}${simplifiedStep}-form-wrapper`
        classes.emailFormLabel = `${popupCustomCSSPrefix}${simplifiedStep}-form-label`
        classes.emailFormItem = `${popupCustomCSSPrefix}${simplifiedStep}-form-item`
      }
    }
  }

  if (isSMSStep) {
    classes.smsFormWrapper = `${popupCustomCSSPrefix}${simplifiedStep}-form-wrapper`
    classes.smsFormLabel = `${popupCustomCSSPrefix}${simplifiedStep}-form-label`
    classes.smsFormItem = `${popupCustomCSSPrefix}${simplifiedStep}-form-item`

    classes = {
      ...classes,
      ...smsWithInputFieldCustomCSSClasses
    }
  }

  return classes
}

export function getDefaultCustomCSSClasses(steps: OptinToolStep[]) {
  const canHaveForm = steps.includes('sms') || steps.includes('smsWithEmail')

  const customCSSClasses = [
    ...Object.values(generalCustomCSSClasses),
    ...Object.values(teaserCustomCSSClasses),
    ...steps.flatMap((step) => {
      if (step === 'oneTimePassword') {
        return Object.values(oneTimePasswordCustomCSSClasses)
      }

      if (step === 'success') {
        return Object.values(successCustomCSSClasses)
      }

      if (['messenger', 'email', 'sms', 'smsWithEmail'].includes(step)) {
        return Object.values(getCustomCSSClassesForChannel({ step, canHaveForm }))
      }

      return []
    })
  ]

  return customCSSClasses.map((cssClass) => `.${cssClass} {}`).join('\n')
}
