import { DelayUnit } from '@ghostmonitor/recartapis'
import moment from 'moment'

export const oneMilliInNanoSecs = 1000000
export const oneMinuteInNanosecs = oneMilliInNanoSecs * 1000 * 60
export const oneHourInNanosecs = oneMinuteInNanosecs * 60
export const oneDayInNanosecs = oneHourInNanosecs * 24

// convert minutes, hours or days to nanosecs
export function convertUnitToNanosec(value: number, unit: DelayUnit) {
  const duration = moment.duration({ [unit]: value })
  return duration.asMilliseconds() * oneMilliInNanoSecs
}

// convert nanosecs to minutes, hours or days
export function convertNanosecToUnit(value: number, unit: DelayUnit) {
  const duration = moment.duration({ milliseconds: Math.round(value / oneMilliInNanoSecs) })
  return duration.as(unit as any)
}
