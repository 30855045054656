import { SequenceGroup } from '../../types/sequence-group'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'

export function useSequenceGroupByTags(tags: string[]): UseResource<SequenceGroup> {
  const { data: sequenceGroups, isLoading } = hooks.useSequenceGroupsByTags(tags)

  return {
    data: sequenceGroups?.[0],
    isLoading
  }
}
