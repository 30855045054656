import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { FlowEditorError } from '../../../../utils/flow-editor/flow-editor-errors'
import { getDiscountCodesFromFlow } from '../../../../utils/get-discount-codes-from-flow'
import { serializeError } from '../../../../utils/serialize-error'
import { validateDiscountCode } from '../../../../utils/validators/flow/validate-discount-code'
import { flowEditorSelectors } from '../../../selectors'
import { ThunkAPI } from '../../../types/thunk-api.type'
import {
  discountCodeValidationFailed,
  discountCodeValidationSucceeded
} from '../flow-editor.actions'

export const validateDiscountCodesThunk = createAsyncThunk<unknown, void>(
  'flowEditor/validateDiscountCodes',
  async (args, thunkAPI: any) => {
    const { dispatch, getState }: ThunkAPI = thunkAPI
    const state = getState()

    const flow = flowEditorSelectors.selectEditorFlow(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    const actions: AnyAction[] = []
    let hasError = false

    const discountCodes = getDiscountCodesFromFlow(flow)
    await Promise.all(
      discountCodes.map(async (discountCodeName) => {
        const error = await validateDiscountCode(discountCodeName)
        if (error !== undefined) {
          hasError = true
          actions.push(
            discountCodeValidationFailed({
              discountCodeName,
              error
            })
          )
        } else {
          actions.push(
            discountCodeValidationSucceeded({
              discountCodeName
            })
          )
        }
      })
    )

    batch(() => {
      actions.forEach(dispatch)
    })

    if (hasError) {
      throw new Error(FlowEditorError.DiscountCodeValidationFailed)
    }
  },
  { serializeError }
)
