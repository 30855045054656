import { Form, Input } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { hooks } from '../../../../../hooks/hooks'
import { selectFacebookPageConfig } from '../../../../../store/selectors'
import styles from './comment-capture-input.component.scss'

const HELP_LINK = 'https://help.recart.com/en/articles/3967015-how-to-use-facebook-comments-feature'

function validateFacebookIdFormat(id: string): boolean {
  const isNumber = /^\d+$/
  const minPostIdLength = 10

  return id.length >= minPostIdLength && isNumber.test(id)
}

export interface CommentCaptureInputProps {
  defaultValue?: string
  onChange: (value: string) => void
  facebookPostId: string | undefined
}

export function CommentCaptureInput(props: CommentCaptureInputProps) {
  const fbPageConfig = useSelector(selectFacebookPageConfig)

  const [postId, setPostId] = useState(props.defaultValue ?? '')
  const [isValidFacebookPostIdFormat, setIsValidFacebookPostIdFormat] = useState(true)
  const [isFacebookPostIdTaken, setIsFacebookPostIdTaken] = useState(false)

  const debounceChange = debounce(throttle(setPostId, 1400), 700)

  const {
    data: facebookPost,
    isLoading,
    isError: error
  } = hooks.useFacebookPost(postId, !!postId && !!fbPageConfig.accessToken && !!fbPageConfig.pageId)

  const { data: sequences } = hooks.useSequences(
    postId ? { post_id: postId } : {},
    !!postId && isValidFacebookPostIdFormat
  )

  useEffect(() => {
    if (facebookPost && postId) {
      setIsFacebookPostIdTaken(sequences?.length > 0)
      props.onChange(postId)
    }
  }, [facebookPost, postId, sequences])

  useEffect(() => {
    if (error) {
      setIsFacebookPostIdTaken(false)
      props.onChange(undefined)
    }
  }, [error])

  useEffect(() => {
    if (isFacebookPostIdTaken) {
      props.onChange(undefined)
    }
  }, [isFacebookPostIdTaken])

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target

    if (validateFacebookIdFormat(value)) {
      setIsValidFacebookPostIdFormat(true)
      debounceChange(value)
    } else {
      setIsFacebookPostIdTaken(false)
      setIsValidFacebookPostIdFormat(false)
      setPostId(undefined)
      props.onChange(undefined)
    }
  }

  function getHelpText(): ReactNode {
    if (isLoading) {
      return ''
    }

    if (!isValidFacebookPostIdFormat) {
      return (
        <>
          This doesn't seem to be a Facebook post ID.{' '}
          <a href={HELP_LINK} target='_blank' rel='noopener noreferrer'>
            Please verify
          </a>{' '}
          and try again.
        </>
      )
    }

    if (isValidFacebookPostIdFormat && isFacebookPostIdTaken && props.facebookPostId !== postId) {
      return <>This Facebook post already has a flow attached, please select an other one.</>
    }

    if (error) {
      return (
        <>
          This post is not available with the currently selected Ad Account.{' '}
          <a href={HELP_LINK} target='_blank' rel='noopener noreferrer'>
            Please verify
          </a>{' '}
          and try again.
        </>
      )
    }

    if (facebookPost || props.defaultValue) {
      return 'You can select a different Facebook post by entering another post ID.'
    }

    return (
      <>
        You can find the Facebook post ID in your Ads Manager.{' '}
        <a href={HELP_LINK} target='_blank' rel='noopener noreferrer'>
          Learn more
        </a>
      </>
    )
  }

  function getValidateStatus(): ValidateStatus {
    if (isLoading) {
      return 'validating'
    }

    if (
      error ||
      !isValidFacebookPostIdFormat ||
      (isFacebookPostIdTaken && props.facebookPostId !== postId)
    ) {
      return 'error'
    }

    if (facebookPost || props.defaultValue) {
      return 'success'
    }

    return ''
  }

  const fullWidthLayout = {
    labelCol: {
      xs: { span: 24 }
    },
    wrapperCol: {
      xs: { span: 24 }
    }
  }

  return (
    <Form className={styles['comment-capture-form']} layout='vertical'>
      <Form.Item
        {...fullWidthLayout}
        label='Please enter the desired Facebook post ID'
        labelAlign='left'
        colon={false}
        validateStatus={getValidateStatus()}
        help={getHelpText()}
        hasFeedback
        data-testid='comment-capture-field'
      >
        <Input
          defaultValue={props.defaultValue}
          onChange={handleChange}
          size='large'
          placeholder='2283610145277322'
          data-testid='comment-capture-input'
        />
      </Form.Item>
    </Form>
  )
}
