import {
  ConditionalSplitSequenceItemUI,
  LogicConditionalSplitCondition
} from '@ghostmonitor/recartapis'
import { Action } from '@reduxjs/toolkit'
import { selectSequenceItem } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

export interface UpdateConditionAction extends Action {
  sequenceItemId: string
  condition: LogicConditionalSplitCondition
  index: number
}

export function updateConditionActionHandler(
  state: SequenceEditorState,
  action: UpdateConditionAction
) {
  const sequenceItem = selectSequenceItem<ConditionalSplitSequenceItemUI>(action.sequenceItemId)(
    state
  )
  sequenceItem.logic.conditionalSplit.variants[0].conditions.splice(
    action.index,
    1,
    action.condition
  )
}
