import { OptinToolMenuItem } from '../../../components/editor/left-panel/left-panel.component'

export enum CustomerChatMenuItemSlug {
  GENERAL = 'general',
  LAYOUT_BEHAVIOR = 'layoutBehavior'
}

export const customerChatMenuItems: OptinToolMenuItem[] = [
  { slug: CustomerChatMenuItemSlug.GENERAL, label: 'General' },
  { slug: CustomerChatMenuItemSlug.LAYOUT_BEHAVIOR, label: 'Layout & behavior' }
]
