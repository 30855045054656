export enum FlowEditorError {
  CampaignSchedulingInvalid = 'CampaignSchedulingInvalid',
  CampaignScheduledToQuietHours = 'CampaignScheduledToQuietHours',
  CannotFetchFlow = 'CannotFetchFlow',
  ConditionalSplitFlowItemMustHaveConnection = 'ConditionalSplitFlowItemMustHaveConnection',
  ConditionalSplitFlowItemV2MustHaveConnection = 'ConditionalSplitFlowItemV2MustHaveConnection',
  ConditionalSplitV2ExpressionError = 'ConditionalSplitV2ExpressionError',
  ConditionalSplitV1DeprecatedError = 'ConditionalSplitV1DeprecatedError',
  DelayFlowItemMustHaveConnection = 'DelayFlowItemMustHaveConnection',
  DelayValueExceedMaximum = 'DelayValueExceedMaximum',
  DiscountCodeError = 'DiscountCodeError',
  DiscountCodeExpired = 'DiscountCodeExpired',
  DiscountCodeNotExists = 'DiscountCodeNotExists',
  DiscountCodePoolSizeNotEnough = 'DiscountCodePoolSizeNotEnough',
  DiscountCodePoolNotEnoughAvailable = 'DiscountCodePoolNotEnoughAvailable',
  DiscountCodeValidationFailed = 'DiscountCodeValidationFailed',
  FailedToLoadSMSCampaign = 'FailedToLoadSMSCampaign',
  FailedToSaveSMSCampaign = 'FailedToSaveSMSCampaign',
  FlowHasInfiniteLoop = 'FlowHasInfiniteLoop',
  FlowItemValidationFailed = 'FlowItemValidationFailed',
  FlowValidationFailed = 'FlowValidationFailed',
  IntegrityError = 'IntegrityError',
  JoinedASegmentFlowMissingSegment = 'JoinedASegmentFlowMissingSegment',
  KeywordReplyFlowItemMustHaveConnection = 'KeywordReplyFlowItemMustHaveConnection',
  MessageHasInvalidLink = 'MessageHasInvalidLink',
  MessageHasEntryLinkInNonEntryItem = 'MessageHasEntryLinkInNonEntryItem',
  MessageHasInvalidVariable = 'MessageHasInvalidVariable',
  MessageHasInvalidReplyKeyword = 'MessageHasInvalidReplyKeyword',
  MessageHasCustomLinkWithoutValue = 'MessageHasCustomLinkWithoutValue',
  MessageContainsHTMLTag = 'MessageContainsHTMLTag',
  RandomSplitFlowItemMustHaveConnection = 'RandomSplitFlowItemMustHaveConnection',
  RandomSplitFlowItemHasDisconnectedSplits = 'RandomSplitFlowItemHasDisconnectedSplits',
  RandomSplitFlowItemMustHaveVariations = 'RandomSplitFlowItemMustHaveVariations',
  RandomSplitValueExceedMaximum = 'RandomSplitValueExceedMaximum',
  ScheduledDelayDateBeforeCampaignStartingDate = 'ScheduledDelayDateBeforeCampaignStartingDate',
  ScheduledDelayDateBeforePrecedingDelayScheduledDate = 'ScheduledDelayDateBeforePrecedingDelayScheduledDate',
  ScheduledDelayDateIsRequired = 'ScheduledDelayDateIsRequired',
  ScheduledDelayDateIsInThePast = 'ScheduledDelayDateIsInThePast',
  ScheduledDelayDateIsUndefined = 'ScheduledDelayDateIsUndefined',
  SMSCampaignValidationFailed = 'SMSCampaignValidationFailed',
  SMSMessageEmpty = 'SMSMessageEmpty',
  TriggerMustHaveConnection = 'TriggerMustHaveConnection',
  RenameFlowFailed = 'RenameFlowFailed',

  // Anomalies
  NodeNotFound = 'NodeNotFound',
  FlowItemIdNotFoundOnNode = 'FlowItemIdNotFoundOnNode',
  FlowIsNotLoaded = 'FlowIsNotLoaded',
  FlowItemIsNotLoaded = 'FlowItemIsNotLoaded',
  FlowMetaAnomaly = 'FlowMetaAnomaly',
  SMSCampaignIsNotLoaded = 'SMSCampaignIsNotLoaded',
  SMSCampaignMetaIsNotLoaded = 'SMSCampaignMetaIsNotLoaded',
  TriggerNodeNotFound = 'TriggerNodeNotFound',
  EditingMessageIndexNotFound = 'EditingMessageIndexNotFound',
  ConnectionDoesntHaveSource = 'ConnectionDoesntHaveSource',
  FlowItemIsNotConditionalSplitV2 = 'FlowItemIsNotConditionalSplitV2',
  CannotDetermineCanConvertValue = 'CannotDetermineCanConvertValue',

  // API Thunk
  ChangeTriggerFailed = 'ChangeTriggerFailed',
  GetSMSCampaignByFlowIdFailed = 'GetSMSCampaignByFlowIdFailed',
  LoadSMSCampaignFailed = 'LoadSMSCampaignFailed',
  PatchSMSCampaignFailed = 'PatchSMSCampaignFailed',
  RemoveSMSCampaignFailed = 'RemoveSMSCampaignFailed',
  SaveFlowFailed = 'SaveFlowFailed',
  SaveSMSCampaignFailed = 'SaveSMSCampaignFailed',
  ToggleFlowFailed = 'ToggleFlowFailed',

  // User actions
  FailedToEditSMSCampaign = 'FailedToEditSMSCampaign',

  // CustomIntegrations
  CustomIntegrationMissingCategory = 'CustomIntegrationMissingCategory',
  CustomIntegrationMissingEvent = 'CustomIntegrationMissingEvent'
}

export const smsCampaignValidationErrors = [
  FlowEditorError.CampaignSchedulingInvalid,
  FlowEditorError.CampaignScheduledToQuietHours,
  FlowEditorError.DiscountCodePoolSizeNotEnough,
  FlowEditorError.DiscountCodePoolNotEnoughAvailable
]

export const flowValidationErrors = [
  FlowEditorError.FlowHasInfiniteLoop,
  FlowEditorError.JoinedASegmentFlowMissingSegment,
  FlowEditorError.TriggerMustHaveConnection,
  FlowEditorError.CustomIntegrationMissingCategory,
  FlowEditorError.CustomIntegrationMissingEvent
]

export const flowItemValidationErrors = [
  FlowEditorError.ConditionalSplitFlowItemMustHaveConnection,
  FlowEditorError.ConditionalSplitFlowItemV2MustHaveConnection,
  FlowEditorError.ConditionalSplitV1DeprecatedError,
  FlowEditorError.ConditionalSplitV2ExpressionError,
  FlowEditorError.DelayFlowItemMustHaveConnection,
  FlowEditorError.DelayValueExceedMaximum,
  FlowEditorError.KeywordReplyFlowItemMustHaveConnection,
  FlowEditorError.RandomSplitFlowItemHasDisconnectedSplits,
  FlowEditorError.RandomSplitFlowItemMustHaveConnection,
  FlowEditorError.RandomSplitFlowItemMustHaveVariations,
  FlowEditorError.RandomSplitValueExceedMaximum,
  FlowEditorError.ScheduledDelayDateBeforeCampaignStartingDate,
  FlowEditorError.ScheduledDelayDateBeforePrecedingDelayScheduledDate,
  FlowEditorError.ScheduledDelayDateIsInThePast
]

export const messageValidationErrors = [
  FlowEditorError.SMSMessageEmpty,
  FlowEditorError.MessageHasInvalidLink,
  FlowEditorError.MessageHasEntryLinkInNonEntryItem,
  FlowEditorError.MessageHasInvalidVariable,
  FlowEditorError.MessageContainsHTMLTag,
  FlowEditorError.MessageHasCustomLinkWithoutValue
]

export const discountCodeValidationErrors = [
  FlowEditorError.DiscountCodeNotExists,
  FlowEditorError.DiscountCodeExpired,
  FlowEditorError.DiscountCodeError
]

export const errorTypesToDisplayWithErrorMessageOnNode = [
  FlowEditorError.ConditionalSplitFlowItemMustHaveConnection,
  FlowEditorError.ConditionalSplitFlowItemV2MustHaveConnection,
  FlowEditorError.DelayFlowItemMustHaveConnection,
  FlowEditorError.KeywordReplyFlowItemMustHaveConnection,
  FlowEditorError.RandomSplitFlowItemHasDisconnectedSplits,
  FlowEditorError.RandomSplitFlowItemMustHaveConnection,
  FlowEditorError.TriggerMustHaveConnection
]

export const errorNameToMessage: Record<FlowEditorError, string> = {
  [FlowEditorError.CampaignSchedulingInvalid]: 'You cannot select date in the past',
  [FlowEditorError.CampaignScheduledToQuietHours]:
    'The scheduled time is during quiet hours; messages will be sent afterward.',
  [FlowEditorError.ConditionalSplitFlowItemMustHaveConnection]:
    'Connect at least one connection to a flow item',
  [FlowEditorError.ConditionalSplitFlowItemV2MustHaveConnection]:
    'Connect all of the conditions or the ELSE to a flow item',
  [FlowEditorError.ConditionalSplitV2ExpressionError]: 'Please resolve conditional split error',
  [FlowEditorError.ConditionalSplitV1DeprecatedError]: 'Conditional split version is deprecated.',
  [FlowEditorError.DelayFlowItemMustHaveConnection]: 'Connect to a flow item',
  [FlowEditorError.DelayValueExceedMaximum]: 'Delay cannot be more than 365 days',
  [FlowEditorError.DiscountCodeError]: 'There is a problem with your discount code',
  [FlowEditorError.DiscountCodeExpired]: 'Discount code expired',
  [FlowEditorError.DiscountCodeNotExists]: 'Discount code not exists',
  [FlowEditorError.FlowHasInfiniteLoop]: 'Remove connection causing an endless flow',
  [FlowEditorError.JoinedASegmentFlowMissingSegment]:
    'Segment must be added to Custom Triggers flow',
  [FlowEditorError.KeywordReplyFlowItemMustHaveConnection]:
    'Connect all of the keywords to a flow item',
  [FlowEditorError.MessageHasInvalidLink]: 'Message has invalid link',
  [FlowEditorError.MessageHasEntryLinkInNonEntryItem]:
    'Message has a link that should be in first message',
  [FlowEditorError.MessageHasInvalidVariable]: 'Message has invalid variable',
  [FlowEditorError.MessageHasInvalidReplyKeyword]: 'Message has invalid keyword reply',
  [FlowEditorError.MessageHasCustomLinkWithoutValue]:
    'Message has custom link without url value provided',
  [FlowEditorError.MessageContainsHTMLTag]: 'Message contains HTML tag',
  [FlowEditorError.RandomSplitFlowItemMustHaveConnection]: 'Connect more splits to a flow item',
  [FlowEditorError.RandomSplitFlowItemHasDisconnectedSplits]: 'Contains disconnected splits',
  [FlowEditorError.RandomSplitFlowItemMustHaveVariations]: 'Add at least one split',
  [FlowEditorError.RandomSplitValueExceedMaximum]: 'The sum of the splits must be equal to 100%',
  [FlowEditorError.SMSMessageEmpty]: 'Message cannot be empty',
  [FlowEditorError.TriggerMustHaveConnection]: 'Connect trigger to a flow item',
  [FlowEditorError.DiscountCodePoolSizeNotEnough]: 'Insufficient Discount Code Pool Size',
  [FlowEditorError.DiscountCodePoolNotEnoughAvailable]: 'Not Enough Available Discount Codes',
  [FlowEditorError.FlowItemValidationFailed]: 'Flow Item Validation Unsuccessful',
  [FlowEditorError.FlowValidationFailed]: 'Flow Validation Unsuccessful',
  [FlowEditorError.SMSCampaignValidationFailed]: 'SMS Campaign Validation Unsuccessful',
  [FlowEditorError.DiscountCodeValidationFailed]: 'Discount Code Validation Unsuccessful',
  [FlowEditorError.SMSCampaignIsNotLoaded]: 'SMS Campaign Not Loaded',
  [FlowEditorError.SMSCampaignMetaIsNotLoaded]: 'SMS Campaign Metadata Not Loaded',
  [FlowEditorError.FlowIsNotLoaded]: 'Flow Not Loaded',
  [FlowEditorError.FlowItemIsNotLoaded]: 'Flow Item Not Loaded',
  [FlowEditorError.FlowMetaAnomaly]: 'Flow Metadata Anomaly Detected',
  [FlowEditorError.NodeNotFound]: 'Node Missing',
  [FlowEditorError.FlowItemIdNotFoundOnNode]: 'Flow Item ID Missing on Node',
  [FlowEditorError.TriggerNodeNotFound]: 'Trigger Node Missing',
  [FlowEditorError.EditingMessageIndexNotFound]: 'Editing Message Index Missing',
  [FlowEditorError.ConnectionDoesntHaveSource]: 'Connection Missing Source',
  [FlowEditorError.FlowItemIsNotConditionalSplitV2]:
    'Flow Item Is Not a Conditional Split Version 2',
  [FlowEditorError.CannotDetermineCanConvertValue]: 'Unable to Determine Convertible Value',
  [FlowEditorError.IntegrityError]: 'Data Integrity Issue',
  [FlowEditorError.ChangeTriggerFailed]: 'Failed to Change Trigger',
  [FlowEditorError.GetSMSCampaignByFlowIdFailed]: 'Failed to Retrieve SMS Campaign by Flow ID',
  [FlowEditorError.LoadSMSCampaignFailed]: 'Failed to Load SMS Campaign',
  [FlowEditorError.PatchSMSCampaignFailed]: 'Failed to Update SMS Campaign',
  [FlowEditorError.RemoveSMSCampaignFailed]: 'Failed to Remove SMS Campaign',
  [FlowEditorError.SaveFlowFailed]: 'Failed to Save Flow',
  [FlowEditorError.SaveSMSCampaignFailed]: 'Failed to Save SMS Campaign',
  [FlowEditorError.ToggleFlowFailed]: 'Failed to Toggle Flow',
  [FlowEditorError.FailedToEditSMSCampaign]: 'Failed to Edit SMS Campaign',
  [FlowEditorError.FailedToSaveSMSCampaign]: 'Failed to Save SMS Campaign',
  [FlowEditorError.FailedToLoadSMSCampaign]: 'Failed to Load SMS Campaign',
  [FlowEditorError.CannotFetchFlow]: 'Failed to fetch Flow',
  [FlowEditorError.RenameFlowFailed]: 'Failed to rename Flow',
  [FlowEditorError.ScheduledDelayDateBeforeCampaignStartingDate]:
    'Scheduled delay must be later than the campaign starting date',
  [FlowEditorError.ScheduledDelayDateBeforePrecedingDelayScheduledDate]:
    'This scheduled delay conflicts with other delay item',
  [FlowEditorError.ScheduledDelayDateIsRequired]: 'Delay date is required',
  [FlowEditorError.ScheduledDelayDateIsInThePast]: 'Scheduled delay must be in the future',
  [FlowEditorError.ScheduledDelayDateIsUndefined]: 'Scheduled delay must be selected',
  [FlowEditorError.CustomIntegrationMissingCategory]:
    'Custom Integration category must be selected',
  [FlowEditorError.CustomIntegrationMissingEvent]: 'Custom Integration event must be selected'
}

export const validationErrors = [
  FlowEditorError.FlowValidationFailed,
  FlowEditorError.FlowItemValidationFailed,
  FlowEditorError.SMSCampaignValidationFailed,
  FlowEditorError.DiscountCodeValidationFailed,
  ...smsCampaignValidationErrors,
  ...flowValidationErrors,
  ...flowItemValidationErrors,
  ...messageValidationErrors,
  ...discountCodeValidationErrors
]

export enum FlowEditorErrorLevel {
  Error = 'error',
  Warning = 'warning'
}

export interface FlowEditorValidationError {
  message: FlowEditorError | string
  level: FlowEditorErrorLevel
}
