import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { hooks } from '../../../hooks/hooks'
import type { AppDispatch } from '../../../store/create-store'
import { selectEditorSequence, selectErrorCount } from '../../../store/selectors'
import { saveSequenceThunk } from '../../../store/slices/sequence-editor/thunks/save-sequence.thunk'
import { Notification } from '../../../utils/notification/notification.util'
import { runThunks } from '../settings/run-thunks'
import { SequenceEditorErrorMessage } from '../types/sequence-editor-errors'
import { useSequenceEditorSettings } from './use-sequence-editor-settings'

export function useSaveSequenceEditor(): { saveSequenceEditor: () => Promise<void> } {
  const dispatch: AppDispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const errorCount = useSelector(selectErrorCount)
  const sequence = useSelector(selectEditorSequence)
  const editorSettings = useSequenceEditorSettings()

  const { invalidateCache: invalidateSequencesCache } = hooks.useSequencesCache()
  const { invalidateCache: invalidateSequenceItemsStatCache } =
    hooks.useEditorSequenceItemsStatCache()

  async function saveSequenceEditor(): Promise<void> {
    if (errorCount !== 0) {
      return
    }

    const saveThunksResult = await dispatch(runThunks(editorSettings.saveThunks))

    if (isFulfilled(saveThunksResult)) {
      const searchParams = new URLSearchParams(location.search)
      const backTo = searchParams.get('backTo')
      if (backTo) {
        const locationFrom = {
          fromSlug: location?.pathname,
          id: sequence._id
        }
        navigate(backTo, { state: locationFrom })
      }

      invalidateSequencesCache()
      invalidateSequenceItemsStatCache()
    }

    if (isRejected(saveThunksResult)) {
      if (saveSequenceThunk.rejected.match(saveThunksResult)) {
        Notification.error(SequenceEditorErrorMessage.SAVE_ERROR)
      }
      throw new Error(SequenceEditorErrorMessage.SAVE_ERROR)
    }
  }

  return {
    saveSequenceEditor
  }
}
