import { DiscountCode, DiscountCodePool, UniqueDiscountCode } from '@ghostmonitor/recartapis'
import { Form } from 'antd'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { EditorButton } from '../../../../../../components/ui-kit/editor-button/editor-button.component'
import { Input } from '../../../../../../components/ui-kit/input/input.component'
import { Option, Select } from '../../../../../../components/ui-kit/select/select.component'
import { hooks } from '../../../../../../hooks/hooks'
import { webURLRegexp } from '../../../../../../utils/regexps/web-url-regexp'
import styles from './edit-unique-discount-code.scss'

interface EditUniqueDiscountCodeProps {
  discountCodePoolId: string
  url?: string
  footer: React.FC<{ discountCodeExists: boolean }>
  onAdd: (discountCode: DiscountCode, url?: string) => void
  onUpdate: (discountCode: DiscountCode, url: string) => void
  onCreatePoolOnDashboard: () => void
  isEditing: boolean
}

const fullWidthLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  }
}

const createPoolValue = 'create-new-pool-on-dashboard'

export function EditUniqueDiscountCode(props: EditUniqueDiscountCodeProps) {
  const [pool, setPool] = useState<DiscountCodePool>()
  const { data: me } = hooks.useMe()
  const { data: discountCodePools, isLoading: isDiscountCodeLoading } = hooks.useDiscountCodePools()
  const siteDomain = me?.site.domain
  const defaultUrl = props.url || siteDomain
  const [url, setUrl] = useState(defaultUrl)

  const { discountCodePoolId } = props
  useEffect(() => {
    if (discountCodePools !== undefined && discountCodePoolId !== undefined) {
      const discountCodePool = discountCodePools.find((pool) => pool.id === discountCodePoolId)
      setPool(discountCodePool)
    }
  }, [discountCodePools, discountCodePoolId])

  function clearForm() {
    setPool(undefined)
    setUrl(defaultUrl)
  }

  function getEditedDiscountCode(): UniqueDiscountCode {
    return {
      siteId: '',
      discountCode: pool.name,
      codeType: 'UNIQUE',
      poolId: pool.id,
      isSent: true
    } as UniqueDiscountCode
  }

  async function handleAdd() {
    const discountCode = getEditedDiscountCode()
    props.onAdd(discountCode, url)
    clearForm()
  }

  function handleUpdate() {
    const discountCode = getEditedDiscountCode()
    props.onUpdate(discountCode, url)
  }

  function handlePoolChange(value: string) {
    if (value === createPoolValue) {
      setPool(undefined)
      props.onCreatePoolOnDashboard()
    } else {
      const discountCodePool = discountCodePools.find((pool) => pool.id === value)
      setPool(discountCodePool)
    }
  }

  function handleDiscountUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUrl(event.target.value)
  }

  function isCreateDiscountCodeButtonDisabled(): boolean {
    if (props.isEditing && !webURLRegexp.test(url)) {
      return true
    }
    return pool === undefined
  }

  return (
    <div className={styles.container}>
      <h2 className='px-0 pt-2 pb-1 text-sm font-bold'>Add discount code pool</h2>
      {pool ? (
        <p className={cn(styles.text, 'text-tiny mb-2')}>
          Make sure you have enough discount codes in your pool to cover your target segment before
          sending the message.
        </p>
      ) : (
        <p className={cn(styles.text, 'text-tiny mb-2')}>
          Before selecting a pool, make sure you have created one on the Discounts page and it has
          enough codes to cover your target segment.
        </p>
      )}
      <Form layout='vertical'>
        <Form.Item
          {...fullWidthLayout}
          label='Discount code pool'
          labelAlign='left'
          colon={false}
          data-testid='discount-type-input'
          className={styles.formItem}
        >
          <Select
            disabled={props.isEditing}
            loading={isDiscountCodeLoading}
            onChange={handlePoolChange}
            defaultValue={props.isEditing ? props.discountCodePoolId : undefined}
            placeholder='Select a pool'
            size='middle'
          >
            {discountCodePools?.map((value) => {
              return (
                <Option key={value.id} value={value.id}>
                  {value.name}
                </Option>
              )
            })}
            <Option key='create-new-pool' value={createPoolValue}>
              + Create new on Dashboard
            </Option>
          </Select>
        </Form.Item>
        {pool && (
          <Form.Item
            {...fullWidthLayout}
            label='Destination URL'
            labelAlign='left'
            colon={false}
            data-testid='unique-discount-code-url'
            className={styles.formItem}
            validateStatus={url && !webURLRegexp.test(url) ? 'error' : 'success'}
            help={url && !webURLRegexp.test(url) ? 'Invalid URL format.' : null}
            tooltip="Enter the link your subscribers should be directed to. Your store's home page is added by default. Your discount code is embedded in this link."
          >
            <Input
              defaultValue={props.url || siteDomain}
              onChange={handleDiscountUrlChange}
              data-testid='unique-discount-code-url-input'
              size='middle'
            />
          </Form.Item>
        )}
        <Form.Item className={styles.formButtonWrapper}>
          {props.isEditing ? (
            <>
              <EditorButton
                type='primary'
                className='w-full'
                onClick={handleUpdate}
                disabled={isCreateDiscountCodeButtonDisabled()}
                loading={isDiscountCodeLoading}
              >
                Update discount code pool
              </EditorButton>
              <props.footer discountCodeExists />
            </>
          ) : (
            <>
              <EditorButton
                type='primary'
                className='w-full'
                onClick={handleAdd}
                disabled={isCreateDiscountCodeButtonDisabled()}
                data-testid='add-discount-code-pool'
              >
                Add discount code pool
              </EditorButton>
              <props.footer discountCodeExists />
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  )
}
