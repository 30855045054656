import { SubscriberOverview, SubscriberOverviewResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import type { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscribersOverview(): UseResource<SubscriberOverview> {
  const queryName = [QUERY_NAME.subscribersOverview]

  const { isLoading, data } = useQuery(queryName, async () => {
    return request.get<SubscriberOverviewResponse>('subscribers-overview')
  })

  return { isLoading, data: data?.data }
}
