import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '../../../../utils/request'
import { AppDispatch } from '../../../create-store'
import { DashboardState } from '../../../dashboard.state'
import { selectEditorSequence } from '../../../selectors'

export const toggleSequenceThunk = createAsyncThunk<
  boolean,
  boolean,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/toggleSequence', async (isEnabled: boolean, store) => {
  const state = store.getState()
  const sequence = selectEditorSequence(state)

  return await request.put(`sequences/${sequence._id}/toggle`, {
    isEnabled
  })
})
