import { MessengerHeader } from '../../components/header/messenger-header.component'
import { messengerVariableTypeOptions } from '../../components/inline-editor/components/make-variable-input/variable-type-options'
import { SaveModal } from '../../components/save-modal/save-modal.component'
import { SequenceItemStatsBar } from '../../components/sequence-item-stats-bar/sequence-item-stats-bar.component'
import { SettingsDrawer } from '../../components/settings-drawer/settings-drawer.component'
import { MessengerTray } from '../../components/tray/messenger-tray/messenger-tray.component'
import { sequenceEditorGlobalSettings } from '../../config'
import { initialCanvasToolbarItems } from './defaults/canvas-toolbar-items'
import { initialConditionalSplitConditions } from './defaults/conditional-split-conditions'
import { initialLeaveEditorModal } from './defaults/leave-editor-modal'
import { initialSaveThunks, initialToggleThunks } from './defaults/sequence-thunks'
import { SequenceEditorSettingsContextType } from './sequence-editor-settings.context.type'

export const initialSequenceEditorSettingsContext: SequenceEditorSettingsContextType = {
  dragAndDrop: true,
  hideMessengerSendTestButton: false,
  isFacebookComment: false,
  variableTypeOptions: messengerVariableTypeOptions,
  leaveEditorModal: initialLeaveEditorModal,
  saveModalComponent: SaveModal,
  sequenceItemStatsBarComponent: SequenceItemStatsBar,
  entrySequenceItemStatsBarComponent: SequenceItemStatsBar,
  tray: MessengerTray,
  header: MessengerHeader,
  drawer: SettingsDrawer,
  isLegalMessageNeeded: false,
  isEnabledLocked: false,
  saveThunks: initialSaveThunks,
  toggleThunks: initialToggleThunks,
  maxDelay: 23,
  maxDelayNotificationText:
    'Due to Facebook policy changes, setting ‘Delay’ block over 23 hours is not possible anymore. Please change it accordingly.',
  hasDaysDelay: false,
  canvasToolbarItems: initialCanvasToolbarItems,
  isConditionalSplitEnabled: true,
  conditionalSplitConditions: initialConditionalSplitConditions,
  showSaveDraftButton: true
}

Object.assign(sequenceEditorGlobalSettings, initialSequenceEditorSettingsContext)
