import { LogicSplitVariantAPI, RandomSplitFlowItemUI } from '@ghostmonitor/recartapis'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  FlowEditorValidationError
} from '../../flow-editor/flow-editor-errors'

export function validateRandomSplit(
  variants: LogicSplitVariantAPI[]
): FlowEditorValidationError | undefined {
  const percentageSum = variants.reduce((prev, curr) => prev + curr.percentage, 0)

  if (variants.length && Math.round(percentageSum) !== 100) {
    return {
      message: FlowEditorError.RandomSplitValueExceedMaximum,
      level: FlowEditorErrorLevel.Error
    }
  }

  if (variants.length === 0) {
    return {
      message: FlowEditorError.RandomSplitFlowItemMustHaveVariations,
      level: FlowEditorErrorLevel.Error
    }
  }

  return undefined
}

export function validateRandomSplitFlowItemMustHaveConnection(
  flowItem: RandomSplitFlowItemUI,
  shouldBypassWarning?: boolean
): FlowEditorValidationError | undefined {
  const connectedVariants = flowItem.item.logic.split.variants.filter(
    (variant) => variant.trigger !== null
  )

  switch (flowItem.item.logic.split.variants.length - connectedVariants.length) {
    case 0:
      return undefined
    case 1:
      if (shouldBypassWarning) {
        return undefined
      }

      return {
        message: FlowEditorError.RandomSplitFlowItemHasDisconnectedSplits,
        level: FlowEditorErrorLevel.Warning
      }
    default:
      return {
        message: FlowEditorError.RandomSplitFlowItemMustHaveConnection,
        level: FlowEditorErrorLevel.Error
      }
  }
}
