import cn from 'classnames'
import React from 'react'
import { SequenceEditorHeaderProps } from '../../types/sequence-editor-header-props'
import { HeaderBack } from './components/header-back.component'
import { HeaderCopyButtons } from './components/header-copy-buttons.component'
import { HeaderInternalDuplicateButton } from './components/header-internal-duplicate-button.component'
import { HeaderSaveToggle } from './components/header-save-toggle.component'
import { HeaderTimeFrameFilter } from './components/header-time-frame-filter.component'
import { LastUpdated } from './components/last-updated.component'
import { NotificationBar } from './components/notification-bar.component'
import styles from './header.component.scss'

export function MessengerHeader(props: SequenceEditorHeaderProps) {
  return (
    <div className={styles.container} ref={props.headerRef}>
      <div className={cn('flex justify-between', styles.headerContainer)}>
        <div className='flex items-center'>
          <HeaderBack />
          <LastUpdated />
          <HeaderCopyButtons />
          <HeaderInternalDuplicateButton />
        </div>
        <div className='flex space-x-3 items-center'>
          <HeaderTimeFrameFilter />
          <HeaderSaveToggle />
        </div>
      </div>
      <NotificationBar />
    </div>
  )
}
