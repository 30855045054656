import {
  AttachmentPayload,
  AttachmentType,
  isSMSMessageMMSUI,
  isSMSMessageTextUI,
  MessengerButtonTemplatePayload,
  MessengerGenericTemplatePayload,
  MessengerMediaTemplatePayload,
  MessengerMessageUI,
  MessengerReceiptTemplatePayload,
  MessengerReviewTemplatePayload,
  MessengerTemplateType
} from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import { serializedError } from '../../../utils/serialized-error'
import { MMS_MESSAGE_MAX_LENGTH, SMS_MESSAGE_MAX_LENGTH } from '../config'
import { SequenceEditorError } from '../types/sequence-editor-errors'

function messengerButtonTemplatePayloadValidator(
  messengerTemplatePayload: MessengerButtonTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!messengerTemplatePayload) {
    return []
  }

  if (messengerTemplatePayload.text === '') {
    errors.push(serializedError(SequenceEditorError.MessageEmptyMessage))
  }

  return errors
}

function messengerGenericTemplatePayloadValidator(
  messengerTemplatePayload: MessengerGenericTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!messengerTemplatePayload) {
    return []
  }

  if (messengerTemplatePayload.title === '') {
    errors.push(serializedError(SequenceEditorError.MessageEmptyTitle))
  }

  if (messengerTemplatePayload.subtitle === '') {
    errors.push(serializedError(SequenceEditorError.MessageEmptySubtitle))
  }

  if (messengerTemplatePayload.image_url === '') {
    errors.push(serializedError(SequenceEditorError.MessageInvalidImageAttachment))
  }

  return errors
}

function messengerMediaTemplatePayloadValidator(
  messengerTemplatePayload: MessengerMediaTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!messengerTemplatePayload) {
    return []
  }

  if (messengerTemplatePayload.url === '') {
    if (messengerTemplatePayload.media_type === 'video') {
      errors.push(serializedError(SequenceEditorError.MessageInvalidVideoAttachment))
    } else if (messengerTemplatePayload.media_type === 'image') {
      errors.push(serializedError(SequenceEditorError.MessageInvalidImageAttachment))
    }
  }

  return errors
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function messengerReceiptTemplatePayloadValidator(
  messengerTemplatePayload: MessengerReceiptTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []
  return errors
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function messengerReviewTemplatePayloadValidator(
  messengerTemplatePayload: MessengerReviewTemplatePayload
): SerializedError[] {
  const errors: SerializedError[] = []
  return errors
}

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
function messengerListTemplatePayloadValidator(messengerTemplatePayload: any): SerializedError[] {
  const errors: SerializedError[] = []
  return errors
}

function messengerAudioAttachmentValidator(
  attachmentPayload: AttachmentPayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!attachmentPayload) {
    return []
  }

  if (attachmentPayload.url === '') {
    errors.push(serializedError(SequenceEditorError.MessageInvalidAudioAttachment))
  }

  return errors
}

function messengerFileAttachmentValidator(attachmentPayload: AttachmentPayload): SerializedError[] {
  const errors: SerializedError[] = []
  if (!attachmentPayload) {
    return []
  }

  if (attachmentPayload.url === '') {
    errors.push(serializedError(SequenceEditorError.MessageInvalidFileAttachment))
  }

  return errors
}

function messengerVideoAttachmentValidator(
  attachmentPayload: AttachmentPayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!attachmentPayload) {
    return []
  }

  if (attachmentPayload.url === '') {
    errors.push(serializedError(SequenceEditorError.MessageInvalidVideoAttachment))
  }

  return errors
}

function messengerImageAttachmentValidator(
  attachmentPayload: AttachmentPayload
): SerializedError[] {
  const errors: SerializedError[] = []

  if (!attachmentPayload) {
    return []
  }

  if (attachmentPayload.url === '') {
    errors.push(serializedError(SequenceEditorError.MessageInvalidImageAttachment))
  }

  return errors
}

export function messageValidator(
  message: MessengerMessageUI,
  characterCount?: number
): SerializedError[] {
  const errors: SerializedError[] = []

  if (isSMSMessageMMSUI(message)) {
    if (message.attachments.length === 0) {
      const error: SerializedError = serializedError(
        SequenceEditorError.MessageEmptyMediaAttachment
      )
      errors.push(error)
    }

    if (characterCount > MMS_MESSAGE_MAX_LENGTH) {
      errors.push(serializedError(SequenceEditorError.MessageExceedingMaxCharCount))
    }
  } else if (isSMSMessageTextUI(message)) {
    if (message.text.length === 0) {
      const error: SerializedError = serializedError(SequenceEditorError.MessageEmptyMessage)
      errors.push(error)
    }
    if (characterCount > SMS_MESSAGE_MAX_LENGTH) {
      errors.push(serializedError(SequenceEditorError.MessageExceedingMaxCharCount))
    }
  }

  if (message.messengerTemplateType) {
    const messengerTemplatePayloadValidator =
      {
        [MessengerTemplateType.BUTTON]: messengerButtonTemplatePayloadValidator,
        [MessengerTemplateType.GENERIC]: messengerGenericTemplatePayloadValidator,
        [MessengerTemplateType.MEDIA]: messengerMediaTemplatePayloadValidator,
        [MessengerTemplateType.RECEIPT]: messengerReceiptTemplatePayloadValidator,
        [MessengerTemplateType.REVIEW]: messengerReviewTemplatePayloadValidator,
        [MessengerTemplateType.LIST]: messengerListTemplatePayloadValidator
      }[message.messengerTemplateType] || (() => [])

    errors.push(...messengerTemplatePayloadValidator(message.messengerTemplatePayload as any))
  } else if (message.attachmentType) {
    const messengerAttachmentPayloadValidator =
      {
        [AttachmentType.audio]: messengerAudioAttachmentValidator,
        [AttachmentType.file]: messengerFileAttachmentValidator,
        // TODO: remove image and video once templates are migrated to template type
        [AttachmentType.image]: messengerImageAttachmentValidator,
        [AttachmentType.video]: messengerVideoAttachmentValidator
      }[message.attachmentType] || (() => [])

    errors.push(...messengerAttachmentPayloadValidator(message.attachmentPayload as any))
  }

  return errors
}
