import { GetApiKeyResponse } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { QUERY_NAME } from './query-names'

export function useApiKey(id: string) {
  const queryName = [QUERY_NAME.apiKeys, id]
  const { data, isInitialLoading, error } = useQuery<GetApiKeyResponse>(
    queryName,
    () => request.get<GetApiKeyResponse>(`apikeys/${id}`),
    { enabled: !!id }
  )

  return {
    data: data?.data.key,
    isLoading: isInitialLoading,
    error
  }
}
