// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j50SQn9ng5ziKLAYFHKw{color:#627283;display:inline-block;flex-grow:1}.j50SQn9ng5ziKLAYFHKw:not(:last-child){border-right:1px solid #e7e7e7}.Bb9cbE1EwzznnAWXRLKt{font-size:12px;font-weight:700;text-align:center}.Bb9cbE1EwzznnAWXRLKt:hover .qDJpr6ORVApBnpHv5Mv7{visibility:visible}.igsQbkr4FWettXx7KBev{font-size:11px;font-weight:400;text-align:center}.qDJpr6ORVApBnpHv5Mv7{background-color:#fff;border:1px solid #c8c8c8;border-radius:7px;color:#476dff;font-size:11px;font-weight:400;left:5px;padding:5px 0;position:absolute;text-align:center;top:50px;visibility:hidden;width:180px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/stat/stat.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAIE,sBAEE,aCoHc,CDrHd,oBAAA,CAEA,WAAA,CAEA,uCACE,8BAAA,CAIJ,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAEA,kDACE,kBAAA,CAIJ,sBACE,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,sBAGE,qBCWuB,CDFvB,wBAAA,CACA,iBAAA,CATA,aC/BY,CDgCZ,cAAA,CACA,eAAA,CAIA,QAAA,CAFA,aAAA,CACA,iBAAA,CAFA,iBAAA,CAIA,QAAA,CAVA,iBAAA,CACA,WAWA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `j50SQn9ng5ziKLAYFHKw`,
	"value": `Bb9cbE1EwzznnAWXRLKt`,
	"tooltip": `qDJpr6ORVApBnpHv5Mv7`,
	"label": `igsQbkr4FWettXx7KBev`
};
export default ___CSS_LOADER_EXPORT___;
