import { FacebookAdStatus, Stat } from '@ghostmonitor/recartapis'
import { UseResource } from '../../../hooks/types/use-resource.type'
import { useFlowEditorFlowItemsStat } from './use-flow-editor-flow-items-stat.hook'

export const flowItemInitialStat: Stat = {
  clicked: 0,
  clickRate: 0,
  converted: 0,
  cost: null,
  facebook: {
    ctr: 0,
    effectiveStatus: FacebookAdStatus.UNKNOWN,
    endDate: '',
    reach: 0,
    roas: 0,
    salesTotal: {
      USD: 0
    },
    spend: {
      USD: 0
    },
    startDate: ''
  },
  opened: 0,
  openRate: 0,
  optOutRate: {
    sms: 0
  },
  spamRate: {
    sms: 0
  },
  roi: 0,
  sales: {
    totalUSD: 0
  },
  salesTotal: {
    USD: 0
  },
  sent: 0,
  sentToPSID: 0,
  sentToUserRef: 0
}

export function useFlowEditorFlowItemStat(flowItemId: string): UseResource<Stat> {
  const { data: flowItemsStat, isLoading, isError } = useFlowEditorFlowItemsStat()

  return {
    data: flowItemsStat?.[flowItemId] ?? { ...flowItemInitialStat },
    isLoading,
    isError
  }
}
