// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LAGtpyfyXsJtr4vuUUNp{border-radius:22px;min-height:110px;overflow:hidden;position:relative;width:100%}.zJUSIXVPWeK7zKWMrS7g{color:#262626;font-family:Lato,sans-serif;font-size:14px;line-height:24px;margin:var(--recart-spacing-2) 0 var(--recart-spacing-1);text-align:center}.KM_gmtCqxKIpq8IbYCh_{color:#8c8c8c;font-family:Lato,sans-serif;font-size:12px;line-height:14px;margin-bottom:var(--recart-spacing-1);text-align:center}.eBk_a8j8kTBp1l86xCjX{background:hsla(0,0%,100%,.85);inset:0;position:absolute;transition:background .25s ease-in-out}.OUYd5wu8F3p62SI0DUfx{background:hsla(0,0%,100%,0);inset:0;position:absolute;transition:background .25s ease-in-out}.XsRK08NJb77IXSVJCwuw{align-items:center;display:flex;inset:0;justify-content:center;position:absolute}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/image-upload/image-upload.component.scss"],"names":[],"mappings":"AACE,sBAIE,kBAAA,CACA,gBAAA,CAFA,eAAA,CAFA,iBAAA,CACA,UAGA,CAGF,sBAIE,aAAA,CAHA,2BAAA,CACA,cAAA,CACA,gBAAA,CAEA,wDAAA,CACA,iBAAA,CAGF,sBAIE,aAAA,CAHA,2BAAA,CACA,cAAA,CACA,gBAAA,CAGA,qCAAA,CADA,iBACA,CAGF,sBAGE,8BAAA,CADA,OAAA,CADA,iBAAA,CAGA,sCAAA,CAGF,sBAGE,4BAAA,CADA,OAAA,CADA,iBAAA,CAGA,sCAAA,CAGF,sBAIE,kBAAA,CADA,YAAA,CADA,OAAA,CAGA,sBAAA,CAJA,iBAIA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LAGtpyfyXsJtr4vuUUNp`,
	"uploadLabel": `zJUSIXVPWeK7zKWMrS7g`,
	"uploadText": `KM_gmtCqxKIpq8IbYCh_`,
	"hoveredBg": `eBk_a8j8kTBp1l86xCjX`,
	"transparentBg": `OUYd5wu8F3p62SI0DUfx`,
	"loadingContainer": `XsRK08NJb77IXSVJCwuw`
};
export default ___CSS_LOADER_EXPORT___;
