// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jnwF4KMSdStoq2jCaxc2{color:#979ba1;margin-right:20px}.jnwF4KMSdStoq2jCaxc2 .eLK1nqGc54hOA9RZb6kM{margin-left:10px}.ycXeOy0sQpn2EDWHAWLa{color:#ff7875;margin-right:2rem}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/header/components/header-status-text.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,aCUU,CDTV,iBAAA,CAEA,4CACE,gBAAA,CAIJ,sBACE,aC+CY,CD9CZ,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saved": `jnwF4KMSdStoq2jCaxc2`,
	"savedLabel": `eLK1nqGc54hOA9RZb6kM`,
	"unsaved": `ycXeOy0sQpn2EDWHAWLa`
};
export default ___CSS_LOADER_EXPORT___;
