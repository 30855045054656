import { SMSCampaignStatsById } from '../../types/sms-campaign-stats-by-id.type'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'
import { SequenceTag } from '@ghostmonitor/recartapis'

export function useBlastsStat(): UseResource<SMSCampaignStatsById> {
  const { data: blasts, isLoading: isBlastLoading } = hooks.useBlasts()
  const { data: sequencesStat, isLoading: isStatLoading } = hooks.useSequencesStat({
    isEnabled: true,
    tags: [SequenceTag.SMS, SequenceTag.CAMPAIGN]
  })

  const blastsStat: SMSCampaignStatsById = blasts?.reduce((acc, blast) => {
    acc[blast._id] = sequencesStat?.[blast.sequenceId]
    return acc
  }, {})

  return {
    data: blastsStat,
    isLoading: isBlastLoading || isStatLoading
  }
}
