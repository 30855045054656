// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kWAhN9id_jqpPcFs2pWO{color:var(--recart-night-blue-1)}.rOpdzOJQGXjYOaBv1AXX{background-color:#fff1f0;border:1px solid #ff4d4f}.F3ppf6A9uISEf2bN2QOb{color:#ff4d4f;padding-top:var(--recart-spacing-1)}.MTtsQ6tarWWMCGuSLZjt{margin-top:7px}.MTtsQ6tarWWMCGuSLZjt:first-child{margin-bottom:5px}.jEDiW_gMTSH60BzkUvFw{margin-left:24px;margin-top:16px}.jEDiW_gMTSH60BzkUvFw .ant-select{width:100%!important}.iz7m7C1FmHspU6OW82Ts{color:var(--recart-night-blue-1);font-size:14px;padding:9px 12px;width:135px}.Q9uU60Z42o_ncVllBTgR{color:var(--recart-night-blue-1);font-size:14px;padding:9px 12px;width:110px}.ixiDeMw5xQqLGDVnZ4uZ{width:100%}.Ycw83TV00DbtDRgwBYwW{height:13px;margin-bottom:-2px;margin-left:-4px;width:13px}.Ycw83TV00DbtDRgwBYwW svg{display:block}.OtWTcurh6tzg95NNrgSb{margin-left:52px}.vKtt7h1jtBPF4gUo6Nwo{background:#fffbed;border:1px solid var(--recart-yellow-1);border-radius:8px;display:flex;margin:var(--recart-spacing-3) 0;padding:12px}.VEemEE5djjw9C1k2yYKY svg{width:18px}.tYS99NDgcP0Ss82STSAw .ant-skeleton{width:100%!important}.tYS99NDgcP0Ss82STSAw .ant-skeleton li{width:95%!important}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/settings-drawer/components/blast-settings-drawer/blast-settings-drawer.component.scss"],"names":[],"mappings":"AACE,sBACE,gCAAA,CAGF,sBACE,wBAAA,CACA,wBAAA,CAGF,sBACE,aAAA,CACA,mCAAA,CAGF,sBACE,cAAA,CAEA,kCACE,iBAAA,CAIJ,sBACE,gBAAA,CACA,eAAA,CAEA,kCACE,oBAAA,CAIJ,sBAEE,gCAAA,CACA,cAAA,CAFA,gBAAA,CAGA,WAAA,CAGF,sBAEE,gCAAA,CACA,cAAA,CAFA,gBAAA,CAGA,WAAA,CAGF,sBACE,UAAA,CAGF,sBAEE,WAAA,CAEA,kBAAA,CADA,gBAAA,CAFA,UAGA,CAEA,0BACE,aAAA,CAIJ,sBACE,gBAAA,CAGF,sBACE,kBAAA,CACA,uCAAA,CACA,iBAAA,CAEA,YAAA,CACA,gCAAA,CAFA,YAEA,CAIA,0BACE,UAAA,CAKF,oCACE,oBAAA,CAEA,uCACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `kWAhN9id_jqpPcFs2pWO`,
	"bg-error": `rOpdzOJQGXjYOaBv1AXX`,
	"error": `F3ppf6A9uISEf2bN2QOb`,
	"radio": `MTtsQ6tarWWMCGuSLZjt`,
	"pickerContainer": `jEDiW_gMTSH60BzkUvFw`,
	"datePicker": `iz7m7C1FmHspU6OW82Ts`,
	"timePicker": `Q9uU60Z42o_ncVllBTgR`,
	"select": `ixiDeMw5xQqLGDVnZ4uZ`,
	"drawerIcon": `Ycw83TV00DbtDRgwBYwW`,
	"optionSubTitle": `OtWTcurh6tzg95NNrgSb`,
	"uniqueDiscountWarning": `vKtt7h1jtBPF4gUo6Nwo`,
	"warningIcon": `VEemEE5djjw9C1k2yYKY`,
	"loader": `tYS99NDgcP0Ss82STSAw`
};
export default ___CSS_LOADER_EXPORT___;
