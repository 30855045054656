import {
  CreateDiscountCodePoolRequest,
  CreateDiscountCodePoolResponse,
  DiscountCodePool,
  GetDiscountCodePoolsResponse
} from '@ghostmonitor/recartapis'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { useMutationHelpers } from '../utils/use-mutation-helpers'
import { QUERY_NAME } from './query-names'

export type CreateDiscountCodePoolRequestUI = Omit<CreateDiscountCodePoolRequest, 'siteId'>

export function useDiscountCodePools(
  options?: UseQueryOptions<GetDiscountCodePoolsResponse>,
  additionalQueryParams?: Record<string, number | string | boolean>
): UseResource<DiscountCodePool[]> & { isLoading: boolean } {
  const queryName = [QUERY_NAME.discountCodePools]
  const queryClient = useQueryClient()
  const { handleError } = useMutationHelpers(queryName)

  const {
    isLoading,
    data: discountCodePools,
    isError,
    refetch
  } = useQuery<GetDiscountCodePoolsResponse>(
    queryName,
    async () => {
      return request.get<GetDiscountCodePoolsResponse>('discount-code-pools', {
        ...(additionalQueryParams ? { params: additionalQueryParams } : {})
      })
    },
    options
  )

  async function createDiscountCodePool(
    discountCodePoolRequest: CreateDiscountCodePoolRequestUI
  ): Promise<CreateDiscountCodePoolResponse> {
    return request.post<CreateDiscountCodePoolResponse>(
      'discount-code-pools',
      discountCodePoolRequest
    )
  }

  const { mutateAsync: mutateCreateDiscountCode } = useMutation<
    CreateDiscountCodePoolResponse,
    unknown,
    CreateDiscountCodePoolRequestUI
  >(createDiscountCodePool, {
    onError: handleError,
    onSuccess: (createDiscountPoolResponse: CreateDiscountCodePoolResponse) => {
      const discountCodePools: GetDiscountCodePoolsResponse = queryClient.getQueryData(queryName)
      if (discountCodePools) {
        const newDiscountCodePools = {
          data: [...discountCodePools.data, createDiscountPoolResponse.data]
        }

        queryClient.setQueryData(queryName, newDiscountCodePools)
        queryClient.invalidateQueries(queryName)
      }
    }
  })

  return {
    data: discountCodePools?.data,
    refetch: refetch as any,
    isLoading: isLoading || discountCodePools === undefined,
    isError,
    create: mutateCreateDiscountCode
  }
}
