import {
  DiscountCode,
  DiscountTypes,
  isStaticDiscountCode,
  isUniqueDiscountCode,
  Variable
} from '@ghostmonitor/recartapis'
import { getDiscountCodeInfoFromVariable } from '../variables/get-discount-code-from-variable'
import { getVariableFromId, getVariablesFromRawValue } from './get-variables'

export function getDiscountCodeVariable(discountCode: DiscountCode): Variable {
  if (isStaticDiscountCode(discountCode)) {
    const { title } = discountCode
    return {
      name: title,
      params: {
        discount_code: title
      }
    }
  }
  if (isUniqueDiscountCode(discountCode)) {
    const { poolId } = discountCode
    return {
      name: 'discount.unique',
      params: {
        discount_pool_id: poolId
      }
    }
  }
}

export function getUrlDiscountCodeVariable(discountCode: DiscountCode, url: string): Variable {
  const baseParams = {
    value: url,
    url_add_discount: undefined,
    url_shorten: undefined,
    url_decorate: undefined
  }
  if (isStaticDiscountCode(discountCode)) {
    const { title } = discountCode
    return {
      name: 'url',
      params: {
        ...baseParams,
        discount_code: title
      }
    }
  }
  if (isUniqueDiscountCode(discountCode)) {
    const { poolId } = discountCode
    return {
      name: 'url',
      params: {
        ...baseParams,
        discount_pool_id: poolId
      }
    }
  }
}

export function getDiscountCodeFromVariableData(variableData: string) {
  const variable = getVariableFromId(variableData)
  return getDiscountCodeInfoFromVariable(variable)
}

export const DiscountTypeLabels: { [K in DiscountTypes]?: string } = {
  [DiscountTypes.PERCENTAGE]: 'Percentage',
  [DiscountTypes.FIXED_AMOUNT]: 'Fixed Amount',
  [DiscountTypes.FREE_SHIPPING]: 'Free Shipping'
}

export function getDiscountCodesFromText(text: string): string[] {
  const variables = getVariablesFromRawValue(text)

  const discountCodes = variables.reduce((accDiscountCodes, variable) => {
    if (variable.params.discount_code) {
      accDiscountCodes.add(variable.params.discount_code)
    }
    return accDiscountCodes
  }, new Set<string>())

  return Array.from(discountCodes)
}

export function getDiscountPoolIdsFromText(text: string): string[] {
  const variables = getVariablesFromRawValue(text)

  const discountPoolIds = variables.reduce((accDiscountPoolIds, variable) => {
    if (variable.params.discount_pool_id) {
      accDiscountPoolIds.add(variable.params.discount_pool_id)
    }
    return accDiscountPoolIds
  }, new Set<string>())

  return Array.from(discountPoolIds)
}
