import React from 'react'

import { ReactComponent as TextTrayIcon } from '../../../../static/images/svg/tray/icon-text.svg'
import { ReactComponent as CardTrayIcon } from '../../../../static/images/svg/tray/icon-card.svg'
import { ReactComponent as ImageTrayIcon } from '../../../../static/images/svg/tray/icon-image.svg'
import { ReactComponent as VideoTrayIcon } from '../../../../static/images/svg/tray/icon-video.svg'
import { ReactComponent as AudioTrayIcon } from '../../../../static/images/svg/tray/icon-audio.svg'
import { ReactComponent as FileTrayIcon } from '../../../../static/images/svg/tray/icon-file.svg'
import { ReactComponent as GalleryTrayIcon } from '../../../../static/images/svg/tray/icon-gallery.svg'
import { ReactComponent as NewsTrayIcon } from '../../../../static/images/svg/icon-news.svg'
import { ReactComponent as TemplateTrayIcon } from '../../../../static/images/svg/icon-template.svg'
import { ReactComponent as ListTrayIcon } from '../../../../static/images/svg/tray/icon-list.svg'
import { ReactComponent as MessageTrayIcon } from '../../../../static/images/svg/streamline/stroke/21-Messages-Chat-Smileys/02-Messages-Speech-Bubbles/messages-bubble-add.svg'
import { ReactComponent as ConditionTrayIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/15-Filter/filter-1.svg'
import { ReactComponent as DelayTrayIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/18-Time/time-stopwatch-3-quarters.svg'
import { ReactComponent as UserInputTrayIcon } from '../../../../static/images/svg/tray/icon-user-input.svg'
import { ReactComponent as RandomSplitTrayIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/42-Multimedia-Controls/button-split.svg'
import { ReactComponent as ReceiptTrayIcon } from '../../../../static/images/svg/tray/icon-receipt.svg'
import { ReactComponent as ReviewTrayIcon } from '../../../../static/images/svg/tray/icon-review.svg'
import { ReactComponent as FulfillmentTrayIcon } from '../../../../static/images/svg/tray/icon-fulfillment.svg'
import { ReactComponent as AbandonedTrayIcon } from '../../../../static/images/svg/tray/icon-abandoned.svg'
import { ReactComponent as ContactCardIcon } from '../../../../static/images/svg/tray/icon-contact-card.svg'

export const trayIcons = {
  message: <MessageTrayIcon />,
  condition: <ConditionTrayIcon />,
  delay: <DelayTrayIcon />,
  userInput: <UserInputTrayIcon />,
  randomSplit: <RandomSplitTrayIcon />,
  text: <TextTrayIcon />,
  card: <CardTrayIcon />,
  image: <ImageTrayIcon />,
  video: <VideoTrayIcon />,
  audio: <AudioTrayIcon />,
  file: <FileTrayIcon />,
  gallery: <GalleryTrayIcon />,
  news: <NewsTrayIcon />,
  template: <TemplateTrayIcon />,
  list: <ListTrayIcon />,
  receipt: <ReceiptTrayIcon />,
  review: <ReviewTrayIcon />,
  fulfillment: <FulfillmentTrayIcon />,
  abandoned: <AbandonedTrayIcon />,
  contactCard: <ContactCardIcon />
}
