import cn from 'classnames'
import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import ContentEditable from 'react-contenteditable'
import useOnClickOutside from 'use-onclickoutside'
import { getCharacterCount } from '../../../../../../utils/form'
import {
  getContentLength,
  getNodeCaretPosition,
  insertContentAtCaretPosition,
  removeHTMLEntities,
  replaceNonBreakingSpaces,
  setNodeCaretPosition
} from '../../../../../../utils/inline-editor/inline-editor-utils'
import { debug } from '../../../../utils/debug'
import { containerRef } from '../../inline-editor-container-ref'
import {
  InlineEditorProps,
  VariableMouseClickEvent
} from '../../use-inline-editor-hoc-control.hook'
import styles from './pluginable-inline-editor.style.scss'

const caretChangingSpecialKeys = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp']
const allowedSpecialKeys = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
  'ArrowUp',
  'Delete'
]

const MemoContentEditable = React.memo(ContentEditable, (p, n) => p.html === n.html)

const ref = {
  handleBlur: () => undefined
}

function refHandleBlur() {
  ref.handleBlur()
}

export function PluginableInlineEditor(props: InlineEditorProps) {
  const [currentCaretPosition, setCurrentCaretPosition] = useState<number>(null)
  const [nextCaretPosition, setNextCaretPosition] = useState<number>(null)
  const [html, setHtml] = useState(null)
  const [htmlForCaretPosition, setHtmlForCaretPosition] = useState(null)
  const [rawValue, setRawValue] = useState(props.rawValue)
  const [isFocus, setIsFocus] = useState(false)
  const [changedByTyping, setChangedByTyping] = useState(false)
  const [keyDownSinceLastChange, setKeyDownSinceLastChange] = useState(false)
  const [defaultValue, setDefaultValue] = useState<string>()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const defaultEditorRef = useRef<HTMLDivElement>()
  const editorRef = props.editorRef ?? defaultEditorRef

  const characterCountRef = useRef<number>()

  const triggerChange = useCallback(
    (newHtmlValue: string) => {
      props.onChange?.(removeHTMLEntities(newHtmlValue))
      if (props.html === undefined) {
        setHtml(newHtmlValue)
      }
    },
    [props]
  )

  function handleKeydown(event: KeyboardEvent<HTMLDivElement>) {
    setKeyDownSinceLastChange(true)
    if (props.multiline === false && event.key === 'Enter') {
      event.preventDefault()
      return
    }
    if (
      props.characterLimit !== undefined &&
      characterCountRef.current >= props.characterLimit &&
      !allowedSpecialKeys.includes(event.key)
    ) {
      event.preventDefault()
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    debug('Trigger with handleChange')
    debug(`Change: ${getNodeCaretPosition(editorRef.current)}`)
    setCurrentCaretPosition(getNodeCaretPosition(editorRef.current))
    setChangedByTyping(true)
    triggerChange(event.target.value)
  }

  function handleFocus() {
    setIsFocus(true)
    props.onFocus?.()
  }

  function handleBlur() {
    if (!isPopoverOpen) {
      setIsFocus(false)
      props.onBlur?.()
    }
  }

  ref.handleBlur = handleBlur

  function handleClick(event: VariableMouseClickEvent) {
    handleFocus()
    props.onClick?.(event)
    debug(`Click: ${getNodeCaretPosition(editorRef.current)}`)
    setCurrentCaretPosition(getNodeCaretPosition(editorRef.current))
  }

  function handleKeyUp(event: KeyboardEvent) {
    if (caretChangingSpecialKeys.includes(event.key)) {
      debug(`Arrow: ${getNodeCaretPosition(editorRef.current)}`)
      setCurrentCaretPosition(getNodeCaretPosition(editorRef.current))
    }
  }

  function handleMouseMove(event: MouseEvent<HTMLDivElement>) {
    props.onMouseMove?.(event)
  }

  function handleMouseLeave(event: MouseEvent<HTMLDivElement>) {
    props.onMouseLeave?.(event)
  }

  function handleMouseDown(event: MouseEvent<HTMLDivElement>) {
    event.stopPropagation()
  }

  function handlePaste(event: React.ClipboardEvent) {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text)
  }

  function handleContentFocus() {
    setIsFocus(true)
  }

  function insertContent(insertedValue: string, position: number): string {
    const prevHtml = editorRef.current.innerHTML
    const nextHtml = insertContentAtCaretPosition(prevHtml, insertedValue, position)
    const nextPosition = position + getContentLength(insertedValue)
    setNextCaretPosition(nextPosition)
    return nextHtml
  }

  useEffect(() => {
    props.onInsertMethodChange?.(insertContent)
  }, [])

  useEffect(() => {
    if (rawValue !== props.rawValue) {
      setRawValue(props.rawValue)
    }
  }, [rawValue, props.rawValue])

  useEffect(() => {
    if (props.defaultValue !== defaultValue && !keyDownSinceLastChange) {
      if (props.html !== undefined) {
        debug('Trigger with defaultValue', props.defaultValue)
        triggerChange(replaceNonBreakingSpaces(props.defaultValue))
      } else {
        setHtml(props.defaultValue)
        setRawValue(props.defaultValue)
      }
      setDefaultValue(props.defaultValue)
      setKeyDownSinceLastChange(false)
    }
  }, [props.defaultValue, defaultValue, keyDownSinceLastChange])

  /**
   * In controlled mode, an other plugin inserted a HTML
   */
  useEffect(() => {
    if (props.html !== undefined && props.html !== html) {
      debug('NEW HTML')

      if (html !== null && !changedByTyping) {
        debug('TURN UP', props.html)
        triggerChange(props.html)
      }

      if (html !== null) {
        setCurrentCaretPosition(getNodeCaretPosition(editorRef.current))
      }
      if (nextCaretPosition !== null) {
        setCurrentCaretPosition(nextCaretPosition)
        setNextCaretPosition(null)
      }

      setHtml(props.html)
      setChangedByTyping(false)
    }
  }, [props.html, html, changedByTyping, triggerChange])

  /**
   * In controlled mode, an other plugin inserted a HTML
   */
  useEffect(() => {
    if (
      html !== null &&
      htmlForCaretPosition !== html &&
      !changedByTyping &&
      currentCaretPosition !== null &&
      currentCaretPosition !== getNodeCaretPosition(editorRef.current)
    ) {
      setHtmlForCaretPosition(html)
      setNodeCaretPosition(editorRef.current, currentCaretPosition)
    }
  }, [html, htmlForCaretPosition, changedByTyping, currentCaretPosition, editorRef])

  /**
   * The inner caret position state changed, so
   * report it for the listeners
   */
  useEffect(() => {
    if (currentCaretPosition !== null) {
      props.onCaretPositionChange?.(currentCaretPosition)
    }
  }, [currentCaretPosition])

  useEffect(() => {
    if (rawValue !== undefined) {
      const count = props.getCharacterCount ?? getCharacterCount
      const characterCount = count(rawValue)
      characterCountRef.current = characterCount
      props.onCharCountChange?.(characterCount)
    }
  }, [rawValue])

  if (props.html === undefined && props.rawValue !== undefined) {
    throw new Error('Cannot set rawValue if not in controlled mode')
  }

  function handleClickOutside() {
    setIsPopoverOpen(false)
    ref.handleBlur()
  }

  useOnClickOutside(containerRef, handleClickOutside)

  function handleButtonClick(event: MouseEvent) {
    event.stopPropagation()
    setIsPopoverOpen(true)
  }

  return (
    <div ref={containerRef}>
      <div>
        <MemoContentEditable
          data-testid='inline-editor'
          className={cn(styles.editorContainer, {
            [styles.disabled]: !props.isEditable
          })}
          disabled={!props.isEditable}
          html={html ?? ''}
          innerRef={editorRef}
          onBlur={refHandleBlur}
          onFocus={handleContentFocus}
          onChange={handleChange}
          onKeyDown={handleKeydown}
          onMouseDown={handleMouseDown}
          onPaste={handlePaste}
          onClick={(event: unknown) => handleClick(event as VariableMouseClickEvent)}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onKeyUp={handleKeyUp}
        />
        <div
          data-testid='addon-container'
          className={cn(styles.addonContainer, {
            [styles.showAddonContainer]: isFocus && props.isEditable
          })}
        >
          {props.plugins?.map((plugin) => {
            return (
              <div key={plugin.name} id={`button-${plugin.name}`} onClick={handleButtonClick}>
                {plugin.button}
              </div>
            )
          })}
        </div>
      </div>
      {props.plugins?.map((plugin) => {
        return <div key={plugin.name}>{plugin.popover}</div>
      })}
    </div>
  )
}
