import { FlowUI } from '@ghostmonitor/recartapis'
import { useSelector } from 'react-redux'
import { flowEditorSelectors } from '../../store/selectors'
import { isCustomWelcomeFlowUI } from '../../types/guards/flow-ui.guards'
import { hooks } from '../hooks'

export interface CustomWelcomeFlowStatus {
  statusLabel: 'active' | 'inactive' | 'draft'
  primaryCTA: string | undefined
  secondaryCTA: string | undefined
}
export function useSMSWelcomeFlowStatus(
  flow: FlowUI,
  backTo?: string | null
): CustomWelcomeFlowStatus | undefined {
  const { data: optinTools } = hooks.useOptinToolsList()
  const isEverSaved = useSelector(flowEditorSelectors.selectEditorFlowMeta)?.isEverSaved

  if (!flow._id || optinTools === undefined || !isCustomWelcomeFlowUI(flow)) {
    return undefined
  }

  const path = backTo?.split('/') ?? []
  const isArrivedFromOptinEditor = path.includes('optin-tools')

  if (isArrivedFromOptinEditor) {
    const backToOptinToolId = path.slice(-1)[0]
    const optinToolArrivedFrom = optinTools.find((optinTool) => optinTool.id === backToOptinToolId)

    if (optinToolArrivedFrom?.status === 'active') {
      if (!isEverSaved) {
        return {
          statusLabel: undefined,
          primaryCTA: 'activate',
          secondaryCTA: undefined
        }
      }

      return {
        statusLabel: 'active',
        primaryCTA: flow.hasEverBeenEnabled === true ? 'update' : 'activate',
        secondaryCTA: undefined
      }
    }

    if (flow.hasEverBeenEnabled) {
      return {
        statusLabel: 'inactive',
        primaryCTA: undefined,
        secondaryCTA: 'save-as-inactive'
      }
    }

    return {
      statusLabel: isEverSaved ? 'draft' : undefined,
      primaryCTA: undefined,
      secondaryCTA: 'save-as-draft'
    }
  }

  if (!flow.hasEverBeenEnabled) {
    return {
      statusLabel: 'draft',
      primaryCTA: undefined,
      secondaryCTA: 'save-as-draft'
    }
  }

  return flow.isEnabled
    ? {
        statusLabel: 'active',
        primaryCTA: 'update',
        secondaryCTA: undefined
      }
    : {
        statusLabel: 'inactive',
        primaryCTA: undefined,
        secondaryCTA: 'save-as-inactive'
      }
}
