import {
  URLGenerationSettingsInUse,
  Variable,
  VariableParam,
  VariableParams,
  aliasVariableNames,
  getVariableFromRawVariable,
  getVariableParams,
  placeholderVariablePattern,
  specialPlaceholderVariables,
  variablePattern
} from '@ghostmonitor/recartapis'

import { getShortDomain } from './get-short-domain'
import {
  isDiscountUrlVariable,
  isKeywordReplyVariable,
  isLinkVariable,
  isStaticDiscountCodeVariable,
  isUniqueDiscountCodeVariable
} from './variable-assertion'

export function getVariablePlaceholder(variableName: string): string {
  return specialPlaceholderVariables[variableName] ?? variableName.replace('.', '_')
}

export function getVariableNameFromAlias(variableName: string): string {
  let variable: string = variableName
  Object.keys(aliasVariableNames).forEach((originalVariable) => {
    if (aliasVariableNames[originalVariable].includes(variableName)) {
      variable = originalVariable
    }
  })
  return variable
}

export function getRawVariable(variableName: string, variableParams: VariableParams = {}): string {
  const smartVariableName = getVariableNameFromAlias(variableName)
  return `{{${smartVariableName}|${Object.keys(variableParams)
    .map(
      (paramName) =>
        `${paramName}${
          // note: we should accept empty string as a value because of the name variables
          variableParams[paramName] !== undefined ? `:${variableParams[paramName]}` : ''
        }`
    )
    .join('|')}}}`
}

function canContainDiscountCode(variableName: string): boolean {
  return ['site.domain', 'cart.url'].includes(variableName)
}

export function getVariable(variableName: string, variableFallback?: string): Variable {
  const variable: Variable = {
    name: variableName,
    params: {}
  }

  if (canContainDiscountCode(variableName)) {
    variable.params[VariableParam.url_add_discount] = undefined
  }

  // note: we should accept empty string as a value because of the name variables
  if (variableFallback !== undefined) {
    variable.params[VariableParam.default] = variableFallback
  } else {
    variable.params[VariableParam.required] = undefined
  }

  return variable
}

export function getVariableFromId(id: string): Variable {
  const parts = id.split('|')
  const variable: Variable = {
    name: parts[0],
    params: {}
  }

  if (parts.length > 2) {
    variable.params = getVariableParams(parts.slice(1, -1).join('|'))
  }

  return variable
}

export function getUrlFromId(id: string, withTag: string): string {
  const tagArr = id.split('|')
  const isUrlTag = tagArr.includes(withTag)
  if (!isUrlTag) {
    return
  }
  const value = tagArr.find((val) => val.startsWith('value:'))
  if (!value) {
    return
  }
  const [, ...urlArr] = value.split(':')
  const url = urlArr.join(':')
  return url
}

export function getIndexFromId(id: string): number {
  const parts = id.split('|')
  return Number.parseInt(parts[parts.length - 1], 10)
}

export function getElementByVariableIndex(
  htmlValue: string,
  variableIndex: number
): HTMLSpanElement | undefined {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = htmlValue
  for (const node of holderElement.children) {
    if (node.tagName !== 'SPAN') {
      continue
    }
    const tagId = node.getAttribute('id')
    if (!tagId) {
      continue
    }
    const index = getIndexFromId(tagId)
    if (index === variableIndex) {
      return document.getElementById(node.getAttribute('id'))
    }
  }
}

export function getRenderedVariable(
  variable: Variable,
  urlGenerationSettings: URLGenerationSettingsInUse,
  prefix?: string
): string {
  const { domain, subdomain } = urlGenerationSettings
  if (isLinkVariable(variable)) {
    return `${getShortDomain(domain, subdomain)}/shortlink`
  }

  if (isStaticDiscountCodeVariable(variable)) {
    if (variable.name === 'url') {
      return `${getShortDomain(domain, subdomain)}/discount-code-url`
    }
    if (isDiscountUrlVariable(variable)) {
      return `${getVariablePlaceholder(variable.name)}`
    }

    return `${getVariablePlaceholder(variable.params.discount_code)}`
  }
  if (isUniqueDiscountCodeVariable(variable)) {
    if (variable.name === 'url') {
      return `${getShortDomain(domain, subdomain)}/unique-discount-code-url`
    }

    if (isDiscountUrlVariable(variable)) {
      return `${getVariablePlaceholder(variable.name)}`
    }
    return prefix ? `${prefix}UNIQUE` : 'UNIQUE'
  }

  if (isKeywordReplyVariable(variable)) {
    return `${getVariablePlaceholder(variable.name)}`
  }

  return `{${getVariablePlaceholder(variable.name)}}`
}

export function getIdFromVariable(variable: Variable, index: number): string {
  const parts = Object.keys(variable.params ?? {}).map(
    (paramName) =>
      `${paramName}${
        // note: we should accept empty string as a value because of the name variables
        variable.params[paramName] !== undefined ? `:${variable.params[paramName]}` : ''
      }`
  )
  parts.push(`${index}`)
  return `${variable.name}|${parts.join('|')}`
}

export function getVariablesFromRawValue(rawValue: string): Variable[] {
  const variables: Variable[] = []
  let match: string[]
  const pattern = new RegExp(variablePattern, 'g')
  while ((match = pattern.exec(rawValue))) {
    variables.push(getVariableFromRawVariable(match[0]))
  }
  return variables
}

export function convertUserVariables(rawValue: string): string {
  let match: string[]

  while ((match = placeholderVariablePattern.exec(rawValue))) {
    const variableName = match[0].slice(1, -1)
    rawValue = rawValue.replace(
      match[0],
      getRawVariableFromVariable({
        name: variableName,
        params: {
          [VariableParam.default]: undefined
        }
      })
    )
  }

  return rawValue
}

export function getRawVariableFromVariable(variable: Variable): string {
  if (!variable) {
    return
  }
  return getRawVariable(variable.name, variable.params)
}

export function getMessageVariablesCaretPosition(plainText: string): number[] {
  let plainTextToCheckForVariable = plainText
  let plainTextAlreadyChecked = ''

  const varPattern = new RegExp(placeholderVariablePattern, 'g')
  const caretPositions: number[] = []
  let match

  while (plainTextToCheckForVariable.match(varPattern)) {
    match = varPattern.exec(plainTextToCheckForVariable)

    const caretPosition =
      plainTextToCheckForVariable.indexOf(match[0]) + plainTextAlreadyChecked.length
    plainTextAlreadyChecked = plainTextAlreadyChecked.concat(
      plainTextToCheckForVariable.slice(
        0,
        plainTextToCheckForVariable.indexOf(match[0]) + match[0].length
      )
    )
    caretPositions.push(caretPosition)
    plainTextToCheckForVariable = plainTextToCheckForVariable.slice(
      plainTextToCheckForVariable.indexOf(match[0]) + match[0].length
    )
  }

  return caretPositions
}
