import { LockFilled, UserOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Button } from '../../../components/ui-kit/button/button.component'
import { Input, PasswordInput } from '../../../components/ui-kit/input/input.component'
import { config } from '../../../config'
import { hooks } from '../../../hooks/hooks'
import { ReactComponent as RecartLogo } from '../../../static/images/svg/loading-recart-logo.svg'
import { Hint } from '../components/hint/hint.component'
import styles from './login.style.scss'

const demoEmails = [
  'sms-ai-beauty@recart.com',
  'sms-ai-fashion-m@recart.com',
  'sms-ai-fashion-w@recart.com',
  'sms-ai-health@recart.com',
  'sms-ai-cpg@recart.com',
  'sms-ai-caden-lane@recart.com'
]

export interface LoginForm {
  email: string
  password: string
}

export function Login() {
  const navigate = useNavigate()

  const { demoLogin, login, isLoginLoading } = hooks.useAuth()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const encodedUrl = searchParams.get('ref')
    if (encodedUrl !== null) {
      const queryParams = new URLSearchParams(window.atob(encodedUrl))
      const email = queryParams.get('email')
      const demo = queryParams.get('ai-demo')

      if (email !== null && demoEmails.includes(email) && demo === 'true') {
        demoLogin(email)
        return
      }
    }

    const token = searchParams.get('token')
    if (!token) {
      return
    }

    localStorage.setItem('x-auth-token', token as string)
    navigate(config.START_URL, { replace: true })
  }, [navigate])

  async function handleFinish(values: LoginForm) {
    await login(values)
  }

  return (
    <>
      <RecartLogo className={styles.logo} />
      <Hint className='mb-4'>
        <span className='font-bold'>Are you on Shopify?</span> You can log in from Shopify Admin -
        Apps - Recart.
      </Hint>
      <Form<LoginForm> onFinish={handleFinish}>
        <Form.Item
          name='email'
          rules={[
            { required: true, message: 'Required' },
            { type: 'email', message: 'Please enter valid email address' }
          ]}
        >
          <Input prefix={<UserOutlined />} placeholder='Email address' data-testid='input-email' />
        </Form.Item>
        <Form.Item name='password' rules={[{ required: true, message: 'Required' }]}>
          <PasswordInput prefix={<LockFilled />} placeholder='Password' />
        </Form.Item>
        <Form.Item className='flex justify-center !mb-0'>
          <Button
            type='primary'
            htmlType='submit'
            loading={isLoginLoading}
            data-testid='button-login'
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
