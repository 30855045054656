// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BNpoc5SFSn15yvnsM9iI{padding:var(--recart-spacing-1) 0;width:220px}.BNpoc5SFSn15yvnsM9iI .GoEHy0FTbxJLFORomOYg{color:#595959;font-family:Lato,sans-serif}.BNpoc5SFSn15yvnsM9iI svg path{fill:#ef807a}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/popover-discount-code-expired/popover-discount-code-expired.component.scss"],"names":[],"mappings":"AACE,sBACE,iCAAA,CACA,WAAA,CAEA,4CAEE,aAAA,CADA,2BACA,CAIA,+BACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BNpoc5SFSn15yvnsM9iI`,
	"content": `GoEHy0FTbxJLFORomOYg`
};
export default ___CSS_LOADER_EXPORT___;
