import React from 'react'
import { ReactComponent as RightArrowIcon } from '../../../static/ui-kit/icons/small/arrow-right.svg'
import styles from './range-picker-separator.component.scss'

export function RangePickerSeparator() {
  return (
    <div className={styles.separator}>
      <RightArrowIcon className={styles.icon} />
    </div>
  )
}
