import { AttachmentType } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import React, { useEffect } from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { ReactComponent as MenuIcon } from '../../../../static/images/svg/streamline/stroke/01-Interface-Essential/03-Menu/navigation-menu.svg'
import { getComponentDisplayName } from '../../../../utils/utils'
import styles from './interactive-component.component.scss'

export interface InteractiveComponentProps {
  errorMessage?: string
  isLast?: boolean
  isFirst?: boolean
  removeEnabled?: boolean
  onAddButtonClick?: () => void
  onRemoveElement?: () => void
  isSelected: boolean
  isBlurred: boolean
  hasError?: boolean
  isRounded?: boolean
  hasAddButton?: boolean
  isGallery?: boolean
  attachmentType?: AttachmentType
  isDraggable?: boolean
  disableTextEdit?: boolean
  className?: string
}

const DragHandle = SortableHandle(() => <MenuIcon />)

export function makeInteractive<WrappedComponentProps>(
  WrappedComponent: React.ComponentType<WrappedComponentProps & InteractiveComponentProps>
): React.FC<WrappedComponentProps & InteractiveComponentProps> {
  const MakeInteractive = (props) => {
    const [hovered, setHovered] = React.useState(false)

    useEffect(() => {
      if (!props.isSelected) {
        setHovered(false)
      }
    }, [props.isSelected])

    function onMouseOver(event: React.MouseEvent) {
      event.stopPropagation()
      setHovered(true)
      return false
    }

    function onMouseOverHandle(event: React.MouseEvent) {
      event.stopPropagation()
      setHovered(false)
      return false
    }

    function onMouseLeave(event: React.MouseEvent) {
      event.stopPropagation()
      setHovered(false)
      return false
    }

    function onRemove() {
      if (props.onRemoveElement) {
        props.onRemoveElement()
      }
    }

    function onAdd() {
      if (props.onAddButtonClick) {
        props.onAddButtonClick()
      }
    }

    return (
      <div
        className={cn(styles.interactive, {
          [styles['has-error']]: props.errorMessage,
          [styles.hovered]: hovered,
          [styles.last]: props.isLast,
          [styles.first]: props.isFirst,
          [styles.selected]: props.isSelected,
          [styles.blurred]: props.isBlurred && !props.errorMessage,
          [styles.rounded]: props.isRounded,
          [styles.image]: props.attachmentType && props.attachmentType === AttachmentType.image,
          [props.className]: props.className
        })}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        data-testid={props.errorMessage ? 'interactive-component-error' : 'interactive-component'}
      >
        <div className={styles['remove-button-wrapper']}>
          {props.removeEnabled !== false && (
            <div
              data-testid='remove-element'
              className={styles['remove-button']}
              onClick={onRemove}
            >
              ×
            </div>
          )}
          <div className={styles['error-message-wrapper']}>
            <div className={styles['error-message']}>{props.errorMessage}</div>
          </div>
        </div>
        {props.isDraggable && !props.isGallery && (
          <div
            className={styles.handle}
            onMouseOver={onMouseOverHandle}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <DragHandle />
          </div>
        )}
        <WrappedComponent
          {...(props as WrappedComponentProps)}
          isSelected={props.isSelected}
          isBlurred={props.isBlurred}
          hasError={!!props.errorMessage}
          isGallery={props.isGallery}
          errorMessage={props.errorMessage}
        />
        {props.hasAddButton && (
          <div className={styles['add-button-wrapper']}>
            <div onClick={onAdd} className={styles['add-button']}>
              + Add button
            </div>
          </div>
        )}
      </div>
    )
  }

  MakeInteractive.displayName = `makeInteractive(${getComponentDisplayName(WrappedComponent)})`

  return MakeInteractive
}
