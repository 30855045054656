import type { SyncThunk } from '../../../../../store/redux-helpers'
import { selectEditorSequence } from '../../../../../store/selectors'
import { FacebookCommentSaveModal } from '../../../components/save-modal/components/facebook-comment-save-modal.component'
import { setSequenceEditorSetting } from '../../../hooks/use-sequence-editor-settings-context'
import { isFacebookCommentsSequence } from '../../../utils/assert-sequence-type'
import { SequenceEditorSettings } from '../../context/sequence-editor-settings.type'

export function facebookCommentThunk(): SyncThunk {
  return (dispatch, getState) => {
    const state = getState()
    const sequence = selectEditorSequence(state)

    if (isFacebookCommentsSequence(sequence)) {
      setSequenceEditorSetting(SequenceEditorSettings.saveModalComponent, FacebookCommentSaveModal)
    }
  }
}
