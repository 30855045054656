import { BlastScheduleType } from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import * as antd from 'antd'
import {
  DatePickerProps,
  Form,
  InputProps,
  RadioChangeEvent,
  RadioProps,
  Skeleton,
  Space,
  Switch,
  SwitchProps,
  TimePickerProps
} from 'antd'
import cn from 'classnames'
import { Moment } from 'moment'
import * as momentTimeZone from 'moment-timezone'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Option, Select } from '../../../../../../components/ui-kit/select/select.component'
import { Tooltip } from '../../../../../../components/ui-kit/tooltip/tooltip.component'
import { UIKit } from '../../../../../../components/ui-kit/ui-kit'
import { hooks } from '../../../../../../hooks/hooks'
import { useFeatureFlag } from '../../../../../../hooks/use-feature-flag'
import { ReactComponent as InfoIcon } from '../../../../../../static/images/svg/sequence-editor/info-icon.svg'
import { ReactComponent as ExclamationIcon } from '../../../../../../static/ui-kit/icons/normal/exclamation.svg'
import {
  selectEditorBlast,
  selectEditorBlastMeta,
  selectEditorSequence
} from '../../../../../../store/selectors'
import {
  blastScheduledChanged,
  renameBlast,
  scheduleBlastForLater,
  scheduleBlastForNow,
  setBlastScheduleType,
  toggleBlastQuietHours,
  toggleBlastSmartSending
} from '../../../../../../store/slices/sequence-editor/sequence-editor.actions'
import { selectTimeZone } from '../../../../../../store/slices/site/site.selectors'
import { gaEvent } from '../../../../../../utils/analytics/google-analytics'
import { formatters } from '../../../../../../utils/formatters/formatters'
import {
  loadScheduledFor,
  saveScheduledFor
} from '../../../../../../utils/scheduled-for-date-handling'
import { dateFormatDatePicker, quietHoursTimeFormat } from '../../../../../../utils/utils'
import { blastValidationErrors } from '../../../../validators/blast.validator'
import { Label } from '../label/label.component'
import { SegmentSection } from '../segment-section/segment-section.component'
import { Title } from '../title/title.component'
import styles from './blast-settings-drawer.component.scss'

type ToggleProps = SwitchProps & { title: string; tooltipText: string; subTitle?: string }

function Toggle(props: ToggleProps) {
  const { title, tooltipText, subTitle, ...switchProps } = props
  return (
    <div className='mb-3'>
      <Space size='small'>
        <Switch {...switchProps} />
        <div className='text-sm'>{title}</div>
        <Tooltip placement='top' title={tooltipText} arrowPointAtCenter>
          <div className={styles.drawerIcon}>
            <InfoIcon />
          </div>
        </Tooltip>
      </Space>
      {subTitle && (
        <p className={cn('text-tiny text-secondary', styles.optionSubTitle)}>{subTitle}</p>
      )}
    </div>
  )
}

function Radio(props: RadioProps) {
  return <antd.Radio className={styles.radio} {...props} />
}

function Input(props: InputProps) {
  return <UIKit.Input className={styles.input} {...props} />
}

function DatePicker(props: DatePickerProps) {
  return <antd.DatePicker className={styles.datePicker} {...props} />
}

function TimePicker(props: TimePickerProps) {
  return <antd.TimePicker use12Hours showNow={false} className={styles.timePicker} {...props} />
}

enum SchedulingType {
  NOW = 'NOW',
  LATER = 'LATER'
}

const timeFormat = 'h:mm a'

export function BlastSettingsDrawer() {
  const dispatch = useDispatch()

  const blast = useSelector(selectEditorBlast)
  const timezone = hooks.useSiteSelector(selectTimeZone)
  const sequence = useSelector(selectEditorSequence)
  const blastMeta = useSelector(selectEditorBlastMeta)
  const [dateTime, setDateTime] = useState<Moment>()
  const isSMSFeatureEnabled = useFeatureFlag('enable-sms-feature')

  const { data: allSubscribersCount } = hooks.useSubscribersCount('sms')
  const { data: targetSubscribersCount, isLoading: isSubscribersCountLoading } =
    hooks.useSubscribersCount('sms', blast?.targetRules)

  const { data: segments } = hooks.useSegmentsList(undefined, {
    enabled: isSMSFeatureEnabled ?? false
  })
  const { data: SMSQuietHoursSettings } = hooks.useSMSQuietHoursSettings()
  const { data: SMSSmartSendingSettings } = hooks.useSMSSmartSendingSettings()
  const isQuietHoursSet = SMSQuietHoursSettings?.start && SMSQuietHoursSettings?.end
  const { data: attachedDiscountCodePools } = hooks.useDiscountPoolAttachedToSequence()
  const hasMMS = hooks.useHasMMS()
  const hasInternationalPhoneNumber = hooks.useHasInternationPhoneNumber()

  const subscriberTimeZoneInfo = (
    <>
      <div className='mb-2'>
        Subscribers without a known timezone will receive this message in the store's timezone.
      </div>
      <div>
        If schedule time is within 24 hours which might be past for some subscribers, then message
        will be sent out immediately to those subscribers.
      </div>
    </>
  )

  useEffect(() => {
    if (!blast || dateTime || !timezone) {
      return
    }

    setDateTime(loadScheduledFor(blast.scheduledFor, blast.scheduleType, timezone))
  }, [blast, dateTime, timezone])

  useEffect(() => {
    if (blastMeta.name.error === (blastValidationErrors.EMPTY_NAME as SerializedError)) {
      gaEvent({
        category: 'Sequence Editor',
        action: 'Blast save',
        label: 'Campaign name empty'
      })
    }
  }, [blastMeta.name.error])

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    const name = event.target.value
    dispatch(renameBlast({ name }))
  }

  function handleScheduleTypeChange(value: any): void {
    dispatch(setBlastScheduleType({ scheduleType: value }))
    dispatch(
      blastScheduledChanged({
        scheduledFor: saveScheduledFor(dateTime.toISOString(), value, timezone).toISOString(true)
      })
    )
  }

  function handleSmartSendingChange(isChecked: boolean) {
    dispatch(toggleBlastSmartSending({ isEnabled: isChecked }))
  }

  function handleQuietHoursChange(isChecked: boolean) {
    dispatch(toggleBlastQuietHours({ isEnabled: isChecked }))
  }

  function handleSchedulingTypeChange(event: RadioChangeEvent) {
    const schedulingType: SchedulingType = event.target.value
    if (schedulingType === SchedulingType.NOW) {
      dispatch(scheduleBlastForNow())
    } else {
      dispatch(scheduleBlastForLater({ scheduledFor: dateTime.toISOString(true) }))
    }
  }

  function handleDateTimeChange(newDateTime: Moment) {
    setDateTime(newDateTime)
    dispatch(
      blastScheduledChanged({
        scheduledFor: saveScheduledFor(
          newDateTime.toISOString(),
          blast.scheduleType,
          timezone
        ).toISOString(true)
      })
    )
  }

  function isDateDisabled(date: Moment): boolean {
    if (timezone === undefined) {
      return false
    }
    return date && date < momentTimeZone.tz(timezone).startOf('day')
  }

  function getDisabledHours(): number[] {
    if (dateTime === undefined || timezone === undefined) {
      return []
    }

    const hours: number[] = []
    if (dateTime > momentTimeZone.tz(timezone).endOf('day')) {
      return hours
    }

    for (let i = 0; i < momentTimeZone.tz(timezone).hour(); i++) {
      hours.push(i)
    }
    return hours
  }

  function getDisabledMinutes(): number[] {
    if (dateTime === undefined || timezone === undefined) {
      return []
    }

    const minutes: number[] = []
    if (
      dateTime > momentTimeZone.tz(timezone).endOf('day') ||
      momentTimeZone.tz(dateTime, timezone).get('hour') > momentTimeZone.tz(timezone).get('hour')
    ) {
      return minutes
    }
    for (let i = 0; i < momentTimeZone.tz(timezone).minute(); i++) {
      minutes.push(i)
    }
    return minutes
  }

  function getDisabledTime(current: momentTimeZone.Moment | undefined) {
    if (!current) {
      return {}
    }

    return {
      disabledHours: () => getDisabledHours(),
      disabledMinutes: () => getDisabledMinutes()
    }
  }

  function renderMultipleSegmentsSelection() {
    if (!segments || !allSubscribersCount) {
      return
    }

    return (
      <Form layout='vertical'>
        <SegmentSection
          segments={segments}
          blast={blast}
          allSubscribersCount={allSubscribersCount}
          targetType='includeSegmentIds'
          title='Send to'
          showToolTip={hasMMS && hasInternationalPhoneNumber}
        />
        <SegmentSection
          segments={segments}
          blast={blast}
          allSubscribersCount={allSubscribersCount}
          targetType='excludeSegmentIds'
          title="Don't send to"
        />
        <div className={cn(styles.targetSize, 'my-3')}>
          {isSubscribersCountLoading ? (
            <div className={cn('w-3/12 inline-block align-text-bottom', styles.loader)}>
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            </div>
          ) : (
            <span className='font-bold'>
              {formatters.number(targetSubscribersCount?.count ?? 0)}
            </span>
          )}{' '}
          <span>estimated subscriber{targetSubscribersCount?.count !== 1 && 's'}</span>
        </div>
      </Form>
    )
  }

  function renderScheduleTypeSelector(
    schedulingValue: SchedulingType,
    scheduleType: BlastScheduleType
  ) {
    return (
      <div className='mb-2'>
        <Select
          data-testid='select-schedule-type'
          disabled={schedulingValue === SchedulingType.NOW}
          onChange={handleScheduleTypeChange}
          size='large'
          value={scheduleType}
        >
          <Option key='schedule-type-site-timezone' value='siteTimezone'>
            In store's timezone
          </Option>
          <Option key='schedule-type-subscribers-timezone' value='subscriberTimezone'>
            In subscriber's timezone
          </Option>
        </Select>
      </div>
    )
  }

  if (!blast || timezone === undefined) {
    return null
  }

  let schedulingValue = SchedulingType.NOW
  if (blast.scheduledFor !== null) {
    schedulingValue = SchedulingType.LATER
  }

  return (
    <div data-testid='blast-settings-drawer'>
      <Title>Campaign settings</Title>
      <Label title='Campaign name'>
        <Input
          data-testid='sms-campaign-name'
          value={blast.name}
          onChange={handleNameChange}
          className={blastMeta?.name.error ? styles['bg-error'] : ''}
        />
        {blastMeta?.name.error && <div className={styles.error}>{blastMeta?.name.error}</div>}
      </Label>
      {isSMSFeatureEnabled && renderMultipleSegmentsSelection()}
      <Label title='Campaign scheduling'>
        <antd.Radio.Group value={schedulingValue} onChange={handleSchedulingTypeChange}>
          <Radio value={SchedulingType.NOW}>Start sending immediately</Radio>
          <Radio value={SchedulingType.LATER}>
            <Space size='small'>
              Start sending
              {blast.scheduleType === 'subscriberTimezone' && (
                <Tooltip placement='top' title={subscriberTimeZoneInfo} arrowPointAtCenter>
                  <div className={styles.drawerIcon}>
                    <InfoIcon />
                  </div>
                </Tooltip>
              )}
            </Space>
          </Radio>
        </antd.Radio.Group>
        <div className={styles.pickerContainer}>
          {renderScheduleTypeSelector(schedulingValue, blast.scheduleType)}
          <Space size='small'>
            <DatePicker
              format={dateFormatDatePicker}
              value={dateTime}
              disabled={schedulingValue === SchedulingType.NOW}
              onChange={handleDateTimeChange}
              disabledDate={isDateDisabled}
              allowClear={false}
              className={cn(styles.datePicker, {
                [styles['bg-error']]: blastMeta?.scheduledFor.error
              })}
            />
            <TimePicker
              inputReadOnly
              allowClear={false}
              format={timeFormat}
              value={dateTime}
              onChange={handleDateTimeChange}
              onSelect={handleDateTimeChange}
              disabled={schedulingValue === SchedulingType.NOW || isDateDisabled(dateTime)}
              disabledTime={getDisabledTime}
              className={cn(styles.timePicker, {
                [styles['bg-error']]: blastMeta?.scheduledFor.error
              })}
            />
          </Space>
          {blastMeta?.scheduledFor.error && (
            <div className={styles.error}>{blastMeta?.scheduledFor.error}</div>
          )}
          {schedulingValue === SchedulingType.LATER && attachedDiscountCodePools?.length > 0 && (
            <div className={styles.uniqueDiscountWarning}>
              <div className={styles.warningIcon}>
                <ExclamationIcon className='text-yellow-1 mr-2' />
              </div>
              <div>
                <p className='text-primary text-tiny mb-2 font-semibold'>Warning</p>
                <p className='text-primary text-tiny'>
                  Make sure you have enough discount codes in your pool to cover your target segment
                  at the moment of sending.{' '}
                  <a
                    href='https://help.recart.com/en/articles/5949003-how-to-add-unique-discount-codes-to-your-recart-text-messages#h_86307c570f'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Learn more
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </Label>
      <Label title='Sending options'>
        <Toggle
          title='Smart sending'
          subTitle={
            SMSSmartSendingSettings?.ttlHours >= 0 && `${SMSSmartSendingSettings?.ttlHours} hours `
          }
          tooltipText='Smart sending makes sure you do not send promotional text messages too often to your subscribers. These texts will not be rescheduled to send at a later time.'
          onChange={handleSmartSendingChange}
          checked={sequence.isSmartSendingEnabled}
          data-testid='smart-sending-toggle'
        />
        <Toggle
          title='Quiet hours'
          subTitle={
            isQuietHoursSet &&
            `From ${formatters.date(
              SMSQuietHoursSettings?.start,
              quietHoursTimeFormat
            )} to ${formatters.date(
              SMSQuietHoursSettings?.end,
              quietHoursTimeFormat
            )} in the subscriber’s time zone`
          }
          tooltipText='During quiet hours, subscribers are exempted from receiving promotional text messages. This setting only applies to the first message, follow-up messages will always be sent out once quiet hours are over.'
          onChange={handleQuietHoursChange}
          checked={blast.isQuietHoursEnabled}
          data-testid='quiet-hours-toggle'
        />
      </Label>
    </div>
  )
}
