import {
  Condition,
  ConditionSlug,
  DateFilter,
  DateFilterType,
  Subcondition,
  SubconditionSlug
} from '@ghostmonitor/recartapis'
import moment from 'moment'
import {
  BooleanConditionUI,
  ConditionUI,
  DateConditionUI,
  DateFilterUI,
  NumberConditionUI,
  StringConditionUI,
  SubconditionUI
} from '../../condition-ui.type'

function convertDateFilterAPIToUI(dateFilter: DateFilter): DateFilterUI {
  switch (dateFilter?.type) {
    case DateFilterType.All:
    case DateFilterType.Exist:
    case DateFilterType.NotExist:
      return {
        dateType: dateFilter.type
      }
    case DateFilterType.Between:
    case DateFilterType.NotBetween:
      return {
        dateType: dateFilter.type,
        dateRange: [moment.utc(dateFilter.dateFrom), moment.utc(dateFilter.dateTo)]
      }
    case DateFilterType.Since:
      return {
        dateType: dateFilter.type,
        date: moment.utc(dateFilter.dateFrom)
      }
    case DateFilterType.Until:
      return {
        dateType: dateFilter.type,
        date: moment.utc(dateFilter.dateTo)
      }
    case DateFilterType.On:
      return {
        dateType: dateFilter.type,
        date: moment.utc(dateFilter.dateFrom)
      }
    case DateFilterType.InTheLast:
    case DateFilterType.NotInTheLast:
      return {
        dateType: dateFilter.type,
        daysOffset: dateFilter.daysOffset
      }
  }
}

function convertSubconditionAPIToUI(subcondition: Subcondition): SubconditionUI {
  switch (subcondition?.slug) {
    case SubconditionSlug.SEQUENCE:
      return {
        subconditionSlug: subcondition.slug,
        subconditionCategory: subcondition.category,
        subconditionOperator: subcondition.operator,
        subconditionValue: subcondition.value
      }
    case SubconditionSlug.PRODUCT:
      return {
        subconditionSlug: subcondition.slug,
        subconditionCategory: subcondition.category,
        subconditionOperator: subcondition.operator,
        subconditionValue: subcondition.value
      }
    case SubconditionSlug.SUBSCRIPTION_SOURCE:
      if (['integration-event', 'external-id'].includes(subcondition.category)) {
        const values = subcondition.value as string[]

        return {
          subconditionSlug: subcondition.slug,
          subconditionCategory: subcondition.category,
          subconditionOperator: subcondition.operator,
          subconditionValue: values[0],
          subconditionSlugValue: values.slice(1)
        }
      } else {
        return {
          subconditionSlug: subcondition.slug,
          subconditionCategory: subcondition.category,
          subconditionOperator: subcondition.operator,
          subconditionValue: subcondition.value
        }
      }
    case SubconditionSlug.LOCATION_CATEGORY:
      return {
        subconditionSlug: subcondition.slug,
        subconditionCategory: subcondition.category,
        subconditionOperator: subcondition.operator,
        subconditionValue: subcondition.value
      }
  }
}

export function convertConditionAPIToUI(condition: Condition): ConditionUI {
  let conditionUI: ConditionUI

  switch (condition?.type) {
    case 'string':
      conditionUI = {
        slug: condition.slug,
        type: condition.type,
        operator: condition.operator,
        value: condition.value
      } as StringConditionUI
      break
    case 'number':
      conditionUI = {
        slug: condition.slug,
        type: condition.type,
        operator: condition.operator,
        value: condition.value
      } as NumberConditionUI
      break
    case 'boolean': {
      conditionUI = {
        slug: condition.slug,
        type: condition.type,
        operator: condition.operator,
        ...(condition.value && { value: condition.value })
      } as BooleanConditionUI
      break
    }
    case 'date':
      conditionUI = {
        slug: condition.slug,
        type: condition.type,
        operator: condition.operator,
        ...(condition.value && { value: condition.value })
      } as DateConditionUI
      break
  }

  const isShopifyTagCondition =
    condition?.slug === ConditionSlug.ORDER_TAG ||
    condition?.slug === ConditionSlug.SUBSCRIBER_SHOPIFY_TAG
  if (isShopifyTagCondition) {
    conditionUI.slug = ConditionSlug.SHOPIFY_TAG
    ;(conditionUI as BooleanConditionUI).shopifyTagType =
      condition.slug === ConditionSlug.ORDER_TAG ? 'order' : 'subscriber'
  }

  const isSubscriberDetailCondition = condition?.slug === ConditionSlug.SUBSCRIBER_DETAIL
  if (isSubscriberDetailCondition) {
    conditionUI.field = condition.field
  }

  conditionUI = {
    ...conditionUI,
    ...(condition && { ...convertDateFilterAPIToUI(condition.dateFilter) }),
    ...(condition.filters && { filters: { occurrence: condition.filters?.occurrence } }),
    ...(condition && { ...convertSubconditionAPIToUI(condition.subcondition) })
  }

  return conditionUI
}
