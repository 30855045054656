import { FacebookPage } from '@ghostmonitor/recartapis'
import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../redux-helpers'

export const authFacebookSuccess = createAction(
  'facebook/AUTH_FACEBOOK_SUCCESS',
  withPayloadType<{ userId: string; authorizationToken: string }>()
)
export const authFacebookFailure = createAction(
  'facebook/AUTH_FACEBOOK_FAILURE',
  withPayloadType<{ error: string }>()
)

export const clearFacebookAuth = createAction('facebook/CLEAR_FACEBOOK_AUTH')

export const connectFacebookPage = createAction('facebook/CONNECT_FACEBOOK_PAGE')
export const connectFacebookPageSuccess = createAction(
  'facebook/CONNECT_FACEBOOK_PAGE_SUCCESS',
  withPayloadType<{
    pageId: string
    userId: string
    name: string
    accessToken: string
  }>()
)
export const connectFacebookPageFailure = createAction(
  'facebook/CONNECT_FACEBOOK_PAGE_FAILURE',
  withPayloadType<{ error: string }>()
)

export const clearFacebookPages = createAction('facebook/CLEAR_FACEBOOK_PAGES')
export const clearFacebookPageConnect = createAction('facebook/CLEAR_FACEBOOK_PAGE_CONNECT')
export const clearFacebookPageConnectSuccess = createAction(
  'facebook/CLEAR_FACEBOOK_PAGE_CONNECT_SUCCESS'
)

export const loadFacebookPagesRequest = createAction('facebook/LOAD_FACEBOOK_PAGES_REQUEST')
export const loadFacebookPagesSuccess = createAction(
  'facebook/LOAD_FACEBOOK_PAGES_SUCCESS',
  withPayloadType<{
    pages: FacebookPage.Page[]
  }>()
)
export const loadFacebookPagesFailure = createAction(
  'facebook/LOAD_FACEBOOK_PAGES_FAILURE',
  withPayloadType<{ error: string }>()
)
