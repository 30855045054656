export interface VariableTypeOption {
  value: string
  label: string
  group?: string
}

export const urlVariableOption: VariableTypeOption = {
  value: 'url',
  label: 'Custom link'
}

export const productName: VariableTypeOption = {
  value: 'product.name',
  label: 'Product name'
}

export const productUrl: VariableTypeOption = {
  value: 'product.url',
  label: 'Product URL'
}

const pageNameVariableOption: VariableTypeOption = {
  value: 'page.name',
  label: 'Facebook Page name'
}

const siteNameVariableOption: VariableTypeOption = {
  value: 'site.name',
  label: 'Site name'
}

export const siteDomainVariableOption: VariableTypeOption = {
  value: 'site.domain',
  label: 'Site link'
}

export const cartUrlVariableOption: VariableTypeOption = {
  value: 'cart.url',
  label: 'Cart link'
}

export const fulfillmentUrlVariableOption: VariableTypeOption = {
  value: 'fulfillment.tracking_url',
  label: 'Fulfillment tracking link'
}

export const orderStatusUrlVariableOption: VariableTypeOption = {
  value: 'order.status_url',
  label: 'Order status link'
}

export const orderNumberVariableOption: VariableTypeOption = {
  value: 'order.number',
  label: 'Order number'
}

// note sms related name variables

export const subscriberFirstNameVariableOption: VariableTypeOption = {
  value: 'subscriber.first_name',
  label: 'First name'
}
export const subscriberLastNameVariableOption: VariableTypeOption = {
  value: 'subscriber.last_name',
  label: 'Last name'
}
export const subscriberNickNameVariableOption: VariableTypeOption = {
  value: 'subscriber.nick_name',
  label: 'Nick name'
}

// note messenger related name variables => to be deprecated

const firstNameVariableOption: VariableTypeOption = {
  value: 'first_name',
  label: 'First name'
}

const lastNameVariableOption: VariableTypeOption = {
  value: 'last_name',
  label: 'Last name'
}

export const supportEmailVariableOption: VariableTypeOption = {
  value: 'site.email',
  label: 'Support email'
}

export const restoreLink: VariableTypeOption = {
  value: 'subscriber.optin_source_url',
  label: 'Restore link'
}

export const unsubscribeLink: VariableTypeOption = {
  value: 'subscriber.unsubscribe_url',
  label: 'Unsubscribe link'
}

export const loopworkBillingAttemptErrorMessage: VariableTypeOption = {
  value: 'loopwork.billing_attempt_error_message',
  label: 'Billing attempt error message',
  group: 'Loop'
}

export const loopworkRetryDelayDays: VariableTypeOption = {
  value: 'loopwork.billing_retry_delay_days',
  label: 'Billing retry delay days',
  group: 'Loop'
}

export const loopworkFirstName: VariableTypeOption = {
  value: 'loopwork.first_name',
  label: 'First name',
  group: 'Loop'
}

export const loopworkLastName: VariableTypeOption = {
  value: 'loopwork.last_name',
  label: 'Last name',
  group: 'Loop'
}

export const loopworkProductNames: VariableTypeOption = {
  value: 'loopwork.product_names',
  label: 'Product names',
  group: 'Loop'
}

export const loopworkNextBillingDateDisplay: VariableTypeOption = {
  value: 'loopwork.next_billing_date_display',
  label: 'Next billing date',
  group: 'Loop'
}

export const loopworkSubscriptionShopifyId: VariableTypeOption = {
  value: 'loopwork.subscription_shopify_id',
  label: 'Subscription Shopify ID',
  group: 'Loop'
}

export const loopworkCustomerPortalLink: VariableTypeOption = {
  value: 'loopwork.customer_portal_link',
  label: 'Customer portal link',
  group: 'Loop'
}

export const loopworkQuickActionReactivateSubscription: VariableTypeOption = {
  value: 'loopwork.quick_action_reactivate_subscription',
  label: 'Quick action: Reactivate subscription',
  group: 'Loop'
}

export const loopworkQuickActionUpdatePaymentMethod: VariableTypeOption = {
  value: 'loopwork.quick_action_update_payment_method',
  label: 'Quick action: Update payment method',
  group: 'Loop'
}

export const loopworkQuickActionResumeSubscription: VariableTypeOption = {
  value: 'loopwork.quick_action_resume_subscription',
  label: 'Quick action: Resume subscription',
  group: 'Loop'
}

export const loopworkQuickActionAddProduct: VariableTypeOption = {
  value: 'loopwork.quick_action_add_product',
  label: 'Quick action: Add product',
  group: 'Loop'
}

export const rechargeFirstName: VariableTypeOption = {
  value: 'recharge.first_name',
  label: 'First name',
  group: 'Recharge'
}

export const rechargeLastName: VariableTypeOption = {
  value: 'recharge.last_name',
  label: 'Last name',
  group: 'Recharge'
}

export const rechargeProductNames: VariableTypeOption = {
  value: 'recharge.product_names',
  label: 'Product names',
  group: 'Recharge'
}

export const rechargeBillingAttemptErrorMessage: VariableTypeOption = {
  value: 'recharge.billing_attempt_error_message',
  label: 'Billing attempt error message',
  group: 'Recharge'
}

export const rechargeNextChargeScheduledAt: VariableTypeOption = {
  value: 'recharge.next_charge_scheduled_at',
  label: 'Next billing date',
  group: 'Recharge'
}

export const rechargeCustomerPortalLink: VariableTypeOption = {
  value: 'recharge.customer_portal_link',
  label: 'Customer portal link',
  group: 'Recharge'
}

export const rechargeOrderScheduledAt: VariableTypeOption = {
  value: 'recharge.order_scheduled_at',
  label: 'Next order scheduled at',
  group: 'Recharge'
}

export const wondermentTrackingCode: VariableTypeOption = {
  value: 'wonderment.tracking_code',
  label: 'Tracking code',
  group: 'Wonderment'
}

export const wondermentOrderNumber: VariableTypeOption = {
  value: 'wonderment.order_number',
  label: 'Shopify order number',
  group: 'Wonderment'
}

export const wondermentOrderID: VariableTypeOption = {
  value: 'wonderment.order_id',
  label: 'Shopify order ID',
  group: 'Wonderment'
}

export const wondermentCarrierName: VariableTypeOption = {
  value: 'wonderment.carrier_name',
  label: 'Carrier name',
  group: 'Wonderment'
}

export const wondermentProductNames: VariableTypeOption = {
  value: 'wonderment.product_names',
  label: 'Product names',
  group: 'Wonderment'
}

export const wondermentTrackingUrlOption: VariableTypeOption = {
  value: 'wonderment.tracking_url',
  label: 'Tracking URL',
  group: 'Wonderment'
}

export const wondermentWondermentTrackingUrlOption: VariableTypeOption = {
  value: 'wonderment.wonderment_tracking_url',
  label: 'Wonderment Tracking URL',
  group: 'Wonderment'
}

export const checkoutRecoveryLinkOption: VariableTypeOption = {
  value: 'checkout.recovery_link',
  label: 'Checkout recovery link',
  group: 'Checkout abandonment'
}

export const integrationUpdatePaymentMethodURL: VariableTypeOption = {
  value: 'integration.update_payment_method_url',
  label: 'Payment method update URL',
  group: 'integration'
}

export const yotpoPerkRewardPoints: VariableTypeOption = {
  value: 'yotpo.perk_reward_points',
  label: 'Perk reward points',
  group: 'Yotpo'
}

export const yotpoRedemptionRewardText: VariableTypeOption = {
  value: 'yotpo.redemption_reward_text',
  label: 'Redemption reward text',
  group: 'Yotpo'
}

export const yotpoRedemptionOptionName: VariableTypeOption = {
  value: 'yotpo.redemption_option_name',
  label: 'Redemption option name',
  group: 'Yotpo'
}

export const yotpoCurrentBalance: VariableTypeOption = {
  value: 'yotpo.current_balance',
  label: 'Current balance',
  group: 'Yotpo'
}

export const yotpoPointsNeeded: VariableTypeOption = {
  value: 'yotpo.points_needed',
  label: 'Points needed',
  group: 'Yotpo'
}

export const yotpoLoyaltyNextPointsExpireOn: VariableTypeOption = {
  value: 'yotpo.loyalty_next_points_expire_on',
  label: 'Next points expiry date',
  group: 'Yotpo'
}

export const yotpoLoyaltyNextPointsExpireAmount: VariableTypeOption = {
  value: 'yotpo.loyalty_next_points_expire_amount',
  label: 'Next points expiry amount',
  group: 'Yotpo'
}

export const yotpoNewTier: VariableTypeOption = {
  value: 'yotpo.new_tier',
  label: 'New tier',
  group: 'Yotpo'
}

export const yotpoOldTier: VariableTypeOption = {
  value: 'yotpo.old_tier',
  label: 'Old tier',
  group: 'Yotpo'
}

export const yotpoReviewLink: VariableTypeOption = {
  value: 'yotpo.review_link',
  label: 'Review link',
  group: 'Yotpo'
}

export const yotpoProductName: VariableTypeOption = {
  value: 'yotpo.product_name',
  label: 'Product name',
  group: 'Yotpo'
}

export const loyaltyLionNewTier: VariableTypeOption = {
  value: 'loyaltyLion.new_tier',
  label: 'New tier',
  group: 'Loyalty Lion'
}

export const loyaltyLionOldTier: VariableTypeOption = {
  value: 'loyaltyLion.old_tier',
  label: 'Old tier',
  group: 'Loyalty Lion'
}

export const loyaltyLionPointsNeeded: VariableTypeOption = {
  value: 'loyaltyLion.points_needed',
  label: 'Points needed',
  group: 'Loyalty Lion'
}

export const loyaltyLionPointsEarned: VariableTypeOption = {
  value: 'loyaltyLion.points_earned',
  label: 'Points earned',
  group: 'Loyalty Lion'
}

export const loyaltyLionCurrentBalance: VariableTypeOption = {
  value: 'loyaltyLion.current_balance',
  label: 'Current balance',
  group: 'Loyalty Lion'
}

export const loyaltyLionRewardName: VariableTypeOption = {
  value: 'loyaltyLion.reward_name',
  label: 'Reward name',
  group: 'Loyalty Lion'
}
export const boldFirstName: VariableTypeOption = {
  value: 'bold.first_name',
  label: 'First name',
  group: 'Bold'
}
export const boldLineItem: VariableTypeOption = {
  value: 'bold.line_item',
  label: 'Line item title',
  group: 'Bold'
}
export const boldNextOrderDate: VariableTypeOption = {
  value: 'bold.next_order_datetime',
  label: 'Next order date',
  group: 'Bold'
}
export const boldCustomerPortalLink: VariableTypeOption = {
  value: 'bold.customer_portal_link',
  label: 'Customer Portal Link',
  group: 'Bold'
}
export const boldFailureReason: VariableTypeOption = {
  value: 'bold.failure_reason',
  label: 'Failure Reason',
  group: 'Bold'
}

export const smsVariableTypeOptions: VariableTypeOption[] = [
  siteNameVariableOption,
  subscriberFirstNameVariableOption,
  subscriberLastNameVariableOption,
  subscriberNickNameVariableOption
]

export const smsDiscountVariableTypeOptions: VariableTypeOption[] = [siteNameVariableOption]
export const smsLinkVariableTypeOptions: VariableTypeOption[] = [
  urlVariableOption,
  siteDomainVariableOption,
  unsubscribeLink
]

export const messengerVariableTypeOptions: VariableTypeOption[] = [
  firstNameVariableOption,
  lastNameVariableOption,
  pageNameVariableOption,
  siteDomainVariableOption
]

export const variableOptionTypes: VariableTypeOption[] = [
  ...smsVariableTypeOptions,
  ...messengerVariableTypeOptions,
  cartUrlVariableOption,
  fulfillmentUrlVariableOption,
  loopworkBillingAttemptErrorMessage,
  loopworkCustomerPortalLink,
  loopworkFirstName,
  loopworkLastName,
  loopworkNextBillingDateDisplay,
  loopworkProductNames,
  loopworkRetryDelayDays,
  loopworkSubscriptionShopifyId,
  loopworkQuickActionReactivateSubscription,
  loopworkQuickActionUpdatePaymentMethod,
  loopworkQuickActionResumeSubscription,
  loopworkQuickActionAddProduct,
  orderStatusUrlVariableOption,
  orderNumberVariableOption,
  productName,
  productUrl,
  rechargeFirstName,
  rechargeLastName,
  rechargeProductNames,
  rechargeBillingAttemptErrorMessage,
  rechargeOrderScheduledAt,
  rechargeNextChargeScheduledAt,
  rechargeCustomerPortalLink,
  restoreLink,
  unsubscribeLink,
  urlVariableOption,
  wondermentCarrierName,
  wondermentOrderID,
  wondermentOrderNumber,
  wondermentProductNames,
  wondermentTrackingCode,
  wondermentTrackingUrlOption,
  wondermentWondermentTrackingUrlOption,
  checkoutRecoveryLinkOption,
  integrationUpdatePaymentMethodURL,
  yotpoPerkRewardPoints,
  yotpoRedemptionRewardText,
  yotpoRedemptionOptionName,
  yotpoCurrentBalance,
  yotpoPointsNeeded,
  yotpoLoyaltyNextPointsExpireOn,
  yotpoLoyaltyNextPointsExpireAmount,
  yotpoNewTier,
  yotpoOldTier,
  yotpoReviewLink,
  yotpoProductName,
  loyaltyLionNewTier,
  loyaltyLionOldTier,
  loyaltyLionPointsNeeded,
  loyaltyLionPointsEarned,
  loyaltyLionCurrentBalance,
  loyaltyLionRewardName,
  boldFirstName,
  boldLineItem,
  boldNextOrderDate,
  boldCustomerPortalLink,
  boldFailureReason
]

export const variableOptionNames = variableOptionTypes.map((option) => option.value)

export function getVariableLabelByVariableName(variableName: string): string {
  return variableOptionTypes.find((variable) => variable.value === variableName).label
}

export const compulsorySitenameVariableRTELength = 11
