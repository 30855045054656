import {
  ButtonActionType,
  ButtonUI,
  isPhoneNumberButton,
  isSequenceItemLinkButton,
  isWebUrlButton
} from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import { getDefaultButtonTitle } from '../../../../routes/SequenceEditor/defaults/button-title'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { UpdateButtonAction } from '../sequence-editor.actions'
import { selectEditorSequence, selectSequenceItem } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

const { info } = createScope('sequence-editor')
export function updateButtonActionHandler(state: SequenceEditorState, action: UpdateButtonAction) {
  const sequence = selectEditorSequence(state)
  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)

  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    let button: ButtonUI
    if (isMessageGenericGalleryTemplate(message) && action.messageItemIndex !== undefined) {
      button = message.messengerTemplatePayload[action.messageItemIndex].buttons[action.buttonIndex]
    } else if (hasMessengerButtonOnMessageType(message)) {
      button = message.messengerTemplatePayload.buttons[action.buttonIndex]
    } else {
      info('updateButtonActionHandler:1')
      return
    }

    const originalButtonType = button.type
    const originalDiscountCode = isWebUrlButton(button) ? button.discount_code : ''

    // On type change, reset optional fields
    if (action.button.type !== undefined && action.button.type !== originalButtonType) {
      if (isWebUrlButton(button)) {
        delete button.url
        delete button.discount_code
      }

      if (isPhoneNumberButton(button)) {
        delete button.phone_number
      }

      if (isSequenceItemLinkButton(button)) {
        delete button.trigger
      }

      // Reset title only if button still has the default title
      const defaultTitle = getDefaultButtonTitle(button.type)
      if (button.title === defaultTitle) {
        button.title = getDefaultButtonTitle(action.button.type)
      }
      button.type = action.button.type

      if (isSequenceItemLinkButton(button)) {
        button.trigger = null
      }
    } else {
      merge(button, action.button)
      if (
        action.isShopify &&
        isWebUrlButton(button) &&
        (action.button as any).discount_code !== undefined
      ) {
        const { code: discountCode } = JSON.parse((action.button as any).discount_code)
        button.discount_code = discountCode
      }
    }

    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, action.sequenceItemId)

    // Remove links if button type changes
    if (action.button.type !== undefined && action.button.type !== originalButtonType) {
      if (action.button.type === ButtonActionType.postback) {
        node.createButtonPort(action.messageIndex, action.buttonIndex, action.messageItemIndex)
      } else if (originalButtonType === ButtonActionType.postback) {
        node.removeButtonPort(action.messageIndex, action.buttonIndex, action.messageItemIndex)
      }
    }

    sequence.serializedDiagram = diagramModel.serializeDiagram() as any
  }
}
