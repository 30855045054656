export interface UploadAttachmentResponse {
  attachmentId: string
}

export async function uploadFacebookAttachment(
  url: string,
  pageAuthToken: string,
  mediaType = 'image'
): Promise<UploadAttachmentResponse> {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const raw = JSON.stringify({
    message: {
      attachment: {
        type: mediaType,
        payload: {
          is_reusable: true,
          url
        }
      }
    }
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow' as RequestRedirect
  }

  const response = await fetch(
    `https://graph.facebook.com/v18.0/me/message_attachments?access_token=${pageAuthToken}`,
    requestOptions
  )
  const body = await response.json()
  return {
    attachmentId: body.attachment_id
  }
}
