import { SequenceUI } from '@ghostmonitor/recartapis'
import {
  messengerWelcomePlaceholderSequences,
  smsWelcomePlaceholderSequences
} from '../routes/sequences/components/welcome-flows/custom-welcome-flow-placeholders'
import { isSequenceMatchPlaceholder } from './is-sequence-match-placeholder'

export function isBuiltInWelcomeSequence(sequence: SequenceUI): boolean {
  const placeholders = [...smsWelcomePlaceholderSequences, ...messengerWelcomePlaceholderSequences]
  return placeholders.some((placeholder) => isSequenceMatchPlaceholder(sequence, placeholder))
}
