import { Stat } from '@ghostmonitor/recartapis'
import { hooks } from '../hooks'
import { UseResource } from '../types/use-resource.type'

export function useSequenceStat(sequenceId?: string): UseResource<Stat> {
  const { data: sequencesStat, isLoading } = hooks.useSequencesStat()

  return {
    data: sequencesStat?.[sequenceId],
    isLoading
  }
}
