import { TargetRulesBlast } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { SubscribersCount, SubscribersCountData } from '../../types/subscribers-count.type'
import { request } from '../../utils/request'
import { UseResource } from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'

export function useSubscribersCount(
  channel: string,
  targetRules?: TargetRulesBlast,
  isEnabled = true
): UseResource<SubscribersCountData> & { isLoading: boolean } {
  const queryName = [QUERY_NAME.subscribersCount, channel, targetRules]

  const {
    isInitialLoading,
    isError,
    data: subscribersCount
  } = useQuery<SubscribersCount>(
    queryName,
    async () => {
      return request.get(`/subscribers/sms-subscribers/count`, {
        params: {
          ...(targetRules?.includeSegmentIds && {
            includeSegmentIds: targetRules.includeSegmentIds
          }),
          ...(targetRules?.excludeSegmentIds && {
            excludeSegmentIds: targetRules.excludeSegmentIds
          })
        }
      })
    },
    { enabled: isEnabled }
  )

  return {
    data: subscribersCount?.data,
    isError,
    isLoading: isInitialLoading
  }
}
