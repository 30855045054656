// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dAsviMHAQZQy6Gar92hZ{color:#476dff;cursor:pointer}.t3xACCoOh8ICphW60fwW{color:#ff7a45;cursor:pointer}.CG9_hRdvZHpYB1WN8fRg{color:#000}.ba22i8z_ixxbpOh9EmdZ{background-color:#ff7a45;border-radius:50%;color:#fff;display:inline-block;font-size:10px;height:12px;line-height:12px;margin-left:3px;text-align:center;vertical-align:middle;width:12px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-link-shortening/short-link-variable.style.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBACE,aCDY,CDEZ,cAAA,CAGF,sBACE,aCiCO,CDhCP,cAAA,CAGF,sBACE,UAAA,CAGF,sBAKE,wBCoBO,CDrBP,iBAAA,CAEA,UAAA,CALA,oBAAA,CASA,cAAA,CAPA,WAAA,CAQA,gBAAA,CAJA,eAAA,CACA,iBAAA,CACA,qBAAA,CAPA,UASA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sequenceVariable": `dAsviMHAQZQy6Gar92hZ`,
	"customSequenceVariable": `t3xACCoOh8ICphW60fwW`,
	"ignoredSequenceVariable": `CG9_hRdvZHpYB1WN8fRg`,
	"questionMark": `ba22i8z_ixxbpOh9EmdZ`
};
export default ___CSS_LOADER_EXPORT___;
