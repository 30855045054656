import React from 'react'

interface VideoProps {
  input: any
  loadFinished: VoidFunction
}

export function Video(props: VideoProps) {
  return (
    <div className='file-uploader__preview-video'>
      <video
        preload='metadata'
        src={encodeURI(props.input.value)}
        onLoadStart={props.loadFinished}
      />
    </div>
  )
}
