import React from 'react'
import { useSelector } from 'react-redux'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { selectEditorSequenceMeta, selectErrorCount } from '../../../../../store/selectors'
import { useSaveSequenceEditor } from '../../../hooks/use-save-sequence'

export function BlastHeaderSaveAsDraftButton() {
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const errorCount = useSelector(selectErrorCount)

  const { saveSequenceEditor } = useSaveSequenceEditor()

  async function handleSave() {
    try {
      await saveSequenceEditor()
    } catch {}
  }

  return (
    <div className='flex items-center'>
      <EditorButton
        disabled={errorCount > 0 || !sequenceMeta.unsavedChanges || sequenceMeta.saving}
        onClick={handleSave}
        type='default'
        data-testid='header-save-as-draft-button'
      >
        Save as draft
      </EditorButton>
    </div>
  )
}
