import { FlowAPI, SMSCampaign } from '@ghostmonitor/recartapis'

export function checkSMSCampaignFlowIntegrity(flow: FlowAPI, smsCampaign: SMSCampaign): void {
  // Flows and the corresponting smsCampaigns are loaded async, and there can be some seconds
  // when we already run integrity chcker, but the smsCampaign is not loaded yet
  if (smsCampaign === undefined) {
    return
  }

  if (flow.siteId !== smsCampaign.siteId) {
    throw new Error(
      `the siteId on the flow ${flow.siteId} should be the same as the siteId ${smsCampaign.siteId} on the smsCampaign `
    )
  }

  if (smsCampaign.scheduledFor === null && smsCampaign.scheduleType === 'subscriberTimezone') {
    throw new Error(`if campaign is scheduledForNow the scheduleType can not be subscriberTimezone`)
  }

  if (flow.sendingFrequency !== undefined) {
    throw new Error(
      'sendingFrequency is defined on flow, but it only should be defined on Custom Trigger flow'
    )
  }

  if (flow.isSmartSendingEnabled === undefined) {
    throw new Error(`isSmartSendingEnabled should be defined for all campaigns`)
  }

  if (flow.isQuietHoursEnabled === undefined) {
    throw new Error(`isQuietHoursEnabled should be defined for all campaigns`)
  }
}
