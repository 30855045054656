import React, { useState } from 'react'
import styles from './sequence-editor-overlay.component.scss'

let WrappedContent: React.ComponentType<any> | null = null
let toggle: (show: boolean) => void
let setTopPadding: (top: number) => void

export function showOverlay(_wrappedContent: React.ComponentType<any>, top = 0) {
  WrappedContent = _wrappedContent
  setTopPadding(top)
  toggle(true)
}

export function hideOverlay() {
  WrappedContent = null
  toggle(false)
}

export function SequenceEditorOverlay() {
  const [show, _setShow] = useState(false)
  const [top, _setTop] = useState(0)

  toggle = _setShow
  setTopPadding = _setTop

  return show ? (
    <div className={styles.overlay} style={{ top: `${top}px` }} onClick={() => _setShow(false)}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <WrappedContent />
      </div>
    </div>
  ) : null
}
