import { AttachmentPayload, AttachmentType } from '@ghostmonitor/recartapis'
import cn from 'classnames'
import React from 'react'
import {
  MessageMeta,
  SequenceItemMeta
} from '../../../../../store/slices/sequence-editor/sequence-editor.state'
import { useSequenceEditorSettings } from '../../../hooks/use-sequence-editor-settings'
import { getErrorMessage } from '../../../types/sequence-editor-errors'
import { isAnyButtonSelected } from '../../../utils/is-any-button-selected'
import { FileEditor } from '../../file-editor/file-editor.component'
import {
  InteractiveComponentProps,
  makeInteractive
} from '../../interactive-component/make-interactive.hoc'
import classNames from './attachment-template.component.scss'

interface AttachmentTemplateProps {
  attachmentType: AttachmentType
  attachmentPayload: AttachmentPayload
  onMessageChange: (change: any) => void
  onMessageFocus: () => void
  onMessageBlur: () => void
  onRemove?: () => void
  messageMeta: MessageMeta
  sequenceItemMeta: SequenceItemMeta
  index?: number
  siteId: string
}

const DragHandle = ({ attachmentType, props }) => (
  <div className={classNames.container}>
    {{
      image: renderImageAttachment,
      video: renderVideoAttachment,
      audio: renderAudioAttachment,
      file: renderFileAttachment
    }[attachmentType](props)}
  </div>
)

const InteractiveAttachment = makeInteractive<AttachmentTemplateProps & InteractiveComponentProps>(
  (props) => {
    return (
      <div
        className={cn(classNames['attachment-container'], {
          [classNames.blurred]: props.isBlurred
        })}
      >
        <DragHandle attachmentType={props.attachmentType} props={{ ...props }} />
      </div>
    )
  }
)

export function AttachmentTemplate(props: AttachmentTemplateProps) {
  const isDragAndDropEnabled = useSequenceEditorSettings().dragAndDrop

  return (
    <div onMouseDown={(e) => e.stopPropagation()}>
      <InteractiveAttachment
        attachmentPayload={props.attachmentPayload}
        attachmentType={props.attachmentType}
        onMessageChange={props.onMessageChange}
        onMessageFocus={props.onMessageFocus}
        onMessageBlur={props.onMessageBlur}
        messageMeta={props.messageMeta}
        sequenceItemMeta={props.sequenceItemMeta}
        errorMessage={getErrorMessage(props.messageMeta.errors[0])}
        isSelected={props.messageMeta.selected && !isAnyButtonSelected(props.messageMeta)}
        isBlurred={
          (props.sequenceItemMeta.selected && !props.messageMeta.selected) ||
          (props.messageMeta.selected && isAnyButtonSelected(props.messageMeta))
        }
        onRemoveElement={props.onRemove}
        isFirst
        isLast
        isRounded
        hasAddButton={false}
        index={props.index}
        isDraggable={isDragAndDropEnabled}
        siteId={props.siteId}
      />
    </div>
  )
}

function renderImageAttachment(props: AttachmentTemplateProps) {
  return (
    <FileEditor
      contentEditable
      attachmentType='image'
      input={{ name: '', value: props.attachmentPayload.url }}
      change={(value) => props.onMessageChange({ url: value })}
      messageMeta={props.messageMeta}
      siteId={props.siteId}
    />
  )
}

function renderVideoAttachment(props: AttachmentTemplateProps) {
  return (
    <FileEditor
      contentEditable
      attachmentType='video'
      input={{ name: '', value: props.attachmentPayload.url }}
      change={(value) => props.onMessageChange({ url: value })}
      messageMeta={props.messageMeta}
      siteId={props.siteId}
    />
  )
}

function renderAudioAttachment(props: AttachmentTemplateProps) {
  return (
    <FileEditor
      contentEditable
      attachmentType='audio'
      input={{ name: '', value: props.attachmentPayload.url }}
      change={(value) => props.onMessageChange({ url: value })}
      siteId={props.siteId}
    />
  )
}

function renderFileAttachment(props: AttachmentTemplateProps) {
  return (
    <FileEditor
      contentEditable
      attachmentType='file'
      input={{ name: '', value: props.attachmentPayload.url }}
      change={(value) => props.onMessageChange({ url: value })}
      siteId={props.siteId}
    />
  )
}
