import React, { useEffect } from 'react'
import { ReactComponent as FileIcon } from '../../../../../static/images/svg/icon-file.svg'

interface FileProps {
  input: any
  loadFinished: VoidFunction
}

export function File(props: FileProps) {
  useEffect(() => {
    props.loadFinished()
  }, [])

  function getFilename(fileurl) {
    const parts = fileurl.split('/')
    return parts.pop()
  }

  return (
    <div className='file-uploader__preview-file'>
      <FileIcon className='file-uploader__preview-file-icon' />
      {getFilename(props.input.value)}
    </div>
  )
}
