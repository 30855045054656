import {
  CUSTOM_INTEGRATION_CATEGORY_PREFIX,
  CUSTOM_INTEGRATION_SLUG_PREFIX,
  NodeType
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  isJoinedASegmentFlowUI,
  isWelcomeCustomIntegrationFlowUI
} from '../../../../types/guards/flow-ui.guards'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  FlowEditorValidationError
} from '../../../../utils/flow-editor/flow-editor-errors'
import { serializeError } from '../../../../utils/serialize-error'
import { flowEditorSelectors } from '../../../selectors'
import { ThunkAPI } from '../../../types/thunk-api.type'
import { flowValidationFailed, flowValidationSucceeded } from '../flow-editor.actions'
import { getLoopEdge } from '../helpers/get-loop-edge'
import { validateFlowItemThunk, ValidationThunkResult } from './validate-flow-item.thunk'

type ValidatorValue = 'onlyIfValidationSucceeded' | boolean

export interface ValidateFlowThunkBypassWarnings {
  bypassWarnings?: {
    randomSplitHasDisconnectedSplits?: boolean
    isScheduledInQuietHours?: boolean
  }
}

interface ValidateFlowThunkArgs extends ValidateFlowThunkBypassWarnings {
  infiniteLoop?: boolean
  triggerMustHaveConnection?: ValidatorValue
  logicFlowItemConnections?: ValidatorValue
  flowItemContent?: ValidatorValue
  joinedASegmentMustHaveSegment?: ValidatorValue
  conditionExpression?: ValidatorValue
  conditionalSplitV1Deprecated?: ValidatorValue
  scheduledDelay?: ValidatorValue
  customIntegrationMustHaveCategory?: ValidatorValue
  customIntegrationMustHaveEvent?: ValidatorValue
  keywordReplyConnections?: ValidatorValue
}

export const validateFlowThunk = createAsyncThunk<unknown, ValidateFlowThunkArgs>(
  'flowEditor/validateFlow',
  async (args: ValidateFlowThunkArgs, thunkAPI: any) => {
    const { dispatch, getState }: ThunkAPI = thunkAPI
    const state = getState()

    const validators: Required<ValidateFlowThunkArgs> = {
      infiniteLoop: false,
      flowItemContent: false,
      logicFlowItemConnections: false,
      triggerMustHaveConnection: false,
      joinedASegmentMustHaveSegment: false,
      conditionExpression: false,
      conditionalSplitV1Deprecated: false,
      scheduledDelay: false,
      customIntegrationMustHaveCategory: false,
      customIntegrationMustHaveEvent: false,
      bypassWarnings: {
        randomSplitHasDisconnectedSplits: false
      },
      keywordReplyConnections: false,
      ...args
    }

    const flow = flowEditorSelectors.selectEditorFlow(state)

    if (!flow) {
      throw new Error(FlowEditorError.FlowIsNotLoaded)
    }

    const flowValidationErrors: (FlowEditorValidationError & {
      nodeId: string
      edgeId?: string
    })[] = []
    const triggerNode = flow.flowEditorDiagram.nodes.find((node) => node.type === NodeType.TRIGGER)
    if (!triggerNode) {
      throw new Error(FlowEditorError.TriggerNodeNotFound)
    }

    let hasError = false

    /***************************
     * Trigger validations
     ***************************/
    if (validators.triggerMustHaveConnection) {
      const edgeFromTrigger = flow.flowEditorDiagram.edges.find(
        (edge) => edge.source === triggerNode.id
      )

      if (!edgeFromTrigger) {
        flowValidationErrors.push({
          message: FlowEditorError.TriggerMustHaveConnection,
          nodeId: triggerNode.id,
          level: FlowEditorErrorLevel.Error
        })
      }
    }

    /***************************
     * Infinite loop validation
     ***************************/
    if (validators.infiniteLoop) {
      const infiniteLoopEdge = getLoopEdge(flow.flowEditorDiagram)
      if (infiniteLoopEdge) {
        flowValidationErrors.push({
          message: FlowEditorError.FlowHasInfiniteLoop,
          nodeId: infiniteLoopEdge.source,
          edgeId: infiniteLoopEdge.id,
          level: FlowEditorErrorLevel.Error
        })
      }
    }

    /***************************
     * JAS Settings validation
     ***************************/
    if (validators.joinedASegmentMustHaveSegment && isJoinedASegmentFlowUI(flow)) {
      if (flow.segmentId === undefined) {
        flowValidationErrors.push({
          message: FlowEditorError.JoinedASegmentFlowMissingSegment,
          nodeId: triggerNode.id,
          level: FlowEditorErrorLevel.Error
        })
      }
    }
    /***************************
     * Custom Integration Settings validation
     ***************************/
    if (
      (validators.customIntegrationMustHaveCategory || validators.customIntegrationMustHaveEvent) &&
      isWelcomeCustomIntegrationFlowUI(flow)
    ) {
      if (
        validators.customIntegrationMustHaveCategory &&
        !flow.tags.some((tag) => tag.includes(CUSTOM_INTEGRATION_CATEGORY_PREFIX))
      ) {
        flowValidationErrors.push({
          message: FlowEditorError.CustomIntegrationMissingCategory,
          nodeId: triggerNode.id,
          level: FlowEditorErrorLevel.Error
        })
      }

      if (
        validators.customIntegrationMustHaveEvent &&
        !flow.tags.some((tag) => tag.includes(CUSTOM_INTEGRATION_SLUG_PREFIX))
      ) {
        flowValidationErrors.push({
          message: FlowEditorError.CustomIntegrationMissingEvent,
          nodeId: triggerNode.id,
          level: FlowEditorErrorLevel.Error
        })
      }
    }

    if (flowValidationErrors.length) {
      hasError = true
      dispatch(flowValidationFailed({ errors: flowValidationErrors }))
    } else {
      dispatch(flowValidationSucceeded())
    }

    if (hasError) {
      const isError = flowValidationErrors.some(
        (error) => error.level === FlowEditorErrorLevel.Error
      )
      const isWarning =
        !isError &&
        flowValidationErrors.some((error) => error.level === FlowEditorErrorLevel.Warning)

      throw new Error(FlowEditorError.FlowValidationFailed, {
        cause: { isError, isWarning }
      })
    }

    /***************************
     * Flow item validation
     ***************************/
    if (
      validators.flowItemContent ||
      validators.logicFlowItemConnections ||
      validators.conditionExpression ||
      validators.conditionalSplitV1Deprecated ||
      validators.scheduledDelay ||
      validators.keywordReplyConnections
    ) {
      const results: ValidationThunkResult[] = (await Promise.all(
        flow.sequenceItemIds.map(async (flowItemId) => {
          return dispatch(
            validateFlowItemThunk({
              flowItemId,
              validators: {
                flowItemContent: validators.flowItemContent,
                logicFlowItemConnections: validators.logicFlowItemConnections,
                conditionExpression: validators.conditionExpression,
                conditionalSplitV1Deprecated: validators.conditionalSplitV1Deprecated,
                scheduledDelay: validators.scheduledDelay,
                keywordReplyConnections: validators.keywordReplyConnections
              },
              bypassWarnings: validators.bypassWarnings
            })
          )
        })
      )) as any

      if (results.some((result) => result.hasError)) {
        throw new Error(FlowEditorError.FlowItemValidationFailed, {
          cause: { isError: true }
        })
      }

      if (results.some((result) => result.hasWarning)) {
        throw new Error(FlowEditorError.FlowItemValidationFailed, {
          cause: { isWarning: true }
        })
      }
    }
  },
  { serializeError }
)
