import {
  ButtonActionType,
  ButtonUI,
  LinkingMeta,
  MessageChange,
  MessengerMessageUI,
  MessengerTemplateType
} from '@ghostmonitor/recartapis'
import React from 'react'
import {
  MessageMeta,
  SequenceItemMeta
} from '../../../../store/slices/sequence-editor/sequence-editor.state'
import { MessageSequenceItemNodeModel } from '../../models/sequence-item/message/message-sequence-item-model'
import { isMessageGenericGalleryTemplate } from '../../utils/assert-message-template-type'
import { AttachmentTemplate } from '../message-templates/attachment-template/attachment-template.component'
import { MessengerButtonTemplate } from '../message-templates/messenger-button-template/messenger-button-template.component'
import { MessengerGenericTemplate } from '../message-templates/messenger-generic-template/messenger-generic-template.component'
import { MessengerMediaTemplate } from '../message-templates/messenger-media-template/messenger-media-template.component'
import { MessengerReceiptTemplate } from '../message-templates/messenger-receipt-template/messenger-receipt-template.component'
import { MessengerReviewTemplate } from '../message-templates/messenger-review-template/messenger-review-template.component'
import { MessengerTemplateProps } from '../message-templates/messenger-template-props.type'
import { buttonTypeOptions } from '../messenger-button/button-type-options'
import { ButtonTypeOption } from '../messenger-button/types/button-type-option.type'
import styles from './message.component.scss'

interface MessageComponentProps {
  index: number
  key: string
  message: MessengerMessageUI
  messageMeta: MessageMeta
  sequenceItemMeta: SequenceItemMeta
  onMessageChange: (messageChange: MessageChange, messageItemIndex?: number) => void
  onMessageFocus: (messageItemIndex?: number) => void
  onMessageBlur: () => void
  onButtonChange: (
    buttonIndex: number,
    change: Partial<ButtonUI>,
    messageItemIndex?: number
  ) => void
  onButtonFocus: (buttonIndex: number, messageItemIndex?: number) => void
  onButtonBlur: () => void
  onRemove: () => void
  onAddMessageItem: (indexToPlace: number, jumpToIndex: (index: number) => void) => void
  onRemoveMessageItem: (messageItemIndex: number) => void
  onAddButton: (index: number, messageItemIndex?: number) => void
  onRemoveButton: (index: number, messageItemIndex?: number) => void
  clearSelection: () => void
  sequenceItemNodeModel: MessageSequenceItemNodeModel
  messageIndex: number
  linking: boolean
  linkingMeta: LinkingMeta
  isFulfillmentEntry: boolean
  isReviewEntry: boolean
  isAbandonmentSequence: boolean
  hasVariable?: boolean
  sequenceItemId: string
  siteId: string
}

export function MessageComponent(props: MessageComponentProps) {
  function renderMessengerTemplate() {
    const templateComponents = {
      [MessengerTemplateType.BUTTON]: MessengerButtonTemplate,
      [MessengerTemplateType.GENERIC]: MessengerGenericTemplate,
      [MessengerTemplateType.RECEIPT]: MessengerReceiptTemplate,
      [MessengerTemplateType.REVIEW]: MessengerReviewTemplate,
      [MessengerTemplateType.MEDIA]: MessengerMediaTemplate
    }

    const TemplateComponent: React.FC<MessengerTemplateProps> =
      templateComponents[props.message.messengerTemplateType]

    const templateButtonTypeOptions: ButtonTypeOption[] = [
      buttonTypeOptions[ButtonActionType.web_url],
      buttonTypeOptions[ButtonActionType.phone_number]
    ]

    // Linking from buttom
    if (
      props.message.messengerTemplateType &&
      [
        MessengerTemplateType.BUTTON,
        MessengerTemplateType.GENERIC,
        MessengerTemplateType.MEDIA
      ].includes(props.message.messengerTemplateType) &&
      !isMessageGenericGalleryTemplate(props.message)
    ) {
      templateButtonTypeOptions.push(buttonTypeOptions[ButtonActionType.postback])
    }

    // Shipment tracking
    if (props.isFulfillmentEntry) {
      templateButtonTypeOptions.push(buttonTypeOptions[ButtonActionType.shipment_tracking_url])
    }

    if (props.isReviewEntry) {
      templateButtonTypeOptions.push(buttonTypeOptions[ButtonActionType.review_url])
    }

    if (props.isAbandonmentSequence) {
      templateButtonTypeOptions.push(buttonTypeOptions[ButtonActionType.cart_url])
    }

    return (
      <div className={styles['message-item']} key={props.key}>
        <TemplateComponent
          sequenceItemId={props.sequenceItemId}
          messengerTemplatePayload={props.message.messengerTemplatePayload}
          messageIndex={props.messageIndex}
          messageMeta={props.messageMeta}
          messageType={props.message.type}
          sequenceItemMeta={props.sequenceItemMeta}
          linking={props.linking}
          linkingMeta={props.linkingMeta}
          sequenceItemNodeModel={props.sequenceItemNodeModel}
          buttonTypeOptions={templateButtonTypeOptions}
          onMessageChange={props.onMessageChange}
          onMessageFocus={props.onMessageFocus}
          onMessageBlur={props.onMessageBlur}
          onButtonChange={props.onButtonChange}
          onButtonFocus={props.onButtonFocus}
          onButtonBlur={props.onButtonBlur}
          onRemove={props.onRemove}
          onAddMessageItem={props.onAddMessageItem}
          onRemoveMessageItem={props.onRemoveMessageItem}
          onAddButton={props.onAddButton}
          onRemoveButton={props.onRemoveButton}
          clearSelection={props.clearSelection}
          hasVariable={props.hasVariable}
          siteId={props.siteId}
        />
      </div>
    )
  }

  function renderAttachment() {
    return (
      <div key={props.key}>
        <AttachmentTemplate
          attachmentType={props.message.attachmentType}
          attachmentPayload={props.message.attachmentPayload}
          messageMeta={props.messageMeta}
          sequenceItemMeta={props.sequenceItemMeta}
          onMessageChange={props.onMessageChange}
          onMessageFocus={props.onMessageFocus}
          onMessageBlur={props.onMessageBlur}
          onRemove={props.onRemove}
          index={props.index}
          siteId={props.siteId}
        />
      </div>
    )
  }

  if (props.message.messengerTemplateType) {
    return renderMessengerTemplate()
  }

  if (props.message.attachmentType) {
    return renderAttachment()
  }

  throw new Error(`Cannot render message: ${JSON.stringify(props.message)}`)
}
