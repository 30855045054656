// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KYlyE_B8vB3T9gb9QQ7U{font-size:46px}.ZgFOVBrsanQBhw8h51P_{position:relative}.ZgFOVBrsanQBhw8h51P_ .video-icon{background-color:rgba(0,0,0,.6);border-radius:50%;height:auto;left:116px;padding:5px 5px 0;position:absolute;top:52px;width:45px;z-index:1}.ZgFOVBrsanQBhw8h51P_ .video-icon circle,.ZgFOVBrsanQBhw8h51P_ .video-icon line,.ZgFOVBrsanQBhw8h51P_ .video-icon path{color:#fff}.file-uploader__preview-file{padding:10px 15px}.file-uploader-hover{align-items:center;border:1px solid rgba(56,163,236,.85);border-top-left-radius:22px;border-top-right-radius:22px}.file-uploader-hover__container{align-items:center;display:flex;margin:0 auto;max-height:100%}.file-uploader-hover__icon,.file-uploader-hover__icon svg{height:50px;width:65px}.file-uploader-hover__text{align-self:center;margin-top:0}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/facebook-file-editor/facebook-file-editor.component.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CAGF,sBACE,iBAAA,CAEA,kCAOE,+BAAA,CAEA,iBAAA,CAPA,WAAA,CAEA,UAAA,CAIA,iBAAA,CALA,iBAAA,CAEA,QAAA,CAJA,UAAA,CAKA,SAGA,CAEA,uHAGE,UAAA,CAKN,6BACE,iBAAA,CAGF,qBAIE,kBAAA,CAHA,qCAAA,CACA,2BAAA,CACA,4BACA,CAGF,gCAGE,kBAAA,CADA,YAAA,CADA,aAAA,CAGA,eAAA,CAGF,0DAEE,WAAA,CACA,UAAA,CAGF,2BAEE,iBAAA,CADA,YACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageItemIndex": `KYlyE_B8vB3T9gb9QQ7U`,
	"file-editor": `ZgFOVBrsanQBhw8h51P_`
};
export default ___CSS_LOADER_EXPORT___;
