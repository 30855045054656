import { DelayType } from '@ghostmonitor/recartapis'
import { AnyAction, isAnyOf, Store } from '@reduxjs/toolkit'
import debounce from 'lodash/debounce'
import { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { flowEditorSelectors } from '../../../../store/selectors'
import {
  flowItemValidationFailed,
  flowItemValidationSucceeded
} from '../../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../../store/slices/flow-editor/flow-editor.reducer'
import { isDelayFlowItemUI } from '../../../../types/guards/flow-item-ui.guards'
import {
  FlowEditorError,
  FlowEditorErrorLevel,
  FlowEditorValidationError
} from '../../../../utils/flow-editor/flow-editor-errors'
import {
  validateDelay,
  validateScheduledDelay
} from '../../../../utils/validators/flow/validate-delay'

const DEBOUNCE_DELAY = 500

function getValidateDelayFlowItem(dispatch: AppDispatch) {
  return debounce(
    ({ flowItem, flow, smsCampaignScheduledFor }) => {
      if (isDelayFlowItemUI(flowItem)) {
        let error: FlowEditorValidationError | undefined

        if (flowItem.item.logic.delay.type === DelayType.delayScheduled) {
          if (flowItem.item.logic.delay.scheduled_date === undefined) {
            error = {
              level: FlowEditorErrorLevel.Error,
              message: FlowEditorError.ScheduledDelayDateIsRequired
            }
          } else {
            error = validateScheduledDelay(flowItem, flow, smsCampaignScheduledFor)
          }
        }

        if (flowItem.item.logic.delay.type === DelayType.delay) {
          error = validateDelay(flowItem.item.logic.delay.delay_duration)
        }
        if (error) {
          dispatch(
            flowItemValidationFailed({
              flowItemId: flowItem._id,
              errors: [error]
            })
          )
        } else {
          dispatch(
            flowItemValidationSucceeded({
              flowItemId: flowItem._id
            })
          )
        }
      }
    },
    DEBOUNCE_DELAY,
    { leading: false, trailing: true }
  )
}

export const getValidateDelayFlowItemChangedAction = (store: Store<DashboardState>) => {
  const dispatch: AppDispatch = store.dispatch
  const validateDelayItem = getValidateDelayFlowItem(dispatch)

  return (store: Store<DashboardState>, action: AnyAction) => {
    if (isAnyOf(flowEditorActions.delayItemChanged)(action)) {
      const state = store.getState()
      const flowItem = flowEditorSelectors.selectFlowItem(action.payload.flowItemId)(state)
      const smsCampaign = flowEditorSelectors.selectEditorSMSCampaign(state)
      const flow = flowEditorSelectors.selectEditorFlow(state)
      validateDelayItem({ flowItem, flow, smsCampaignScheduledFor: smsCampaign?.scheduledFor })
    }

    if (isAnyOf(flowEditorActions.flowUnloaded)(action)) {
      validateDelayItem.cancel()
    }
  }
}
