// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ap8Gfyg5rLJSlJRkaf8x{display:flex;flex-direction:column;width:220px}.Ap8Gfyg5rLJSlJRkaf8x .ant-btn{margin-top:10px;width:100%}.K9jCswIdnVUtFyACyT01{display:flex;flex-direction:row;justify-content:space-between}.K9jCswIdnVUtFyACyT01 .jzpIIv_PzUgjilphc4wp{font-weight:700;margin-bottom:12px}.K9jCswIdnVUtFyACyT01 svg{color:#ff4d4f;width:24px}.vRHALu9zeoALbYY0CsRS{margin:8px 0 16px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/messenger-button/popover-forms/dicount-code/discount-code-error.component.scss"],"names":[],"mappings":"AAGE,sBAEE,YAAA,CACA,qBAAA,CAFA,WAEA,CAGE,+BACE,eAAA,CACA,UAAA,CAKN,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,4CACE,eAAA,CACA,kBAAA,CAGF,0BAEE,aAAA,CADA,UACA,CAIJ,sBACE,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ap8Gfyg5rLJSlJRkaf8x`,
	"header": `K9jCswIdnVUtFyACyT01`,
	"title": `jzpIIv_PzUgjilphc4wp`,
	"message": `vRHALu9zeoALbYY0CsRS`
};
export default ___CSS_LOADER_EXPORT___;
