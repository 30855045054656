import React from 'react'
import { Button } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import cn from 'classnames'
import styles from './add-element-button.component.scss'

export enum AddElementButtonType {
  FOLLOW_UP = 'followUp',
  CONDITION = 'condition',
  SPLIT = 'split'
}

export interface AddElementButtonProps extends ButtonProps {
  label: string
  buttonType: AddElementButtonType
}

export function AddElementButton({ label, buttonType, ...props }: AddElementButtonProps) {
  const buttonStyles = cn(styles.container, styles[buttonType])

  return (
    <Button className={buttonStyles} size='small' {...props}>
      {label}
    </Button>
  )
}
