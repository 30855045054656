// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._0Wa0fJyIjxpRNj1xtBM0{background-color:#476dff;border-radius:2px;color:#fff;margin:0 1px;padding:0 4px}._0Wa0fJyIjxpRNj1xtBM0.YeQVWevYI68mIiYwn3GR{background-color:#ff7875}.G8JcTvnKFwnjOHkdnKD1{color:#476dff;cursor:pointer;margin:0 1px}.G8JcTvnKFwnjOHkdnKD1.YeQVWevYI68mIiYwn3GR{color:#ff7875}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-discount-code/sequence-discount.style.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,uBACE,wBCDY,CDEZ,iBAAA,CAEA,UAAA,CACA,YAAA,CAFA,aAEA,CAEA,4CACE,wBCiDU,CD7Cd,sBACE,aCbY,CDcZ,cAAA,CACA,YAAA,CAEA,2CACE,aCuCU","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sequenceDiscount": `_0Wa0fJyIjxpRNj1xtBM0`,
	"error": `YeQVWevYI68mIiYwn3GR`,
	"sequenceDiscountUrl": `G8JcTvnKFwnjOHkdnKD1`
};
export default ___CSS_LOADER_EXPORT___;
