import { SMSSettingsService } from '@ghostmonitor/recartapis'
import { useQuery } from '@tanstack/react-query'
import { api } from '../../utils/api'
import { QUERY_NAME } from './query-names'

export function useAvailableCountries() {
  const { isLoading, data } = useQuery<SMSSettingsService.GetAvailableCountriesResponse>(
    [QUERY_NAME.availableCountries],
    api.getAvailableCountries
  )

  return {
    data: data?.data,
    isLoading
  }
}
