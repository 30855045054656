// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fn7Y3UrXuInpQ3LdOftv .ant-modal-confirm-body .ant-modal-confirm-title{font-weight:var(--recart-font-weight-semibold)}.Fn7Y3UrXuInpQ3LdOftv .ant-modal-confirm-btns .ant-btn-dangerous:focus{background:var(--recart-red-1);border-color:var(--recart-red-1)}.Fn7Y3UrXuInpQ3LdOftv .ant-modal-content{border-radius:var(--recart-border-radius)}.Fn7Y3UrXuInpQ3LdOftv .ant-modal-confirm-title{color:var(--recart-night-blue-1)}.Fn7Y3UrXuInpQ3LdOftv .ant-modal-confirm-content{color:var(--recart-grey-6)}.Fn7Y3UrXuInpQ3LdOftv .ant-btn{height:40px}`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/modals/modals.component.scss"],"names":[],"mappings":"AAIQ,uEACE,8CAAA,CAKF,uEACE,8BAAA,CACA,gCAAA,CAIJ,yCACE,yCAAA,CAGF,+CACE,gCAAA,CAGF,iDACE,0BAAA,CAGF,+BACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Fn7Y3UrXuInpQ3LdOftv`
};
export default ___CSS_LOADER_EXPORT___;
