import { Action, isAnyOf, Store } from '@reduxjs/toolkit'
import { AppDispatch } from '../../../store/create-store'
import { DashboardState } from '../../../store/dashboard.state'
import { flowEditorSelectors, selectLaunchDarklyFlag } from '../../../store/selectors'
import {
  nodeRemoved,
  removeDeprecatedConditionalSplitClicked
} from '../../../store/slices/flow-editor/flow-editor.actions'
import { flowEditorActions } from '../../../store/slices/flow-editor/flow-editor.reducer'
import { selectNodeById } from '../../../store/slices/flow-editor/flow-editor.selectors'
import { validateDiscountCodesThunk } from '../../../store/slices/flow-editor/thunks/validate-discount-codes.thunk'
import { validateFlowItemThunk } from '../../../store/slices/flow-editor/thunks/validate-flow-item.thunk'
import { validateFlowThunk } from '../../../store/slices/flow-editor/thunks/validate-flow.thunk'
import { validateSMSCampaignThunk } from '../../../store/slices/flow-editor/thunks/validate-sms-campaign.thunk'
import { FlowEditorError } from '../../../utils/flow-editor/flow-editor-errors'
import { isTriggerNode } from '../../../utils/flow-editor/is-trigger-node'
import { getValidateConditionalSplitV2Action } from './action-validators/validate-conditional-split-v2-action'
import { getValidateDelayFlowItemChangedAction } from './action-validators/validate-delay-item-changed-action'
import { getValidateMessageChangedAction } from './action-validators/validate-message-changed-action'
import { getValidateRandomSplitFlowItemChangedAction } from './action-validators/validate-random-split-item-changed-action'
import { getValidateUpdateDiscountCodesOnFlowItemAction } from './action-validators/validate-update-discount-codes-on-flow-item-action'

export function validateMiddleware(store: Store<DashboardState>) {
  const validateMessageChangedAction = getValidateMessageChangedAction(store)
  const validateConditionalSplitV2Action = getValidateConditionalSplitV2Action(store)
  const validateDelayFlowItemChangedAction = getValidateDelayFlowItemChangedAction(store)
  const validateRandomSplitFlowItemChangedAction =
    getValidateRandomSplitFlowItemChangedAction(store)
  const validateUpdateDiscountCodeAction = getValidateUpdateDiscountCodesOnFlowItemAction(store)
  const dispatch: AppDispatch = store.dispatch

  return (next) => (action: Action) => {
    next(action)
    const state = store.getState()
    const flowEditorState = state.flowEditor
    const isConditionalSplitV1SunsetEnabled = selectLaunchDarklyFlag(
      'conditional-split-v-1-sunset'
    )(state)
    const isScheduledDelayEnabled = flowEditorSelectors.selectIsScheduledDelayEnabled(state)
    validateMessageChangedAction(store, action)
    validateDelayFlowItemChangedAction(store, action)
    validateRandomSplitFlowItemChangedAction(store, action)
    validateUpdateDiscountCodeAction(store, action)
    validateConditionalSplitV2Action(store, action)

    /***********
     * Add edge
     ***********/
    if (flowEditorActions.edgeAdded.match(action)) {
      const sourceNode = selectNodeById(action.payload.connection.source)(flowEditorState)

      if (!sourceNode) {
        throw new Error(FlowEditorError.NodeNotFound)
      }

      /***********
       * Trigger node
       ***********/
      if (isTriggerNode(sourceNode)) {
        dispatch(
          validateFlowThunk({
            triggerMustHaveConnection: true
          })
        )

        /***********
         * Any other node
         ***********/
      } else {
        if (!action.payload.connection.source) {
          throw new Error(FlowEditorError.ConnectionDoesntHaveSource)
        }

        const node = selectNodeById(action.payload.connection.source)(flowEditorState)

        if (!node) {
          throw new Error(FlowEditorError.NodeNotFound)
        }

        if (!node.data.flowItemId) {
          throw new Error(FlowEditorError.FlowItemIdNotFoundOnNode)
        }

        dispatch(
          validateFlowItemThunk({
            flowItemId: node.data.flowItemId,
            validators: {
              logicFlowItemConnections: 'onlyIfValidationSucceeded',
              keywordReplyConnections: 'onlyIfValidationSucceeded',
              flowItemContent: 'onlyIfAlreadyFailed',
              scheduledDelay: isScheduledDelayEnabled
            }
          })
        )
      }
    }

    /***********
     * Add edge by inserting a new node
     ***********/
    if (
      isAnyOf(
        flowEditorActions.insertNodeToPortClicked,
        flowEditorActions.insertNodeBetweenNodesClicked
      )(action)
    ) {
      const sourceNode = selectNodeById(action.payload.source)(flowEditorState)

      if (!sourceNode) {
        throw new Error(FlowEditorError.NodeNotFound)
      }

      /***********
       * Trigger node
       ***********/
      if (isTriggerNode(sourceNode)) {
        dispatch(
          validateFlowThunk({
            triggerMustHaveConnection: true
          })
        )

        /***********
         * Any other node
         ***********/
      } else {
        if (!action.payload.source) {
          throw new Error(FlowEditorError.ConnectionDoesntHaveSource)
        }

        const node = selectNodeById(action.payload.source)(flowEditorState)

        if (!node) {
          throw new Error(FlowEditorError.NodeNotFound)
        }

        if (!node.data.flowItemId) {
          throw new Error(FlowEditorError.FlowItemIdNotFoundOnNode)
        }

        dispatch(
          validateFlowItemThunk({
            flowItemId: node.data.flowItemId,
            validators: {
              logicFlowItemConnections: 'onlyIfValidationSucceeded',
              keywordReplyConnections: 'onlyIfValidationSucceeded',
              flowItemContent: 'onlyIfAlreadyFailed',
              scheduledDelay: isScheduledDelayEnabled
            }
          })
        )
      }
    }
    /***********
     * Change or remove edge
     ***********/

    if (
      isAnyOf(
        flowEditorActions.edgesChanged,
        flowEditorActions.edgeRemoved,
        flowEditorActions.edgeAdded
      )(action)
    ) {
      if (isScheduledDelayEnabled) {
        dispatch(
          validateFlowThunk({
            scheduledDelay: isScheduledDelayEnabled
          })
        )
      }
    }

    /*************************
     * SMS Campaign Settings
     * ***********************/
    if (isAnyOf(flowEditorActions.smsCampaignSettingsChanged)(action)) {
      dispatch(validateSMSCampaignThunk({ validateDiscountCodePool: false }))
      if (isScheduledDelayEnabled) {
        dispatch(
          validateFlowThunk({
            scheduledDelay: true
          })
        )
      }
    }

    /*****************************
     * Joined a Segment (UI name: Custom triggers) Settings
     * ***************************/
    if (isAnyOf(flowEditorActions.joinedASegmentSettingsChanged)(action)) {
      // Redo validation, if we fixed this error
      if (action.payload.sendToSegment) {
        dispatch(validateFlowThunk({ joinedASegmentMustHaveSegment: true }))
      }
    }

    /*****************************
     * Custom Integration Settings
     * ***************************/
    if (isAnyOf(flowEditorActions.customIntegrationSettingsChanged)(action)) {
      // Redo validation, if we fixed this error
      const { customIntegrationCategory, customIntegrationSlug } = action.payload
      if (customIntegrationCategory || customIntegrationSlug) {
        const args = {
          ...(customIntegrationCategory && { customIntegrationMustHaveCategory: true }),
          ...(customIntegrationSlug && { customIntegrationMustHaveEvent: true })
        }

        dispatch(validateFlowThunk(args))
      }
    }

    /******************
     * Deleting a node
     * ****************/
    if (isAnyOf(nodeRemoved, removeDeprecatedConditionalSplitClicked)(action)) {
      dispatch(validateDiscountCodesThunk())

      if (isScheduledDelayEnabled) {
        dispatch(
          validateFlowThunk({
            scheduledDelay: isScheduledDelayEnabled
          })
        )
      }
    }

    /******************
     * Changing a delay item => we need to check how this change affect the other delay items
     * ****************/
    if (isAnyOf(flowEditorActions.delayItemChanged)(action)) {
      if (isScheduledDelayEnabled) {
        dispatch(
          validateFlowThunk({
            scheduledDelay: isScheduledDelayEnabled
          })
        )
      }
    }

    /******************
     * Duplicating a node
     * ****************/
    if (isAnyOf(flowEditorActions.nodeDuplicated)(action)) {
      // Mark failed discount codes on the new node, show flow item error on duplicated node
      dispatch(validateDiscountCodesThunk())

      dispatch(
        validateFlowItemThunk({
          flowItemId: action.payload.newFlowItemId,
          validators: {
            conditionalSplitV1Deprecated: isConditionalSplitV1SunsetEnabled,
            scheduledDelay: isScheduledDelayEnabled
          }
        })
      )
    }

    if (flowEditorActions.flowEditorReady.match(action)) {
      dispatch(validateDiscountCodesThunk())
      dispatch(
        validateFlowThunk({
          flowItemContent: true,
          conditionExpression: true,
          triggerMustHaveConnection: true,
          logicFlowItemConnections: false,
          conditionalSplitV1Deprecated: isConditionalSplitV1SunsetEnabled,
          scheduledDelay: isScheduledDelayEnabled
        })
      )
    }
  }
}
