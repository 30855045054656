import { FacebookAdStatus, Stat } from '@ghostmonitor/recartapis'
import { hooks } from '../../../../hooks/hooks'
import { UseResource } from '../../../../hooks/types/use-resource.type'

export const sequenceItemInitialStat: Stat = {
  clicked: 0,
  clickRate: 0,
  converted: 0,
  cost: null,
  facebook: {
    ctr: 0,
    effectiveStatus: FacebookAdStatus.UNKNOWN,
    endDate: null,
    reach: 0,
    roas: 0,
    salesTotal: {
      USD: 0
    },
    spend: {
      USD: 0
    },
    startDate: null
  },
  opened: 0,
  openRate: 0,
  optOutRate: {
    sms: 0
  },
  spamRate: {
    sms: 0
  },
  roi: 0,
  sales: {
    totalUSD: 0
  },
  salesTotal: {
    USD: 0
  },
  sent: 0,
  sentToPSID: 0,
  sentToUserRef: 0
}

export function useEditorSequenceItemStat(sequenceItemId: string): UseResource<Stat> {
  const { data: sequenceItemsStat, isLoading, isError } = hooks.useEditorSequenceItemsStat()
  return {
    data: sequenceItemsStat?.[sequenceItemId] ?? sequenceItemInitialStat,
    isLoading,
    isError
  }
}
