/* global UPLOAD_API_URL */
import { AttachmentType } from '@ghostmonitor/recartapis'
import { message } from 'antd'
import cn from 'classnames'
import React, { useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { DropzoneComponent } from 'react-dropzone-component'
import { useDispatch } from 'react-redux'
import { ReactComponent as ButtonPlay } from '../../../../../src/static/images/svg/streamline/stroke/01-Interface-Essential/42-Multimedia-Controls/button-play-1.svg'
import { config } from '../../../../config'
import { ReactComponent as ReplaceIcon } from '../../../../static/images/svg/icon-replace.svg'
import type { AppDispatch } from '../../../../store/create-store'
import { uploadMediaToFacebookThunk } from '../../../../store/slices/sequence-editor/thunks/upload-media-to-facebook.thunk'
import { classify } from '../../../../utils/utils'
import { Audio } from '../file-editor/components/audio.component'
import { File } from '../file-editor/components/file.component'
import { Image } from '../file-editor/components/image.component'
import { Video } from '../file-editor/components/video.component'
import { fbElements } from '../file-editor/file-editor.component'
import '../file-editor/file-upload.style.scss'
import styles from './facebook-file-editor.component.scss'

const attachmentPreviews = {
  image: Image,
  file: File,
  audio: Audio,
  video: Video
}

export interface FacebookFileEditorProps {
  messageItemIndex?: number
  messageMeta?: any
  input: {
    onBlur?: () => void
    name: string
    value: string
  }
  attachmentType: string
  contentEditable: boolean
  isGallery?: boolean
  onFacebookUploadComplete?: (result: { attachmentId: string; url: string }) => void
  siteId: string
}

const ERROR_DURATION = 5.0
const MAX_FILE_UPLOAD_SIZE = 25000000
const MB = 1000000
const MAX_FILE_UPLOAD_SIZE_MB = MAX_FILE_UPLOAD_SIZE / MB
const FILE_TYPES = {
  image: 'image/jpeg, image/gif, image/png, image/tiff',
  video: 'video/*',
  file: 'image/*, audio/*, video/*, application/*'
}
const MEDIA_UPLOAD_SOURCE = 'sequence-editor'

export function FacebookFileEditor(props: FacebookFileEditorProps) {
  const name = `upload_${Math.round(Math.random() * 100000).toString()}`
  const [loading, setLoading] = useState(false)
  const dispatch: AppDispatch = useDispatch()

  const componentConfig = Object.assign(
    {},
    {
      showFiletypeIcon: false,
      postUrl:
        config.UPLOAD_API_URL + `sites/${props.siteId}/media-uploads?source=${MEDIA_UPLOAD_SOURCE}`
    }
  )

  const eventHandlers = {
    canceled,
    addedfile: addedFile,
    success,
    error
  }
  const authToken = localStorage.getItem('x-auth-token')

  const djsConfig = {
    headers: { 'X-Auth-Token': authToken },
    paramName: 'file',
    timeout: 120000,
    maxFilesize: MAX_FILE_UPLOAD_SIZE_MB,
    acceptedFiles: FILE_TYPES[props.attachmentType],
    clickable: `.file-uploader--${classify(name)} .file-uploader__clickable`,
    previewsContainer: `.file-uploader--${classify(name)}`,
    dictDefaultMessage: '',
    dictMaxFilesExceeded: 'MaxFiles limit exceeded',
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className='dz-preview dz-file-preview'>
        <div className='dz-error-message'>
          <span data-dz-errormessage />
        </div>
      </div>
    )
  }

  async function success(file) {
    const { attachmentType } = props
    const response = JSON.parse(file.xhr.response)
    const facebookResponse = await dispatch(
      uploadMediaToFacebookThunk({ url: response.url, mediaType: attachmentType })
    )

    if (uploadMediaToFacebookThunk.fulfilled.match(facebookResponse)) {
      props.onFacebookUploadComplete?.({
        attachmentId: (facebookResponse as any).attachmentId,
        url: response.url
      })
    }
  }

  function error(data) {
    setLoading(false)
    if (data.size > MAX_FILE_UPLOAD_SIZE) {
      message.error('Please attach a file which is not larger than 25MB', ERROR_DURATION)
    } else {
      message.error(`Upload failed: ${data.name}`, ERROR_DURATION)
    }
  }

  function addedFile(file) {
    const { onFacebookUploadComplete } = props
    onFacebookUploadComplete?.({ attachmentId: '', url: '' })
    setLoading(true)
  }

  function canceled() {
    const {
      input: { onBlur }
    } = props
    onBlur()
  }

  function loadFinished() {
    setLoading(false)
  }

  function renderDefaultMessage() {
    const { attachmentType } = props
    return (
      <div className='file-uploader__preview-placeholder'>
        {props.messageItemIndex !== undefined ? (
          <div className={styles.messageItemIndex}>{props.messageItemIndex + 1}</div>
        ) : null}
        <div className={`file-uploader__preview-icon ${attachmentType}`}>
          {fbElements[attachmentType].icon}
        </div>
        Please select an attachment file
      </div>
    )
  }

  function renderHover() {
    const isFlexRow =
      [AttachmentType.audio, AttachmentType.file].indexOf(AttachmentType[props.attachmentType]) > -1

    return (
      <div className='file-uploader-hover'>
        <div
          className='file-uploader-hover__container'
          style={{ flexDirection: isFlexRow ? 'row' : 'column' }}
        >
          <div className='file-uploader-hover__icon'>
            <ReplaceIcon />
          </div>
          <div className='file-uploader-hover__text'>Click to replace</div>
        </div>
      </div>
    )
  }

  const {
    input: { value },
    attachmentType,
    contentEditable,
    isGallery
  } = props
  const AttachmentComponent = attachmentPreviews[attachmentType]
  let preview = <AttachmentComponent {...props} loadFinished={loadFinished} isGallery={isGallery} />
  if (!value) {
    preview = renderDefaultMessage()
  }

  const classes = {
    'file-uploader': true,
    [`file-uploader--${classify(name)}`]: true,
    'file-uploader--has-value': !!value
  }
  const fileUploaderPreview = (
    <div className='file-uploader__preview'>
      <div
        className={`file-uploader__loader-container ${
          loading ? 'file-uploader__loader-container--active' : ''
        }`}
      >
        <div className='file-uploader__loader' />
      </div>
      {contentEditable && value && renderHover()}
      {preview}
    </div>
  )

  if (contentEditable) {
    return (
      <div className={cn(styles['file-editor'], classes)}>
        {value && attachmentType === AttachmentType.video ? (
          <ButtonPlay className='video-icon' />
        ) : null}
        <div className='file-uploader__clickable' data-testid={`file-uploader-${attachmentType}`}>
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            {fileUploaderPreview}
          </DropzoneComponent>
        </div>
      </div>
    )
  }
  return (
    <div className={cn(classes)}>
      <div className='file-uploader__clickable'>{fileUploaderPreview}</div>
    </div>
  )
}
