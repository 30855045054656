import { useCallback, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { UseResourceCache } from '../types/use-resource-cache.type'
import { QUERY_NAME } from './query-names'

export function useMeCache(): UseResourceCache {
  const queryClient = useQueryClient()
  const queryName = useMemo(() => [QUERY_NAME.me], [])

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  return {
    invalidateCache
  }
}
