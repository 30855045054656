import {
  Variable,
  VariableParam,
  placeholderVariablePattern,
  variableParamsPattern,
  variablePattern
} from '@ghostmonitor/recartapis'
import cheerio from 'cheerio'
import he from 'he'

export function removeHTMLEntities(html: string): string {
  return he.decode(html)
}

export function addEditorHTMLEntities(html: string): string {
  const el = document.createElement('div')
  el.contentEditable = 'true'
  el.innerHTML = html

  function replacer(match: string, group1: string, group2: string | null): string {
    if (group2 === null) {
      return '&nbsp;'
    }
    return `&nbsp;${group2}`
  }

  let doReplace = true
  while (doReplace) {
    const replacedHtml = el.innerHTML.replace(/(\s)(\s*)$/gm, replacer)
    if (replacedHtml === el.innerHTML) {
      doReplace = false
    }
    el.innerHTML = replacedHtml
  }

  return el.innerHTML
}

export function convertHTMLtoPlainText(html: string): string {
  if (html === '') return ''
  return html.replace(/(<((?!img)[^>]+)>)|/gi, '')
}

// Source: https://stackoverflow.com/questions/6249095/how-to-set-caretcursor-position-in-contenteditable-element-div
export function createRange(node, chars, range?: Range): Range {
  if (!range) {
    range = document.createRange()
    range.selectNode(node)
    range.setStart(node, 0)
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count)
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length
      } else {
        range.setEnd(node, chars.count)
        chars.count = 0
      }
    } else {
      for (let lp = 0; lp < node.childNodes.length; lp++) {
        range = createRange(node.childNodes[lp], chars, range)

        if (chars.count === 0) {
          break
        }
      }
    }
  }

  return range
}

export function setNodeCaretPosition(node: HTMLDivElement, chars: number): void {
  if (chars < 0) {
    return
  }

  const selection = window.getSelection()
  const range = createRange(node, { count: chars })

  if (!range) {
    return
  }

  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
}

export function getNodeCaretPosition(node: HTMLDivElement): number {
  let position = 0
  const isSupported = window.getSelection !== undefined
  if (isSupported && node) {
    const selection = window.getSelection()
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(node)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      position = preCaretRange.toString().length
    }
  }
  return position
}

export type VariableParams = Partial<Record<VariableParam, string>>

export const specialPlaceholderVariables = {
  'site.name': 'SiteName',
  'site.domain': 'SiteURL',
  'page.name': 'FacebookPageName',
  'cart.url': 'CartURL',
  'product.url': 'ProductURL',
  'product.name': 'ProductName',
  'fulfillment.tracking_url': 'FulfillmentURL',
  'order.status_url': 'OrderStatusURL',
  'sms.privacy_policy_url': 'PrivacyPolicyURL',
  'site.email': 'SupportEmail',
  'subscriber.optin_source_url': 'RestoreLink',
  'wonderment.tracking_code': 'WondermentTrackingCode',
  'wonderment.order_number': 'WondermentOrderNumber',
  'wonderment.order_id': 'WondermentOrderID',
  'wonderment.carrier_name': 'WondermentCarrierName',
  'wonderment.product_names': 'WondermentProductNames',
  'wonderment.tracking_url': 'WondermentTrackingURL',
  'wonderment.wonderment_tracking_url': 'WondermentWondermentTrackingURL',
  'loopwork.billing_attempt_error_message': 'LoopBillingAttemptErrorMessage',
  'loopwork.billing_retry_delay_days': 'LoopBillingRetryDelayDays',
  'loopwork.first_name': 'LoopFirstName',
  'loopwork.last_name': 'LoopLastName',
  'loopwork.product_names': 'LoopProductNames',
  'loopwork.next_billing_date_display': 'LoopNextBillingDateDisplay',
  'loopwork.subscription_shopify_id': 'LoopSubscriptionShopifyID',
  'loopwork.customer_portal_link': 'LoopCustomerPortalLink',
  'recharge.billing_attempt_error_message': 'rechargeBillingAttemptErrorMessage',
  'recharge.first_name': 'RechargeFirstName',
  'recharge.last_name': 'RechargeLastName',
  'recharge.product_names': 'RechargeProductNames',
  'recharge.next_charge_scheduled_at': 'RechargeNextChargeScheduledAt',
  'recharge.order_scheduled_at': 'RechargeOrderScheduledAt',
  'recharge.customer_portal_link': 'rechargeCustomerPortalLink',
  'checkout.recovery_link': 'CheckoutRecoveryLink',
  'integration.update_payment_method_url': 'integrationUpdatePaymentMethodURL',
  'yotpo.perk_reward_points': 'PerkRewardPoints',
  'yotpo.redemption_reward_text': 'RedemptionRewardText',
  'yotpo.redemption_option_name': 'RedemptionOptionName',
  'yotpo.current_balance': 'CurrentBalance',
  'yotpo.points_needed': 'PointsNeeded',
  'yotpo.loyalty_next_points_expire_on': 'LoyaltyNextPointsExpireOn',
  'yotpo.loyalty_next_points_expire_amount': 'LoyaltyNextPointsExpireAmount',
  'yotpo.new_tier': 'NewTier',
  'yotpo.old_tier': 'OldTier',
  'loyaltyLion.reward_name': 'LoyaltyLionRewardName',
  'loyaltyLion.new_tier': 'LoyaltyLionNewTier',
  'loyaltyLion.old_tier': 'LoyaltyLionOldTier',
  'loyaltyLion.current_balance': 'LoyaltyLionCurrentBalance',
  'loyaltyLion.points_needed': 'LoyaltyLionPointsNeeded',
  'loyaltyLion.points_earned': 'LoyaltyLionPointsEarned',
  'bold.customer_portal_link': 'BoldCustomerPortalLink',
  'bold.first_name': 'BoldFirstName',
  'bold.next_order_datetime': 'BoldNextOrderDatetime',
  'bold.failure_reason': 'BoldFailureReason'
}

export function getVariablePlaceholder(variableName: string): string {
  return specialPlaceholderVariables[variableName] ?? variableName.replace('.', '_')
}

export function getUrlFromTagId(tagId: string, withTag: string): string {
  const tagArr = tagId.split('|')
  const isUrlTag = tagArr.includes(withTag)
  if (!isUrlTag) {
    return
  }
  const value = tagArr.find((val) => val.startsWith('value:'))
  if (!value) {
    return
  }
  const [, ...urlArr] = value.split(':')
  const url = urlArr.join(':')
  return url
}

export function isStaticDiscountCodeVariable(variable: Variable): boolean {
  return variable.params.discount_code !== undefined
}

export function isUniqueDiscountCodeVariable(variable: Variable): boolean {
  return variable.params.discount_pool_id !== undefined
}

export function isDiscountCodeVariable(variable: Variable): boolean {
  return isStaticDiscountCodeVariable(variable) || isUniqueDiscountCodeVariable(variable)
}

export function isDiscountUrlVariable(variable: Variable): boolean {
  return isDiscountCodeVariable(variable) && variable.name === 'url'
}

export function isUrlVariable(variable: Variable): boolean {
  return variable.name === 'url' && !isDiscountCodeVariable(variable)
}

export function isSiteDomainVariable(variable: Variable): boolean {
  return variable.name === 'site.domain'
}

function getShortDomain(subdomain: string | undefined): string {
  return `${subdomain?.length > 0 ? `${subdomain}.` : ''}recartsms.com`
}

export function getRenderedVariable(variable: Variable, subdomain?: string): string {
  if (isUrlVariable(variable)) {
    return `${getShortDomain(subdomain)}/shortlink`
  }

  if (isStaticDiscountCodeVariable(variable)) {
    if (variable.name === 'url') {
      return `${getShortDomain(subdomain)}/discount-code-url`
    }
    return `${getVariablePlaceholder(variable.params.discount_code)}`
  }
  if (isUniqueDiscountCodeVariable(variable)) {
    if (variable.name === 'url') {
      return `${getShortDomain(subdomain)}/unique-discount-code-url`
    }
    return 'UNIQUE'
  }

  return `{${getVariablePlaceholder(variable.name)}}`
}

export function getRawVariable(variableName: string, variableParams: VariableParams = {}): string {
  return `{{${variableName}|${Object.keys(variableParams)
    .map(
      (paramName) =>
        `${paramName}${variableParams[paramName] ? `:${variableParams[paramName]}` : ''}`
    )
    .join('|')}}}`
}

export function getRawVariableFromVariable(variable: Variable): string {
  if (!variable) {
    return
  }
  return getRawVariable(variable.name, variable.params)
}

export function getVariableParamsSequenceEditor(
  rawParams: string
): Partial<Record<VariableParam, string>> {
  const params: Partial<Record<VariableParam, string>> = {}
  let match: string[]
  const pattern = new RegExp(variableParamsPattern, 'g')
  while ((match = pattern.exec(rawParams))) {
    const paramName = match[1]
    const paramValue = match[2]
    params[paramName] = paramValue
  }
  return params
}

export function getVariableFromRawVariableSequenceEditor(rawVariable: string): Variable {
  const match = rawVariable.match(new RegExp(variablePattern))
  if (!match) {
    return
  }
  const [, variableName, params] = match
  const variable: Variable = {
    name: variableName,
    params: {}
  }
  if (params) {
    variable.params = getVariableParamsSequenceEditor(params)
  }
  return variable
}

export function getVariablesFromRawValue(rawValue: string): Variable[] {
  const variables: Variable[] = []
  let match: string[]
  const pattern = new RegExp(variablePattern, 'g')
  while ((match = pattern.exec(rawValue))) {
    variables.push(getVariableFromRawVariableSequenceEditor(match[0]))
  }
  return variables
}

export function convertUserVariables(rawValue: string): string {
  let match: string[]
  while ((match = placeholderVariablePattern.exec(rawValue))) {
    const variableName = match[0].slice(1, -1)
    rawValue = rawValue.replace(
      match[0],
      getRawVariableFromVariable({
        name: variableName,
        params: {
          [VariableParam.default]: undefined
        }
      })
    )
  }
  return rawValue
}

export function getIdFromVariable(variable: Variable, index: number): string {
  const parts = Object.keys(variable.params ?? {}).map(
    (paramName) =>
      `${paramName}${variable.params[paramName] ? `:${variable.params[paramName]}` : ''}`
  )
  parts.push(`${index}`)
  return `${variable.name}|${parts.join('|')}`
}

export function getVariableFromId(id: string): Variable {
  const parts = id.split('|')
  const variable: Variable = {
    name: parts[0],
    params: {}
  }

  if (parts.length > 2) {
    variable.params = getVariableParamsSequenceEditor(parts.slice(1, -1).join('|'))
  }

  return variable
}

export function getIndexFromId(id: string): number {
  const parts = id.split('|')
  return Number.parseInt(parts[parts.length - 1], 10)
}

export function getElementByVariableIndex(
  htmlValue: string,
  variableIndex: number
): HTMLSpanElement | undefined {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = htmlValue
  for (const node of holderElement.children) {
    if (node.tagName !== 'SPAN') {
      continue
    }
    const tagId = node.getAttribute('id')
    if (!tagId) {
      continue
    }
    const index = getIndexFromId(tagId)
    if (index === variableIndex) {
      return document.getElementById(node.getAttribute('id'))
    }
  }
}

export function replaceVariableTagsToRawVariables(
  htmlValue: string,
  variableNamesToReplace: string[]
): string {
  const $html = cheerio.load(htmlValue)

  $html('span').each((index, element) => {
    const tagId = $html(element).attr('id')
    if (tagId) {
      const variable = getVariableFromId(tagId)

      if (variableNamesToReplace.includes(variable.name)) {
        $html(element).replaceWith(getRawVariableFromVariable(variable))
      }
    }
  })

  return removeHTMLEntities($html('body').html())
}

export function replaceUserVariablesToRawVariables(htmlValue: string): string {
  const $html = cheerio.load(htmlValue)

  $html('span').each((index, element) => {
    const tagId = $html(element).attr('id')
    if (tagId) {
      const variable = getVariableFromId(tagId)
      $html(element).replaceWith(getRawVariableFromVariable(variable))
    }
  })

  return removeHTMLEntities($html('body').html())
}

export function replaceNonBreakingSpaces(value: string): string {
  return value
    .split('')
    .map((char) => {
      if (char.charCodeAt(0) === 160) {
        return 32
      }
      return char.charCodeAt(0)
    })
    .reduce((acc, str) => {
      return acc + String.fromCharCode(str)
    }, '')
}

export function setIdAtIndex(html: string, indexToChange: number, updatedId: string): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html
  let newHtml = ''
  for (const childNode of holderElement.childNodes) {
    if (childNode.nodeType === Node.ELEMENT_NODE && (childNode as any).tagName === 'SPAN') {
      const id = (childNode as any).getAttribute('id')
      const index = getIndexFromId(id)
      if (index === indexToChange) {
        (childNode as any).setAttribute('id', updatedId)
      }
      newHtml += (childNode as any).outerHTML
    }
    if (childNode.nodeType === Node.TEXT_NODE) {
      newHtml += childNode.nodeValue
    }
  }
  return newHtml
}

export function removeVariableByCondition(
  html: string,
  condition: (varibale: Variable, index?: number) => boolean
): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html
  let newHtml = ''
  for (const childNode of holderElement.childNodes) {
    if (childNode.nodeType === Node.ELEMENT_NODE && (childNode as any).tagName === 'SPAN') {
      const id = (childNode as any).getAttribute('id')
      const variable = getVariableFromId(id)
      const index = getIndexFromId(id)
      const removable = condition(variable, index)
      if (!removable) {
        newHtml += (childNode as any).outerHTML
      }
    }
    if (childNode.nodeType === Node.TEXT_NODE) {
      newHtml += childNode.nodeValue
    }
  }
  return newHtml
}

export function removeDiscountCodeByName(html: string, discountCodeName: string): string {
  const newHtml = removeVariableByCondition(html, (variable: Variable) => {
    return (
      isStaticDiscountCodeVariable(variable) && variable.params.discount_code === discountCodeName
    )
  })
  return newHtml
}

export function removeDiscountCodeByPoolId(html: string, discountPoolId: string): string {
  const newHtml = removeVariableByCondition(html, (variable: Variable) => {
    return (
      isUniqueDiscountCodeVariable(variable) && variable.params.discount_pool_id === discountPoolId
    )
  })
  return newHtml
}

export function removeVariableByIndex(html: string, indexToRemove: number): string {
  const newHtml = removeVariableByCondition(html, (_: Variable, index: number) => {
    return index === indexToRemove
  })
  return newHtml
}

export function changeIndexInTagId(tagId: string, index: number): string {
  const idParts = tagId.split('|')
  const idIndex = idParts.pop()
  if (!isNaN(Number(idIndex))) {
    idParts.push(index.toString())
  } else {
    idParts.push(idIndex)
  }
  return idParts.join('|')
}

export function reindexVariableNodes(html: string): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html
  let newHtml = ''
  let index = 0
  for (const node of holderElement.childNodes) {
    const childNode = node as any
    if (childNode.nodeType === Node.ELEMENT_NODE && childNode.tagName === 'SPAN') {
      const tagId = childNode.getAttribute('id')
      if (tagId !== undefined) {
        const reindexedTagId = changeIndexInTagId(tagId, index)
        childNode.setAttribute('id', reindexedTagId)
        index += 1
      }
      newHtml += childNode.outerHTML
    }
    if (childNode.nodeType === Node.TEXT_NODE) {
      newHtml += childNode.nodeValue
    }
  }
  return newHtml
}

export function getContentLength(content: string): number {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = content
  const child = holderElement.firstChild
  if (!child) {
    return 0
  }
  if (child.nodeType === Node.TEXT_NODE) {
    return child.nodeValue.length
  }
  if (child.nodeType === Node.ELEMENT_NODE) {
    return child.textContent.length
  }
  return 0
}

export function insertContentAtCaretPosition(
  html: string,
  content: string,
  insertPosition: number
): string {
  const holderElement = document.createElement('div')
  holderElement.innerHTML = html

  let newHtml = ''
  let position = 0
  let inserted = false

  if (insertPosition <= 0) {
    newHtml += content
    inserted = true
  }
  for (const childNode of holderElement.childNodes) {
    if (childNode.nodeType === Node.ELEMENT_NODE) {
      newHtml += (childNode as any).outerHTML
      position += childNode.textContent.length
    }
    if (childNode.nodeType === Node.TEXT_NODE) {
      const { nodeValue } = childNode
      if (
        !inserted &&
        position <= insertPosition &&
        insertPosition <= position + nodeValue.length
      ) {
        const startPosition = insertPosition - position
        newHtml += `${nodeValue.slice(0, startPosition)}${content}${nodeValue.slice(startPosition)}`
        position += nodeValue.length + getContentLength(content)
        inserted = true
      } else {
        newHtml += nodeValue
        position += nodeValue.length
      }
    }
    if (!inserted && position >= insertPosition) {
      newHtml += content
      inserted = true
    }
  }
  if (!inserted) {
    newHtml += content
  }

  return reindexVariableNodes(newHtml)
}
