import { MessengerGenericTemplatePayload } from '@ghostmonitor/recartapis'
import debounce from 'lodash/debounce'
import { Action, Store } from '@reduxjs/toolkit'
import type { AppDispatch } from '../../../../store/create-store'
import { DashboardState } from '../../../../store/dashboard.state'
import { selectMessageItem } from '../../../../store/selectors'
import {
  messageItemValidationError,
  messageItemValidationSuccess,
  UpdateMessageItemAction,
  UPDATE_MESSAGE_ITEM
} from '../../../../store/slices/sequence-editor/sequence-editor.actions'
import { messageItemValidator } from '../../validators/message-item.validator'

const DEBOUNCE_DELAY = 400

function getValidateMessageItem(dispatch: AppDispatch) {
  return debounce(
    (
      messengerGenericTemplatePayload: MessengerGenericTemplatePayload,
      action: UpdateMessageItemAction
    ) => {
      const messageItemErrors = messageItemValidator(messengerGenericTemplatePayload)
      if (messageItemErrors.length > 0) {
        dispatch(
          messageItemValidationError(messageItemErrors[0], {
            sequenceItemId: action.sequenceItemId,
            messageIndex: action.messageIndex,
            messageItemIndex: action.messageItemIndex
          })
        )
      } else {
        dispatch(
          messageItemValidationSuccess(null, {
            sequenceItemId: action.sequenceItemId,
            messageIndex: action.messageIndex,
            messageItemIndex: action.messageItemIndex
          })
        )
      }
    },
    DEBOUNCE_DELAY,
    { leading: true, trailing: true }
  )
}

function isValidableAction(action: Action): action is UpdateMessageItemAction {
  return action.type === UPDATE_MESSAGE_ITEM
}

export function validateMessageItemMiddleware(store: Store<DashboardState>) {
  const dispatch: AppDispatch = store.dispatch
  const validateMessageItem = getValidateMessageItem(dispatch)

  return (next) => (action: ValidatableAction) => {
    next(action)

    if (isValidableAction(action)) {
      const state = store.getState()
      const messageItemTemplatePayload = selectMessageItem(
        action.sequenceItemId,
        action.messageIndex,
        action.messageItemIndex
      )(state)

      validateMessageItem(messageItemTemplatePayload, action)
    }
  }
}
