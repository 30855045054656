import React from 'react'

interface AudioProps {
  input: any
  loadFinished: VoidFunction
}

export function Audio(props: AudioProps) {
  return (
    <div className='file-uploader__preview-audio'>
      <div className='file-uploader__preview-audio-overlay' />
      <audio controls src={encodeURI(props.input.value)} onLoadStart={props.loadFinished} />
    </div>
  )
}
