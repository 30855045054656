import {
  AttachmentPayload,
  isMessengerSequenceItemUI,
  isSMSSequenceItemUI
} from '@ghostmonitor/recartapis'
import merge from 'lodash/merge'
import { isMessageGenericGalleryTemplate } from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import { hasMessageOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { createScope } from '../../../../utils/logger/logger'
import { UpdateMessageAction } from '../sequence-editor.actions'
import {
  selectEditorSequenceMeta,
  selectMessageMeta,
  selectSequenceItem
} from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'
import { setVariablesMetaInMessageHandler } from './set-variables-meta-in-message.handler'

const { info } = createScope('sequence-editor')

export function updateMessageActionHandler(
  state: SequenceEditorState,
  action: UpdateMessageAction
): void {
  const sequenceMeta = selectEditorSequenceMeta(state)
  sequenceMeta.waitForMessageChange = false

  const sequenceItem = selectSequenceItem(action.sequenceItemId)(state)
  if (hasMessageOnSequenceItemType(sequenceItem)) {
    const message = sequenceItem.messages[action.messageIndex]
    const messageMeta = selectMessageMeta(action.sequenceItemId, action.messageIndex)(state)
    messageMeta.edited = true

    const updatedText = action.messagePayloadChange.text
    setVariablesMetaInMessageHandler(state, message, messageMeta, updatedText)

    if (isSMSSequenceItemUI(sequenceItem)) {
      message.text = action.messagePayloadChange.text
    } else if (isMessengerSequenceItemUI(sequenceItem)) {
      // Generic or Button template
      const message = sequenceItem.messages[action.messageIndex]

      if (
        message.messengerTemplatePayload !== undefined &&
        !isMessageGenericGalleryTemplate(message)
      ) {
        const messagePayload = message.messengerTemplatePayload
        merge(messagePayload, action.messagePayloadChange)

        // Attachment
      } else if (sequenceItem.messages[action.messageIndex].attachmentPayload !== undefined) {
        const messagePayload: AttachmentPayload =
          sequenceItem.messages[action.messageIndex].attachmentPayload
        merge(messagePayload, action.messagePayloadChange)
      } else {
        info('updateMessageActionHandler:1', { action, sequenceItem })
      }
    } else {
      info('updateMessageActionHandler:3', { action, sequenceItem })
    }
  } else {
    info('updateMessageActionHandler:2', { action, sequenceItem })
  }
}
