import {
  EmbeddedFormDesktopEmailSettings,
  EmbeddedFormSettings,
  EmbeddedFormSMSSettings,
  LinkButtonField,
  OptinToolStep
} from '@ghostmonitor/recartapis'
import {
  oneWaySMSDescriptionText,
  twoWaySMSDescriptionText,
  twoWaySMSEmailDescriptionText
} from './legal-texts'

function getEmailDesktopSettings(): EmbeddedFormDesktopEmailSettings {
  return {
    logo: {
      type: 'image',
      source: ''
    },
    headingTop: {
      type: 'text',
      text: 'UNLOCK 15% OFF YOUR ORDER',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '32px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    headingMiddle: {
      type: 'text',
      text: '',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '24px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    headingBottom: {
      type: 'text',
      text: '',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '18px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    form: {
      items: [],
      validationRules: {
        required: {
          message: 'Please fill in this input field'
        }
      }
    },
    input: {
      type: 'input',
      shape: 'rounded',
      defaultState: {
        font: {
          type: 'google',
          family: 'Roboto',
          variant: '400',
          size: '20px',
          fallback: 'Arial, sans-serif'
        },
        placeholderText: 'Enter your email',
        placeholderColor: '#9BA6B1',
        backgroundColor: '#FFFFFF',
        borderColor: '#9BA6B1'
      },
      activeState: {
        filledTextColor: '#243C6B',
        backgroundColor: '#FFFFFF',
        borderColor: '#0375FF'
      },
      errorState: {
        text: 'Please enter a valid email',
        textColor: '#FFFFFF',
        textBackgroundColor: '#FF6565',
        filledTextColor: '#FF6565',
        backgroundColor: '#FFF8F8',
        borderColor: '#FF6565'
      }
    },
    success: {
      type: 'text',
      text: 'Thanks for your email! To claim the discount please enter your phone number',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#0F1B2B',
      textDecoration: 'none'
    },
    button: {
      type: 'button',
      text: 'CONTINUE',
      shape: 'rounded',
      textColor: '#FFFFFF',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '22px',
        fallback: 'Arial, sans-serif'
      },
      backgroundColor: '#0375FF',
      borderColor: 'rgba(255, 255, 255, 0)',
      borderWidth: '1px',
      size: '13px',
      textDecoration: 'none'
    },
    description: {
      type: 'textarea',
      text: 'By submitting your email, you agree to receiving promotional content. You can unsubscribe at any time.',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#9BA6B1',
      textDecoration: 'none'
    }
  }
}

function getDesktopSMSSettings(messagingType: 'one-way' | 'two-way'): EmbeddedFormSMSSettings {
  return {
    logo: {
      type: 'image',
      source: ''
    },
    headingTop: {
      type: 'text',
      text: 'UNLOCK 15% OFF YOUR ORDER',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '32px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    headingMiddle: {
      type: 'text',
      text: '',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '24px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    headingBottom: {
      type: 'text',
      text: '',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '18px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    form: {
      items: [],
      validationRules: {
        required: {
          message: 'Please fill in this input field'
        }
      }
    },
    input: {
      type: 'input',
      shape: 'rounded',
      defaultState: {
        font: {
          type: 'google',
          family: 'Roboto',
          variant: '400',
          size: '20px',
          fallback: 'Arial, sans-serif'
        },
        placeholderText: 'Enter phone number',
        placeholderColor: '#9BA6B1',
        backgroundColor: '#FFFFFF',
        borderColor: '#9BA6B1'
      },
      activeState: {
        filledTextColor: '#243C6B',
        backgroundColor: '#FFFFFF',
        borderColor: '#0375FF'
      },
      errorState: {
        text: 'Please enter a valid email',
        textColor: '#FFFFFF',
        textBackgroundColor: '#FF6565',
        filledTextColor: '#FF6565',
        backgroundColor: '#FFF8F8',
        borderColor: '#FF6565'
      }
    },
    button: {
      type: 'button',
      text: 'GET 15% OFF NOW',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '22px',
        fallback: 'Arial, sans-serif'
      },
      shape: 'rounded',
      textColor: '#FFFFFF',
      backgroundColor: '#0375FF',
      borderColor: 'rgba(255, 255, 255, 0)',
      borderWidth: '1px',
      size: '13px',
      textDecoration: 'none'
    },
    description: {
      type: 'textarea',
      text: messagingType === 'one-way' ? oneWaySMSDescriptionText : twoWaySMSDescriptionText,
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '10px',
        fallback: 'Arial, sans-serif'
      },
      color: '#6E7C97',
      textDecoration: 'none'
    },
    success: {
      ...(messagingType === 'one-way'
        ? getSMSMobileSuccessSettingsTemplate()
        : getSMSDesktopSuccessSettingsTemplate())
    }
  }
}

function getMobileSMSSettings(messagingType: 'one-way' | 'two-way'): EmbeddedFormSMSSettings {
  let mobileSMSSettings: EmbeddedFormSMSSettings = {
    logo: {
      type: 'copied',
      copyOf: 'desktop'
    },
    headingTop: {
      type: 'copied',
      copyOf: 'desktop'
    },
    headingMiddle: {
      type: 'copied',
      copyOf: 'desktop'
    },
    headingBottom: {
      type: 'copied',
      copyOf: 'desktop'
    },
    form: {
      items: [],
      validationRules: {
        required: {
          message: 'Please fill in this input field'
        }
      }
    },
    button: {
      type: 'copied',
      copyOf: 'desktop'
    },
    description: {
      type: 'copied',
      copyOf: 'desktop'
    },
    success: { ...getSMSMobileSuccessSettingsTemplate() }
  }

  if (messagingType === 'one-way') {
    mobileSMSSettings = {
      ...mobileSMSSettings,
      input: {
        type: 'copied',
        copyOf: 'desktop'
      }
    }
  }
  return mobileSMSSettings
}

function getSMSDesktopSuccessSettingsTemplate(): EmbeddedFormSMSSettings['success'] {
  return {
    logo: {
      type: 'image',
      source:
        'https://static.ghostmonitor.com/optin-tools/landing-page/default-sms-success-desktop.svg'
    },
    heading: {
      type: 'text',
      text: 'Check your phone',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '32px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    description: {
      type: 'text',
      text: 'Send "Yes" to subscribe for the discounts',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    button: null
  }
}

function getSMSMobileSuccessSettingsTemplate(): EmbeddedFormSMSSettings['success'] {
  return {
    logo: {
      type: 'image',
      source:
        'https://static.ghostmonitor.com/optin-tools/landing-page/default-sms-success-mobile.svg'
    },
    heading: {
      type: 'text',
      text: 'Congratulations!',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '500',
        size: '24px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    description: {
      type: 'text',
      text: 'Check your phone for the discount code.',
      font: {
        type: 'google',
        family: 'Roboto',
        variant: '400',
        size: '14px',
        fallback: 'Arial, sans-serif'
      },
      color: '#243C6B',
      textDecoration: 'none'
    },
    button: null
  }
}

export function getSMSSuccessButtonSettingsTemplate(siteUrl: string): LinkButtonField {
  return {
    type: 'link-button',
    text: 'Go to Home',
    font: {
      type: 'google',
      family: 'Roboto',
      variant: '500',
      size: '24px',
      fallback: 'Arial, sans-serif'
    },
    shape: 'rounded',
    textColor: '#0375FF',
    backgroundColor: 'rgba(255,255,255,0)',
    borderColor: 'rgba(255, 255, 255, 0)',
    borderWidth: '1px',
    size: '13px',
    link: siteUrl,
    textDecoration: 'none'
  }
}

export function getGeotargetingErrorButtonSettingsTemplate(siteUrl: string): LinkButtonField {
  return {
    type: 'link-button',
    text: 'Go to Home',
    font: {
      type: 'google',
      family: 'Roboto',
      variant: '500',
      size: '24px',
      fallback: 'Arial, sans-serif'
    },
    shape: 'rounded',
    textColor: '#FFFFFF',
    backgroundColor: '#0375FF',
    borderColor: 'rgba(255, 255, 255, 0)',
    borderWidth: '1px',
    size: '13px',
    link: siteUrl,
    textDecoration: 'none'
  }
}

export function getEmbeddedFormSettingsTemplate(
  steps: OptinToolStep[],
  smsCountries: { countryCode: string; canSend: boolean }[],
  messagingType: 'one-way' | 'two-way'
): EmbeddedFormSettings {
  const template: EmbeddedFormSettings = {
    sequenceId: null,
    customCSS: '',
    displayRules: {
      location: {
        type: 'include',
        values:
          smsCountries
            ?.filter((country) => country.canSend)
            .map((country) => country.countryCode) ?? []
      }
    },
    desktop: {
      additionalImage: null,
      background: {
        image: {
          type: 'image',
          source: ''
        },
        color: '#FFFFFF'
      },
      padding: {
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px'
      },
      width: {
        type: 'full'
      },
      geotargetingError: {
        logo: {
          type: 'image',
          source: ''
        },
        heading: {
          type: 'text',
          text: "We're sorry",
          font: {
            type: 'google',
            family: 'Roboto',
            variant: '500',
            size: '32px',
            fallback: 'Arial, sans-serif'
          },
          color: '#9BA6B1',
          textDecoration: 'none'
        },
        description: {
          type: 'textarea',
          text: 'This form is not available at your location.',
          font: {
            type: 'google',
            family: 'Roboto',
            variant: '400',
            size: '18px',
            fallback: 'Arial, sans-serif'
          },
          color: '#9BA6B1',
          textDecoration: 'none'
        },
        button: null
      }
    },
    mobile: {
      additionalImage: null,
      background: {
        image: {
          type: 'image',
          source: ''
        },
        color: '#FFFFFF'
      },
      padding: {
        top: '0px',
        bottom: '0px',
        left: '0px',
        right: '0px'
      },
      width: {
        type: 'full'
      },
      geotargetingError: {
        logo: {
          type: 'copied',
          copyOf: 'desktop'
        },
        heading: {
          type: 'copied',
          copyOf: 'desktop'
        },
        description: {
          type: 'copied',
          copyOf: 'desktop'
        },
        button: {
          type: 'copied',
          copyOf: 'desktop'
        }
      }
    }
  }

  if (steps.includes('sms')) {
    template.desktop.sms = getDesktopSMSSettings(messagingType)
    template.mobile.sms = getMobileSMSSettings(messagingType)
  }

  if (steps.includes('email')) {
    template.desktop.email = getEmailDesktopSettings()
    template.mobile.email = {
      ...getEmailDesktopSettings(),
      logo: {
        type: 'copied',
        copyOf: 'desktop'
      },
      headingTop: {
        type: 'copied',
        copyOf: 'desktop'
      },
      headingMiddle: {
        type: 'copied',
        copyOf: 'desktop'
      },
      headingBottom: {
        type: 'copied',
        copyOf: 'desktop'
      },
      button: {
        type: 'copied',
        copyOf: 'desktop'
      },
      description: {
        type: 'copied',
        copyOf: 'desktop'
      }
    }

    template.mobile.sms = {
      ...template.mobile.sms,
      logo: {
        type: 'copied',
        copyOf: 'email'
      },
      headingTop: {
        type: 'copied',
        copyOf: 'email'
      },
      headingMiddle: {
        type: 'copied',
        copyOf: 'email'
      },
      headingBottom: {
        type: 'copied',
        copyOf: 'email'
      }
    }
  }

  if (steps.includes('smsWithEmail')) {
    template.desktop.smsWithEmail = {
      ...getDesktopSMSSettings(messagingType),
      email: {
        input: {
          type: 'optional-input',
          shape: 'rounded',
          required: true,
          defaultState: {
            font: {
              type: 'google',
              family: 'Roboto',
              variant: '400',
              size: '20px',
              fallback: 'Arial, sans-serif'
            },
            placeholderText: 'Enter your email',
            placeholderColor: '#9BA6B1',
            backgroundColor: '#FFFFFF',
            borderColor: '#9BA6B1'
          },
          activeState: {
            filledTextColor: '#243C6B',
            backgroundColor: '#FFFFFF',
            borderColor: '#0375FF'
          },
          errorState: {
            text: 'Please enter a valid email',
            textColor: '#FFFFFF',
            textBackgroundColor: '#FF6565',
            filledTextColor: '#FF6565',
            backgroundColor: '#FFF8F8',
            borderColor: '#FF6565'
          }
        }
      },
      description: {
        type: 'textarea',
        text:
          messagingType === 'one-way' ? oneWaySMSDescriptionText : twoWaySMSEmailDescriptionText,
        font: {
          type: 'google',
          family: 'Roboto',
          variant: '400',
          size: '10px',
          fallback: 'Arial, sans-serif'
        },
        color: '#6E7C97',
        textDecoration: 'none'
      }
    }

    template.mobile.smsWithEmail = {
      ...getMobileSMSSettings(messagingType),
      email: {
        input: {
          type: 'copied',
          copyOf: 'desktop'
        }
      }
    }
  }

  return template
}
