// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wStzgr26RuDat0Ovzwzl .ant-btn{height:32px;margin-right:10px}.wStzgr26RuDat0Ovzwzl .ant-btn:not([disabled]){border:1px solid #40a9ff;color:#40a9ff}.UtIjVFmCpKu_eSyMv19R{align-items:center;background:#fff;border-radius:10px;box-shadow:0 0 30px 0 rgba(0,0,0,.2);display:flex;height:156px;justify-content:center;width:320px}.sMYlQOsyFLJX4fvmjA1o{align-items:center;display:flex;flex-direction:column;height:85px;justify-content:space-between}.sMYlQOsyFLJX4fvmjA1o img{width:40px}.sMYlQOsyFLJX4fvmjA1o p{font-size:24px;margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/copy-button/copy-button.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAKM,+BACE,WAAA,CACA,iBAAA,CAEA,+CACE,wBAAA,CACA,aCLQ,CDWhB,sBAEE,kBAAA,CAKA,eAAA,CAHA,kBAAA,CAIA,oCAAA,CAPA,YAAA,CAKA,YAAA,CAHA,sBAAA,CAEA,WAGA,CAGF,sBAEE,kBAAA,CADA,YAAA,CAGA,qBAAA,CACA,WAAA,CAFA,6BAEA,CAGE,0BACE,UAAA,CAGF,wBAEE,cAAA,CADA,eACA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyButton": `wStzgr26RuDat0Ovzwzl`,
	"copied": `UtIjVFmCpKu_eSyMv19R`,
	"content": `sMYlQOsyFLJX4fvmjA1o`
};
export default ___CSS_LOADER_EXPORT___;
