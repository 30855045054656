import { Site } from '@ghostmonitor/recartapis'
import { FacebookAdAccount } from '../../../types/facebook/facebook-list-item.type'
import { FbMessengerConfig } from '../../../types/facebook/facebook-messenger-config.type'

export interface UserRole {
  _id: string | null
  id: string | null
  name: string | null
  updatedAt: string | null
  description: string | null
  permissions: string[]
  slug: string
}

export interface MeState {
  _id: string | null
  id: string | null
  createdAt: string | null
  updatedAt: string | null
  email: string | null
  firstName: string | null
  lastName: string | null
  role: UserRole | null
  site: SiteState
}

export const meInitialState: MeState = {
  _id: null,
  id: null,
  createdAt: null,
  updatedAt: null,
  email: null,
  firstName: null,
  lastName: null,
  role: null,
  site: {
    id: null,
    createdAt: null,
    updatedAt: null,
    username: null,
    name: null,
    onboardingCompleted: null,
    domain: null,
    engine: null,
    fbMessengerConfig: null,
    // ! Backend comes back with an empty object, and we are translating it to null
    currency: null,
    account: null,
    antiSpam: null,
    campaignItemLanguage: null,
    campaigns: null,
    emailSettings: null,
    shopify: null,
    stripe: null,
    trialEndsAt: null,
    wizardFinished: null,
    timeZone: null,
    urlGenerationSettings: null,
    tags: [],
    billingSettings: {
      smsCreditRollover: undefined,
      smsOverageLimit: 500
    }
  }
}

export type SiteState = Site & {
  fbMessengerConfig: FbMessengerConfig | null
  // todo remove fbAdAccountConfig completely
  fbAdAccountConfig?: FacebookAdAccount
}

export interface AccountState {
  address: string
  city: string
  company: string
  country: string
  industry: string[]
  phone: string
  state: string
  zipCode: string
}
