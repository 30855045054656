import { BlastScheduleType, SMSCampaignScheduleType } from '@ghostmonitor/recartapis'
import moment, { Moment } from 'moment'
import * as momentTimeZone from 'moment-timezone'

export function loadScheduledFor(
  scheduledFor: string | null | undefined,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType | undefined,
  timezone: string
): Moment {
  if (!scheduledFor) {
    return momentTimeZone.tz(new Date(), timezone).add(2, 'hours')
  }

  let scheduledForMoment = moment.tz(scheduledFor, timezone)

  if (scheduleType === 'subscriberTimezone') {
    scheduledForMoment = scheduledForMoment
      .utc()
      .subtract(moment.tz(timezone).utcOffset(), 'minutes')
      .tz(timezone)
  }

  return scheduledForMoment
}

export function saveScheduledFor(
  scheduledFor: string | undefined,
  scheduleType: SMSCampaignScheduleType | BlastScheduleType,
  timezone?: string
): Moment {
  if (!timezone) {
    timezone = moment.tz.guess()
  }

  if (!scheduledFor) {
    return momentTimeZone.tz(new Date(), timezone).add(2, 'hours')
  }

  let scheduledForMoment = moment.tz(scheduledFor, timezone)

  if (scheduleType === 'subscriberTimezone') {
    scheduledForMoment = scheduledForMoment.utc().add(moment.tz(timezone).utcOffset(), 'minutes')
  }

  return scheduledForMoment
}
