import {
  AdSetConfig,
  Blast,
  BlastScheduleType,
  BlastStatus,
  ButtonUI,
  Discount,
  DisplayMeta,
  LogicConditionalSplitCondition,
  MessageChange as MessagePayloadChange,
  MessengerGenericTemplatePayload,
  MessengerMessageUI,
  PortType,
  QuickReply,
  SequenceItemUI,
  SequenceUI,
  SerializedDiagram,
  TargetRulesBlast,
  UserInputValidationType
} from '@ghostmonitor/recartapis'
import { Action, SerializedError, createAction } from '@reduxjs/toolkit'
import { EntryPointType } from '../../../routes/SequenceEditor/components/custom-conversation-wizard/types/custom-conversation-wizard.type'
import { SequenceEditorSettings } from '../../../routes/SequenceEditor/settings/context/sequence-editor-settings.type'
import { TrayItem } from '../../../routes/SequenceEditor/types/tray-item.type'
import { withPayloadType } from '../../redux-helpers'

export const UPDATE_MODEL_ON_SEQUENCE = 'sequence-editor/UPDATE_MODEL_ON_SEQUENCE'

export const CREATE_FOLLOW_UP_PORT = 'sequence-editor/CREATE_FOLLOW_UP_PORT'

export const CREATE_SEQUENCE_ITEM = 'sequence-editor/CREATE_SEQUENCE_ITEM'
export const UPDATE_SEQUENCE_ITEM = 'sequence-editor/UPDATE_SEQUENCE_ITEM'
export const REMOVE_SEQUENCE_ITEM = 'sequence-editor/REMOVE_SEQUENCE_ITEM'
export const UPDATE_SEQUENCE_ITEM_TRIGGER = 'sequence-editor/UPDATE_SEQUENCE_ITEM_TRIGGER'

export const UPDATE_LOGIC_ADD_CONDITION = 'sequence-editor/UPDATE_LOGIC/ADD_CONDITION'
export const UPDATE_LOGIC_REMOVE_CONDITION = 'sequence-editor/UPDATE_LOGIC/REMOVE_CONDITION'
export const UPDATE_LOGIC_UPDATE_CONDITION = 'sequence-editor/UPDATE_LOGIC/UPDATE_CONDITION'
export const UPDATE_LOGIC_USER_INPUT_TYPE = 'sequence-editor/UPDATE_LOGIC_USER_INPUT_TYPE'

export const CREATE_MESSAGE = 'sequence-editor/CREATE_MESSAGE'
export const UPDATE_MESSAGE = 'sequence-editor/UPDATE_MESSAGE'
export const REMOVE_MESSAGE = 'sequence-editor/REMOVE_MESSAGE'
export const REORDER_MESSAGE = 'sequence-editor/REORDER_MESSAGE'

export const CREATE_BUTTON = 'sequence-editor/CREATE_BUTTON'
export const UPDATE_BUTTON = 'sequence-editor/UPDATE_BUTTON'
export const REMOVE_BUTTON = 'sequence-editor/REMOVE_BUTTON'

export const CREATE_MESSAGE_ITEM = 'sequence-editor/CREATE_MESSAGE_ITEM'
export const UPDATE_MESSAGE_ITEM = 'sequence-editor/UPDATE_MESSAGE_ITEM'
export const REMOVE_MESSAGE_ITEM = 'sequence-editor/REMOVE_MESSAGE_ITEM'

export const CREATE_QUICK_REPLY = 'sequence-editor/CREATE_QUICK_REPLY'
export const UPDATE_QUICK_REPLY = 'sequence-editor/UPDATE_QUICK_REPLY'
export const REMOVE_QUICK_REPLY = 'sequence-editor/REMOVE_QUICK_REPLY'
export const REORDER_QUICK_REPLY = 'sequence-editor/REORDER_QUICK_REPLY'

export const CREATE_SPLIT = 'sequence-editor/CREATE_SPLIT'
export const UPDATE_SPLIT = 'sequence-editor/UPDATE_SPLIT'
export const REMOVE_SPLIT = 'sequence-editor/REMOVE_SPLIT'

export const UPDATE_DELAY_VALUE = 'sequence-editor/UPDATE_DELAY_VALUE'
export const UPDATE_DISPLAY_META = 'sequence-editor/UPDATE_DISPLAY_META'

export const RENAME_BLAST = 'sequence-editor/renameBlast'
export const SCHEDULE_BLAST_FOR_NOW = 'sequence-editor/scheduleBlastForNow'
export const SCHEDULE_BLAST_FOR_LATER = 'sequence-editor/scheduleBlastForLater'
export const BLAST_SCHEDULED_CHANGED = 'sequence-editor/blastScheduledChanged'

export const initializeSetting = createAction(
  'sequence-editor/initializeSetting',
  withPayloadType<{ name: SequenceEditorSettings; description?: string }>()
)

export const setBlastStatus = createAction(
  'sequence-editor/setBlastStatus',
  withPayloadType<{ status: BlastStatus }>()
)

export const setBlastSubdomain = createAction(
  'sequence-editor/setBlastSubdomain',
  withPayloadType<{ subdomain: string }>()
)

export const toggleBlastQuietHours = createAction(
  'sequence-editor/toggleBlastQuietHours',
  withPayloadType<{ isEnabled: boolean }>()
)

export const toggleBlastSmartSending = createAction(
  'sequence-editor/toggleBlastSmartSending',
  withPayloadType<{ isEnabled: boolean }>()
)

export const setBlastScheduleType = createAction(
  'sequence-editor/setBlastScheduleType',
  withPayloadType<{ scheduleType: BlastScheduleType }>()
)

export const updateBlastTargetRules = createAction(
  'sequence-editor/updateBlastTargetRules',
  withPayloadType<{ targetType: keyof TargetRulesBlast; segmentIds: string[] }>()
)

export const removeBlastTargetRule = createAction(
  'sequence-editor/removeBlastTargetRule',
  withPayloadType<{ targetType: keyof TargetRulesBlast; segmentId: string }>()
)

export interface RenameBlastAction extends Action {
  name: string
}
export const renameBlast = createAction(RENAME_BLAST, withPayloadType<{ name: string }>())

export const updateBlast = createAction(
  'sequence-editor/updateBlast',
  withPayloadType<{ blast: Blast }>()
)

export const scheduleBlastForNow = createAction(SCHEDULE_BLAST_FOR_NOW)
export interface ScheduleBlastForLaterAction extends Action {
  scheduledFor: string
}

export const scheduleBlastForLater = createAction(
  SCHEDULE_BLAST_FOR_LATER,
  withPayloadType<{ scheduledFor: string }>()
)
export interface BlastScheduledChangedAction extends Action {
  scheduledFor: string
}

export const blastScheduledChanged = createAction(
  BLAST_SCHEDULED_CHANGED,
  withPayloadType<{ scheduledFor: string }>()
)

export const touchAllBlastFormItem = createAction('sequence-editor/touchAllBlastFormItem')
export const readOnlyMode = createAction('sequence-editor/readOnlyMode')

export const leaveReadOnlyMode = createAction('sequence-editor/leaveReadOnlyMode')

export const toggleSettingsDrawer = createAction(
  'sequence-editor/toggleSettingsDrawer',
  withPayloadType<{ isVisible: boolean }>()
)

export const mediaUploadCompleted = createAction(
  'sequence-editor/mediaUploadCompleted',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
    url: string
  }>()
)
export const mediaUploadStarted = createAction(
  'sequence-editor/mediaUploadStarted',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
  }>()
)

export const updateSequence = createAction(
  'sequence-editor/updateSequence',
  withPayloadType<{ sequence: SequenceUI }>()
)

export const updateSequenceCanConvert = createAction(
  'sequence-editor/updateSequenceCanConvert',
  withPayloadType<{ canConvert: boolean }>()
)

export const updateSequenceItem = createAction(
  'sequence-editor/updateSequenceItem',
  withPayloadType<{ sequenceItem: SequenceItemUI }>()
)

export const updateDiscountCodesOnSequenceItem = createAction(
  'sequence-editor/updateDiscountCodesOnSequenceItem',
  withPayloadType<{ sequenceItemId: string; discounts: Discount[] }>()
)

export const updateDiscountPoolIdsOnSequenceItem = createAction(
  'sequence-editor/updateDiscountPoolIdsOnSequenceItem',
  withPayloadType<{ sequenceItemId: string; poolIds: string[] }>()
)

export const clearSelection = createAction('sequence-editor/clearSelection')

export const focusMessage = createAction(
  'sequence-editor/focusMessage',
  withPayloadType<{ sequenceItemId: string; messageIndex: number; messageItemIndex?: number }>()
)

export const focusButton = createAction(
  'sequence-editor/focusButton',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
    buttonIndex: number
    messageItemIndex?: number
  }>()
)

export const startDragging = createAction(
  'sequence-editor/startDragging',
  withPayloadType<{
    itemType: TrayItem
  }>()
)
export const stopDragging = createAction('sequence-editor/stopDragging')

export const startLinking = createAction(
  'sequence-editor/startLinking',
  withPayloadType<{ portType: PortType; portParentSequenceItemId: string }>()
)
export const stopLinking = createAction('sequence-editor/stopLinking')

export interface FinishCustomConverationWizardPayload {
  facebookPageId: string
  entryPointType: EntryPointType
  sequenceId?: string
  facebookPostId?: string
  // adAccountId: string
  // adSetId: string
  // adId: string
  // adSetConfig: AdSetConfig
}
export interface FinishCustomConverationWizardAction extends Action {
  payload: FinishCustomConverationWizardPayload
}

export const finishCustomConversationWizard = createAction(
  'sequence-editor/finishCustomConversationWizard',
  withPayloadType<FinishCustomConverationWizardPayload>()
)

export const saveSequencePartialFailure = createAction(
  'sequence-editor/SAVE_SEQUENCE_PARTIAL_FAILURE',
  withPayloadType<SerializedError>()
)

export const toggleSequenceItemStatistics = createAction(
  'sequence-editor/toggleSequenceItemStatistics',
  withPayloadType<{ visible: boolean }>()
)

export const toggleDebugView = createAction(
  'sequence-editor/toggleDebugView',
  withPayloadType<{ visible: boolean }>()
)

export const settingsInitialized = createAction('sequence-editor/settingsInitialized')

export const localDraftChanged = createAction('sequence-editor/localDraftChanged')

export const galleryStepRight = createAction(
  'sequence-editor/GALLERY_STEP_RIGHT',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
  }>()
)

export const galleryStepLeft = createAction(
  'sequence-editor/GALLERY_STEP_LEFT',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
  }>()
)

export const galleryStepToIndex = createAction(
  'sequence-editor/GALLERY_STEP_TO_INDEX',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
    index: number
  }>()
)

export const sequenceLoadedFromServer = createAction('sequence-editor/sequenceLoadedFromServer')
export const sequenceLoadedFromDraft = createAction(
  'sequence-editor/sequenceLoadedFromDraft',
  withPayloadType<{ isEverSaved: boolean; unsavedChanges: boolean }>()
)

export const sequenceSaved = createAction(
  'sequence-editor/sequenceSaved',
  withPayloadType<{
    sequenceId: string
    optinToolId?: string
  }>()
)

export const sequenceCreated = createAction('sequence-editor/sequenceCreated')
export const sequenceExistsOnServer = createAction('sequence-editor/sequenceExistsOnServer')

export const sequenceItemValidationSuccess = createAction(
  'sequence-editor/sequenceItemValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
    }
  >()
)

export const sequenceItemValidationError = createAction(
  'sequence-editor/SEQUENCE_ITEM_VALIDATION_ERROR',
  withPayloadType<
    SerializedError,
    {
      sequenceItemId: string
    }
  >()
)

export const inlineDiscountCodeValidationSuccess = createAction(
  'sequence-editor/inlineDiscountCodeValidationSuccess',
  withPayloadType<{
    discountCode: string
  }>()
)

export const inlineDiscountCodeValidationErrorNotExists = createAction(
  'sequence-editor/inlineDiscountCodeValidationErrorNotExists',
  withPayloadType<{
    discountCode: string
  }>()
)

export const inlineDiscountCodeValidationErrorExpired = createAction(
  'sequence-editor/inlineDiscountCodeValidationErrorExpired',
  withPayloadType<{
    discountCode: string
  }>()
)

export const discountCodeTourStarted = createAction('sequence-editor/discountCodeTourStarted')

export const discountCodeTourFinished = createAction('sequence-editor/discountCodeTourFinished')

export const inlineVariableMarkedForManualReview = createAction(
  'sequence-editor/inlineVariableMarkedForManualReview',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
    variableIndex: number
  }>()
)

export const inlineVariableManualReviewSuccess = createAction(
  'sequence-editor/inlineVariableManualReviewSuccess',
  withPayloadType<{
    sequenceItemId: string
    messageIndex: number
    variableIndex: number
  }>()
)

export const inlineVariableManualReviewAborted = createAction(
  'sequence-editor/inlineVariableManualReviewFailure'
)

export const messageValidationSuccess = createAction(
  'sequence-editor/messageValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
      messageIndex: number
    }
  >()
)

export const messageValidationError = createAction(
  'sequence-editor/messageValidationError',
  withPayloadType<
    SerializedError[],
    {
      sequenceItemId: string
      messageIndex: number
    }
  >()
)

export const messageItemValidationSuccess = createAction(
  'sequence-editor/messageItemValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
      messageIndex: number
      messageItemIndex: number
    }
  >()
)

export const messageItemValidationError = createAction(
  'sequence-editor/messageItemValidationError',
  withPayloadType<
    SerializedError,
    {
      sequenceItemId: string
      messageIndex: number
      messageItemIndex: number
    }
  >()
)

export const buttonValidationSuccess = createAction(
  'sequence-editor/buttonValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
      messageIndex: number
      buttonIndex: number
      messageItemIndex?: number
    }
  >()
)

export const buttonValidationError = createAction(
  'sequence-editor/buttonValidationError',
  withPayloadType<
    SerializedError,
    {
      sequenceItemId: string
      messageIndex: number
      buttonIndex: number
      messageItemIndex?: number
    }
  >()
)

export const quickReplyValidationSuccess = createAction(
  'sequence-editor/quickReplyValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
      quickReplyIndex: number
    }
  >()
)

export const quickReplyValidationError = createAction(
  'sequence-editor/quickReplyValidationError',
  withPayloadType<
    SerializedError,
    {
      sequenceItemId: string
      quickReplyIndex: number
    }
  >()
)

export const splitValidationSuccess = createAction(
  'sequence-editor/splitValidationSuccess',
  withPayloadType<
    void,
    {
      sequenceItemId: string
      splitIndex: number
    }
  >()
)

export const splitValidationError = createAction(
  'sequence-editor/splitValidationError',
  withPayloadType<
    SerializedError,
    {
      sequenceItemId: string
      splitIndex: number
    }
  >()
)

export const blastValidationSuccess = createAction(
  'sequence-editor/blastValidationSuccess',
  withPayloadType<
    void,
    {
      key: string
    }
  >()
)

export const blastValidationError = createAction(
  'sequence-editor/blastValidationError',
  withPayloadType<
    SerializedError,
    {
      key: string
    }
  >()
)

export const dropSMSMedia = createAction(
  'sequence-editor/dropSMSMedia',
  withPayloadType<void, { sequenceItemId: string }>()
)

export const dropSMSContactCard = createAction(
  'sequence-editor/dropSMSContactCard',
  withPayloadType<void, { sequenceItemId: string }>()
)

export const removeSequence = createAction('removeSequence')
export const touchAllQuickReplies = createAction('touchAllQuickReplies')
export const touchAllSequenceItems = createAction('touchAllSequenceItems')
export const touchAllSplits = createAction('touchAllSplits')
export const facebookCommentsPostIdChanged = createAction(
  'facebookCommentsPostIdChanged',
  withPayloadType<{ postId: string; pageId: string }>()
)

export interface UpdateModelOnSequenceAction extends Action {
  serializedDiagram: SerializedDiagram
}

export function updateModelOnSequence(
  serializedDiagram: SerializedDiagram
): UpdateModelOnSequenceAction {
  return {
    type: UPDATE_MODEL_ON_SEQUENCE,
    serializedDiagram
  }
}

export interface CreateSequenceItemAction extends Action {
  sequenceItem: SequenceItemUI
  points?: { x: number; y: number }
  displayMeta?: DisplayMeta
}

export const createSequenceItem = (
  sequenceItem: SequenceItemUI,
  points?: { x: number; y: number },
  displayMeta?: DisplayMeta
): CreateSequenceItemAction => {
  return {
    type: CREATE_SEQUENCE_ITEM,
    sequenceItem,
    points,
    displayMeta
  }
}
export interface RemoveSequenceItemAction extends Action {
  sequenceItemId: string
}
export const removeSequenceItem = (sequenceItemId: string): RemoveSequenceItemAction => {
  return {
    type: REMOVE_SEQUENCE_ITEM,
    sequenceItemId
  }
}

export interface CreateMessageAction extends Action {
  sequenceItemId: string
  message: MessengerMessageUI
  messageIndex?: number
}

export const createMessage = (
  sequenceItemId: string,
  message: MessengerMessageUI,
  messageIndex?: number
): CreateMessageAction => {
  return {
    type: CREATE_MESSAGE,
    sequenceItemId,
    message,
    messageIndex
  }
}

export interface UpdateMessageAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messagePayloadChange: MessagePayloadChange
}

export const updateMessage = (
  sequenceItemId: string,
  messageIndex: number,
  messagePayloadChange: MessagePayloadChange
): UpdateMessageAction => {
  return {
    type: UPDATE_MESSAGE,
    sequenceItemId,
    messageIndex,
    messagePayloadChange
  }
}

export interface RemoveMessageAction extends Action {
  sequenceItemId: string
  messageIndex: number
}

export const removeMessage = (
  sequenceItemId: string,
  messageIndex: number
): RemoveMessageAction => {
  return {
    type: REMOVE_MESSAGE,
    sequenceItemId,
    messageIndex
  }
}

export interface ReorderMessageAction extends Action {
  sequenceItemId: string
  oldIndex: number
  newIndex: number
}

export const reorderMessage = (
  sequenceItemId: string,
  oldIndex: number,
  newIndex: number
): ReorderMessageAction => {
  return {
    type: REORDER_MESSAGE,
    sequenceItemId,
    oldIndex,
    newIndex
  }
}

export interface CreateButtonAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex?: number
  buttonIndex?: number
  button?: ButtonUI
  siteUrl?: string
}

export const createButton = (
  sequenceItemId: string,
  messageIndex: number,
  buttonIndex: number,
  messageItemIndex?: number,
  siteUrl?: string
): CreateButtonAction => {
  return {
    type: CREATE_BUTTON,
    sequenceItemId,
    messageIndex,
    messageItemIndex,
    buttonIndex,
    siteUrl
  }
}

export interface UpdateButtonAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex?: number
  buttonIndex: number
  button: Partial<ButtonUI>
  isShopify?: boolean
}

export const updateButton = (
  sequenceItemId: string,
  messageIndex: number,
  buttonIndex: number,
  button: Partial<ButtonUI>,
  messageItemIndex?: number,
  isShopify?: boolean
): UpdateButtonAction => {
  return {
    type: UPDATE_BUTTON,
    sequenceItemId,
    messageIndex,
    messageItemIndex,
    buttonIndex,
    button,
    isShopify
  }
}

export interface RemoveButtonAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex?: number
  buttonIndex: number
}

export const removeButton = (
  sequenceItemId: string,
  messageIndex: number,
  buttonIndex: number,
  messageItemIndex?: number
): RemoveButtonAction => {
  return {
    type: REMOVE_BUTTON,
    sequenceItemId,
    messageIndex,
    buttonIndex,
    messageItemIndex
  }
}

export interface CreateMessageItemAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex?: number
  messageItemPayload?: MessengerGenericTemplatePayload
}

export const createMessageItem = (
  sequenceItemId: string,
  messageIndex: number,
  messageItemIndex: number,
  messageItemPayload?: MessengerGenericTemplatePayload
): CreateMessageItemAction => {
  return {
    type: CREATE_MESSAGE_ITEM,
    sequenceItemId,
    messageIndex,
    messageItemIndex,
    messageItemPayload
  }
}

export interface UpdateMessageItemAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex: number
  messagePayloadChange: MessagePayloadChange
}

export const updateMessageItem = (
  sequenceItemId: string,
  messageIndex: number,
  messageItemIndex: number,
  messagePayloadChange: MessagePayloadChange
): UpdateMessageItemAction => {
  return {
    type: UPDATE_MESSAGE_ITEM,
    sequenceItemId,
    messageIndex,
    messageItemIndex,
    messagePayloadChange
  }
}

export interface RemoveMessageItemAction extends Action {
  sequenceItemId: string
  messageIndex: number
  messageItemIndex: number
}

export const removeMessageItem = (
  sequenceItemId: string,
  messageIndex: number,
  messageItemIndex: number
): RemoveMessageItemAction => {
  return {
    type: REMOVE_MESSAGE_ITEM,
    sequenceItemId,
    messageIndex,
    messageItemIndex
  }
}

export interface CreateQuickReplyAction extends Action {
  sequenceItemId: string
  quickReply: QuickReply
}

export const createQuickReply = (
  sequenceItemId: string,
  quickReply: QuickReply
): CreateQuickReplyAction => {
  return {
    type: CREATE_QUICK_REPLY,
    sequenceItemId,
    quickReply
  }
}

export interface RemoveQuickReplyAction extends Action {
  sequenceItemId: string
  quickReplyIndex: number
}

export const removeQuickReply = (
  sequenceItemId: string,
  quickReplyIndex: number
): RemoveQuickReplyAction => {
  return {
    type: REMOVE_QUICK_REPLY,
    sequenceItemId,
    quickReplyIndex
  }
}

export interface UpdateQuickReplyAction extends Action {
  sequenceItemId: string
  quickReplyIndex: number
  title: string
}

export const updateQuickReply = (
  sequenceItemId: string,
  quickReplyIndex: number,
  title: string
): UpdateQuickReplyAction => {
  return {
    type: UPDATE_QUICK_REPLY,
    sequenceItemId,
    quickReplyIndex,
    title
  }
}

export interface ReorderQuickReplyAction extends Action {
  sequenceItemId: string
  oldIndex: number
  newIndex: number
}

export const reorderQuickReply = (
  sequenceItemId: string,
  oldIndex: number,
  newIndex: number
): ReorderQuickReplyAction => {
  return {
    type: REORDER_QUICK_REPLY,
    sequenceItemId,
    oldIndex,
    newIndex
  }
}

export interface CreateSplitAction extends Action {
  sequenceItemId: string
}

export function createSplit(sequenceItemId: string): CreateSplitAction {
  return {
    type: CREATE_SPLIT,
    sequenceItemId
  }
}

export interface UpdateSplitAction extends Action {
  sequenceItemId: string
  splitIndex: number
  percentage: number
}

export function updateSplit(
  sequenceItemId: string,
  splitIndex: number,
  percentage: number
): UpdateSplitAction {
  return {
    type: UPDATE_SPLIT,
    sequenceItemId,
    splitIndex,
    percentage
  }
}

export interface RemoveSplitAction extends Action {
  sequenceItemId: string
  splitIndex: number
}

export function removeSplit(sequenceItemId: string, splitIndex: number): RemoveSplitAction {
  return {
    type: REMOVE_SPLIT,
    sequenceItemId,
    splitIndex
  }
}

export interface UpdateLogicAction extends Action {
  sequenceItemId: string
  condition?: LogicConditionalSplitCondition
  index?: number
  userInputType?: UserInputValidationType
}

export function addCondition(
  sequenceItemId: string,
  condition: LogicConditionalSplitCondition
): UpdateLogicAction {
  return {
    type: UPDATE_LOGIC_ADD_CONDITION,
    condition,
    sequenceItemId
  }
}

export function removeCondition(sequenceItemId: string, index: number): UpdateLogicAction {
  return {
    type: UPDATE_LOGIC_REMOVE_CONDITION,
    index,
    sequenceItemId
  }
}

export function updateCondition(
  sequenceItemId: string,
  index: number,
  condition: LogicConditionalSplitCondition
): UpdateLogicAction {
  return {
    type: UPDATE_LOGIC_UPDATE_CONDITION,
    condition,
    index,
    sequenceItemId
  }
}

export interface UpdateUserInputTypeAction extends Action {
  sequenceItemId: string
  userInputType: UserInputValidationType
  siteUrl: string
}

export function updateUserInputType(
  sequenceItemId: string,
  userInputType: UserInputValidationType,
  siteUrl: string
): UpdateUserInputTypeAction {
  return {
    type: UPDATE_LOGIC_USER_INPUT_TYPE,
    userInputType,
    sequenceItemId,
    siteUrl
  }
}

export interface CreateFollowUpPortAction extends Action {
  sequenceItemId: string
}

export function createFollowUpPort(sequenceItemId: string): CreateFollowUpPortAction {
  return {
    type: CREATE_FOLLOW_UP_PORT,
    sequenceItemId
  }
}

export interface UpdateDelayValue extends Action {
  sequenceItemId: string
  value: number
}

export function updateDelayValue(sequenceItemId: string, value: number): UpdateDelayValue {
  return {
    type: UPDATE_DELAY_VALUE,
    sequenceItemId,
    value
  }
}

export interface UpdateDisplayMetaAction extends Action {
  sequenceItemId: string
  displayMeta: DisplayMeta
}

export function updateDisplayMeta(
  sequenceItemId: string,
  displayMeta: DisplayMeta
): UpdateDisplayMetaAction {
  return {
    type: UPDATE_DISPLAY_META,
    sequenceItemId,
    displayMeta
  }
}
