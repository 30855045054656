import moment from 'moment'

export enum TimeFrameOption {
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  DAYS7 = 'DAYS7',
  DAYS30 = 'DAYS30',
  MONTH1 = 'MONTH1',
  ALLTIME = 'ALLTIME',
  CALENDAR = 'CALENDAR'
}

export const timeFrameOptions = {
  [TimeFrameOption.YESTERDAY]: {
    title: 'Yesterday',
    from: moment.utc().subtract(1, 'days').startOf('day'),
    to: moment.utc().subtract(1, 'days').endOf('day')
  },
  [TimeFrameOption.TODAY]: {
    title: 'Today',
    from: moment.utc().startOf('day'),
    to: moment.utc().endOf('day')
  },
  [TimeFrameOption.DAYS7]: {
    title: 'Previous 7 days',
    from: moment.utc().subtract(7, 'days').startOf('day'),
    to: moment.utc().subtract(1, 'days').endOf('day')
  },
  [TimeFrameOption.DAYS30]: {
    title: 'Previous 30 days',
    from: moment.utc().subtract(30, 'days').startOf('day'),
    to: moment.utc().subtract(1, 'days').endOf('day')
  },
  [TimeFrameOption.MONTH1]: {
    title: 'This Month',
    from: moment.utc().startOf('month').startOf('day'),
    to: moment.utc().endOf('day')
  },
  [TimeFrameOption.ALLTIME]: {
    title: 'All time',
    from: null,
    to: null
  },
  [TimeFrameOption.CALENDAR]: {
    title: 'Custom range',
    from: moment(),
    to: moment()
  }
}
