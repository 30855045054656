import { Input as AntInput, InputNumber as AntInputNumber } from 'antd'
import type { InputProps as AntInputProps, InputRef } from 'antd/lib/input'
import type { InputNumberProps as AntInputNumberProps } from 'antd/lib/input-number'
import cn from 'classnames'
import React from 'react'
import { TextSkeleton } from '../loader/text-skeleton.component'
import styles from './input.component.scss'

type InputProps = AntInputProps & {
  isLoading?: boolean
  isError?: boolean
}

export const Input = React.forwardRef(function Input(props: InputProps, ref: React.Ref<InputRef>) {
  const { className, width, isLoading, isError, ...restProps } = props

  if (isLoading) {
    return <TextSkeleton size='h1' width={width as number} isLoading />
  }

  return (
    <AntInput
      style={{ width }}
      ref={ref}
      data-testid='input'
      className={cn(styles.input, className, { [styles.inputError]: isError })}
      size='large'
      {...restProps}
    />
  )
})

export function PasswordInput(props: InputProps) {
  const { className, width, isLoading, isError, ...restProps } = props

  if (isLoading) {
    return <TextSkeleton size='h1' width={width as number} isLoading />
  }

  return (
    <AntInput.Password
      style={{ width }}
      data-testid='input-password'
      className={cn(styles.input, className, { [styles.inputError]: isError })}
      size='large'
      {...restProps}
    />
  )
}

export function InputNumber(props: AntInputNumberProps) {
  const { className, width, onChange, ...restProps } = props

  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    if (!event.target.value && onChange !== undefined) {
      onChange(Math.max(Number(props.min), 0))
    }
  }

  return (
    <AntInputNumber
      style={{ width }}
      className={cn(styles.inputNumber, className)}
      size='large'
      onChange={onChange}
      onBlur={handleBlur}
      {...restProps}
    />
  )
}
