import {
  convertSequenceAPIToSequenceUI,
  convertSequenceItemAPIToSequenceItemUI,
  isConditionalSplitV2SequenceItemAPI,
  isMessengerMessageSequenceItemUI,
  SequenceAPI,
  SequenceUI
} from '@ghostmonitor/recartapis'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ObjectId } from 'bson'
import { batch } from 'react-redux'
import { DiagramModel } from 'storm-react-diagrams'
import { attachQuickRepliesIdsOnSequenceItem } from '../../../../routes/SequenceEditor/middlewares/utils/attach-id-to-quick-replies'
import { SequenceEditorError } from '../../../../routes/SequenceEditor/types/sequence-editor-errors'
import {
  getLocalDebugView,
  getLocalSequenceEditorInstance,
  getLocalSequenceItem
} from '../../../../routes/SequenceEditor/utils/localstorage'
import { history } from '../../../../routing-utilities/history/history'
import { DashboardErrorMessages } from '../../../../types/dashboard-errors'
import { api } from '../../../../utils/api'
import { createScope } from '../../../../utils/logger/logger'
import { Notification } from '../../../../utils/notification/notification.util'
import { serializedError } from '../../../../utils/serialized-error'
import type { AppDispatch } from '../../../create-store'
import { DashboardState } from '../../../dashboard.state'
import { selectEditorSequence, selectLaunchDarklyFlag } from '../../../selectors'
import {
  sequenceCreated,
  sequenceExistsOnServer,
  sequenceLoadedFromDraft,
  sequenceLoadedFromServer,
  toggleDebugView,
  updateSequence,
  updateSequenceItem
} from '../sequence-editor.actions'

const { error } = createScope('sequence-editor')

export interface LoadEditorArgs {
  sequenceId?: string
}

export const loadEditorThunk = createAsyncThunk<
  void,
  LoadEditorArgs,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/loadEditor', async (args, store) => {
  const dispatch = store.dispatch
  const state = store.getState()
  const { sequenceId } = args

  let forceLoadFromServer = selectLaunchDarklyFlag('force-reload-sequence-editor')(state) ?? false

  if (sequenceId) {
    let sequenceOnServer: SequenceAPI
    const isSequenceExistsOnServer = await api.sequenceExistsById(sequenceId)
    const localSequenceEditorInstance = getLocalSequenceEditorInstance(sequenceId)
    const showDebugView = getLocalDebugView()
    if (showDebugView !== null) {
      dispatch(toggleDebugView({ visible: showDebugView }))
    }

    if (isSequenceExistsOnServer) {
      sequenceOnServer = await api.getSequenceById(sequenceId)

      dispatch(sequenceExistsOnServer())

      if (localSequenceEditorInstance !== null) {
        forceLoadFromServer =
          new Date(sequenceOnServer.updatedAt).getTime() >
          new Date(localSequenceEditorInstance.serverUpdatedAt).getTime()
      }
    }

    // Load from localStorage
    if (localSequenceEditorInstance !== null && !forceLoadFromServer) {
      const { sequence, isEverSaved, unsavedChanges } = localSequenceEditorInstance

      batch(() => {
        const sequenceInStore = selectEditorSequence(state)
        if (!sequenceInStore) {
          dispatch(updateSequence({ sequence }))

          sequence.sequenceItemIds.forEach((sequenceItemId) => {
            const sequenceItem = getLocalSequenceItem(sequenceItemId)
            dispatch(updateSequenceItem({ sequenceItem }))
          })
        }

        dispatch(sequenceLoadedFromDraft({ isEverSaved, unsavedChanges }))
      })

      // Load from server
    } else {
      if (!isSequenceExistsOnServer) {
        error(new Error('SequenceDoesntExistOnServer'), { sequenceId })
        Notification.error(DashboardErrorMessages.COULDNT_LOAD_FLOW)
        return
      }

      const hasConditionalSplitV2 = sequenceOnServer.sequenceItems?.some((sequenceItem) =>
        isConditionalSplitV2SequenceItemAPI(sequenceItem)
      )

      if (hasConditionalSplitV2) {
        const errorMessage = SequenceEditorError.FlowNotCompatible
        error(errorMessage, { sequenceId })
        throw serializedError(errorMessage)
      }

      batch(() => {
        // Update sequence in redux
        dispatch(updateSequence({ sequence: convertSequenceAPIToSequenceUI(sequenceOnServer) }))

        // Update all sequence items
        sequenceOnServer.sequenceItems.forEach((sequenceItemAPI) => {
          let sequenceItem = convertSequenceItemAPIToSequenceItemUI(sequenceItemAPI)
          if (isMessengerMessageSequenceItemUI(sequenceItem)) {
            sequenceItem = attachQuickRepliesIdsOnSequenceItem(
              sequenceItem,
              sequenceOnServer.quickReplyIndexToId
            )
          }
          dispatch(updateSequenceItem({ sequenceItem }))
        })

        dispatch(sequenceLoadedFromServer())
      })
    }
    // Create new sequence
  } else {
    const newSequenceId = new ObjectId().toHexString()
    const diagramModel = new DiagramModel()

    const sequence: SequenceUI = {
      _id: newSequenceId,
      siteId: state.me.site.id,
      isEnabled: false,
      serializedDiagram: diagramModel.serializeDiagram() as any,
      sequenceItemIds: [],
      name: 'New Sequence',
      tags: [],
      entrySequenceItemId: null,
      quickReplyIndexToId: {}
    }

    batch(() => {
      dispatch(updateSequence({ sequence }))
      dispatch(sequenceCreated())
    })

    history.replace(`/sequence-editor/${newSequenceId}`)
  }
})
