// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noVXnIUBNq2HoLdqIkaF{color:#595959;font-family:Lato,sans-serif;font-size:14px;width:188px}.noVXnIUBNq2HoLdqIkaF .BvbJsLlk9873CxUDxD2P{color:#595959}.noVXnIUBNq2HoLdqIkaF .BvbJsLlk9873CxUDxD2P input{color:#595959;font-size:12px;height:24px}.noVXnIUBNq2HoLdqIkaF ._Bpg8mPEeaxraj1YBo0W{font-size:9px;margin-top:2px}.noVXnIUBNq2HoLdqIkaF .e3ZHweTWfMRKikPNCUma{color:var(--recart-grey-5);font-size:14px}.noVXnIUBNq2HoLdqIkaF .e3ZHweTWfMRKikPNCUma span{text-decoration:underline}.noVXnIUBNq2HoLdqIkaF .kDTJyMOTHxC31KP145rn{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/inline-editor/components/make-variable-input/variable-popover.style.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,sBAGE,aCYK,CDdL,2BAAA,CAGA,cAAA,CAFA,WAEA,CAEA,4CACE,aCQG,CDNH,kDAEE,aCIC,CDLD,cAAA,CAEA,WAAA,CAIJ,4CAEE,aAAA,CADA,cACA,CAGF,4CAEE,0BAAA,CADA,cACA,CAEA,iDACE,yBAAA,CAIJ,4CACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"variablePopoverContainer": `noVXnIUBNq2HoLdqIkaF`,
	"input": `BvbJsLlk9873CxUDxD2P`,
	"inputSuffix": `_Bpg8mPEeaxraj1YBo0W`,
	"backButton": `e3ZHweTWfMRKikPNCUma`,
	"saveButton": `kDTJyMOTHxC31KP145rn`
};
export default ___CSS_LOADER_EXPORT___;
