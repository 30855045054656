import { SMSCampaign, SMSCampaignListResponse } from '@ghostmonitor/recartapis'
import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { createScope } from '../../utils/logger/logger'
import { Notification } from '../../utils/notification/notification.util'
import { request } from '../../utils/request'
import { getSMSCampaignUIStatus } from '../../utils/sms/get-sms-campaign-ui-status'
import {
  PatchResourceArgs,
  RemoveResourceArgs,
  RenameResourceArgs,
  UseResource
} from '../types/use-resource.type'
import { QUERY_NAME } from './query-names'
import { useFlows } from './use-flows'
const logger = createScope('dashboard')

async function remove({ id }: RemoveResourceArgs) {
  await request.del<SMSCampaign>(`sms-campaigns/${id}`)
}

async function patch({ id, payload }: PatchResourceArgs<Partial<SMSCampaign>>) {
  await request.patch<SMSCampaign>(`sms-campaigns/${id}`, payload)
}

interface SMSCampaignListQueryParams {
  segmentId: string
}

export function useSMSCampaigns(
  segmentId?: string,
  options?: UseQueryOptions<SMSCampaign[]>
): UseResource<SMSCampaign[], Partial<SMSCampaign>> {
  const queryClient = useQueryClient()
  const { patch: patchFlow } = useFlows()

  const params: SMSCampaignListQueryParams = useMemo(
    () => ({
      ...(segmentId && { segmentId })
    }),
    [segmentId]
  )

  const queryName = useMemo(() => [QUERY_NAME.smsCampaign, params], [params])

  const {
    isLoading,
    data: smsCampaigns,
    isError
  } = useQuery(
    queryName,
    async () => {
      const response = await request.get<SMSCampaignListResponse>('sms-campaigns', {
        params
      })
      return response.data
    },
    options
  )

  const smsCampaignsWithUIStatus = smsCampaigns?.map((smsCampaign) => {
    return { ...smsCampaign, status: getSMSCampaignUIStatus(smsCampaign) }
  })

  const handlePatchMutate = useCallback(
    (patchPayload: PatchResourceArgs<SMSCampaign>) => {
      const smsCampaignsQuery = queryClient.getQueryCache().find(queryName)
      const previousSMSCampaigns = cloneDeep(smsCampaignsQuery.state.data as SMSCampaign[])

      const newData: SMSCampaign[] = previousSMSCampaigns.map((item) => {
        if (item._id === patchPayload.id) {
          return { ...item, ...patchPayload.payload }
        }
        return item
      })

      queryClient.setQueryData(queryName, newData)

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousSMSCampaigns)
    },
    [queryClient, queryName]
  )

  const handleRemove = useCallback(
    (removePayload: RemoveResourceArgs) => {
      queryClient.cancelQueries(queryName)

      const dataQuery = queryClient.getQueryCache().find(queryName)
      const previousData = cloneDeep(dataQuery.state.data as SMSCampaign[])

      const newData: SMSCampaign[] = previousData.filter((item) => item._id !== removePayload.id)

      queryClient.setQueryData(queryName, newData)

      return () => queryClient.setQueryData(queryName, previousData)
    },
    [queryClient, queryName]
  )

  const handleRenameMutate = useCallback(
    (renamePayload: RenameResourceArgs) => {
      const smsCampaignQuery = queryClient.getQueryCache().find(queryName)
      const previousSMSCampaigns = cloneDeep(smsCampaignQuery.state.data as SMSCampaign[])

      const newSMSCampaigns = cloneDeep(smsCampaignQuery.state.data as SMSCampaign[])
      const smsCampaign = newSMSCampaigns.find(
        (smsCampaign) => smsCampaign._id === renamePayload.id
      )
      smsCampaign.name = renamePayload.name

      // TODO patch localstorage too

      queryClient.setQueryData(queryName, newSMSCampaigns)

      // Return a rollback function
      return () => queryClient.setQueryData(queryName, previousSMSCampaigns)
    },
    [queryClient, queryName]
  )

  const rename = useCallback(
    async ({ id, name }: RenameResourceArgs) => {
      const smsCampaign = smsCampaigns.find((smsCampaign) => smsCampaign._id === id)
      if (!smsCampaign) {
        throw new Error('SMS Campaign not found')
      }
      const flowId = smsCampaign.sequenceId
      await patchFlow({ id: flowId, payload: { name } })
    },
    [smsCampaigns, patchFlow]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(queryName)
  }, [queryClient, queryName])

  const handlePatchError = useCallback((err, newSMSCampaign, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Updating your SMS campaign failed. Please try again or contact support.')
  }, [])

  const handleError = useCallback((err, _, rollback: () => void) => {
    logger.error(err)
    rollback()
    Notification.error('Removing your SMS campaign failed. Please try again or contact support.')
  }, [])

  const { mutateAsync: mutatePatchSMSCampaign } = useMutation<
    void,
    unknown,
    PatchResourceArgs<SMSCampaign>
  >(patch, {
    onMutate: handlePatchMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  const { mutateAsync: mutateRemoveSMSCampaign } = useMutation<void, unknown, RemoveResourceArgs>(
    remove,
    {
      onMutate: handleRemove,
      onSettled: handleSettled,
      onError: handleError
    }
  )

  const { mutateAsync: mutateRenameSMSCampaign } = useMutation<
    void,
    unknown,
    { id: string; name: string }
  >(rename, {
    onMutate: handleRenameMutate,
    onSettled: handleSettled,
    onError: handlePatchError
  })

  return {
    data: smsCampaignsWithUIStatus,
    isLoading,
    isError,
    removeFromList: mutateRemoveSMSCampaign,
    patch: mutatePatchSMSCampaign,
    rename: mutateRenameSMSCampaign
  }
}
