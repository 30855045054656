import React from 'react'
import { useSelector } from 'react-redux'
import { useSiteSelector } from '../../../../hooks/use-site-selector'
import { selectEditorBlast, selectIsReadOnly } from '../../../../store/selectors'
import { selectUrlGenerationSettings } from '../../../../store/slices/site/site.selectors'
import { getSubdomainInUseSequenceEditor } from '../../utils/get-subdomain-in-use'
import { makeDiscountCodeUnique } from './components/make-discount-code/make-discount-code-unique'
import { makeEmojiPicker } from './components/make-emoji-picker/make-emoji-picker'
import { makeLinkShortening } from './components/make-link-shortening/make-link-shortening.hoc'
import { makeVariableInput } from './components/make-variable-input/make-variable-input'
import { PluginableInlineEditor } from './components/pluginable-inline-editor/pluginable-inline-editor.component'
import { InlineEditorProps } from './use-inline-editor-hoc-control.hook'

export type InlineEditorType = 'basic' | 'messenger' | 'sms' | 'sms-legal' | 'sms-discount'

export interface InlineEditorWrapperProps extends InlineEditorProps {
  type: InlineEditorType
}

const BasicInlineEditor = makeEmojiPicker(PluginableInlineEditor, {
  showCharacterCounter: true
})
const MessengerInlineEditor = makeVariableInput(
  makeEmojiPicker(PluginableInlineEditor, { showCharacterCounter: true })
)
const SMSInlineEditor = makeVariableInput(
  makeLinkShortening(makeEmojiPicker(PluginableInlineEditor, { showCharacterCounter: false }))
)
const SMSDiscountUniqueInlineEditor = makeDiscountCodeUnique(SMSInlineEditor)
const SMSLegalInlineEditor = makeVariableInput(PluginableInlineEditor)

export const InlineEditor = React.memo(function InlineEditor(props: InlineEditorWrapperProps) {
  const blast = useSelector(selectEditorBlast)
  const isReadOnly = useSelector(selectIsReadOnly)
  const urlGenerationSettings = useSiteSelector(selectUrlGenerationSettings)

  const subdomain: string = getSubdomainInUseSequenceEditor(
    isReadOnly,
    blast,
    urlGenerationSettings
  )

  switch (props.type) {
    case 'basic':
      return <BasicInlineEditor {...props} />
    case 'messenger':
      return <MessengerInlineEditor {...props} />
    case 'sms':
      return <SMSInlineEditor {...props} subdomain={subdomain} />
    case 'sms-discount':
      return <SMSDiscountUniqueInlineEditor {...props} subdomain={subdomain} />
    case 'sms-legal':
      return <SMSLegalInlineEditor {...props} subdomain={subdomain} />
    default:
      return null
  }
})
