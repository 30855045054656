import {
  createHttpError,
  isHttpErrorResponse as libErrorIsHttpErrorResponse
} from '@ghostmonitor/lib-error'
import { HttpError } from '@ghostmonitor/recartapis'
import { SerializedError } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

export function isHttpErrorResponse(response: AxiosResponse): boolean {
  return libErrorIsHttpErrorResponse(response?.data)
}

export function getSerializedError(error: Error): SerializedError {
  return {
    name: error.name,
    message: error.message
  }
}

export function getSerializedErrorFromAxiosResponse(response: AxiosResponse): SerializedError {
  if (!isHttpErrorResponse(response)) {
    return {}
  }
  const { status, data } = response
  const httpError = createHttpError(status, data.error.title, data.error.detail).toObject()

  return {
    name: httpError.title,
    message: (httpError as HttpError).detail
  }
}
