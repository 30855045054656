import { useMemo } from 'react'
import { selectSiteEngineSlug } from '../store/slices/site/site.selectors'
import { hooks } from './hooks'

export function useSupportedSiteEngines<T extends { supportedSiteEngines: string[] }>(
  items: T[]
): T[] {
  const siteEngineSlug = hooks.useSiteSelector(selectSiteEngineSlug)

  return useMemo(() => {
    if (!items || siteEngineSlug === undefined) {
      return items
    }

    return items.filter((item) => item.supportedSiteEngines.includes(siteEngineSlug))
  }, [items, siteEngineSlug])
}
