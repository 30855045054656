import {
  Channel,
  LogicRandomSplitVariant,
  LogicType,
  NodeType,
  PortType,
  RandomSplitSequenceItemUI,
  SequenceItemType
} from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { LogicPortModel } from '../../port/logic-port-model'
import { BaseSequenceItemNodeModel } from '../base-sequence-item-model'

export const DEFAULT_SPLIT_VALUE: LogicRandomSplitVariant = Object.freeze({
  percentage: 50,
  trigger: null
})

export function generateSplitPortName(sequenceItemId: string, index: number) {
  return `${sequenceItemId}-${index}`
}

export function getNewRandomSplitSequenceItem(
  siteId: string,
  channel: Channel
): RandomSplitSequenceItemUI {
  const sequenceItemId = new ObjectId().toHexString()
  const now = new Date().toISOString()

  return {
    _id: sequenceItemId,
    type: SequenceItemType.LOGIC,
    siteId,
    trigger: [sequenceItemId],
    createdAt: now,
    updatedAt: now,
    canConvert: false,
    channel,
    tags: [],
    logic: {
      type: LogicType.RANDOM_SPLIT,
      randomSplit: {
        isSticky: false,
        variants: [{ ...DEFAULT_SPLIT_VALUE }, { ...DEFAULT_SPLIT_VALUE }]
      }
    }
  }
}

export class RandomSplitSequenceItemNodeModel extends BaseSequenceItemNodeModel {
  constructor(sequenceItemId?: string, sequenceItem?: RandomSplitSequenceItemUI, id?: string) {
    super(NodeType.RANDOM_SPLIT_SEQUENCE_ITEM, sequenceItemId, id)

    if (sequenceItem) {
      sequenceItem.logic.randomSplit.variants.forEach(() => {
        this.createSplitPort()
      })
    }
  }

  private generatePorts() {
    this.createSplitPort()
    this.createSplitPort()
  }

  public generateSplitPortName(sequenceItemId: string, index: number) {
    return generateSplitPortName(sequenceItemId, index)
  }

  public createSplitPort() {
    const index = Object.values(this.getPortsByType(PortType.LOGIC)).length
    const name = this.generateSplitPortName(this.sequenceItemId, index)

    this.addPort(new LogicPortModel(name))
  }

  public removeSplitPort(name: string) {
    const portToDelete = this.getPort(name) as LogicPortModel
    const links = portToDelete.getLinks()

    Object.values(links).forEach((link) => link.remove())
    this.removePort(portToDelete)
    portToDelete.remove()

    const ports = this.getPortsByType(PortType.LOGIC)
    ports.forEach((port, index) => {
      port.name = this.generateSplitPortName(this.sequenceItemId, index)
    })
  }
}
