import { hooks } from './hooks'
import { OptinToolsListData } from './use-optin-tools-list-data'

export function useOptinToolsListWithStats(
  optinToolsListData: Partial<OptinToolsListData>[] | undefined
) {
  const { data: optinToolsStat, isLoading: isOptinToolStatLoading } = hooks.useOptinToolsStat()
  const { data: subscriptionSourcesStat, isLoading: isSubscriptionSourceStatLoading } =
    hooks.useSubscriptionSourcesStat()

  const optinToolsWithStats =
    (optinToolsListData.map((optinTool) => {
      const optinToolStat = optinToolsStat?.[optinTool.id]
      const subscriptionSourceStat = subscriptionSourcesStat?.[optinTool.id]
      return {
        ...optinTool,
        ...optinToolStat,
        subscriptionSourceStat
      }
    }) as OptinToolsListData[]) ?? []

  return {
    optinToolsWithStats,
    isOptinToolStatLoading,
    isSubscriptionSourceStatLoading
  }
}
