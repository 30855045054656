import { Skeleton } from 'antd'
import cn from 'classnames'
import * as momentTimeZone from 'moment-timezone'
import React from 'react'
import { useSelector } from 'react-redux'
import { hooks } from '../../../../../../hooks/hooks'
import { useFeatureFlag } from '../../../../../../hooks/use-feature-flag'
import { selectEditorBlast, selectEditorSequence } from '../../../../../../store/selectors'
import { selectTimeZone } from '../../../../../../store/slices/site/site.selectors'
import { formatters } from '../../../../../../utils/formatters/formatters'
import { dateTimeFormat12hours } from '../../../../../../utils/utils'
import { getBlastSendingDate } from '../../../../utils/get-blast-sending-date'
import { Label } from '../label/label.component'
import { Title } from '../title/title.component'
import styles from './blast-settings-drawer-read-only.component.scss'
import { StaticText } from './static-text/static-text.component'

export function BlastSettingsDrawerReadOnly() {
  const blast = useSelector(selectEditorBlast)
  const sequence = useSelector(selectEditorSequence)
  const timezone = hooks.useSiteSelector(selectTimeZone)
  const isSMSFeatureEnabled = useFeatureFlag('enable-sms-feature')

  const { data: sequenceStat, isLoading: isSequenceStatLoading } = hooks.useSequenceStat(
    sequence?._id
  )
  const { data: segments } = hooks.useSegmentsList(undefined, {
    enabled: isSMSFeatureEnabled ?? false
  })

  if (!blast || timezone === undefined) {
    return null
  }

  function renderSegments() {
    const includedSegments =
      segments?.filter((segment) => blast?.targetRules?.includeSegmentIds?.includes(segment.id)) ??
      []
    const excludedSegments =
      segments?.filter((segment) => blast?.targetRules?.excludeSegmentIds?.includes(segment.id)) ??
      []
    return (
      <>
        <Label title='Send to'>
          <StaticText>
            {includedSegments.length > 0 ? (
              includedSegments.map((segment) => (
                <p key={segment.name}>
                  {segment.name} ({segment.size})
                </p>
              ))
            ) : (
              <p>All subscribers</p>
            )}
          </StaticText>
        </Label>
        {excludedSegments.length > 0 && (
          <Label title="Don't send to">
            <StaticText>
              {excludedSegments.map((segment) => (
                <p key={segment.name}>
                  {segment.name} ({segment.size})
                </p>
              ))}
            </StaticText>
          </Label>
        )}
        <StaticText>
          <div className='pt-3'>
            {isSequenceStatLoading ? (
              <div className={cn('w-3/12 inline-block align-text-bottom', styles.loader)}>
                <Skeleton active title={false} paragraph={{ rows: 2 }} />
              </div>
            ) : (
              <>
                <div>
                  <span>Sent to</span>{' '}
                  <span className='font-bold'>{formatters.number(sequenceStat?.sent ?? 0)}</span>{' '}
                  <span>subscriber{sequenceStat?.sent !== 1 && 's'}</span>
                </div>
                <div className='pt-1'>
                  <span className='font-bold'>
                    {formatters.percentage(sequenceStat?.spamRate?.sms ?? 0)}
                  </span>{' '}
                  <span>flagged as spam</span>
                </div>
              </>
            )}
          </div>
        </StaticText>
      </>
    )
  }

  return (
    <div data-testid='blast-settings-drawer-view-only'>
      <Title>Campaign settings</Title>
      <Label title='Campaign name'>
        <StaticText>{blast.name}</StaticText>
      </Label>
      {isSMSFeatureEnabled && renderSegments()}
      <Label title='Campaign scheduling'>
        <StaticText>{`Start sending on ${getBlastSendingDate(
          blast.scheduledFor,
          blast.scheduleType,
          timezone
        )}`}</StaticText>
        {blast.cannotEditAfter && (
          <StaticText>
            {`Last edit until ${momentTimeZone
              .tz(blast.cannotEditAfter, timezone)
              .format(dateTimeFormat12hours)}`}
            <br />
            <span>(store's timezone)</span>
          </StaticText>
        )}
      </Label>
      <Label title='Sending options'>
        <StaticText>{`Smart sending is turned ${
          sequence.isSmartSendingEnabled ? 'on' : 'off'
        }.`}</StaticText>
        <StaticText>{`Quiet hours is turned ${
          blast.isQuietHoursEnabled ? 'on' : 'off'
        }.`}</StaticText>
      </Label>
    </div>
  )
}
