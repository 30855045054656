// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.srd-node--selected{z-index:4}.PExwAjZGD9H3PeWhqI4Q{border-color:#c8c8c8;margin:18px 0 12px}.BHgW5qgaT_iA1sxw2KPF{text-align:center}.BHgW5qgaT_iA1sxw2KPF .logic-sequence-item-port-wrapper{background-color:rgba(71,109,255,.1);margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/routes/SequenceEditor/components/sequence-item-node-widget/message-node-widget/message-sequence-item-node-widget.component.scss","webpack://./src/routes/SequenceEditor/sequence-editor.scss"],"names":[],"mappings":"AAGE,oBACE,SAAA,CAKF,sBACE,oBCYU,CDXV,kBAAA,CAGF,sBACE,iBAAA,CAGE,wDAEE,oCCTe,CDQf,cCRe","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `PExwAjZGD9H3PeWhqI4Q`,
	"follow-up-wrapper": `BHgW5qgaT_iA1sxw2KPF`
};
export default ___CSS_LOADER_EXPORT___;
