import {
  getTriggerForPostId,
  MessengerMessageSequenceItemUI,
  SequenceTag
} from '@ghostmonitor/recartapis'
import { EntryPointType } from '../../../../routes/SequenceEditor/components/custom-conversation-wizard/types/custom-conversation-wizard.type'
import { MessageSequenceItemNodeModel } from '../../../../routes/SequenceEditor/models/sequence-item/message/message-sequence-item-model'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { getNodeModel } from '../../../../routes/SequenceEditor/types/get-node-model'
import { hasFollowUpButtonOnSequenceItemType } from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { FinishCustomConverationWizardAction } from '../sequence-editor.actions'
import { selectEditorSequence, selectSequenceItem } from '../sequence-editor.selectors'
import { SequenceEditorState } from '../sequence-editor.state'

function shouldRemoveFollowUp(entryPointType: EntryPointType) {
  return [
    EntryPointType.CTM,
    EntryPointType.SPONSORED_MESSAGE,
    EntryPointType.COMMENT_CAPTURE
  ].includes(entryPointType)
}

const tagsToRemove: string[] = [
  SequenceTag.AD,
  SequenceTag.AUTOMATED,
  SequenceTag.CAMPAIGN,
  SequenceTag.CUSTOM_CONVERSATION_FLOW,
  SequenceTag.FACEBOOK_COMMENTS,
  SequenceTag.SPONSORED_MESSAGE
]

export function finishCustomConversationWizardHandler(
  state: SequenceEditorState,
  action: FinishCustomConverationWizardAction
) {
  const sequence = selectEditorSequence(state)

  const { entrySequenceItemId } = sequence
  const entrySequenceItem =
    selectSequenceItem<MessengerMessageSequenceItemUI>(entrySequenceItemId)(state)

  let isDuplicate = false
  // TODO: https://app.clubhouse.io/recart/story/12507/implement-default-name-logic-on-sequences
  if (sequence.name.startsWith('copy_')) {
    isDuplicate = true
  }

  const tagsFromTemplate = sequence.tags.filter((tag) => !tagsToRemove.includes(tag))
  const tagsToAdd: string[] = []

  switch (action.payload.entryPointType) {
    case EntryPointType.BOT_PAYLOAD:
      tagsToAdd.push(
        SequenceTag.CUSTOM_CONVERSATION_FLOW,
        SequenceTag.FBMESSAGE,
        SequenceTag.CAMPAIGN
      )
      break

    case EntryPointType.COMMENT_CAPTURE:
      if (!isDuplicate) {
        sequence.name = 'Facebook Comments'
      }
      tagsToAdd.push(SequenceTag.AUTOMATED, SequenceTag.FACEBOOK_COMMENTS, SequenceTag.FBMESSAGE)
      if (action.payload.facebookPageId && action.payload.facebookPostId) {
        const facebookPostTrigger = getTriggerForPostId(
          action.payload.facebookPageId,
          action.payload.facebookPostId
        )
        entrySequenceItem.trigger.push(facebookPostTrigger)
      }
      break
    default:
      break
  }

  sequence.tags = [...new Set([...tagsFromTemplate, ...tagsToAdd])]

  sequence.sequenceItemIds.forEach((sequenceItemId) => {
    const sequenceItem = selectSequenceItem<MessengerMessageSequenceItemUI>(sequenceItemId)(state)
    sequenceItem.tags = sequence.tags
  })

  if (shouldRemoveFollowUp(action.payload.entryPointType)) {
    entrySequenceItem.nextSequenceItemTrigger = undefined

    const diagramModel = getDiagramModel(sequence.serializedDiagram)
    const node = getNodeModel<MessageSequenceItemNodeModel>(diagramModel, entrySequenceItem._id)

    if (hasFollowUpButtonOnSequenceItemType(entrySequenceItem)) {
      if (node.getFollowUpPort() !== undefined) {
        node.removeFollowUpPort()
      }
    }
  }
}
