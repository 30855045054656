import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom-v5-compat'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { Toast } from '../../../../../components/ui-kit/toast/toast.component'
import { AppDispatch } from '../../../../../store/create-store'
import { duplicateBlastThunk } from '../../../../../store/modules/blast/blast.thunks'
import { selectEditorBlast, selectEditorSequence } from '../../../../../store/selectors'
import { Notification } from '../../../../../utils/notification/notification.util'

export function BlastHeaderDuplicateButton() {
  const dispatch: AppDispatch = useDispatch()
  const sequence = useSelector(selectEditorSequence)
  const blast = useSelector(selectEditorBlast)
  const navigate = useNavigate()

  async function handleDuplicateBlast() {
    const resultAction = await dispatch(duplicateBlastThunk({ sequenceId: sequence._id, blast }))
    if (duplicateBlastThunk.fulfilled.match(resultAction)) {
      navigate(`/sequence-editor/${resultAction.payload}`)
      Toast({
        type: 'success',
        key: `toast-duplication-success`,
        content: 'Your campaign has been duplicated successfully.'
      })
    }

    if (duplicateBlastThunk.rejected.match(resultAction)) {
      Notification.error(
        'Something went wrong',
        'Your request cannot be completed right now. Please try again later.'
      )
    }
  }

  return (
    <div className='flex items-center'>
      <EditorButton
        type='primary'
        onClick={handleDuplicateBlast}
        data-testid='header-blast-duplicate'
      >
        Duplicate
      </EditorButton>
    </div>
  )
}
