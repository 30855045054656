import cheerio from 'cheerio'

function stripHtml(html: string) {
  const tmp = document.createElement('div')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

function replaceEmojis(text = ' ') {
  const $ch = cheerio.load('')
  const $wrapper = $ch('<div />')
  $wrapper.append(text)
  $wrapper.find('img').each((index, item) => {
    const emoji = $ch(item).attr('alt')
    $ch(item).replaceWith(emoji)
  })
  return $wrapper.html()
}

function replaceVariables(text: string) {
  let match
  const variable = /(?:{{([a-zA-Z_|]+)\|default:([a-zA-Z0-9\s]+)?}})/g
  while ((match = variable.exec(text))) {
    const fallback = match[2] || ''
    text = text.replace(match[0], fallback)
  }
  return text
}

function fancyCount(str: string): number {
  return Array.from(str.split(/[\ufe00-\ufe0f]/).join('')).length
}

export function getCharacterCount(value: string): number {
  const withReplacedEmojis = replaceEmojis(value)
  const withReplacedVariables = replaceVariables(withReplacedEmojis)
  const strippedHtml = stripHtml(withReplacedVariables)
  return fancyCount(strippedHtml.trim())
}
