import React from 'react'
import styled from 'styled-components'

const StyledStaticText = styled.div`
  margin-top: 6px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`

interface StaticTextProps {
  children?: React.ReactNode
}

export function StaticText(props: StaticTextProps) {
  return <StyledStaticText className='text-sm text-tertiary'>{props.children}</StyledStaticText>
}
