import { SequenceTag } from '@ghostmonitor/recartapis'
import { CheckboxOptionType, InputProps, RadioChangeEvent } from 'antd'
import React, { ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hooks } from '../../../../hooks/hooks'
import { useJoinedASegmentFlowCheck } from '../../../../hooks/use-joined-a-segment-flow-check'
import { quietHoursTooltipText } from '../../../../routes/flow-editor/utils/sms-campaign-settings'
import { ReactComponent as InfoIcon } from '../../../../static/images/svg/sequence-editor/info-icon.svg'
import { AppDispatch } from '../../../../store/create-store'
import { selectEditorSequence } from '../../../../store/selectors'
import {
  updateQuietHours,
  updateSegmentId,
  updateSendingFrequency,
  updateSequenceName
} from '../../../../store/slices/sequence/sequence.actions'
import { formatters } from '../../../../utils/formatters/formatters'
import { quietHoursTimeFormat } from '../../../../utils/utils'
import { LabelledRadioGroup } from '../../../ui-kit/labelled-radio-group/labelled-radio-group.component'
import { Option, Select } from '../../../ui-kit/select/select.component'
import { Tooltip } from '../../../ui-kit/tooltip/tooltip.component'
import { UIKit } from '../../../ui-kit/ui-kit'
import { Label } from '../label/label.component'
import { Title } from '../title/title.component'
import { Toggle } from '../toggle/toggle.component'
import styles from './joined-a-segment.component.scss'
import { SegmentOption } from './segment-option/segment-option.component'

const segmentRefetchInterval = 10000

const frequencyOptions: CheckboxOptionType[] = [
  {
    label: 'Every time',
    value: 0
  },
  {
    label: 'For the first time',
    value: 1
  }
]

function Input(props: InputProps) {
  return <UIKit.Input className={styles.input} {...props} />
}

export function JoinedASegmentDrawer() {
  const sequence = useSelector(selectEditorSequence)
  const { data: joinedASegmentSequences } = hooks.useSequencesByTags([SequenceTag.JOINED_A_SEGMENT])
  const { data: segments } = hooks.useSegmentsList({}, { refetchInterval: segmentRefetchInterval })
  const { data: quietHoursSettings } = hooks.useSMSQuietHoursSettings()
  const { existingSequenceWithName } = useJoinedASegmentFlowCheck(sequence, joinedASegmentSequences)
  const [isNameChanged, setIsNameChanged] = useState(false)

  const hasMMS = hooks.useHasMMS()
  const hasInternationalPhoneNumber = hooks.useHasInternationPhoneNumber()

  const dispatch: AppDispatch = useDispatch()

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setIsNameChanged(true)
    dispatch(updateSequenceName({ name: event.target.value }))
  }

  function handleSegmentChange(segmentId: string) {
    dispatch(updateSegmentId({ segmentId }))
  }

  function handleQuietHoursChange(isChecked: boolean) {
    dispatch(updateQuietHours({ isQuietHoursEnabled: isChecked }))
  }

  function frequencyChangeHandler(e: RadioChangeEvent) {
    if (typeof e.target.value !== 'number') {
      return
    }
    const numberOfSentMessages = e.target.value
    dispatch(
      updateSendingFrequency({
        sendingFrequency: {
          type: 'lockAfterMessageCount',
          numberOfSentMessages
        }
      })
    )
  }

  return (
    <div data-testid='joined-a-segment-settings-drawer'>
      <Title>Settings</Title>
      <Label title='Flow name'>
        <Input
          key='sequence-name'
          data-testid='joined-a-segment-name'
          value={sequence.name ?? ''}
          className={existingSequenceWithName !== undefined ? styles.bgError : ''}
          onChange={handleNameChange}
        />
        {isNameChanged && sequence.name === '' && (
          <div className={styles.error}>Flow name cannot be empty.</div>
        )}
        {existingSequenceWithName && (
          <div className={styles.error}>This flow name is already in use.</div>
        )}
      </Label>
      <div>
        <div className='flex items-center mt-6 mb-3'>
          <div className='font-semibold text-sm  mr-1'>Send to</div>
          {hasMMS && hasInternationalPhoneNumber && (
            <Tooltip
              placement='top'
              title='Subscribers outside of US and Canada will only receive the text part of the message without attachments (as an SMS).'
              arrowPointAtCenter
            >
              <InfoIcon className='icon-tiny text-secondary' />
            </Tooltip>
          )}
        </div>
        <Select
          className={styles.select}
          data-testid='select-segment'
          popupClassName={styles.dropdown}
          onChange={handleSegmentChange}
          optionFilterProp='children'
          placeholder='Select a segment'
          showArrow
          size='large'
          value={sequence.segmentId}
        >
          {!sequence.isEnabled && (
            <Option key='segment-empty' value=''>
              None
            </Option>
          )}
          {segments?.map((segment) => {
            const usingSequence = joinedASegmentSequences?.find(
              (joinedASegmentSequence) =>
                joinedASegmentSequence.segmentId === segment.id &&
                joinedASegmentSequence._id !== sequence._id
            )
            return (
              <Option
                key={`segment-${segment.id}`}
                value={segment.id}
                disabled={usingSequence !== undefined || segment.creationStatus !== 'created'}
              >
                <SegmentOption segment={segment} usingSequenceName={usingSequence?.name} />
              </Option>
            )
          })}
        </Select>
      </div>
      <Label title='Sending options'>
        <Toggle
          title='Quiet hours'
          subTitle={
            quietHoursSettings?.start &&
            quietHoursSettings?.end &&
            `From ${formatters.date(
              quietHoursSettings?.start,
              quietHoursTimeFormat
            )} to ${formatters.date(
              quietHoursSettings?.end,
              quietHoursTimeFormat
            )} in the subscriber’s time zone`
          }
          tooltipText={quietHoursTooltipText}
          onChange={handleQuietHoursChange}
          checked={sequence.isQuietHoursEnabled}
          data-testid='quiet-hours-toggle'
        />
        <LabelledRadioGroup
          label='Sending frequency'
          toolTipText='Subscribers will receive the flow when they enter the segment:'
          defaultValue={sequence.sendingFrequency?.numberOfSentMessages}
          options={frequencyOptions}
          onChange={frequencyChangeHandler}
        />
      </Label>
    </div>
  )
}
