import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditorButton } from '../../../../../components/ui-kit/editor-button/editor-button.component'
import { hooks } from '../../../../../hooks/hooks'
import { selectEditorSequenceMeta } from '../../../../../store/selectors'
import { showModal } from '../../../../../store/slices/modal/modal.actions'
import { ModalType } from '../../../../../store/slices/modal/modal.type'

export function HeaderSendSMSTestButton() {
  const sequenceMeta = useSelector(selectEditorSequenceMeta)
  const isSendSMSTestEnabled = hooks.useFeatureFlag('enable-sms-feature')

  const dispatch = useDispatch()

  async function handleSendSMSTest() {
    dispatch(showModal({ modalType: ModalType.SEND_SMS_TEST }))
  }

  if (!isSendSMSTestEnabled) {
    return null
  }

  return (
    <div className='flex items-center'>
      <EditorButton
        disabled={sequenceMeta.unsavedChanges}
        onClick={handleSendSMSTest}
        type='default'
        data-testid='header-send-sms-test-button'
      >
        Send test
      </EditorButton>
    </div>
  )
}
