import { FlowUI } from '@ghostmonitor/recartapis'
import { getDiscountCodesFromFlowItem } from './get-discount-codes-from-flow-item'

export function getDiscountCodesFromFlow(flow: FlowUI): string[] {
  const discountCodes: string[] = []

  flow.sequenceItems.forEach((flowItem) => {
    discountCodes.push(...getDiscountCodesFromFlowItem(flowItem))
  })

  return discountCodes
}
