import cheerio from 'cheerio'
import { getRawVariableFromVariable, getVariableFromId } from './get-variables'

export function replaceUserVariablesToRawVariables(htmlValue: string): string {
  const $html = cheerio.load(htmlValue)

  $html('span').each((index, element) => {
    const tagId = $html(element).attr('id')
    if (tagId) {
      const variable = getVariableFromId(tagId)
      $html(element).replaceWith(getRawVariableFromVariable(variable))
    }
  })

  return $html('body').html()
}

export function replaceVariableTagsToRawVariables(
  htmlValue: string,
  variableNamesToReplace: string[]
): string {
  const $html = cheerio.load(htmlValue)

  $html('span').each((index, element) => {
    const tagId = $html(element).attr('id')
    if (tagId) {
      const variable = getVariableFromId(tagId)

      if (variableNamesToReplace.includes(variable.name)) {
        $html(element).replaceWith(getRawVariableFromVariable(variable))
      }
    }
  })

  return $html('body').html()
}
