import { UpdateModelOnSequenceAction } from '../sequence-editor.actions'
import { selectEditorSequence, selectSequenceItem } from '../sequence-editor.selectors'
import { getDiagramModel } from '../../../../routes/SequenceEditor/types/get-diagram-model'
import { updateLinkTargets } from '../utils/update-link-targets'
import { SequenceEditorState } from '../sequence-editor.state'

export function updateModelOnSequenceActionHandler(
  state: SequenceEditorState,
  action: UpdateModelOnSequenceAction
) {
  const sequence = selectEditorSequence(state)
  sequence.serializedDiagram = action.serializedDiagram
  const diagramModel = getDiagramModel(sequence.serializedDiagram)

  /**
   * If links were deleted, update targets on sequence items
   */
  sequence.sequenceItemIds.forEach((sequenceItemId) => {
    const sequenceItem = selectSequenceItem(sequenceItemId)(state)
    updateLinkTargets(state, diagramModel, sequenceItem)
  })
}
