import { Blast, BlastStatus } from '@ghostmonitor/recartapis'

export function isBlastReadonly(blast: Blast): boolean {
  return [
    BlastStatus.SCHEDULED,
    BlastStatus.SCHEDULED_FOR_NOW,
    BlastStatus.PROCESSING,
    BlastStatus.CANCELLED,
    BlastStatus.FAILED,
    BlastStatus.SENDING,
    BlastStatus.SENT
  ].includes(blast?.status)
}
