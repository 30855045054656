import { FlowItemUI, isLegalFlowItem, NodeType, SequenceTag } from '@ghostmonitor/recartapis'
import { ObjectId } from 'bson'
import { getNewLegalSMSMessageFlowItem } from '../../../../routes/flow-editor/utils/get-new-flow-item'
import { selectEditorFlow, selectFlowItem, selectNodeByFlowItemId } from '../flow-editor.selectors'
import { FlowEditorState } from '../flow-editor.state'
import { connectNodeTo } from './connect-node'
import { deleteNode } from './delete-node'
import { attachEntryFlowItem, detachEntryFlowItem, insertEntryFlowItem } from './entry-flow-item'

export function addSMSWelcomeLegalMessage(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const legalMessageFlowItemId = new ObjectId().toHexString()
  const legalMessageFlowItem: FlowItemUI = getNewLegalSMSMessageFlowItem(
    legalMessageFlowItemId,
    flow
  )

  insertEntryFlowItem(state, NodeType.SMS_FLOW_ITEM, legalMessageFlowItem, true, true)
}

export function removeSMSWelcomeLegalMessage(state: FlowEditorState) {
  const flow = selectEditorFlow(state)

  if (!flow) {
    throw new Error('Flow not found')
  }

  const entryFlowItemId = flow.entrySequenceItemId

  if (!entryFlowItemId) {
    throw new Error('entrySequenceItemId not found')
  }

  const entryFlowItem = selectFlowItem(entryFlowItemId)(state)
  const isLegalItem = isLegalFlowItem(entryFlowItem)

  if (!isLegalItem) {
    return
  }

  const node = selectNodeByFlowItemId(entryFlowItemId)(state)

  if (!node) {
    throw new Error('Node not found')
  }

  const connectionToLegalMessage = flow.flowEditorDiagram.edges.find(
    (edge) => edge.target === node.id
  )

  if (!connectionToLegalMessage) {
    throw new Error('Connection to legal message not found')
  }

  if (!connectionToLegalMessage.sourceHandle) {
    throw new Error('Connection to legal message source handle not found')
  }

  const connectionFromLegalMessage = flow.flowEditorDiagram.edges.find(
    (edge) => edge.source === node.id
  )

  detachEntryFlowItem(state)
  deleteNode(state, node.id)

  if (connectionFromLegalMessage) {
    const nodeAfterLegalMessage = flow.flowEditorDiagram.nodes.find(
      (node) => node.id === connectionFromLegalMessage.target
    )

    if (!nodeAfterLegalMessage) {
      throw new Error('Target node not found')
    }

    if (!nodeAfterLegalMessage.data.flowItemId) {
      throw new Error('Target flowItemId not found')
    }

    const targetFlowItem = flow.sequenceItems.find(
      (flowItem) => flowItem._id === nodeAfterLegalMessage.data.flowItemId
    )

    if (!targetFlowItem) {
      throw new Error('Target flow item not found')
    }

    const triggerNodeId = connectionToLegalMessage.source
    const triggerNodeHandleId = connectionToLegalMessage.sourceHandle

    connectNodeTo(state, nodeAfterLegalMessage, triggerNodeId, triggerNodeHandleId)
    attachEntryFlowItem(state, nodeAfterLegalMessage.data.flowItemId)
  }
}
