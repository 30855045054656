import {
  getTextFromMessage,
  hasTextOnMessage,
  isMessengerMessageSequenceItemUI,
  isRandomSplitSequenceItemUI,
  MessageUI,
  SiteEngineSlug
} from '@ghostmonitor/recartapis'
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import memoize from 'lodash/memoize'
import { batch } from 'react-redux'
import { SequenceEditorError } from '../../../../routes/SequenceEditor/types/sequence-editor-errors'
import {
  hasMessengerButtonOnMessageType,
  isMessageGenericGalleryTemplate
} from '../../../../routes/SequenceEditor/utils/assert-message-template-type'
import {
  hasMessageOnSequenceItemType,
  isFacebookCommentsSequenceItem
} from '../../../../routes/SequenceEditor/utils/assert-sequence-item-type'
import { buttonValidator } from '../../../../routes/SequenceEditor/validators/button.validator'
import { discountCodeValidator } from '../../../../routes/SequenceEditor/validators/discount-code.validator'
import { facebookCommentsValidator } from '../../../../routes/SequenceEditor/validators/facebook-comments.validator'
import { messageItemValidator } from '../../../../routes/SequenceEditor/validators/message-item.validator'
import { messageValidator } from '../../../../routes/SequenceEditor/validators/message.validator'
import { quickReplyValidator } from '../../../../routes/SequenceEditor/validators/quick-reply.validator'
import { sequenceItemValidator } from '../../../../routes/SequenceEditor/validators/sequence-item.validator'
import { sequenceValidator } from '../../../../routes/SequenceEditor/validators/sequence.validator'
import { splitValidator } from '../../../../routes/SequenceEditor/validators/split.validator'
import { getDiscountCodesFromText } from '../../../../routes/SequenceEditor/validators/utils/get-discount-codes-from-text'
import { serializedError } from '../../../../utils/serialized-error'
import type { AppDispatch } from '../../../create-store'
import { DashboardState } from '../../../dashboard.state'
import {
  selectEditorSequence,
  selectEditorSequenceMeta,
  selectMessageMeta,
  selectSequenceItem,
  selectSiteEngineSlug,
  selectValidDiscountCodes
} from '../../../selectors'
import {
  buttonValidationError,
  buttonValidationSuccess,
  inlineDiscountCodeValidationErrorExpired,
  inlineDiscountCodeValidationErrorNotExists,
  inlineDiscountCodeValidationSuccess,
  messageItemValidationError,
  messageItemValidationSuccess,
  messageValidationError,
  messageValidationSuccess,
  quickReplyValidationError,
  quickReplyValidationSuccess,
  sequenceItemValidationError,
  sequenceItemValidationSuccess,
  splitValidationError,
  splitValidationSuccess,
  touchAllQuickReplies,
  touchAllSequenceItems,
  touchAllSplits
} from '../sequence-editor.actions'

export const validateSequenceThunk = createAsyncThunk<
  unknown,
  void,
  {
    dispatch: AppDispatch
    state: DashboardState
  }
>('sequence-editor/validateSequence', async (args, store) => {
  const state = store.getState()
  const dispatch = store.dispatch
  const sequence = cloneDeep(selectEditorSequence(state))
  const sequenceMeta = selectEditorSequenceMeta(state)
  const sequenceItems = sequence.sequenceItemIds.map((sequenceItemId) =>
    selectSequenceItem(sequenceItemId)(state)
  )
  const validDiscountCodes = selectValidDiscountCodes(state)
  const memoizedDiscountCodeValidator = memoize(discountCodeValidator)

  const siteEngineSlug = selectSiteEngineSlug(state)
  const isShopify = siteEngineSlug === SiteEngineSlug.SHOPIFY

  if (
    !sequenceMeta.quickRepliesTouched ||
    !sequenceMeta.sequenceItemsTouched ||
    !sequenceMeta.splitsTouched
  ) {
    batch(() => {
      dispatch(touchAllQuickReplies())
      dispatch(touchAllSequenceItems())
      dispatch(touchAllSplits())
    })
  }

  const actions = []
  let hasError = false

  const hasAllSequenceItem = sequenceItems.every((item) => item !== undefined)
  if (hasAllSequenceItem) {
    const { sequenceErrors, sequenceItemErrors: itemErrorsOnSequence } = sequenceValidator(
      sequence,
      sequenceItems
    )

    if (sequenceErrors.length > 0) {
      // TODO there is no sequence error yet
    }

    Object.entries(itemErrorsOnSequence).forEach(([sequenceItemId, errors]) => {
      if (errors.length > 0) {
        hasError = true
        actions.push(
          sequenceItemValidationError(errors[0], {
            sequenceItemId
          })
        )
      } else {
        actions.push(
          sequenceItemValidationSuccess(null, {
            sequenceItemId
          })
        )
      }
    })
  }

  await Promise.all(
    sequence.sequenceItemIds.map(async (sequenceItemId) => {
      const sequenceItem = selectSequenceItem(sequenceItemId)(state)
      const sequenceItemErrors = sequenceItemValidator(sequence, sequenceItem)
      const isEntrySequenceItem = sequenceItem._id === sequence.entrySequenceItemId

      if (isFacebookCommentsSequenceItem(sequenceItem) && isEntrySequenceItem) {
        sequenceItemErrors.push(...facebookCommentsValidator(sequenceItem))
      }

      if (sequenceItemErrors.length > 0) {
        hasError = true
        actions.push(
          sequenceItemValidationError(sequenceItemErrors[0], {
            sequenceItemId
          })
        )
      } else {
        actions.push(
          sequenceItemValidationSuccess(null, {
            sequenceItemId
          })
        )
      }

      if (isMessengerMessageSequenceItemUI(sequenceItem)) {
        sequenceItem.quickReplies.forEach((quickReply, quickReplyIndex) => {
          const quickReplyErrors = quickReplyValidator(quickReply)
          if (quickReplyErrors.length > 0) {
            hasError = true
            actions.push(
              quickReplyValidationError(quickReplyErrors[0], {
                sequenceItemId,
                quickReplyIndex
              })
            )
          } else {
            actions.push(
              quickReplyValidationSuccess(null, {
                sequenceItemId,
                quickReplyIndex
              })
            )
          }
        })
      }

      if (hasMessageOnSequenceItemType(sequenceItem)) {
        await Promise.all(
          sequenceItem.messages.map(async (message: MessageUI, messageIndex: number) => {
            const discountCodeErrors: SerializedError[] = []
            const messageMeta = selectMessageMeta(sequenceItem._id, messageIndex)(state)

            if (hasTextOnMessage(message)) {
              const text = getTextFromMessage(message)
              const discountCodes = getDiscountCodesFromText(text)

              await Promise.all(
                discountCodes.map(async (discountCode) => {
                  if (validDiscountCodes.includes(discountCode)) {
                    return
                  }
                  const discountCodeError = await memoizedDiscountCodeValidator(discountCode)
                  if (discountCodeError) {
                    if (discountCodeError.name === SequenceEditorError.DiscountCodeExpired) {
                      discountCodeErrors.push(discountCodeError)
                      dispatch(
                        inlineDiscountCodeValidationErrorExpired({
                          discountCode
                        })
                      )
                    } else if (
                      discountCodeError.name === SequenceEditorError.DiscountCodeNotExists &&
                      (sequenceMeta.isEverSaved || messageMeta.edited)
                    ) {
                      discountCodeErrors.push(discountCodeError)
                      dispatch(
                        inlineDiscountCodeValidationErrorNotExists({
                          discountCode
                        })
                      )
                    }
                  } else {
                    dispatch(
                      inlineDiscountCodeValidationSuccess({
                        discountCode
                      })
                    )
                  }
                })
              )
            }

            const messageErrors = messageValidator(message)
            if (messageErrors.length > 0 || discountCodeErrors.length > 0) {
              hasError = true
              actions.push(
                messageValidationError(
                  [
                    ...messageErrors,
                    ...(discountCodeErrors.length > 0
                      ? [serializedError(SequenceEditorError.MessageInvalidDiscountCode)]
                      : [])
                  ],
                  {
                    sequenceItemId,
                    messageIndex
                  }
                )
              )
            } else {
              actions.push(
                messageValidationSuccess(null, {
                  sequenceItemId,
                  messageIndex
                })
              )
            }

            if (hasMessengerButtonOnMessageType(message)) {
              await Promise.all(
                message.messengerTemplatePayload.buttons.map(async (button, buttonIndex) => {
                  const buttonErrors = await buttonValidator(button, isShopify)
                  if (buttonErrors.length > 0) {
                    hasError = true
                    actions.push(
                      buttonValidationError(buttonErrors[0], {
                        sequenceItemId,
                        messageIndex,
                        buttonIndex
                      })
                    )
                  } else {
                    actions.push(
                      buttonValidationSuccess(null, {
                        sequenceItemId,
                        messageIndex,
                        buttonIndex
                      })
                    )
                  }
                })
              )
            }

            if (isMessageGenericGalleryTemplate(message)) {
              await Promise.all(
                message.messengerTemplatePayload.map(async (messageItem, messageItemIndex) => {
                  const messageItemErrors = messageItemValidator(messageItem)
                  if (messageItemErrors.length > 0) {
                    hasError = true
                    actions.push(
                      messageItemValidationError(messageItemErrors[0], {
                        sequenceItemId,
                        messageIndex,
                        messageItemIndex
                      })
                    )
                  } else {
                    actions.push(
                      messageItemValidationSuccess(null, {
                        sequenceItemId,
                        messageIndex,
                        messageItemIndex
                      })
                    )
                  }

                  await Promise.all(
                    messageItem.buttons.map(async (button, buttonIndex) => {
                      const buttonErrors = await buttonValidator(button, isShopify)
                      if (buttonErrors.length > 0) {
                        hasError = true
                        actions.push(
                          buttonValidationError(buttonErrors[0], {
                            sequenceItemId,
                            messageIndex,
                            buttonIndex,
                            messageItemIndex
                          })
                        )
                      } else {
                        actions.push(
                          buttonValidationSuccess(null, {
                            sequenceItemId,
                            messageIndex,
                            buttonIndex,
                            messageItemIndex
                          })
                        )
                      }
                    })
                  )
                })
              )
            }
          })
        )
      }

      if (isRandomSplitSequenceItemUI(sequenceItem)) {
        sequenceItem.logic.randomSplit.variants.forEach((split, splitIndex) => {
          const splitErrors = splitValidator(split)
          if (splitErrors.length > 0) {
            hasError = true
            actions.push(splitValidationError(splitErrors[0], { sequenceItemId, splitIndex }))
          } else {
            actions.push(splitValidationSuccess(null, { sequenceItemId, splitIndex }))
          }
        })
      }
    })
  )

  batch(() => {
    actions.forEach(dispatch)
  })

  if (hasError) {
    throw new Error('Sequence validation failed')
  }
})
